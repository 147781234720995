(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AgreementValuationDetailController', AgreementValuationDetailController);

    AgreementValuationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'AgreementValuation'];

    function AgreementValuationDetailController($scope, $rootScope, $stateParams, entity, AgreementValuation) {
        var vm = this;
        vm.agreementValuation = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:agreementValuationUpdate', function(event, result) {
            vm.agreementValuation = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
