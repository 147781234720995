(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance', '$mdToast', '$http'];

    function LoginController ($rootScope, $state, $timeout, Auth, $uibModalInstance, $mdToast, $http) {
        var vm = this;

        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;

        $timeout(function (){angular.element('#username').focus();});

        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            $uibModalInstance.dismiss('cancel');
        }

        function login (event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                showFollowUpsToast();
                vm.authenticationError = false;
                $uibModalInstance.close();
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function () {
                vm.authenticationError = true;
            });
        }

        function register () {
            $uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function requestResetPassword () {
            $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }

        function showFollowUpsToast(){
            var filters = {
                data: {
                    "page": 1,
                    "pageSize": 1, // lightweight request
                    filter: {
                        logic: "and",
                        filters: [{
                            "operator": "eqdateonly",
                            "value": moment(new Date()).format('YYYY-MM-DD'),
                            "field": "date"
                        }, {
                            "field": "completed",
                            "operator": "eqbool",
                            "value": "false"
                        },{
                            "field": "leadCall.leadOffers.offerStatus.id",
                            "operator": "noteq",
                            "value": 3
                        }]
                    }
                }
            };
            $http.get('/api/followup-calls/search',
                {params: filters.data})
                .success(function (response) {
                    //console.log(response);
                    if (response.content !== null && response.content !== undefined && response.content.length > 0){
                        $mdToast.show({
                            hideDelay   : false,
                            position    : 'top right',
                            controller  : 'MdToastCtrl',
                            templateUrl : 'app/components/follow-ups-md-toast/md-toast-template.html'
                        });
                    }
                })
                .error(function (error) {
                    //console.log(error);
                });
        }
    }
})();
