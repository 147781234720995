/**
 * Created by Kristy on 20/1/2020.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminEmployeeViewMedReportsController', AdminEmployeeViewMedReportsController);

    AdminEmployeeViewMedReportsController.$inject = ['$http','$state', '$stateParams', '$filter', 'Principal', 'KendoFilter', 'CustomUtils', 'KendoService', 'MasterFilters'];

    function AdminEmployeeViewMedReportsController ($http, $state, $stateParams, $filter, Principal, KendoFilter, CustomUtils, KendoService, MasterFilters) {
        var vm = this;
        vm.$state = $state;
    }

})();
