/**
 * Created by Kristy on 20/12/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PharmacyAssignationsController', PharmacyAssignationsController);

    PharmacyAssignationsController.$inject = ['$filter','$http', '$state', 'ApplicationParameters'];

    function PharmacyAssignationsController ($filter, $http, $state, ApplicationParameters) {
        var vm = this;

        ApplicationParameters.hasModule('Visit_Pharmacies', true);
    }
})();
