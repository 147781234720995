/**
 * Created by Kristy on 2/6/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('hospitalizations', {
                parent: 'app',
                url: '/hospitalizations',
                data: {
                    authorities: ['ROLE_REC_MANAGEMENT', 'ROLE_REC_FRONT_DESK'],
                    pageTitle: 'sigmaCrmApp.hospitalizations.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/hospitalizations/hospitalizations.html',
                        controller: 'HospitalizationsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('hospitalizationView', {
                parent: 'app',
                url: '/hospitalizations/search?id:hospitalizationId',
                data: {
                    authorities: ['ROLE_REC_MANAGEMENT', 'ROLE_REC_FRONT_DESK'],
                    pageTitle: 'sigmaCrmApp.hospitalizations.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/hospitalizations/hospitalizationView.html',
                        controller: 'HospitalizationViewController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null,
                    patientId: null,
                    leadCallId: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search,
                            patientId: $stateParams.patientId,
                            leadCallId: $stateParams.leadCallId
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
