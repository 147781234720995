(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('EmployeeCustomerSectorDeleteController',EmployeeCustomerSectorDeleteController);

    EmployeeCustomerSectorDeleteController.$inject = ['$uibModalInstance', 'entity', 'EmployeeCustomerSector'];

    function EmployeeCustomerSectorDeleteController($uibModalInstance, entity, EmployeeCustomerSector) {
        var vm = this;
        vm.employeeCustomerSector = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            EmployeeCustomerSector.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
