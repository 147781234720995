/**
 * Created by Maria on 13/5/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('adminEventsOtherController', adminEventsOtherController);

    adminEventsOtherController.$inject = ['$scope', 'favouriteFilters', '$timeout', 'employee','$http', 'KendoService', 'KendoFilter', 'SupervisorsService', 'MasterFiltersService', 'FavouriteFiltersService', 'MasterToKendoFiltersService', 'customersView'];

    function adminEventsOtherController ($scope, favouriteFilters, $timeout, employee, $http, KendoService, KendoFilter, SupervisorsService, MasterFiltersService, FavouriteFiltersService, MasterToKendoFiltersService, customersView) {
        var vm = this;
        vm.favouriteFilters = favouriteFilters.data.content;
        vm.customerView = customersView.data.content[0].value;
        vm.employee = employee;
        SupervisorsService.setSelectedSupervisors([]);
        vm.masterFilters = { selectedSupervisors: SupervisorsService.getSelectedSupervisors(), selectedEmployees: [] };
        vm.pageHeading = 'Άλλα Συμβάντα';
        vm.disabled = true;
        vm.isEdited = vm.isRenameMode = false;
        vm.selectedFilter = {};
        vm.mainGrid = {};
        vm.masterOptions = [];
        vm.filter = {};
        vm.view = 'otherEvents';
        vm.exportFlag = false;

        var setView = function() {
            var filterable = vm.masterFilters.selectedEmployees.length || SupervisorsService.getSelectedSupervisors().length ? {
                mode: "row"
            } : false;

            vm.disabled = !vm.masterFilters.selectedEmployees.length && !SupervisorsService.getSelectedSupervisors().length;
            vm.mainGrid.setOptions({
                sortable: vm.masterFilters.selectedEmployees.length || SupervisorsService.getSelectedSupervisors().length,
                groupable: vm.masterFilters.selectedEmployees.length || SupervisorsService.getSelectedSupervisors().length,
                filterable: filterable
            });
        };

        $scope.$watch("vm.masterFilters.selectedSupervisors", function (value) {
            SupervisorsService.setSelectedSupervisors(value);
            setView();
            vm.employeesMulti.enable(!vm.masterFilters.selectedSupervisors.length);
        });
        $scope.$watch("vm.masterFilters.selectedEmployees", function () {
            setView();
            vm.supervisorsMulti.enable(!vm.masterFilters.selectedEmployees.length);
        });

        vm.masterFiltersEdited = function(){
            var gridOptions = FavouriteFiltersService.getKendoOptions(vm.mainGrid);
            $timeout(function(){
                vm.isEdited = !!(vm.selectedFilter.kendoOptions && vm.masterOptions.length &&
                    (!angular.equals(vm.masterOptions, MasterToKendoFiltersService.getMasterFilters([], vm.masterFilters, vm.view)) || !angular.equals(JSON.parse(vm.selectedFilter.kendoOptions), gridOptions)) );
            });
        };
        $scope.$watch("vm.masterFilters", vm.masterFiltersEdited, true);

        vm.collapseAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-collapse").trigger("click"); };
        vm.expandAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-expand").trigger("click"); };

        vm.supervisorsMultiOptions = MasterFiltersService.getSupervisorsComboBoxOptions();
        vm.supervisorsMultiOptions.change = function () {
            vm.employeesMulti.value('');
            vm.employeesMultiOptions.dataSource.filter({});
            vm.masterFilters.selectedEmployees = [];
            vm.employeesMulti.dataSource.read();
        };
        vm.employeesMultiOptions = MasterFiltersService.getEmployeesComboBoxOptions(["all", "me"]);
        vm.employeesMultiOptions.dataSource.filter({});

        //refresh filters
        var masterFilters = [vm.employeesMultiOptions];
        angular.forEach(masterFilters, function(masterFilter) {
            masterFilter.dataSource.filter({});
        });

        //Date Filter

        vm.clearDatePickers = function () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        };

        vm.masterFiltersOnClick = function (e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id == "today") {
                vm.clearDatePickers();
                $timeout(function() {
                    vm.masterFilters.from = date;
                    vm.masterFilters.to = date;
                });

            }
            else if (e.id == "week") {
                vm.clearDatePickers();

                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6

                $timeout(function() {
                    vm.masterFilters.from = firstWeekDay;
                    vm.masterFilters.to = lastWeekDay;
                });

            }
            else if (e.id == "month") {
                vm.clearDatePickers();

                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                $timeout(function() {
                    vm.masterFilters.from = firstMonthDay;
                    vm.masterFilters.to = lastMonthDay;
                });
            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup", id:"buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ],
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Από: </label><input class='inline' kendo-date-picker id='from' options='vm.fromOptions' k-ng-model='vm.masterFilters.from'/>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Εώς: </label><input class='inline' kendo-date-picker id='to' options='vm.toOptions' k-ng-model='vm.masterFilters.to'/>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Προιστάμενος: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.supervisorsMulti' multiple='multiple' " +
                    "k-options='vm.supervisorsMultiOptions' k-ng-model='vm.masterFilters.selectedSupervisors'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Ιατρικός Επισκέπτης: </label>" +
                        "<select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.employeesMulti' multiple='multiple' " +
                        "k-options='vm.employeesMultiOptions' k-ng-model='vm.masterFilters.selectedEmployees'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary' aria-label='search' ng-click='vm.mainGridOptions.dataSource.read()' ng-disabled='vm.disabled'>" +
                    "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>", attributes: { class: "right" },
                    overflow: "never"
                }
            ]
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.from = value;
                } else {
                    vm.masterFilters.from = null;
                }
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.to = value;
                } else {
                    vm.masterFilters.to = null;
                }
            }
        };

        var filterTemplate = function (args) {
            KendoFilter.getTemplate(args);
        };

        vm.mainGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function (o) {
                        vm.searchEventsUrl = '/api/other-events/all';
                        if (o.data.filter === undefined) { o.data.filter = { filters: [] }; }
                        o.data.filter.filters = MasterToKendoFiltersService.getMasterFilters(o.data.filter.filters, vm.masterFilters, vm.view);

                        $http.get(vm.searchEventsUrl, {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    }
                },
                schema: {
                    data: function (data) {
                        data.forEach(function (item) {
                            if (item.employee === null) item.employee = { account: {} };

                            if(item.eventCategoryType === null){
                                item.eventCategoryType = {};
                                if(item.eventCategory === null){
                                    item.eventCategory = {};
                                }
                            }else{
                                if(item.eventCategory === null) item.eventCategory = {};
                            }

                        });
                        return data;
                    },
                    parse: function (data) {
                        angular.forEach(data, function (item) {
                            item.eventDate = kendo.parseDate(kendo.toString(kendo.parseDate(item.dateFrom), 'dd/MM/yyyy'));
                            item.eventDateSort = kendo.toString(kendo.parseDate(item.dateFrom), 'dd/MM/yyyy HH:mm');
                            item.endDate = kendo.parseDate(kendo.toString(kendo.parseDate(item.dateTo), 'dd/MM/yyyy'));
                            item.endDateSort = kendo.toString(kendo.parseDate(item.dateTo), 'dd/MM/yyyy HH:mm');
                        });
                        return data;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: {editable: false, nullable: false},
                            // eventDate: { type: 'date' },
                            // 'eventCustomerSector.agreedPercent': { type: "number" }
                        }
                    }
                },
                group: [{field: "eventDate", dir: "desc", aggregates: [{field: "eventDate", aggregate: "count"}]}],
                aggregate: [{field: "id", aggregate: "count"}]
            }),
            sortable: false,
            groupable: false,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    title: "Στοιχεία Επίσκεψης",
                    columns: [
                        {
                            field: "eventDate",
                            title: "Ημερομηνία Έναρξης",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "eq",
                                    showOperators: false
                                }
                            },
                            template: "#= kendo.toString(kendo.parseDate(dateFrom), 'dddd, dd/MM/yyyy HH:mm')#",
                            groupHeaderTemplate: "Ημερομηνία Έναρξης: #= kendo.toString(kendo.parseDate(value), 'dddd, dd/MM/yyyy')# (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            hidden: true,
                            field: "eventDateSort",
                            title: "Ημερομηνία Έναρξης",
                            filterable: {
                                cell: {
                                    operator: "eq",
                                    showOperators: false
                                }
                            },
                            template: "#= kendo.toString(kendo.parseDate(dateFrom), 'dddd, dd/MM/yyyy HH:mm')# ",
                            aggregates: ["count"]
                        },
                        {
                            field: "endDate",
                            title: "Ημερομηνία Λήξης",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "eq",
                                    showOperators: false
                                }
                            },
                            template: "#= kendo.toString(kendo.parseDate(dateTo), 'dddd, dd/MM/yyyy HH:mm')#",
                            groupHeaderTemplate: "Ημερομηνία Λήξης: #= kendo.toString(kendo.parseDate(value), 'dddd, dd/MM/yyyy')# (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            hidden: true,
                            field: "endDateSort",
                            title: "Ημερομηνία Λήξης",
                            filterable: {
                                cell: {
                                    operator: "eq",
                                    showOperators: false
                                }
                            },
                            template: "#= kendo.toString(kendo.parseDate(dateTo), 'dddd, dd/MM/yyyy HH:mm')# ",
                            aggregates: ["count"]
                        },
                        {
                            field: "eventCategoryType.eventType.name",
                            title: "Κατηγορία",
                            attributes: {
                                class: "#=eventCategoryType.eventCategory.id == '1' ? 'red' : 'green' #"
                            },
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            groupHeaderTemplate: "#= value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "title",
                            title: "Τίτλος",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            }
                        },
                        {
                            field: "description",
                            title: "Περιγραφή",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            }
                        }
                    ]
                },
                {
                    title: "Στοιχεία Ιατρικού Επισκέπτη",
                    columns: [
                        {
                            field: "employee.account.lastName",
                            title: "Επώνυμο",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            }
                        },
                        {
                            field: "employee.account.firstName",
                            title: "Όνομα",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            }
                        }
                    ]
                }
            ],
            toolbar: [
                { template: "<md-button class='md-raised md-mini md-primary' aria-label='collapseAll' ng-click='vm.collapseAll()'>Collapse All</md-button>" },
                { template: "<md-button class='md-raised md-mini md-primary' aria-label='expandAll' ng-click='vm.expandAll()'>Expand All</md-button>" },
                { name: "excel", template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                "<span class='k-icon k-i-excel'></span>Export</md-button></div>" }
            ],
            excel: {
                allPages: false,
                fileName: "Άλλα Συμβάντα.xlsx"
            },
            excelExport: function(e) {
                if(!vm.exportFlag){
                    e.sender.hideColumn("eventDate");
                    e.sender.showColumn("eventDateSort");
                    e.sender.hideColumn("endDate");
                    e.sender.showColumn("endDateSort");
                    e.preventDefault();
                    vm.exportFlag = true;
                    setTimeout(function () {
                        e.sender.saveAsExcel();
                    })
                } else {
                    e.sender.hideColumn("eventDateSort");
                    e.sender.showColumn("eventDate");
                    e.sender.hideColumn("endDateSort");
                    e.sender.showColumn("endDate");
                    vm.exportFlag = false;
                }
            },
            dataBound: function () { vm.masterFiltersEdited(); }
        };

        $timeout(function () {
            MasterFiltersService.clearFilterEvent()
        });
    }
})();
