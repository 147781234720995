/**
 * Created by Teo on 05/04/2019.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchasesAggregatedPerEmployeeController', PurchasesAggregatedPerEmployeeController);

    PurchasesAggregatedPerEmployeeController.$inject = ['$scope', '$filter', '$http', 'KendoGrid', 'CustomUtils', 'Alerts', '$timeout', 'KendoToolbar', 'CustomDataUtils'];

    function PurchasesAggregatedPerEmployeeController ($scope, $filter, $http, KendoGrid, CustomUtils, Alerts, $timeout, KendoToolbar, CustomDataUtils) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(50);
        vm.showProgress = false;
        vm.masterFilters = {};
        vm.mainGrid = {};

        vm.getData = function () {
            var cancel = false;
            var message = "";
            if (vm.masterFilters.from === null || vm.masterFilters.to === null ||
                vm.masterFilters.from === undefined || vm.masterFilters.to === undefined){
                cancel = true;
                message = "Επιλέξτε ημερομηνίες";
            }

            if (!cancel && (vm.masterFilters.from > vm.masterFilters.to)){
                cancel = true;
                message = "Η ημερομηνία 'Από' πρέπει να είναι μικρότερη από την ημερομηνία 'Εώς'"
            }

            if (cancel){
                Alerts.showAlert("Sigma SalesForce", message, "ΟΚ");
            }else {
                vm.mainGridOptions.dataSource.read();
            }
        };

        vm.masterFiltersOnClick = function(e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');
            if (e.id === "today") {
                KendoToolbar.clearDatePickers();
                $timeout(function () {
                    vm.masterFilters.from = date;
                    vm.masterFilters.to = date;
                });
            }
            else if (e.id === "week") {
                KendoToolbar.clearDatePickers();
                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6
                $timeout(function () {
                    vm.masterFilters.from = firstWeekDay;
                    vm.masterFilters.to = lastWeekDay;
                });
            }
            else if (e.id === "month") {
                KendoToolbar.clearDatePickers();
                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                $timeout(function () {
                    vm.masterFilters.from = firstMonthDay;
                    vm.masterFilters.to = lastMonthDay;
                });
            }
            else if (e.id === "year") {
                KendoToolbar.clearDatePickers();
                $timeout(function () {
                    vm.masterFilters.from = new Date(date.getFullYear(), 0, 1);
                    vm.masterFilters.to = new Date();
                });
            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup", id:"buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ], overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Από: </label><input class='inline' kendo-date-picker id='from' options='vm.fromOptions' k-ng-model='vm.masterFilters.from'/>", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Εώς: </label><input class='inline' kendo-date-picker id='to' options='vm.toOptions' k-ng-model='vm.masterFilters.to'/>", overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary' aria-label='search' ng-click='vm.getData();' ng-disabled='vm.disabled'>" +
                    "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<md-button class='md-raised md-mini md-primary k-button-icontext k-button-icontext k-grid-excel' aria-label='search' ng-click='vm.exportData();' ng-disabled='vm.disabled'>" +
                    "<span class='k-icon k-i-excel'></span>EXPORT</md-button>", overflow: "never", attributes: { class: "right" }
                }
            ]
        };

        vm.fromOptions = {
            change: function() {
                var start = angular.element("#from").data("kendoDatePicker");
                var end = angular.element("#to").data("kendoDatePicker");
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.from = value;
                } else {
                    vm.masterFilters.from = null;
                }

                var startDate = vm.masterFilters.from,
                    endDate = vm.masterFilters.to;

                if (startDate) {
                    startDate = new Date(startDate);
                    startDate.setDate(startDate.getDate());
                    end.min(startDate);

                    //set range to year
                    var maxDate = new Date(startDate);
                    maxDate = new Date(maxDate.setFullYear(startDate.getFullYear() + 1));
                    end.max(maxDate);
                } else if (endDate) {
                    start.max(new Date(endDate));
                    end.max(new Date(2099, 11, 31));
                } else {
                    endDate = new Date();
                    start.max(endDate);
                    end.min(endDate);
                    end.max(new Date(2099, 11, 31));
                }
            }
        };

        vm.toOptions = {
            change: function() {
                var start = angular.element("#from").data("kendoDatePicker");
                var end = angular.element("#to").data("kendoDatePicker");
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.to = value;
                } else {
                    vm.masterFilters.to = null;
                }

                var endDate = vm.masterFilters.to,
                    startDate = vm.masterFilters.from;

                if (endDate) {
                    endDate = new Date(endDate);
                    endDate.setDate(endDate.getDate());
                    start.max(endDate);

                    //set range to year
                    var minDate = new Date(endDate);
                    minDate = new Date(minDate.setFullYear(endDate.getFullYear() - 1));
                    start.min(minDate);
                } else if (startDate) {
                    end.min(new Date(startDate));
                    start.min(new Date(1900, 0, 1));
                } else {
                    endDate = new Date();
                    start.max(endDate);
                    end.min(endDate);
                    start.min(new Date(1900, 0, 1));
                }
            }
        };

        vm.mainGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function (o) {
                        vm.showProgress = true;
                        $http.get("/api/purchases/totals-per-employee?dateFrom=" + $filter('date')(vm.masterFilters.from, 'yyyy-MM-dd') + "&dateTo=" + $filter('date')(vm.masterFilters.to, 'yyyy-MM-dd'))
                            .success(function (response) {
                                o.success(response);
                                vm.showProgress = false;
                            })
                            .error(function (error) {
                                o.error(error);
                                vm.showProgress = false;
                                Alerts.showAlert("Sigma SalesForce", "Λυπούμαστε. Κάποιο σφάλμα προσέκυψε!", "ΟΚ");
                                console.log(error);
                            });
                    }
                },
                schema: {
                    model: {
                        id: "id",
                        fields: {
                            numberOfCustomers: { type: "number" },
                            numberOfPurchases: { type: "number" },
                            totalNetPrice: { type: "number" },
                            totalDiscountPrice: { type: "number" },
                            execItemQuantities: { type: "number" },
                            trueExecItemQuantities: { type: "number" },
                            totalPresentPrice: { type: "number" },
                            execPresentItemQuantities: { type: "number" },
                            trueExecPresentItemQuantities: { type: "number" },
                            totalSyfaNetPrice: { type: "number" },
                            execQuantitiesSyfa: { type: "number" },
                            trueExecQuantitiesSyfa: { type: "number" },
                            totalMhsyfaNetPrice: { type: "number" },
                            execQuantitiesMhSyfa: { type: "number" },
                            trurExecQuantitiesMhSyfa: { type: "number" },
                            totalIatrNetPrice: { type: "number" },
                            execQuantitiesIatr: { type: "number" },
                            trueExecQuantitiesIatr: { type: "number" },
                            totalCosmeticNetPrice: { type: "number" },
                            execQuantitiesCosmetics: { type: "number" },
                            trueExecQuantitiesCosmetics: { type: "number" },
                            totalFSupplementsNetPrice: { type: "number" },
                            execQuantitiesFSupplements: { type: "number" },
                            trueExecQuantitiesFSupplements: { type: "number" }
                        }
                    },
                    data: function (data) {
                        if (data != null && data !== undefined && data.length > 0){
                            angular.forEach(data, function (item) {
                                item.employee.fullName = item.employee.account.lastName + " " + item.employee.account.firstName;
                                if (item.numberOfCustomers === null) item.numberOfCustomers = 0;
                                if (item.numberOfPurchases === null) item.numberOfPurchases = 0;
                                if (item.totalNetPrice === null) item.totalNetPrice = 0;
                                if (item.totalDiscountPrice === null) item.totalDiscountPrice = 0;
                                if (item.execItemQuantities === null) item.execItemQuantities = 0;
                                if (item.trueExecItemQuantities === null) item.trueExecItemQuantities = 0;
                                if (item.totalPresentPrice === null) item.totalPresentPrice = 0;
                                if (item.execPresentItemQuantities === null) item.execPresentItemQuantities = 0;
                                if (item.trueExecPresentItemQuantities === null) item.trueExecPresentItemQuantities = 0;
                                if (item.totalSyfaNetPrice === null) item.totalSyfaNetPrice = 0;
                                if (item.execQuantitiesSyfa === null) item.execQuantitiesSyfa = 0;
                                if (item.trueExecQuantitiesSyfa === null) item.trueExecQuantitiesSyfa = 0;
                                if (item.totalMhsyfaNetPrice === null) item.totalMhsyfaNetPrice = 0;
                                if (item.execQuantitiesMhSyfa === null) item.execQuantitiesMhSyfa = 0;
                                if (item.trueExecQuantitiesMhSyfa === null) item.trueExecQuantitiesMhSyfa = 0;
                                if (item.totalIatrNetPrice === null) item.totalIatrNetPrice = 0;
                                if (item.execQuantitiesIatr === null) item.execQuantitiesIatr = 0;
                                if (item.trueExecQuantitiesIatr === null) item.trueExecQuantitiesIatr = 0;
                                if (item.totalCosmeticNetPrice === null) item.totalCosmeticNetPrice = 0;
                                if (item.execQuantitiesCosmetics === null) item.execQuantitiesCosmetics = 0;
                                if (item.trueExecQuantitiesCosmetics === null) item.trueExecQuantitiesCosmetics = 0;
                                if (item.totalFSupplementsNetPrice === null) item.totalFSupplementsNetPrice = 0;
                                if (item.execQuantitiesFSupplements === null) item.execQuantitiesFSupplements = 0;
                                if (item.trueExecQuantitiesFSupplements === null) item.trueExecQuantitiesFSupplements = 0;
                            });
                        }
                        return data;
                    }
                },
                aggregate: [
                    {field: "numberOfCustomers", aggregate: "sum"},
                    {field: "numberOfPurchases", aggregate: "sum"},
                    {field: "totalNetPrice", aggregate: "sum"},
                    {field: "totalDiscountPrice", aggregate: "sum"},
                    {field: "execItemQuantities", aggregate: "sum"},
                    {field: "trueExecItemQuantities", aggregate: "sum"},
                    {field: "totalPresentPrice", aggregate: "sum"},
                    {field: "execPresentItemQuantities", aggregate: "sum"},
                    {field: "trueExecPresentItemQuantities", aggregate: "sum"},
                    {field: "totalSyfaNetPrice", aggregate: "sum"},
                    {field: "execQuantitiesSyfa", aggregate: "sum"},
                    {field: "trueExecQuantitiesSyfa", aggregate: "sum"},
                    {field: "totalMhsyfaNetPrice", aggregate: "sum"},
                    {field: "execQuantitiesMhSyfa", aggregate: "sum"},
                    {field: "trueExecQuantitiesMhSyfa", aggregate: "sum"},
                    {field: "totalIatrNetPrice", aggregate: "sum"},
                    {field: "execQuantitiesIatr", aggregate: "sum"},
                    {field: "trueExecQuantitiesIatr", aggregate: "sum"},
                    {field: "totalCosmeticNetPrice", aggregate: "sum"},
                    {field: "execQuantitiesCosmetics", aggregate: "sum"},
                    {field: "trueExecQuantitiesCosmetics", aggregate: "sum"},
                    {field: "totalFSupplementsNetPrice", aggregate: "sum"},
                    {field: "execQuantitiesFSupplements", aggregate: "sum"},
                    {field: "trueExecQuantitiesFSupplements", aggregate: "sum"}
                ]
            }),
            sortable: true,
            groupable: false,
            filterable: false,
            resizable: true,
            scrollable: true,
            columns: [
                {
                    field: "employee.fullName",
                    title: "Υπάλληλος",
                    width: "250px",
                    footerTemplate: "Σύνολο"
                },
                {
                    field: "numberOfCustomers",
                    title: "Αριθμός Επαγγελματιών Υγείας",
                    width: "130px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "numberOfPurchases",
                    title: "Αριθμός Παραγγελιών",
                    width: "170px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "totalNetPrice",
                    title: "Τζίρος (€)",
                    width: "160px",
                    aggregates: ["sum"],
                    template: "#= (totalNetPrice == null) ? '' : kendo.format('{0:c}', totalNetPrice)#",
                    footerTemplate: "#= kendo.toString(sum, 'C') #"
                },
                {
                    field: "totalDiscountPrice",
                    title: "Συνολική Αξιακή Έκπτωση (€)",
                    width: "210px",
                    aggregates: ["sum"],
                    template: "#= (totalDiscountPrice == null) ? '' : kendo.format('{0:c}', totalDiscountPrice)#",
                    footerTemplate: "#=kendo.toString(sum, 'C')#"
                },
                {
                    field: "execItemQuantities",
                    title: "Ποσότητα Παραγγελίας (Τεμ)",
                    width: "230px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "trueExecItemQuantities",
                    title: "Εκτελ. Ποόσητητα Παραγγελίας (Τεμ)",
                    width: "250px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "totalPresentPrice",
                    title: "Συνολική Έκπτωση σε Δώρα (€)",
                    width: "230px",
                    aggregates: ["sum"],
                    template: "#= (totalPresentPrice == null) ? '' : kendo.format('{0:c}', totalPresentPrice)#",
                    footerTemplate: "#=kendo.toString(sum, 'C')#"
                },
                {
                    field: "execPresentItemQuantities",
                    title: "Ποσότητα Δώρων παραγγελίας (Τεμ)",
                    width: "245px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "trueExecPresentItemQuantities",
                    title: "Εκτελ. Ποσότητα Δώρων παραγγελίας (Τεμ)",
                    width: "290px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "totalSyfaNetPrice",
                    title: "Τζίρος ΣΥΦΑ (€)",
                    width: "120px",
                    aggregates: ["sum"],
                    template: "#= (totalSyfaNetPrice == null) ? '' : kendo.format('{0:c}', totalSyfaNetPrice)#",
                    footerTemplate: "#=kendo.toString(sum, 'C')#"
                },
                {
                    field: "execQuantitiesSyfa",
                    title: "Τεμάχια ΣΥΦΑ",
                    width: "120px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "trueExecQuantitiesSyfa",
                    title: "Εκτελ. Τεμάχια ΣΥΦΑ",
                    width: "150px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "totalMhsyfaNetPrice",
                    title: "Τζίρος ΜΗΣΥΦΑ (€)",
                    width: "140px",
                    aggregates: ["sum"],
                    template: "#= (totalMhsyfaNetPrice == null) ? '' : kendo.format('{0:c}', totalMhsyfaNetPrice)#",
                    footerTemplate: "#=kendo.toString(sum, 'C')#"
                },
                {
                    field: "execQuantitiesMhSyfa",
                    title: "Τεμάχια ΜΗΣΥΦΑ",
                    width: "130px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "trueExecQuantitiesMhSyfa",
                    title: "Εκτελ. Τεμάχια ΜΗΣΥΦΑ",
                    width: "170px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "totalIatrNetPrice",
                    title: "Τζίρος ΙΑΤΡ (€)",
                    width: "120px",
                    aggregates: ["sum"],
                    template: "#= (totalIatrNetPrice == null) ? '' : kendo.format('{0:c}', totalIatrNetPrice)#",
                    footerTemplate: "#=kendo.toString(sum, 'C')#"
                },
                {
                    field: "execQuantitiesIatr",
                    title: "Τεμάχια ΙΑΤΡ",
                    width: "120px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "trueExecQuantitiesIatr",
                    title: "Εκτελ. Τεμάχια ΙΑΤΡ",
                    width: "160px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "totalCosmeticNetPrice",
                    title: "Τζίρος COSMETIC (€)",
                    width: "160px",
                    aggregates: ["sum"],
                    template: "#= (totalCosmeticNetPrice == null) ? '' : kendo.format('{0:c}', totalCosmeticNetPrice)#",
                    footerTemplate: "#=kendo.toString(sum, 'C')#"
                },
                {
                    field: "execQuantitiesCosmetics",
                    title: "Τεμάχια COSMETIC",
                    width: "160px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "trueExecQuantitiesCosmetics",
                    title: "Εκτελ. Τεμάχια COSMETIC",
                    width: "200px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "totalFSupplementsNetPrice",
                    title: "Τζίρος F Supplements (€)",
                    width: "210px",
                    aggregates: ["sum"],
                    template: "#= (totalFSupplementsNetPrice == null) ? '' : kendo.format('{0:c}', totalFSupplementsNetPrice)#",
                    footerTemplate: "#=kendo.toString(sum, 'C')#"
                },
                {
                    field: "execQuantitiesFSupplements",
                    title: "Τεμάχια F Supplements",
                    width: "200px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "trueExecQuantitiesFSupplements",
                    title: "Εκτελ. Τεμάχια F Supplements",
                    width: "220px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                }
            ],
            excel: {
                allPages: true,
                fileName: "Σύνολα Παραγγελιών ανά Ιατρικό Επισκέπτη.xlsx"
            },
            excelExport: function (e) {
                var footerCells = $filter('filter')(e.workbook.sheets[0].rows, {type: "footer"}, true)[0].cells;
                for(var i = 1; i < footerCells.length; i++) {
                    if (footerCells[i].value.includes("€")) {
                        footerCells[i].value.replace(' €','');
                        footerCells[i].format = "#,##0.00 €";
                    }
                    footerCells[i].hAlign = "right";
                    CustomDataUtils.convertToNumberFormat(footerCells[i].value);
                }
            },
            dataBound: function () {
                $timeout(function() {
                    kendo.resize($("#mainGrid"));
                });
            },
            noRecords: {
                template: "Δεν βρέθηκαν εγγραφές"
            }
        };

        vm.clearDatePickers = function () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        };

        vm.exportData = function () {
            var grid = $("#mainGrid").data("kendoGrid");
            grid.saveAsExcel();
        };

        $(window).on("resize", function() {
            kendo.resize($("#mainGrid"));
        });

        // Click ok when screen opens
        $timeout(function () {
            $("#today").click();
        });
    }
})();
