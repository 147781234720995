(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminEmployeesController', AdminEmployeesController);

    AdminEmployeesController.$inject = ['$scope', '$state', 'Principal', '$filter', 'ParseLinks', 'KendoGrid', 'ApplicationParameters', 'KendoFilter','$http', 'CustomUtils'];

    function AdminEmployeesController ($scope, $state, Principal, $filter, ParseLinks, KendoGrid, ApplicationParameters, KendoFilter, $http, CustomUtils) {
        var vm = this;
        vm.principal = Principal;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(65);
        vm.pageHeading = 'Ιατρικοι Επισκεπτες';
        vm.north = 'ΒΟΡΕΙΑ ΕΛΛΑΔΑ';
        vm.south = 'ΝΟΤΙΑ ΕΛΛΑΔΑ';
        vm.westAndPeloponnese = 'ΔΥΤ. ΕΛΛΑΔΟΣ & ΠΕΛΟΠΟΝΝΗΣΟΥ';
        vm.selectedProductCategories = [];
        vm.purchaseNumberingRows = [];
        vm.isActive = true;

        var districtDataSource = new kendo.data.DataSource({
            data: [
                { id: 1, name: vm.north},
                { id: 2,  name: vm.south},
                { id: 3,  name: vm.westAndPeloponnese}
            ]
        });

        vm.usersComboBoxDataSource= new kendo.data.DataSource({
            transport: {
                read: function(o) {
                    var url = '/api/employees/users/not-mapped';
                    $http.get(url)
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        function productCategoriesDisplay(data, container) {
            if(data.productCategories) {
                return data.productCategories.map(function(elem) {
                    return elem.name
                }).join(', ');
            }
            return "";
        }

        function purchaseNumberingRowsDisplay(data, container) {
            if(data.purchaseNumberingRows) {
                return data.purchaseNumberingRows.map(function(elem) {
                    return elem.name
                }).join(', ');
            }
            return "";
        }

        vm.toolbarOptions = {
            items: [
                {
                    template: "<label>Ενεργός: </label><md-checkbox aria-label='isActive' ng-model='vm.isActive' ng-change='vm.onFilterChange()' class='checkbox-toolbar green inline'></md-checkbox>",
                    overflow: "never", attributes: {class: "left left-space"}
                }
            ]
        };

        vm.onFilterChange = function () { angular.element("#mainGrid").data("kendoGrid").dataSource.read(); };

        vm.gridColumns = [
            {
                title: "Στοιχεία Χρήστη",
                columns: [
                    {
                        field: "account.lastName",
                        title: "Επώνυμο",
                        attributes: {
                            class: "link-cell link-cell-color"
                        },
                        editor: function(container, options) {
                            angular.element('<select id="lastName" data-bind="value:account.lastName"></select>')
                                .appendTo(container)
                                .kendoComboBox({
                                    enable: false,
                                    dataSource: vm.usersComboBoxDataSource,
                                    dataTextField: "lastName"
                                });
                        },
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        },
                        width: 200
                    },
                    {
                        field: "account.firstName",
                        title: "Όνομα",
                        attributes: {
                            class: "link-cell link-cell-color"
                        },
                        editor: function(container, options) {
                            angular.element('<select id="firstName" data-bind="value:account.firstName"></select>')
                                .appendTo(container)
                                .kendoComboBox({
                                    enable: false,
                                    dataSource: vm.usersComboBoxDataSource,
                                    dataTextField: "firstName"
                                });
                        },
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        },
                        width: 200
                    },
                    {
                        field: "account.login",
                        title: "Όνομα σύνδεσης",
                        editor: function(container, options) {
                            angular.element('<select id="comboBox" data-bind="value:account.id"></select>')
                                .appendTo(container)
                                .kendoComboBox({
                                    dataSource: vm.usersComboBoxDataSource,
                                    filter: "startswith",
                                    change: function(e) {
                                        var index = e.sender.selectedIndex;
                                        $('#firstName').data('kendoComboBox').select(index);
                                        $('#lastName').data('kendoComboBox').select(index);
                                    },
                                    valuePrimitive: true,
                                    dataTextField: "login",
                                    dataValueField: "id",
                                    template: "<table><span width='100px'><strong>(${login}) </strong></span>" +
                                    "<span width='50px'>${firstName} </span>" +
                                    "<span width='50px'>${lastName}</span></table>"
                                });
                        },
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        },
                        width: 170
                    },
                    // {
                    //     field: "account.activated",
                    //     title: "Ενεργός",
                    //     filterable: {
                    //         cell: {
                    //             operator: "eqbool",
                    //             showOperators: false,
                    //             template: function (args) {
                    //                 args.element.kendoDropDownList({
                    //                     autoBind:false,
                    //                     dataTextField: "text",
                    //                     dataValueField: "value",
                    //                     dataSource: new kendo.data.DataSource({
                    //                         data: [{ text: "Ναι", value: "true" },
                    //                             { text: "Όχι", value: "false" }]
                    //                     }),
                    //                     valuePrimitive: true
                    //
                    //                 })
                    //             }
                    //         }
                    //     },
                    //     width: 133,
                    //     template: "#= (account.activated == '1') ? 'Ναι' : 'Όχι' #"
                    // }
                ]
            },
            {
                field: "phone",
                title: "Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 160
            },
            {
                field: "district",
                title: "Περιοχή",
                editor: function(container, options) {
                    if (options.model.district == 'north') {
                        options.model.district = { id:1, name:'ΒΟΡΕΙΑ ΕΛΛΑΔΑ' }
                    } else if (options.model.district == 'south'){
                        options.model.district = { id:2, name:'ΝΟΤΙΑ ΕΛΛΑΔΑ' }
                    } else if (options.model.district == 'westAndPeloponnese'){
                        options.model.district = { id:3, name:'ΔΥΤ. ΕΛΛΑΔΟΣ & ΠΕΛΟΠΟΝΝΗΣΟΥ' }
                    }

                    angular.element('<input data-bind="value: district.id" k-ng-model="district"/>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: districtDataSource,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                },
                template: "#= (district == 'north') ? 'ΒΟΡΕΙΑ ΕΛΛΑΔΑ' : (district == 'south') ? 'ΝΟΤΙΑ ΕΛΛΑΔΑ' : (district == 'westAndPeloponnese') ? 'ΔΥΤ. ΕΛΛΑΔΟΣ & ΠΕΛΟΠΟΝΝΗΣΟΥ' : '' #",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false,
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: vm.north, value: "north" },
                                        { text: vm.south, value: "south" },
                                        { text: vm.westAndPeloponnese, value: "westAndPeloponnese" }]
                                }),
                                valuePrimitive: true

                            })
                        }
                    }
                }
            },
            {
                field: "mobileVersion",
                title: "Mobile Έκδοση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 100
            },
            {
                field: "supervisor.account.fullName",
                title: "Προϊστάμενος",
                editor: function(container, options) {
                    vm.employeesComboBoxDataSource= new kendo.data.DataSource({
                        transport: {
                            read: function(o) {
                                var url = '/api/employees/available-supervisors';
                                $http.get(url, {params: {id: options.model.id}})
                                    .success(function (response) {
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        },
                        schema: {
                            parse: function (data) {
                                data.forEach( function (item) {
                                    item.fullName = item.account.lastName + " " + item.account.firstName;
                                });
                                return data;
                            }
                        }
                    });

                    angular.element('<select data-bind="value:supervisor.id"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: vm.employeesComboBoxDataSource,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "fullName",
                            dataValueField: "id"
                        });
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 300
            },
            {
                field: "productCategories.id",
                title: "Κατηγορίες Προϊόντων",
                filterable: {
                    cell: {
                        operator: "in",
                        showOperators: false,
                        template: function (args) {
                            args.element.removeAttr('data-bind').kendoMultiSelect({
                                dataSource: new kendo.data.DataSource({
                                    serverFiltering: true,
                                    transport: {
                                        read: function (o) {
                                            if (o.data.filter == undefined) {
                                                o.data.filter = {filters: []};
                                            }
                                            o.data.page = 1;
                                            o.data.pageSize = 10000;
                                            $http.get('/api/masterdata/product-categories',
                                                {params: o.data})
                                                .success(function (response, status, headers) {
                                                    response.content.unshift({id:"none", name :"(Κενό)"});
                                                    o.success(response.content);
                                                })
                                                .error(function (response) {
                                                    console.log(response);
                                                });
                                        }
                                    }
                                }),
                                valuePrimitive: true,
                                dataTextField: "name",
                                dataValueField: "id",
                                change: function () {
                                    vm.selectedProductCategories = this.value();
                                    vm.mainGridOptions.dataSource.read();
                                },
                                select: function (e) {
                                    var dataItem = this.dataSource.view()[e.item.index()];
                                    var values = this.value();

                                    if (dataItem.id === "none") {
                                        this.value("");
                                    } else if (values.indexOf("none") !== -1) {
                                        values = angular.element.grep(values, function (value) {
                                            return value !== "none";
                                        });
                                        this.value(values);
                                    }
                                }
                            })
                        }
                    }
                },
                template: productCategoriesDisplay,
                editor: function (container, options) {
                    angular.element('<select id="productCategories" multiple="multiple" data-bind="value: productCategories"/>')
                        .appendTo(container)
                        .kendoMultiSelect({
                            dataSource: new kendo.data.DataSource({
                                serverFiltering: true,
                                transport: {
                                    read: function (o) {
                                        if (o.data.filter == undefined) {
                                            o.data.filter = {filters: []};
                                        }

                                        o.data.page = 1;
                                        o.data.pageSize = 10000;
                                        $http.get('/api/masterdata/product-categories',
                                            {params: o.data})
                                            .success(function (response, status, headers) {
                                                o.success(response.content);
                                            })
                                            .error(function (response) {
                                                console.log(response);
                                            });
                                    }
                                }
                            }),
                            valuePrimitive: false,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                }
            },
            {
                field: "purchaseNumberingRows.id",
                title: "Σειρές Αρίθμησης",
                filterable: {
                    cell: {
                        operator: "in",
                        showOperators: false,
                        template: function (args) {
                            args.element.removeAttr('data-bind').kendoMultiSelect({
                                dataSource: new kendo.data.DataSource({
                                    serverFiltering: true,
                                    transport: {
                                        read: function (o) {
                                            if (o.data.filter == undefined) {
                                                o.data.filter = {filters: []};
                                            }
                                            o.data.page = 1;
                                            o.data.pageSize = 10000;
                                            $http.get('/api/purchase-numbering-rows/search',
                                                {params: o.data})
                                                .success(function (response, status, headers) {
                                                    response.content.unshift({id:"none", name :"(Κενό)"});
                                                    o.success(response.content);
                                                })
                                                .error(function (response) {
                                                    console.log(response);
                                                });
                                        }
                                    }
                                }),
                                valuePrimitive: true,
                                dataTextField: "name",
                                dataValueField: "id",
                                change: function () {
                                    vm.purchaseNumberingRows = this.value();
                                    vm.mainGridOptions.dataSource.read();
                                },
                                select: function (e) {
                                    var dataItem = this.dataSource.view()[e.item.index()];
                                    var values = this.value();

                                    if (dataItem.id === "none") {
                                        this.value("");
                                    } else if (values.indexOf("none") !== -1) {
                                        values = angular.element.grep(values, function (value) {
                                            return value !== "none";
                                        });
                                        this.value(values);
                                    }
                                }
                            })
                        }
                    }
                },
                template: purchaseNumberingRowsDisplay,
                editor: function (container, options) {
                    angular.element('<select id="purchaseNumberingRows" multiple="multiple" data-bind="value: purchaseNumberingRows"/>')
                        .appendTo(container)
                        .kendoMultiSelect({
                            dataSource: new kendo.data.DataSource({
                                serverFiltering: true,
                                transport: {
                                    read: function (o) {
                                        if (o.data.filter == undefined) {
                                            o.data.filter = {filters: []};
                                        }

                                        o.data.page = 1;
                                        o.data.pageSize = 10000;
                                        $http.get('/api/purchase-numbering-rows/search',
                                            {params: o.data})
                                            .success(function (response, status, headers) {
                                                o.success(response.content);
                                            })
                                            .error(function (response) {
                                                console.log(response);
                                            });
                                    }
                                }
                            }),
                            valuePrimitive: false,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                }
            },
            {
                field: "code",
                title: "Κωδικός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];

        vm.onAddEmployeeClick = function () {
            const grid = $("#mainGrid").data("kendoGrid");
            grid.addRow();
        };

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        if(vm.selectedProductCategories.length) {
                            o.data.filter.filters.push(
                                { "field": "productCategories.id", "operator": "in",
                                    "value": vm.selectedProductCategories.toString() }
                            );
                        }

                        if(vm.purchaseNumberingRows.length) {
                            o.data.filter.filters.push(
                                { "field": "purchaseNumberingRows.id", "operator": "in",
                                    "value": vm.purchaseNumberingRows.toString() }
                            );
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                {
                                    "field": "account.activated",
                                    "operator": "eqbool",
                                    "value": vm.isActive
                                }
                            ]
                        );

                        $http.get('/api/me/employees',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    create: function (o){

                        var data = o.data.models[0];

                        if (data.district.id == 1) {
                            data.district = 'north';
                        } else if (data.district.id == 2) {
                            data.district = 'south';
                        } else if (data.district.id == 3) {
                            data.district = 'westAndPeloponnese';
                        } else {
                            data.district = null;
                        }

                        if (!data.supervisor.id) {
                            data.supervisor = null;
                        }

                        $http.post('/api/employees', data)
                            .success(function (response) {
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    update: function (o){

                        var data = o.data.models[0];
                        data.account.authorities = [];

                        if(data.district) {
                            if (data.district.id == 1) {
                                data.district = 'north';
                            } else if (data.district.id == 2) {
                                data.district = 'south';
                            } else if (data.district.id == 3) {
                                data.district = 'westAndPeloponnese';
                            } else {
                                data.district = null;
                            }
                        }

                        if (!data.supervisor.id) {
                            data.supervisor = null;
                        }

                        $http.put('/api/employees', data)
                            .success(function (response) {
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (data, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        } else {
                            return data;
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        if(data != undefined) {
                            data.content.forEach(function(item){

                                if (!item.account) {
                                    item.account = {
                                        firstName: "",
                                        lastName: "",
                                        login: ""
                                    };
                                }

                                if (!item.district) {
                                    item.district = {};
                                }

                                if (!item.supervisor) {
                                    item.supervisor = {
                                        account: {fullName: ""}
                                    }
                                } else {
                                    if (item.supervisor.account.lastName && item.supervisor.account.firstName) {
                                        item.supervisor.account.fullName =
                                            item.supervisor.account.lastName + " " + item.supervisor.account.firstName;
                                    } else if (!item.supervisor.account.firstName) {
                                        item.supervisor.account.fullName = item.supervisor.account.lastName;
                                    } else if (!item.supervisor.account.lastName) {
                                        item.supervisor.account.fullName = item.supervisor.account.firstName;
                                    }
                                }
                            });
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: false },
                            district : { defaultValue: {} },
                            password: { type: 'password', editable: true, nullable: false, defaultValue: null },
                            supervisor: { defaultValue: { account: { defaultValue: {}} }},
                            account: { defaultValue: {} },
                            mobileVersion: {editable:false},
                            'account.activated':{
                                type: "boolean",
                                defaultValue: true,
                                parse: function(value) {
                                    return (value === "1" || value === "true" || value === true);

                                }
                            }
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 100
            }),
            dataBound: function() {
                var grid = $('#mainGrid').data('kendoGrid');

                ApplicationParameters.hasModule('Purchase_Pharmacies', false).then(function(response) {
                    if (response.data.content[0].value != 1) {
                        angular.forEach( grid.columns, function (item, index) {
                            if( item.field == "purchaseNumberingRows.id" ) {
                                grid.hideColumn(index);
                            }
                        });
                    }
                });
            },
            sortable: true,
            pageable: {
                messages: {
                    display: "{0} - {1} από {2} Ιατρικοί Επισκέπτες", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                    empty: "Δεν βρέθηκαν Ιατρικοί Επισκέπτες",
                    page: "Σελίδα",
                    allPages: "Όλα",
                    of: "από {0}", // {0} is total amount of pages.
                    itemsPerPage: "εγγραφές ανά σελίδα",
                    first: "Πήγαινε στη πρώτη σελίδα",
                    previous: "Πήγαινε στη τελευταία σελίδα",
                    next: "Πήγαινε στην επόμενη σελίδα",
                    last: "Πήγαινε στη τελευταία σελίδα",
                    refresh: "Ανανέωση"
                }
            },
            noRecords: {
                template: "Δεν βρέθηκαν Ιατρικοί Επισκέπτες"
            },
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: "cell",
            columns: vm.gridColumns,
            height: vm.contentHeight,
            change: onChange,
            edit: function(e) {
                ApplicationParameters.hasModule('Purchase_Pharmacies', false).then(function(response) {
                    if (response.data.content[0].value != 1) {
                        e.container.find("[data-container-for='purchaseNumberingRows.id']").remove();
                        e.container.find("[for='purchaseNumberingRows.id']").parent().remove();
                    }
                });
                if (e.model.isNew() == false) {
                    $("#comboBox").kendoComboBox({ enable: false });
                }

                KendoGrid.defaultEditPopup(this._editContainer);

                //fix css
                angular.element( "div[data-container-for='productCategories.id']" ).addClass("k-multi-custom");
                angular.element( "label[for='mobileVersion']" ).parent().next().addClass("k-text-custom");
            },
            editable: {
                mode: "popup"
            }
        };

        function onChange() {
            // Get selected cell
            var selected = this.select();
            // Get the row that the cell belongs to.
            var row = this.select().closest("tr");
            var cellIndex = selected[0].cellIndex;

            if(cellIndex === 0 || cellIndex === 1) {
                $state.go('userInfo', {id: this.dataItem(row).id});
            }
        }
    }
})();
