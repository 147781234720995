/**
 * Created by Kristy on 15/7/2016.
 */
(function(){
    angular
        .module('sigmasfApp')
        .directive('selectableGrid', selectableGrid);

    // Activate it by passing selectable-grid attribute to
    // the div which constructs the grid.
    selectableGrid.$inject = ['$compile'];

    function selectableGrid ($compile) {
        return {
            restrict: 'A',
            scope: true,
            controller: function ($scope, $filter) {
                var items = [];
                var kendoPageData = [];

                $scope.$watch("vm.statusChanged",function(newValue,oldValue) {
                    //This gets called when status changes.
                    //clear selected items
                    items = [];
                });

                $scope.toggleSelectAll = function(ev) {
                    var grid = $(ev.target).closest("[kendo-grid]").data("kendoGrid");
                    var me = this;

                    var dataSource = grid.dataSource;
                    var filters = dataSource.filter();
                    var query = new kendo.data.Query(dataSource.data());
                    var filteredData = query.data;

                    if(filters) {
                        if(filters.filters[0].operator !== "like" && filters.filters[0].operator !== "eqdate") {
                            filteredData = query.filter(filters).data;
                        }
                    }

                    angular.copy(filteredData, kendoPageData);

                    filteredData.forEach(function (item) {
                        item.selected = ev.target.checked;
                        var itemByUID = $(ev.target).closest("[kendo-grid]").find("tr[data-uid='" + item.uid + "']");
                        if(me.allSelected) {
                            itemByUID.addClass("k-state-selected");
                        } else {
                            itemByUID.removeClass("k-state-selected");
                        }
                    });

                    if(this.allSelected) {
                        angular.forEach(kendoPageData, function (kendoPageDataItem) {
                            var foundItemById = $filter('filter')(items, {id: kendoPageDataItem.id}, true)[0];
                            if ( !foundItemById ) items = items.concat(kendoPageDataItem);
                        });
                        grid.options.selectedItems(items);
                    } else {
                        items = items.filter(function(item) {
                            var foundItemById = $filter('filter')(kendoPageData, {id: item.id}, true)[0];
                            return kendoPageData.indexOf(foundItemById) === -1;
                        });
                        grid.options.selectedItems(items);
                    }
                };

                $scope.onSelectItem = function(ev, dataItem){
                    var grid;
                    if (ev.target) {
                        grid = $(ev.target).closest("[kendo-grid]").data("kendoGrid");
                    } else {
                        grid = ev;
                    }

                    var foundItemById = $filter('filter')(items, {id: dataItem.id}, true)[0];

                    if(items.indexOf(foundItemById) !== -1){
                        items.splice(items.indexOf(foundItemById), 1);
                        dataItem.selected = false;
                    }else{
                        items.push(dataItem);
                        dataItem.selected = true;
                    }

                    grid.options.selectedItems(items);
                };
            },
            link: function (scope, $element, $attrs) {
                scope.checkboxTitle = $attrs.id+"checkbox";
                var options = scope.$eval($attrs.kOptions);
                if (options && options.columns[0].name !== 'checkbox') {
                    options.columns.unshift({
                        name: "checkbox",
                        template: "<input type='checkbox' class='checkbox' ng-model='dataItem.selected' ng-click='onSelectItem($event, dataItem)'/>",
                        title: "<label>Επιλογή <br>όλων</label><input id='{{ checkboxTitle }}' type='checkbox' class='checkbox' ng-show='vm.massSelect' ng-model='this.allSelected' ng-click='toggleSelectAll($event)'/>",
                        width: 70
                    });
                }
            }
        };
    }
})();
