(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('TempCustomerSectorDeleteController',TempCustomerSectorDeleteController);

    TempCustomerSectorDeleteController.$inject = ['$uibModalInstance', 'entity', 'TempCustomerSector'];

    function TempCustomerSectorDeleteController($uibModalInstance, entity, TempCustomerSector) {
        var vm = this;
        vm.tempCustomerSector = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            TempCustomerSector.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
