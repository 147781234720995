(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MDSectorTypesController', MDSectorTypesController);

    MDSectorTypesController.$inject = ['$scope', 'KendoGrid', '$http', '$filter', 'KendoFilter', 'CustomUtils'];

    function MDSectorTypesController ($scope, KendoGrid, $http, $filter, KendoFilter, CustomUtils) {
        var vm = this;
        vm.pageHeading = $filter('translate')('masterData.sectorTypes.title');
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(65);

        vm.onAddSectorTypeClick = function () {
            const grid = $("#mainGrid").data("kendoGrid");
            grid.addRow();
        };

        vm.sectorTypeCategories = ["hcu", "beautyCenter", "pharmacy"];

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/masterdata/sector-types',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    create: function(o) {
                        var data = o.data.models[0];
                        $http.post('/api/sector-types', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    update: function(o) {
                        var data = o.data.models[0];
                        $http.put('/api/sector-types', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (data, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        } else {
                            return data;
                        }

                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            name: { editable: true, validation: { required: true, validation: { required: true, min: 3 } } },
                            sectorTypeCategory: { editable: true, validation: { required: true } },
                            isPrivate: { editable: true, type:"boolean" },
                            updatedAt: { editable: false }
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            sortable: true,
            pageable: {
                messages: {
                    display: "{0} - {1} από {2} Τύποι Μονάδων Υγείας", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                    empty: "Δεν βρέθηκαν Τύποι Μονάδων Υγείας",
                    page: "Σελίδα",
                    allPages: "Όλα",
                    of: "από {0}", // {0} is total amount of pages.
                    itemsPerPage: "εγγραφές ανά σελίδα",
                    first: "Πήγαινε στη πρώτη σελίδα",
                    previous: "Πήγαινε στη τελευταία σελίδα",
                    next: "Πήγαινε στην επόμενη σελίδα",
                    last: "Πήγαινε στη τελευταία σελίδα",
                    refresh: "Ανανέωση"
                }
            },
            noRecords: {
                template: "Δεν βρέθηκαν Τύποι Μονάδων Υγείας"
            },
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "name", title: "Όνομα",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "nameEn", title: "Όνομα (Αγγλικά)",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "sectorTypeCategory",
                    title: "Κατηγορία Τύπου Μ.Υ.",
                    editor: function(container, options) {
                        angular.element('<select id="sectorType-sectorTypeCategory" name="sectorTypeSectorTypeCategory" required data-required-msg="Επιλέξτε Κατηγορία Τύπου Μ.Υ." data-bind="value:sectorTypeCategory"></select>')
                            .appendTo(container)
                            .kendoDropDownList({
                                dataSource: vm.sectorTypeCategories
                            }).kendoValidator().data("kendoValidator");
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {template: '<md-checkbox class="checkbox-toolbar customers green inline"  aria-label="isPrivate" ng-model="dataItem.isPrivate" ' +
                    'ng-disabled="true"></md-checkbox>',
                    field: "isPrivate", title: "Ιδιωτικό",
                    editor: function(container,options){
                        angular.element('<md-checkbox ng-model="dataItem.isPrivate"  aria-label="isPrivate" ' +
                            'class="checkbox-toolbar customers green inline"></md-checkbox>')
                            .appendTo(container);
                    },
                    filterable: {
                        cell: {
                            operator: "eqbool",
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Ναι", value: "true" },
                                            { text: "Όχι", value: "false" }]
                                    }),
                                    valuePrimitive: true

                                })
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    command:[
                        {name:"edit", text: "Επεξεργασία"}
                    ],
                    text: "Επεξεργασία",
                    title: "&nbsp;"
                }
            ],
            height: vm.contentHeight,
            edit: function(e) {
                e.model.dirty = true;
                KendoGrid.defaultEditPopup(this._editContainer);
            },
            editable: "popup"
        };

    }
})();
