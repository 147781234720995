(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadDiscountDetailController', LeadDiscountDetailController);

    LeadDiscountDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'LeadDiscount', 'DiscountReason', 'DiscountRate'];

    function LeadDiscountDetailController($scope, $rootScope, $stateParams, entity, LeadDiscount, DiscountReason, DiscountRate) {
        var vm = this;
        vm.leadDiscount = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:leadDiscountUpdate', function(event, result) {
            vm.leadDiscount = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
