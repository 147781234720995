/**
 * Created by Teo on 18/04/2019.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchasesAggregatedPerProductController', PurchasesAggregatedPerProductController);

    PurchasesAggregatedPerProductController.$inject = ['$http', 'KendoGrid', 'KendoService', 'CustomUtils', 'Alerts', '$timeout', 'pharmacyVisitors', 'productCategories', '$filter', 'CustomDataUtils', 'accountingDeps', 'purchaseProducts'];

    function PurchasesAggregatedPerProductController ($http, KendoGrid, KendoService, CustomUtils, Alerts, $timeout, pharmacyVisitors, productCategories, $filter, CustomDataUtils, accountingDeps, purchaseProducts) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(55);
        vm.showProgress = false;

        vm.masterFilters = {};
        vm.selectedEmployeeId = null;
        vm.selectedProductCategoryId = null;
        vm.selectedProduct = null;

        Array.prototype.push.apply(pharmacyVisitors, accountingDeps);
        vm.employeeOptions = {
            dataSource: {
                data: pharmacyVisitors
            },
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "fullName",
            dataValueField: "id",
            placeholder: "ΟΛΟΙ"
        };

        vm.productCategoryOptions = {
            dataSource: {
                data: productCategories
            },
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id",
            clearButton: false,
            placeholder: "ΟΛΑ",
            change: function (e) {
                var productComboBox = $("#productSelect").data("kendoComboBox");
                if (this.dataItem() === undefined || this.dataItem() === null){
                    productComboBox.enable(true);
                }else {
                    productComboBox.value(null);
                    productComboBox.enable(false);
                }
            }
        };

        vm.productsOptions = {
            dataSource: purchaseProducts,
            filter: "contains",
            dataTextField: "name",
            dataValueField: "id",
            clearButton: true,
            placeholder: "ΟΛΑ",
            change: function (e) {
                var productCategoryComboBox = $("#productCategorySelect").data("kendoComboBox");
                if (this.dataItem() === undefined || this.dataItem() === null){
                    productCategoryComboBox.enable(true);
                }else {
                    productCategoryComboBox.value(null);
                    productCategoryComboBox.enable(false);
                }
            }
        };

        vm.fromOptions = {
            change: function() {
                var start = angular.element("#from").data("kendoDatePicker");
                var end = angular.element("#to").data("kendoDatePicker");
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.from = value;
                } else {
                    vm.masterFilters.from = null;
                }

                var startDate = vm.masterFilters.from,
                    endDate = vm.masterFilters.to;

                if (startDate) {
                    startDate = new Date(startDate);
                    startDate.setDate(startDate.getDate());
                    end.min(startDate);

                    //set range to year
                    var maxDate = new Date(startDate);
                    maxDate = new Date(maxDate.setFullYear(startDate.getFullYear() + 1));
                    end.max(maxDate);
                } else if (endDate) {
                    start.max(new Date(endDate));
                    end.max(new Date(2099, 11, 31));
                } else {
                    endDate = new Date();
                    start.max(endDate);
                    end.min(endDate);
                    end.max(new Date(2099, 11, 31));
                }
            }
        };

        vm.toOptions = {
            change: function() {
                var start = angular.element("#from").data("kendoDatePicker");
                var end = angular.element("#to").data("kendoDatePicker");
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.to = value;
                } else {
                    vm.masterFilters.to = null;
                }

                var endDate = vm.masterFilters.to,
                    startDate = vm.masterFilters.from;

                if (endDate) {
                    endDate = new Date(endDate);
                    endDate.setDate(endDate.getDate());
                    start.max(endDate);

                    //set range to year
                    var minDate = new Date(endDate);
                    minDate = new Date(minDate.setFullYear(endDate.getFullYear() - 1));
                    start.min(minDate);
                } else if (startDate) {
                    end.min(new Date(startDate));
                    start.min(new Date(1900, 0, 1));
                } else {
                    endDate = new Date();
                    start.max(endDate);
                    end.min(endDate);
                    start.min(new Date(1900, 0, 1));
                }
            }
        };

        vm.mainGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function (o) {
                        vm.showProgress = true;
                        $http.get(constructUrl())
                            .success(function (response) {
                                o.success(response);
                                vm.showProgress = false;
                            })
                            .error(function (error) {
                                o.error(error);
                                vm.showProgress = false;
                                Alerts.showAlert("Sigma SalesForce", "Λυπούμαστε. Κάποιο σφάλμα προσέκυψε!", "ΟΚ");
                                console.log(error);
                            });
                    }
                },
                schema: {
                    model: {
                        id: "id",
                        fields: {
                            totalPrice: { type: "number" },
                            totalQuantities: { type: "number" },
                            totalExecQuantities: { type: "number" },
                            totalSyfaNetPrice: { type: "number" },
                            execQuantitiesSyfa: { type: "number" },
                            trueExecQuantitiesSyfa: { type: "number" },
                            totalMhsyfaNetPrice: { type: "number" },
                            execQuantitiesMhSyfa: { type: "number" },
                            trueExecQuantitiesMhSyfa: { type: "number" },
                            totalIatrNetPrice: { type: "number" },
                            execQuantitiesIatr: { type: "number" },
                            trueExecQuantitiesIatr: { type: "number" },
                            totalCosmeticNetPrice: { type: "number" },
                            execQuantitiesCosmetics: { type: "number" },
                            trueExecQuantitiesCosmetics: { type: "number" },
                            totalFSupplementsNetPrice: { type: "number" },
                            execQuantitiesFSupplements: { type: "number" },
                            trueExecQuantitiesFSupplements: { type: "number" }
                        }
                    },
                    data: function (data) {
                        if (data != null && data !== undefined && data.length > 0){
                            angular.forEach(data, function (item) {
                                if (!item.product) item.product = {};
                                if (item.totalSyfaNetPrice === null) item.totalSyfaNetPrice = 0;
                                if (item.execQuantitiesSyfa === null) item.execQuantitiesSyfa = 0;
                                if (item.trueExecQuantitiesSyfa === null) item.trueExecQuantitiesSyfa = 0;
                                if (item.totalMhsyfaNetPrice === null) item.totalMhsyfaNetPrice = 0;
                                if (item.execQuantitiesMhSyfa === null) item.execQuantitiesMhSyfa = 0;
                                if (item.trueExecQuantitiesMhSyfa === null) item.trueExecQuantitiesMhSyfa = 0;
                                if (item.totalIatrNetPrice === null) item.totalIatrNetPrice = 0;
                                if (item.execQuantitiesIatr === null) item.execQuantitiesIatr = 0;
                                if (item.trueExecQuantitiesIatr === null) item.trueExecQuantitiesIatr = 0;
                                if (item.totalCosmeticNetPrice === null) item.totalCosmeticNetPrice = 0;
                                if (item.execQuantitiesCosmetics === null) item.execQuantitiesCosmetics = 0;
                                if (item.trueExecQuantitiesCosmetics === null) item.trueExecQuantitiesCosmetics = 0;
                                if (item.totalFSupplementsNetPrice === null) item.totalFSupplementsNetPrice = 0;
                                if (item.execQuantitiesFSupplements === null) item.execQuantitiesFSupplements = 0;
                                if (item.trueExecQuantitiesFSupplements === null) item.trueExecQuantitiesFSupplements = 0;

                                // Extra columns
                                item.totalPrice = item.totalSyfaNetPrice + item.totalMhsyfaNetPrice + item.totalIatrNetPrice + item.totalCosmeticNetPrice + item.totalFSupplementsNetPrice;
                                item.totalQuantities = item.execQuantitiesSyfa + item.execQuantitiesMhSyfa + item.execQuantitiesIatr + item.execQuantitiesCosmetics + item.execQuantitiesFSupplements;
                                item.totalExecQuantities = item.trueExecQuantitiesSyfa + item.trueExecQuantitiesMhSyfa + item.trueExecQuantitiesIatr + item.trueExecQuantitiesCosmetics + item.trueExecQuantitiesFSupplements;
                            });
                        }

                        return data;
                    }
                },
                aggregate: [
                    {field: "totalPrice", aggregate: "sum"},
                    {field: "totalQuantities", aggregate: "sum"},
                    {field: "totalExecQuantities", aggregate: "sum"},
                    {field: "totalSyfaNetPrice", aggregate: "sum"},
                    {field: "execQuantitiesSyfa", aggregate: "sum"},
                    {field: "trueExecQuantitiesSyfa", aggregate: "sum"},
                    {field: "totalMhsyfaNetPrice", aggregate: "sum"},
                    {field: "execQuantitiesMhSyfa", aggregate: "sum"},
                    {field: "trueExecQuantitiesMhSyfa", aggregate: "sum"},
                    {field: "totalIatrNetPrice", aggregate: "sum"},
                    {field: "execQuantitiesIatr", aggregate: "sum"},
                    {field: "trueExecQuantitiesIatr", aggregate: "sum"},
                    {field: "totalCosmeticNetPrice", aggregate: "sum"},
                    {field: "execQuantitiesCosmetics", aggregate: "sum"},
                    {field: "trueExecQuantitiesCosmetics", aggregate: "sum"},
                    {field: "totalFSupplementsNetPrice", aggregate: "sum"},
                    {field: "execQuantitiesFSupplements", aggregate: "sum"},
                    {field: "trueExecQuantitiesFSupplements", aggregate: "sum"}
                ]
            }),
            sortable: true,
            groupable: false,
            filterable: false,
            resizable: true,
            scrollable: true,
            columns: [
                {
                    field: "product.name",
                    title: "Είδος",
                    width: "340px"
                },
                {
                    field: "totalPrice",
                    title: "Σύνολο Τζίρου",
                    width: "130px",
                    aggregates: ["sum"],
                    template: "#= kendo.format('{0:c}', totalPrice) #",
                    footerTemplate: "#=kendo.toString(sum, 'C')#"
                },
                {
                    field: "totalQuantities",
                    title: "Ποσότητα Παραγγελίας (Τεμ)",
                    width: "200px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "totalExecQuantities",
                    title: "Εκτελ. Ποσότητα Παραγγελίας (Τεμ)",
                    width: "245px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "totalSyfaNetPrice",
                    title: "Τζίρος ΣΥΦΑ",
                    width: "110px",
                    aggregates: ["sum"],
                    template: "#= kendo.format('{0:c}', totalSyfaNetPrice) #",
                    footerTemplate: "#=kendo.toString(sum, 'C')#"
                },
                {
                    field: "execQuantitiesSyfa",
                    title: "Τεμ. ΣΥΦΑ",
                    width: "90px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "trueExecQuantitiesSyfa",
                    title: "Εκτελ. Τεμ. ΣΥΦΑ",
                    width: "130px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "totalMhsyfaNetPrice",
                    title: "Τζίρος ΜΗΣΥΦΑ",
                    width: "120px",
                    aggregates: ["sum"],
                    template: "#= kendo.format('{0:c}', totalMhsyfaNetPrice) #",
                    footerTemplate: "#=kendo.toString(sum, 'C')#"
                },
                {
                    field: "execQuantitiesMhSyfa",
                    title: "Τεμ. ΜΗΣΥΦΑ",
                    width: "110px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "trueExecQuantitiesMhSyfa",
                    title: "Εκτελ. Τεμ. ΜΗΣΥΦΑ (Τεμ)",
                    width: "200px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "totalIatrNetPrice",
                    title: "Τζίρος ΙΑΤΡ",
                    width: "110px",
                    aggregates: ["sum"],
                    template: "#= kendo.format('{0:c}', totalIatrNetPrice) #",
                    footerTemplate: "#=kendo.toString(sum, 'C')#"
                },
                {
                    field: "execQuantitiesIatr",
                    title: "Τεμ. ΙΑΤΡ",
                    width: "90px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "trueExecQuantitiesIatr",
                    title: "Εκτελ. Τεμ. ΙΑΤΡ",
                    width: "130px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "totalCosmeticNetPrice",
                    title: "Τζίρος COSMETICS",
                    width: "150px",
                    aggregates: ["sum"],
                    template: "#= kendo.format('{0:c}', totalCosmeticNetPrice) #",
                    footerTemplate: "#=kendo.toString(sum, 'C')#"
                },
                {
                    field: "execQuantitiesCosmetics",
                    title: "Τεμ. COSMETICS",
                    width: "130px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "trueExecQuantitiesCosmetics",
                    title: "Εκτελ. Τεμ. COSMETICS",
                    width: "180px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "totalFSupplementsNetPrice",
                    title: "Τζίρος F Supplements",
                    width: "160px",
                    aggregates: ["sum"],
                    template: "#= kendo.format('{0:c}', totalFSupplementsNetPrice) #",
                    footerTemplate: "#=kendo.toString(sum, 'C')#"
                },
                {
                    field: "execQuantitiesFSupplements",
                    title: "Τεμ. F Supplements",
                    width: "150px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                },
                {
                    field: "trueExecQuantitiesFSupplements",
                    title: "Εκτελ. Τεμ. F Supplements",
                    width: "200px",
                    aggregates: ["sum"],
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#"
                }
            ],
            excel: {
                allPages: true,
                fileName: "Σύνολα Ειδών.xlsx"
            },
            excelExport: function (e) {
                var footerCells = $filter('filter')(e.workbook.sheets[0].rows, {type: "footer"}, true)[0].cells;
                for(var i = 1; i < footerCells.length; i++) {
                    if (footerCells[i].value.includes("€")) {
                        footerCells[i].value.replace(' €','');
                        footerCells[i].format = "#,##0.00 €";
                    }
                    footerCells[i].hAlign = "right";
                    CustomDataUtils.convertToNumberFormat(footerCells[i].value);
                }
            },
            dataBound: function () {
                $timeout(function() {
                    kendo.resize($("#mainGrid"));
                });
            },
            noRecords: {
                template: "Δεν βρέθηκαν εγγραφές"
            }
        };

        vm.exportData = function () {
            var grid = $("#mainGrid").data("kendoGrid");
            grid.saveAsExcel();
        };

        vm.getData = function () {
            var cancel = false;
            var message = "";
            if (vm.masterFilters.from === null || vm.masterFilters.to === null ||
                vm.masterFilters.from === undefined || vm.masterFilters.to === undefined){
                cancel = true;
                message = "Επιλέξτε ημερομηνίες";
            }

            if (!cancel && (vm.masterFilters.from > vm.masterFilters.to)){
                cancel = true;
                message = "Η ημερομηνία 'Από' πρέπει να είναι μικρότερη από την ημερομηνία 'Εώς'"
            }

            if (cancel){
                Alerts.showAlert("Sigma SalesForce", message, "ΟΚ");
            }else {
                vm.mainGridOptions.dataSource.read();
            }
        };

        vm.clearFilters = function () {
            // $("#today").click();
            var employeeComboBox = $("#employeeSelect").data("kendoComboBox");
            employeeComboBox.value(null);
            vm.selectedEmployeeId = null;
            var productCategoryComboBox = $("#productCategorySelect").data("kendoComboBox");
            productCategoryComboBox.value(null);
            productCategoryComboBox.enable(true);
            vm.selectedProductCategoryId = null;
            var productComboBox = $("#productSelect").data("kendoComboBox");
            productComboBox.value(null);
            productComboBox.enable(true);
            vm.selectedProduct = null;
        };

        vm.clearDatePickers = function () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        };

        vm.masterFiltersOnClick = function (e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id === "today") {
                vm.clearDatePickers();

                $timeout(function (){
                    vm.masterFilters.from = date;
                    vm.masterFilters.to = date;
                });
            } else if (e.id === "week") {
                vm.clearDatePickers();

                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6

                $timeout(function (){
                    vm.masterFilters.from = firstWeekDay;
                    vm.masterFilters.to = lastWeekDay;
                });
            } else if (e.id === "month") {
                vm.clearDatePickers();

                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                $timeout(function (){
                    vm.masterFilters.from = firstMonthDay;
                    vm.masterFilters.to = lastMonthDay;
                });
            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup", id:"buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ], overflow: "never", attributes: { class: "left" }
                },
                {
                    type: "separator", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Από: </label><input style='width: 100px;' kendo-date-picker id='from' options='vm.fromOptions' k-ng-model='vm.masterFilters.from'/>", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Εώς: </label><input style='width: 100px;' kendo-date-picker id='to' options='vm.toOptions' k-ng-model='vm.masterFilters.to'/>", overflow: "never", attributes: { class: "left" }
                },
                {
                    type: "separator", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Ιατρικός Επισκέπτης: </label><select style='width: 210px; margin-right: 2px' name='employeeSelect' id='employeeSelect' kendo-combo-box k-ng-model='vm.selectedEmployeeId' k-options='vm.employeeOptions'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    type: "separator", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Κατ. Ειδών: </label><select style='width: 100px; margin-right: 2px' name='productCategorySelect' id='productCategorySelect' kendo-combo-box k-ng-model='vm.selectedProductCategoryId' k-options='vm.productCategoryOptions'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    type: "separator", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Είδος: </label><select style='width: 440px; margin-right: 2px' name='productSelect' id='productSelect' kendo-combo-box k-ng-model='vm.selectedProduct' k-options='vm.productsOptions'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    type: "separator", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary' aria-label='search' ng-click='vm.getData();' style='margin-left: 2px; margin-right: 2px;'>" +
                    "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>", overflow: "never", attributes: { class: "left" }
                },
                /*{
                    template: "<md-button class='md-icon-button' aria-label='search' ng-click='vm.clearFilters();' style='padding: 2px 0 0 0; margin: 2px 0 0 0;'>" +
                    "<md-icon class='clear-red'></md-icon><md-tooltip md-direction='top'>Εκκαθάριση φίλτρων</md-tooltip></md-button>", overflow: "never", attributes: { class: "left" }
                },*/
                {
                    template: "<md-button class='md-raised md-mini md-primary k-button-icontext k-button-icontext k-grid-excel' aria-label='search' ng-click='vm.exportData();' style='margin-left: 2px; margin-right: 2px;'>" +
                    "<span class='k-icon k-i-excel'></span>EXPORT</md-button>", overflow: "never", attributes: { class: "left" }
                }
            ]
        };

        function constructUrl() {
            var url = "/api/purchases/totals-per-product";
            url += "?dateFrom=" + $filter('date')(vm.masterFilters.from, 'yyyy-MM-dd');
            url += "&dateTo=" + $filter('date')(vm.masterFilters.to, 'yyyy-MM-dd');
            if (vm.selectedEmployeeId !== null){
                url += "&employeeId=" + vm.selectedEmployeeId;
            }
            if (vm.selectedProduct !== null){
                url += "&productId=" + vm.selectedProduct.id;
            }
            if (vm.selectedProductCategoryId !== null){
                url += "&productCategoryId=" + vm.selectedProductCategoryId;
            }

            return url;
        }

        $(window).on("resize", function() {
            kendo.resize($("#mainGrid"));
        });

        // Click ok when screen opens
        $timeout(function () {
            $("#today").click();
        });
    }
})();
