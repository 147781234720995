(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ChangeFieldDeleteController',ChangeFieldDeleteController);

    ChangeFieldDeleteController.$inject = ['$uibModalInstance', 'entity', 'ChangeField'];

    function ChangeFieldDeleteController($uibModalInstance, entity, ChangeField) {
        var vm = this;
        vm.changeField = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            ChangeField.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
