(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('SectorSearch', SectorSearch);

    SectorSearch.$inject = ['$resource'];

    function SectorSearch($resource) {
        var resourceUrl =  'api/_search/sectors/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
