/**
 * Created by Kristy on 25/2/2020.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MedReportApprovalsHistoryController', MedReportApprovalsHistoryController);

    MedReportApprovalsHistoryController.$inject = ['$http','$state', '$stateParams','$filter', '$timeout', 'KendoFilter', 'CustomUtils'];

    function MedReportApprovalsHistoryController ($http, $state, $stateParams, $filter, $timeout, KendoFilter, CustomUtils ) {
        var vm = this;

        vm.historyGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "isActive", "operator": "eqbool", "value": true }
                            ]
                        );

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "status", "operator": "eq",  "value": "approved"}
                            ]
                        );

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        $http.get('api/me/med-reports',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function () {});
                    },
                    parameterMap: function (options, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            fromDate: { type: 'date'},
                            toDate: { type: 'date'},
                            checkDigit1: { type: 'number'},
                            checkDigit2: { type: 'number'}
                        }
                    }
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                pageSize: 50
            }),
            height: CustomUtils.getHeightPixelsFromPercentage(60),
            sortable: true,
            pageable: true,
            selectable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "fromDate",
                    title: "Μήνας",
                    template: "#= fromDate == null ? '' : kendo.toString(kendo.parseDate(fromDate), 'MMMM')#",
                    filterable: false, width: 250
                },
                {
                    field: "toDate",
                    title: "Έτος",
                    template: "#= toDate == null ? '' : kendo.toString(kendo.parseDate(toDate), 'yyyy')#",
                    filterable: false, width: 230
                },
                {
                    field: "checkDigit1", title: "Check Digit",
                    filterable: false,
                    width: 230
                },
                {
                    field: "checkDigit2", title: "Check Digit 2",
                    filterable: false,
                    width: 230
                },
                {
                    field: "code", title: "Κωδικός", width: 300,
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:230
                },
                {field: "employee.account.firstName", title: "Όνομα Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:230
                }
            ],
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.pendingGridOptions.dataSource.total()}}</label></div>", overflow: "never" }
            ]
        };
    }
})();
