/**
 * Created by Maria on 26/5/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('sectors', {
                parent: 'app',
                url: '/sectors',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUPERVISOR', 'ROLE_ACCOUNTING_DEP', 'ROLE_REC_ADMINISTRATOR', 'ROLE_REC_FRONT_DESK']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/sectors/sectors.html',
                        controller: 'SectorsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('sectorView', {
                parent: 'app',
                url: '/sectors/search?id:sectorId',
                data: {
                    authorities: ['ROLE_ADMIN','ROLE_SUPERVISOR', 'ROLE_ACCOUNTING_DEP', 'ROLE_REC_ADMINISTRATOR', 'ROLE_REC_FRONT_DESK']
                   //pageTitle: 'sigmaCrmApp.customer.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/sectors/sectorView.html',
                        controller: 'SectorViewController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    entity: ['$stateParams', 'Sector', function($stateParams, Sector) {
                        return Sector.get({id : $stateParams.id});
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
