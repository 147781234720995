/**
 * Created by Kristy on 12/9/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .directive('favouriteFiltersTitleCard', favouriteFiltersTitleCard);

    function favouriteFiltersTitleCard () {

        return {
            restrict: 'A',
            template: "<md-card md-theme=\"default\" md-theme-watch>\n" +
            "    <md-card-title>\n" +
            "        <md-card-title-text>\n" +
            "            <span class=\"md-headline\">{{ vm.pageHeading }}\n" +
            "                <span ng-if=\"vm.selectedFilter.name\"> /\n" +
            "                    <span class=\"fav-filter-title\"> {{vm.selectedFilter.name}} <!--alert alert-info-->\n" +
            "                        <span ng-if=\"vm.selectedFilter.name && vm.isEdited\" class=\"filter-edited-item\"><span>Edited</span></span>\n" +
            "\n" +
            "                        <div ng-if=\"vm.selectedFilter.name && vm.isEdited\" class=\"btn-group\" style=\"margin-left: 10px;\">\n" +
            "                            <button ng-click=\"vm.saveFilter(vm.selectedFilter)\" ng-disabled=\"vm.disabled\" type=\"button\" class=\"btn btn-default\">Αποθήκευση</button>\n" +
            "                            <button ng-disabled=\"vm.disabled\" type=\"button\" class=\"btn btn-default dropdown-toggle\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n" +
            "                                <span class=\"caret\"></span>\n" +
            "                                <span class=\"sr-only\"></span>\n" +
            "                            </button>\n" +
            "                            <ul class=\"dropdown-menu fix-dropdown-menu\">\n" +
            "                                <li><a ng-click=\"vm.window.open().center()\">Αποθήκευση ως</a></li>\n" +
            "                            </ul>\n" +
            "                        </div>\n" +
            "                        <button ng-if=\"vm.selectedFilter.name && !vm.isEdited\" ng-click=\"vm.window.open().center()\" ng-disabled=\"vm.disabled\"\n" +
            "                                type=\"button\" class=\"btn btn-default\">Αποθήκευση ως</button>\n" +
            "                    </span>\n" +
            "                </span>\n" +
            "            </span>\n" +
            "        </md-card-title-text>\n" +
            "\n" +
            "        <md-button ng-if=\"!vm.selectedFilter.name\" ng-disabled=\"vm.disabled\" class=\"next-button-toolbar md-raised md-mini\" aria-label=\"savefilter\" ng-click=\"vm.window.open().center()\">\n" +
            "            <md-icon class=\"material-icons\">filter_list</md-icon>Αποθήκευση Φίλτρου</md-button>\n" +
            "\n" +
            "        <md-button class=\"next-button-toolbar md-raised md-mini\" aria-label=\"savefilter\" ng-click=\"vm.clearAllOptions()\">\n" +
            "            <md-icon class=\"material-icons\">clear</md-icon>Καθαρισμός Φίλτρων</md-button>\n" +
            "\n" +
            "    </md-card-title>\n" +
            "</md-card>"
        };
    }
})();
