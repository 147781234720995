(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('EventCustomerSectorDialogController', EventCustomerSectorDialogController);

    EventCustomerSectorDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'EventCustomerSector', 'CommunicationType', 'CustomerSector', 'AgreementValuation', 'Event', 'Sector'];

    function EventCustomerSectorDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, EventCustomerSector, CommunicationType, CustomerSector, AgreementValuation, Event, Sector) {
        var vm = this;
        vm.eventCustomerSector = entity;
        vm.communicationtypes = CommunicationType.query();
        vm.customersectors = CustomerSector.query();
        vm.agreementvaluations = AgreementValuation.query();
        vm.events = Event.query();
        vm.sectors = Sector.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:eventCustomerSectorUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.eventCustomerSector.id !== null) {
                EventCustomerSector.update(vm.eventCustomerSector, onSaveSuccess, onSaveError);
            } else {
                EventCustomerSector.save(vm.eventCustomerSector, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
