/**
 * Created by Kristy on 12/4/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('KendoNotifications', KendoNotifications);

    KendoNotifications.$inject = ['$filter'];

    function KendoNotifications ($filter) {

        var service = {
            onShow : onShow,
            onSaveSuccess : onSaveSuccess,
            onSaveError : onSaveError,
            onSave : onSave,
            onDeleteSuccess : onDeleteSuccess,
            onDeleteError : onDeleteError,
            showSuccessNotification : showSuccessNotification,
            showErrorNotification : showErrorNotification
        };

        return service;

        function onShow(e) {
            if (e.sender.getNotifications().length == 1) {
                var element = e.element.parent(),
                    eWidth = element.width(),
                    eHeight = element.height(),
                    wWidth = $(window).width(),
                    wHeight = $(window).height(),
                    newTop, newLeft;

                newLeft = Math.floor(wWidth / 2 - eWidth / 2);
                newTop = Math.floor(wHeight / 2 - eHeight / 2);

                e.element.parent().css({top: newTop, left: newLeft, zIndex: 22222});
            }
        }

        function onSaveSuccess( notification ) {
            notification.show({
                message: "Η ενημέρωση των στοιχείων ήταν επιτυχής!"
            }, "success");
        }

        function onSaveError( notification ) {
            notification.show({
                message: "Η ενημέρωση των στοιχείων απέτυχε!"
            }, "error");
        }

        function onSave( notification, message, status ) {
            notification.show({
                message: message
            }, status);
        }

        function onDeleteSuccess( notification ) {
            notification.show({
                message: "Η εγγραφή διαγράφηκε με επιτυχία!"
            }, "success");
        }

        function onDeleteError( notification ) {
            notification.show({
                message: "Η διαγραφή της εγγραφής απέτυχε!"
            }, "error");
        }

        function showSuccessNotification (notification, message){
            notification.show({
                message: message
            }, "success");
        }

        function showErrorNotification (notification, message){
            notification.show({
                message: message
            }, "error");
        }
    }

})();
