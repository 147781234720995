(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('DiscountRateDetailController', DiscountRateDetailController);

    DiscountRateDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'DiscountRate'];

    function DiscountRateDetailController($scope, $rootScope, $stateParams, entity, DiscountRate) {
        var vm = this;
        vm.discountRate = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:discountRateUpdate', function(event, result) {
            vm.discountRate = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
