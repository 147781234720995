/**
 * Recreated by Teo on 04/09/2019.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminEmployeeVisitsAllController', AdminEmployeeVisitsAllController);

    AdminEmployeeVisitsAllController.$inject = ['$http', 'KendoGrid', 'KendoService', 'CustomUtils', 'Alerts', '$timeout', '$filter', 'CustomDataUtils', 'KendoFilter', 'healthVisitors', 'customersView'];

    function AdminEmployeeVisitsAllController ($http, KendoGrid, KendoService, CustomUtils, Alerts, $timeout, $filter, CustomDataUtils, KendoFilter, healthVisitors, customersView) {
        var vm = this;
        vm.pageHeading = 'Εργαλείο Απολογισμού Επισκέψεων ανά Ιατρικό Επισκέπτη';
        vm.showProgress = false;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(58);
        vm.masterFilters = {};
        vm.customerView = customersView !== null && customersView.data !== null && customersView.data.content !== null ? customersView.data.content[0].value : null;
        vm.selectedEmployeeId = null;
        vm.selectedSoftwares = [];
        vm.isActive = true;
        vm.myTarget = true;
        var filterTemplate = function (args) {
            KendoFilter.getTemplate(args);
        };

        vm.employeeOptions = {
            dataSource: {
                data: healthVisitors
            },
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "fullName",
            dataValueField: "id",
            placeholder: "Επιλέξτε Ιατρικό Επισκέπτη"
        };

        vm.fromOptions = {
            change: function() {
                var start = angular.element("#from").data("kendoDatePicker");
                var end = angular.element("#to").data("kendoDatePicker");
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.from = value;
                } else {
                    vm.masterFilters.from = null;
                }

                var startDate = vm.masterFilters.from,
                    endDate = vm.masterFilters.to;

                if (startDate) {
                    startDate = new Date(startDate);
                    startDate.setDate(startDate.getDate());
                    end.min(startDate);

                    //set range to year
                    var maxDate = new Date(startDate);
                    maxDate = new Date(maxDate.setFullYear(startDate.getFullYear() + 1));
                    end.max(maxDate);
                } else if (endDate) {
                    start.max(new Date(endDate));
                    end.max(new Date(2099, 11, 31));
                } else {
                    endDate = new Date();
                    start.max(endDate);
                    end.min(endDate);
                    end.max(new Date(2099, 11, 31));
                }
            }
        };

        vm.toOptions = {
            change: function() {
                var start = angular.element("#from").data("kendoDatePicker");
                var end = angular.element("#to").data("kendoDatePicker");
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.to = value;
                } else {
                    vm.masterFilters.to = null;
                }

                var endDate = vm.masterFilters.to,
                    startDate = vm.masterFilters.from;

                if (endDate) {
                    endDate = new Date(endDate);
                    endDate.setDate(endDate.getDate());
                    start.max(endDate);

                    //set range to year
                    var minDate = new Date(endDate);
                    minDate = new Date(minDate.setFullYear(endDate.getFullYear() - 1));
                    start.min(minDate);
                } else if (startDate) {
                    end.min(new Date(startDate));
                    start.min(new Date(1900, 0, 1));
                } else {
                    endDate = new Date();
                    start.max(endDate);
                    end.min(endDate);
                    start.min(new Date(1900, 0, 1));
                }
            }
        };

        vm.softwareOptions = {
            dataSource: [
                // {text: 'Επιλογή όλων', value: 'all'},
                {text: 'Χωρίς Λογισμικό', value: 'none'},
                {text: $filter('translate')('global.field.software.medexpress'), value: 'medexpress'},
                {text: $filter('translate')('global.field.software.medmobile'), value: 'medmobile'},
                {text: $filter('translate')('global.field.software.other'), value: 'other'}
                ],
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "text",
            dataValueField: "value",
            placeholder: "Επιλογή λογισμικού",
            select: function (e) {
                var dataItem = this.dataSource.view()[e.item.index()];
                var values = this.value();

                if (dataItem.value === "all" || dataItem.value === "none") {
                    this.value("");
                } else if (values.indexOf("all") !== -1) {
                    values = angular.element.grep(values, function(value) {
                        return value !== "all";
                    });
                    this.value(values);
                } else if (values.indexOf("none") !== -1) {
                    values = angular.element.grep(values, function(value) {
                        return value !== "none";
                    });
                    this.value(values);
                }
            }
        };

        vm.clearDatePickers = function () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        };

        vm.masterFiltersOnClick = function (e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id === "today") {
                vm.clearDatePickers();

                $timeout(function (){
                    vm.masterFilters.from = date;
                    vm.masterFilters.to = date;
                });
            } else if (e.id === "week") {
                vm.clearDatePickers();

                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6

                $timeout(function (){
                    vm.masterFilters.from = firstWeekDay;
                    vm.masterFilters.to = lastWeekDay;
                });
            } else if (e.id === "month") {
                vm.clearDatePickers();

                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                $timeout(function (){
                    vm.masterFilters.from = firstMonthDay;
                    vm.masterFilters.to = lastMonthDay;
                });
            }
        };

        vm.exportData = function () {
            var grid = $("#mainGrid").data("kendoGrid");
            grid.saveAsExcel();
        };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup", id:"buttonGroup",
                    buttons: [
                        /*{ text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },*/
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ], overflow: "never", attributes: { class: "left" }
                },
                {
                    type: "separator", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Από: </label><input class='inline' kendo-date-picker id='from' options='vm.fromOptions' k-ng-model='vm.masterFilters.from'/>", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Εώς: </label><input class='inline' kendo-date-picker id='to' options='vm.toOptions' k-ng-model='vm.masterFilters.to'/>", overflow: "never", attributes: { class: "left" }
                },
                {
                    type: "separator", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Ιατρικός Επισκέπτης: </label><select name='employeeSelect' id='employeeSelect' kendo-combo-box k-ng-model='vm.selectedEmployeeId' k-options='vm.employeeOptions' style='width: 72%'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    type: "separator", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<div style='padding-top: 6px;'><label>My Target: </label><div class='inline'><md-radio-group layout='row' ng-model='vm.myTarget'>" +
                    "<md-radio-button ng-value=true class='md-primary'> Ναι </md-radio-button>" +
                    "<md-radio-button ng-value=false class='md-primary'> Όχι </md-radio-button>" +
                    "<md-radio-button ng-value='' class='md-primary'> Όλα </md-radio-button></md-radio-group></div></div>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    type: "separator", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Ενεργός: </label><md-checkbox aria-label='isActive' ng-model='vm.isActive' class='checkbox-toolbar green inline'></md-checkbox>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    type: "separator", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<div ng-if='vm.customerView === \"3\"' style='padding-top: 2px;'><label>Λογισμικό: </label><select kendo-multi-select multiple='multiple' id='multiSelect' name='multiSelect' class='inline' k-ng-model='vm.selectedSoftwares' " +
                    "                            k-options='vm.softwareOptions' class='col-fix'></select></div>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    type: "separator", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary' aria-label='search' ng-click='vm.getData();' ng-disabled='vm.disabled'>" +
                    "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<md-button class='md-raised md-mini md-primary k-button-icontext k-button-icontext k-grid-excel' aria-label='search' ng-click='vm.exportData();' ng-disabled='vm.disabled'>" +
                    "<span class='k-icon k-i-excel'></span>EXPORT</md-button>", overflow: "never", attributes: { class: "right" }
                }
            ]
        };

        vm.getData = function () {
            var cancel = false;
            var message = "";
            if (vm.masterFilters.from === null || vm.masterFilters.to === null ||
                vm.masterFilters.from === undefined || vm.masterFilters.to === undefined){
                cancel = true;
                message = "Επιλέξτε ημερομηνίες";
            }

            if (!cancel && (vm.masterFilters.from > vm.masterFilters.to)){
                cancel = true;
                message = "Η ημερομηνία 'Από' πρέπει να είναι μικρότερη από την ημερομηνία 'Εώς'"
            }

            if (!cancel && (vm.selectedEmployeeId === null || vm.selectedEmployeeId === undefined)) {
                cancel = true;
                message = "Επιλέξτε Ιατρικό Επισκέπτη"
            }

            if (cancel){
                Alerts.showAlert("Sigma SalesForce", message, "ΟΚ");
            }else {
                $("#mainGrid").data().kendoGrid.destroy();
                $("#mainGrid").empty();
                vm.showProgress = true;
                $http.get(constructUrl())
                    .success(function (response) {
                        vm.showProgress = false;
                        generateGrid(response);
                    })
                    .error(function (error) {
                        vm.showProgress = false;
                        console.log(error);
                        Alerts.showAlert("Sigma SalesForce", "Λυπούμαστε. Κάποιο σφάλμα προσέκυψε!", "ΟΚ");
                    });
            }
        };

        function constructUrl() {
            var url = "/api/events/count-events-in-period";
            url += "?dateFrom=" + $filter('date')(vm.masterFilters.from, 'yyyy-MM-dd');
            url += "&dateTo=" + $filter('date')(vm.masterFilters.to, 'yyyy-MM-dd');
            url += "&employeeId=" + vm.selectedEmployeeId;
            url += "&active=" + vm.isActive;
            if (vm.myTarget === true || vm.myTarget === false) {
                url += "&myTarget=" + vm.myTarget;
            }
            if (vm.selectedSoftwares.length > 0) {
                url += "&softwares=" + vm.selectedSoftwares;
            }

            return url;
        }

        function generateGrid(response) {
            $("#mainGrid").kendoGrid({
                dataSource: {
                    batch: true,
                    serverFiltering: false,
                    transport: {
                        read: function (options) {
                            options.success(response);
                        }
                    },
                    schema: {
                        parse: function (data) {
                            if (data !== null && data !== undefined && data.length > 0){
                                angular.forEach(data, function(item) {
                                    if (!item.rowObject.customerSector.customer) {
                                        item.rowObject.customerSector.customer = {
                                            doctorSpecialty: {},
                                            customerCategory: {},
                                            customerSubCategory: {},
                                            collaboration: {}
                                        }
                                    }else {
                                        if (!item.rowObject.customerSector.customer.doctorSpecialty) item.rowObject.customerSector.customer.doctorSpecialty = {};
                                        if (!item.rowObject.customerSector.customer.customerCategory) item.rowObject.customerSector.customer.customerCategory = {};
                                        if (!item.rowObject.customerSector.customer.customerSubCategory) item.rowObject.customerSector.customer.customerSubCategory = {};
                                        if (!item.rowObject.customerSector.customer.collaboration) item.rowObject.customerSector.customer.collaboration = {};

                                        if (item.rowObject.customerSector.customer.software === "other") {
                                            item.rowObject.customerSector.customer.software = $filter('translate')('global.field.software.other')
                                        }else if (item.rowObject.customerSector.customer.software === "medexpress") {
                                            item.rowObject.customerSector.customer.software = $filter('translate')('global.field.software.medexpress')
                                        }else if (item.rowObject.customerSector.customer.software === "medmobile") {
                                            item.rowObject.customerSector.customer.software = $filter('translate')('global.field.software.medmobile')
                                        }
                                    }

                                    if (!item.rowObject.customerSector.sector) {
                                        item.rowObject.customerSector.sector = {
                                            sectorType: {}
                                        }
                                    }else {
                                        if (!item.rowObject.customerSector.sector.sectorType) item.rowObject.customerSector.sector.sectorType = {};
                                    }

                                    item.rowObject.myTarget = item.rowObject.myTarget === true ? 'Ναι' : 'Όχι';
                                });
                            }
                            return data;
                        },
                        model: {
                            id: "rowObject.customerSector.customer.id"
                        }
                    }
                },
                columns: generateColumns(response),
                height: vm.contentHeight,
                pageable: false,
                editable: false,
                resizable: true,
                scrollable: true,
                filterable: {
                    mode: "row"
                },
                sortable: true,
                dataBound: function () {
                    $timeout(function() {
                        kendo.resize($("#mainGrid"));
                    });
                },
                excel: {
                    allPages: true,
                    fileName: "Απολογισμός Επισκέψεων ανά Ιατρικό Επισκέπτη.xlsx"
                },
                noRecords: {
                    template: "Δεν βρέθηκαν εγγραφές"
                }
            });
        }

        function generateColumns(response) {
            var columns = [];
            if (response !== null && response !== undefined && response.length > 0) {
                columns = [
                    {
                        field: "rowObject.customerSector.customer.lastName",
                        title: "Επώνυμο",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: filterTemplate,
                                showOperators: false
                            }
                        },
                        width: 120
                    },
                    {
                        field: "rowObject.customerSector.customer.firstName",
                        title: "Όνομα",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: filterTemplate,
                                showOperators: false
                            }
                        },
                        width: 130
                    },
                    {
                        field: "rowObject.customerSector.customer.doctorSpecialty.name",
                        title: "Ειδικότητα",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: filterTemplate,
                                showOperators: false
                            }
                        },
                        width: 100
                    },
                    {
                        field: "rowObject.customerSector.customer.customerCategory.name",
                        title: "Κατηγορία",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: filterTemplate,
                                showOperators: false
                            }
                        },
                        width: 70
                    },
                    {
                        field: "rowObject.customerSector.customer.customerSubCategory.name",
                        title: "ΥποΚατηγορία",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: filterTemplate,
                                showOperators: false
                            }
                        },
                        width: 70
                    },
                    {
                        field: "rowObject.customerSector.customer.collaboration.name",
                        title: "Συνεργασία",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: filterTemplate,
                                showOperators: false
                            }
                        },
                        width: 90,
                        hidden: vm.customerView === '1' || vm.customerView === '6'
                    },
                    {
                        field: "rowObject.customerSector.customer.software",
                        title: "Λογισμικό",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: filterTemplate,
                                showOperators: false
                            }
                        },
                        width: 80,
                        hidden: vm.customerView === '1' || vm.customerView === '6'
                    },
                    {
                        field: "rowObject.customerSector.sector.cityName",
                        title: "Πόλη",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: filterTemplate,
                                showOperators: false
                            }
                        },
                        width: 120
                    },
                    {
                        field: "rowObject.customerSector.sector.prefectureName",
                        title: "Νομός",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: filterTemplate,
                                showOperators: false
                            }
                        },
                        width: 120
                    },
                    {
                        field: "rowObject.customerSector.sector.sectorType.name",
                        title: "Τύπος Μ.Υ.",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: filterTemplate,
                                showOperators: false
                            }
                        },
                        width: 90,
                        hidden: vm.customerView === '1' || vm.customerView === '6'
                    },
                    {
                        field: "rowObject.customerSector.sector.name",
                        title: "Kύρια Μ.Υ.",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: filterTemplate,
                                showOperators: false
                            }
                        },
                        width: 120
                    },
                    {
                        field: "rowObject.customerSector.sector.osfeBrick",
                        title: "Brick ΟΣΦΦΕ",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: filterTemplate,
                                showOperators: false
                            }
                        },
                        width: 120,
                        hidden: vm.customerView !== '1'
                    },
                    {
                        field: "rowObject.myTarget",
                        title: "MyTarget",
                        filterable: {
                            cell: {
                                operator: "eq",
                                showOperators: false,
                                template: function (args) {
                                    args.element.kendoDropDownList({
                                        autoBind: false,
                                        dataTextField: "text",
                                        dataValueField: "value",
                                        dataSource: new kendo.data.DataSource({
                                            data: [{text: "Ναι", value: "Ναι"},
                                                {text: "Όχι", value: "Όχι"}]
                                        }),
                                        valuePrimitive: true
                                    });
                                }
                            }
                        },
                        width: 70
                    }
                ];
                angular.forEach(response[0].rowValues, function (item, index) {
                    columns.push({
                        field: "rowValues[" + index + "].value",
                        title: item.columnName,
                        filterable: false,
                        width: 40
                    });
                });

                columns.push({
                    field: "rowTotal",
                    title: "Σύνολο",
                    filterable: false,
                    width: 50
                });
            }

            return columns;
        }

        $(window).on("resize", function() {
            kendo.resize($("#mainGrid"));
        });

        // Click month when screen opens
        $timeout(function () {
            $("#month").click();
        });
    }
})();
