(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MDTaxStatusClassesController', MDTaxStatusClassesController);

    MDTaxStatusClassesController.$inject = ['KendoGrid', 'KendoService', '$http', '$filter', 'KendoFilter'];

    function MDTaxStatusClassesController (KendoGrid, KendoService, $http, $filter, KendoFilter) {
        var vm = this;

        vm.pageHeading = $filter('translate')('masterData.taxStatusClasses.title');

        vm.dataSource = {};
        vm.dataSource.taxStatuses = KendoService.initDefaultDataSource('/api/tax-statuses/search');
        vm.dataSource.taxClasses = KendoService.initDefaultDataSource('/api/tax-classes/search');

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/tax-status-classes/search',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    create: function(o) {
                        var data = o.data.models[0];
                        $http.post('/api/tax-status-classes', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    update: function(o) {
                        var data = o.data.models[0];
                        $http.put('/api/tax-status-classes', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            taxStatus: {defaultValue: { name: "" }},
                            'taxStatus.name': { validation: { required: true , validation: { required: true, min: 3}}},
                            taxClass: {defaultValue:  { name: "" }},
                            'taxClass.name': { validation: { required: true , validation: { required: true, min: 3}}},
                            value: {defaultValue: null}
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            toolbar: [{name :"create", text: "Προσθήκη Νέας εγγραφής"},
                { template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }],
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "taxStatus.name", title: "Καθεστώς ΦΠΑ ΜΥ",
                    editor: function(container, options) {
                        angular.element('<select id="taxStatus" data-bind="value:taxStatus.id"></select>')
                            .appendTo(container)
                            .kendoComboBox({
                                dataSource: vm.dataSource.taxStatuses,
                                filter: "startswith",
                                valuePrimitive: true,
                                dataTextField: "name",
                                dataValueField: "id"
                            });
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "taxClass.name", title: "Φ.Π.Α. Είδους",
                    editor: function(container, options) {
                        angular.element('<select id="taxClass" data-bind="value:taxClass.id"></select>')
                            .appendTo(container)
                            .kendoComboBox({
                                dataSource: vm.dataSource.taxClasses,
                                filter: "startswith",
                                valuePrimitive: true,
                                dataTextField: "name",
                                dataValueField: "id"
                            });
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "value", title: "Ποσοστό",
                    template: "#= (value == null) ? ' ' : kendo.format('{0:p0}', value / 100)#",
                    editor: function(container,options){

                        angular.element('<input data-bind="value: value">')
                            .appendTo(container)
                            .kendoNumericTextBox({format:"{0:#\\%}"});
                    },
                    filterable: {
                        cell: {
                            operator: "eq",
                            template: function (args) {
                                args.element.kendoNumericTextBox({
                                    format: "{0:#\\%}"
                                });
                            },
                            showOperators: false
                        }
                    }},
                {
                    command:[
                        {name:"edit", text: "Επεξεργασία"}
                    ],
                    text: "Επεξεργασία",
                    title: "&nbsp;"
                }
            ],
            edit: function() { KendoGrid.defaultEditPopup(this._editContainer); },
            editable: "popup"
        };

    }
})();
