(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('DiseaseDetailController', DiseaseDetailController);

    DiseaseDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Disease', 'DiseaseType'];

    function DiseaseDetailController($scope, $rootScope, $stateParams, entity, Disease, DiseaseType) {
        var vm = this;
        vm.disease = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:diseaseUpdate', function(event, result) {
            vm.disease = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
