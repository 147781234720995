/**
 * Created by Kristy on 10/5/2018.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('prospectivePatientInfo', {
                parent: 'prospectivePatientView',
                url: '',
                data: {
                    authorities: ['ROLE_REC_MANAGEMENT', 'ROLE_REC_FRONT_DESK'],
                    pageTitle: 'sigmaCrmApp.patient.home.title'
                },
                templateUrl: 'app/displays/prospectivePatients/info/info.html',
                controller: 'ProspectivePatientViewInfoController',
                controllerAs: 'vm',
                params: {
                    search: null
                },
                resolve: {
                    entity: ['$stateParams', 'ProspectivePatient', function($stateParams, ProspectivePatient) {
                        return ProspectivePatient.get({id : $stateParams.id}).$promise;
                    }],
                    professions: ['Profession', function (Profession) {
                        return Profession.query({page: 0, size: 400}).$promise;
                    }],
                    relationshipTypes: ['RelationshipType', function (RelationshipType) {
                        return RelationshipType.query({page: 0, size: 200}).$promise;
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
