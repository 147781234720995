(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('DoctorSpecialtyDeleteController',DoctorSpecialtyDeleteController);

    DoctorSpecialtyDeleteController.$inject = ['$uibModalInstance', 'entity', 'DoctorSpecialty'];

    function DoctorSpecialtyDeleteController($uibModalInstance, entity, DoctorSpecialty) {
        var vm = this;
        vm.doctorSpecialty = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            DoctorSpecialty.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
