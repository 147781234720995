(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('EventPlaceDetailController', EventPlaceDetailController);

    EventPlaceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'EventPlace'];

    function EventPlaceDetailController($scope, $rootScope, $stateParams, entity, EventPlace) {
        var vm = this;
        vm.eventPlace = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:eventPlaceUpdate', function(event, result) {
            vm.eventPlace = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
