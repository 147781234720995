(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('ChangeTableSearch', ChangeTableSearch);

    ChangeTableSearch.$inject = ['$resource'];

    function ChangeTableSearch($resource) {
        var resourceUrl =  'api/_search/change-tables/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
