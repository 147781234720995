/**
 * Created by Kristy on 5/9/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('NotificationOptions', NotificationOptions);

    NotificationOptions.$inject = ['$filter'];

    function NotificationOptions ($filter) {

        return {
                autoHideAfter: 4000,
                templates: [{
                    type: "error",
                    template: angular.element("#errorTemplate").html()
                }, {
                    type: "success",
                    template: angular.element("#successTemplate").html()
                }]
            };
    }

})();
