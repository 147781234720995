/**
 * Created by Teo on 11/02/2019.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchaseAccountingViewController', PurchaseAccountingViewController);

    PurchaseAccountingViewController.$inject = ['$state', '$http', '$stateParams', '$filter', '$timeout', '$mdDialog', 'KendoService', 'KendoGrid', 'KendoFilter', 'KendoNotifications', 'Purchase', 'PurchaseItem', 'PurchaseGrids',
        'NotificationOptions', 'employees', 'purchase', 'purchaseItems', 'purchasePresentItems', 'Alerts', '$window'];

    function PurchaseAccountingViewController ($state, $http, $stateParams, $filter, $timeout, $mdDialog, KendoService, KendoGrid, KendoFilter, KendoNotifications, Purchase, PurchaseItem, PurchaseGrids,
                                               NotificationOptions, employees, purchase, purchaseItems, purchasePresentItems, Alerts, $window) {
        var vm = this;
        vm.employees = employees;
        vm.purchase = purchase;
        setUpWithPurchase();
        vm.purchaseItems = purchaseItems;
        vm.purchasePresentItems = purchasePresentItems;
        vm.selectedProducts = angular.copy(vm.purchasePresentItems);
        vm.priceCalculation = true;
        vm.selectedPurchaseId = $stateParams.id;
        vm.options = {
            spinners: false,
            format: "c2"
        };
        vm.percentOptions = {
            format: "# \\%",
            max: 100
        };
        vm.presentListProductsWindow = {};
        vm.sectorSearchWindowVisible = false;
        vm.sectorSearchWindowOptions = {
            title: "Επιλέξτε επαγγελματία υγείας",
            visible: false,
            modal: true,
            width: '90%'
        };

        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");
        var sectorSearchWindow = angular.element('#popupSectorSearchWindow');
        var sectorTypeCategories = ["pharmacy", "beautyCenter"];
        var dataSource = {};
        dataSource.sectorsDataSource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) o.data.filter = { filters: [] };

                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            { "field": "sectorType.sectorTypeCategory", "operator": "in",
                                "value": sectorTypeCategories.toString() }
                        ]
                    );

                    angular.forEach( o.data.filter.filters, function(item) {
                        if ( item.value instanceof Date) {
                            item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                        }
                    });

                    $http.get('/api/me/sectors',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response);
                        })
                        .error(function (response) {});
                }
            },
            schema : {
                data: function (data) {
                    angular.forEach( data.content, function (item) {
                        if ( item.companyType == null ) {
                            item.companyType = {};
                        }
                        if ( item.taxStatus == null ) {
                            item.taxStatus = {};
                        }
                        if ( item.taxOffice == null ) {
                            item.taxOffice = {};
                        }
                    });
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        id: "id",
                        name : { editable: false },
                        vat : { editable: false },
                        address : { editable: false },
                        addressNum : { editable: false },
                        cityName : { editable: false },
                        prefectureName : { editable: false },
                        brick : { editable: false },
                        osfeBrick : { editable: false },
                        phone : { editable: false },
                        createdDate: { type: 'date', editable: false},
                        companyType: { defaultValue: { id: null, name: null }},
                        taxStatus: { defaultValue: { id: null, name: null }},
                        taxOffice: { defaultValue: { id: null, name: null }},
                        creditControlCheck: { type: "boolean" }
                    }
                }
            },
            pageSize: 20,
            serverPaging: true,
            serverSorting: true,
            scrollable:false,
            serverFiltering :true
        });
        dataSource.employeesDataSource = new kendo.data.DataSource({
            data: vm.employees,
            schema: {
                parse: function (employeesArray) {
                    if (employeesArray && employeesArray.length > 0){
                        var length = employeesArray.length;
                        var dataItem;
                        var idx = 0;
                        for (; idx < length; idx++) {
                            dataItem = employeesArray[idx];
                            dataItem.fullName = dataItem.account.lastName + " " + dataItem.account.firstName;
                        }
                    }

                    return employeesArray;
                }
            }
        });
        dataSource.purchaseStatusDataSource = new kendo.data.DataSource({
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get( '/api/masterdata/purchase-statuses',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            },
            sort: { field: "code", dir: "asc" }
        });
        dataSource.paymentMethods = KendoService.initDefaultDataSource('/api/payment-methods/search');
        dataSource.codesDataSource = KendoService.initDefaultDataSource('/api/masterdata/products');
        dataSource.productsDataSource = KendoService.initDefaultDataSource('/api/masterdata/products');
        dataSource.pickerDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: function (o) {
                    var url = '/api/me/employees';
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }
                    if (o.data.sort === undefined || !o.data.sort.length) {
                        o.data.sort = { "field": "account.lastName",  "dir": "asc" };
                    }
                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            { "field": "account.authorities", "operator": "eq",
                                "value": "ROLE_STOREHOUSE" }
                        ]
                    );
                    o.data.page = 1;
                    o.data.pageSize = 20;
                    $http.get(url,
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            },
            schema: {
                parse: function (data) {
                    data.forEach(function (item) {
                        item.employee = {
                            account: {
                                fullName: item.account.lastName + " " + item.account.firstName
                            }
                        }
                    });
                    return data;
                }
            }
        });
        dataSource.presentLists = KendoService.initDataSource('/api/present-lists/search', 1, 20);
        vm.productsGridDataSource = new kendo.data.DataSource({
            autoBind: false,
            transport: {
                read: function (o) {
                    if (o.data.filter === undefined) o.data.filter = {filters: []};
                    o.data.filter.filters.push({field: "presentList.id", operator: "eq", value: vm.purchase.presentList.id});

                    $http.get('/api/present-list-products/search', {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {});
                }
            },
            schema: {
                data: function (data) {
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        id: { editable: false, nullable: true},
                        'product.code': { editable: false, nullable: true},
                        'product.name': { editable: false, nullable: true, type: "text"}
                    }
                }
            },
            serverFiltering: true,
            serverSorting: true,
            serverPaging: true,
            pageSize: 20,
            scrollable:false
        });

        vm.sectorsComboBoxOptions = KendoService.initDefaultOptions(dataSource.sectorsDataSource, "startswith", "name", "id", false);
        vm.sectorsComboBoxOptions.autoBind = false;
        vm.sectorsComboBoxOptions.change = function() {
            $timeout( function() { if(vm.purchase.sector) vm.purchase.sector.shippingAddress = vm.purchase.sector.address; });
            if(vm.purchase.sector) {
                if (vm.purchase.sector.address) {
                    angular.element("#sectorAddress").css('width', ((vm.purchase.sector.address.length + 1) * 10) + 'px');
                    angular.element("#sectorShippingAddress").css('width', ((vm.purchase.sector.address.length + 1) * 10) + 'px');
                }
            }
        };
        vm.employeesComboBoxOptions = KendoService.initDefaultOptions(dataSource.employeesDataSource, "startswith", "fullName", "id", false);
        vm.employeesComboBoxOptions.cascade = function () {
            // Cascade event to fire when widget value changes programmatically (from '/api/employees/me' call below)
            vm.purchase.employee = this.dataItem();
            vm.purchase.employee.fullName = vm.purchase.employee.account.lastName + " " + vm.purchase.employee.account.firstName;
            angular.element("#employee").css('width', ((vm.purchase.employee.fullName.length + 1) * 8) + 'px');

            dataSource.purchaseNumberingRowDataSource = vm.purchase.employee.purchaseNumberingRows;
            vm.purchaseNumberingRowOptions = KendoService.initDefaultOptions(dataSource.purchaseNumberingRowDataSource, "startswith", "name", "id");
        };
        vm.purchaseStatusOptions = {
            dataSource: dataSource.purchaseStatusDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };
        vm.paymentMethodOptions = KendoService.initDefaultOptions(dataSource.paymentMethods, "startswith", "name", "id");
        vm.pickerOptions = KendoService.initDefaultOptions(dataSource.pickerDataSource, "startswith", "employee.account.fullName", "id");
        vm.presentListsComboBoxOptions = KendoService.initDefaultOptions(dataSource.presentLists, "startswith", "code", "id");
        vm.presentListsComboBoxOptions.valuePrimitive = false;

        vm.purchaseItemsOptions = {
            dataSource:  new kendo.data.DataSource({
                data: vm.purchaseItems,
                schema : {
                    model: {
                        id: "id",
                        fields: {
                            id: "id",
                            product: { defaultValue: { id: null, code: "", name: "", barcode: "" } },
                            quantity: { type: "number", validation: { min: 1 }, defaultValue: 1 },
                            productPrice: { editable: false, type: "number", validation: { min: 0 }, defaultValue: 0 },
                            totalPrice: { editable: false, type: "number", validation: { min: 0 }, defaultValue: 0 },
                            discountLinePercent: { type: "number", validation: { min: 0 }, defaultValue: 0 },
                            discountLinePrice: { editable: false, type: "number", validation: { min: 0 }, defaultValue: 0 },
                            discountSummary: { editable: false, type: "number", validation: { min: 0 }, defaultValue: 0 },
                            taxPrice: { editable: false, type: "number", validation: { min: 0 }, defaultValue: 0 },
                            finalPrice: { editable: false, type: "number", validation: { min: 0 }, defaultValue: 0 },
                            'product.defaultDiscount': { type: "number", validation: { min: 0 }, defaultValue: 0 },
                            discountPrice: { type: "number", validation: { min: 0 }, defaultValue: 0 },
                            discountPurchasePercent: { editable: false, type: "number", validation: { min: 0 }, defaultValue: 0 },
                            discountPurchasePrice: { editable: false, type: "number", validation: { min: 0 }, defaultValue: 0 },
                            taxPercent: { editable: false, type: "number", validation: { min: 0 }, defaultValue: 0 },
                            netPrice: { editable: false, type: "number", validation: { min: 0 }, defaultValue: 0 },
                            pfs: { editable: false, type: "number", validation: { min: 0 }, defaultValue: 0 }
                        }
                    }
                },
                aggregate: [{field: "quantity", aggregate: "sum"}]
            }),
            navigatable: true,
            editable: true,
            toolbar: [{ template: "<md-button class='blue-color-md-button md-raised md-mini md-primary k-grid-add' aria-label='add' ng-disabled='!vm.purchase.sector'>" +
                "<i class='material-icons fixed-icons fixed-icons-2'>add</i>Προσθήκη Νέας Γραμμής Παραγγελίας</md-button>"}],
            resizable: true,
            columns: [
                {
                    field: "product",
                    title: "Κωδικός είδους",
                    template: "#= product.code ? product.code : ''#",
                    editor: function(container, options) {
                        angular.element('<select id="productCode" data-bind="value:product"></select>')
                            .appendTo(container)
                            .kendoComboBox({
                                dataSource: dataSource.codesDataSource,
                                dataBound: function (e) {
                                    var dataSource = e.sender.dataSource;
                                    angular.forEach( dataSource.data(), function(item) {
                                        if (!item.code) {
                                            dataSource.remove(item);
                                        }
                                    });
                                },
                                filter: "contains",
                                dataTextField: "code",
                                dataValueField: "id"
                            });
                    }
                },
                {
                    field: "product",
                    title: "Περιγραφή είδους",
                    template: "#= product ? product.name : ''#",
                    editor: function(container) {
                        angular.element('<select id="productName" data-bind="value:product"></select>')
                            .appendTo(container)
                            .kendoComboBox({
                                dataSource: dataSource.productsDataSource,
                                filter: "contains",
                                dataTextField: "name",
                                dataValueField: "id"
                            });
                    }
                },
                {
                    field: "quantity",
                    title: "Ποσότητα",
                    attributes:{style:"text-align:right;"},
                    footerTemplate: "Σύνολο: #=kendo.format('{0:n0}', sum)#",
                    template: function (model) {
                        model.quantity = model.quantity || 1;
                        return kendo.format('{0:n0}', model.quantity);
                    }
                },
                {
                    field: "productPrice",
                    title: "Τιμή",
                    attributes:{style:"text-align:right;"},
                    template: function (model) {
                        var date = new Date();
                        angular.forEach( model.product.productPrices, function( productPrice ) {
                            if (date > new Date(productPrice.startedDate) && date < new Date(productPrice.endedDate)) {
                                model.productPrice = productPrice.wholesalePrice || 0;
                            }
                        });
                        return kendo.format('{0:c}', model.productPrice || 0);
                    }
                },
                {
                    field: "totalPrice",
                    title: "Μικτή Αξία",
                    attributes:{style:"text-align:right;"},
                    template: function (model) {
                        model.totalPrice = model.quantity * model.productPrice;
                        return kendo.format('{0:c}', model.totalPrice);
                    }
                },
                {
                    field: "discountLinePercent",
                    title: "Έκπτωση (%)",
                    attributes:{style:"text-align:right;"},
                    template: "#= (discountLinePercent == null) ? '' : kendo.format('{0:p}', discountLinePercent / 100)#"
                },
                {
                    field: "discountLinePrice",
                    title: "Αξία Έκπτωσης",
                    attributes:{style:"text-align:right;"},
                    template: function (model) {
                        model.discountLinePrice = (model.totalPrice - (model.totalPrice * (model.product.defaultDiscount/100))) * (model.discountLinePercent/100) || 0;
                        return kendo.format('{0:c}', model.discountLinePrice);
                    }
                },
                {
                    field: "discountSummary",
                    title: "Συνολική Έκπτωση",
                    attributes:{style:"text-align:right;"},
                    template: function (model) {
                        model.discountSummary = (model.totalPrice * model.product.defaultDiscount / 100) + model.discountLinePrice + model.discountPurchasePrice || 0;
                        return kendo.format('{0:c}', model.discountSummary);
                    }
                },
                {
                    field: "netPrice",
                    title: "Καθαρή Αξία",
                    attributes:{style:"text-align:right;"},
                    template: function (model) {
                        model.netPrice = model.totalPrice - model.discountSummary + model.pfs;
                        return kendo.format('{0:c}', model.netPrice);
                    }
                },
                {
                    field: "taxPrice",
                    title: "Αξία Φ.Π.Α.",
                    attributes:{style:"text-align:right;"},
                    template: function (model) {
                        if (model.product.taxClass) {
                            model.taxPercent =
                                $filter('filter')(model.product.taxClass.taxStatusClasses, { taxStatus: { id: vm.purchase.sector.taxStatus.id } }, true)[0].value;
                        }
                        model.taxPrice = model.netPrice * model.taxPercent/100;
                        return kendo.format('{0:c}', model.taxPrice);
                    }
                },
                {
                    field: "finalPrice",
                    title: "Συνολική Αξία",
                    attributes:{style:"text-align:right;"},
                    template: function (model) {
                        model.finalPrice = model.netPrice + model.taxPrice;
                        return kendo.format('{0:c}', model.finalPrice);
                    }
                },
                {
                    field: "pfs",
                    title: "Π.Φ.Σ.",
                    attributes:{style:"text-align:right;"},
                    template: function (model) {
                        model.pfs = 0;
                        if(model.product)
                            if(model.product.calcPFS) model.pfs = model.totalPrice * 0.004;

                        return kendo.format('{0:c}', model.pfs);
                    }
                },
                {
                    field: "destroy",
                    template: "<md-button data-command='destroy' class='k-grid-delete md-icon-button md-mini md-warn' aria-label='Delete'>" +
                    "<i class='material-icons' style='vertical-align: inherit;'>delete_forever</i>" +
                    "</md-button>",
                    title: "&nbsp;",
                    width: 100
                }
            ],
            dataBound: function (e) {
                var items = e.sender.items();
                var columns = e.sender.options.columns;
                var grid = e.sender;
                var data = e.sender.dataSource.data();
                var totalPrice = 0, discountFinalPrice = 0, netPrice = 0, taxPrice = 0, finalPrice = 0, discountPrice = 0, pfs = 0;

                //Total Prices
                angular.forEach(data, function(item) {
                    totalPrice += item.totalPrice;
                    discountFinalPrice += item.discountSummary;
                    netPrice += item.netPrice;
                    taxPrice += item.taxPrice;
                    finalPrice += item.finalPrice;
                    discountPrice += item.discountPurchasePrice;
                    pfs += item.pfs;
                });
                vm.purchase.totalPrice = totalPrice;
                vm.purchase.discountFinalPrice = discountFinalPrice;
                vm.purchase.netPrice = netPrice;
                vm.purchase.taxPrice = taxPrice;
                vm.purchase.finalPrice = finalPrice;
                vm.purchase.discountPercent = 0;
                vm.purchase.discountPrice = discountPrice;
                vm.purchase.presentPercent = 0;
                vm.purchase.presentPrice = 0;
                vm.purchase.pfs = pfs;

                //Functionality for save row on enter.
                items.each(function () {
                    for (var i = 0; i < columns.length; i++) {
                        if (columns[i].field !== "destroy") {
                            angular.element(this).find("td:eq(" + i + ")").keydown(function (e) {
                                if (e.keyCode === 13) {
                                    grid.saveRow();
                                }
                            });
                        }

                        var row = angular.element(this);
                        if(!vm.priceCalculation) row.find(".k-hierarchy-cell").html("");
                    }
                });
                this.expandRow(this.tbody.find("tr.k-master-row"));
            },
            pageable: false
        };
        vm.purchasePresentItemsOptions = {
            dataSource:  new kendo.data.DataSource({
                data: vm.selectedProducts,
                schema : {
                    model: {
                        id: "id",
                        fields: {
                            id:"id",
                            'product.name': { editable: false },
                            'product.code': { editable: false },
                            quantity: { editable: true, type: "number", validation: { min: 0 }, defaultValue: 1 },
                            quantityExecuted: { editable: true, type: "number", validation: { min: 0 } },
                            price: { editable: false, type: "number", validation: { min: 0 } }
                        }
                    }
                }
            }),
            navigatable: true,
            editable: true,
            resizable: true,
            columns:  [
                {
                    field: "product.code",
                    title: "Κωδικός είδους"
                },
                {
                    field: "product.name",
                    title: "Περιγραφή είδους"
                },
                {
                    field: "quantity",
                    title: "Ποσότητα",
                    attributes:{style:"text-align:right;"}
                },
                {
                    field: "quantityExecuted",
                    title: "Εκτελεσμένη Ποσότητα",
                    attributes:{style:"text-align:right;"}
                },
                {
                    field: "price",
                    title: "Τιμή",
                    attributes:{style:"text-align:right;"},
                    template: function (model) {
                        var date = new Date();
                        angular.forEach( model.product.productPrices, function( productPrice ) {
                            if (date > new Date(productPrice.startedDate) && date < new Date(productPrice.endedDate)) {
                                model.price = productPrice.wholesalePrice || 0;
                            }
                        });
                        return kendo.format('{0:c}', model.price || 0);
                    }
                },
                {
                    field: "destroy",
                    template: "<md-button data-command='destroy' class='k-grid-delete md-icon-button md-mini md-warn' aria-label='Delete'>" +
                    "<i class='material-icons' style='vertical-align: inherit;'>delete_forever</i>" +
                    "</md-button>",
                    title: "&nbsp;",
                    width: 100
                }
            ],
            pageable: false,
            dataBound: function(e) {
                var items = e.sender.items();
                var columns = e.sender.options.columns;
                var grid = e.sender;
                //Functionality for save row on enter.
                items.each(function () {
                    for (var i = 0; i < columns.length; i++) {
                        if (columns[i].field !== "destroy") {
                            angular.element(this).find("td:eq(" + i + ")").keydown(function (e) {
                                if (e.keyCode === 13) {
                                    grid.saveRow();
                                }
                            });
                        }
                    }
                });
            }
        };
        vm.productsGridOptions = {
            dataSource: vm.productsGridDataSource,
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: true,
            editable: true,
            columns: [
                {
                    field: "product.code",
                    title: "Κωδικός Είδους",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "product.name",
                    title: "Περιγραφή",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    template: '<md-checkbox class="checkbox-toolbar customers green inline"  aria-label="selectable" ng-model="dataItem.active" ' + 'ng-disabled="true"></md-checkbox>',
                    editor: function(container,options){
                        angular.element('<md-checkbox ng-model="dataItem.active"  aria-label="active" ' +
                            'class="checkbox-toolbar customers green inline"></md-checkbox>')
                            .appendTo(container);
                    },
                    field: "active",
                    title: "Ενεργό",
                    filterable: {
                        cell: {
                            operator: "eqbool",
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Ναι", value: "true" },
                                            { text: "Όχι", value: "false" }]
                                    }),
                                    valuePrimitive: true

                                })
                            },
                            showOperators: false
                        }
                    }
                }
            ],
            selectedItems: function(dataItems) {
                vm.selectedProducts = dataItems;
            },
            dataBound: function () {
                angular.element("#productsGridcheckbox").prop('checked', false);
                //toggle class on click
                angular.element(".checkbox").bind("change", function (e) {
                    angular.element(e.target).closest("tr").toggleClass("k-state-selected");
                });

                var view = this.dataSource.data();
                var me = this;

                var selectedProductsViewCounter = 0;
                angular.forEach( view, function(item) {
                    var foundItemById = $filter('filter')(vm.selectedProducts, {id: item.id}, true)[0];
                    if(vm.selectedProducts.indexOf(foundItemById) !== -1){
                        me.tbody.find("tr[data-uid='" + item.uid + "']")
                            .addClass("k-state-selected");
                        item.selected = true;
                        selectedProductsViewCounter++
                    }
                });
                if(selectedProductsViewCounter === view.length) {
                    angular.element("#productsGridcheckbox").prop('checked', true);
                }
            }
        };
        vm.sectorsGridOptions = {
            autoBind: false,
            dataSource: dataSource.sectorsDataSource,
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "code",
                    title: "Κωδικός Ε.Ε.",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "name",
                    title: "Επωνυμία",
                    filterable: {
                        cell: {
                            operator: "like",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "vat",
                    title: "Α.Φ.Μ.",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "address",
                    title: "Διεύθυνση",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "addressNum",
                    title: "Αριθμός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "cityName",
                    title: "Πόλη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "prefectureName",
                    title: "Νομός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "postalCode", title: "Τ.Κ.",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "phone",
                    title: "Τηλέφωνο",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "sectorType.sectorTypeCategory",
                    title: "Τύπος",
                    template: "#= (sectorType.sectorTypeCategory == 'beautyCenter') ? 'Κέντρο Αισθητικής' : (sectorType.sectorTypeCategory == 'pharmacy') ? 'Φαρμακείο' : '' #",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Κέντρο Αισθητικής", value: "beautyCenter" },
                                            { text: "Φαρμακείο", value: "pharmacy" }]
                                    }),
                                    valuePrimitive: true

                                })
                            },
                            showOperators: false
                        }
                    }
                },
                {template: '<md-checkbox class="checkbox-toolbar customers green inline"  aria-label="selectable" ng-model="dataItem.creditControlCheck" ' +
                    'ng-disabled="true"></md-checkbox>',
                    field: "creditControlCheck", title: "Πιστωτικός Έλεγχος",
                    filterable: {
                        cell: {
                            operator: "eqbool",
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Ναι", value: "true" },
                                            { text: "Όχι", value: "false" }]
                                    }),
                                    valuePrimitive: true

                                })
                            },
                            showOperators: false
                        }
                    }},
                {
                    command: {
                        text: "Επιλογή",
                        click: onSectorGridSelection
                    }
                }
            ]
        };

        vm.update = function(ev) {
            vm.isSaving = true;
            if (vm.purchase.sector) if (vm.purchase.sector.id === null) vm.purchase.sector = null;
            if (vm.purchase.paymentMethod) if (vm.purchase.paymentMethod.id === null) vm.purchase.paymentMethod = null;
            if (vm.purchase.purchaseNumberingRow) if (vm.purchase.purchaseNumberingRow.id === null) vm.purchase.purchaseNumberingRow = null;
            if (vm.purchase.picker) if (vm.purchase.picker.id === null) vm.purchase.picker = null;

            if (!vm.priceCalculation) {
                if (vm.purchase.sector) vm.purchase.sector.shippingAddress = null;
                vm.purchase.purchaseStatus = null;
                vm.purchase.picker = null;
                vm.purchase.comment = null;
            }

            var purchaseItemList = vm.purchaseItemsOptions.dataSource.data();

            vm.validation = true;

            if (vm.validator.validate()){
                if (purchaseItemList.length > 0) {
                    for (var i = 0, len = purchaseItemList.length; i < len; i++) {
                        if (!purchaseItemList[i].product || !purchaseItemList[i].product.name) {
                            vm.validation = false;
                            vm.isSaving = false;
                            break;
                        }
                    }

                    vm.purchase.issueDate = kendo.toString(kendo.parseDate(vm.purchase.issueDate), 'yyyy-MM-dd');
                    if (vm.purchase.desirableExecutionDate !== null &&
                        vm.purchase.desirableExecutionDate !== undefined &&
                        vm.purchase.desirableExecutionDate !== ""){
                        vm.purchase.desirableExecutionDate = kendo.toString(kendo.parseDate(vm.purchase.desirableExecutionDate), 'yyyy-MM-dd');
                    }

                    angular.extend(vm.purchase, getPurchaseAddress(vm.purchase.sector));
                    angular.forEach(purchaseItemList, function (item) {
                        item.purchase = {id: vm.selectedPurchaseId};
                    });

                    var purchasePresentItemList = vm.purchasePresentItemsOptions.dataSource.data();
                    angular.forEach(purchasePresentItemList, function (item) {
                        item.purchase = {id: vm.selectedPurchaseId};
                    });

                    //console.log("try to save purchase with items:");
                    //console.log({purchase: vm.purchase, purchaseItems: purchaseItemList, purchasePresentItems: purchasePresentItemList});

                    $http.put('/api/purchases/update-with-items', {
                        purchase: vm.purchase,
                        purchaseItems: purchaseItemList,
                        purchasePresentItems: purchasePresentItemList
                    }).success(function (response) {
                        vm.isSaving = false;
                        KendoNotifications.onSaveSuccess(notification);
                        vm.goBack();
                    }).error(function (error) {
                        if (error.message && error.message.includes("error.concurrencyFailure")) {
                            Alerts.showConfirmDialog(ev, $filter('translate')('error.purchase.purchaseConcurrencyFailureTitle'),
                                $filter('translate')('error.purchase.purchaseConcurrencyFailureMessage'),
                                false, $filter('translate')('entity.action.refresh'), $filter('translate')('entity.action.cancel'), function () {
                                    // on confirm
                                    $window.location.reload();
                                }, function () {
                                    // on cancel
                                });
                        }else {
                            KendoNotifications.onSaveError(notification);
                        }
                    });
                }else {
                    vm.isSaving = false;
                    vm.validation = false;
                }
            }else {
                vm.isSaving = false;
                KendoNotifications.onSaveError(notification);
            }
        };

        vm.addPresentList = function () {
            vm.initPopupGrid = true;
            vm.clearPopUpGrid("opened");
            vm.presentListProductsWindow.center().open();
        };

        vm.closeProductsWindow = function () {
            delete vm.initPopupGrid;
            vm.clearPopUpGrid("closed");
            vm.presentListProductsWindow.close();
        };

        vm.clearPopUpGrid = function(status) {
            KendoGrid.refresh(vm.productsGridOptions);
            vm.statusChanged = status;
        };

        vm.openSectorsGrid = function() {
            vm.sectorsGridOptions.dataSource.read();
            vm.window.title("Επιλέξτε Επαγγελματία Υγείας");
            vm.window.center().open();
        };

        vm.closeSectorsGrid = function() {
            vm.window.close();
        };

        var presentList;
        vm.addProducts = function () {
            if (!vm.purchasePresentItemsOptions.dataSource.data().length || presentList === vm.purchase.presentList) {
                angular.forEach( vm.selectedProducts, function( item ) {

                    var data = { filter: { filters: [{field: "product.id", operator: "eq", value: item.id}] } };
                    $http.get('/api/product-prices/search', {params: data})
                        .success(function (response) {
                            var newItem = {
                                quantity: item.quantity,
                                quantityExecuted: 0,
                                presentListProduct: { id: item.id },
                                product: { id: item.product.id ,code: item.product.code, name: item.product.name,
                                    productPrices: item.product.productPrices },
                                price: null
                            };

                            angular.forEach( response.content, function( productPrice ) {
                                var date = new Date();
                                if (date > new Date(productPrice.startedDate) && date < new Date(productPrice.endedDate)) {
                                    newItem.price = productPrice.wholesalePrice || null;
                                }
                            });

                            vm.purchasePresentItemsOptions.dataSource.add( newItem );
                        })
                        .error(function (response) {});
                });
                presentList = vm.purchase.presentList;
            } else {
                vm.showConfirm();
            }

            vm.presentListProductsWindow.close();
            vm.clearPopUpGrid("closed");
        };

        vm.showConfirm = function() {
            var confirm = $mdDialog.confirm()
                .title('Τα ήδη υπάρχοντα προϊόντα θα διαγραφουν!')
                .textContent('Είστε σίγουροι ότι θέλετε να συνεχίσετε;')
                .ariaLabel('list-warning')
                .ok('Ναι!')
                .cancel('Όχι');

            $mdDialog.show(confirm).then(function() {
                angular.forEach( vm.purchasePresentItemsOptions.dataSource.data(), function( item ) {
                    var foundItemById = $filter('filter')(vm.selectedProducts, {id: item.id}, true)[0];
                    if(vm.selectedProducts.indexOf(foundItemById) !== -1){
                        vm.selectedProducts.splice(vm.selectedProducts.indexOf(foundItemById), 1);
                    }
                });
                vm.purchasePresentItemsOptions.dataSource.data([]);
                vm.addProducts();
            }, function() {});
        };

        vm.goBack = function () {
            $state.go('purchases');
        };

        function setUpWithPurchase() {
            if(vm.purchase.netPrice) vm.netPrice = vm.purchase.netPrice.toLocaleString();
            if(vm.purchase.discountFinalPrice) vm.discountFinalPrice = vm.purchase.discountFinalPrice.toLocaleString();
            if(vm.purchase.totalPrice) vm.totalPrice = vm.purchase.totalPrice.toLocaleString();
            if(vm.purchase.taxPrice) vm.taxPrice = vm.purchase.taxPrice.toLocaleString();
            if(vm.purchase.finalPrice) vm.finalPrice = vm.purchase.finalPrice.toLocaleString();

            if (!vm.purchase.shippingAddressNum) vm.purchase.shippingAddressNum = "";
            if (!vm.purchase.shippingPostalCode) vm.purchase.shippingPostalCode = "";

            vm.purchase.fullAddress = vm.purchase.shippingAddress + " "
                + vm.purchase.shippingAddressNum + " "
                + vm.purchase.shippingCityName + " "
                + vm.purchase.shippingPrefectureName + " "
                + vm.purchase.shippingCountryName + " "
                + vm.purchase.shippingPostalCode;

            if (vm.purchase.picker) {
                vm.purchase.picker.fullName = vm.purchase.picker.account.lastName + " " + vm.purchase.picker.account.firstName
            }
        }

        function getPurchaseAddress (sector) {
            return {
                shippingAddress: sector.address,
                shippingAddressNum:  sector.addressNum,
                shippingCityName:  sector.cityName,
                shippingPrefectureName:  sector.prefectureName,
                shippingCountryName:  sector.countryName,
                shippingPostalCode:  sector.postalCode
            }
        }

        function onSectorGridSelection(e){
            e.preventDefault();
            var data = this.dataItem(angular.element(e.currentTarget).closest("tr"));

            $timeout( function() {
                vm.purchase.sector = data;
                vm.purchase.sector.shippingAddress = vm.purchase.sector.address;
                if(vm.purchase.sector.address) {
                    angular.element("#sectorAddress").css('width', ((vm.purchase.sector.address.length + 1) * 10) + 'px');
                    angular.element("#sectorShippingAddress").css('width', ((vm.purchase.sector.address.length + 1) * 10) + 'px');
                }
            });
            angular.element("#sectorName").data('kendoComboBox').text(data.name);
            vm.closeSectorsGrid();
        }
    }
})();
