/**
 * Created by Teo on 06/11/2019.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('DeletionsController', DeletionsController);

    DeletionsController.$inject = ['$http', '$filter', 'KendoFilter', 'CustomUtils', '$timeout', 'Alerts', 'healthVisitors', 'customersView'];

    function DeletionsController ($http, $filter, KendoFilter, CustomUtils, $timeout, Alerts, healthVisitors, customersView) {
        var vm = this;
        vm.showProgress = false;
        vm.customerView = customersView.data.content[0].value;
        vm.selectedEmployeeId = null;
        vm.masterFilters = { myTarget: true };
        vm.selectedCustomersIds = [];

        var filterTemplate = function (args) { KendoFilter.getTemplate(args); };

        vm.employeeOptions = {
            dataSource: {
                data: healthVisitors
            },
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "fullName",
            dataValueField: "id",
            placeholder: "Επιλέξτε Ιατρικό Επισκέπτη",
            change: function () {
                vm.mainGridOptions.dataSource.read();
            }
        };

        vm.deleteSelected = function (ev) {
            var numberOfCustomersToDelete = vm.selectedCustomersIds.length;
            Alerts.showConfirmDialog(ev, "Sigma SalesForce", "Διαγραφή των " + numberOfCustomersToDelete + " επιλεγμένων Επαγγελματιών Υγείας από τον Ιατρικό Επισκέπτη?", false, "ΝΑΙ", "OXI", function () {
                vm.showProgress = true;
                $http.post('/api/employee-customer-sectors/deactivate', vm.selectedCustomersIds)
                    .success(function (response) {
                        vm.showProgress = false;
                        vm.mainGridOptions.dataSource.read();
                        Alerts.showAlert("Sigma SalesForce", "Διαγράφτηκαν επιτυχώς " + response + " από " + numberOfCustomersToDelete + " Επαγγελματίες Υγείας", "OK");
                    })
                    .error(function (error) {
                        console.log(error);
                        vm.showProgress = false;
                        vm.mainGridOptions.dataSource.read();
                        Alerts.showAlert("Sigma SalesForce", "Κάποιο σφάλμα προέκυψε κατά την διαγραφή των Επαγγελματιών Υγείας", "OK");
                    });
            }, function () {});
        };

        vm.mainGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function (o) {
                        if (vm.selectedEmployeeId !== null && vm.selectedEmployeeId !== undefined) {
                            o.data.filter = {filters: []};

                            o.data.filter.filters.push({
                                field: "employee.id",
                                operator: "eq",
                                value: vm.selectedEmployeeId
                            });

                            // MyTarget
                            o.data.filter.filters.push({
                                field: "myTarget",
                                operator: "eqbool",
                                "value": vm.masterFilters.myTarget
                            });

                            // Ενεργοί
                            o.data.filter.filters.push({
                                field: "active",
                                operator: "eqbool",
                                value: true
                            });

                            if (vm.customerView === '3') {
                                // Cross, fetch only not expired customerSectors
                                o.data.filter.filters.push({
                                    field: "customerSector.endedAt",
                                    operator: "null_or_greater",
                                    value: $filter('date')(Date.now(), 'yyyy-MM-dd')
                                });
                            }

                            o.data.sort = {
                                field: "customerSector.customer.lastName",
                                dir: "asc"
                            };

                            $http.get('/api/employee-customer-sectors/all', {params: o.data})
                                .success(function (response) {
                                    o.success(response);
                                })
                                .error(function (response) {
                                    o.error(response);
                                });
                        }else {
                            o.error([]);
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        data.forEach(function (item) {
                            if (item.customerSector === null) {
                                item.customerSector = {
                                    customer: {
                                        customerCategory: {},
                                        customerSubCategory: {},
                                        doctorSpecialty: {}
                                    },
                                    sector: {
                                        city: {},
                                        prefecture: {},
                                        sectorType: {}
                                    },
                                    main: 'false',
                                    dayTime: ''
                                }
                            }else {
                                if (item.customerSector.customer === null) item.customerSector.customer = {};
                                if (item.customerSector.customer !== null) {
                                    if (item.customerSector.customer.customerCategory === null) item.customerSector.customer.customerCategory = {};
                                    if (item.customerSector.customer.customerSubCategory === null) item.customerSector.customer.customerSubCategory = {};
                                    if (item.customerSector.customer.doctorSpecialty === null) item.customerSector.customer.doctorSpecialty = {}
                                }
                                if (item.customerSector.sector === null) item.customerSector.sector = {};
                                if (item.customerSector.sector !== null) {
                                    if (item.customerSector.sector.city === null) item.customerSector.sector.city = {};
                                    if (item.customerSector.sector.prefecture === null) item.customerSector.sector.prefecture = {};
                                    if (item.customerSector.sector.sectorType === null) item.customerSector.sector.sectorType = {};
                                }
                                item.main = item.customerSector.main === true ? "true" : "false";
                                item.dayTime = item.customerSector.customer !== null ? (item.customerSector.customer.dayTime === 'day' ? 'ΠΡΩΙΝΌ' : item.customerSector.customer.dayTime === 'night' ? 'ΑΠΟΓΕΥΜΑΤΙΝΌ' : '') : '';
                                item.myTarget = item.myTarget === true ? 'Ναι' : 'Όχι';
                            }
                        });
                        return data;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: {editable: false, nullable: false},
                            isActive: {
                                type: "boolean",
                                defaultValue: true,
                                parse: function (value) {
                                    return !!(value == "1" || value == "true" || value == true);
                                }
                            }
                        }
                    }
                }
            }),
            sortable: true,
            groupable: false,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: "multiple, row",
            columns: [
                { selectable: true, width: 20 },
                {
                    title: "Στοιχεία Επαγγελματία Υγείας",
                    columns: [
                        {
                            field: "customerSector.customer.lastName",
                            title: "Επώνυμο",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 140
                        },
                        {
                            field: "customerSector.customer.firstName",
                            title: "Όνομα",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 120
                        },
                        {
                            field: "customerSector.customer.doctorSpecialty.name",
                            title: "Ειδικοτητα 1η",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 130
                        },
                        {
                            field: "customerSector.customer.customerCategory.name",
                            title: "Κατηγορία",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 60
                        },
                        {
                            field: "customerSector.customer.customerSubCategory.name",
                            title: "Υποκατηγορία",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 60
                        }
                    ]
                },
                {
                    title: "Στοιχεία Μονάδας Υγείας",
                    columns: [
                        {
                            field: "customerSector.sector.sectorType.name",
                            title: "Τύπος",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 120
                        },
                        {
                            field: "customerSector.sector.name",
                            title: "Όνομα",
                            filterable: {
                                cell: {
                                    operator: "contains",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 190
                        },
                        {
                            field: "customerSector.sector.address",
                            title: "Διεύθυνση",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 120
                        },
                        {
                            field: "customerSector.sector.cityName",
                            title: "Πόλη",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 90
                        },
                        {
                            field: "customerSector.sector.prefectureName",
                            title: "Νομός",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 80
                        }
                    ]
                },
                {
                    field: "dayTime",
                    title: "Ωράριο",
                    template: "#= (dayTime == 'ΠΡΩΙΝΌ') ? 'ΠΡΩΙΝΌ' : (dayTime == 'ΑΠΟΓΕΥΜΑΤΙΝΌ') ? 'ΑΠΟΓΕΥΜΑΤΙΝΌ' : '' #",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            showOperators: false,
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind: false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{text: "ΠΡΩΙΝΌ", value: "ΠΡΩΙΝΌ"},
                                            {text: "ΑΠΟΓΕΥΜΑΤΙΝΌ", value: "ΑΠΟΓΕΥΜΑΤΙΝΌ"}]
                                    }),
                                    valuePrimitive: true
                                });
                            }
                        }
                    },
                    width: 100
                },
                {
                    field: "myTarget",
                    title: "MyTarget",
                    filterable: {
                        cell: {
                            operator: "eq",
                            showOperators: false,
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind: false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{text: "Ναι", value: "Ναι"},
                                            {text: "Όχι", value: "Όχι"}]
                                    }),
                                    valuePrimitive: true
                                });
                            }
                        }
                    },
                    width: 100
                }
            ],
            toolbar: [
                {
                    template: "<label>Ιατρικός Επισκέπτης: </label><select name='employeeSelect' id='employeeSelect' kendo-combo-box k-ng-model='vm.selectedEmployeeId' k-options='vm.employeeOptions' style='width: 20%'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label class='left-space'>My Target: </label><div class='inline'>" +
                    "<md-radio-group class='left-space' layout='row' ng-change='vm.mainGridOptions.dataSource.read()' ng-model='vm.masterFilters.myTarget'>" +
                    "<md-radio-button ng-value=true class='md-primary'> Ναι </md-radio-button>" +
                    "<md-radio-button ng-value=false class='md-primary'> Όχι </md-radio-button>" +
                    "<md-radio-button ng-value='' class='md-primary'> Όλα </md-radio-button></md-radio-group></div>",
                    overflow: "never", attributes: {class: "left-space"}
                },
                {
                    name: "deleteAction",
                    template: "<md-button id='deleteButton' class='md-raised md-mini md-warn' style='margin-left: 50px;' aria-label='deleteSelected' ng-click='vm.deleteSelected($event)'>" +
                                "<md-icon class='icon-delete'></md-icon><span id='deleteButtonLabel'>ΔΙΑΓΡΑΦΗ</span>" +
                              "</md-button>"
                },
                {
                    name: "memo",
                    template: "<label style='font-style: italic; margin-left: 20px;'>Κρατήστε πατημένο το πλήκτρο Ctrl για πολλαπλή επιλογή</label>"
                },
                {
                    name: "sum",
                    template: "<div class='right'><label style='margin-top: 8px;'>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label></div>"
                }
            ],
            height: CustomUtils.getHeightPixelsFromPercentage(62),
            noRecords: {
                template: "Δεν βρέθηκαν Επαγγελματίες Υγείας"
            },
            dataBound: function () {
                vm.selectedCustomersIds = [];
                updateDeleteButton();
            },
            change: function (e) {
                var grid = $("#mainGrid").data("kendoGrid");
                var rows = e.sender.select();
                vm.selectedCustomersIds = [];
                rows.each(function(e) {
                    var dataItem = grid.dataItem(this);
                    vm.selectedCustomersIds.push(dataItem.id);
                });
                updateDeleteButton();
            }
        };

        function updateDeleteButton() {
            var deleteButton = angular.element(document.getElementById('deleteButton'))[0];
            var deleteButtonLabel = angular.element(document.getElementById('deleteButtonLabel'))[0];
            if (vm.selectedCustomersIds.length > 0) {
                deleteButton.disabled = false;
                deleteButtonLabel.innerHTML = "Διαγραφή (" + vm.selectedCustomersIds.length + ")";
            }else {
                deleteButton.disabled = true;
                deleteButtonLabel.innerHTML = "Διαγραφή";
            }
        }

        $timeout(function () {
            // Initially disable delete button
            angular.element(document.getElementById('deleteButton'))[0].disabled = true;
        });
    }
})();
