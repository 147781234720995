(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('DoctorSpecialtySearch', DoctorSpecialtySearch);

    DoctorSpecialtySearch.$inject = ['$resource'];

    function DoctorSpecialtySearch($resource) {
        var resourceUrl =  'api/_search/doctor-specialties/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
