(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('EventCustomerSectorDeleteController',EventCustomerSectorDeleteController);

    EventCustomerSectorDeleteController.$inject = ['$uibModalInstance', 'entity', 'EventCustomerSector'];

    function EventCustomerSectorDeleteController($uibModalInstance, entity, EventCustomerSector) {
        var vm = this;
        vm.eventCustomerSector = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            EventCustomerSector.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
