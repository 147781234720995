(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminCustomersController', AdminCustomersController);

    AdminCustomersController.$inject = ['$http', '$filter', '$state', 'Principal', '$timeout', 'KendoGrid', 'customersView', 'eventCycle', 'MasterFiltersService', '$mdDialog', 'KendoService', 'KendoFilter', 'CustomUtils'];

    function AdminCustomersController ($http, $filter, $state, Principal, $timeout, KendoGrid, customersView, eventCycle, MasterFiltersService, $mdDialog, KendoService, KendoFilter, CustomUtils) {
        var vm = this;
        vm.customerUrl = "/api/customers";
        vm.pageHeading = 'Επαγγελματιες Υγειας';
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(60);
        vm.principal = Principal;
        vm.selectedEmployees = [];
        vm.selectedSupervisors = [];
        vm.selectedSoftwares = [];
        vm.customerView = customersView.data.content[0].value;
        vm.eventCycle = eventCycle.data !== null && eventCycle.data.content !== null && eventCycle.data.content.length > 0 ? eventCycle.data.content[0] : null;
        vm.messageWnd = {};
        vm.myTarget = true;
        vm.isActive = true;
        vm.employeeCustomerSectorActive = true;

        var softwareEnumValues = ['none', 'medexpress', 'medmobile', 'other'];

        var enumerationFilterTemplate = function (args) {
            KendoFilter.getEnumerationTemplate(args, 'software', softwareEnumValues);
        };
        var dataSource = {};
        dataSource.doctorSpecialty = KendoService.initDefaultDataSource('/api/masterdata/doctor-specialties');
        dataSource.category = KendoService.initDefaultDataSource('/api/masterdata/customer-categories');
        dataSource.subCategory = KendoService.initDefaultDataSource('/api/masterdata/customer-sub-categories');
        dataSource.title = KendoService.initDefaultDataSource('/api/masterdata/customer-titles');
        dataSource.customerType = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function (o) {
                    if (o.data.filter == undefined) o.data.filter = {filters: []};

                    o.data.page = 1;
                    o.data.pageSize = 10;
                    $http.get('api/masterdata/customer-types',
                        {params: o.data})
                        .success(function (response, status, headers) {
                            var customerTypes = response.content;
                            var filteredCustomerTypes = [];

                            // Add only specific customerTypes
                            customerTypes.forEach(function (currentValue) {
                                if (currentValue.id === 1) {
                                    filteredCustomerTypes.push(currentValue);
                                }
                            });

                            // check application parameter Approvals
                            $http.get('/api/masterdata/application-parameters',
                                {params:{
                                    page: 1,
                                    pageSize: 1,
                                    filter : { filters: [{field: "name", operator: "eq",  value: "Patches"}] }}})
                                .success(function (response,status,headers) {
                                    if (response.content.length > 0 && response.content[0].value == '1') {
                                        customerTypes.forEach(function (currentValue) {
                                            if (currentValue.id === 2) {
                                                filteredCustomerTypes.push(currentValue);
                                            }
                                        });
                                    }
                                    o.success(filteredCustomerTypes);
                                })
                                .error(function (response) {});
                        })
                        .error(function (response) {});
                }
            }
        });
        dataSource.dayTimeDataSource = new kendo.data.DataSource({
            data: [
                { value: 'day', text: "ΠΡΩΙΝΌ"},
                { value: 'night',  text: "ΑΠΟΓΕΥΜΑΤΙΝΌ"}
            ]
        });

        dataSource.software = [{text: 'Επιλογή όλων', value: 'all'}, {text: 'Όχι', value: 'none'},
            {text: $filter('translate')('global.field.software.medexpress'), value: 'medexpress'},
            {text: $filter('translate')('global.field.software.medmobile'), value: 'medmobile'},
            {text: $filter('translate')('global.field.software.other'), value: 'other'}];

        vm.softwareOptions = {
            dataSource: dataSource.software,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "text",
            dataValueField: "value",
            placeholder: "Επιλογή λογισμικού",
            select: function (e) {
                var dataItem = this.dataSource.view()[e.item.index()];
                var values = this.value();

                if (dataItem.value === "all" || dataItem.value === "none") {
                    this.value("");
                } else if (values.indexOf("all") !== -1) {
                    values = angular.element.grep(values, function(value) {
                        return value !== "all";
                    });
                    this.value(values);
                } else if (values.indexOf("none") !== -1) {
                    values = angular.element.grep(values, function(value) {
                        return value !== "none";
                    });
                    this.value(values);
                } else if (values.indexOf("other") !== -1) {
                    values = angular.element.grep(values, function(value) {
                        return value !== "other";
                    });
                    this.value(values);
                }
            },
            change: function () {
                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
            }
        };

        vm.toolTipOptions = {
            filter: "td span",
            position: "top",
            content: function(e){
                var element = e.target[0];
                e.sender.popup.element.css("visibility", "hidden");

                if (element.offsetWidth < element.scrollWidth) {

                    e.sender.popup.element.css("visibility", "visible");

                    return element.innerText;
                }

                return "";
            }
        };

        vm.clearDatePickers = function () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        };

        vm.masterFiltersOnClick = function (e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id == "today") {
                vm.clearDatePickers();
                vm.from = date;
                vm.to = date;
            }
            else if (e.id == "week") {
                vm.clearDatePickers();
                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6

                vm.from = firstWeekDay;
                vm.to = lastWeekDay;
            }
            else if (e.id == "month") {
                vm.clearDatePickers();
                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                vm.from = firstMonthDay;
                vm.to = lastMonthDay;
            }
            angular.element("#mainGrid").data("kendoGrid").dataSource.read();
        };

        vm.toolbarOptions = {
            items: [
                // {
                //     type: "buttonGroup", id:"buttonGroup",
                //     buttons: [
                //         { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                //         { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                //         { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                //     ], overflow: "never"
                // },
                { type: "separator", overflow: "never" },
                { type: "separator", overflow: "never" },
                { template: "<label>Από: </label>", overflow: "never" },
                {
                    template: "<input kendo-date-picker id='from' class='smaller-8em-k-datepicker' options='vm.fromOptions'/>",
                    overflow: "never"
                },
                { template: "<label>Εώς: </label>", overflow: "never" },
                {
                    template: "<input kendo-date-picker id='to' class='smaller-8em-k-datepicker' options='vm.toOptions'/>",
                    overflow: "never"
                },
                {
                    template: "<label>My Target: </label><div class='inline'><md-radio-group layout='row' ng-change='vm.onFilterChange()' ng-model='vm.myTarget'>" +
                    "<md-radio-button ng-value=true class='md-primary'> Ναι </md-radio-button>" +
                    "<md-radio-button ng-value=false class='md-primary'> Όχι </md-radio-button>" +
                    "<md-radio-button ng-value='' class='md-primary'> Όλα </md-radio-button></md-radio-group></div>",
                    overflow: "never", attributes: {class: "left-space"}
                },
                {
                    template: "<label>Ενεργός: </label><md-checkbox aria-label='isActive' ng-model='vm.isActive' ng-change='vm.onFilterChange()' class='checkbox-toolbar green inline'></md-checkbox>",
                    overflow: "never", attributes: {class: "left-space"}
                },
                {
                    template: "<label>Ενεργή ΜΥ: </label><md-checkbox aria-label='employeeCustomerSectorActive' ng-model='vm.employeeCustomerSectorActive' ng-change='vm.onFilterChange()' class='checkbox-toolbar green inline'></md-checkbox>",
                    overflow: "never", attributes: {class: "left-space"}
                },
                {
                    template: "<div ng-if='vm.customerView === \"3\"'><label>Λογισμικό: </label><select kendo-multi-select multiple='multiple' id='multiSelect' name='multiSelect' class='inline' k-ng-model='vm.selectedSoftwares' " +
                    "                            k-options='vm.softwareOptions' class='col-fix'></select></div>",
                    overflow: "never", attributes: {class: "left-space"}
                },
                // {
                //     template: "<label>Τ.Κ.: </label><input class='k-textbox' id='postalCode' ng-change='vm.onFilterChange()' ng-model='vm.postalCode'>",
                //     overflow: "never"
                // },
                {
                    template: "<div ng-if='vm.customerView !== \"6\"'>" +
                        "<label>Brick ΟΣΦΕ: </label>" +
                        "<input class='k-textbox' id='osfeBrick' ng-change='vm.onFilterChange()' ng-model='vm.osfeBrick'>" +
                        "</div>",
                    overflow: "never"
                },
                {
                    template: "<div ng-if='vm.customerView === \"6\"'>" +
                        "<label>Brick: </label>" +
                        "<input class='k-textbox' id='brick' ng-change='vm.onFilterChange()' ng-model='vm.brick'>" +
                        "</div>",
                    overflow: "never"
                },
                { type: "separator", overflow: "never" },
                { type: "separator", overflow: "never" }
            ]
        };

        vm.onFilterChange = function () { angular.element("#mainGrid").data("kendoGrid").dataSource.read(); };

        vm.fromOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.from = value;
                }

                vm.masterFiltersOnClick(e);
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.to = value;
                }

                vm.masterFiltersOnClick(e);
            }
        };

        vm.gridColumns = [
            {
                field: "lastName",
                attributes: {
                    class: "link-cell link-cell-color"
                },
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 140
            },
            {
                field: "firstName",
                attributes: {
                    class: "link-cell link-cell-color"
                },
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 140
            },
            {
                field: "doctorSpecialty.name",
                title: "Ειδικότητα 1η",
                editor: function(container, options) {
                    angular.element('<select id="doctor-specialty" name="doctorSpecialty" required data-required-msg="Επιλέξτε Ειδικότητα" data-bind="value:doctorSpecialty.id"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: dataSource.doctorSpecialty,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        }).kendoValidator().data("kendoValidator");
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {
                hidden: vm.customerView === '3' || vm.customerView === '1' || vm.customerView === '6',
                field: "doctorSpecificSpecialty.name",
                title: "Ειδικότητα 2η",
                editor: function(container) {
                    angular.element('<select id="doctor-specific-specialty" data-bind="value:doctorSpecificSpecialty.id"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: dataSource.doctorSpecialty,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {
                hidden: vm.customerView === '3' || vm.customerView === '1' || vm.customerView === '6',
                field: "amka",
                title: "A.M.K.A.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 80
            },
            {
                field: "customerCategory.name",
                title: "Κατηγορία",
                editor: function(container) {
                    angular.element('<select id="customer-category" data-bind="value:customerCategory.id"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: dataSource.category,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 70
            },
            {
                field: "customerSubCategory.name",
                title: "Υποκατηγορία",
                editor: function(container) {
                    angular.element('<select id="customer-subcategory" data-bind="value:customerSubCategory.id"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: dataSource.subCategory,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 90
            },
            {
                hidden: vm.customerView === '1' || vm.customerView === '6',
                field: "cellPhone",
                title: "Κινητό",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 79
            },
            {
                hidden: vm.customerView === '1' || vm.customerView === '6',
                field: "phone",
                title: "Σταθερό Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 110
            },
            // {
            //     field: "email",
            //     title: "Email",
            //     filterable: {
            //         cell: {
            //             operator: "startswith",
            //             template: function (args) {
            //                 KendoFilter.getTemplate(args);
            //             },
            //             showOperators: false
            //         }
            //     },
            //     width: 151
            // },
            {
                hidden: vm.customerView === '3' || vm.customerView === '1' || vm.customerView === '6',
                field: "dayTime",
                title: "Ωράριο",
                template: "#= (dayTime == 'day') ? 'ΠΡΩΙΝΌ' : (dayTime == 'night') ? 'ΑΠΟΓΕΥΜΑΤΙΝΌ' : '' #",
                editor: function(container) {
                    angular.element('<select id="day-time" data-bind="value:dayTime"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: dataSource.dayTimeDataSource,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "text",
                            dataValueField: "value"
                        });
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false,
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ value: "day", text: "ΠΡΩΙΝΌ" },
                                        { value: "night", text: "ΑΠΟΓΕΥΜΑΤΙΝΌ" }]
                                }),
                                valuePrimitive: true

                            })
                        }
                    }
                },
                width: 120
            },
            {
                hidden: vm.customerView === '3' || vm.customerView === '1' || vm.customerView === '6',
                field: "customerTitle.name",
                title: "Τίτλος",
                editor: function(container) {
                    angular.element('<select id="customer-title" data-bind="value:customerTitle.id"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: dataSource.title,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 120
            },
            // {
            //     field: "isActive",
            //     title: "Ενεργός",
            //     filterable: {
            //         cell: {
            //             operator: "eqbool",
            //             showOperators: false,
            //             template: function (args) {
            //                 args.element.kendoDropDownList({
            //                     autoBind:false,
            //                     dataTextField: "text",
            //                     dataValueField: "value",
            //                     dataSource: new kendo.data.DataSource({
            //                         data: [{ text: "Ναι", value: "true" },
            //                             { text: "Όχι", value: "false" }]
            //                     }),
            //                     valuePrimitive: true
            //
            //                 })
            //             }
            //         }
            //     },
            //     width: 110,
            //     template: "#= (isActive == '1') ? 'Ναι' : 'Όχι' #"
            // },
            {
                hidden: vm.customerView !== '3',
                field: "sector.sectorType.name",
                title: "Τύπος",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 190
            },
            {
                hidden: vm.customerView !== '3' && vm.customerView !== '1' && vm.customerView !== '6',
                field: "sector.name", title: "Μονάδα",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 200
            },
            {
                hidden: vm.customerView !== '3' && vm.customerView !== '1',
                field: "sector.osfeBrick",
                title: "Brick ΟΣΦΕ",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 120
            },
            {
                hidden: vm.customerView !== '6',
                field: "sector.brick",
                title: "Brick",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 80
            },
            // {
            //     field: "sector.osfeBrick",
            //     title: "Brick",
            //     filterable: {
            //         cell: {
            //             operator: "startswith",
            //             template: function (args) {
            //                 KendoFilter.getTemplate(args);
            //             },
            //             showOperators: false
            //         }
            //     },
            //     width: 115
            // },
            {
                hidden: vm.customerView !== '3' && vm.customerView !== '1' && vm.customerView !== '6',
                field: "sector.cityName",
                title: "Πόλη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 190
            },
            {
                hidden: vm.customerView !== '3' && vm.customerView !== '1' && vm.customerView !== '6',
                field: "sector.prefectureName",
                title: "Νομός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 190
            },
            {
                hidden: true,
                field: "sector.address",
                title: "Διεύθυνση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 190
            },
            {
                hidden: vm.customerView !== '3',
                field: "software",
                title: "Λογισμικό",
                template: function (dataItem) {
                    return dataItem.software ? $filter('translate')('global.field.software.' + dataItem.software ) : 'Όχι';
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false,
                        template: enumerationFilterTemplate
                    }
                },
                width: 100
            },
            {
                hidden: vm.customerView !== '3',
                field: "lastVisitDate", title: "Ημέρες Τελ. Επίσκεψης",
                filterable: {
                    operators: {
                        number: {
                            eqdate: 'Ίσο',
                            gte: 'Μικρότερο ή Ίσο',
                            lte: 'Μεγαλύτερο ή Ίσο'
                        }
                    },
                    cell: {
                        template: function (args) {
                            args.element.kendoNumericTextBox({
                                format: "n0",
                                decimals: 0,
                                min: 0
                            });
                        }
                    }
                },
                template: function (dataItem) {
                    if (vm.eventCycle !== null && vm.eventCycle.value !== null &&
                        dataItem.lastVisitDate !== null && dataItem.lastVisitDate !== undefined){
                        if (Number(dataItem.lastVisitDate) > Number(vm.eventCycle.value)) {
                            return "<strong style='color: red'>" + kendo.htmlEncode(dataItem.lastVisitDate) + "</strong>"
                        }else {
                            return kendo.htmlEncode(dataItem.lastVisitDate)
                        }
                    }else {
                        return dataItem.lastVisitDate !== null && dataItem.lastVisitDate !== undefined ? dataItem.lastVisitDate : '';
                    }
                },
                width: 150
            },
            {
                hidden: vm.customerView !== '3' && vm.customerView !== '1' && vm.customerView !== '6',
                field: "employeeCustomerSectors", title: "Ιατρικοί Επισκέπτες",
                template: function (dataItem) {
                    return KendoFilter.getEmployeeCustomerSectorsTemplate(dataItem);
                },
                filterable: false,
                sortable: false
            },
            {
                hidden: true,
                field: "customerTypes",
                title: "Τύποι Επαγγελματία Υγείας",
                filterable: false,
                editor: function (container) {
                    angular.element('<select id="customerTypes" name="customerTypes" multiple="multiple" required data-required-msg="Επιλέξτε Τύπο Επαγγελματία Υγείας" data-bind="value: customerTypes"/>')
                        .appendTo(container)
                        .kendoMultiSelect({
                            dataSource: dataSource.customerType,
                            valuePrimitive: false,
                            dataTextField: "name",
                            dataValueField: "id"
                        }).kendoValidator().data("kendoValidator");
                },
                width: 120
            },
            {
                hidden: true,
                field: "gdpr",
                title: "GDPR",
                filterable: false,
                width: 120
            }
            // {
            //     hidden: vm.customerView !== '3',
            //     field: "collaboration.name",
            //     title: "Συνεργασία",
            //     editor: function(container) {
            //         var collaborationDatasource = KendoService.initDefaultDataSource('/api/collaborations/search');
            //         angular.element('<select id="collaboration" data-bind="value:collaboration.id"></select>')
            //             .appendTo(container)
            //             .kendoComboBox({
            //                 dataSource: collaborationDatasource,
            //                 filter: "startswith",
            //                 valuePrimitive: true,
            //                 dataTextField: "name",
            //                 dataValueField: "id"
            //             });
            //     },
            //     filterable: {
            //         cell: {
            //             operator: "startswith",
            //             template: function (args) {
            //                 KendoFilter.getTemplate(args);
            //             },
            //             showOperators: false
            //         }
            //     },
            //     width: 100
            // },
            // {field: "createdDate", title: "Ημερομηνία Εισαγωγής", template: "#= kendo.toString(kendo.parseDate(createdDate), 'dddd, dd/MM/yyyy HH:mm')#",
            //     filterable: {
            //         cell: {
            //             operator: "eqdate",
            //             showOperators: false
            //         }
            //     },
            //     width: 140
            // }
        ];

        vm.showConfirm = function() {
            $mdDialog.show({
                parent: angular.element(document.body),
                templateUrl : 'app/components/md-dialog/dialog-template.html',
                controller  : 'mdDialogCtrl',
                controllerAs: 'vm',
                fullscreen: true,
                locals : {
                    existedCustomers : vm.existedCustomers,
                    confirmSaveCustomer : function(){
                        $mdDialog.hide();
                        vm.saveCustomer(vm.customerToSave);
                    },
                    closeDialog : function(){
                        $mdDialog.hide();
                        KendoGrid.refresh(vm.mainGridOptions);
                    }
                }
            });
        };

        vm.saveCustomer = function(customer) {
            $http.post(vm.customerUrl, customer)
                .success(function (response) {
                    var newCustomerId = response.id;
                    $state.go('customerInfo', {id: newCustomerId});
                })
                .error(function (response) {});
        };

        vm.onAddCustomerClick = function () {
            const grid = $("#mainGrid").data("kendoGrid");
            grid.addRow();
        };

        vm.mainGridOptions = {};

        if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR'])) {
            vm.supervisorsMultiDataSource = new kendo.data.DataSource({
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: function (o) {
                        var url = '/api/me/employees';
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }
                        if (o.data.sort == undefined || !o.data.sort.length) {
                            o.data.sort = { "field": "account.lastName",  "dir": "asc" };
                        }
                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "account.authorities", "operator": "eq",
                                    "value": "ROLE_SUPERVISOR" },
                                { "field": "account.activated", "operator": "eqbool",
                                    "value": true }
                            ]
                        );
                        o.data.page = 1;
                        o.data.pageSize = 20;
                        $http.get(url,
                            {params: o.data})
                            .success(function (response) {
                                o.success(response.content);
                            })
                            .error(function (response) {});
                    }
                },
                schema: {
                    parse: function (data) {
                        data.forEach(function (item) {
                            item.employee = {
                                account: {
                                    fullName: item.account.lastName + " " + item.account.firstName
                                }
                            };
                        });
                        return data;
                    }
                }
            });
            vm.supervisorsMultiOptions = {
                dataSource: vm.supervisorsMultiDataSource,
                filter: "startswith",
                valuePrimitive: true,
                dataTextField: "employee.account.fullName",
                dataValueField: "id",
                placeholder: "Επιλογή προισταμένου",
                change: function (e) {
                    vm.employeesMulti.value('');
                    vm.selectedEmployees = [];
                    vm.employeesMulti.dataSource.read();
                    angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                }
            };

            vm.employeesMultiDataSource = new kendo.data.DataSource({
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: function (o) {
                        var url = '/api/me/employees';
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }
                        if (o.data.sort == undefined || !o.data.sort.length) {
                            o.data.sort = { "field": "account.lastName",  "dir": "asc" };
                        }
                        if(vm.selectedSupervisors.length) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "supervisor.id", "operator": "in",
                                        "value": vm.selectedSupervisors.toString() }
                                ]
                            );
                        }
                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "account.activated", "operator": "eqbool",
                                    "value": true }
                            ]
                        );
                        o.data.page = 1;
                        o.data.pageSize = 20;
                        $http.get(url, {params: o.data})
                            .success(function (response) {
                                o.success(response.content);
                            })
                            .error(function (response) {});
                    }
                },
                schema: {
                    parse: function (data) {
                        data.forEach(function (item) {
                            item.employee = {
                                account: {
                                    fullName: item.account.lastName + " " + item.account.firstName
                                }
                            };
                        });
                        return data;
                    }
                }
            });
            vm.employeesMultiOptions = {
                dataSource: vm.employeesMultiDataSource,
                filter: "startswith",
                valuePrimitive: true,
                dataTextField: "employee.account.fullName",
                dataValueField: "id",
                placeholder: "Επιλογή Ιατρικού Επισκέπτη",
                change: function (e) {
                    angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                }
            };

            vm.mainGridOptions = {
                dataSource: new kendo.data.DataSource({
                transport: {
                    read: function (o) {
                        if (o.data.filter == undefined) o.data.filter = {filters: []};

                        //master filters functionality
                        if (vm.from && vm.to) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    {
                                        "field": "createdDate", "operator": "from",
                                        "value": $filter('date')(vm.from, 'yyyy-MM-ddT00:00:00.000') + 'Z'
                                    },
                                    {
                                        "field": "createdDate", "operator": "to",
                                        "value": $filter('date')(vm.to, 'yyyy-MM-ddT23:59:59.000') + 'Z'
                                    }
                                ]
                            );
                        }

                        if (vm.selectedEmployees.length) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    {
                                        "field": "customerSectors.employeeCustomerSectors.employee.id",
                                        "operator": "in",
                                        "value": vm.selectedEmployees.toString()
                                    }
                                ]
                            );
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                {
                                    "field": "customerSectors.employeeCustomerSectors.myTarget",
                                    "operator": "eqbool",
                                    "value": vm.myTarget
                                }
                            ]
                        );

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                {
                                    "field": "isActive",
                                    "operator": "eqbool",
                                    "value": vm.isActive
                                }
                            ]
                        );

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                {
                                    "field": "customerSectors.employeeCustomerSectors.active",
                                    "operator": "eqbool",
                                    "value": vm.employeeCustomerSectorActive
                                }
                            ]
                        );

                        // if (vm.postalCode && vm.postalCode != "" && vm.postalCode != null) {
                        //     o.data.filter.filters = o.data.filter.filters.concat(
                        //         [
                        //             {
                        //                 "field": "customerSectors.sector.postalCode", "operator": "startswith",
                        //                 "value": vm.postalCode
                        //             }
                        //         ]
                        //     );
                        // }
                        //
                        if (vm.osfeBrick && vm.osfeBrick != "" && vm.osfeBrick != null) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    {
                                        "field": "customerSectors.sector.osfeBrick", "operator": "startswith",
                                        "value": vm.osfeBrick
                                    }
                                ]
                            );
                        }

                        if (vm.brick && vm.brick != "" && vm.brick != null) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    {
                                        "field": "customerSectors.sector.brick", "operator": "startswith",
                                        "value": vm.brick
                                    }
                                ]
                            );
                        }

                        if (vm.selectedSupervisors.length && !vm.selectedEmployees.length) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    {
                                        "field": "customerSectors.employeeCustomerSectors.employee.id",
                                        "operator": "in_with_hierarchy",
                                        "value": vm.selectedSupervisors.toString()
                                    }
                                ]
                            );
                        }

                        if(vm.selectedSoftwares.length) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "software", "operator":  vm.selectedSoftwares.indexOf('none') > -1 ? "isnull" : "in",
                                        "value": vm.selectedSoftwares.indexOf('all') > -1 ? "medexpress,medmobile,other" :
                                                vm.selectedSoftwares.indexOf('none') > -1 ? null :
                                                vm.selectedSoftwares.toString() }
                                ]
                            );
                        }

                        angular.forEach(o.data.filter.filters, function (item) {
                            if ( item.field === 'software' && item.value === 'none' ) {
                                item.operator = "isnull";
                                item.value = null;
                            }
                            if ( item.field === 'lastVisitDate' ) {
                                var dt = new Date();
                                dt.setDate( dt.getDate() - item.value );
                                item.value = dt;
                            }
                            if (item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        if (vm.customerView === '3' || vm.customerView === '1' || vm.customerView === '6') {
                            vm.url = "/api/me/customers/main";
                        } else {
                            vm.url = "/api/me/customers";
                        }

                        $http.get(vm.url, {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    },
                    create: function (o) {
                        var data = o.data.models[0];

                        if (data.customerCategory && !data.customerCategory.id)
                            data.customerCategory = null;

                        if (data.customerSubCategory && !data.customerSubCategory.id)
                            data.customerSubCategory = null;

                        if (data.customerTitle && !data.customerTitle.id)
                            data.customerTitle = null;

                        if (data.doctorSpecialty && !data.doctorSpecialty.id)
                            data.doctorSpecialty = null;

                        if (data.doctorSpecificSpecialty && !data.doctorSpecificSpecialty.id)
                            data.doctorSpecificSpecialty = null;

                        if (data.collaboration && !data.collaboration.id)
                            data.collaboration = null;

                        $http.post('/api/customers/exists', data)
                            .success(function (response) {
                                vm.existedCustomers = response;
                                vm.customerToSave = data;
                                if (vm.existedCustomers.length) {
                                    vm.showConfirm();
                                } else {
                                    vm.saveCustomer(data);
                                }
                            })
                            .error(function (response) {});
                    },
                    update: function (o) {
                        var data = o.data.models[0];

                        if (data.customerCategory && !data.customerCategory.id)
                            data.customerCategory = null;

                        if (data.customerSubCategory && !data.customerSubCategory.id)
                            data.customerSubCategory = null;

                        if (data.customerTitle && !data.customerTitle.id)
                            data.customerTitle = null;

                        if (data.doctorSpecialty && !data.doctorSpecialty.id)
                            data.doctorSpecialty = null;

                        if (data.doctorSpecificSpecialty && !data.doctorSpecificSpecialty.id)
                            data.doctorSpecificSpecialty = null;

                        if (data.collaboration && !data.collaboration.id)
                            data.collaboration = null;

                        $http.put(vm.customerUrl, data)
                            .success(function () {
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {});
                    }
                },
                batch: true,
                schema: {
                    data: function (data) {
                        if (data.content !== undefined) {
                            data.content.forEach(function (item) {
                                if (item.doctorSpecialty === null) item.doctorSpecialty = {};
                                if (item.doctorSpecificSpecialty === null) item.doctorSpecificSpecialty = {};
                                if (item.customerCategory === null) item.customerCategory = {};
                                if (item.customerSubCategory === null) item.customerSubCategory = {};
                                if (item.customerTitle === null) item.customerTitle = {};
                                if (item.collaboration === null) item.collaboration = {};
                                if (item.sector === null || !item.sector) item.sector = {};
                                if (item.sector.sectorType === null || !item.sector.sectorType) item.sector.sectorType = {};

                                item.employeesString = "";
                                if (item.employees) {
                                    item.employees.forEach(function (employeeName) {
                                        item.employeesString += "[" + employeeName + "] ";
                                    });
                                }



                            });
                        }
                        return data.content;

                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    parse: function (data) {
                        if (data.content !== undefined) {
                            var dt = new Date();
                            dt.setHours(23, 59, 59);
                            data.content.forEach(function (item) {
                                item.lastVisitDate =
                                    item.lastVisitDate ? Math.floor(Math.abs(new Date(item.lastVisitDate).getTime() - dt.getTime()) / (1000 * 60 * 60 * 24)) : '';
                            });
                        }
                        return data;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true},
                            firstName: {type: "string", validation: {required: { message: "Απαραίτητο Πεδίο" } } },
                            lastName: {type: "string", validation: {required: { message: "Απαραίτητο Πεδίο" } } },
                            isActive:{
                                type: "boolean",
                                defaultValue: true,
                                parse: function(value) {
                                    return !!(value == "1" || value == "true" || value == true);

                                }
                            },
                            saw:{
                                type: "boolean",
                                defaultValue: true,
                                parse: function(value) {
                                    return !!(value == "1" || value == "true" || value == true);

                                }
                            },
                            doctorSpecialty:{defaultValue: {id: null, name : null}},
                            doctorSpecificSpecialty:{defaultValue: {id: null, name : null}},
                            customerCategory:{defaultValue: { id : null, name : null}},
                            customerTitle:{defaultValue: { id : null, name : null}},
                            customerSubCategory:{defaultValue: { id : null, name : null}},
                            collaboration:{defaultValue: { id : null, name : null}},
                            dayTime:{defaultValue: null},
                            createdDate: { type: 'date', editable: false},
                            lastVisitDate: { type: 'number', editable: false },
                            customerTypes: {},
                            gdpr: { type: "boolean" },
                            sector: {
                                defaultValue:
                                    {
                                        id: null,
                                        sectorType: {defaultValue: {id: null, name: null}}
                                    }
                            }
                        }
                    }
                },
                pageSize: 50,
                serverPaging: true,
                serverSorting: true,
                scrollable: false,
                serverFiltering: true
            }),
                sortable: true,
                pageable: {
                    messages: {
                        display: "{0} - {1} από {2} Επαγγελματίες Υγείας", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                        empty: "Δεν βρέθηκαν Επαγγελματίες Υγείας",
                        page: "Σελίδα",
                        allPages: "Όλα",
                        of: "από {0}", // {0} is total amount of pages.
                        itemsPerPage: "εγγραφές ανά σελίδα",
                        first: "Πήγαινε στη πρώτη σελίδα",
                        previous: "Πήγαινε στη τελευταία σελίδα",
                        next: "Πήγαινε στην επόμενη σελίδα",
                        last: "Πήγαινε στη τελευταία σελίδα",
                        refresh: "Ανανέωση"
                    }
                },
                noRecords: {
                    template: "Δεν βρέθηκαν Επαγγελματίες Υγείας"
                },
                filterable: {
                mode: "row"
            },
                resizable: true,
                selectable: "cell",
                columns: vm.gridColumns,
                save: function () {
                var customersGrid = angular.element("#mainGrid").data("kendoGrid");
                customersGrid.refresh();
            },
                change: onChange,
                toolbar: vm.gridToolbar,
                editable: {
                    mode: "popup"
                },
                edit: function(e) {
                    KendoGrid.defaultEditPopup(this._editContainer);
                    //fix css
                    angular.element("input[name='isActive']").parent().addClass("k-checkbox-custom");
                    angular.element("div[data-container-for='customerTypes']").addClass("k-multi-custom");
                    angular.element("label[for='createdDate']").parent().next().addClass("k-text-custom");
                    e.container.find("[data-container-for='sector.sectorType.name']").prev().remove().end().remove();
                    e.container.find("[data-container-for='sector.osfeBrick']").prev().remove().end().remove();
                    e.container.find("[data-container-for='sector.brick']").prev().remove().end().remove();
                    e.container.find("[data-container-for='sector.cityName']").prev().remove().end().remove();
                    e.container.find("[data-container-for='sector.name']").prev().remove().end().remove();
                    e.container.find("[data-container-for='sector.prefectureName']").prev().remove().end().remove();
                    e.container.find("[data-container-for='employeesString']").prev().remove().end().remove();
                    e.container.find("[data-container-for='software']").prev().remove().end().remove();
                    e.container.find("label[for='lastVisitDate']").parent().next().remove().end().remove();
                    e.container.find("label[for='lastVisitDate']").prev().remove().end().remove();

                    if (vm.customerView === '3') {
                        e.container.find("[data-container-for='customerTitle.name']").prev().remove().end().remove();
                        e.container.find("[data-container-for='doctorSpecificSpecialty.name']").prev().remove().end().remove();
                        e.container.find("[data-container-for='amka']").prev().remove().end().remove();
                        e.container.find("[data-container-for='dayTime']").prev().remove().end().remove();
                    } else {
                        e.container.find("[data-container-for='collaboration.name']").prev().remove().end().remove();
                    }
                }
            };

            $timeout(function () {
                var toolbar = angular.element("#master_filters_customers").data("kendoToolBar");
                toolbar.add({
                    template: "<label>Προιστάμενος: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select multiple='multiple' " +
                    "k-options='vm.supervisorsMultiOptions' k-ng-model='vm.selectedSupervisors'></select>",
                    overflow: "never"
                });
                toolbar.add({
                    template: "<label>Ιατρικός Επισκέπτης: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.employeesMulti' multiple='multiple' " +
                    "k-options='vm.employeesMultiOptions' k-ng-model='vm.selectedEmployees'></select>",
                    overflow: "never"
                });

                MasterFiltersService.clearFilterEvent()
            });
        }else {
            vm.mainGridOptions = {
                dataSource: new kendo.data.DataSource({
                    transport: {
                        read: function (o) {
                            if (o.data.filter == undefined) o.data.filter = {filters: []};

                            //master filters functionality
                            if (vm.from && vm.to) {
                                o.data.filter.filters = o.data.filter.filters.concat(
                                    [
                                        {
                                            "field": "createdDate", "operator": "from",
                                            "value": $filter('date')(vm.from, 'yyyy-MM-ddT00:00:00.000') + 'Z'
                                        },
                                        {
                                            "field": "createdDate", "operator": "to",
                                            "value": $filter('date')(vm.to, 'yyyy-MM-ddT23:59:59.000') + 'Z'
                                        }
                                    ]
                                );
                            }

                            if (vm.selectedEmployees.length) {
                                o.data.filter.filters = o.data.filter.filters.concat(
                                    [
                                        {
                                            "field": "customerSectors.employeeCustomerSectors.employee.id",
                                            "operator": "in",
                                            "value": vm.selectedEmployees.toString()
                                        }
                                    ]
                                );
                            }

                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    {
                                        "field": "customerSectors.employeeCustomerSectors.myTarget",
                                        "operator": "eqbool",
                                        "value": vm.myTarget
                                    }
                                ]
                            );

                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    {
                                        "field": "isActive",
                                        "operator": "eqbool",
                                        "value": vm.isActive
                                    }
                                ]
                            );

                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    {
                                        "field": "customerSectors.employeeCustomerSectors.active",
                                        "operator": "eqbool",
                                        "value": vm.employeeCustomerSectorActive
                                    }
                                ]
                            );
                            // if (vm.postalCode && vm.postalCode != "" && vm.postalCode != null) {
                            //     o.data.filter.filters = o.data.filter.filters.concat(
                            //         [
                            //             {
                            //                 "field": "customerSectors.sector.postalCode", "operator": "startswith",
                            //                 "value": vm.postalCode
                            //             }
                            //         ]
                            //     );
                            // }
                            //
                            if (vm.osfeBrick && vm.osfeBrick != "" && vm.osfeBrick != null) {
                                o.data.filter.filters = o.data.filter.filters.concat(
                                    [
                                        {
                                            "field": "customerSectors.sector.osfeBrick", "operator": "startswith",
                                            "value": vm.osfeBrick
                                        }
                                    ]
                                );
                            }

                            if (vm.brick && vm.brick != "" && vm.brick != null) {
                                o.data.filter.filters = o.data.filter.filters.concat(
                                    [
                                        {
                                            "field": "customerSectors.sector.brick", "operator": "startswith",
                                            "value": vm.brick
                                        }
                                    ]
                                );
                            }

                            if(vm.selectedSoftwares.length) {
                                o.data.filter.filters = o.data.filter.filters.concat(
                                    [
                                        { "field": "software", "operator":  vm.selectedSoftwares.indexOf('none') > -1 ? "isnull" : "in",
                                            "value": vm.selectedSoftwares.indexOf('all') > -1 ? "medexpress,medmobile,other" :
                                                vm.selectedSoftwares.indexOf('none') > -1 ? null :
                                                    vm.selectedSoftwares.toString() }
                                    ]
                                );
                            }

                            if (vm.selectedSupervisors.length && !vm.selectedEmployees.length) {
                                o.data.filter.filters = o.data.filter.filters.concat(
                                    [
                                        {
                                            "field": "customerSectors.employeeCustomerSectors.employee.id",
                                            "operator": "in_with_hierarchy",
                                            "value": vm.selectedSupervisors.toString()
                                        }
                                    ]
                                );
                            }

                            angular.forEach(o.data.filter.filters, function (item) {
                                if ( item.field === 'software' && item.value === 'none' ) {
                                    item.operator = "isnull";
                                    item.value = null;
                                }
                                if ( item.field === 'lastVisitDate' ) {
                                    var dt = new Date();
                                    dt.setDate( dt.getDate() - item.value );
                                    item.value = dt;
                                }
                                if (item.value instanceof Date) {
                                    item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                                }
                            });

                            if (vm.customerView === '3' || vm.customerView === '1' || vm.customerView === '6') {
                                vm.url = "/api/me/customers/main";
                            } else {
                                vm.url = "/api/me/customers";
                            }

                            $http.get(vm.url, {params: o.data})
                                .success(function (response) {
                                    o.success(response);
                                })
                                .error(function (response) {});
                        },
                        create: function (o) {
                            var data = o.data.models[0];

                            if (data.customerCategory && !data.customerCategory.id)
                                data.customerCategory = null;

                            if (data.customerSubCategory && !data.customerSubCategory.id)
                                data.customerSubCategory = null;

                            if (data.customerTitle && !data.customerTitle.id)
                                data.customerTitle = null;

                            if (data.doctorSpecialty && !data.doctorSpecialty.id)
                                data.doctorSpecialty = null;

                            if (data.doctorSpecificSpecialty && !data.doctorSpecificSpecialty.id)
                                data.doctorSpecificSpecialty = null;

                            if (data.collaboration && !data.collaboration.id)
                                data.collaboration = null;

                            $http.post('/api/customers/exists', data)
                                .success(function (response) {
                                    vm.existedCustomers = response;
                                    vm.customerToSave = data;
                                    if (vm.existedCustomers.length) {
                                        vm.showConfirm();
                                    } else {
                                        vm.saveCustomer(data);
                                    }
                                })
                                .error(function (response) {});
                        },
                        update: function (o) {
                            var data = o.data.models[0];

                            if (data.customerCategory && !data.customerCategory.id)
                                data.customerCategory = null;

                            if (data.customerSubCategory && !data.customerSubCategory.id)
                                data.customerSubCategory = null;

                            if (data.customerTitle && !data.customerTitle.id)
                                data.customerTitle = null;

                            if (data.doctorSpecialty && !data.doctorSpecialty.id)
                                data.doctorSpecialty = null;

                            if (data.doctorSpecificSpecialty && !data.doctorSpecificSpecialty.id)
                                data.doctorSpecificSpecialty = null;

                            if (data.collaboration && !data.collaboration.id)
                                data.collaboration = null;

                            $http.put(vm.customerUrl, data)
                                .success(function () {
                                    angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                                })
                                .error(function (response) {});
                        }
                    },
                    batch: true,
                    schema: {
                        data: function (data) {
                            if (data.content !== undefined) {
                                data.content.forEach(function (item) {
                                    if (item.doctorSpecialty === null) item.doctorSpecialty = {};
                                    if (item.doctorSpecificSpecialty === null) item.doctorSpecificSpecialty = {};
                                    if (item.customerCategory === null) item.customerCategory = {};
                                    if (item.customerSubCategory === null) item.customerSubCategory = {};
                                    if (item.customerTitle === null) item.customerTitle = {};
                                    if (item.collaboration === null) item.collaboration = {};
                                    if (item.sector === null || !item.sector) item.sector = {};
                                    if (item.sector.sectorType === null || !item.sector.sectorType) item.sector.sectorType = {};
                                    item.employeesString = "";
                                    if (item.employees) {
                                        item.employees.forEach(function (employeeName) {
                                            item.employeesString += "[" + employeeName + "] ";
                                        });
                                    }
                                });
                            }
                            return data.content;

                        },
                        parse: function (data) {
                            if (data.content !== undefined) {
                                data.content.forEach(function (item) {
                                    var dt = new Date();
                                    dt.setHours(23, 59, 59);
                                    item.lastVisitDate =
                                        item.lastVisitDate ? Math.floor(Math.abs(new Date(item.lastVisitDate).getTime() - dt.getTime()) / (1000 * 60 * 60 * 24)) : '';
                                });
                            }
                            return data;
                        },
                        total: function (data) {
                            return data.totalEntries;
                        },
                        model: {
                            id: "id",
                            fields: {
                                id: { editable: false, nullable: true},
                                firstName: {type: "string", validation: {required: { message: "Απαραίτητο Πεδίο" } } },
                                lastName: {type: "string", validation: {required: { message: "Απαραίτητο Πεδίο" } } },
                                isActive:{
                                    type: "boolean",
                                    defaultValue: true,
                                    parse: function(value) {
                                        return !!(value == "1" || value == "true" || value == true);

                                    }
                                },
                                saw:{
                                    type: "boolean",
                                    defaultValue: true,
                                    parse: function(value) {
                                        return !!(value == "1" || value == "true" || value == true);

                                    }
                                },
                                doctorSpecialty:{defaultValue: {id: null, name : null}},
                                doctorSpecificSpecialty:{defaultValue: {id: null, name : null}},
                                customerCategory:{defaultValue: { id : null, name : null}},
                                customerTitle:{defaultValue: { id : null, name : null}},
                                customerSubCategory:{defaultValue: { id : null, name : null}},
                                collaboration:{defaultValue: { id : null, name : null}},
                                dayTime:{defaultValue: null},
                                createdDate: { type: 'date', editable: false},
                                lastVisitDate: { type: 'number', editable: false },
                                customerTypes: {},
                                gdpr: { type: 'boolean' },
                                sector: {
                                    defaultValue:
                                        {
                                            id: null,
                                            sectorType: {defaultValue: {id: null, name: null}}
                                        }
                                }
                            }
                        }
                    },
                    pageSize: 50,
                    serverPaging: true,
                    serverSorting: true,
                    scrollable: false,
                    serverFiltering: true
                }),
                sortable: true,
                pageable: true,
                filterable: {
                    mode: "row"
                },
                resizable: true,
                selectable: "cell",
                columns: vm.gridColumns,
                save: function () {
                    var customersGrid = angular.element("#mainGrid").data("kendoGrid");
                    customersGrid.refresh();
                },
                change: onChange,
                toolbar: vm.gridToolbar
            }
        }

        function onChange() {
            // Get selected cell
            var selected = this.select();
            // Get the row that the cell belongs to.
            var row = this.select().closest("tr");
            var cellIndex = selected[0].cellIndex;

            if(cellIndex === 0 || cellIndex === 1) {
                $state.go('customerInfo', {id: this.dataItem(row).id});
            }
        }
    }
})();
