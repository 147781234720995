/**
 * Created by gmogas on 31/3/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProductLotsController', ProductLotsController);

    ProductLotsController.$inject = ['KendoGrid', 'KendoNotifications', '$http', '$filter', 'KendoFilter', 'ApplicationParameters', 'CustomUtils'];

    function ProductLotsController (KendoGrid, KendoNotifications, $http, $filter, KendoFilter, ApplicationParameters, CustomUtils) {
        var vm = this;
        vm.pageHeading = $filter('translate')('masterData.productLots.title');
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(67);
        ApplicationParameters.hasModule('Purchase_Pharmacies', true);

        vm.onAddProductLotClick = function () {
            const grid = $("#mainGrid").data("kendoGrid");
            grid.addRow();
        };

        vm.productsDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 100;
                    $http.get('/api/masterdata/products',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        vm.errorPopup = function(response) {
            var centered = $("#centeredNotification").kendoNotification({
                stacking: "down",
                autoHideAfter: 0,
                show: KendoNotifications.onShow,
                button: true,
                hideOnClick: false,
                width: 500,
                height: 250
            }).data("kendoNotification");

            centered.show(' ' + $filter('translate')('masterData.messages.error.'
                    + response.params[0] + ' ') + ': '  + response.params[1], "error"); //.split(" ")[0]
        };

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/product-lots/search',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    create: function(o) {
                        var data = o.data.models[0];
                        if(!data.product.id)
                            data.product = null;

                        $http.post('/api/product-lots', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                                vm.errorPopup(response);
                            });
                    },
                    update: function(o) {
                        var data = o.data.models[0];

                        if(!data.product.id)
                            data.product = null;

                        $http.put('/api/product-lots', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                                vm.errorPopup(response);
                            });
                    },
                    parameterMap: function (data, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        } else {
                            return data;
                        }

                    }
                },
                schema: {
                    data: function (data) {
                        if(data.content != undefined) {
                            data.content.forEach(function (item) {
                                if (item.product === null) item.product = {};
                            });
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            product:{defaultValue: {id: null, name : null}},
                            referenceNumber: { validation: { required: true , referenceNumbervalidation: function (input) {
                                if (input.is("[name='referenceNumber']") && input.val() !== "") {
                                    input.attr("data-referenceNumbervalidation-msg", "Χρησιμοποιήστε μόνο λατινικούς χαρακτήρες");
                                    return /^[a-zA-Z0-9-]+$/.test(input.val());
                                }
                                return true;
                            }} },
                            expirationMonth: {  },
                            fromIdNumber: {  },
                            toIdNumber: {  },
                            registrationDate: { type: 'date' }
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            sortable: true,
            pageable: {
                messages: {
                    display: "{0} - {1} από {2} Παρτίδες Προιόντων", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                    empty: "Δεν βρέθηκαν Παρτίδες Προιόντων",
                    page: "Σελίδα",
                    allPages: "Όλα",
                    of: "από {0}", // {0} is total amount of pages.
                    itemsPerPage: "εγγραφές ανά σελίδα",
                    first: "Πήγαινε στη πρώτη σελίδα",
                    previous: "Πήγαινε στη τελευταία σελίδα",
                    next: "Πήγαινε στην επόμενη σελίδα",
                    last: "Πήγαινε στη τελευταία σελίδα",
                    refresh: "Ανανέωση"
                }
            },
            noRecords: {
                template: "Δεν βρέθηκαν Παρτίδες Προιόντων"
            },
            height: vm.contentHeight,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "referenceNumber", title: "Αριθμός Παρτίδας",
                    filterable: {
                        cell:
                        {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "product.name",
                    title: "Όνομα είδους",
                    editor: function(container, options) {
                        angular.element('<select id="product" data-bind="value:product.id"></select>')
                            .appendTo(container)
                            .kendoComboBox({
                                dataSource: vm.productsDataSource,
                                filter: "startswith",
                                valuePrimitive: true,
                                dataTextField: "name",
                                dataValueField: "id"
                            });
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 145
                },

                {
                    field: "expirationMonth", title: "Ημερομηνία λήξης",
                    filterable: {
                        cell:
                        {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "fromIdNumber", title: "Από ταινία γνησιότητας",
                    filterable: {
                        cell:
                        {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "toIdNumber", title: "Εως ταινία γνησιότητας",
                    filterable: {
                        cell:
                        {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "registrationDate",
                    title: "Ημερομηνία καταχώρησης",
                    template: "#= registrationDate == null ? '' : kendo.toString(kendo.parseDate(registrationDate), 'dddd, dd/MM/yyyy')#",
                    filterable: false
                },
                {
                    command:[
                        {name:"edit", text: "Επεξεργασία"}
                    ],
                    text: "Επεξεργασία",
                    title: "&nbsp;"
                }
            ],
            edit: function() { KendoGrid.defaultEditPopup(this._editContainer); },
            editable: "popup"
        };
    }
})();
