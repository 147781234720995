/**
 * Created by Kristy Kavvada on 07/02/2020.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminCustomerViewMedReportsController', AdminCustomerViewMedReportsController);

    AdminCustomerViewMedReportsController.$inject = ['$state'];

    function AdminCustomerViewMedReportsController ($state) {
        var vm = this;
        vm.$state = $state;
    }
})();
