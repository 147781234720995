/**
 * Created by Maria on 17/5/2016.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminEmployeeViewController', AdminEmployeeViewController);

    AdminEmployeeViewController.$inject = ['$http', '$state', '$stateParams', '$filter','$scope', 'NotificationOptions', 'KendoFilter', 'EmployeeViewService', 'ApplicationParameters'];

    function AdminEmployeeViewController ($http, $state, $stateParams, $filter, $scope, NotificationOptions, KendoFilter, EmployeeViewService, ApplicationParameters) {
        var vm = this;
        vm.$state = $state;
        vm.stateFrom = $stateParams.stateFrom;
        vm.pageHeading = 'Καρτέλα Ιατρικού Επισκέπτη';
        vm.profilePicture = "employee_icon.png";

        EmployeeViewService.setSelectedUser({ id: $stateParams.id });
        vm.selectedUser = EmployeeViewService.getSelectedUser();
        vm.hasMedReport = false;
        ApplicationParameters.hasModule('Med_Report', false).then(function(response) {
            vm.hasMedReport  = response.data.content[0].value === "1";
        });

        $http.get('api/employees/' + vm.selectedUser.id)
            .success(function (response) {
                vm.employee = response;
                $http.get('api/users/' + vm.employee.account.login)
                    .success(function (response) {
                        vm.user = response;
                    })
                    .error(function (response) {
                        console.log(response);
                    });
            })
            .error(function (response) {
                console.log(response);
            });

        vm.goBack = function () {
            $state.go(vm.stateFrom !== null && vm.stateFrom !== undefined ? vm.stateFrom : 'adminEmployees');
        };

    }
})();
