/**
 * Created by gmogas on 8/7/2016.
 */
(function() {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('ArraysUtil', ArraysUtil);

    function ArraysUtil () {
        var service = {
            isValueInArray : isValueInArray
        };

        return service;

        function isValueInArray (value, array) {
            for (var i=0, len=array.length;i<len;i++) {
                console.log(array[i] + " " + value);
                if (array[i] == value) return true;
            }
            return false;
        }
    }
})();
