(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MDProductCategoriesController', MDProductCategoriesController);

    MDProductCategoriesController.$inject = ['$scope', '$state', '$http', '$filter', 'KendoFilter', 'ApplicationParameters', 'KendoGrid', 'CustomUtils'];

    function MDProductCategoriesController ($scope, $state, $http, $filter, KendoFilter, ApplicationParameters, KendoGrid, CustomUtils) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(67);

        vm.pageHeading = $filter('translate')('masterData.productCategories.title');

        vm.numberEditor = function(container, options) {
            $('<input name="' + options.field + '"/>')
                .appendTo(container)
                .kendoNumericTextBox({
                    format: "{0:n0}"
                });
        };

        vm.onAddProductLotClick = function () {
            const grid = $("#mainGrid").data("kendoGrid");
            grid.addRow();
        };

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/masterdata/product-categories',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    create: function(o) {
                        var data = o.data.models[0];
                        $http.post('/api/product-categories', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    update: function(o) {
                        var data = o.data.models[0];
                        $http.put('/api/product-categories', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (data, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        } else {
                            return data;
                        }

                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            shortName: { validation: { required: true , validation: { required: true, min: 3}} },
                            isSalesCategory: { type: "boolean" },
                            sortOrder: { type: "number", format: "{n0}", max: 0 },
                            activeSubstance: { validation: { required: false , validation: { required: true, min: 3}} },
                            updatedAt: { editable: false }
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            sortable: true,
            pageable: {
                messages: {
                    display: "{0} - {1} από {2} Κατηγορίες Προιόντων", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                    empty: "Δεν βρέθηκαν Κατηγορίες Προιόντων",
                    page: "Σελίδα",
                    allPages: "Όλα",
                    of: "από {0}", // {0} is total amount of pages.
                    itemsPerPage: "εγγραφές ανά σελίδα",
                    first: "Πήγαινε στη πρώτη σελίδα",
                    previous: "Πήγαινε στη τελευταία σελίδα",
                    next: "Πήγαινε στην επόμενη σελίδα",
                    last: "Πήγαινε στη τελευταία σελίδα",
                    refresh: "Ανανέωση"
                }
            },
            noRecords: {
                template: "Δεν βρέθηκαν Κατηγορίες Προιόντων"
            },
            height: vm.contentHeight,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "name", title: "Κατηγορία Προϊόντος",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {template: '<md-checkbox class="checkbox-toolbar customers green inline"  aria-label="selectable" ng-model="dataItem.isSalesCategory" ' +
                'ng-disabled="true"></md-checkbox>',
                    field: "isSalesCategory", title: "Κατηγορία για πώληση",
                    filterable: {
                        cell: {
                            operator: "eqbool",
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Ναι", value: "true" },
                                            { text: "Όχι", value: "false" }]
                                    }),
                                    valuePrimitive: true

                                })
                            },
                            showOperators: false
                        }
                    }},
                {
                    field: "sortOrder", title: "Σειρά Εμφάνισης",
                    template: "#= (sortOrder == null) ? ' ' : kendo.format('{0:n0}', sortOrder)#",
                    editor: vm.numberEditor,
                    filterable: {
                        cell: {
                            template: function (args) {
                                args.element.kendoNumericTextBox({
                                    format: "{0:n0}"
                                });
                            },
                            operator: "eq",
                            showOperators: false
                        }
                    }
                },
                {
                    command:[
                        {name:"edit", text: "Επεξεργασία"}
                    ],
                    text: "Επεξεργασία",
                    title: "&nbsp;"
                }
            ],
            dataBound: function() {
                var grid = $('#mainGrid').data('kendoGrid');

                ApplicationParameters.hasModule('Purchase_Pharmacies', false).then(function(response) {
                    if (response.data.content[0].value != 1) {
                        angular.forEach( grid.columns, function (item, index) {
                            if( item.field == "sortOrder" || item.field == "isSalesCategory") {
                                grid.hideColumn(index);
                            }
                        });
                    }
                });
            },
            edit: function(e) {

                KendoGrid.defaultEditPopup(this._editContainer);

                ApplicationParameters.hasModule('Purchase_Pharmacies', false).then(function(response) {
                    if (response.data.content[0].value != 1) {
                        //remove sortOrder
                        e.container.find("[data-container-for='sortOrder']").remove();
                        e.container.find("[for='sortOrder']").parent().remove();

                        //remove isSalesCategory
                        e.container.find("[data-container-for='isSalesCategory']").remove();
                        e.container.find("[for='isSalesCategory']").parent().remove();
                    }
                });
            },
            editable: "popup"
        };

    }
})();
