/**
 * Created by Teo on 06/11/2019.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('deletions', {
                parent: 'assignations',
                url: '/deletions',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR']
                },
                templateUrl: 'app/displays/assignations/deletions/deletions.html',
                controller: 'DeletionsController',
                controllerAs: 'vm',
                params: {
                    search: null
                },
                resolve: {
                    healthVisitors: ['$http','$stateParams', function ($http) {
                        return $http.get('/api/employees/by-authority/?authority=ROLE_HEALTH_VISITOR&excludeSystem=true&activated=true').then(function (response) {
                            if (response && response.data){
                                angular.forEach(response.data, function (item) {
                                    item.fullName = item.account ? item.account.lastName + " " + item.account.firstName : ""
                                })
                            }
                            return response.data.sort(compare)});

                        /**
                         * Compare function for sorting by fullName
                         */
                        function compare(a, b) {
                            if ( a.fullName < b.fullName ){
                                return -1;
                            }
                            if ( a.fullName > b.fullName ){
                                return 1;
                            }
                            return 0;
                        }
                    }],
                    customersView: ['ApplicationParameters', function (ApplicationParameters) {
                        return ApplicationParameters.customersView();
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
