/**
 * Created by Teo on 09/05/2018.
 */

(function () {
    'use strict';

    angular
        .module('sigmasfApp')
        .service('LeadCallsGrids', LeadCallsGrids);

    LeadCallsGrids.$inject = ['$http', 'KendoFilter'];

    function LeadCallsGrids ($http, KendoFilter) {
        var popupGrid = {};

        //Grid's Columns initialization
        var customerColumns = [
            {
                field: "customer.lastName",
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.doctorSpecialty.name",
                title: "Ειδικότητα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "sector.name",
                title: "Όνομα Μονάδας Υγείας",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];
        var patientColumns = [
            {
                field: "lastName",
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "amka",
                title: "Α.Μ.Κ.Α",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "phone",
                title: "Τηλ. Επικοινωνίας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "email",
                title: "Email Επικοινωνίας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "yearOfBirth", title: "Έτος Γέννηση",
                format: "{0:yyyy}",
                filterable: {
                    cell: {
                        operator: "startswithnum",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "notes",
                title: "Σχόλια",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];
        var prospectivePatientColumns = [
            {
                field: "lastName",
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "phone",
                title: "Τηλ. Επικοινωνίας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "email",
                title: "Email Επικοινωνίας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "birthYear", title: "Έτος Γέννηση",
                format: "{0:yyyy}",
                filterable: {
                    cell: {
                        operator: "startswithnum",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];
        var attendantColumns = [
            {
                field: "lastName",
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "profession.name",
                title: "Ειδικότητα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "email",
                title: "Email Επικοινωνίας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];

        //Grid's Datasource initialization
        var getCustomerDatasource = function () {
            return new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = { filters: [] };
                        }
                        // get only active customer-sectors
                        o.data.active = true;

                        $http.get('/api/me/customer-sectors',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            });
                    }
                },
                schema : {
                    data: function (data) {
                        if(data.content !== undefined) {
                            angular.forEach( data.content, function( item ) {
                                if( !item.customer.doctorSpecialty ) item.customer.doctorSpecialty = {};
                                if( !item.sector ) item.sector = {};
                            })
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    }
                },
                pageSize: 100,
                serverPaging: true,
                serverSorting: true,
                scrollable:false,
                serverFiltering :true
            });
        };
        var getPatientDatasource = function () {
            return new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/patients/search',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            });
                    }
                },
                schema : {
                    data: function (data) {
                        /*if(data.content !== undefined) {
                            angular.forEach( data.content, function (item) {
                                if (item.yearOfBirth) {
                                    var date = new Date();
                                    date.setFullYear(item.yearOfBirth);
                                    item.yearOfBirth = date;
                                }
                            })
                        }*/
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    }
                },
                pageSize: 100,
                serverPaging: true,
                serverSorting: true,
                scrollable:false,
                serverFiltering :true
            });
        };
        var getProspectivePatientDatasource = function () {
            return new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = { filters: [] };
                        }

                        // this filters only ProspectivePatients that
                        // have become Patients ECRM-242
                        // o.data.filter.filters[0] = {
                        //     field: "patient",
                        //     operator: "notempty",
                        //     value: "true"
                        // };
                        $http.get('/api/prospective-patients/search',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            });
                    }
                },
                schema : {
                    data: function (data) {
                        /*if(data.content !== undefined) {
                            angular.forEach( data.content, function (item) {
                                if (item.birthYear) {
                                    var date = new Date();
                                    date.setFullYear(item.birthYear);
                                    item.birthYear = date;
                                }
                            })
                        }*/
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    }
                },
                pageSize: 100,
                serverPaging: true,
                serverSorting: true,
                scrollable:false,
                serverFiltering :true
            });
        };
        var getAttendantDatasource = function () {
            return new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/attendants/search',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            });
                    }
                },
                schema : {
                    data: function (data) {
                        if(data.content !== undefined) {
                            angular.forEach( data.content, function (item) {
                                if (!item.profession) item.profession = {};
                                /*if (item.yearOfBirth) {
                                    var date = new Date();
                                    date.setFullYear(item.yearOfBirth);
                                    item.yearOfBirth = date;
                                }*/
                            })
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    }
                },
                pageSize: 100,
                serverPaging: true,
                serverSorting: true,
                scrollable:false,
                serverFiltering :true
            });
        };

        return {
            getGridOptions: getGridOptions,
            getGridTitle: getGridTitle
        };

        function getGridOptions( grid ) {
            switch (grid) {
                case 'oldPatient':
                    popupGrid.columns = patientColumns;
                    popupGrid.datasource = getPatientDatasource();
                    break;
                case 'oldPatientDoctor':
                    popupGrid.columns = customerColumns;
                    popupGrid.datasource = getCustomerDatasource();
                    break;
                case 'prospectivePatient':
                    popupGrid.columns = prospectivePatientColumns;
                    popupGrid.datasource = getProspectivePatientDatasource();
                    break;
                case 'attendant':
                    popupGrid.columns = attendantColumns;
                    popupGrid.datasource = getAttendantDatasource();
                    break;
                default:
                    popupGrid.columns = null;
                    popupGrid.datasource = null;
                    break
            }

            return {
                dataSource: popupGrid.datasource,
                sortable: true,
                pageable: true,
                filterable: {
                    mode: "row"
                },
                resizable: true,
                selectable: true,
                columns:  popupGrid.columns,
                editable: false,
                grid: grid
            };
        }

        function getGridTitle( grid ){
            switch (grid){
                case 'oldPatient':
                    return "Επιλέξτε Παλιό Ασθενή";
                case 'oldPatientDoctor':
                    return "Επιλέξτε Επαγγελματία Υγείας Παλιού Ασθενή";
                case 'prospectivePatient':
                    return "Επιλέξτε Πιθανό Ασθενή";
                case 'attendant':
                    return "Επιλέξτε Συνοδό";
                default:
                    return "Επιλέξτε";
            }
        }
    }
})();
