(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CustomerSectorDetailController', CustomerSectorDetailController);

    CustomerSectorDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CustomerSector', 'Customer', 'Sector', 'WorkingDay', 'SectorPosition', 'EventCustomerSector', 'EmployeeCustomerSector'];

    function CustomerSectorDetailController($scope, $rootScope, $stateParams, previousState, entity, CustomerSector, Customer, Sector, WorkingDay, SectorPosition, EventCustomerSector, EmployeeCustomerSector) {
        var vm = this;

        vm.customerSector = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('sigmasfApp:customerSectorUpdate', function(event, result) {
            vm.customerSector = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
