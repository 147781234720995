(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AgreementDetailController', AgreementDetailController);

    AgreementDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Agreement', 'Event', 'AgreementValuation', 'Product'];

    function AgreementDetailController($scope, $rootScope, $stateParams, entity, Agreement, Event, AgreementValuation, Product) {
        var vm = this;
        vm.agreement = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:agreementUpdate', function(event, result) {
            vm.agreement = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
