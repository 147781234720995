(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CostTypeDeleteController',CostTypeDeleteController);

    CostTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'CostType'];

    function CostTypeDeleteController($uibModalInstance, entity, CostType) {
        var vm = this;
        vm.costType = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            CostType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
