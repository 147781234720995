/**
 * Created by Kristy on 22/6/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchasesReportController', PurchasesReportController);

    PurchasesReportController.$inject = ['$scope', '$filter', '$timeout','$http', 'employee', 'favouriteFilters', 'FavouriteFiltersService', 'MasterFiltersService', 'KendoFilter', 'MasterToKendoFiltersService', 'CustomUtils', 'CustomDataUtils', 'Alerts'];

    function PurchasesReportController ($scope, $filter, $timeout, $http, employee, favouriteFilters, FavouriteFiltersService, MasterFiltersService, KendoFilter, MasterToKendoFiltersService, CustomUtils, CustomDataUtils, Alerts) {
        var vm = this;
        vm.url = '/api/purchases/all';
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(57);
        vm.gridGrouped = false;
        vm.exportOnlyTotals = true;
        vm.favouriteFilters = favouriteFilters.data.content;
        vm.employee = employee;
        vm.masterFilters = { selectedEmployees: [], selectedPharmacies: [] };
        vm.pageHeading = 'Αναφορά Παραγγελιών';
        vm.disabled = true;
        vm.isEdited = vm.isRenameMode = false;
        vm.selectedFilter = {};
        vm.mainGrid = {};
        vm.masterOptions = [];
        vm.filter = {};
        vm.view = 'purchasesReport';

        var setView = function() {
            var filterable = vm.masterFilters.from && vm.masterFilters.to ? {
                mode: "row"
            } : false;

            var sortable = vm.masterFilters.from && vm.masterFilters.to ? {
                mode: "multiple",
                allowUnsort: true,
                showIndexes: true
            } : false;

            vm.disabled = !vm.masterFilters.from || !vm.masterFilters.to || (vm.masterFilters.selectedEmployees.length === 0);
            vm.mainGrid.setOptions({
                sortable: sortable,
                groupable: vm.masterFilters.from && vm.masterFilters.to,
                filterable: filterable
            });
        };

        $scope.$watchGroup(["vm.masterFilters.from", "vm.masterFilters.to", "vm.masterFilters.selectedEmployees"], function () { setView(); });
        vm.masterFiltersEdited = function(){
            var gridOptions = FavouriteFiltersService.getKendoOptions(vm.mainGrid);
            $timeout(function(){
                vm.isEdited = !!(vm.selectedFilter.kendoOptions && vm.masterOptions.length &&
                    (!angular.equals(vm.masterOptions, MasterToKendoFiltersService.getMasterFilters([], vm.masterFilters, vm.view)) || !angular.equals(JSON.parse(vm.selectedFilter.kendoOptions), gridOptions)) );
            });
        };
        $scope.$watch("vm.masterFilters", vm.masterFiltersEdited, true);

        vm.collapseAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-collapse").trigger("click"); };
        vm.expandAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-expand").trigger("click"); };

        vm.employeesMultiOptions = MasterFiltersService.getEmployeesComboBoxOptions(["me"]);
        vm.employeesMultiOptions.dataSource.filter({});

        vm.pharmaciesMultiOptions = MasterFiltersService.getPharmaciesComboBoxOptions(["all"]);
        vm.pharmaciesMultiOptions.dataSource.filter({});

        vm.clearDatePickers = function () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        };

        vm.masterFiltersOnClick = function (e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id === "today") {
                vm.clearDatePickers();

                $timeout(function (){
                    vm.masterFilters.from = date;
                    vm.masterFilters.to = date;
                });
            }
            else if (e.id === "week") {
                vm.clearDatePickers();

                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6

                $timeout(function (){
                    vm.masterFilters.from = firstWeekDay;
                    vm.masterFilters.to = lastWeekDay;
                });
            }
            else if (e.id === "month") {
                vm.clearDatePickers();

                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                $timeout(function (){
                    vm.masterFilters.from = firstMonthDay;
                    vm.masterFilters.to = lastMonthDay;
                });
            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup", id:"buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ], overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Από: </label><input class='inline' kendo-date-picker id='from' options='vm.fromOptions' k-ng-model='vm.masterFilters.from'/>", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Εώς: </label><input class='inline' kendo-date-picker id='to' options='vm.toOptions' k-ng-model='vm.masterFilters.to'/>", overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Ιατρικός Επισκέπτης: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.employeesMulti' multiple='multiple' " +
                    "k-options='vm.employeesMultiOptions' k-ng-model='vm.masterFilters.selectedEmployees'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Φαρμακείο: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.pharmaciesMulti' multiple='multiple' " +
                    "k-options='vm.pharmaciesMultiOptions' k-ng-model='vm.masterFilters.selectedPharmacies'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary' aria-label='search' ng-click='vm.mainGridOptions.dataSource.read();' ng-disabled='vm.disabled'>" +
                    "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>", overflow: "never", attributes: { class: "right" }
                }
            ]
        };

        vm.fromOptions = {
            change: function() {
                var start = angular.element("#from").data("kendoDatePicker");
                var end = angular.element("#to").data("kendoDatePicker");
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.from = value;
                } else {
                    vm.masterFilters.from = null;
                }

                var startDate = vm.masterFilters.from,
                    endDate = vm.masterFilters.to;

                if (startDate) {
                    startDate = new Date(startDate);
                    startDate.setDate(startDate.getDate());
                    end.min(startDate);

                    //set range to year
                    var maxDate = new Date(startDate);
                    maxDate = new Date(maxDate.setFullYear(startDate.getFullYear() + 1));
                    end.max(maxDate);
                } else if (endDate) {
                    start.max(new Date(endDate));
                    end.max(new Date(2099, 11, 31));
                } else {
                    endDate = new Date();
                    start.max(endDate);
                    end.min(endDate);
                    end.max(new Date(2099, 11, 31));
                }
            }
        };

        vm.toOptions = {
            change: function() {
                var start = angular.element("#from").data("kendoDatePicker");
                var end = angular.element("#to").data("kendoDatePicker");
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.to = value;
                } else {
                    vm.masterFilters.to = null;
                }

                var endDate = vm.masterFilters.to,
                    startDate = vm.masterFilters.from;

                if (endDate) {
                    endDate = new Date(endDate);
                    endDate.setDate(endDate.getDate());
                    start.max(endDate);

                    //set range to year
                    var minDate = new Date(endDate);
                    minDate = new Date(minDate.setFullYear(endDate.getFullYear() - 1));
                    start.min(minDate);
                } else if (startDate) {
                    end.min(new Date(startDate));
                    start.min(new Date(1900, 0, 1));
                } else {
                    endDate = new Date();
                    start.max(endDate);
                    end.min(endDate);
                    start.min(new Date(1900, 0, 1));
                }
            }
        };

        var filterTemplate = function (args) {
            KendoFilter.getTemplate(args);
        };


        var sumAggregates = ", Σύνολο Μικτής Αξίας: #=kendo.format('{0:c}', aggregates.totalPrice.sum)#, " +
            "Σύνολο Έκπτωσης: #=kendo.format('{0:c}', aggregates.discountFinalPrice.sum)#, " +
            "Σύνολο Καθαρής Αξίας: #=kendo.format('{0:c}', aggregates.netPrice.sum)#, " +
            "Σύνολο Έκπτωσης Δώρων: #=kendo.format('{0:c}', aggregates.value1.sum)#";

        vm.mainGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function (o) {
                        if (o.data.filter === undefined) o.data.filter = { filters: [] };
                        o.data.filter.filters = MasterToKendoFiltersService.getMasterFilters(o.data.filter.filters, vm.masterFilters, vm.view);
                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                            }
                        });

                        $http.get(vm.url, {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    }
                },
                schema: {
                    data: function (data) {
                        angular.forEach(data, function(item) {
                            if(!item.purchase.purchaseStatus) item.purchase.purchaseStatus = { name: "" };
                            if(!item.purchase.sector) item.purchase.sector = { name: "" };
                            if(item.purchase.employee && item.purchase.employee.account){
                                item.purchase.employee.account.fullName = item.purchase.employee.account.lastName + " " + item.purchase.employee.account.firstName;
                            }else {
                                item.purchase.employee = { account: {} }
                            }
                        });
                        return data;
                    },
                    parse: function (data) {
                        angular.forEach(data, function (item) {
                            item.issueDate = item.purchase.issueDate ? kendo.toString(kendo.parseDate(item.purchase.issueDate), 'dd/MM/yyyy') : null;
                            if (item.value1 === undefined || item.value1 === null){ item.value1 = 0; }
                            item.totalPrice = (item.purchase.totalPrice === undefined || item.purchase.totalPrice === null) ? 0 : item.purchase.totalPrice;
                            item.discountFinalPrice = (item.purchase.discountFinalPrice === undefined || item.purchase.discountFinalPrice === null) ? 0 : item.purchase.discountFinalPrice;
                            item.netPrice = (item.purchase.netPrice === undefined || item.purchase.netPrice === null) ? 0 : item.purchase.netPrice;
                        });
                        return data;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: "id",
                            issueDate: { type: 'date' }
                        }
                    }
                },
                aggregate: [
                    {field: "totalPrice", aggregate: "sum"},
                    {field: "discountFinalPrice", aggregate: "sum"},
                    {field: "netPrice", aggregate: "sum"},
                    {field: "value1", aggregate: "sum"}
                ],
                serverFiltering: false,
                sort: { field: "issueDate", dir: "desc" },
                change: function() {
                    vm.gridGrouped = this.group().length > 0;
                }
            }),
            height: CustomUtils.getHeightPixelsFromPercentage(55),
            sortable: true,
            groupable: false,
            filterable: false,
            resizable: true,
            columns: [
                {
                    field: "issueDate",
                    title: "Ημερομηνία",
                    template: "#= kendo.toString(kendo.parseDate(issueDate), 'dddd, dd/MM/yyyy')#",
                    filterable: {
                        cell: {
                            operator: "eq", // Put 'eqdateonly' if you enable server filtering
                            showOperators: false
                        }
                    },
                    aggregates: ["count"],
                    groupHeaderTemplate: "Ημερομηνία: #= kendo.toString(kendo.parseDate(value), 'dddd, dd/MM/yyyy')# (Σύνολο: #=kendo.format('{0:n0}', count)#" + sumAggregates + ")"
                },
                {
                    field: "purchase.purchaseStatus.name",
                    title: "Κατάσταση",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    aggregates: ["count"],
                    groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#" + sumAggregates + ")"
                },
                {
                    field: "purchase.employee.account.fullName",
                    title: "Ιατρικός Επισκέπτης",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    aggregates: ["count"],
                    groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#" + sumAggregates + ")"
                },
                {
                    field: "purchase.sector.name",
                    title: "Φαρμακείο",
                    filterable: {
                        cell: {
                            operator: "contains", // Put 'like' if you enable serverFiltering
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    aggregates: ["count"],
                    groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#" + sumAggregates + ")"
                },
                {
                    field: "purchase.sector.vat",
                    title: "Α.Φ.Μ Φαρμακείου",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    aggregates: ["count"],
                    groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#" + sumAggregates + ")",
                    footerTemplate: "Σύνολο: "
                },
                {
                    field: "totalPrice",
                    title: "Αξία Προ Έκπτωσης",
                    attributes:{style:"text-align:right;"},
                    template: "#= (totalPrice == null) ? '' : kendo.format('{0:c}', totalPrice)#",
                    filterable: false,
                    groupable: false,
                    footerTemplate: "#=kendo.format('{0:c}', sum)#",
                    aggregates: ["sum"]
                },
                {
                    field: "netPrice",
                    title: "Καθαρή Αξία",
                    attributes:{style:"text-align:right;"},
                    template: "#= (netPrice == null) ? '' : kendo.format('{0:c}', netPrice)#",
                    filterable: false,
                    groupable: false,
                    footerTemplate: "#=kendo.format('{0:c}', sum)#",
                    aggregates: ["sum"]
                },
                {
                    field: "discountFinalPrice",
                    title: "Σύνολική Αξιακή Έκπτωση",
                    attributes:{style:"text-align:right;"},
                    template: "#= (discountFinalPrice == null) ? '' : kendo.format('{0:c}', discountFinalPrice)#",
                    filterable: false,
                    groupable: false,
                    footerTemplate: "#=kendo.format('{0:c}', sum)#",
                    aggregates: ["sum"]
                },
                {
                    field: "value1",
                    title: "Σύνολο Έκπτωσης Δώρων",
                    attributes:{style:"text-align:right;"},
                    template: "#= (value1 == null) ? '' : kendo.format('{0:c}', value1)#",
                    filterable: false,
                    groupable: false,
                    footerTemplate: "#=kendo.format('{0:c}', sum)#",
                    aggregates: ["sum"]
                }
            ],
            toolbar: [
                { template: "<md-button ng-if='vm.gridGrouped' class='md-raised md-mini md-primary' aria-label='collapseAll' ng-click='vm.collapseAll()'>Σύμπτυξη όλων</md-button>" },
                { template: "<md-button ng-if='vm.gridGrouped' class='md-raised md-mini md-primary' aria-label='expandAll' ng-click='vm.expandAll()'>Επέκταση Όλων</md-button>" },
                {
                    name: "onlyTotals", template: "<div style='float: right; margin-right: 10px;'><md-switch class='md-primary' style='top: 0' ng-model='vm.exportOnlyTotals'><label>Εξαγωγή μόνο σύνολα</label></md-switch></div>"
                },
                { name: "excel", template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                "<span class='k-icon k-i-excel'></span>Export</md-button></div>" }
            ],
            noRecords: {
                template: "Δεν βρέθηκαν εγγραφές"
            },
            excel: {
                allPages: true,
                fileName: "Παραγγελίες.xlsx"
            },
            dataBound: function () { vm.masterFiltersEdited(); },
            excelExport: function (e) {
                if (vm.exportOnlyTotals){
                    // Check if user has applied at least 1 grouping into the grid, by checking the length of columns
                    // Same length means that the grid hasn't any grouping
                    if (e.workbook.sheets[0].columns.length !== vm.mainGridOptions.columns.length){
                        e.workbook.sheets[0].columns[0].autoWidth = true;
                        var result = CustomDataUtils.arrayRemove(e.workbook.sheets[0].rows, 'data'); // remove data
                        result = CustomDataUtils.arrayRemove(result, 'footer'); // also remove footer

                        //Define column headers
                        var index = result[0].cells.length;
                        while (index--) if (index < 6 && index !== 0) result[0].cells.splice(index, 1);
                        result[0].cells.splice(1, 0, {background: "#7a7a7a", color: "#fff", value: "Σύνολο Παραγγελιών", colSpan: 1, rowSpan: 1});

                        for (var i=0; i<result.length; i++){
                            if (result[i].type === 'group-header'){
                                var val = result[i].cells[result[i].cells.length - 1].value;
                                var _val = angular.copy(val);

                                //Get group column name
                                result[i].cells[0].value = CustomDataUtils.getGroupColumn(result[i].cells[0].value);
                                result[i].cells[0].colSpan = 0;

                                var values = CustomDataUtils.getValueFromHeaderLabel(val.substring(val.indexOf("Σύνολο: "))); // get val after a certain string
                                if ( values ) {
                                    for(var j = 0; j < values.length; j++) {
                                        result[i].cells.push({
                                            value: CustomDataUtils.convertToNumberFormat(values[j]),
                                            background: "#dfdfdf",
                                            color: "#333",
                                            colSpan: 1
                                        });
                                        if(result[i].cells.length > 2)
                                            result[i].cells[result[i].cells.length-1].format = "#,##0.00 €";
                                    }
                                }

                                // Keep only month in header labels
                                // result[i].cells[result[i].cells.length - 2].value = CustomDataUtils.removeTotalsFromHeaderLabel(String(_val));
                            }
                        }
                        e.workbook.sheets[0].rows = angular.copy(result);
                    } else {
                        e.preventDefault();
                        Alerts.showAlert("Sigma SalesForce", "Θα πρέπει να εφαρμόσετε τουλάχιστον 1 group by στη λίστα για να εξάγετε μόνο σύνολα. Ειδάλλως, κλείστε τον διακόπτη Εξαγωγή μόνο σύνολα και ξαναπροσπαθήστε.", "ΟΚ");
                    }
                } else {
                    var sheet = e.workbook.sheets[0];
                    var groupHeaders = vm.mainGrid.getOptions().dataSource.group.length;

                    //fix purchaseStatus column's width
                    sheet.columns[groupHeaders + 1].autoWidth = false;
                    sheet.columns[groupHeaders + 1].width = 160;

                    //fix date column's width
                    if (!groupHeaders.length) {
                        sheet.columns[0].autoWidth = false;
                        sheet.columns[0].width = 90;
                    }

                    //convert to number and set format
                    for (var rowIndex = 1; rowIndex < sheet.rows.length; rowIndex++) {
                        var row = sheet.rows[rowIndex];
                        for (var cellIndex = 5; cellIndex < row.cells.length; cellIndex ++) {
                            var cell = row.cells[groupHeaders + cellIndex];
                            if (cell) {
                                if (typeof cell.value === 'string' || cell.value instanceof String) cell.value = CustomDataUtils.convertToNumberFormat(cell.value);
                                cell.format = "#,##0.00 €";
                            }
                        }
                    }

                }
            }
        };

        function hasAppliedGrouping(gridColumnsLength, workBookSheetColumnLength) {
            var has = false;

            has = gridColumnsLength !== workBookSheetColumnLength;

            return has;
        }
    }
})();
