(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadDiscountDeleteController',LeadDiscountDeleteController);

    LeadDiscountDeleteController.$inject = ['$uibModalInstance', 'entity', 'LeadDiscount'];

    function LeadDiscountDeleteController($uibModalInstance, entity, LeadDiscount) {
        var vm = this;
        vm.leadDiscount = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            LeadDiscount.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
