(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MedReportDetailController', MedReportDetailController);

    MedReportDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'MedReport', 'Customer', 'Employee', 'MedReportLine'];

    function MedReportDetailController($scope, $rootScope, $stateParams, entity, MedReport, Customer, Employee, MedReportLine) {
        var vm = this;
        vm.medReport = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:medReportUpdate', function(event, result) {
            vm.medReport = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
