/**
 * Created by Kristy on 28/7/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CustomerSectorsAssignationsController', CustomerSectorsAssignationsController);

    CustomerSectorsAssignationsController.$inject = ['$http', '$timeout', 'Assignations', 'KendoFilter', 'MasterFiltersService', 'CustomUtils'];

    function CustomerSectorsAssignationsController ($http, $timeout, Assignations, KendoFilter, MasterFiltersService, CustomUtils) {
        var vm = this;

        vm.selectedEmployees = [];
        vm.massSelect = vm.myTarget = true;
        Assignations.setCopy(false);
        vm.copy = Assignations.isCopy();

        vm.toggleIsCopy = function() {
            vm.copy=!vm.copy;
            Assignations.setCopy(vm.copy);
        };

        vm.employeesDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: function (o) {
                    var url = '/api/me/employees';

                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }
                    if (o.data.sort == undefined || !o.data.sort.length) {
                        o.data.sort = { "field": "account.lastName",  "dir": "asc" };
                    }

                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            { "field": "account.authorities.name", "operator": "in",
                                "value": "ROLE_HEALTH_VISITOR", "ignoreCase": true}
                        ]
                    );

                    o.data.page = 1;
                    o.data.pageSize = 20;
                    $http.get(url,
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });


                }
            },
            schema: {
                parse: function (data) {
                    data.forEach(function (item) {
                        item.employee = {
                            account: {
                                fullName: item.account.lastName + " " + item.account.firstName
                            }
                        };
                    });
                    return data;
                }
            }
        });

        vm.employeesMultiSelectOptions = {
            dataSource: vm.employeesDataSource,
            change: function (e) {
                if (vm.selectedEmployees) {
                    Assignations.setEmployees(vm.selectedEmployees);

                    vm.customerSectorGridOptions.dataSource.read();
                }
            },
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "employee.account.fullName",
            dataValueField: "id",
            placeholder: "Επιλογή ιατρικού επισκέπτη/επισκεπτών"
        };

        vm.employeesComboBoxOptions = {
            dataSource: vm.employeesDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "employee.account.fullName",
            dataValueField: "id",
            placeholder: "Επιλογή ιατρικού επισκέπτη",
            change: function() {
                Assignations.setEmployee(this.dataItem(this.select()));
            }
        };

        vm.toolbarOptions = {
            items: [
                { template: "<label>{{ vm.copy ? 'Αντιγραφή' : 'Μεταφορά' }} τομέα/πελατολογίου από ιατρικό επισκέπτη </label>" },
                {
                    template: "<select id='multiSelect' name='multiSelect' kendo-multi-select multiple='multiple' " +
                    "k-ng-model='vm.selectedEmployees' k-options='vm.employeesMultiSelectOptions'></select>",
                    overflow: "never"
                },
                { template: "<label>σε ιατρικό επισκέπτη </label>" },
                {
                    template: "<select id='comboBox' name='comboBox' kendo-combo-box k-options='vm.employeesComboBoxOptions' k-ng-model='vm.newEmployee'></select>",
                    overflow: "never"
                },
                { template: "<label>My Target: </label>" },
                {
                    template: "<md-checkbox aria-label='myTarget' ng-checked='vm.myTarget' ng-click='vm.myTarget=!vm.myTarget; vm.customerSectorGridOptions.dataSource.read()' class='checkbox-toolbar green'></md-checkbox>"
                },
                { template: "<label>Αντιγραφή πελατολογίου: </label>" },
                {
                    template: "<md-checkbox aria-label='copy' ng-checked='vm.copy' ng-click='vm.toggleIsCopy()' class='checkbox-toolbar green'></md-checkbox>"
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-warn' ui-sref='previewAssignations'>Επόμενο" +
                    "<md-icon class='arrow-right'></md-icon></md-button>", attributes: { class: "right" }
                }
            ]
        };

        vm.gridColumns = [
            { field:"id", filterable: false, hidden : true },
            { title: "Επαγγελματίας Υγείας",
                columns: [
                    { field: "customer.lastName", title: "Επώνυμο",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    { field: "customer.firstName", title: "Όνομα",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    { field: "customer.doctorSpecialty.name", title: "Ειδικότητα",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    }
                ]
            },
            { title: "Μονάδα Υγείας",
                columns: [
                    { field: "sector.name", title: "Όνομα",
                        filterable: {
                            cell: {
                                operator: "contains",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    { field: "sector.sectorType.name", title: "Τύπος",
                        filterable: {
                            cell: {
                                operator: "contains",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    { field: "sector.address", title: "Διεύθυνση",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    { field: "sector.cityName", title: "Πόλη",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    { field: "sector.postalCode", title: "Τ.Κ",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    { field: "sector.prefectureName", title: "Νομός",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    {field: "sector.brick", title: "Brick",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    {field: "sector.osfeBrick", title: "Brick ΟΣΦΕ",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    }
                ]
            }
        ];

        vm.customerSectorGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        if (o.data.sort == undefined || !o.data.sort.length) {
                            o.data.sort = { "field": "customer.lastName",  "dir": "asc" };
                        }

                        if (vm.selectedEmployees.length) {
                            o.data.filter.filters.push(
                                {
                                    "field": "employee.id", "operator": "in",
                                    "value": vm.selectedEmployees.toString()
                                },
                                {
                                    "field": "customerSectors.employeeCustomerSectors.myTarget", "operator": "eqbool",
                                    "value": vm.myTarget
                                }
                            );

                            $http.get('/api/me/customer-sectors/assignations',
                                {params: o.data})
                                .success(function (response,status,headers) {
                                    o.success(response);
                                })
                                .error(function (response) {
                                    console.log(response);
                                });
                        } else {
                            o.success([]);
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        if(data != undefined) {
                            data.forEach(function(item){
                                if(item.customer.doctorSpecialty === null){
                                    item.customer.doctorSpecialty = {
                                        name: ""
                                    };
                                }
                            });
                        }
                        return data;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            sector: {defaultValue : { id : null, name : ""}},
                            customer: { defaultValue : { id : null, firstName : "", lastName : ""}}
                        }
                    }
                },
                pageSize: 200
            }),
            selectedItems: function(dataItems) {
                Assignations.setSelectedCustomerSectors(dataItems);
            },
            height: CustomUtils.getHeightPixelsFromPercentage(56),
            sortable: true,
            scrollable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: true,
            columns: vm.gridColumns/*,
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.customerSectorGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }]*/
        };

        $timeout(function () {
            MasterFiltersService.clearFilterEvent()
        });
    }
})();
