(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PatchReportMaterialCategoryDetailController', PatchReportMaterialCategoryDetailController);

    PatchReportMaterialCategoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PatchReportMaterialCategory'];

    function PatchReportMaterialCategoryDetailController($scope, $rootScope, $stateParams, entity, PatchReportMaterialCategory) {
        var vm = this;
        vm.patchReportMaterialCategory = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:patchReportMaterialCategoryUpdate', function(event, result) {
            vm.patchReportMaterialCategory = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
