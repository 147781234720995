(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('TradePolicyRuleEffectDetailController', TradePolicyRuleEffectDetailController);

    TradePolicyRuleEffectDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TradePolicyRuleEffect', 'TradePolicyRule', 'Product', 'PresentList'];

    function TradePolicyRuleEffectDetailController($scope, $rootScope, $stateParams, entity, TradePolicyRuleEffect, TradePolicyRule, Product, PresentList) {
        var vm = this;
        vm.tradePolicyRuleEffect = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:tradePolicyRuleEffectUpdate', function(event, result) {
            vm.tradePolicyRuleEffect = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
