(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AttendantDialogController', AttendantDialogController);

    AttendantDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Attendant', 'Profession', 'RelationshipType', 'CountryPhoneCode'];

    function AttendantDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Attendant, Profession, RelationshipType, CountryPhoneCode) {
        var vm = this;
        vm.attendant = entity;
        vm.professions = Profession.query();
        vm.relationshiptypes = RelationshipType.query();
        vm.countryphonecodes = CountryPhoneCode.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:attendantUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.attendant.id !== null) {
                Attendant.update(vm.attendant, onSaveSuccess, onSaveError);
            } else {
                Attendant.save(vm.attendant, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
