/**
 * Created by Kristy on 2/9/2016.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminCustomerViewInfoController', AdminCustomerViewInfoController);

    AdminCustomerViewInfoController.$inject = ['$http','$state', '$stateParams', '$filter', 'customersView', 'Principal', 'KendoService', 'NotificationOptions'];

    function AdminCustomerViewInfoController ($http, $state, $stateParams, $filter, customersView, Principal, KendoService, NotificationOptions) {
        var vm = this;

        if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR'])) {
            vm.canEdit = true;
        }else {
            vm.readOnly = true;
        }

        vm.customerView = customersView.data.content[0].value;

        vm.customer = { customerTypes: []};
        vm.selectedUserId = $stateParams.id;

        var validator = angular.element("#form").kendoValidator({
            rules: {
                custom: function (input) {

                    if (input.data('custom')) {
                        if (input.val() !== null && input.val() !== "" && angular.element(input.data('custom')).data("kendoComboBox").selectedIndex === -1) {
                            return false;
                        }
                    }

                    return true;
                }
            },
            messages: {
                custom: "Πρέπει να επιλέξετε από την λίστα",
                maxlength: "Το κινητό τηλέφωνο θα πρέπει να περιέχει 10 χαρακτήρες."
            }
        }).data("kendoValidator");

        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");

        var dayTimeDataSource = new kendo.data.DataSource({
            data: [
                { id: 1, name: "ΠΡΩΙΝΌ"},
                { id: 2,  name: "ΑΠΟΓΕΥΜΑΤΙΝΌ"}
            ]
        });

        vm.dayTimeDropDownOptions = {
            dataSource: dayTimeDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id",
            placeholder: "Ωράριο"

        };

        var collaborationDatasource = KendoService.initDefaultDataSource('/api/collaborations/search');
        vm.collaborationOptions = KendoService.initDefaultOptions(collaborationDatasource, 'startswith', 'name', 'id');

        var softwareDatasource = [{text: $filter('translate')('global.field.software.medexpress'), value: 'medexpress'},
            {text: $filter('translate')('global.field.software.medmobile'), value: 'medmobile'}, {text: $filter('translate')('global.field.software.other'), value: 'other'}];
        vm.softwareOptions = KendoService.initDefaultOptions(softwareDatasource, 'startswith', 'text', 'value');

        var customerTitlesDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 20;
                    $http.get('/api/masterdata/customer-titles',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        //DropDown Options
        vm.customerTitleComboBoxOptions = {
            dataSource: customerTitlesDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };

        vm.customerTypeDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function (o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = {filters: []};
                    }

                    o.data.page = 1;
                    o.data.pageSize = 10;
                    $http.get('api/masterdata/customer-types',
                        {params: o.data})
                        .success(function (response, status, headers) {
                            var customerTypes = response.content;
                            var filteredCustomerTypes = [];

                            // Add only specific customerTypes
                            customerTypes.forEach(function (currentValue) {
                                if (currentValue.id === 1) {
                                    filteredCustomerTypes.push(currentValue);
                                }
                            });

                            // check application parameter Approvals
                            $http.get('/api/masterdata/application-parameters',
                                {params:{
                                    page: 1,
                                    pageSize: 1,
                                    filter : { filters: [{field: "name", operator: "eq",  value: "Patches"}] }}})
                                .success(function (response,status,headers) {
                                    if (response.content.length > 0 && response.content[0].value == '1') {
                                        customerTypes.forEach(function (currentValue) {
                                            if (currentValue.id === 2) {
                                                filteredCustomerTypes.push(currentValue);
                                            }
                                        });
                                    }
                                    o.success(filteredCustomerTypes);
                                })
                                .error(function (response) {
                                    console.log(response);
                                });
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        //DropDown Options
        vm.customerTypesMultiOptions = {
            dataSource: vm.customerTypeDataSource,
            filter: "startswith",
            valuePrimitive: false,
            dataTextField: "name",
            dataValueField: "id"
        };

        $http.get('api/customers/' + vm.selectedUserId)
            .success(function (response) {
                vm.customer = response;

                if (vm.customer.saw == 1) {
                    vm.isViewing = true;
                }

                if (vm.customer.isActive == 1) {
                    vm.isCustomerActive = true;
                }

                if (vm.customer.gdpr == 1) {
                    vm.isCustomerGDPR = true;
                }

                if(vm.customer.dayTime !== null){
                    if(vm.customer.dayTime === "day") {
                        vm.customer.dayTime = "ΠΡΩΙΝΌ";
                    }else if(vm.customer.dayTime === "night"){
                        vm.customer.dayTime = "ΑΠΟΓΕΥΜΑΤΙΝΌ";
                    }
                }
            })
            .error(function (response) {
                console.log(response);
            });

        var specialtyDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 10000;
                    $http.get('/api/masterdata/doctor-specialties',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        //DropDown Options
        vm.specialtyComboBoxOptions = {
            dataSource: specialtyDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };

        vm.subCategoryComboBoxOptions = {
            autoBind: false,
            dataSource: new kendo.Backbone.DataSource({
                serverFiltering: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.page = 1;
                        o.data.pageSize = 20;
                        $http.get('/api/masterdata/customer-sub-categories',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response.content);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                }
            }),
            valuePrimitive: true,
            filter: "startswith",
            dataTextField: "name",
            dataValueField: "id",
            placeholder: "Επιλογή Υποκατηγορίας..."
        };

        vm.categoryComboBoxOptions = {
            autoBind: false,
            dataSource: new kendo.Backbone.DataSource({
                serverFiltering: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.page = 1;
                        o.data.pageSize = 20;
                        $http.get('/api/masterdata/customer-categories',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response.content);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                }
            }),
            valuePrimitive: true,
            filter: "startswith",
            dataTextField: "name",
            dataValueField: "id",
            placeholder: "Επιλογή κατηγορίας..."
        };

        vm.update = function() {

            if (vm.isViewing == true) {
                vm.customer.saw = 1;
            } else {
                vm.customer.saw = 0;
            }

            if (vm.isCustomerActive == true) {
                vm.customer.isActive = 1;
            } else {
                vm.customer.isActive = 0;
            }

            if (vm.isCustomerGDPR == true) {
                vm.customer.gdpr = 1;
            } else {
                vm.customer.gdpr = 0;
            }

            if(vm.customer.dayTime !== null){
                if(vm.customer.dayTime === 1 || vm.customer.dayTime === "ΠΡΩΙΝΌ" ) {
                    vm.customer.dayTime = "day";
                }else if(vm.customer.dayTime === 2 || vm.customer.dayTime === "ΑΠΟΓΕΥΜΑΤΙΝΌ"){
                    vm.customer.dayTime = "night";
                }
            }

            if(vm.customer.customerSubCategory !== null){
                if(vm.customer.customerSubCategory.id === null) {
                    vm.customer.customerSubCategory = null;
                }
            }

            if(vm.customer.doctorSpecialty !== null){
                if(vm.customer.doctorSpecialty.id === null) {
                    vm.customer.doctorSpecialty = null;
                }
            }

            if(vm.customer.customerCategory !== null) {
                if (vm.customer.customerCategory.id === null) {
                    vm.customer.customerCategory = null;
                }
            }

            if (validator.validate()) {
                $http.put('api/customers', vm.customer)
                    .success(function (response) {

                        if(response.dayTime !== null){
                            if(response.dayTime === "day") {
                                vm.customer.dayTime = "ΠΡΩΙΝΌ";
                            }else if(vm.customer.dayTime === "night"){
                                vm.customer.dayTime = "ΑΠΟΓΕΥΜΑΤΙΝΌ";
                            }
                        }

                        notification.show({
                            message: "Η ενημέρωση των στοιχείων ήταν επιτυχής!"
                        }, "success");
                    })
                    .error(function (response) {
                        console.log(response);
                    });
            }else{
                notification.show({
                    message: "Η ενημέρωση των στοιχείων απέτυχε!"
                }, "error");
            }

        };
    }

})();
