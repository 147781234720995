(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('HospitalizationDialogController', HospitalizationDialogController);

    HospitalizationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Hospitalization', 'Patient', 'Customer', 'Sector', 'CustomerTitle', 'ClinicName', 'RoomType', 'HospitalizationType', 'LeadCall'];

    function HospitalizationDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Hospitalization, Patient, Customer, Sector, CustomerTitle, ClinicName, RoomType, HospitalizationType, LeadCall) {
        var vm = this;
        vm.hospitalization = entity;
        vm.patients = Patient.query();
        vm.customers = Customer.query();
        vm.sectors = Sector.query();
        vm.customertitles = CustomerTitle.query();
        vm.clinicnames = ClinicName.query();
        vm.roomtypes = RoomType.query();
        vm.hospitalizationtypes = HospitalizationType.query();
        vm.leadcalls = LeadCall.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:hospitalizationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.hospitalization.id !== null) {
                Hospitalization.update(vm.hospitalization, onSaveSuccess, onSaveError);
            } else {
                Hospitalization.save(vm.hospitalization, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateIssue = false;

        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
