(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchaseNumberingRowDeleteController',PurchaseNumberingRowDeleteController);

    PurchaseNumberingRowDeleteController.$inject = ['$uibModalInstance', 'entity', 'PurchaseNumberingRow'];

    function PurchaseNumberingRowDeleteController($uibModalInstance, entity, PurchaseNumberingRow) {
        var vm = this;
        vm.purchaseNumberingRow = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            PurchaseNumberingRow.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
