/**
 * Created by Teo on 21/06/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('FollowUpCallsController', FollowUpCallsController);

    FollowUpCallsController.$inject = ['$http', '$state', '$filter', 'KendoService', 'KendoFilter', 'KendoToolbar', 'KendoGrid', 'FollowupCall', 'CustomUtils'];

    function FollowUpCallsController ($http, $state, $filter, KendoService, KendoFilter, KendoToolbar, KendoGrid, FollowupCall, CustomUtils) {
        var vm = this;
        vm.editMode = false;
        vm.pageHeading = "Εκκρεμοτητες";
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(62);
        var grid = {};
        var dataSource = {};

        grid.datasource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }
                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            {
                                "field": "leadCall.leadOffers.offerStatus.id", "operator": "noteq",
                                "value": 3
                            }
                        ]
                    );
                    if (vm.from && vm.to) {
                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                {
                                    "field": "date", "operator": "fromdate",
                                    "value": $filter('date')(vm.from, 'yyyy-MM-dd')
                                },
                                {
                                    "field": "date", "operator": "todate",
                                    "value": $filter('date')(vm.to, 'yyyy-MM-dd')
                                }
                            ]
                        );
                    }
                    if (o.data.sort === undefined || !o.data.sort.length) {
                        o.data.sort = { "field": "date",  "dir": "desc" };
                    }else {
                        angular.forEach(o.data.sort, function (item) {
                            if (item.field === "leadCall.leadHospitalizations[0].possibleDischargeDate"){
                                item.field = "leadCall.leadHospitalizations.possibleDischargeDate";
                            }
                        });
                    }
                    angular.forEach( o.data.filter.filters, function(item) {
                        /*if (item.value instanceof Date) {
                            item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                        }*/
                        switch (item.field){
                            case "date":
                                if (item.value instanceof Date) {
                                    item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                                }
                                break;
                            case "leadCall.leadHospitalizations[0].possibleDischargeDate":
                                item.field = "leadCall.leadHospitalizations.possibleDischargeDate";
                                if (item.value instanceof Date) {
                                    item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                                }
                                break;
                            default:
                                break;
                        }
                    });

                    o.data.filter.filters.push({
                        field: "completed",
                        operator: "eqbool",
                        value: "false"
                    });
                    o.data.filter.filters.push({
                        field: "leadCall.prospectivePatient.patient",
                        operator: "isnull",
                        value: "true"
                    });
                    $http.get('/api/followup-calls/search',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        });
                },
                create: function (o){},
                update: function (o){
                    var data = o.data.models[0];

                    if ( data.followupType === null || !data.followupType.id ) { data.followupType = null; }
                    if ( data.rejectionReason === null || !data.rejectionReason.id ) { data.rejectionReason = null; }
                    if ( data.followupStatus === null || !data.followupStatus.id ) { data.followupStatus = null; }

                    FollowupCall.update({}, data, function () {
                        KendoGrid.refresh(vm.followUpCallsGridOptions)
                    });
                }
            },
            schema : {
                data: function (data) {
                    if(data.content !== undefined) {
                        data.content.forEach(function(item){
                            if (!item.leadCall){
                                item.leadCall = {};
                            }

                            if (item.leadCall && !item.leadCall.prospectivePatient){
                                item.leadCall.prospectivePatient = {}
                            }

                            if (item.leadCall && item.leadCall.prospectivePatient && !item.leadCall.prospectivePatient.attendant){
                                item.leadCall.prospectivePatient.attendant = {};
                            }
                        });
                    }
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        date: { type: "date"},
                        willTheyCall: { type: "boolean" },
                        comments: { type: "string" },
                        completed: { type: "boolean" },
                        'leadCall.leadHospitalizations[0].possibleDischargeDate': {type: 'date'}
                    }
                }
            },
            pageSize: 100,
            serverPaging: true,
            serverSorting: true,
            scrollable:false,
            serverFiltering :true
        });

        grid.columns = vm.gridColumns = [
            {
                template: "<md-button class='md-raised md-mini' style='min-width: 50px;margin: 0;' aria-label='edit' ng-click='vm.customEdit($event)'>" + // ui-sref='leadCallEdit({id:dataItem.id})'
                " <i class='dark-grey material-icons fixed-icons-2'>edit</i></md-button>",
                title: "&nbsp;",
                width: 80
            },
            {field: "date", title: "Ημερομηνία", template: "#= date ? kendo.toString(kendo.parseDate(date), 'dddd, dd/MM/yyyy') : ''#",
                filterable: {
                    cell: {
                        operator: "eqdateonly",
                        showOperators: false
                    }
                }
            },
            {
                field: "leadCall.prospectivePatient.firstName",
                title: "Όνομα Πιθανού Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "leadCall.prospectivePatient.lastName",
                title: "Επώνυμο Πιθανού Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "leadCall.prospectivePatient.phone",
                title: "Τηλέφωνο Πιθανού Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "leadCall.prospectivePatient.attendant.firstName",
                title: "Όνομα Συνοδού",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "leadCall.prospectivePatient.attendant.lastName",
                title: "Επώνυμο Συνοδού",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "leadCall.prospectivePatient.attendant.phone",
                title: "Τηλέφωνο Συνοδού",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                template: '<md-checkbox class="checkbox-toolbar green inline"  aria-label="selectable" ' +
                'ng-model="dataItem.leadCall.isImportant" ' +
                'ng-disabled="true"></md-checkbox>',
                field: "leadCall.isImportant", title: "Σημαντικό",
                filterable: {
                    cell: {
                        operator: "eqbool",
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "Ναι", value: "true" },
                                        { text: "Όχι", value: "false" }]
                                }),
                                valuePrimitive: true

                            })
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "leadCall.leadHospitalizations[0].possibleDischargeDate",
                title: "Πιθανή Ημερ/νια Εξιτηρίου",
                template: "#= leadCall.leadHospitalizations[0].possibleDischargeDate ? kendo.toString(kendo.parseDate(leadCall.leadHospitalizations[0].possibleDischargeDate), 'dddd, dd/MM/yyyy') : ''#",
                filterable: {
                    cell: {
                        operator: "eqdateonly",
                        showOperators: false
                    }
                }
            },
            {
                template: "<md-button class='md-raised md-mini' style='min-width: 50px;margin: 0;' ui-sref='leadCallView({id:dataItem.leadCall.id})'>" + // ui-sref='leadCallEdit({id:dataItem.id})'
                " Μετάβαση στην Κλήση</md-button>",
                title: "&nbsp;",
                width: 190
            }
        ];

        vm.followUpCallsGridOptions = {
            dataSource: grid.datasource,
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: true,
            columns:  grid.columns,
            editable: {
                mode: "popup",
                template: kendo.template(angular.element("#popup-follow-up-call").html())
            },
            rowTemplate: kendo.template($("#rowTemplate").html()),
            edit: function(e) {
                vm.editMode = true;
                //e.model.formattedDate = moment(e.model.date).format('DD-MM-YYYY').toString();
                KendoGrid.defaultEditPopup(this._editContainer);
                e.model.dirty = true;
                vm.selectedItem = e.model;
                KendoGrid.defaultCustomEditPopup(this._editContainer,1100,1090);

                //FollowUpType Combobox
                dataSource.followUpTypes = KendoService.initDefaultDataSource('/api/followup-types/search');
                vm.followUpTypeOptions = KendoService.initDefaultOptions(dataSource.followUpTypes, "startswith", "name", "id", false);

                //RejectionReason Combobox
                dataSource.rejectionReasons = KendoService.initDefaultDataSource('/api/rejection-reasons/search');
                vm.rejectionReasonOptions = KendoService.initDefaultOptions(dataSource.rejectionReasons, "startswith", "name", "id", false);

                //RejectionReason Combobox
                dataSource.followupStatuses = KendoService.initDefaultDataSource('/api/followup-statuses/search');
                vm.followupStatuses = KendoService.initDefaultOptions(dataSource.followupStatuses, "startswith", "name", "id", false);
            },
            toolbar: [
                { template: "<div class='right'><label>Σύνολο: {{vm.followUpCallsGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }
            ],
            cancel: function(){
                vm.editMode = false;
                vm.canceled = true;
                KendoGrid.refresh(vm.followUpCallsGridOptions)
            },
            save: function(e){
                vm.editMode = false;
            }
        };

        vm.masterFiltersOnClick = function(e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id === "today") {
                KendoToolbar.clearDatePickers();
                vm.from = date;
                vm.to = date;
            }
            else if (e.id === "week") {
                KendoToolbar.clearDatePickers();
                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6
                vm.from = firstWeekDay;
                vm.to = lastWeekDay;
            }
            else if (e.id === "month") {
                KendoToolbar.clearDatePickers();
                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                vm.from = firstMonthDay;
                vm.to = lastMonthDay;
            }
            else if (e.id === "year") {
                KendoToolbar.clearDatePickers();
                vm.from = new Date(date.getFullYear(), 0, 1);
                vm.to = new Date(new Date().getFullYear(), 11, 31);
            }
            KendoToolbar.setFrom(vm.from);
            KendoToolbar.setTo(vm.to);
            KendoGrid.refresh(vm.followUpCallsGridOptions);
        };

        vm.toolbarOptions = {
            items: KendoToolbar.getOptions(["buttonGroup", "year", "from", "to"], vm)
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.from = value;
                } else {
                    vm.from = null;
                }

                vm.masterFiltersOnClick(e);
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.to = value;
                } else {
                    vm.to = null;
                }

                vm.masterFiltersOnClick(e);
            }
        };

        vm.customEdit = function(e) {
            angular.element("#followUpCallsGrid").data("kendoGrid")
                .editRow($(e.currentTarget).closest("tr"));
        };

        vm.onSelection = function(data) {
            /*if (!vm.editMode && !vm.canceled) $state.go('prospectivePatientInfo', {id: data.id});*/
            if ( vm.canceled ) { vm.canceled = !vm.canceled };
        };
    }
})();
