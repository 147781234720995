(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchaseItemDeleteController',PurchaseItemDeleteController);

    PurchaseItemDeleteController.$inject = ['$uibModalInstance', 'entity', 'PurchaseItem'];

    function PurchaseItemDeleteController($uibModalInstance, entity, PurchaseItem) {
        var vm = this;
        vm.purchaseItem = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            PurchaseItem.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
