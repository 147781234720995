/**
 * Created by Kristy Kavvada on 30/11/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('BeautyCenterViewPurchasesController', BeautyCenterViewPurchasesController);

    BeautyCenterViewPurchasesController.$inject = ['$http', '$stateParams', '$state', '$filter'];

    function BeautyCenterViewPurchasesController ($http, $stateParams, $state, $filter) {
        var vm = this;
        vm.selectedSectorId = $stateParams.id;

        vm.pageHeading = 'Παραγγελίες';

        vm.purchasesColumns = [
            {
                field: "issueDate",
                title: "Ημερομηνία",
                template: "#= kendo.toString(kendo.parseDate(issueDate), 'dddd, dd/MM/yyyy')#",
                filterable: {
                    cell: {
                        operator: "eqdateonly",
                        showOperators: false
                    }
                }
            },
            {
                field: "purchaseStatus.name",
                title: "Κατάσταση",
                filterable: {
                    cell: {
                        operator: "contains",
                        showOperators: false
                    }
                }
            },
            {
                field: "employee.account.firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "contains",
                        showOperators: false
                    }
                }
            },
            {
                field: "employee.account.lastName",
                title: "Επίθετο",
                filterable: {
                    cell: {
                        operator: "contains",
                        showOperators: false
                    }
                }
            },
            {
                field: "comment",
                title: "Σχόλια",
                filterable: {
                    cell: {
                        operator: "contains",
                        showOperators: false
                    }
                }
            },
            {
                field: "finalPrice",
                title: "Σύνολο σε €",
                filterable: {
                    cell: {
                        operator: "contains",
                        showOperators: false
                    }
                }
            }

        ];

        vm.purchasesDatasource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            { "field": "sector.id", "operator": "eq",  "value": $stateParams.id }
                        ]
                    );

                    angular.forEach( o.data.filter.filters, function(item) {
                        if ( item.value instanceof Date) {
                            item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                        }
                    });

                    $http.get('/api/purchases/search',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {});
                }
            },
            schema : {
                data: function (data) {
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        id: "id",
                        issueDate: { type: 'date', editable: false}
                    }
                }
            },
            pageSize: 100,
            serverPaging: true,
            serverSorting: true,
            scrollable:false,
            serverFiltering :true
        });

        vm.purchasesGridOptions = {
            dataSource: vm.purchasesDatasource,
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: true,
            columns:  vm.purchasesColumns
        };

        vm.onSelection = function(data) {
            var selectedId = data.id;
            $state.go('purchaseItems', {id: selectedId});
        };
    }

})();
