/**
 * Created by Kristy on 25/4/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CanceledPatchReportsController', CanceledPatchReportsController);

    CanceledPatchReportsController.$inject = ['$http','$state', '$q', 'PatchReportGridsService','$filter', '$timeout', 'DetailGridsService', 'PatchItemMasterFilter', 'KendoFilter'];

    function CanceledPatchReportsController ($http, $state, $q, PatchReportGridsService, $filter, $timeout, DetailGridsService, PatchItemMasterFilter, KendoFilter) {
        var vm = this;
        vm.detailGridsService = DetailGridsService;

        vm.openPhotoWindow = function (e) {
            e.preventDefault();
            // e.target is the DOM element representing the button
            var tr = $(e.target).closest("tr"); // get the current table row (tr)
            // get the data bound to the current table row
            vm.photo = this.dataItem(tr);

            vm.photoWindow.title(vm.photo.patchPhotoName);
            vm.photoWindow.center().open();
            vm.canceler = $q.defer();
            $http.get('api/patch-photos/' + vm.photo.patchPhotoName, {timeout: vm.canceler.promise})
                .success(function (response,status,headers) {
                    vm.patchPhoto = response;
                })
                .error(function (response) {
                    console.log(response);
                });
        };

        vm.closePhotoWindow = function () {
            vm.canceler.resolve();
            delete vm.patchPhoto;
        };

        vm.savePhoto = function () {
            var dataURI = "data:image/png;base64," + vm.patchPhoto;
            kendo.saveAs({
                dataURI: dataURI,
                fileName: vm.photo.patchPhotoName
            });
        };

        vm.canceledGridOptions = PatchReportGridsService.getGridOptions('cancelled');

        vm.gridColumns = [
            {field: "patchPhotoName", title: "Όνομα αρχείου"},
            {field: "patchPhotoType", title: "Τύπος φωτογραφίας",
                template: "#= (patchPhotoType == 'injury') ? 'Τραύμα' : (patchPhotoType == 'booklet') ? 'Βιβλιάριο' : (patchPhotoType == 'report') ? 'Χαρτί γνωμάτευσης' : ''#"},
            {
                command:[
                    { name:"show", text: "Προβολή", click: vm.openPhotoWindow }
                ],
                title: "&nbsp",
                width:90
            }
        ];
    }
})();
