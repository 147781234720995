(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('Icd10DeleteController',Icd10DeleteController);

    Icd10DeleteController.$inject = ['$uibModalInstance', 'entity', 'Icd10'];

    function Icd10DeleteController($uibModalInstance, entity, Icd10) {
        var vm = this;
        vm.icd10 = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Icd10.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
