/**
 * Created by Kristy on 25/4/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('PatchReportGridsService', PatchReportGridsService);

    PatchReportGridsService.$inject = ['$http', '$filter', 'KendoFilter', 'PatchItemMasterFilter'];

    function PatchReportGridsService($http, $filter, KendoFilter, PatchItemMasterFilter) {

        var gridColumns = [
            {field: "issueDate", title: "Ημερ/νια Γνωμάτευσης",
                template: "#= issueDate ? kendo.toString(kendo.parseDate(issueDate), 'dddd, dd/MM/yyyy') : ''#",
                filterable: {
                    cell: {
                        operator: "eqdate",
                        showOperators: false
                    }
                }
            },
            {field: "patchCustomer.lastName", title: "Επώνυμο Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "patchCustomer.firstName", title: "Όνομα Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "patchCustomer.amka", title: "ΑΜΚΑ Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "customerSector.customer.lastName", title: "Επώνυμο Επαγγελματία Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "customerSector.customer.firstName", title: "Όνομα Επαγγελματία Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "customerSector.sector.name", title: "Όνομα Μονάδας Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "customerSector.sector.address", title: "Διεύθυνση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "customerSector.sector.cityName", title: "Πόλη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "barcode", title: "Barcode",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "materialCategory.name", title: "Κατηγορία Υλικού",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "employee.account.firstName", title: "Όνομα Ιατρικού Επισκέπτη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            // {field: "submitPoint", title: "Σημείο Υποβολής",
            //     filterable: {
            //         cell: {
            //             operator: "startswith",
            //             template: function (args) {
            //                 KendoFilter.getTemplate(args);
            //             },
            //             showOperators: false
            //         }
            //     }
            // },
            {field: "approvalDate", title: "Ημερ/νια Έγκρισης",
                template: "#= approvalDate ? kendo.toString(kendo.parseDate(approvalDate), 'dddd, dd/MM/yyyy') : ''#",
                filterable: {
                    cell: {
                        operator: "eqdatetime",
                        showOperators: false
                    }
                }
            },
            {field: "checkDoctor", title: "Ελεγκτής Επαγγελματίας Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];

        return {
            getGridOptions: function (filter) {
                return {
                    dataSource: new kendo.data.DataSource({
                        batch: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.filter.filters = o.data.filter.filters.concat(
                                    [
                                        { "field": "patchStatus", "operator": "eq",
                                            "value": filter }
                                    ]
                                );

                                if(PatchItemMasterFilter.getSelectedPatchItems().length) {
                                    o.data.filter.filters = o.data.filter.filters.concat(
                                        [
                                            { "field": "patchReportItems.patchItem.id", "operator": "in",
                                                "value": PatchItemMasterFilter.getSelectedPatchItems().toString() }
                                        ]
                                    );
                                }

                                angular.forEach( o.data.filter.filters, function(item) {
                                    if ( item.value instanceof Date ) {
                                        switch (item.operator) {
                                            case "eqdate":
                                                item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                                                break;
                                            case "eqdatetime":
                                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                                                break;
                                            default:
                                        }
                                    }
                                });

                                $http.get('/api/me/patch-reports',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        },
                        schema: {
                            data: function (data) {
                                if(data.content != undefined) {
                                    data.content.forEach(function(item){
                                        if (item.materialCategory === null)
                                            item.materialCategory = {};
                                    });
                                }
                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: { editable: false, nullable: true },
                                    issueDate: { type: 'date'},
                                    approvalDate: { type: 'date'}
                                }
                            }
                        },
                        serverPaging: true,
                        serverFiltering: true,
                        serverSorting: true,
                        pageSize: 50
                    }),
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: {
                        mode: "row"
                    },
                    columns: gridColumns,
                    toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.pendingGridOptions.dataSource.total()}}</label></div>",
                        overflow: "never" }]
                };
            }
        };
    }
})();
