(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('HospitalizationDetailController', HospitalizationDetailController);

    HospitalizationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'Hospitalization', 'Patient', 'Customer', 'Sector', 'CustomerTitle', 'ClinicName', 'RoomType', 'HospitalizationType', 'LeadCall'];

    function HospitalizationDetailController($scope, $rootScope, $stateParams, DataUtils, entity, Hospitalization, Patient, Customer, Sector, CustomerTitle, ClinicName, RoomType, HospitalizationType, LeadCall) {
        var vm = this;
        vm.hospitalization = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:hospitalizationUpdate', function(event, result) {
            vm.hospitalization = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
    }
})();
