(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('RejectionReasonDetailController', RejectionReasonDetailController);

    RejectionReasonDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'RejectionReason'];

    function RejectionReasonDetailController($scope, $rootScope, $stateParams, entity, RejectionReason) {
        var vm = this;
        vm.rejectionReason = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:rejectionReasonUpdate', function(event, result) {
            vm.rejectionReason = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
