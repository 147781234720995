(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('AgreementSearch', AgreementSearch);

    AgreementSearch.$inject = ['$resource'];

    function AgreementSearch($resource) {
        var resourceUrl =  'api/_search/agreements/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
