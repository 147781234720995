/**
 * Created by Kristy on 4/10/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('customerPatchReports.pendingHistory', {
                parent: 'customerPatchReports',
                url: '/pendingHistory',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SUPERVISOR', 'ROLE_PATCH_VISITOR']
                },
                templateUrl: 'app/displays/adminCustomers/patchReports/pendingHistory/pendingHistory.html',
                controller: 'AdminCustomersPendingHistoryPatchReportsController',
                controllerAs: 'vm',
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
