/**
 * Created by Kristy on 14/9/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('MasterFiltersService', MasterFiltersService);

    MasterFiltersService.$inject = ['$http', '$timeout', 'SupervisorsService'];

    function MasterFiltersService($http, $timeout, SupervisorsService) {
        var supervisorsDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: function (o) {
                    var url = '/api/employees/all';
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }
                    if (o.data.sort == undefined || !o.data.sort.length) {
                        o.data.sort = { "field": "account.lastName",  "dir": "asc" };
                    }
                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            { "field": "account.authorities", "operator": "eq",
                                "value": "ROLE_SUPERVISOR" },
                            { "field": "account.activated", "operator": "eqbool",
                                "value": true }
                        ]
                    );
                    $http.get(url,
                        {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            },
            schema: {
                parse: function (data) {
                    data.forEach( function (item) {
                        if(item.id !== "all" && item.id !== "none") {
                            item.employee = {
                                account: {
                                    fullName: item.account.lastName + " " + item.account.firstName
                                }
                            };
                        }
                    });
                    return data;
                }
            }
        });
        var agreementValuationsDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function (o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = {filters: []};
                    }
                    o.data.page = 1;
                    o.data.pageSize = 100;
                    $http.get('/api/masterdata/agreement-valuations',
                        {params: o.data})
                        .success(function (response) {
                            response.content.unshift({id:"all", name:"Επιλογή όλων"});
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });
        var customersDataSource = new kendo.data.DataSource({
            serverSorting: true,
            transport: {
                read: function(o) {
                    var url = '/api/me/customers';
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }
                    if (o.data.sort == undefined || !o.data.sort.length) {
                        o.data.sort = { "field": "lastName",  "dir": "asc" };
                    }
                    o.data.page = 1;
                    o.data.pageSize = 100;
                    $http.get(url,
                        {params: o.data})
                        .success(function (response) {
                            response.content.unshift({id:"all", fullName:"Επιλογή όλων"});
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            },
            schema: {
                parse: function (data) {
                    data.forEach( function (item) {
                        if(item.id !== "all")
                            item.fullName = item.lastName + " " + item.firstName;
                    });
                    return data;
                }
            },
            serverPaging: true,
            serverFiltering :true
        });
        var callTypesDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: function (o) {
                    var url = '/api/call-types';
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }
                    if (o.data.sort == undefined || !o.data.sort.length) {
                        o.data.sort = { "field": "account.lastName",  "dir": "asc" };
                    }
                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            { "field": "account.authorities", "operator": "eq",
                                "value": "ROLE_SUPERVISOR" }
                        ]
                    );
                    $http.get(url)
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            },
            schema: {
                parse: function (data) {
                    return data;
                }
            }
        });
        function getEmployeesDataSource(operations) {
            return new kendo.data.DataSource({
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: function(o) {
                        var url = '/api/employees/all';
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }
                        if (o.data.sort == undefined || !o.data.sort.length) {
                            o.data.sort = { "field": "account.lastName",  "dir": "asc" };
                        }
                        if(o.data.filter.filters.length) {
                            if(o.data.filter.filters[0].field == 'name') {
                                o.data.filter.filters[0].field = 'employee.account.fullName'
                            }
                            o.data.filter.filters = [o.data.filter.filters[0]];
                        }

                        if(SupervisorsService.getSelectedSupervisors().length) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "supervisor.id", "operator": "in",
                                        "value": SupervisorsService.getSelectedSupervisors().toString() }
                                ]
                            );
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "account.activated", "operator": "eqbool",
                                    "value": true }
                            ]
                        );

                        if(operations.indexOf("me") !== -1){
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "employee.id", "operator": "in", "value": "me"}
                                ]
                            );
                        }

                        $http.get(url,
                            {params: o.data})
                            .success(function (response) {
                                if (operations.indexOf("all") !== -1) {
                                    response.unshift({id:"all",name :"Επιλογή όλων"});
                                }
                                if (operations.indexOf("none") !== -1 && !SupervisorsService.getSelectedSupervisors().length) {
                                    response.unshift({id:"none", name :"(Κενό)"});
                                }
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema: {
                    parse: function (data) {
                        data.forEach(function (item) {
                            if (item.id !== "all" && item.id !== "none") {
                                item.name = item.account.lastName + " " + item.account.firstName
                            }
                        });
                        return data;
                    }
                }
            });
        }

        function getPharmaciesDataSource(operations) {
            return new kendo.data.DataSource({
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                transport: {
                    read: function (o) {
                        var url = '/api/me/pharmacies';
                        if (o.data.filter === undefined) {
                            o.data.filter = {filters: []};
                        }

                        o.data.page = 1;
                        o.data.pageSize = 100;
                        $http.get(url,
                            {params: o.data})
                            .success(function (response) {
                                if (operations.indexOf("all") !== -1) {
                                    response.content.unshift({id: "all", name: "Επιλογή όλων"});
                                }
                                if (operations.indexOf("none") !== -1) {
                                    response.content.unshift({id: "none", name: "(Κενό)"});
                                }
                                o.success(response.content);
                            })
                            .error(function () {});
                    }
                }
            });
        }

        return {
            getAgreementValuationsComboBoxOptions : getAgreementValuationsComboBoxOptions,
            getEmployeesComboBoxOptions : getEmployeesComboBoxOptions,
            getCustomersComboBoxOptions : getCustomersComboBoxOptions,
            getSupervisorsComboBoxOptions : getSupervisorsComboBoxOptions,
            getPharmaciesComboBoxOptions : getPharmaciesComboBoxOptions,
            getCallTypeComboBoxOptions : getCallTypeComboBoxOptions,
            selectAllNone: selectAllNone,
            clearFilterEvent: clearFilterEvent
        };

        function clearFilterEvent() {
            angular.element(".k-i-close").click(function(e) {
                angular.element(e.target.parentElement).next().data("kendoMultiSelect").dataSource.filter({});
            });
        }

        function selectAllNone(combobox, dataItem, values, operations) {
            if (dataItem.id === "all" || dataItem.id === "none") {
                combobox.value("");
            } else if (operations.indexOf("all") !== -1 && values.indexOf("all") !== -1) {
                values = angular.element.grep(values, function(value) {
                    return value !== "all";
                });
                combobox.value(values);
            } else if (operations.indexOf("none") !== -1 && values.indexOf("none") !== -1) {
                values = angular.element.grep(values, function(value) {
                    return value !== "none";
                });
                combobox.value(values);
            }
        }

        function getAgreementValuationsComboBoxOptions() {
            return {
                dataSource: agreementValuationsDataSource,
                filter: "startswith",
                valuePrimitive: true,
                dataTextField: "name",
                dataValueField: "id",
                select: function(e) {
                    var dataItem = this.dataSource.view()[e.item.index()];
                    var values = this.value();
                    var self = this;

                    selectAllNone(self, dataItem, values, ["all"]);
                }
            };
        }

        function getSupervisorsComboBoxOptions() {
            return {
                dataSource: supervisorsDataSource,
                filter: "startswith",
                valuePrimitive: true,
                dataTextField: "employee.account.fullName",
                dataValueField: "id",
                placeholder: "Επιλογή προισταμένου"
            };
        }

        function getEmployeesComboBoxOptions(operations) {
            return {
                dataSource: getEmployeesDataSource(operations),
                filter: "startswith",
                valuePrimitive: true,
                dataTextField: "name",
                dataValueField: "id",
                placeholder: "Επιλογή Ιατρικού Επισκέπτη",
                select: function (e) {
                    var dataItem = this.dataSource.view()[e.item.index()];
                    var values = this.value();
                    var self = this;

                    selectAllNone(self, dataItem, values, operations);
                },
                close:function(){
                    if (!this.value().length) {
                        this.dataSource.filter({});
                    }
                }
            };
        }

        function getPharmaciesComboBoxOptions(operations) {
            return {
                dataSource: getPharmaciesDataSource(operations),
                filter: "startswith",
                valuePrimitive: true,
                dataTextField: "name",
                dataValueField: "id",
                placeholder: "Επιλογή Φαρμακείου",
                select: function (e) {
                    var dataItem = this.dataSource.view()[e.item.index()];
                    var values = this.value();
                    var self = this;

                    selectAllNone(self, dataItem, values, operations);
                },
                close:function(){
                    if (!this.value().length) {
                        this.dataSource.filter({});
                    }
                }
            };
        }

        function getCustomersComboBoxOptions() {
            return {
                dataSource: customersDataSource,
                filter: "startswith",
                valuePrimitive: true,
                dataTextField: "fullName",
                dataValueField: "id",
                placeholder: "Επιλογή Επαγγελματία Υγείας",
                select: function (e) {
                    var dataItem = this.dataSource.view()[e.item.index()];
                    var values = this.value();
                    var self = this;

                    selectAllNone(self, dataItem, values, ["all"]);
                }
            };
        }

        function getCallTypeComboBoxOptions() {
            return {
                dataSource: callTypesDataSource,
                filter: "startswith",
                valuePrimitive: true,
                dataTextField: "name",
                dataValueField: "id",
                placeholder: "Επιλογή Τύπου Κλήσης",
                select: function (e) {
                    var dataItem = this.dataSource.view()[e.item.index()];
                    var values = this.value();
                    var self = this;

                    selectAllNone(self, dataItem, values, ["all"]);
                }
            };
        }
    }
})();
