(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService'];

    function UserManagementDialogController ($stateParams, $uibModalInstance, entity, User, JhiLanguageService) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SUPERVISOR',
            'ROLE_HEALTH_VISITOR', 'ROLE_PATCH_VISITOR', 'ROLE_PHARM_VISITOR', 'ROLE_ACCOUNTING_DEP', 'ROLE_STOREHOUSE',
            'ROLE_REC_MANAGEMENT', 'ROLE_REC_ADMINISTRATOR', 'ROLE_REC_HEALTH_VISITOR', 'ROLE_REC_FRONT_DESK', 'ROLE_BC_VISITOR',
            'ROLE_PHARM_SUPERVISOR', 'ROLE_SYSTEM', 'ROLE_BI_VIEWER', 'ROLE_CHECKER'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;


        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
