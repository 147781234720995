/**
 * Created by Kristy on 2/9/2016.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminPharmacyViewInfoController', AdminPharmacyViewInfoController);

    AdminPharmacyViewInfoController.$inject = ['$http', '$stateParams', 'Principal', '$scope', 'NotificationOptions', 'KendoService', 'Sector', '$timeout', 'CustomUtils'];

    function AdminPharmacyViewInfoController ($http, $stateParams, Principal, $scope, NotificationOptions, KendoService, Sector, $timeout, CustomUtils) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(50);

        vm.isSaving = false;

        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SUPERVISOR', 'ROLE_ACCOUNTING_DEP', 'ROLE_PHARM_SUPERVISOR']);

        vm.sector = {};
        vm.selectedSectorId = $stateParams.id;

        // DataSources
        vm.dataSource = {};
        vm.dataSource.companyTypes = KendoService.initDefaultDataSource('/api/masterdata/company-types');
        vm.dataSource.taxOffices = KendoService.initDefaultDataSource('/api/masterdata/tax-offices');
        vm.dataSource.taxStatuses = KendoService.initDefaultDataSource('/api/tax-statuses/search');
        vm.dataSource.workingHours = new kendo.data.DataSource({
            data: [
                { value: 'morning', text: 'Πρωί'},
                { value: 'afternoon', text: 'Απόγευμα'},
                { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα'}
            ]
        });

        // Drop Down Options
        vm.dropDownOptions = {};
        vm.dropDownOptions.companyType = {
            dataSource: vm.dataSource.companyTypes,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };
        vm.dropDownOptions.taxOffice = {
            dataSource: vm.dataSource.taxOffices,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };
        vm.dropDownOptions.taxStatus = {
            dataSource: vm.dataSource.taxStatuses,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };
        vm.dropDownOptions.workingHours = {
            dataSource: vm.dataSource.workingHours,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "text",
            dataValueField: "value"
        };

        $http.get('api/sectors/' + vm.selectedSectorId)
            .success(function (response) {
                vm.sector = response;
            })
            .error(function (response) {
                console.log(response);
            });

        $http.get('/api/employees/me')
            .success(function (response,status,headers) {
                vm.geodata = response.geodata;

                if(!vm.geodata) {
                    vm.dataSource.prefectures = KendoService.initDefaultDataSource('/api/masterdata/prefectures');

                    //DropDown Options
                    vm.prefecturesComboBoxOptions = {
                        dataSource: vm.dataSource.prefectures,
                        filter: "startswith",
                        valuePrimitive: false,
                        dataTextField: "name",
                        dataValueField: "id",
                        change: function(e){
                            var filter = {};
                            if(this.value() && !isNaN(this.value())) {
                                filter = {
                                    "field": "prefecture.id",
                                    "operator": "eq",
                                    "value": this.value()
                                };
                            }
                            vm.refreshCombobox(filter, vm.citiesComboBoxOptions.dataSource);
                        }
                    };

                    var citiesDataSource = new kendo.data.DataSource({
                        serverFiltering: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.page = 1;
                                o.data.pageSize = 50;
                                $http.get('/api/masterdata/cities',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response.content);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        }
                    });

                    //DropDown Options
                    vm.citiesComboBoxOptions = {
                        dataSource: citiesDataSource,
                        filter: "startswith",
                        valuePrimitive: false,
                        dataTextField: "name",
                        dataValueField: "id",
                        template: "<table>${name} &nbsp;" +
                        "<span class='hint'>(${prefecture.name})</span></table>",
                        change: function(e){
                            var filter = {};
                            if(this.dataItem(this.select())) {
                                filter = {
                                    "field"   : "id",
                                    "operator": "eq",
                                    "value"   : this.dataItem(this.select()).prefecture.id
                                };
                            }
                            vm.refreshCombobox(filter, vm.prefecturesComboBoxOptions.dataSource);

                        }
                    };
                }
                else {
                    var geodataDataSource = new kendo.data.DataSource({
                        serverFiltering: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.page = 1;
                                o.data.pageSize = 100;
                                $http.get('/api/masterdata/geodata',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response.content);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        },
                        schema: {
                            parse: function(data) {
                                angular.forEach(data, function(item) {
                                    item.masterFilter = "";
                                    if(item.street !==null) {
                                        item.masterFilter =  item.street + ", ";
                                    }
                                    item.masterFilter = item.masterFilter + item.city + ", " + item.postalCode + ", " + item.prefecture;
                                });
                                return data;
                            }
                        }
                    });

                    vm.masterFilterComboBoxOptions = {
                        dataSource: geodataDataSource,
                        filter: "search",
                        dataTextField: "masterFilter",
                        dataValueField: "id",
                        headerTemplate:"<div class='dropdown-header k-widget k-header'>" +
                        "<span>Διεύθυνση</span>"+
                        "<span>Πόλη</span>"+
                        "<span>Τ.Κ.</span>"+
                        "<span>Νομός</span></div>",
                        template:
                            "#= street != null ? street + ', ' + city + ', ' + postalCode + ', ' + prefecture : city + ', ' + postalCode + ', ' + prefecture #",
                        placeholder: "Αναζητήστε Διεύθυνση, Πόλη, Τ.Κ., Νομό",
                        change: function(e){
                            //initialize inputs (autocomplete)
                            if(this.dataItem(this.select())) {
                                var self = this;
                                //using $timeout to trigger the change
                                $timeout(function(){
                                    vm.sector.prefectureName = self.dataItem(self.select()).prefecture;
                                    vm.sector.cityName = self.dataItem(self.select()).city;
                                    vm.sector.address = self.dataItem(self.select()).street;
                                    vm.sector.postalCode = self.dataItem(self.select()).postalCode;
                                    vm.suggestBricks(vm.sector.postalCode);
                                },0);
                            }
                        }
                    };
                }
            })
            .error(function (response) {
                console.log(response);
            });


        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:sectorUpdate', result);
            vm.isSaving = false;
            notification.show({
                message: "Η ενημέρωση των στοιχείων ήταν επιτυχής!"
            }, "success");
        };

        var onSaveError = function () {
            vm.isSaving = false;
            notification.show({
                message: "Η ενημέρωση των στοιχείων απέτυχε!"
            }, "error");
        };

        vm.save = function () {
            if (vm.validator.validate()){
                vm.isSaving = true;
                Sector.update(vm.sector, onSaveSuccess, onSaveError);
            }else {
                notification.show({
                    message: "Η ενημέρωση των στοιχείων απέτυχε!"
                }, "error");
            }
        };

        vm.refreshCombobox = function(filter, combobox) {
            combobox.filter(filter);
            combobox.read();
        };

        vm.suggestBricks = function($value) {
            var data = {};
            data.filter ={
                filters: [
                    {
                        "field": "postalCode",
                        "operator": "eq",
                        "value": $value,
                        "ignoreCase": true
                    }
                ]};

            data.page = 1;
            data.pageSize = 1;


            $http.get('/api/masterdata/bricks',
                {params: data})
                .success(function (response) {
                    if (response.content != null
                        && response.content instanceof Array
                        && response.content.length > 0) {
                        vm.sector.brick = response.content[0].code;
                    }
                })
                .error(function (response) {
                    console.log(response);
                });


            $http.get('/api/masterdata/brick-osfes',
                {params: data})
                .success(function (response) {
                    if (response.content != null
                        && response.content instanceof Array
                        && response.content.length > 0) {
                        vm.sector.osfeBrick = response.content[0].code;
                    }
                })
                .error(function (response) {
                    console.log(response);
                });
        };
    }
})();
