(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ChangesDialogController', ChangesDialogController);

    ChangesDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Changes', 'ChangeField', 'ChangeTable', 'Customer', 'Sector', 'CustomerSector', 'WorkingDay', 'Employee'];

    function ChangesDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Changes, ChangeField, ChangeTable, Customer, Sector, CustomerSector, WorkingDay, Employee) {
        var vm = this;
        vm.changes = entity;
        vm.changefields = ChangeField.query();
        vm.changetables = ChangeTable.query();
        vm.customers = Customer.query();
        vm.sectors = Sector.query();
        vm.customersectors = CustomerSector.query();
        vm.workingdays = WorkingDay.query();
        vm.employees = Employee.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:changesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.changes.id !== null) {
                Changes.update(vm.changes, onSaveSuccess, onSaveError);
            } else {
                Changes.save(vm.changes, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.issuedDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
