/**
 * Created by gmogas on 18/1/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchaseItemsController', PurchaseItemsController);

    PurchaseItemsController.$inject = ['PurchaseItemLot', 'entity', '$http', '$stateParams', '$filter', '$timeout', 'KendoGrid', 'KendoFilter', 'PurchaseItem', 'KendoNotifications', 'PurchaseGrids', 'ApplicationParameters', 'Principal', 'Alerts', '$window'];

    function PurchaseItemsController (PurchaseItemLot, entity, $http, $stateParams, $filter, $timeout, KendoGrid, KendoFilter, PurchaseItem, KendoNotifications, PurchaseGrids, ApplicationParameters, Principal, Alerts, $window) {
        var vm = this;
        var firstTime = true;
        vm.showProgress = false;

        ApplicationParameters.hasModule('Purchase_Pharmacies', true);
        vm.purchaseGrids = PurchaseGrids;
        vm.kendoGrid = KendoGrid;
        vm.selectedPurchaseId = $stateParams.id;
        vm.purchase = entity;
        vm.purchasePresentItemsGridHeading = $filter('translate')('sigmasfApp.purchase.purchasePresentItemsGrid.title');
        vm.purchaseItemsGridHeading = $filter('translate')('sigmasfApp.purchase.purchaseItemsGrid.title');
        vm.quantityLot = 1;
        vm.options = {
            format: "n2",
            decimals: 2
        };

        vm.addPurchaseItemLot = function(dataItem) {
            //expand row
            var grid = angular.element("#purchaseItemsGrid");
            var row = grid.find("[data-uid=" + dataItem.uid +"]");
            grid.data("kendoGrid").expandRow(row);

            //add row in detailed grid
            var detailedGrid = row.next('tr').find('#detailGrid');
            $timeout(function (){
                detailedGrid.data("kendoGrid").addRow();
            }, 500);
        };

        vm.addProductLots = function() {
            var data = {
                quantity: vm.quantityLot,
                idNumber: vm.barcode,
                purchaseId: vm.selectedPurchaseId
            };

            var request = {
                method: 'POST',
                url: 'api/product-lots/dispense',
                data: data
            };

            $http(request).then(function successCallback(response) {
                if (response.data) {
                    var grid = angular.element("#purchaseItemsGrid").data("kendoGrid");
                    var dataItem = grid.dataSource.get(response.data.id);
                    dataItem.quantityExecuted = response.data.quantityExecuted;
                    vm.quantityLot = 1;
                    vm.barcode = null;
                    angular.element("#quantityLot").data("kendoNumericTextBox").focus();
                    // Refresh grid
                    vm.kendoGrid.refresh(vm.purchaseItemsGridOptions);
                }
            }, function errorCallback(response) {

                var centered = $("#centeredNotification").kendoNotification({
                    stacking: "down",
                    autoHideAfter: 0,
                    show: KendoNotifications.onShow,
                    button: true,
                    hideOnClick: false,
                    width: 500,
                    height: 250
                }).data("kendoNotification");

                centered.show(' ' + $filter('translate')('global.messages.error.' + response.data.params[0] + ' '), "error");
            });


        };

        vm.purchaseItemsGridOptions = PurchaseGrids.getPurchaseItemsGridOptions(vm.selectedPurchaseId);
        vm.purchaseItemsForPDFGridOptions = PurchaseGrids.getPurchaseItemsGridOptions(vm.selectedPurchaseId);
        vm.purchaseItemsGridOptions.height = 750;
        vm.purchaseItemsGridOptions.dataBound = function () {
            angular.element("#quantityLot").data("kendoNumericTextBox").focus();
            Principal.hasAuthority('ROLE_STOREHOUSE')
                .then( function (result) {
                    vm.purchaseGrids.setVisibility(!!result);
                    if (result === true){
                        // Hide 'destroy' column for ROLE_STOREHOUSE from purchaseItemsGrid
                        var index = null;
                        if (vm.purchaseItemsGridOptions && vm.purchaseItemsGridOptions.columns && vm.purchaseItemsGridOptions.columns.length > 0){
                            for (var i=0; i<vm.purchaseItemsGridOptions.columns.length; i++){
                                if (vm.purchaseItemsGridOptions.columns[i].field === 'destroy'){
                                    index = i;
                                    break;
                                }
                            }
                            if (index !== null){
                                angular.element("#purchaseItemsGrid").data("kendoGrid").hideColumn(index);
                            }
                        }
                        // Also hide 'destroy' column for ROLE_STOREHOUSE from purchaseItemsForPDFGrid
                        if (vm.purchaseItemsForPDFGridOptions && vm.purchaseItemsForPDFGridOptions.columns && vm.purchaseItemsForPDFGridOptions.length > 0){
                            index = null;
                            for (var j=0; i<vm.purchaseItemsForPDFGridOptions.columns.length; j++){
                                if (vm.purchaseItemsForPDFGridOptions.columns[j].field === 'destroy'){
                                    index = j;
                                    break;
                                }
                            }
                            if (index !== null){
                                angular.element("#purchaseItemsForPDFGrid").data("kendoGrid").hideColumn(index);
                            }
                        }
                    }
                });
            if (vm.purchase.purchaseStatus) PurchaseGrids.setIsEditable(vm.purchase.purchaseStatus.isEditable);
        };
        vm.purchasePresentItemsGridOptions = PurchaseGrids.getPurchasePresentItemsGridOptions(vm.selectedPurchaseId);

        vm.detailedGridOptions = function (dataItem) {
            if (dataItem != undefined) {
                return {
                    dataSource: {
                        batch: true,
                        transport: {
                            read: function(o) {
                                vm.showProgress = true;
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.filter.filters.push({field: "purchaseItem.id", operator: "eq", value: dataItem.id});
                                $http.get('/api/purchase-item-lots/search',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response);
                                        vm.showProgress = false;
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                        vm.showProgress = false;
                                    });
                            },
                            create: function(o) {
                                vm.showProgress = true;
                                var data = o.data.models[0];
                                data.purchaseItem = {id : dataItem.id};

                                $http.post('/api/purchase-item-lots', data)
                                    .success(function (response) {
                                        o.success(response);
                                        dataItem.quantityExecuted = response.purchaseItem.quantityExecuted;
                                        dataItem.quantity = response.purchaseItem.quantity;
                                        dataItem.measurementUnit = response.purchaseItem.measurementUnit;
                                        dataItem.executedDiscountSummary = response.purchaseItem.executedDiscountSummary;
                                        dataItem.version = response.purchaseItem.version;

                                        vm.showProgress = false;
                                    })
                                    .error(function (response) {
                                        o.error(response);
                                        vm.showProgress = false;
                                        console.log(response);
                                        Alerts.showAlert("Sigma SalesForce", "Κάποιο σφάλμα προέκυψε κατά την δημιουργία Παρτίδας!\n" + response.message, "ΟΚ");
                                        vm.kendoGrid.refresh(vm.purchaseItemsGridOptions);
                                    });
                            },
                            update: function(o) {
                                vm.showProgress = true;
                                var data = o.data.models[0];

                                $http.put('/api/purchase-item-lots', data)
                                    .success(function (response) {
                                        o.success(response);
                                        dataItem.quantityExecuted = response.purchaseItem.quantityExecuted;
                                        dataItem.quantity = response.purchaseItem.quantity;
                                        dataItem.measurementUnit = response.purchaseItem.measurementUnit;
                                        dataItem.executedDiscountSummary = response.purchaseItem.executedDiscountSummary;
                                        dataItem.version = response.purchaseItem.version;

                                        vm.showProgress = false;
                                    })
                                    .error(function (error) {
                                        o.error(error);
                                        vm.showProgress = false;
                                        if (error.message && error.message.includes("error.concurrencyFailure")) {
                                            Alerts.showConfirmDialog(null, $filter('translate')('error.purchaseItemLot.purchaseItemLotConcurrencyFailureTitle'),
                                                $filter('translate')('error.purchaseItemLot.purchaseItemLotConcurrencyFailureMessage'),
                                                false, $filter('translate')('entity.action.refresh'), $filter('translate')('entity.action.cancel'), function () {
                                                    // on confirm
                                                    $window.location.reload();
                                                }, function () {
                                                    // on cancel
                                                });
                                        }else {
                                            Alerts.showAlert("Sigma SalesForce", "Κάποιο σφάλμα προέκυψε κατά την δημιουργία Παρτίδας!\n" + error.message, "ΟΚ");
                                            vm.kendoGrid.refresh(vm.purchaseItemsGridOptions);
                                        }
                                    });
                            },
                            destroy: function(o) {
                                var data = o.data.models[0];
                                PurchaseItemLot.delete({id: data.id},
                                    function () {
                                        var grid = angular.element("#purchaseItemsGrid");
                                        grid.data("kendoGrid").dataSource.read();
                                    });

                            }
                        },
                        requestEnd: function(e) {
                            var type = e.type;
                            if (type == "create"|| type == "destroy") {
                                var dataSource = this;
                                dataSource.read();
                            }

                            if (type == "create" || type == "update"|| type == "destroy") {
                                var grid = angular.element("#detailGrid").data("kendoGrid");
                                grid.dataSource.read();
                            }
                            vm.showProgress = false;
                        },
                        schema: {
                            data: function (data) {
                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: { editable: false, nullable: true },
                                    name: { validation: { namevalidation: function (input) {
                                        if (input.is("[name='name']") && input.val() !== "") {
                                            input.attr("data-namevalidation-msg", "Χρησιμοποιήστε μόνο λατινικούς χαρακτήρες");
                                            return /^[a-zA-Z0-9]+$/.test(input.val());
                                        }
                                        return true;
                                    } } },
                                    quantity: { editable: true, nullable: false, type: "number",
                                        validation: { min: 1, required: { message: "Η ποσότητα είναι υποχρεωτική" }}
                                        /*defaultValue: dataItem.quantity - dataItem.quantityExecuted*/
                                    }
                                }
                            }
                        },
                        serverFiltering: true,
                        serverSorting: true,
                        serverPaging: true,
                        pageSize: 15
                    },
                    pageable: true,
                    sortable: true,
                    filterable: {
                        mode: "row"
                    },
                    columns: [
                        {
                            field: "name",
                            title: "Παρτίδα",
                            filterable: false
                        },
                        {
                            field: "quantity", title: "Ποσότητα", format: "{0:n0}",
                            filterable: false
                        },
                        {
                            field: "destroy",
                            filterable: false,
                            command: [
                                {
                                    name: "destroy",
                                    text: ""
                                }
                            ],
                            title: "&nbsp;"
                        }
                    ],
                    editable: "inline",
                    selectable: "row",
                    navigatable: true,
                    rowTemplate: kendo.template(angular.element("#gridItemsRowTemplate").html()),
                    edit: function(e) {
                        KendoGrid.defaultEditInline(this._editContainer);
                        var quantityDefaultValue = dataItem.quantity - dataItem.quantityExecuted;

                        // This setOptions here does not work SIGMASF-2197
                        // Updating inside event handler is not recommended (https://docs.telerik.com/kendo-ui/api/javascript/ui/widget/methods/setoptions)
                        /*e.container.find("input[name=quantity]").data("kendoNumericTextBox").setOptions({
                            max: quantityDefaultValue,
                            value: quantityDefaultValue,
                            format: '{0:n0}'
                        });*/

                        var quantityInput = e.container.find("input[name=quantity]").data("kendoNumericTextBox");
                        if (quantityInput.value() === null){
                            // Apply the default value only if there is not any value
                            quantityInput.value(quantityDefaultValue);
                            quantityInput.max(quantityDefaultValue);
                            quantityInput.trigger("change");
                        }
                    },
                    // This event causes problems with the PurchaseItem.ExecQuantity.
                    // It runs before the update of PurchaseItemLot
                    /*save: function() {
                        vm.showProgress = true;
                        var data = this._data;
                        dataItem.quantityExecuted = 0;
                        angular.forEach( data, function (item) {
                            dataItem.quantityExecuted += item.quantity;
                        });
                        if (dataItem.quantityExecuted <= dataItem.quantity) {
                            dataItem.measurementUnit = null;
                            dataItem.executedDiscountSummary = (dataItem.quantityExecuted / (dataItem.quantity ? dataItem.quantity : 1)) * (dataItem.discountSummary ? dataItem.discountSummary : 0);
                            PurchaseItem.update(dataItem, function (success) {
                                vm.showProgress = false;
                            }, function (error) {
                                vm.showProgress = false;
                            });
                        }
                    },*/
                    dataBound: function() {
                        angular.element("#detailGrid tbody tr").on("click", function(e) {
                            if (!angular.element(this).hasClass('k-grid-edit-row')) {
                                angular.element("#detailGrid").data("kendoGrid").editRow(angular.element(this));
                            }
                        });
                    }
                };
            }
        };

        vm.purchaseItemLotsColumns = [
            {
                field: "purchaseItem.purchase.code",
                title: "Αριθμός"
            },
            {
                field: "purchaseItem.purchase.sector.code",
                title: "Κωδικός επαγγελματία υγείας"
            },
            {
                field: "purchaseItem.purchase.sector.name",
                title: "Επωνυμία"
            },
            {
                field: "paymentMethod.code",
                title: "Τρόπος Πληρωμής"
            },
            {
                field: "employeeCode",
                title: "Ιατρικός Επισκέπτης"
            },
            {
                field: "purchaseItem.product.code",
                title: "Κωδικός Είδους"
            },
            {
                field: "name",
                title: "Παρτίδα"
            },
            {
                field: "quantity",
                title: "Ποσότητα"
            },
            {
                field: "purchaseItem.productPrice",
                title: "Τιμή Μονάδος"
            },
            { //09
                field: "purchaseItem.discountSummary",
                title: "Συνολική Έκπτωση"
            },
            { //10
                field: "purchaseItem.executedDiscountSummary",
                title: "Συνολική Έκπτωση"
            },
            { //11
                field: "purchaseItem.purchase.eventComments",
                title: "Σχόλια Event"
            },
            { //12
                field: "isPresent",
                title: "Δώρο"
            },
            { //13
                field: "totalLineValue",
                title: "Συνολική Αξία Γραμμής"
            },
            { //14
                field: "progressiveValue",
                title: "Προοδευτική Αξία"
            }
        ];

        vm.purchaseItemLotsDatasource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            { "field": "purchaseItem.purchase.id", "operator": "eq",  "value": vm.selectedPurchaseId }
                        ]
                    );

                    /** Need sort in product for calculating correctly the 'progressiveValue' in excel SIGMASF-2073 **/
                    o.data.sort = { "field": "purchaseItem.id",  "dir": "asc" };

                    $http.get('/api/purchase-item-lots/search',
                        {params: o.data})
                        .success(function (response) {
                            // Also get Purchase Present Item Lots and add them to response
                            var filters = {
                                data: {
                                    "page": 1,
                                    "pageSize": 5000,
                                    filter: {
                                        logic: "and",
                                        filters: [{
                                            "operator": "eq",
                                            "value": vm.selectedPurchaseId,
                                            "field": "purchasePresentItem.purchase.id"
                                        }]
                                    },
                                    sort: {
                                        "field": "purchasePresentItem.id", "dir": "asc"
                                    }
                                }
                            };
                            $http.get('/api/purchase-present-item-lots/search', {params: filters.data})
                                .success(function (purchasePresentItemLots) {
                                    response.content.forEach(function (item) {
                                        item.isPresent = 0;
                                    });
                                    if (purchasePresentItemLots !== null && purchasePresentItemLots !== undefined && purchasePresentItemLots.content){
                                        var addedPresents = 0;
                                        for (var i=0; i<purchasePresentItemLots.content.length; i++){
                                            var item = purchasePresentItemLots.content[i];
                                            if (item.purchasePresentItem.quantityExecuted !== null &&
                                                item.purchasePresentItem.quantityExecuted !== undefined &&
                                                item.purchasePresentItem.quantityExecuted > 0){
                                                response.content.push({
                                                    "id": item.id,
                                                    "name": item.name,
                                                    "quantity": item.quantity,
                                                    "purchaseItem": {
                                                        "createdBy": item.purchasePresentItem.createdBy,
                                                        "createdDate": item.purchasePresentItem.createdDate,
                                                        "lastModifiedBy": item.purchasePresentItem.lastModifiedBy,
                                                        "lastModifiedDate": item.purchasePresentItem.lastModifiedDate,
                                                        "id": item.purchasePresentItem.id,
                                                        "quantity": item.purchasePresentItem.quantity,
                                                        "productPrice": item.purchasePresentItem.price,
                                                        "netPrice": 0,
                                                        "discountPercent": 0,
                                                        "discountPrice": 0,
                                                        "discountLinePercent": 0,
                                                        "discountLinePrice": 0,
                                                        "totalPrice": 0,
                                                        "taxPrice": 0,
                                                        "finalPrice": 0,
                                                        "comment": null,
                                                        "quantityExecuted": item.purchasePresentItem.quantityExecuted,
                                                        "discountPurchasePercent": 0,
                                                        "discountPurchasePrice": 0,
                                                        "discountSummary": item.quantity * item.purchasePresentItem.price,
                                                        "executedDiscountSummary": item.purchasePresentItem.quantityExecuted * item.purchasePresentItem.price,
                                                        "taxPercent": 0,
                                                        "lotNumber": null,
                                                        "pfs": 0,
                                                        "product": item.purchasePresentItem.product,
                                                        "purchase": item.purchasePresentItem.purchase,
                                                        "measurementUnit": null
                                                    },
                                                    "isPresent": 1
                                                });
                                                addedPresents++;
                                            }
                                        }
                                        response.count = response.count + addedPresents;
                                        response.totalEntries = response.totalEntries + addedPresents;
                                    }
                                    // bind initial response
                                    o.success(response);
                            }).error(function (error) {
                                // bind initial response anyway
                                o.success(response);
                            });
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            },
            schema : {
                data: function (data) {
                    if(data.content.length) {
                        var progressiveValue = 0;
                        var prevRowId = null;
                        angular.forEach(data.content, function(item){
                            item.paymentMethod = vm.purchase.paymentMethod;
                            if (vm.purchase.employee) { item.employeeCode = vm.purchase.employee.code; }
                            if (!item.purchaseItem) { item.purchaseItem = { product: {} } }
                            if (!item.paymentMethod) { item.paymentMethod = {} }
                            if (item.purchaseItem.purchase === null) item.purchaseItem.purchase = {};
                            if (item.purchaseItem.purchase.code === null) item.purchaseItem.purchase.code ="";
                            if (item.purchaseItem.purchase.sector === null) item.purchaseItem.purchase.sector = {};
                            if (item.purchaseItem.purchase.sector.name === null) item.purchaseItem.purchase.sector.name = "";
                            if (item.purchaseItem.purchase.sector.code === null) item.purchaseItem.purchase.sector.code = "";
                            // Custom calculated values
                            //item.totalLineValue = (item.purchaseItem.netPrice ? item.purchaseItem.netPrice : 0) + (item.purchaseItem.pfs ? item.purchaseItem.pfs : 0); // OLD
                            if (item.isPresent === 0){
                                // Purchase Item
                                if (item.purchaseItem.product.calcPFS === true){
                                    item.totalLineValue = ((item.purchaseItem.quantityExecuted ? item.purchaseItem.quantityExecuted : 0) * (item.purchaseItem.productPrice ? item.purchaseItem.productPrice : 0) * 1.004) - (item.purchaseItem.executedDiscountSummary ? item.purchaseItem.executedDiscountSummary : 0); // NEW
                                }else {
                                    item.totalLineValue = ((item.purchaseItem.quantityExecuted ? item.purchaseItem.quantityExecuted : 0) * (item.purchaseItem.productPrice ? item.purchaseItem.productPrice : 0)) - (item.purchaseItem.executedDiscountSummary ? item.purchaseItem.executedDiscountSummary : 0); // NEW
                                }
                            }else {
                                // Purchase Present Item
                                item.totalLineValue = ((item.purchaseItem.quantityExecuted ? item.purchaseItem.quantityExecuted : 0) * (item.purchaseItem.productPrice ? item.purchaseItem.productPrice : 0)) - (item.purchaseItem.executedDiscountSummary ? item.purchaseItem.executedDiscountSummary : 0); // NEW
                            }

                            if (prevRowId === null){
                                prevRowId = item.purchaseItem.id;
                                progressiveValue += (item.isPresent === 0 ? item.totalLineValue : 0);
                            }else {
                                if (prevRowId !== item.purchaseItem.id){
                                    // Only sum the progressive value if the product has changed.
                                    // Works in a sorted list. SIGMASF-2073
                                    progressiveValue += (item.isPresent === 0 ? item.totalLineValue : 0);
                                }
                                prevRowId = item.purchaseItem.id;
                            }

                            item.progressiveValue = progressiveValue;
                        });
                    }
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        id: { editable: false, nullable: true}
                    }
                }
            },
            pageSize: 100,
            serverFiltering: true,
            serverSorting: true,
            serverPaging: true,
            scrollable:false
        });

        var exportFlag = false;
        vm.purchaseItemLotsGridOptions = {
            dataSource: vm.purchaseItemLotsDatasource,
            columns:  vm.purchaseItemLotsColumns,
            dataBound: function () {
                // Hide the custom calculated columns when displaying grid
                this.hideColumn(10); // item.executedDiscountSummary - Συνολική Έκπτωση
                this.hideColumn(11); // item.purchase.eventComments - Σχόλια Event
                this.hideColumn(12); // item.isPresent - Δώρο
                this.hideColumn(13); // item.totalLineValue - Συνολική Αξία Γραμμής
                this.hideColumn(14); // item.progressiveValue - Προοδευτική Αξία
            },
            excel: {
                allPages: true,
                fileName: "Παρτίδες Φαρμάκων.xlsx"
            },
            editable: false,
            excelExport: function (e) {
                // Show/Hide custom calculated columns when export grid in excel
                if (!exportFlag){
                    e.sender.hideColumn(9);  // item.discountSummary - Συνολική Έκπτωση
                    e.sender.showColumn(10); // item.executedDiscountSummary - Συνολική Έκπτωση
                    e.sender.showColumn(11); // item.purchase.eventComments - Σχόλια Event
                    e.sender.showColumn(12); // item.isPresent - Δώρο
                    e.sender.showColumn(13); // item.totalLineValue - Συνολική Αξία Γραμμής
                    e.sender.showColumn(14); // item.progressiveValue - Προοδευτική Αξία
                    e.preventDefault();
                    exportFlag = true;
                    setTimeout(function () {
                        console.log(e.sender.columns);
                        e.sender.saveAsExcel();
                    });
                }else {
                    this.showColumn(9);  // item.discountSummary - Συνολική Έκπτωση
                    this.hideColumn(10); // item.executedDiscountSummary - Συνολική Έκπτωση
                    this.hideColumn(11); // item.purchase.eventComments - Σχόλια Event
                    this.hideColumn(12); // item.isPresent - Δώρο
                    this.hideColumn(13); // item.totalLineValue - Συνολική Αξία Γραμμής
                    this.hideColumn(14); // item.progressiveValue - Προοδευτική Αξία
                    exportFlag = false;
                }
            }
        };
    }

})();
