(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('DoctorSpecialtyDetailController', DoctorSpecialtyDetailController);

    DoctorSpecialtyDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'DoctorSpecialty'];

    function DoctorSpecialtyDetailController($scope, $rootScope, $stateParams, entity, DoctorSpecialty) {
        var vm = this;
        vm.doctorSpecialty = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:doctorSpecialtyUpdate', function(event, result) {
            vm.doctorSpecialty = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
