/**
 * Created by Kristy Kavvada on 13/2/2020.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MedReportSumsCustomerAndMonthController', MedReportSumsCustomerAndMonthController);

    MedReportSumsCustomerAndMonthController.$inject = ['$scope', '$http','$state', '$stateParams', '$filter', 'Principal', 'employee', 'KendoFilter', 'CustomUtils', 'KendoService', 'Alerts', '$timeout', 'MasterFiltersService', 'favouriteFilters', 'FavouriteFiltersService', 'MasterToKendoFiltersService'];

    function MedReportSumsCustomerAndMonthController ($scope, $http, $state, $stateParams, $filter, Principal, employee, KendoFilter, CustomUtils, KendoService, Alerts, $timeout, MasterFiltersService, favouriteFilters, FavouriteFiltersService, MasterToKendoFiltersService ) {
        var vm = this;
        vm.isEdited = false;
        vm.employee = employee;
        vm.favouriteFilters = favouriteFilters.data.content;
        vm.view = 'medReportSumsPerCustomerAndMonth';
        vm.selectedFilter = {};
        var aggregates = [];
        vm.selectedUserId = $stateParams.id;
        var months = CustomUtils.getMonths(false);
        var years = CustomUtils.getYears(2017, true);
        vm.masterFilters = { selectedMonths: [], selectedMedicines: [], selectedEmployees: [], isActive: true, selectedYear: null };
        angular.forEach(moment.months(), function (value, key) {
            if (key <= new Date().getMonth()) {
                vm.masterFilters.selectedMonths.push(key + 1);
            }
        });
        vm.pageHeading = 'Συνολα & Συγκριτικα'.toUpperCase();
        var medicinesDatasource = KendoService.initDataSource('/api/medicines/search', 1, 50);
        vm.medicinesMultiOptions = KendoService.initDefaultOptions(medicinesDatasource, "startswith", "name", "id", true);
        vm.monthsMultiOptions = KendoService.initDefaultOptions(months, "startswith", "value", "id");
        vm.yearsMultiOptions = KendoService.initDefaultOptions(years, "startswith", "value", "value");
        vm.masterFilters.selectedYear = years[0].value; //2020
        vm.employeesMultiOptions = MasterFiltersService.getEmployeesComboBoxOptions([]);
        vm.employeesMultiOptions.dataSource.filter({});
        vm.toolbarOptions = {
            items: [
                {
                    template: "<label>Σκευάσματα: </label><select id='multiSelect' name='multiSelect' class='inline widgets-l' kendo-multi-select multiple='multiple' " +
                        "k-options='vm.medicinesMultiOptions' k-ng-model='vm.masterFilters.selectedMedicines'></select>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    template: "<label>Μήνες: </label><select id='multiSelect' name='multiSelect' class='inline widgets-l' kendo-multi-select multiple='multiple' " +
                    "k-options='vm.monthsMultiOptions' k-ng-model='vm.masterFilters.selectedMonths'></select>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    template: "<label>Έτος: </label><select id='comboBox' name='comboBox' class='inline widgets-s' kendo-combo-box k-options='vm.yearsMultiOptions' " +
                        "k-ng-model='vm.masterFilters.selectedYear'></select>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    template: "<label>Ιατρικοί Επισκέπτες: </label><select id='multiSelect' name='multiSelect' class='inline widgets-l' kendo-multi-select multiple='multiple' " +
                        "k-options='vm.employeesMultiOptions' k-ng-model='vm.masterFilters.selectedEmployees'></select>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary md-primary-light' aria-label='search' ng-click='vm.getData()' ng-disabled='!vm.masterFilters.selectedYear'>" +
                        "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>",
                    overflow: "never",
                    attributes: {class: "right"}
                }
            ]
        };

        vm.masterFiltersEdited = function(){
            var gridOptions = FavouriteFiltersService.getKendoOptions(vm.mainGrid);
            $timeout(function(){
                vm.isEdited = !!(vm.selectedFilter.kendoOptions && vm.masterOptions.length &&
                    (!angular.equals(vm.masterOptions, MasterToKendoFiltersService.getMasterFilters([], vm.masterFilters, vm.view)) || !angular.equals(JSON.parse(vm.selectedFilter.kendoOptions), gridOptions)) );
            });
        };
        $scope.$watch("vm.masterFilters", vm.masterFiltersEdited, true);

        vm.getData = function () {
            $("#mainGrid").data().kendoGrid.destroy();
            $("#mainGrid").empty();
            vm.showProgress = true;
            $http.get("/api/med-reports/sums/per-customer/all?years=" + vm.masterFilters.selectedYear + "&months=" + vm.masterFilters.selectedMonths
                + "&medicines=" + vm.masterFilters.selectedMedicines
                + "&employeeIds=" + vm.masterFilters.selectedEmployees)
                .success(function (response) {
                    vm.showProgress = false;
                    generateGrid(response);
                })
                .error(function (error) {
                    vm.showProgress = false;
                    Alerts.showAlert("Sigma SalesForce", "Λυπούμαστε. Κάποιο σφάλμα προσέκυψε!", "ΟΚ");
                });
        };

        function generateGrid(response){
            aggregates = [];
            $("#mainGrid").kendoGrid({
                dataSource: {
                    transport: {
                        read: function (options) {
                            options.success(response);
                        }
                    },
                    schema: {
                        parse: function (data) {
                            if (data !== null && data !== undefined && data.length > 0){
                                for ( var i=0; i < data.length; i++) {
                                    data[i].total = 0;
                                    for ( var j=0; j < data[i].results.length; j++) {
                                        if (!data[i]["customer" + data[i].results[j].customer.id + "month" + data[i].results[j].month])
                                            data[i]["customer" + data[i].results[j].customer.id + "month" + data[i].results[j].month] = 0;
                                        data[i]["customer" + data[i].results[j].customer.id + "month" + data[i].results[j].month] += data[i].results[j].sum;
                                        data[i].total += data[i].results[j].sum;
                                    }
                                }
                            }
                            return data;
                        }
                    },
                    sort: { field: "total", dir: "desc" },
                    aggregate: aggregates
                },
                selectable: "cell",
                columns: generateColumns(response),
                toolbar: [
                    { name: "excel", template: "<div class='right'><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                            "<span class='k-icon k-i-excel'></span>Export</md-button></div>" }
                ],
                excel: {
                    allPages: true,
                    fileName: "Σύνολα Φαρμάκων.xlsx"
                },
                height: vm.contentHeight,
                pageable: false,
                editable: false,
                resizable: true,
                scrollable: true,
                sortable: true,
                dataBound: function () {
                    $timeout(function() {
                        kendo.resize($("#mainGrid"));
                    });
                }
            });
        }

        function generateColumns(response){
            var columns = [];
            columns.push({
                field: "medicine.name",
                title: "Σκέυασμα",
                footerTemplate: "Σύνολο: ",
                width: "370px"
            });
            var date = new Date();
            var customerIterations = []; // dummy initial customer
            for (var k=0; k<response.length; k++) {
                for (var i = 0; i < response[k].results.length; i++) {
                    vm.result = response[k].results[i];
                    if (!customerIterations.includes(response[k].results[i].customer.id)) {

                        // Add a new title column
                        date.setMonth(response[k].results[i].month - 1);
                        aggregates.push({
                            field: "customer" + response[k].results[i].customer.id + "month" + response[k].results[i].month,
                            aggregate: "sum"
                        });
                        // angular.element("#mainGrid").data("kendoGrid").dataSource.options.schema.model.fields["customer" + response[k].results[i].customer.id + "month" + response[k].results[i].month] = {defaultValue : 0};
                        columns.push({
                            title: response[k].results[i].customer.lastName + " " + response[k].results[i].customer.firstName,
                            columns: [{
                                field: "customer" + response[k].results[i].customer.id + "month" + response[k].results[i].month,
                                aggregates: ["sum"],
                                title: kendo.toString(kendo.parseDate(date), 'MMMM'),
                                footerTemplate: "#=sum ? kendo.format('{0:n0}', sum) : 0#"
                            }]
                        });
                    } else {
                        date.setMonth(response[k].results[i].month - 1);
                        var foundColumnByTitle = $filter('filter')(columns, {title: response[k].results[i].customer.lastName + " " + response[k].results[i].customer.firstName}, true)[0];
                        var foundInnerColumnByField = $filter('filter')(columns[columns.indexOf(foundColumnByTitle)].columns,
                            {field:  "customer" + response[k].results[i].customer.id + "month" + response[k].results[i].month}, true)[0];

                        if (!columns[columns.indexOf(foundColumnByTitle)].columns[columns[columns.indexOf(foundColumnByTitle)].columns.indexOf(foundInnerColumnByField)]) {
                            aggregates.push({
                                field: "customer" + response[k].results[i].customer.id + "month" + response[k].results[i].month,
                                aggregate: "sum"
                            });
                            columns[columns.indexOf(foundColumnByTitle)].columns.push({
                                field: "customer" + response[k].results[i].customer.id + "month" + response[k].results[i].month ,
                                aggregates: ["sum"],
                                title: kendo.toString(kendo.parseDate(date), 'MMMM'),
                                footerTemplate: "#=sum ? kendo.format('{0:n0}', sum) : 0#"
                            });
                        }
                    }

                    customerIterations.push(response[k].results[i].customer.id); // hold customer.id, to know if new
                }
            }
            aggregates.push({ field: "total", aggregate: "sum" });
            columns.push({
                field: "total",
                title: "Σύνολο",
                footerTemplate: "#=sum ? kendo.format('{0:n0}', sum) : 0#",
                width: "100px"
            });

            return columns;
        }
    }

})();
