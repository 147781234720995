(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProfessionDetailController', ProfessionDetailController);

    ProfessionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Profession'];

    function ProfessionDetailController($scope, $rootScope, $stateParams, entity, Profession) {
        var vm = this;
        vm.profession = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:professionUpdate', function(event, result) {
            vm.profession = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
