(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('adminEmployees', {
            parent: 'app',
            url: '/employees',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR', 'ROLE_ACCOUNTING_DEP', 'ROLE_ACCOUNTING_DEP', 'ROLE_REC_ADMINISTRATOR', 'ROLE_PHARM_SUPERVISOR']
            },
            views: {
                'content@': {
                    templateUrl: 'app/displays/adminEmployees/adminEmployees.html',
                    controller: 'AdminEmployeesController',
                    controllerAs: 'vm'
                }
            },
            params: {
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', function ($stateParams) {
                    return {
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('employee');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
            .state('adminEmployeeView', {
                abstract: true,
                parent: 'app',
                url: '/employees/search?id:employeeId:stateFrom',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR', 'ROLE_ACCOUNTING_DEP', 'ROLE_REC_ADMINISTRATOR', 'ROLE_PHARM_SUPERVISOR'],
                    pageTitle: 'sigmaCrmApp.customer.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/adminEmployees/adminEmployeeView.html',
                        controller: 'AdminEmployeeViewController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('customer');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
