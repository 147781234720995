(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CustomerSubCategoryDetailController', CustomerSubCategoryDetailController);

    CustomerSubCategoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'CustomerSubCategory'];

    function CustomerSubCategoryDetailController($scope, $rootScope, $stateParams, entity, CustomerSubCategory) {
        var vm = this;
        vm.customerSubCategory = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:customerSubCategoryUpdate', function(event, result) {
            vm.customerSubCategory = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
