/**
 * Created by Maria on 13/5/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminAgreementsAllController', AdminAgreementsAllController);

    AdminAgreementsAllController.$inject = ['$scope', 'employee', '$timeout', 'favouriteFilters','$http', 'FavouriteFiltersService', 'MasterToKendoFiltersService', 'SupervisorsService', 'MasterFiltersService', 'KendoFilter'];

    function AdminAgreementsAllController ($scope, employee, $timeout, favouriteFilters, $http, FavouriteFiltersService, MasterToKendoFiltersService, SupervisorsService, MasterFiltersService, KendoFilter) {
        var vm = this;

        vm.favouriteFilters = favouriteFilters.data.content;
        vm.employee = employee;
        SupervisorsService.setSelectedSupervisors([]);
        vm.masterFilters = { selectedSupervisors: SupervisorsService.getSelectedSupervisors(), selectedEmployees: [], selectedCustomers: [], selectedProducts: [], selectedAgreementValuations: [] };
        vm.pageHeading = 'Αξιολόγηση';
        vm.disabled = true;
        vm.isEdited = vm.isRenameMode = false;
        vm.selectedFilter = {};
        vm.mainGrid = {};
        vm.masterOptions = [];
        vm.filter = {};
        vm.view = 'agreements';

        var setView = function() {
            var filterable = vm.masterFilters.selectedEmployees.length || SupervisorsService.getSelectedSupervisors().length ? {
                mode: "row"
            } : false;

            vm.disabled = !vm.masterFilters.selectedEmployees.length && !SupervisorsService.getSelectedSupervisors().length;
            vm.mainGrid.setOptions({
                sortable: vm.masterFilters.selectedEmployees.length || SupervisorsService.getSelectedSupervisors().length,
                groupable: vm.masterFilters.selectedEmployees.length || SupervisorsService.getSelectedSupervisors().length,
                filterable: filterable
            });
        };

        $scope.$watch("vm.masterFilters.selectedSupervisors", function (value) {
            SupervisorsService.setSelectedSupervisors(value);
            setView();
            vm.employeesMulti.enable(!vm.masterFilters.selectedSupervisors.length);
        });
        $scope.$watch("vm.masterFilters.selectedEmployees", function () {
            setView();
            vm.supervisorsMulti.enable(!vm.masterFilters.selectedEmployees.length);
        });

        vm.masterFiltersEdited = function(){
            var gridOptions = FavouriteFiltersService.getKendoOptions(vm.mainGrid);
            $timeout(function(){
                vm.isEdited = !!(vm.selectedFilter.kendoOptions && vm.masterOptions.length &&
                    (!angular.equals(vm.masterOptions, MasterToKendoFiltersService.getMasterFilters([], vm.masterFilters, vm.view)) || !angular.equals(JSON.parse(vm.selectedFilter.kendoOptions), gridOptions)) );
            });
        };
        $scope.$watch("vm.masterFilters", vm.masterFiltersEdited, true);

        vm.collapseAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-collapse").trigger("click"); };
        vm.expandAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-expand").trigger("click"); };

        vm.supervisorsMultiOptions = MasterFiltersService.getSupervisorsComboBoxOptions();
        vm.supervisorsMultiOptions.change = function () {
            vm.employeesMulti.value('');
            vm.employeesMultiOptions.dataSource.filter({});
            vm.masterFilters.selectedCustomers = [];
            vm.employeesMulti.dataSource.read();
        };
        vm.employeesMultiOptions = MasterFiltersService.getEmployeesComboBoxOptions(["all", "me"]);
        vm.employeesMultiOptions.dataSource.filter({});
        vm.customersMultiOptions = vm.customersMultiOptions = MasterFiltersService.getCustomersComboBoxOptions();

        vm.productsMultiDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function (o) {
                    if (o.data.filter === undefined) o.data.filter = {filters: []};

                    o.data.page = 1;
                    o.data.pageSize = 150;
                    $http.get('/api/masterdata/products',
                        {params: o.data})
                        .success(function (response) {
                            response.content.unshift({id:"all", name:"Επιλογή όλων", prefecture: {}});
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            }
        });
        vm.productsMultiOptions = {
            dataSource: vm.productsMultiDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id",
            select: function(e) {
                var dataItem = this.dataSource.view()[e.item.index()];
                var values = this.value();
                var self = this;

                MasterFiltersService.selectAllNone(self, dataItem, values, ["all"]);
            }
        };

        vm.agreementValuationsMultiOptions = MasterFiltersService.getAgreementValuationsComboBoxOptions();
        //refresh filters
        var masterFilters = [vm.employeesMultiOptions, vm.customersMultiOptions, vm.agreementValuationsMultiOptions];
        angular.forEach(masterFilters, function(masterFilter) {
            masterFilter.dataSource.filter({});
        });

        //Date Filter
        vm.clearDatePickers = function () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        };

        vm.masterFiltersOnClick = function (e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id == "today") {
                vm.clearDatePickers();
                $timeout(function() {
                    vm.masterFilters.from = date;
                    vm.masterFilters.to = date;
                });

            }
            else if (e.id == "week") {
                vm.clearDatePickers();

                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6

                $timeout(function() {
                    vm.masterFilters.from = firstWeekDay;
                    vm.masterFilters.to = lastWeekDay;
                });
            }
            else if (e.id == "month") {
                vm.clearDatePickers();

                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                $timeout(function() {
                    vm.masterFilters.from = firstMonthDay;
                    vm.masterFilters.to = lastMonthDay;
                });
            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup", id:"buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ], overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Από: </label><input class='inline' kendo-date-picker id='from' options='vm.fromOptions' k-ng-model='vm.masterFilters.from'/>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Εώς: </label><input class='inline' kendo-date-picker id='to' options='vm.toOptions' k-ng-model='vm.masterFilters.to'/>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Προιστάμενος: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.supervisorsMulti' multiple='multiple' " +
                    "k-options='vm.supervisorsMultiOptions' k-ng-model='vm.masterFilters.selectedSupervisors'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Ιατρικός Επισκέπτης: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.employeesMulti' multiple='multiple' " +
                    "k-options='vm.employeesMultiOptions' k-ng-model='vm.masterFilters.selectedEmployees'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Επαγγελματίας Υγείας: </label><select id='multiSelectCustomers' name='multiSelect' class='inline' kendo-multi-select multiple='multiple' " +
                    "k-options='vm.customersMultiOptions' k-ng-model='vm.masterFilters.selectedCustomers'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Προϊόν: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select multiple='multiple' " +
                    "k-options='vm.productsMultiOptions' k-ng-model='vm.masterFilters.selectedProducts'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Αξιολόγηση: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select multiple='multiple' " +
                    "k-options='vm.agreementValuationsMultiOptions' k-ng-model='vm.masterFilters.selectedAgreementValuations'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary' aria-label='search' ng-click='vm.mainGridOptions.dataSource.read()' ng-disabled='vm.disabled'>" +
                    "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>", attributes: { class: "right" }
                }
            ]
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.from = value;
                } else {
                    vm.masterFilters.from = null;
                }
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.to = value;
                } else {
                    vm.masterFilters.to = null;
                }
            }
        };

        var filterTemplate = function (args) {
            KendoFilter.getTemplate(args);
        };

        vm.mainGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function (o) {
                        vm.searchAgreementsUrl = '/api/agreements/all';
                        if (o.data.filter === undefined) o.data.filter = { filters: [] };
                        o.data.filter.filters = MasterToKendoFiltersService.getMasterFilters(o.data.filter.filters, vm.masterFilters, vm.view);
                        o.data.filter.filters.push({
                            field: "event.eventCustomerSector.customerSector",
                            operator: "isnotnull",
                            value: "null"
                        });

                        $http.get(vm.searchAgreementsUrl, {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    }
                },
                schema: {
                    data: function (data) {
                        if(data != undefined) {
                            data.forEach(function(item){
                                if (item.event === null) {
                                    item.event = {
                                        employee: { account: {} },
                                        eventCustomerSector: { customerSector: { customer: {} } }
                                    };
                                } else {
                                    if (item.event.eventCustomerSector === null) {
                                        item.event.eventCustomerSector = {
                                            customerSector: { customer: {} }
                                        };
                                    }
                                }
                            });
                        }
                        return data;
                    },
                    parse: function(data) {
                        angular.forEach(data, function(item) {
                            if (event != undefined) {
                                item.event_date = kendo.toString(kendo.parseDate(item.event.startedAt), 'dd/MM/yyyy');
                            }
                        });
                        return data;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: false },
                            event_date: { type: 'date'},
                            promisedQuantity: { type: 'string'}
                        }
                    }
                },
                group: [ { field: "event_date", dir: "desc" }, { field: "event.eventCustomerSector.customerSector.customer.lastName", dir: "asc" } ]
            }),
            sortable: false,
            groupable: false,
            resizable: true,
            filterable: false,
            columns: [
                {field: "event_date", title: "Ημερομηνία Επίσκεψης", hidden: true,
                    groupHeaderTemplate: "Ημερομηνία Επίσκεψης: #= kendo.toString(kendo.parseDate(value), 'dddd, dd/MM/yyyy')#"
                },
                {
                    field: "event_date", title: "Ημερομηνία Επίσκεψης", template: "#= kendo.toString(kendo.parseDate(event.startedAt), 'dddd, dd/MM/yyyy HH:mm')#",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "eq",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "product.name", title: "Προϊόν",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    groupHeaderTemplate: "#= value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                },
                {
                    field: "promisedQuantity", title: "Προβλεπόμενη Συχνότητα",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    groupHeaderTemplate: "#= value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                },
                {
                    field: "priority", title: "Σειρά προτεραιότητας",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "eq",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    groupHeaderTemplate: "#= value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                },
                {
                    field: "agreementValuation.name", title: "Αξιολόγηση",attributes: {
                    class: "#=agreementValuation.id == '1' ? 'red' : agreementValuation.id == '2' ? 'orange' : agreementValuation.id == '3' ? 'purple' : agreementValuation.id == '4' ? 'green' : 'black' #"
                },
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    groupHeaderTemplate: "#= value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                },
                {
                    field: "event.eventCustomerSector.customerSector.customer.lastName", title: "Επώνυμο Επαγγελματία Υγείας",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    }
                },
                {
                    field: "event.eventCustomerSector.customerSector.customer.firstName", title: "Όνομα Επαγγελματία Υγείας",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    }
                },
                {
                    field: "event.employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    }
                },
                {
                    field: "event.employee.account.firstName", title: "Όνομα Ιατρικού Επισκέπτη",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    }
                },
                {field: "description", title: "Περιγραφή Αξιολόγησης",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    groupable: false
                }
            ],
            toolbar: [
                { template: "<md-button class='md-raised md-mini md-primary' aria-label='collapseAll' ng-click='vm.collapseAll()'>Collapse All</md-button>" },
                { template: "<md-button class='md-raised md-mini md-primary' aria-label='expandAll' ng-click='vm.expandAll()'>Expand All</md-button>" },
                { name: "excel", template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                "<span class='k-icon k-i-excel'></span>Export</md-button></div>" }
            ],
            excel: {
                allPages: true,
                fileName: "Αξιολόγηση.xlsx"
            },
            dataBound: function () { vm.masterFiltersEdited(); }
        };

        $timeout(function () {
            MasterFiltersService.clearFilterEvent()
        });
    }
})();
