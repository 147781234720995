/**
 * Created by Kristy on 22/9/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('Approvals', Approvals);

    Approvals.$inject = ['$filter', '$timeout'];

    function Approvals ($filter, $timeout) {
        var selectedList = [];
        var gridId = "#pendingGrid";

        return {
            getSelectedList: function () {
                return selectedList;
            },
            setSelectedList: function (value) {
                selectedList = value;
            },
            calculateValue : calculateValue,
            openWindow : openWindow,
            closeWindow : closeWindow,
            removeItem : removeItem
        };

        function calculateValue(container, value) {
            if(container.changeField.name === 'dayTime') {
                switch (value) {
                    case 'night':
                        return 'Απογευματινό';
                    case 'day':
                        return 'Πρωινό';
                    default :
                        return ''
                }
            } else if (container.changeField.name === 'saw' || container.changeField.name === 'isActive' || container.changeField.name === 'main') {
                switch (value) {
                    case 'true':
                        return 'Ναι';
                    case 'false':
                        return 'Όχι';
                    default :
                        return ''
                }
            } else if (container.changeField.name === 'isMale') {
                switch (value) {
                    case 'true':
                        return 'Άρρεν';
                    case 'false':
                        return 'Θήλυ';
                    default :
                        return ''
                }
            } else if (container.changeField.name === 'startedAt' || container.changeField.name === 'endedAt') {
                if (container.changeField.name != null) {
                    return kendo.toString(kendo.parseDate(value), 'dd/MM/yyyy')
                }
            } else if (container.changeField.name === 'monday' || container.changeField.name === 'tuesday' ||
                container.changeField.name === 'wednesday' || container.changeField.name === 'thursday' ||
                container.changeField.name === 'friday') {
                switch (value) {
                    case 'morning':
                        return 'Πρωί';
                    case 'afternoon':
                        return 'Απόγευμα';
                    case 'morningAfternoon':
                        return 'Πρωί/Απόγευμα';
                    default:
                        return '';
                }
            } else if (value == null) {
                return ''
            } else {
                return value;
            }
        }

        function openWindow(previewWindow, status) {
            var title = '';
            switch (status) {
                case 'approve':
                    title = 'Επιλεγμένες Εγγραφές προς Έγκριση';
                    break;
                case 'reject':
                    title = 'Επιλεγμένες Εγγραφές προς Απόρριψη';
                    break;
                default :
                    break;
            }
            previewWindow.title(title);
            previewWindow.center().open();
        }

        function closeWindow(grid, previewWindow) {
            grid.dataSource.read();
            previewWindow.close();
        }

        function removeItem(gridData, id, grid) {
            angular.forEach(gridData, function (item) {
                if (item.id.toString() === id.toString()){
                    gridData.remove(item);
                    $timeout(function() {
                        $(grid ? grid : gridId).data("kendoGrid").tbody.find("tr[data-uid='" + item.uid + "']").trigger('click');
                    });
                }
            });
            this.setSelectedList(gridData);
        }
    }

})();
