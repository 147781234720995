/**
 * Created by Kristy Kavvada on 29/11/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('BeautyCenterViewController', BeautyCenterViewController);

    BeautyCenterViewController.$inject = ['$http','$state', '$stateParams', 'Principal'];

    function BeautyCenterViewController ($http, $state, $stateParams, Principal) {
        var vm = this;

        vm.pageHeading = 'Φαρμακείο';
        vm.profilePicture = "lotus_icon.png";
        vm.selectedSectorId = $stateParams.id;
        vm.$state = $state;


        $http.get('api/sectors/' + vm.selectedSectorId)
            .success(function (response) {
                vm.sector = response;
            })
            .error(function (response) {
                console.log(response);
            });

        kendo.Backbone = kendo.Backbone || {};

        kendo.Backbone.DataSource = kendo.data.DataSource.extend({
            init: function(options) {
                if (options.transport && options.transport.read) {
                    options.transport.read.type = 'GET';
                    options.transport.read.dataType = 'json';
                    options.transport.read.contentType = 'application/json;charset=UTF-8';
                    options.transport.read.parameterMap = function (data, operation) {
                        return JSON.stringify(data);
                    }
                }
                kendo.data.DataSource.fn.init.call(this, options);
            }
        });
    }

})();
