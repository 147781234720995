/**
 * Created by Kristy Kavvada on 07/2/2020.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminCustomerViewMedReportsSumsPerMonthController', AdminCustomerViewMedReportsSumsPerMonthController);

    AdminCustomerViewMedReportsSumsPerMonthController.$inject = ['$scope', '$http','$state', '$stateParams', '$filter', 'Principal', 'KendoFilter', 'CustomUtils', 'KendoService', 'Alerts', '$timeout'];

    function AdminCustomerViewMedReportsSumsPerMonthController ($scope, $http, $state, $stateParams, $filter, Principal, KendoFilter, CustomUtils, KendoService, Alerts, $timeout) {
        var vm = this;
        var aggregates = [];
        vm.selectedUserId = $stateParams.id;
        var months = CustomUtils.getMonths(false);
        var years = CustomUtils.getYears(2017, true);
        vm.masterFilters = { selectedMonths: [], selectedMedicines: [], isActive: true };
        angular.forEach(moment.months(), function (value, key) {
            if (key <= new Date().getMonth()) {
                vm.masterFilters.selectedMonths.push(key + 1);
            }
        });

        var medicinesDatasource = KendoService.initDataSource('/api/medicines/search', 1, 50);
        vm.medicinesMultiOptions = KendoService.initDefaultOptions(medicinesDatasource, "startswith", "name", "id", true);
        vm.monthsMultiOptions = KendoService.initDefaultOptions(months, "startswith", "value", "id");
        vm.yearsMultiOptions = KendoService.initDefaultOptions(years, "startswith", "value", "value");
        vm.masterFilters.selectedYear = years[0].value; //2020
        vm.toolbarOptions = {
            items: [
                {
                    template: "<label>Σκευάσματα: </label><select id='multiSelect' name='multiSelect' class='inline widgets-l' kendo-multi-select multiple='multiple' " +
                        "k-options='vm.medicinesMultiOptions' k-ng-model='vm.masterFilters.selectedMedicines'></select>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    template: "<label>Μήνες: </label><select id='multiSelect' name='multiSelect' class='inline widgets-l' kendo-multi-select multiple='multiple' " +
                    "k-options='vm.monthsMultiOptions' k-ng-model='vm.masterFilters.selectedMonths'></select>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    template: "<label>Έτος: </label><select id='comboBox' name='comboBox' class='inline widgets-s' kendo-combo-box k-options='vm.yearsMultiOptions' " +
                        "k-ng-model='vm.masterFilters.selectedYear'></select>",
                    overflow: "never", attributes: {class: "left"}
                },
                // {
                //     template: "<label>Ενεργό: </label><md-checkbox aria-label='isActive' ng-model='vm.masterFilters.isActive' class='checkbox-toolbar green inline'></md-checkbox>",
                //     overflow: "never", attributes: {class: "left"}
                // },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary md-primary-light' aria-label='search' ng-click='vm.getData()' ng-disabled='!vm.masterFilters.selectedYear'>" +
                        "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>",
                    overflow: "never",
                    attributes: {class: "right"}
                }
            ]
        };

        vm.getData = function () {
            $("#medReportSumsGrid").data().kendoGrid.destroy();
            $("#medReportSumsGrid").empty();
            vm.showProgress = true;
            $http.get("/api/med-reports/sums-per-month?years=" + vm.masterFilters.selectedYear + "&months=" + vm.masterFilters.selectedMonths
                + "&medicines=" + vm.masterFilters.selectedMedicines
                + "&customer=" + vm.selectedUserId)
                .success(function (response) {
                    vm.showProgress = false;
                    generateGrid(response);
                })
                .error(function (error) {
                    vm.showProgress = false;
                    Alerts.showAlert("Sigma SalesForce", "Λυπούμαστε. Κάποιο σφάλμα προσέκυψε!", "ΟΚ");
                });
        };

        function generateGrid(response){
            aggregates = [];
            $("#medReportSumsGrid").kendoGrid({
                dataSource: {
                    transport: {
                        read: function (options) {
                            options.success(response);
                        }
                    },
                    schema: {
                        parse: function (data) {
                            var columnFields = vm.masterFilters.selectedMonths;
                            if (columnFields.length === 0) columnFields = [1,2,3,4,5,6,7,8,9,10,11,12];

                            if (data !== null && data !== undefined && data.length > 0){
                                for ( var i=0; i < data.length; i++) {
                                    data[i].total = 0;
                                    for ( var j=0; j < data[i].results.length; j++) {
                                        data[i]["month" + data[i].results[j].month] = data[i].results[j].sum;
                                        data[i].total += data[i].results[j].sum;
                                    }
                                    for ( var k=0; k < columnFields.length; k++) {
                                        if (!data[i]["month" + columnFields[k]]) data[i]["month" + columnFields[k]] = 0;
                                    }
                                }
                            }
                            return data;
                        }
                    },
                    sort: { field: "total", dir: "desc" },
                    aggregate: aggregates
                },
                columns: generateColumns(),
                toolbar: [
                    { name: "excel", template: "<div class='right'><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                            "<span class='k-icon k-i-excel'></span>Export</md-button></div>" }
                ],
                excel: {
                    allPages: true,
                    fileName: "Σύνολα Σκευασμάτων.xlsx"
                },
                height: vm.contentHeight,
                pageable: false,
                editable: false,
                resizable: true,
                scrollable: true,
                sortable: true,
                dataBound: function () {
                    $timeout(function() {
                        kendo.resize($("#medReportSumsGrid"));
                    });
                }
            });
        }

        function generateColumns(){
            var columns = [];
            var columnFields = vm.masterFilters.selectedMonths;
            if (columnFields.length === 0) {
                columnFields = [1,2,3,4,5,6,7,8,9,10,11,12]
            }
            columns.push({
                field: "medicine.name",
                title: "Σκέυασμα",
                footerTemplate: "Σύνολο: ",
                width: "370px"
            });
            angular.forEach(columnFields, function (columnField) {
                var date = new Date();
                date.setMonth(columnField-1);
                aggregates.push({ field: "month" + columnField, aggregate: "sum" });
                columns.push({
                    field: "month" + columnField,
                    title: kendo.toString(kendo.parseDate(date), 'MMMM'),
                    footerTemplate: "#=sum ? kendo.format('{0:n0}', sum) : 0#"
                });
            });
            aggregates.push({ field: "total", aggregate: "sum" });
            columns.push({
                field: "total",
                title: "Σύνολο",
                footerTemplate: "#=sum ? kendo.format('{0:n0}', sum) : 0#",
                width: "100px"
            });

            return columns;
        }
    }

})();
