(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('HospitalizationTypeParentDetailController', HospitalizationTypeParentDetailController);

    HospitalizationTypeParentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'HospitalizationTypeParent'];

    function HospitalizationTypeParentDetailController($scope, $rootScope, $stateParams, entity, HospitalizationTypeParent) {
        var vm = this;
        vm.hospitalizationTypeParent = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:hospitalizationTypeParentUpdate', function(event, result) {
            vm.hospitalizationTypeParent = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
