/**
 * Created by Kristy on 2/9/2016.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminCustomerViewAgreementsController', AdminCustomerViewAgreementsController);

    AdminCustomerViewAgreementsController.$inject = ['$http','$state', '$stateParams', '$filter', 'KendoFilter', '$scope'];

    function AdminCustomerViewAgreementsController ($http, $state, $stateParams, $filter, KendoFilter, $scope) {
        var vm = this;

        vm.selectedUserId = $stateParams.id;

        vm.clearDatePickers = function () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        };

        vm.masterFiltersOnClick = function (e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id == "today") {

                vm.clearDatePickers();

                vm.from = date;
                vm.to = date;

            }
            else if (e.id == "week") {

                vm.clearDatePickers();

                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6

                vm.from = firstWeekDay;
                vm.to = lastWeekDay;

            }
            else if (e.id == "month") {

                vm.clearDatePickers();

                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                vm.from = firstMonthDay;
                vm.to = lastMonthDay;
            }

            if ( e.target != undefined) {
                vm.agreementsFrom = vm.from;
                vm.agreementsTo = vm.to;
                vm.agreementsGridOptions.dataSource.read();
            } else {
                vm.agreementsFrom = vm.from;
                vm.agreementsTo = vm.to;
                vm.agreementsGridOptions.dataSource.read();
            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup", id:"buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ]
                },
                { type: "separator" },
                { type: "separator" },
                { template: "<label>Από: </label>" },
                {
                    template: "<input kendo-date-picker id='from' options='vm.fromOptions'/>",
                    overflow: "never"
                },
                { template: "<label>Εώς: </label>" },
                {
                    template: "<input kendo-date-picker id='to' options='vm.toOptions'/>",
                    overflow: "never"
                }
            ]
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                vm.selectedTabStrip = angular.element("#tabstrip2").kendoTabStrip().data("kendoTabStrip").select().index();
                var value = this.value();
                if (value) {
                    vm.agreementsFrom = value;
                }
                vm.from = value;

                vm.masterFiltersOnClick(e);
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                vm.selectedTabStrip = angular.element("#tabstrip2").kendoTabStrip().data("kendoTabStrip").select().index();
                var value = this.value();
                if (value) {
                    vm.agreementsTo = value;
                }
                vm.to = value;

                vm.masterFiltersOnClick(e);
            }
        };

        vm.agreementsGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "event.eventCustomerSector.customerSector.customer.id", "operator": "eq",  "value":  vm.selectedUserId}
                            ]
                        );

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        //master filters functionality
                        if(vm.agreementsFrom && vm.agreementsTo) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "event.startedAt", "operator": "from",  "value": $filter('date')(vm.agreementsFrom, 'yyyy-MM-ddT00:00:00.000') + 'Z' },
                                    { "field": "event.startedAt", "operator": "to",  "value": $filter('date')(vm.agreementsTo, 'yyyy-MM-ddT23:59:59.000') + 'Z' }
                                ]
                            );
                        }

                        o.data.page = 1;
                        $http.get('/api/me/agreements',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (data, operation) {
                        return data;
                    }
                },
                serverSorting: true,
                serverFiltering: true,
                serverPaging: true,
                pageSize: 50,
                group: {field:  'event_date'},
                schema: {
                    data: function (data) {
                        if(data != undefined) {
                            data.content.forEach(function(item){

                                if (item.event === null) {
                                    item.event = {
                                        startedAt: '',
                                        employee : {
                                            account : {
                                                lastName : ""
                                            }
                                        }
                                    };
                                }

                                if (item.product === null) {
                                    item.product = { name: ''};
                                }

                                if (item.agreementValuation === null) {
                                    item.agreementValuation = { name: ''};
                                }

                                if (item.eventPlace === null) {
                                    item.eventPlace = {};
                                    item.eventPlace.name = "";
                                }
                            });
                        }
                        return data.content;
                    },
                    parse: function(data) {
                        angular.forEach(data.content, function(item) {
                            if (item.event != undefined) {
                                item.event_date = kendo.toString(kendo.parseDate(item.event.startedAt), 'dd/MM/yyyy');
                            }

                        });
                        return data;
                    },
                    total : function(data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: false },
                            promisedQuantity: { type:'number' },
                            priority: { type:'number' },
                            //agreed_price: { type:'number' },
                            event_date: { type: 'date'},
                            'event.startedAt': { type: 'date' }
                        }
                    }
                }
            }),
            pageable: true,
            sortable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            columns: [
                {field: "event_date", hidden: true,
                    groupHeaderTemplate: "Ημερομηνία Επίσκεψης: #= value ? kendo.toString(kendo.parseDate(value), 'dddd, dd/MM/yyyy') : ''#"
                },
                {field: "event.startedAt", title: "Ημερομηνία Επίσκεψης", template: "#= event.startedAt ? kendo.toString(kendo.parseDate(event.startedAt), 'dddd, dd/MM/yyyy HH:mm') : ''#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    }},
                {field: "product.name", title: "Προϊόν",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "promisedQuantity", title: "Προβλεπόμενη Συχνότητα",
                    filterable: {
                        cell: {
                            operator: "eq",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "priority", title: "Σειρά προτεραιότητας",
                    filterable: {
                        cell: {
                            operator: "eq",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "agreementValuation.name", title: "Αξιολόγηση",attributes: {
                    class: "#=agreementValuation.id == '1' ? 'red' : agreementValuation.id == '2' ? 'orange' : agreementValuation.id == '3' ? 'purple' : agreementValuation.id == '4' ? 'green' : 'black' #"
                },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "event.employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }}
            ],
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.agreementsGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }]
        };
    }

})();
