/**
 * Created by Kristy on 1/6/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('RecDoctorViewController', RecDoctorViewController);

    RecDoctorViewController.$inject = ['DataUtils', '$http','$state', '$stateParams', '$filter', 'Principal', '$scope', 'NotificationOptions'];

    function RecDoctorViewController (DataUtils, $http, $state, $stateParams, $filter, Principal, $scope, NotificationOptions) {
        var vm = this;
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");
        if (Principal.hasAnyAuthority(['ROLE_REC_ADMINISTRATOR'])) {
            vm.readOnly = true;
        }

        vm.customer = {};
        vm.profilePicture = "customer_icon.png";
        vm.selectedUserId = $stateParams.id;
        vm.$state = $state;

        $http.get('api/customers/' + vm.selectedUserId)
            .success(function (response) {
                vm.customer = response;
            })
            .error(function (response) {});

        kendo.Backbone = kendo.Backbone || {};

        kendo.Backbone.DataSource = kendo.data.DataSource.extend({
            init: function(options) {
                if (options.transport && options.transport.read) {
                    options.transport.read.type = 'GET';
                    options.transport.read.dataType = 'json';
                    options.transport.read.contentType = 'application/json;charset=UTF-8';
                }
                kendo.data.DataSource.fn.init.call(this, options);
            }
        });

        vm.setProfilePhoto = function ($file, customer) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        customer.profilePhoto = base64Data;
                        customer.profilePhotoContentType = $file.type;
                    });

                    $http.put('api/customers', customer)
                        .success(function (response) {
                            notification.show({
                                message: "Η ενημέρωση της φωτογραφίας ήταν επιτυχής!"
                            }, "success");
                        })
                        .error(function (response) {});
                });
            }
        };
    }

})();
