/**
 * Created by Teo on Σεπ, 2018
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadCallsAggregatedController', LeadCallsAggregatedController);

    LeadCallsAggregatedController.$inject = ['$state'];

    function LeadCallsAggregatedController ($state) {
        var vm = this;
        vm.$state = $state;
        vm.pageHeading = "Συγκεντρωτικα Lead Management";
    }
})();
