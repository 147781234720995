/**
 * Created by Kristy on 24/5/2017.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminEmployeeViewEmployeeSchedulesController', AdminEmployeeViewEmployeeSchedulesController);

    AdminEmployeeViewEmployeeSchedulesController.$inject = ['ApplicationParameters', '$http', '$filter', 'EmployeeViewService', 'KendoFilter', 'CustomUtils'];

    function AdminEmployeeViewEmployeeSchedulesController (ApplicationParameters, $http, $filter, EmployeeViewService, KendoFilter, CustomUtils) {
        var vm = this;
        vm.selectedUser = EmployeeViewService.getSelectedUser();
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(60);
        ApplicationParameters.hasModule('Scheduling', true);
        vm.schedulesGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "employee.id", "operator": "eq",  "value": vm.selectedUser.id}
                            ]
                        );

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        $http.get('/api/me/schedules',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (options, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        } else {
                            if (options.data.filter != undefined) {
                                angular.forEach(options.data.filter.filters, function (item) {
                                    if (item.field === "eventSchedule.startedAt") {
                                        item.value = kendo.toString(kendo.parseDate(item.value), 'yyyy-MM-dd');
                                    }
                                });
                            }
                            return data;
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        if(data.content != undefined) {
                            data.content.forEach(function(item){

                                if (item.eventCustomerSector === null) {
                                    item.eventCustomerSector = {
                                        customerSector: {
                                            customer: {
                                                last_name: "",
                                                first_name: ""
                                            },
                                            sector: {
                                                address: "",
                                                city: {
                                                    name: ""
                                                }
                                            }
                                        },
                                        sector: {
                                            address: "",
                                            sectorType: {}
                                        }
                                    };
                                } else {
                                    if (item.eventCustomerSector.customerSector === null) {
                                        item.eventCustomerSector.customerSector = {
                                            customer: {
                                                last_name: "",
                                                first_name: ""
                                            },
                                            sector: {
                                                address: "",
                                                city: {
                                                    name: ""
                                                }
                                            }
                                        };
                                    }

                                    if (item.eventCustomerSector.sector == null) {
                                        item.eventCustomerSector.sector = {
                                            sectorType: {}
                                        };
                                    }

                                    if (item.eventCustomerSector.sector.sectorType == null) {
                                        item.eventCustomerSector.sector.sectorType = {
                                            name: ""
                                        };
                                    }
                                }

                            });
                        }

                        return data.content;
                    },
                    total : function(data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            'eventSchedule.startedAt': { type: 'date'}
                        }
                    }
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                pageSize: 50
            }),
            height: vm.contentHeight,
            sortable: true,
            pageable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    title: "Επαγγελματίας Υγείας",
                    columns: [
                        {field: "eventCustomerSector.customerSector.customer.lastName", title: "Επώνυμο Επαγγελματία Υγείας",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.customerSector.customer.firstName", title: "Όνομα Επαγγελματία Υγείας",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        }
                    ]
                },
                {field: "eventSchedule.startedAt", title: "Ημερομηνία Ραντεβού", template: "#= eventSchedule.startedAt ? kendo.toString(kendo.parseDate(eventSchedule.startedAt), 'dddd, dd/MM/yyyy HH:mm') : ''#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    }
                },
                {
                    title: "Μονάδα Υγείας",
                    columns: [
                        {field: "eventCustomerSector.sector.sectorType.name", title: "Τύπος",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        { field: "eventCustomerSector.sector.name", title: "Όνομα",
                            filterable: {
                                cell: {
                                    operator: "like",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.sector.address", title: "Διεύθυνση",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.sector.cityName", title: "Πόλη",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        }
                    ]
                },
                {field: "title", title: "Τίτλος",
                    filterable: {
                        cell: {
                            operator: "like",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "eventSchedule.description", title: "Σημειώσεις Ραντεβού",
                    filterable: {
                        cell: {
                            operator: "like",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                }
            ],
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.schedulesGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }]
        };
    }

})();
