(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('FavouriteFilterDeleteController',FavouriteFilterDeleteController);

    FavouriteFilterDeleteController.$inject = ['$uibModalInstance', 'entity', 'FavouriteFilter'];

    function FavouriteFilterDeleteController($uibModalInstance, entity, FavouriteFilter) {
        var vm = this;
        vm.favouriteFilter = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            FavouriteFilter.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
