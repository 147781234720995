/**
 * Created by Kristy on 2/9/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('customerNotes', {
                parent: 'adminCustomerView',
                url: '/notes',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR', 'ROLE_HEALTH_VISITOR'],
                    pageTitle: 'sigmaCrmApp.customer.home.title'
                },
                templateUrl: 'app/displays/adminCustomers/notes/notes.html',
                controller: 'AdminCustomerViewNotesController',
                controllerAs: 'vm',
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('customer');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
