/**
 * Created by Teo on 23/08/2018.
 * A custom service using $resource just
 * like jHipster's default entity services,
 * but for '/search' API-endpoint
 * Use it like this:
   DiseaseTypeService.search({
        sort: {
            "field": "sortOrder",
            "dir": "asc"
        },
        page: 1,
        pageSize: 200,
        filter:{
            filters:[
                {
                    field: "sortOrder",
                    operator: "eq",
                    value: 1
                }
            ]
        }}).$promise.then(function(result){...});
 */

(function() {
    'use strict';
    angular
        .module('sigmasfApp')
        .factory('DiseaseTypeService', DiseaseTypeService);

    DiseaseTypeService.$inject = ['$resource'];

    function DiseaseTypeService ($resource) {
        var resourceUrl =  '/api/disease-types/search/';

        return $resource(resourceUrl, {}, {
            'search': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data.content;
                }
            }
        });
    }
})();
