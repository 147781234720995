(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PatchReportDialogController', PatchReportDialogController);

    PatchReportDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PatchReport', 'PatchCustomer', 'Employee', 'PatchReportMaterialCategory', 'CustomerSector'];

    function PatchReportDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PatchReport, PatchCustomer, Employee, PatchReportMaterialCategory, CustomerSector) {
        var vm = this;
        vm.patchReport = entity;
        vm.patchcustomers = PatchCustomer.query();
        vm.employees = Employee.query();
        vm.patchreportmaterialcategories = PatchReportMaterialCategory.query();
        vm.customersectors = CustomerSector.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:patchReportUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.patchReport.id !== null) {
                PatchReport.update(vm.patchReport, onSaveSuccess, onSaveError);
            } else {
                PatchReport.save(vm.patchReport, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.issueDate = false;
        vm.datePickerOpenStatus.executedDate = false;
        vm.datePickerOpenStatus.approvalDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
