(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('patch-customer', {
            parent: 'entity',
            url: '/patch-customer?page&sort&search',
            data: {
                authorities: ['ROLE_SYSTEM'],
                pageTitle: 'sigmasfApp.patchCustomer.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/patch-customer/patch-customers.html',
                    controller: 'PatchCustomerController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('patchCustomer');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('patch-customer-detail', {
            parent: 'entity',
            url: '/patch-customer/{id}',
            data: {
                authorities: ['ROLE_SYSTEM'],
                pageTitle: 'sigmasfApp.patchCustomer.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/patch-customer/patch-customer-detail.html',
                    controller: 'PatchCustomerDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('patchCustomer');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PatchCustomer', function($stateParams, PatchCustomer) {
                    return PatchCustomer.get({id : $stateParams.id});
                }]
            }
        })
        .state('patch-customer.new', {
            parent: 'patch-customer',
            url: '/new',
            data: {
                authorities: ['ROLE_SYSTEM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/patch-customer/patch-customer-dialog.html',
                    controller: 'PatchCustomerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                amka: null,
                                dateOfBirth: null,
                                firstName: null,
                                lastName: null,
                                directName: null,
                                directAmka: null,
                                directAma: null,
                                phone: null,
                                mobile: null,
                                addressNum: null,
                                address: null,
                                postalCode: null,
                                cityName: null,
                                prefectureName: null,
                                regionName: null,
                                countryName: null,
                                legacyCode: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('patch-customer', null, { reload: true });
                }, function() {
                    $state.go('patch-customer');
                });
            }]
        })
        .state('patch-customer.edit', {
            parent: 'patch-customer',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_SYSTEM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/patch-customer/patch-customer-dialog.html',
                    controller: 'PatchCustomerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PatchCustomer', function(PatchCustomer) {
                            return PatchCustomer.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('patch-customer', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('patch-customer.delete', {
            parent: 'patch-customer',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_SYSTEM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/patch-customer/patch-customer-delete-dialog.html',
                    controller: 'PatchCustomerDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PatchCustomer', function(PatchCustomer) {
                            return PatchCustomer.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('patch-customer', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
