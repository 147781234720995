(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchasePresentItemLotDetailController', PurchasePresentItemLotDetailController);

    PurchasePresentItemLotDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PurchasePresentItemLot', 'PurchasePresentItem'];

    function PurchasePresentItemLotDetailController($scope, $rootScope, $stateParams, entity, PurchasePresentItemLot, PurchasePresentItem) {
        var vm = this;
        vm.purchasePresentItemLot = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:purchasePresentItemLotUpdate', function(event, result) {
            vm.purchasePresentItemLot = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
