(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminPatchCustomersController', AdminPatchCustomersController);

    AdminPatchCustomersController.$inject = ['$scope', '$state', '$timeout', 'Principal', '$filter', 'ParseLinks', 'AlertService', 'KendoFilter', 'ApplicationParameters','$http'];

    function AdminPatchCustomersController ($scope, $state, $timeout, Principal, $filter, ParseLinks, AlertService, KendoFilter, ApplicationParameters, $http) {
        var vm = this;

        vm.pageHeading = 'Ασθενεις';
        ApplicationParameters.hasModule('Patches', true);

        var socialInsuranceDataSource = new kendo.data.DataSource({
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 50;
                    $http.get('/api/masterdata/social-insurances',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        vm.socialInsuranceComboBoxOptions = {
            dataSource: socialInsuranceDataSource,
            filterable: true,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };

        vm.gridColumns = [
            {
                field: "firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "lastName",
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "amka",
                title: "Α.Μ.Κ.Α",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "directAma", title: "Α.Μ.Α",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "socialInsurance.name", title: "Ασφάλιση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "phone",
                title: "Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "address", title: "Διεύθυνση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "cityName", title: "Πόλη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "prefectureName", title: "Νομός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "postalCode", title: "Τ.Κ",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];

        vm.gridToolbar = [{ template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label></div>",
            overflow: "never" }];

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/patch-customers/filter',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema: {
                    data: function (data) {
                        if(data != undefined) {
                            data.content.forEach(function(item){
                                if (!item.socialInsurance) {
                                    item.socialInsurance = {};
                                }
                            });
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: false }
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 100
            }),
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            columns: vm.gridColumns,
            toolbar: vm.gridToolbar
        };
    }
})();
