(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MeasurementUnitDeleteController',MeasurementUnitDeleteController);

    MeasurementUnitDeleteController.$inject = ['$uibModalInstance', 'entity', 'MeasurementUnit'];

    function MeasurementUnitDeleteController($uibModalInstance, entity, MeasurementUnit) {
        var vm = this;
        vm.measurementUnit = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            MeasurementUnit.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
