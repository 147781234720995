(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PatientDialogController', PatientDialogController);

    PatientDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'Patient', 'ProspectivePatient'];

    function PatientDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, Patient, ProspectivePatient) {
        var vm = this;
        vm.patient = entity;
        vm.prospectivepatients = ProspectivePatient.query({filter: 'patient-is-null'});
        $q.all([vm.patient.$promise, vm.prospectivepatients.$promise]).then(function() {
            if (!vm.patient.prospectivePatient || !vm.patient.prospectivePatient.id) {
                return $q.reject();
            }
            return ProspectivePatient.get({id : vm.patient.prospectivePatient.id}).$promise;
        }).then(function(prospectivePatient) {
            vm.prospectivepatients.push(prospectivePatient);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:patientUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.patient.id !== null) {
                Patient.update(vm.patient, onSaveSuccess, onSaveError);
            } else {
                Patient.save(vm.patient, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
    }
})();
