(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProspectivePatientDetailController', ProspectivePatientDetailController);

    ProspectivePatientDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ProspectivePatient', 'Attendant', 'Profession', 'NativeLanguage', 'Country', 'Region', 'Prefecture', 'City', 'Patient', 'CountryPhoneCode'];

    function ProspectivePatientDetailController($scope, $rootScope, $stateParams, entity, ProspectivePatient, Attendant, Profession, NativeLanguage, Country, Region, Prefecture, City, Patient, CountryPhoneCode) {
        var vm = this;
        vm.prospectivePatient = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:prospectivePatientUpdate', function(event, result) {
            vm.prospectivePatient = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
