(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('TaxStatusClassDeleteController',TaxStatusClassDeleteController);

    TaxStatusClassDeleteController.$inject = ['$uibModalInstance', 'entity', 'TaxStatusClass'];

    function TaxStatusClassDeleteController($uibModalInstance, entity, TaxStatusClass) {
        var vm = this;
        vm.taxStatusClass = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            TaxStatusClass.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
