/**
 * Created by Teo on 07/08/2019.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminEmployeeViewInfoController', AdminEmployeeViewInfoController);

    AdminEmployeeViewInfoController.$inject = ['$http', '$window', 'EmployeeViewService', 'NotificationOptions', 'ApplicationParameters', 'Principal', 'selectedEmployee', '$mdToast'];

    function AdminEmployeeViewInfoController ($http, $window, EmployeeViewService, NotificationOptions, ApplicationParameters, Principal, selectedEmployee, $mdToast) {
        var vm = this;
        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);
        ApplicationParameters.hasModule('Purchase_Pharmacies', false).then(function(response) {
            vm.hasModulePurchasePharmacies = response.data.content[0].value === "1";
        });
        vm.productCategoriesOptions = {
            placeholder: "Επιλέξτε κατηγορίες...",
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function(o) {
                        o.data.page = 1;
                        o.data.pageSize = 10000;
                        $http.get('/api/masterdata/product-categories',
                            {params: o.data})
                            .success(function (response) {
                                response.content.unshift({id:"none", name :"(Κενό)"});
                                o.success(response.content);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                }
            }),
            valuePrimitive: false,
            dataTextField: "name",
            dataValueField: "id"
        };
        vm.purchaseNumberingRowsOptions = {
            placeholder: "Επιλέξτε σειρές αρίθμησης...",
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function(o) {
                        o.data.page = 1;
                        o.data.pageSize = 10000;
                        $http.get('/api/purchase-numbering-rows/search',
                            {params: o.data})
                            .success(function (response) {
                                response.content.unshift({id:"none", name :"(Κενό)"});
                                o.success(response.content);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                }
            }),
            valuePrimitive: false,
            dataTextField: "name",
            dataValueField: "id"
        };
        vm.authoritiesOptions = {
            placeholder: "Επιλέξτε ρόλους...",
            dataSource: [
                "ROLE_USER",
                "ROLE_HEALTH_VISITOR",
                "ROLE_ADMIN",
                "ROLE_MANAGEMENT",
                "ROLE_SUPERVISOR",
                "ROLE_PATCH_VISITOR",
                "ROLE_PHARM_VISITOR",
                "ROLE_ACCOUNTING_DEP",
                "ROLE_STOREHOUSE",
                "ROLE_REC_HEALTH_VISITOR",
                "ROLE_REC_MANAGEMENT",
                "ROLE_REC_ADMINISTRATOR",
                "ROLE_REC_FRONT_DESK",
                "ROLE_BC_VISITOR",
                "ROLE_PHARM_SUPERVISOR",
                "ROLE_BI_VIEWER",
                "ROLE_CHECKER"
            ]
        };
        vm.districtComboBoxOptions = {
            dataSource: [
                { id: 1, name: 'ΒΟΡΕΙΑ ΕΛΛΑΔΑ' },
                { id: 2, name: 'ΝΟΤΙΑ ΕΛΛΑΔΑ' },
                { id: 3, name: 'ΔΥΤ. ΕΛΛΑΔΟΣ & ΠΕΛΟΠΟΝΝΗΣΟΥ' }
            ],
            valuePrimitive: false,
            filter: "startswith",
            dataTextField: "name",
            dataValueField: "id",
            placeholder: "Επιλογή περιοχής..."
        };
        vm.employeesComboBoxOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function(o) {
                        var url = '/api/employees/available-supervisors';

                        $http.get(url, {params: {id: selectedEmployee.id}})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema: {
                    parse: function (data) {
                        data.forEach( function (item) {
                            item.name = item.account.lastName + " " + item.account.firstName;
                        });
                        return data;
                    }
                },
                sort:{field:"account.lastName", dir:"asc"}
            }),
            filter: "contains",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };
        vm.employee = selectedEmployee;
        setDistinct();
        setSupervisor();
        $http.get('api/users/' + vm.employee.account.login).success(function (response) {
            vm.employee.account = response;
        }).error(function (error) {
            console.log(error);
            $mdToast.show(
                $mdToast.simple()
                    .textContent('Κάποιο σφάλμα προέκυψε κατά την λήψη Πληρφοριών Χρήστη!')
                    .position('bottom right')
                    .hideDelay(2500));
        });

        var userValidator = angular.element("#form").kendoValidator({}).data("kendoValidator");
        vm.updateUser = function() {
            var updatedUser = angular.copy(vm.employee.account);
            if (userValidator.validate()) {
                $http.put('/api/users', updatedUser)
                    .success(function () {
                        $mdToast.show(
                            $mdToast.simple()
                                .textContent('Επιτυχής ενημέρωση Χρήστη')
                                .position('bottom right')
                                .hideDelay(2000));
                    })
                    .error(function (error) {
                        console.log(error);
                        $mdToast.show(
                            $mdToast.simple()
                                .textContent('Κάποιο σφάλμα προέκυψε κατά την ενημέρωση Χρήστη!')
                                .position('bottom right')
                                .hideDelay(2500));
                    });
            }
        };

        var employeeValidator = angular.element("#form").kendoValidator({
            rules: {
                custom: function (input) {

                    if (input.data('custom')) {
                        console.log("-=-=-=-=-");
                        console.log(input.data('custom'));
                        if (input.val() !== null && input.val() !== "" && angular.element(input.data('custom')).data("kendoComboBox").selectedIndex === -1) {
                            return false;
                        }
                    }

                    return true;
                }
            },
            messages: {
                custom: "Πρέπει να επιλέξετε από την λίστα",
                maxlength: "Το Τηλέφωνο θα πρέπει να περιέχει 10 χαρακτήρες."
            }
        }).data("kendoValidator");
        vm.updateEmployee = function () {
            var updatedEmployee = angular.copy(vm.employee);
            if(updatedEmployee.district) {
                if (updatedEmployee.district.id === 1) {
                    updatedEmployee.district = 'north';
                } else if (updatedEmployee.district.id === 2) {
                    updatedEmployee.district = 'south';
                } else if (updatedEmployee.district.id === 3) {
                    updatedEmployee.district = 'westAndPeloponnese';
                }
            }

            if (updatedEmployee.supervisor === null || updatedEmployee.supervisor === undefined ||
                updatedEmployee.supervisor.id === null || updatedEmployee.supervisor.id === undefined) {
                updatedEmployee.supervisor = null;
            }

            if (employeeValidator.validate()) {
                $http.put('/api/employees', updatedEmployee)
                    .success(function () {
                        $mdToast.show(
                            $mdToast.simple()
                                .textContent('Επιτυχής ενημέρωση Ιατρικού Επισκέπτη')
                                .position('bottom right')
                                .hideDelay(2000));
                    })
                    .error(function (error) {
                        console.log(error);
                        $mdToast.show(
                            $mdToast.simple()
                                .textContent('Κάποιο σφάλμα προέκυψε κατά την ενημέρωση Ιατρικού Επισκέπτη!')
                                .position('bottom right')
                                .hideDelay(2500));
                    });
            }
        };

        vm.onOsVersionInfoClick = function () {
            $window.open('https://el.wikipedia.org/wiki/%CE%99%CF%83%CF%84%CE%BF%CF%81%CE%AF%CE%B1_%CE%B5%CE%BA%CE%B4%CF%8C%CF%83%CE%B5%CF%89%CE%BD_%CF%84%CE%BF%CF%85_Android', '_blank');
        };

        function setDistinct() {
            if(vm.employee.district === 'north') {
                vm.employee.district = { id: 1, name: vm.north };
            } else if(vm.employee.district === 'south') {
                vm.employee.district = { id: 2, name: vm.south };
            } else if(vm.employee.district === 'westAndPeloponnese') {
                vm.employee.district = { id: 3, name: vm.westAndPeloponnese };
            }
        }

        function setSupervisor() {
            if (vm.employee.supervisor) {
                vm.employee.supervisor.name =
                    vm.employee.supervisor.account.lastName + " " + vm.employee.supervisor.account.firstName;
            }
        }
    }
})();
