/**
 * Created by Kristy on 2/6/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('HospitalizationsController', HospitalizationsController);

    HospitalizationsController.$inject = ['$http', '$state', '$filter','KendoFilter', 'KendoToolbar', 'KendoGrid', 'CustomUtils'];

    function HospitalizationsController ($http, $state, $filter, KendoFilter, KendoToolbar, KendoGrid, CustomUtils) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(62);
        vm.pageHeading = "Εισαγωγες";
        var grid = {};

        grid.datasource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }
                    if(vm.from && vm.to) {
                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "dateIssue", "operator": "fromdate",
                                    "value": $filter('date')(vm.from, 'yyyy-MM-dd')},
                                { "field": "dateIssue", "operator": "todate",
                                    "value": $filter('date')(vm.to, 'yyyy-MM-dd')}
                            ]
                        );
                    }
                    if (o.data.sort === undefined || !o.data.sort.length) {
                        o.data.sort = { "field": "dateIssue",  "dir": "desc" };
                    }
                    angular.forEach( o.data.filter.filters, function(item) {
                        if ( item.value instanceof Date) {
                            item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                        }
                    });

                    $http.get('/api/hospitalizations/search',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        });
                }
            },
            schema : {
                data: function (data) {
                    if(data.content !== undefined) {
                        data.content.forEach(function(item){
                            if (!item.sector) item.sector = {};
                            if (!item.sector.sectorType) item.sector.sectorType = {};
                            if (!item.clinicName) item.clinicName = {};
                            if (!item.roomType) item.roomType = {};
                            if (!item.finalRoomType) item.finalRoomType = {};
                            if (!item.hospitalizationType) item.hospitalizationType = {};
                            if (!item.patient) item.patient = {};
                            if (!item.customer) item.customer = { customerTitle: {} };
                            if (!item.customer.customerTitle) item.customer.customerTitle = {};
                        });
                    }
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        dateIssue: { type: "date" }
                    }
                }
            },
            pageSize: 100,
            serverPaging: true,
            serverSorting: true,
            scrollable:false,
            serverFiltering :true
        });
        grid.columns = vm.gridColumns = [
            {
                template: "<md-button class='md-raised md-mini' style='min-width: 50px;margin: 0;' aria-label='add' ng-click='vm.edit(dataItem)'>" +
                    " <i class='dark-grey material-icons fixed-icons-2'>edit</i></md-button>",
                title: "&nbsp;",
                width: 80
            },
            {
                field: "patient.lastName",
                title: "Επώνυμο Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "patient.firstName",
                title: "Όνομα Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.lastName",
                title: "Επώνυμο Επαγγελματία Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.firstName",
                title: "Όνομα Επαγγελματία Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "sector.name",
                title: "Όνομα Μονάδας Υγείας",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "sector.sectorType.name",
                title: "Τύπος Μονάδας Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.customerTitle.name",
                title: "Τύπος Επαγγελματία Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "clinicName.name",
                title: "Κλινική",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "roomType.name",
                title: "Επιθυμητός Τύπος Δωματίου",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "finalRoomType.name",
                title: "Τελική Τοποθέτηση",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "hospitalizationType.name",
                title: "Παρατηρήσεις",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "notes",
                title: "Σχόλια",
                editor: function(container) {
                    angular.element('<textarea rows="3" cols="150" class = "k-textbox custom-popup-textarea pull-right" data-bind="value:notes"></textarea>')
                        .appendTo(container);
                },
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "dateIssue", title: "Ημερομηνία Εισαγωγής", template: "#= dateIssue ? kendo.toString(kendo.parseDate(dateIssue), 'dddd, dd/MM/yyyy') : ''#",
                filterable: {
                    cell: {
                        operator: "eqdateonly",
                        showOperators: false
                    }
                }
            }
        ];

        vm.hospitalizationsGridOptions = {
            dataSource: grid.datasource,
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: true,
            columns:  grid.columns,
            editable: false,
            toolbar: [
                /*{ template: "<md-button class='md-raised md-mini md-primary' aria-label='add' ng-click='vm.addNew()'>" +
                    "Προσθήκη Νέας Εγγραφής</md-button>" },*/
                { template: "<div class='right'><label>Σύνολο: {{vm.hospitalizationsGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }
            ]
        };

        vm.masterFiltersOnClick = function(e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id === "today") {
                KendoToolbar.clearDatePickers();
                vm.from = date;
                vm.to = date;
            }
            else if (e.id === "week") {
                KendoToolbar.clearDatePickers();
                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6
                vm.from = firstWeekDay;
                vm.to = lastWeekDay;
            }
            else if (e.id === "month") {
                KendoToolbar.clearDatePickers();
                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                vm.from = firstMonthDay;
                vm.to = lastMonthDay;
            }
            else if (e.id === "year") {
                KendoToolbar.clearDatePickers();
                vm.from = new Date(date.getFullYear(), 0, 1);
                vm.to = new Date();
            }
            KendoGrid.refresh(vm.hospitalizationsGridOptions);
        };

        vm.toolbarOptions = {
            items: KendoToolbar.getOptions(["buttonGroup", "year", "from", "to"], vm)
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.from = value;
                } else {
                    vm.from = null;
                }

                vm.masterFiltersOnClick(e);
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.to = value;
                } else {
                    vm.to = null;
                }

                vm.masterFiltersOnClick(e);
            }
        };

        vm.addNew = function() {
            $state.go('hospitalizationView', {id: null});
        };

        vm.onFilterChange = function () {
            angular.element("#hospitalizationsGrid").data("kendoGrid").dataSource.read();
        };

        vm.edit = function(dataItem) {
            $state.go('hospitalizationView', {id: dataItem.id});
        };
    }
})();
