(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ChangeTableDeleteController',ChangeTableDeleteController);

    ChangeTableDeleteController.$inject = ['$uibModalInstance', 'entity', 'ChangeTable'];

    function ChangeTableDeleteController($uibModalInstance, entity, ChangeTable) {
        var vm = this;
        vm.changeTable = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            ChangeTable.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
