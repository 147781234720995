(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AddConditionDetailController', AddConditionDetailController);

    AddConditionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'AddCondition'];

    function AddConditionDetailController($scope, $rootScope, $stateParams, entity, AddCondition) {
        var vm = this;
        vm.addCondition = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:addConditionUpdate', function(event, result) {
            vm.addCondition = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
