(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('FavouriteFilterDetailController', FavouriteFilterDetailController);

    FavouriteFilterDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'FavouriteFilter', 'Employee'];

    function FavouriteFilterDetailController($scope, $rootScope, $stateParams, DataUtils, entity, FavouriteFilter, Employee) {
        var vm = this;
        vm.favouriteFilter = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:favouriteFilterUpdate', function(event, result) {
            vm.favouriteFilter = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
    }
})();
