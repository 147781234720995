/**
 * Created by Kristy Kavvada on 29/11/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('BeautyCenterViewInfoController', BeautyCenterViewInfoController);

    BeautyCenterViewInfoController.$inject = ['$http', 'entity', 'employee', '$stateParams', 'Principal', '$scope', 'NotificationOptions', 'KendoService', 'Sector', '$timeout'];

    function BeautyCenterViewInfoController ($http, entity, employee, $stateParams, Principal, $scope, NotificationOptions, KendoService, Sector, $timeout) {
        var vm = this;
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");
        vm.isSaving = false;
        vm.canEdit = !!Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_ACCOUNTING_DEP']);
        vm.sector = entity;
        vm.selectedSectorId = $stateParams.id;
        vm.geodata = employee.data.geodata;

        vm.dataSource = {};
        vm.dropDownOptions = {};
        vm.dataSource.companyTypes = KendoService.initDefaultDataSource('/api/masterdata/company-types');
        vm.dropDownOptions.companyType = KendoService.initDefaultOptions(vm.dataSource.companyTypes, "startswith", "name", "id");
        vm.dataSource.taxOffices = KendoService.initDefaultDataSource('/api/masterdata/tax-offices');
        vm.dropDownOptions.taxOffice = KendoService.initDefaultOptions(vm.dataSource.taxOffices, "startswith", "name", "id");
        vm.dataSource.taxStatuses = KendoService.initDefaultDataSource('/api/tax-statuses/search');
        vm.dropDownOptions.taxStatus = KendoService.initDefaultOptions(vm.dataSource.taxStatuses, "startswith", "name", "id");

        if(!vm.geodata) {
            vm.dataSource.prefectures = KendoService.initDefaultDataSource('/api/masterdata/prefectures');
            vm.prefecturesComboBoxOptions = KendoService.initDefaultOptions(vm.dataSource.prefectures, "startswith", "name", "id");
            vm.prefecturesComboBoxOptions.valuePrimitive = false;
            vm.prefecturesComboBoxOptions.change = function(){
                var filter = {};
                if(this.value() && !isNaN(this.value())) {
                    filter = {
                        "field": "prefecture.id",
                        "operator": "eq",
                        "value": this.value()
                    };
                }
                vm.refreshCombobox(filter, vm.citiesComboBoxOptions.dataSource);
            };

            vm.dataSource.cities = KendoService.initDefaultDataSource('/api/masterdata/cities');
            vm.citiesComboBoxOptions = KendoService.initDefaultOptions(vm.dataSource.cities, "startswith", "name", "id");
            vm.citiesComboBoxOptions.valuePrimitive = false;
            vm.citiesComboBoxOptions.template = "<table>${name} &nbsp;<span class='hint'>(${prefecture.name})</span></table>";
            vm.citiesComboBoxOptions.change = function(){
                var filter = {};
                if(this.dataItem(this.select())) {
                    filter = {
                        "field"   : "id",
                        "operator": "eq",
                        "value"   : this.dataItem(this.select()).prefecture.id
                    };
                }
                vm.refreshCombobox(filter, vm.prefecturesComboBoxOptions.dataSource);
            };
        }
        else {
            var geodataDataSource = new kendo.data.DataSource({
                serverFiltering: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) o.data.filter = { filters: [] };

                        o.data.page = 1;
                        o.data.pageSize = 100;
                        $http.get('/api/masterdata/geodata', {params: o.data})
                            .success(function (response) {
                                o.success(response.content);
                            })
                            .error(function () {});
                    }
                },
                schema: {
                    parse: function(data) {
                        angular.forEach(data, function(item) {
                            item.masterFilter = "";
                            if(item.street !==null) {
                                item.masterFilter =  item.street + ", ";
                            }
                            item.masterFilter = item.masterFilter + item.city + ", " + item.postalCode + ", " + item.prefecture;
                        });
                        return data;
                    }
                }
            });

            vm.masterFilterComboBoxOptions = {
                dataSource: geodataDataSource,
                filter: "search",
                dataTextField: "masterFilter",
                dataValueField: "id",
                headerTemplate:"<div class='dropdown-header k-widget k-header'>" +
                "<span>Διεύθυνση</span>"+
                "<span>Πόλη</span>"+
                "<span>Τ.Κ.</span>"+
                "<span>Νομός</span></div>",
                template:
                    "#= street != null ? street + ', ' + city + ', ' + postalCode + ', ' + prefecture : city + ', ' + postalCode + ', ' + prefecture #",
                placeholder: "Αναζητήστε Διεύθυνση, Πόλη, Τ.Κ., Νομό",
                change: function(){
                    //initialize inputs (autocomplete)
                    if(this.dataItem(this.select())) {
                        var self = this;
                        //using $timeout to trigger the change
                        $timeout(function(){
                            vm.sector.prefectureName = self.dataItem(self.select()).prefecture;
                            vm.sector.cityName = self.dataItem(self.select()).city;
                            vm.sector.address = self.dataItem(self.select()).street;
                            vm.sector.postalCode = self.dataItem(self.select()).postalCode;
                        },0);
                    }
                }
            };
        }

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:sectorUpdate', result);
            vm.isSaving = false;
            notification.show({
                message: "Η ενημέρωση των στοιχείων ήταν επιτυχής!"
            }, "success");
        };

        var onSaveError = function () {
            vm.isSaving = false;
            notification.show({
                message: "Η ενημέρωση των στοιχείων απέτυχε!"
            }, "error");
        };

        vm.save = function () {
            vm.isSaving = true;
            Sector.update(vm.sector, onSaveSuccess, onSaveError);
        };

        vm.refreshCombobox = function(filter, combobox) {
            combobox.filter(filter);
            combobox.read();
        };
    }

})();
