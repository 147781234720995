/**
 * Created by Kristy on 10/3/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('tradePolicies', {
            parent: 'masterData',
            url: '/tradePolicies',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_ACCOUNTING_DEP']
            },
            views: {
                'content@': {
                    templateUrl: 'app/masterData/tradePolicies/tradePolicies.html',
                    controller: 'MDTradePoliciesController',
                    controllerAs: 'vm'
                }
            },
            params: {
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', function ($stateParams) {
                    return {
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('masterData');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('tradePoliciesView', {
            parent: 'app',
            url: '/tradePolicies/search?id:tradePolicyId',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_ACCOUNTING_DEP']
            },
            views: {
                'content@': {
                    templateUrl: 'app/masterData/tradePolicies/tradePoliciesView.html',
                    controller: 'MDTradePoliciesViewController',
                    controllerAs: 'vm'
                }
            },
            params: {
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', function ($stateParams) {
                    return {
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('masterData');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
    }

})();
