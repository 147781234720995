/**
 * Created by Maria on 30/6/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CustomerSchedulesGridController', CustomerSchedulesGridController);

    CustomerSchedulesGridController.$inject = ['$http','$state', '$stateParams','$filter', 'Principal'];

    function CustomerSchedulesGridController ($http, $state, $stateParams, $filter, Principal) {
        var vm = this;
        if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR'])) {
            vm.canEdit = true;
        }else {
            vm.readOnly = true;
        }

        vm.customer = {};
        vm.selectedUserId = $stateParams.id;
        $http.get('api/customers/' + vm.selectedUserId)
            .success(function (response) {
                vm.customer = response;
            })
            .error(function (response) {
                console.log(response);
            });

        vm.mainGridOptions = {
            dataSource: {
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "eventCustomerSector.customerSector.customer.id", "operator": "eq",  "value": vm.selectedUserId}
                            ]
                        );

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        $http.get('/api/me/schedules',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                console.log(response)
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (options, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        } else {
                            if (options.data.filter != undefined) {
                                angular.forEach(options.data.filter.filters, function (item) {
                                    if (item.field === "eventSchedule.startedAt") {
                                        item.value = kendo.toString(kendo.parseDate(item.value), 'yyyy-MM-dd');
                                    }
                                });
                            }
                            return data;
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        if(data.content != undefined) {
                            data.content.forEach(function(item){

                                if (item.eventCustomerSector === null) {
                                    item.eventCustomerSector = {
                                        customerSector: {
                                            sector: {
                                                address: "",
                                                city: {
                                                    name: ""
                                                },
                                                sectorType: {
                                                    name: ""
                                                }
                                            }
                                        }
                                    };
                                }

                                if (item.eventCustomerSector.customerSector == null) {
                                    item.eventCustomerSector.customerSector = {
                                        sector: {
                                            sectorType: {
                                                name: ""
                                            }
                                        }
                                    };
                                }

                                if (item.eventCustomerSector.customerSector.sector == null) {
                                    item.eventCustomerSector.customerSector.sector = {
                                        name: "",
                                        sectorType: {
                                            name: ""
                                        }
                                    };
                                }

                                if (item.eventCustomerSector.customerSector.sector.sectorType == null) {
                                    item.eventCustomerSector.customerSector.sector.sectorType = {
                                            name: ""
                                    };
                                }

                                if (item.employee === null) {
                                    item.employee = { account: { lastName: ""}};
                                }else {
                                    if (item.employee.account === null) {
                                        item.employee.account = { lastName: "" };
                                    }
                                }

                            });
                        }

                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            'eventSchedule.startedAt': { type: 'date'}
                        }
                    }
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                pageSize: 50
            },
            sortable: true,
            pageable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {field: "eventSchedule.startedAt", title: "Ημερομηνία Ραντεβού", template: "#= eventSchedule.startedAt ? kendo.toString(kendo.parseDate(eventSchedule.startedAt), 'dddd, dd/MM/yyyy HH:mm') : ''#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    }
                },
                {
                    title: "Μονάδα Υγείας",
                    columns: [
                        {field: "eventCustomerSector.customerSector.sector.sectorType.name", title: "Τύπος",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    showOperators: false
                                }
                            }
                        },
                        { field: "eventCustomerSector.customerSector.sector.name", title: "Όνομα",
                            filterable: {
                                cell: {
                                    operator: "like",
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.customerSector.sector.address", title: "Διεύθυνση",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.customerSector.sector.cityName", title: "Πόλη",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    showOperators: false
                                }
                            }
                        }
                    ]
                },
                {
                    title: "Ιατρικός Επισκέπτης",
                    columns: [
                        {field: "employee.account.firstName", title: "Όνομα Ιατρικού Επισκέπτη",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    showOperators: false
                                }
                            }
                        },
                        {field: "employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    showOperators: false
                                }
                            }
                        }
                    ]
                },
                {field: "title", title: "Τίτλος",
                    filterable: {
                        cell: {
                            operator: "like",
                            showOperators: false
                        }
                    }
                },
                {field: "eventSchedule.description", title: "Σημειώσεις Ραντεβού",
                    filterable: {
                        cell: {
                            operator: "like",
                            showOperators: false
                        }
                    }
                }
            ]
        };

    }
})();
