/**
 * Created by Kristy on 10/3/2017.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MDTradePoliciesViewController', MDTradePoliciesViewController);

    MDTradePoliciesViewController.$inject = ['$rootScope', '$http','$state', '$stateParams', '$filter', 'NotificationOptions', 'TradePolicy'];

    function MDTradePoliciesViewController ($rootScope, $http, $state, $stateParams, $filter, NotificationOptions, TradePolicy) {
        var vm = this;
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");
        // if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT'])) {
        //     vm.canEdit = true;
        // }else {
        //     vm.readOnly = true;
        // }

        vm.pageHeading = $filter('translate')('masterData.tradePolicy.title');
        vm.tradePolicy = {};
        vm.profilePicture = "handshake_icon.png";
        vm.selectedTradePolicyId = $stateParams.id;
        vm.$state = $state;

        $http.get('api/trade-policies/' + vm.selectedTradePolicyId)
            .success(function (response) {
                vm.tradePolicy = response;
            })
            .error(function (response) {
                console.log(response);
            });

        vm.update = function() {
            vm.tradePolicy.fromDate = kendo.toString(kendo.parseDate(vm.tradePolicy.fromDate), 'yyyy-MM-dd');
            vm.tradePolicy.toDate = kendo.toString(kendo.parseDate(vm.tradePolicy.toDate), 'yyyy-MM-dd');
            TradePolicy.update(vm.tradePolicy)
                .$promise.then(
                //success
                function( value ){
                    notification.show({
                        message: "Η ενημέρωση των στοιχείων ήταν επιτυχής!"
                    }, "success");
                },
                //error
                function( error ){
                    console.log(error);
                    notification.show({
                        message: "Η ενημέρωση των στοιχείων απέτυχε!"
                    }, "error");
                }
            );
        };
    }

})();
