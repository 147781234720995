/**
 * Created by Teo on 03/07/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('leadEvents', {
                parent: 'app',
                url: '/leadEvents',
                data: {
                    authorities: ['ROLE_REC_MANAGEMENT', 'ROLE_REC_FRONT_DESK'],
                    pageTitle: 'sigmaCrmApp.global.menu.lead-management.lead-events'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/leadEvents/leadEvents.html',
                        controller: 'LeadEventsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
