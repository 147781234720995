(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchaseNumberingRowDetailController', PurchaseNumberingRowDetailController);

    PurchaseNumberingRowDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PurchaseNumberingRow'];

    function PurchaseNumberingRowDetailController($scope, $rootScope, $stateParams, entity, PurchaseNumberingRow) {
        var vm = this;
        vm.purchaseNumberingRow = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:purchaseNumberingRowUpdate', function(event, result) {
            vm.purchaseNumberingRow = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
