/**
 * Created by Kristy on 2/9/2016.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminCustomerViewNotesController', AdminCustomerViewNotesController);

    AdminCustomerViewNotesController.$inject = ['$http','$state', '$stateParams', '$filter', 'Principal', 'NotificationOptions'];

    function AdminCustomerViewNotesController ($http, $state, $stateParams, $filter, Principal, NotificationOptions) {
        var vm = this;

        if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR'])) {
            vm.canEdit = true;
        }else {
            vm.readOnly = true;
        }

        vm.customer = {};
        vm.selectedUserId = $stateParams.id;

        $http.get('api/customers/' + vm.selectedUserId)
            .success(function (response) {
                vm.customer = response;
            })
            .error(function (response) {
                console.log(response);
            });

        var validator = angular.element("#form").kendoValidator({}).data("kendoValidator");
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");

        vm.update = function() {
            if (validator.validate()) {
                $http.put('api/customers', vm.customer)
                    .success(function (response) {
                        notification.show({
                            message: "Η ενημέρωση των στοιχείων ήταν επιτυχής!"
                        }, "success");
                    })
                    .error(function (response) {
                        notification.show({
                            message: "Η ενημέρωση των στοιχείων απέτυχε!"
                        }, "error");
                        console.log(response);
                    });
            }else{
                notification.show({
                    message: "Η ενημέρωση των στοιχείων απέτυχε!"
                }, "error");
            }

        };
    }

})();
