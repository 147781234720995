(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchasePresentItemLotDeleteController',PurchasePresentItemLotDeleteController);

    PurchasePresentItemLotDeleteController.$inject = ['$uibModalInstance', 'entity', 'PurchasePresentItemLot'];

    function PurchasePresentItemLotDeleteController($uibModalInstance, entity, PurchasePresentItemLot) {
        var vm = this;
        vm.purchasePresentItemLot = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            PurchasePresentItemLot.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
