(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('NativeLanguageDetailController', NativeLanguageDetailController);

    NativeLanguageDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'NativeLanguage'];

    function NativeLanguageDetailController($scope, $rootScope, $stateParams, entity, NativeLanguage) {
        var vm = this;
        vm.nativeLanguage = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:nativeLanguageUpdate', function(event, result) {
            vm.nativeLanguage = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
