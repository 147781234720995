/**
 * Created by Kristy on 28/7/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('EmployeesService', EmployeesService);

    EmployeesService.$inject = ['KendoFilter', '$filter'];

    function EmployeesService(KendoFilter, $filter) {
        var hasAssignations = false;
        var popupGrid = false;
        var employeesGridColumns = [
            {
                title: "Στοιχεία Χρήστη",
                columns: [
                    {
                        field: "account.login",
                        title: "Όνομα σύνδεσης",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    {
                        field: "account.firstName",
                        title: "Όνομα",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    {
                        field: "account.lastName",
                        title: "Επώνυμο",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    }
                ]
            },
            {
                field: "phone",
                title: "Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "district",
                title: "Περιοχή",
                template: "#= (district == 'north') ? 'ΒΟΡΕΙΑ ΕΛΛΑΔΑ' : (district == 'south') ? 'ΝΟΤΙΑ ΕΛΛΑΔΑ' : (district == 'westAndPeloponnese') ? 'ΔΥΤ. ΕΛΛΑΔΟΣ & ΠΕΛΟΠΟΝΝΗΣΟΥ' : '' #",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false,
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: $filter('translate')('sigmasfApp.customer.districts.north'), value: "north" },
                                        { text: $filter('translate')('sigmasfApp.customer.districts.south'), value: "south" },
                                        { text: $filter('translate')('sigmasfApp.customer.districts.westAndPeloponnese'), value: "westAndPeloponnese" }]
                                }),
                                valuePrimitive: true

                            })
                        }
                    }
                }
            },
            {
                field: "mobileVersion",
                title: "Mobile Έκδοση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "supervisor.account.fullName",
                title: "Προϊστάμενος",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];

        return {
            getHasAssignations: getHasAssignations,
            setHasAssignations: setHasAssignations,
            getInitPopupGrid: getInitPopupGrid,
            setInitPopupGrid: setInitPopupGrid,
            getEmployeesOptions: getEmployeesOptions
        };

        function getHasAssignations() {
            return hasAssignations;
        }

        function setHasAssignations(value) {
            hasAssignations = value;
        }

        function getInitPopupGrid() {
            return popupGrid;
        }

        function setInitPopupGrid(value) {
            popupGrid = value;
        }

        function getEmployeesOptions(datasource) {
            return {
                dataSource: datasource,
                sortable: true,
                pageable: true,
                selectable: true,
                scrolable: true,
                filterable: {
                    mode: "row"
                },
                resizable: true,
                columns: employeesGridColumns
            };
        }
    }
})();
