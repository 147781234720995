/**
 * Created by Teo on 07/08/2019.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminEmployeeViewCustomerSectorsController', AdminEmployeeViewCustomerSectorsController);

    AdminEmployeeViewCustomerSectorsController.$inject = ['$http', '$filter', '$state', 'customersView', 'KendoFilter', 'EmployeeViewService', 'CustomUtils', 'selectedEmployee'];

    function AdminEmployeeViewCustomerSectorsController ($http, $filter, $state, customersView, KendoFilter, EmployeeViewService, CustomUtils, selectedEmployee) {
        var vm = this;
        vm.customerView = customersView.data.content[0].value;
        vm.selectedEmployee = selectedEmployee;
        vm.masterFilters = { myTarget: true };

        vm.collapseAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-collapse").trigger("click"); };
        vm.expandAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-expand").trigger("click"); };

        var filterTemplate = function (args) { KendoFilter.getTemplate(args); };

        vm.mainGridOptions = {
            autoBind: true,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function (o) {
                        o.data.filter = {filters: []};

                        o.data.filter.filters.push({
                            field: "employee.id",
                            operator: "eq",
                            value: vm.selectedEmployee.id
                        });

                        // MyTarget
                        o.data.filter.filters.push({
                            field: "myTarget",
                            operator: "eqbool",
                            "value": vm.masterFilters.myTarget
                        });

                        // Ενεργοί
                        o.data.filter.filters.push({
                            field: "active",
                            operator: "eqbool",
                            value: true
                        });

                        if (vm.customerView === '3') {
                            // Cross, fetch only not expired customerSectors
                            o.data.filter.filters.push({
                                field: "customerSector.endedAt",
                                operator: "null_or_greater",
                                value: $filter('date')(Date.now(), 'yyyy-MM-dd')
                            });
                        }

                        o.data.sort = {
                            field: "customerSector.customer.lastName",
                            dir: "asc"
                        };

                        $http.get('/api/employee-customer-sectors/all', {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                o.error(response);
                            });
                    }
                },
                schema: {
                    data: function (data) {
                        data.forEach(function (item) {
                            if (item.customerSector === null) {
                                item.customerSector = {
                                    customer: {
                                        customerCategory: {},
                                        customerSubCategory: {},
                                        doctorSpecialty: {}
                                    },
                                    sector: {
                                        city: {},
                                        prefecture: {},
                                        sectorType: {}
                                    },
                                    main: 'false',
                                    dayTime: ''
                                }
                            }else {
                                if (item.customerSector.customer === null) item.customerSector.customer = {};
                                if (item.customerSector.customer !== null) {
                                    if (item.customerSector.customer.customerCategory === null) item.customerSector.customer.customerCategory = {};
                                    if (item.customerSector.customer.customerSubCategory === null) item.customerSector.customer.customerSubCategory = {};
                                    if (item.customerSector.customer.doctorSpecialty === null) item.customerSector.customer.doctorSpecialty = {}
                                }
                                if (item.customerSector.sector === null) item.customerSector.sector = {};
                                if (item.customerSector.sector !== null) {
                                    if (item.customerSector.sector.city === null) item.customerSector.sector.city = {};
                                    if (item.customerSector.sector.prefecture === null) item.customerSector.sector.prefecture = {};
                                    if (item.customerSector.sector.sectorType === null) item.customerSector.sector.sectorType = {};
                                }
                                item.main = item.customerSector.main === true ? "true" : "false";
                                item.dayTime = item.customerSector.customer !== null ? (item.customerSector.customer.dayTime === 'day' ? 'ΠΡΩΙΝΌ' : item.customerSector.customer.dayTime === 'night' ? 'ΑΠΟΓΕΥΜΑΤΙΝΌ' : '') : '';
                                item.myTarget = item.myTarget === true ? 'Ναι' : 'Όχι';
                            }
                        });
                        return data;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: {editable: false, nullable: false},
                            isActive: {
                                type: "boolean",
                                defaultValue: true,
                                parse: function (value) {
                                    return !!(value == "1" || value == "true" || value == true);
                                }
                            }
                        }
                    }
                },
                aggregate: [
                    {field: "customerSector.customer.lastName", aggregate: "count"},
                    {field: "customerSector.customer.firstName", aggregate: "count"},
                    {field: "customerSector.customer.doctorSpecialty.name", aggregate: "count"},
                    {field: "customerSector.customer.customerCategory.name", aggregate: "count"},
                    {field: "customerSector.customer.customerSubCategory.name", aggregate: "count"},
                    {field: "customerSector.customer.dayTime", aggregate: "count"},
                    {field: "customerSector.sector.name", aggregate: "count"},
                    {field: "customerSector.sector.sectorType.name", aggregate: "count"},
                    {field: "customerSector.sector.address", aggregate: "count"},
                    {field: "customerSector.sector.prefectureName", aggregate: "count"},
                    {field: "customerSector.sector.cityName", aggregate: "count"},
                    {field: "main", aggregate: "count"},
                    {field: "myTarget", aggregate: "count"}
                ]
            }),
            sortable: true,
            groupable: {
                messages: {
                    empty: "Σύρετε μια στήλη εδώ για εφαρμογή ομαδοποίησησς σε αυτή τη στήλη"
                }
            },
            noRecords: {
                template: "Δεν βρέθηκαν Επαγγελματίες Υγείας"
            },
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: "cell",
            columns: [
                {
                    title: "Στοιχεία Επαγγελματία Υγείας",
                    columns: [
                        {
                            field: "customerSector.customer.lastName",
                            title: "Επώνυμο",
                            attributes: {
                                class: "link-cell link-cell-color"
                            },
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 140,
                            groupHeaderTemplate: "#= value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "customerSector.customer.firstName",
                            title: "Όνομα",
                            attributes: {
                                class: "link-cell link-cell-color"
                            },
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 120,
                            groupHeaderTemplate: "#= (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "customerSector.customer.doctorSpecialty.name",
                            title: "Ειδικοτητα 1η",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 130,
                            groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "customerSector.customer.customerCategory.name",
                            title: "Κατηγορία",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 60,
                            groupHeaderTemplate: "#=  typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "customerSector.customer.customerSubCategory.name",
                            title: "Υποκατηγορία",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 60,
                            groupHeaderTemplate: "#=  typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        }
                    ]
                },
                {
                    title: "Στοιχεία Μονάδας Υγείας",
                    columns: [
                        {
                            field: "customerSector.sector.sectorType.name",
                            title: "Τύπος",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 120,
                            groupHeaderTemplate: "#= (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "customerSector.sector.name",
                            title: "Όνομα",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "contains",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 190,
                            groupHeaderTemplate: "#= value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "customerSector.sector.address",
                            title: "Διεύθυνση",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 120,
                            groupHeaderTemplate: "#= (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "customerSector.sector.cityName",
                            title: "Πόλη",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 90,
                            groupHeaderTemplate: "#= (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "customerSector.sector.prefectureName",
                            title: "Νομός",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 80,
                            groupHeaderTemplate: "#= (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "customerSector.sector.osfeBrick",
                            title: "Brick ΟΣΦΕ",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            width: 80,
                            groupHeaderTemplate: "#= (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)",
                            hidden: vm.customerView !== '1'
                        }
                    ]
                },
                {
                    field: "dayTime",
                    title: "Ωράριο",
                    aggregates: ["count"],
                    template: "#= (dayTime == 'ΠΡΩΙΝΌ') ? 'ΠΡΩΙΝΌ' : (dayTime == 'ΑΠΟΓΕΥΜΑΤΙΝΌ') ? 'ΑΠΟΓΕΥΜΑΤΙΝΌ' : '' #",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            showOperators: false,
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind: false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{text: "ΠΡΩΙΝΌ", value: "ΠΡΩΙΝΌ"},
                                            {text: "ΑΠΟΓΕΥΜΑΤΙΝΌ", value: "ΑΠΟΓΕΥΜΑΤΙΝΌ"}]
                                    }),
                                    valuePrimitive: true
                                });
                            }
                        }
                    },
                    width: 100,
                    groupHeaderTemplate: "#= typeof value !== 'undefined' ? ((value == 'ΠΡΩΙΝΟ') ? 'ΠΡΩΙΝΟ' : (value == 'ΑΠΟΓΕΥΜΑΤΙΝΟ') ? 'ΑΠΟΓΕΥΜΑΤΙΝΟ' : '') : '' #(Σύνολο: #=kendo.format('{0:n0}', count)#)",
                    hidden: vm.customerView === '1' || vm.customerView === '6'
                },
                {
                    field: "myTarget",
                    title: "MyTarget",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "eq",
                            showOperators: false,
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind: false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{text: "Ναι", value: "Ναι"},
                                            {text: "Όχι", value: "Όχι"}]
                                    }),
                                    valuePrimitive: true
                                });
                            }
                        }
                    },
                    width: 100,
                    groupHeaderTemplate: "#= typeof value !== 'undefined' ? ((value == 'Ναι') ? 'MyTarget: Ναι' : (value == 'Όχι') ? 'MyTarget: Όχι' : '') : '' #(Σύνολο: #=kendo.format('{0:n0}', count)#)"
                }
            ],
            toolbar: [
                {template: "<md-button class='md-raised md-mini md-primary' aria-label='collapseAll' ng-click='vm.collapseAll()'>Collapse All</md-button>"},
                {template: "<md-button class='md-raised md-mini md-primary' aria-label='expandAll' ng-click='vm.expandAll()'>Expand All</md-button>"},
                {
                    template: "<label class='left-space'>My Target: </label><div class='inline'>" +
                    "<md-radio-group class='left-space' layout='row' ng-change='vm.mainGridOptions.dataSource.read()' ng-model='vm.masterFilters.myTarget'>" +
                    "<md-radio-button ng-value=true class='md-primary'> Ναι </md-radio-button>" +
                    "<md-radio-button ng-value=false class='md-primary'> Όχι </md-radio-button>" +
                    "<md-radio-button ng-value='' class='md-primary'> Όλα </md-radio-button></md-radio-group></div>",
                    overflow: "never", attributes: {class: "left-space"}
                },
                {
                    name: "excel",
                    template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                    "<span class='k-icon k-i-excel'></span>Export</md-button></div>"
                }
            ],
            height: CustomUtils.getHeightPixelsFromPercentage(57),
            excel: {
                allPages: true,
                fileName: "Επαγγελματίες Υγείας_" + vm.selectedEmployee.account.lastName + " " + vm.selectedEmployee.account.firstName + ".xlsx"
            },
            change: onChange,
        };

        function onChange() {
            // Get selected cell
            var selected = this.select();
            // Get the row that the cell belongs to.
            var row = this.select().closest("tr");
            var cellIndex = selected[0].cellIndex;

            if(cellIndex === 0 || cellIndex === 1) {
                $state.go('customerInfo', {id: this.dataItem(row).customerSector.customer.id});
            }
        }
    }
})();
