/**
 * Created by Kristy on 30/8/2016.
 */
(function () {
    'use strict';

    angular
        .module('sigmasfApp')
        .service('Assignations', Assignations);

    Assignations.$inject = ['$filter'];
    function Assignations ($filter) {
        var selectedCustomerSectors,
            employees = [];
        var employee, copy;

        return {
            getSelectedCustomerSectors: function () {
                return selectedCustomerSectors;
            },
            setSelectedCustomerSectors: function (value) {
                selectedCustomerSectors = value;
            },
            getEmployees: function () {
                return employees;
            },
            setEmployees: function (value) {
                employees = value;
            },
            getEmployee: function () {
                return employee;
            },
            setEmployee: function (value) {
                employee = value;
            },
            isCopy: function () {
                return copy;
            },
            setCopy: function (value) {
                copy = value;
            }
        };

    }
})();
