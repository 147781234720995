(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadCallDetailController', LeadCallDetailController);

    LeadCallDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'LeadCall', 'CallType', 'ProspectivePatient', 'Patient', 'LeadHospitalization', 'Customer'];

    function LeadCallDetailController($scope, $rootScope, $stateParams, entity, LeadCall, CallType, ProspectivePatient, Patient, LeadHospitalization, Customer) {
        var vm = this;
        vm.leadCall = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:leadCallUpdate', function(event, result) {
            vm.leadCall = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
