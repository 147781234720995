/**
 * Created by Kristy on 27/7/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CustomerSectorAssignationController', CustomerSectorAssignationController);

    CustomerSectorAssignationController.$inject = ['$stateParams', '$timeout','$http', 'KendoService', 'CustomerSectorsService', 'KendoFilter'];

    function CustomerSectorAssignationController ($stateParams, $timeout, $http, KendoService, CustomerSectorsService, KendoFilter) {
        var vm = this;
        vm.selectedUserId = $stateParams.id;
        vm.customerSectorsService = CustomerSectorsService;
        vm.customerSectorsService.setIsSectorSaved(false);
        vm.sectorIsSaved = vm.newSector =false;
        vm.newCustomerSector = { startedAt: new Date(), main: true };
        var dataSource = {};

        /**
         * Get selected Customer for autocomplete his
         * name when a private SectorType is selected
         */
        $http.get('api/customers/' + vm.selectedUserId)
            .success(function (response) {
                vm.customer = response;
            })
            .error(function (response) {
                console.log(response);
            });

        var promise = $http.get('/api/employees/me');
        promise.then(function(response) {
            vm.geodata = response.data.geodata;
            if(!vm.geodata) vm.initializeMasterData();
            else vm.initializeGeodata();
        });

        vm.refreshCombobox = function(filter, combobox) {
            combobox.filter(filter);
            combobox.read();
        };

        vm.initializeMasterData = function() {
            //Prefectures Combobox
            dataSource.prefecturesDataSource = KendoService.initDefaultDataSource('/api/masterdata/prefectures');
            vm.prefecturesComboBoxOptions = KendoService.initDefaultOptions(dataSource.prefecturesDataSource, "startswith", "name", "id");
            vm.prefecturesComboBoxOptions.valuePrimitive = false;
            vm.prefecturesComboBoxOptions.change = function() {
                var filter = {};
                if(this.value() && !isNaN(this.value())) {
                    filter = {
                        "field": "prefecture.id",
                        "operator": "eq",
                        "value": this.value()
                    };
                }
                vm.refreshCombobox(filter, vm.citiesComboBoxOptions.dataSource);
            };
            vm.customerSectorsService.setPrefecturesComboboxOptions(vm.prefecturesComboBoxOptions);

            //Cities Combobox
            dataSource.citiesDataSource = KendoService.initDataSource('/api/masterdata/cities', 1, 50);
            vm.citiesComboBoxOptions = KendoService.initDefaultOptions(dataSource.citiesDataSource, "startswith", "name", "id");
            vm.citiesComboBoxOptions.valuePrimitive = false;
            vm.citiesComboBoxOptions.template = "<table>${name} &nbsp;<span class='hint'>(${prefecture.name})</span></table>";
            vm.citiesComboBoxOptions.change = function() {
                var filter = {};
                if(this.dataItem(this.select())) {
                    filter = {
                        "field"   : "id",
                        "operator": "eq",
                        "value"   : this.dataItem(this.select()).prefecture.id
                    };
                    vm.refreshCombobox(filter, vm.prefecturesComboBoxOptions.dataSource);
                } else {
                    vm.refreshCombobox(filter, vm.prefecturesComboBoxOptions.dataSource);
                }
            };
            vm.customerSectorsService.setCitiesComboboxOptions(vm.citiesComboBoxOptions);
        };

        vm.initializeGeodata = function() {
            dataSource.geodataDataSource = new kendo.data.DataSource({
                serverFiltering: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.page = 1;
                        o.data.pageSize = 100;
                        $http.get('/api/masterdata/geodata',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response.content);
                            })
                            .error(function (response) {});
                    }
                },
                schema: {
                    parse: function(data) {
                        angular.forEach(data, function(item) {
                            item.masterFilter = "";
                            if(item.street !==null) {
                                item.masterFilter =  item.street + ", ";
                            }
                            item.masterFilter = item.masterFilter + item.city + ", " + item.postalCode + ", " + item.prefecture;
                        });
                        return data;
                    }
                }
            });

            vm.masterFilterComboBoxOptions = KendoService.initDefaultOptions(dataSource.geodataDataSource, "search", "masterFilter", "id");
            vm.masterFilterComboBoxOptions.headerTemplate = "<div class='dropdown-header k-widget k-header'>" +
                "<span>Διεύθυνση</span>"+
                "<span>Πόλη</span>"+
                "<span>Τ.Κ.</span>"+
                "<span>Νομός</span></div>";
            vm.masterFilterComboBoxOptions.template = "#= street != null ? street + ', ' + city + ', ' + postalCode + ', ' + prefecture : city + ', ' + postalCode + ', ' + prefecture #";
            vm.masterFilterComboBoxOptions.placeholder =   "Αναζητήστε Διεύθυνση, Πόλη, Τ.Κ., Νομό";
            vm.masterFilterComboBoxOptions.change = function(){
                //initialize inputs (autocomplete)
                if(this.dataItem(this.select())) {
                    var self = this;
                    //using $timeout to trigger the change
                    $timeout(function(){
                        vm.sector.prefectureName = self.dataItem(self.select()).prefecture;
                        vm.sector.cityName = self.dataItem(self.select()).city;
                        vm.sector.address = self.dataItem(self.select()).street;
                        vm.sector.postalCode = self.dataItem(self.select()).postalCode;
                        vm.suggestBricks(vm.sector.postalCode);
                    },0);
                }
            };
        };

        //SectorPositions Combobox
        dataSource.sectorPositionsDataSource = KendoService.initDefaultDataSource('/api/masterdata/sector-positions');
        vm.sectorPositionComboBoxOptions = KendoService.initDefaultOptions(dataSource.sectorPositionsDataSource, "startswith", "name", "id");

        //SectorTypes Combobox
        dataSource.sectorTypesDataSource = new kendo.data.DataSource({
            // filter: sectorTypeFilter(),
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }
                    o.data.filter.filters.push({ "field": "sectorTypeCategory", "operator": "startswith", "value": 'hcu'});

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get('/api/masterdata/sector-types',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            }
        });
        vm.sectorTypeComboBoxOptions = KendoService.initDefaultOptions(dataSource.sectorTypesDataSource, "startswith", "name", "id");
        vm.sectorTypeComboBoxOptions.suggest = true;
        vm.sectorTypeComboBoxOptions.change = function() {
            var value = this.value();
            var obj = this.dataItem();
            if (obj.isPrivate) {
                $timeout(function() {
                    vm.newSector = true;
                    vm.sector = { sectorType: { id: value} };
                    vm.sector.name = obj.name + " " + vm.customer.lastName + " " + vm.customer.firstName;
                });
            } else {
                $timeout(function() {
                    vm.newSector = false;
                    vm.sector.name = "";
                });
                vm.sectorsGridOptions.dataSource.filter(
                    { field: "sectorType.id", operator: "eq", value: parseInt(value) }
                );
            }
        };

        vm.suggestBricks = function($value) {
            var data = {};
            data.filter ={
                filters: [
                    {
                        "field": "postalCode",
                        "operator": "eq",
                        "value": $value,
                        "ignoreCase": true
                    }
                ]};
            data.page = 1;
            data.pageSize = 1;


            $http.get('/api/masterdata/bricks',
                {params: data})
                .success(function (response) {
                    if (response.content != null
                        && response.content instanceof Array
                        && response.content.length > 0) {
                        vm.sector.brick = response.content[0].territoryNo;
                    }
                })
                .error(function (response) {});


            $http.get('/api/masterdata/brick-osfes',
                {params: data})
                .success(function (response) {
                    if (response.content != null
                        && response.content instanceof Array
                        && response.content.length > 0) {
                        vm.sector.osfeBrick = response.content[0].territoryName;
                    }
                })
                .error(function (response) {});
        };

        var grid = {};
        grid.columns = [
            {field: "name", title: "Όνομα Μονάδας Υγείας",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "sectorType.name", title: "Τύπος Μονάδας Υγείας",
                filterable: false
            },
            {field: "doctorSpecialty.name", title: "Ειδικότητες στη ΜΣ",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "address", title: "Διεύθυνση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "addressNum", title: "Αριθμός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "postalCode", title: "Τ.Κ.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "cityName", title: "Πόλη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "prefectureName", title: "Νομός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "brick", title: "Brick",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "osfeBrick", title: "Brick ΟΣΦΕ",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "phone", title: "Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "sectorParent.name", title: "Ανήκει στη ΜΣ",
                filterable: {
                    cell: {
                        field : "parent.name",
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];
        grid.dataSource = new kendo.data.DataSource({
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    if(o.data.filter != undefined && o.data.filter.filters != undefined){
                        angular.forEach(o.data.filter.filters, function(item) {
                            if (item.field === "sectorParent.name"){
                                item.field = "parent.name"
                            }
                        });
                    }

                    if(o.data.sort != undefined){
                        angular.forEach(o.data.sort, function(item) {
                            if (item.field === "sectorParent.name"){
                                item.field = "parent.name"
                            }
                        });
                    }

                    $http.get('api/customer-sectors/customers/' + vm.selectedUserId +'/sectors/suggest',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {});
                }
            },
            schema: {
                data: function (data) {
                    if(data != undefined) {
                        data.content.forEach(function(item){
                            if(item.name === null) item.name = "";
                            if(item.sectorType === null) item.sectorType = {};
                            if(item.doctorSpecialty === null) item.doctorSpecialty = {};
                            if(item.parent === null) item.parent = {};
                        });
                    }
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        id: { editable: false, nullable: false },
                        sectorParent: { from: "parent", defaultValue : { id : null, name : null} }
                    }
                }
            },
            serverFiltering: true,
            serverSorting: true,
            serverPaging: true,
            pageSize: 20
        });
        vm.sectorsGridOptions = {
            dataSource: grid.dataSource,
            sortable: true,
            pageable: true,
            selectable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: grid.columns,
            change: function() {
                var selectedItem = this.dataItem(this.select());
                $timeout( function() { vm.sector = selectedItem; });
                vm.customerSectorsService.setSector(selectedItem);
            }
        };

        vm.nextView = function() {
            vm.customerSectorsService.setSector(vm.sector);
            vm.customerSectorsService.setCustomerSector(vm.newCustomerSector);
        }
    }
})();
