/**
 * Created by Kristy on 29/8/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PreviewAssignationsController', PreviewAssignationsController);

    PreviewAssignationsController.$inject = ['$filter', '$scope','$http', '$state', '$mdToast', 'Assignations', 'NotificationOptions', 'KendoFilter', 'CustomUtils'];

    function PreviewAssignationsController ($filter, $scope, $http, $state, $mdToast, Assignations, NotificationOptions, KendoFilter, CustomUtils) {
        var vm = this;

        vm.success = vm.error = "";
        vm.allSelected = false;
        vm.employee = Assignations.getEmployee();
        vm.disableSave = !!(!Assignations.getEmployee() || !Assignations.getSelectedCustomerSectors().length);
        vm.copy = Assignations.isCopy();
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");

        vm.assign = function () {
            var customerSectors = Assignations.getSelectedCustomerSectors();
            var employees = Assignations.getEmployees();
            var employeeID = Assignations.getEmployee().id;

            angular.forEach(customerSectors, function(customerSector) {
                angular.forEach(customerSector.employeeCustomerSectors, function(employee) {
                    if(employees && employees.indexOf(employee.id) !== -1) {
                        employee.id = employeeID;
                    }
                })
            });

            var assignations = {
                "copy": vm.copy,
                "employeeToId": employeeID,
                "employeeFromIds": employees,
                "customerSectors": customerSectors
            };

            $mdToast.show({
                hideDelay   : 0,
                position    : 'top right',
                controller  : 'ToastCtrl',
                templateUrl : 'app/components/md-toast/toast-template.html'
            });
            vm.disableSave = true;
            $http.post('/api/customer-sectors/mass', assignations)
                .success(function () {
                    $mdToast
                        .hide()
                        .then(function () {
                        });
                    notification.show({
                        message: "Η μαζική ανάθεση πραγματοποιήθηκε με επιτυχία!"
                    }, "success");
                })
                .error(function (response) {
                    $mdToast
                        .hide()
                        .then(function () {
                        });
                    notification.show({
                        message: "Η ενημέρωση των στοιχείων απέτυχε!"
                    }, "error");
                    console.log(response);
                });
        };

        vm.toolbarPreviewOptions = {
            items: [
                {
                    template: "<md-button class='next-button-toolbar md-raised md-warn' ui-sref='customerSectorsAssignations'>" +
                    "<md-icon class='arrow-left'></md-icon>Προηγούμενο</md-button>"
                },
                { template: "<label>{{ vm.copy ? 'Αντιγραφή' : 'Μεταφορά' }} επιλεγμένου τομέα/πελατολογίου στον ιατρικό επισκέπτη </label>" },
                {
                    template: "<md-input-container class='md-block' flex-gt-sm=''>" +
                                "<md-icon class='assignment-ind'></md-icon><input aria-label='employee' ng-model='vm.employee.employee.account.fullName' ng-readonly='true'>" +
                            "</md-input-container>",
                    overflow: "never"
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-warn' ng-click='vm.assign()' ng-disabled='vm.disableSave'>" +
                    "Τέλος<md-icon class='done'></md-icon></md-button>",
                    attributes: { class: "right" }
                }
            ]
        };

        vm.removeItem = function(id) {
            var gridData = vm.previewGridOptions.dataSource.data();
            angular.forEach(gridData, function (item) {
                if (item.id.toString() === id.toString()){
                    gridData.remove(item);
                }
            });
            Assignations.setSelectedCustomerSectors(gridData);
        };

        vm.toggleSelectAll = function (ev) {
            vm.allSelected = !vm.allSelected;
            var grid = $(ev.target).closest("[kendo-grid]").data("kendoGrid");
            angular.forEach( grid.dataSource.data(), function( dataItem ) {
                dataItem.myTarget = vm.allSelected;
            });
        };

        vm.onMyTargetClick = function (dataItem) {
            dataItem.myTarget = !dataItem.myTarget;
            if (!dataItem.myTarget) {
                vm.allSelected = dataItem.myTarget;
            }
        };

        vm.gridColumns = [
            { field:"id", filterable: false, hidden : true },
            { title: "Επαγγελματίας Υγείας",
                columns: [
                    { field: "customer.lastName", title: "Επώνυμο",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    { field: "customer.firstName", title: "Όνομα",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    { field: "customer.doctorSpecialty.name", title: "Ειδικότητα",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    }
                ]
            },
            { title: "Μονάδα Υγείας",
                columns: [
                    { field: "sector.name", title: "Όνομα",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    { field: "sector.address", title: "Διεύθυνση",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    { field: "sector.cityName", title: "Πόλη",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    { field: "sector.postalCode", title: "Τ.Κ",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    { field: "sector.prefectureName", title: "Νομός",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    }
                ]
            },
            { "template": "<md-checkbox ng-click='vm.onMyTargetClick( dataItem );' aria-label='myTarget' ng-checked='dataItem.myTarget' " +
                            "class='checkbox-toolbar green inline'></md-checkbox>", width: 90,
                title: "<label>My Target</label>" +
                        "<input id='checkboxMyTarget' type='checkbox' class='checkbox' ng-checked='vm.allSelected' " +
                        "ng-click='vm.toggleSelectAll($event)'/>" },
            { "template": "<md-button class='md-icon-button' aria-label='Remove' ng-click='vm.removeItem(#=id#)'>" +
            "<md-icon class='remove'></md-icon></md-button>", width: 90 }
        ];

        vm.previewGridOptions = {
            dataSource: new kendo.data.DataSource({
                data  : Assignations.getSelectedCustomerSectors(),
                schema: {
                    model: {
                        id: "id"
                    }
                },
                pageSize: 200
            }),
            height: CustomUtils.getHeightPixelsFromPercentage(55),
            sortable: true,
            scrollable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            columns: vm.gridColumns,
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.previewGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }]
        };
    }
})();
