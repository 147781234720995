/**
 * Created by Kristy on 7/6/2017.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PatientViewController', PatientViewController);

    PatientViewController.$inject = ['$state', '$stateParams', 'Patient'];

    function PatientViewController ($state, $stateParams, Patient) {
        var vm = this;
        vm.$state = $state;

        Patient.get({id : $stateParams.id})
            .$promise.then(function(data) {
                vm.patient = data;
            });

        vm.goBack = function () {
            $state.go('patients');
        };
    }
})();
