/**
 * Created by Kristy on 25/8/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ApprovalsController', ApprovalsController);

    ApprovalsController.$inject = ['$http','$state', '$stateParams','$filter', '$timeout', 'ApplicationParameters', 'HasAuthorizationByUser'];

    function ApprovalsController ($http, $state, $stateParams, $filter, $timeout, ApplicationParameters, HasAuthorizationByUser) {
        var vm = this;
        ApplicationParameters.hasModule('Approvals', true);
        HasAuthorizationByUser.hasAuthority();

        vm.$state = $state;
    }
})();
