(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('PatchReportItemSearch', PatchReportItemSearch);

    PatchReportItemSearch.$inject = ['$resource'];

    function PatchReportItemSearch($resource) {
        var resourceUrl =  'api/_search/patch-report-items/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
