(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('TradePolicyDetailController', TradePolicyDetailController);

    TradePolicyDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TradePolicy'];

    function TradePolicyDetailController($scope, $rootScope, $stateParams, entity, TradePolicy) {
        var vm = this;
        vm.tradePolicy = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:tradePolicyUpdate', function(event, result) {
            vm.tradePolicy = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
