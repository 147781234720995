(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CostTypeDetailController', CostTypeDetailController);

    CostTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'CostType'];

    function CostTypeDetailController($scope, $rootScope, $stateParams, entity, CostType) {
        var vm = this;
        vm.costType = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:costTypeUpdate', function(event, result) {
            vm.costType = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
