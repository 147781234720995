(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('EventScheduleDeleteController',EventScheduleDeleteController);

    EventScheduleDeleteController.$inject = ['$uibModalInstance', 'entity', 'EventSchedule'];

    function EventScheduleDeleteController($uibModalInstance, entity, EventSchedule) {
        var vm = this;
        vm.eventSchedule = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            EventSchedule.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
