/**
 * Created by Teo on 01/06/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProspectivePatientViewLeadHospitalizationDiseasesController', ProspectivePatientViewLeadHospitalizationDiseasesController);

    ProspectivePatientViewLeadHospitalizationDiseasesController.$inject = [ '$stateParams', '$http', 'CustomUtils'];

    function ProspectivePatientViewLeadHospitalizationDiseasesController ( $stateParams, $http, CustomUtils) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(58);
        var prospectivePatientId = $stateParams.id;
        vm.showProgress = false;

        if (prospectivePatientId !== null && prospectivePatientId > 0){
            var prospectivePatientFilter = {
                field: "leadHospitalization.leadCalls.prospectivePatient.id",
                operator: "eq",
                value: prospectivePatientId
            };
            $http.get('/api/lead-hospitalization-diseases/search-with-dates',
                {params:{
                        page: "1",
                        pageSize: "200",
                        filter : { filters: [prospectivePatientFilter] }}})
                .success(function (response) {
                    if (response != null && response.content != null && response.content.length > 0){
                        vm.leadHospitalizationDiseases = response.content;
                    }
                })
                .error(function (error) {
                    console.log(error);
                });
        }

        /**
         * Convert date to human readable format
         * @param diagnosisDate ZonedDateTime object '2018-05-15T21:00:00.000Z'
         */
        vm.beautifyDate = function (diagnosisDate) {
            if (diagnosisDate !== null && diagnosisDate !== undefined && diagnosisDate !== ''){
                //return CustomDateUtils.fromServerDateFormat(new Date(diagnosisDate));
                return moment(new Date(diagnosisDate)).format('DD/MM/YYYY h:mm')
            }
            return '';
        }
    }
})();
