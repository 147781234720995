(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('RoomTypeDetailController', RoomTypeDetailController);

    RoomTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'RoomType'];

    function RoomTypeDetailController($scope, $rootScope, $stateParams, entity, RoomType) {
        var vm = this;
        vm.roomType = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:roomTypeUpdate', function(event, result) {
            vm.roomType = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
