(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('EventCategoryTypeDialogController', EventCategoryTypeDialogController);

    EventCategoryTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'EventCategoryType', 'EventCategory', 'EventType'];

    function EventCategoryTypeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, EventCategoryType, EventCategory, EventType) {
        var vm = this;
        vm.eventCategoryType = entity;
        vm.eventcategories = EventCategory.query();
        vm.eventtypes = EventType.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:eventCategoryTypeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.eventCategoryType.id !== null) {
                EventCategoryType.update(vm.eventCategoryType, onSaveSuccess, onSaveError);
            } else {
                EventCategoryType.save(vm.eventCategoryType, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
