(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchaseDialogController', PurchaseDialogController);

    PurchaseDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Purchase', 'Employee', 'PurchaseStatus', 'Sector', 'Customer', 'PaymentMethod', 'TaxStatus', 'PurchaseNumberingRow', 'TradePolicy'];

    function PurchaseDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Purchase, Employee, PurchaseStatus, Sector, Customer, PaymentMethod, TaxStatus, PurchaseNumberingRow, TradePolicy) {
        var vm = this;
        vm.purchase = entity;
        vm.employees = Employee.query();
        vm.purchasestatuses = PurchaseStatus.query();
        vm.sectors = Sector.query();
        vm.customers = Customer.query();
        vm.paymentmethods = PaymentMethod.query();
        vm.taxstatuses = TaxStatus.query();
        vm.purchasenumberingrows = PurchaseNumberingRow.query();
        vm.tradepolicies = TradePolicy.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:purchaseUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.purchase.id !== null) {
                Purchase.update(vm.purchase, onSaveSuccess, onSaveError);
            } else {
                Purchase.save(vm.purchase, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.issueDate = false;
        vm.datePickerOpenStatus.desirableExecutionDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
