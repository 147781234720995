/**
 * Created by Teo on 23/08/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MDCommunicationStatusesController', MDCommunicationStatusesController);

    MDCommunicationStatusesController.$inject = ['$scope', 'KendoGrid', '$http', '$filter', 'KendoFilter', 'KendoService', 'CommunicationStatus'];

    function MDCommunicationStatusesController ($scope, KendoGrid, $http, $filter, KendoFilter, KendoService, CommunicationStatus) {
        var vm = this;
        var dataSource = {};
        vm.pageHeading = $filter('translate')('masterData.communicationStatuses.title');

        var onSaveSuccess = function () {
            KendoGrid.refresh(vm.mainGridOptions);
        };

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) o.data.filter = { filters: [] };

                        $http.get('/api/masterdata/communication-statuses',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    },
                    create: function(o) {
                        var data = o.data.models[0];
                        CommunicationStatus.update(data, onSaveSuccess);
                    },
                    update: function(o) {
                        var data = o.data.models[0];
                        CommunicationStatus.update(data, onSaveSuccess);
                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            name: { validation: { required: true , validation: { required: true, min: 3}}}
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            toolbar: [{name :"create", text: "Προσθήκη Νέας εγγραφής"},
                { template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }],
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "name", title: "Όνομα",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    command:[
                        {name:"edit", text: "Επεξεργασία"}
                    ],
                    text: "Επεξεργασία",
                    title: "&nbsp;"
                }
            ],
            edit: function() { KendoGrid.defaultEditPopup(this._editContainer); },
            editable: "popup"
        };

    }
})();
