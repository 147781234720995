(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('EventScheduleSearch', EventScheduleSearch);

    EventScheduleSearch.$inject = ['$resource'];

    function EventScheduleSearch($resource) {
        var resourceUrl =  'api/_search/event-schedules/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
