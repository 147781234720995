(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadOfferDetailController', LeadOfferDetailController);

    LeadOfferDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'LeadOffer', 'CostType', 'RoomType', 'Transportation', 'Competitor', 'LeadDiscount', 'LeadCall', 'OfferStatus'];

    function LeadOfferDetailController($scope, $rootScope, $stateParams, entity, LeadOffer, CostType, RoomType, Transportation, Competitor, LeadDiscount, LeadCall, OfferStatus) {
        var vm = this;
        vm.leadOffer = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:leadOfferUpdate', function(event, result) {
            vm.leadOffer = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
