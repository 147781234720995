/**
 * Created by Kristy on 2/8/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SectorClinicNamesController', SectorClinicNamesController);

    SectorClinicNamesController.$inject = ['$http', '$stateParams','$filter', 'KendoGrid', 'KendoFilter', 'CustomUtils', '$mdToast'];

    function SectorClinicNamesController ($http, $stateParams, $filter, KendoGrid, KendoFilter, CustomUtils, $mdToast) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(58);
        vm.selectedSectorId = $stateParams.id;
        vm.selectedClinicNames = vm.selectedSectorClinicNames = [];
        vm.massSelect = true;

        vm.deleteSectorClinicNames = function() {
            $http.put('api/sector-clinic-names/delete', vm.selectedSectorClinicNames)
                .success(function () { KendoGrid.refresh(vm.sectorClinicNamesGridOptions); })
                .error(function (response) {});
        };

        var grid = {};
        grid.dataSource = new kendo.data.DataSource({
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            {"field": "sector.id", "operator": "eq", "value": vm.selectedSectorId}
                        ]
                    );

                    $http.get('/api/sector-clinic-names/search',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {
                            o.error(response);
                            $mdToast.show(
                                $mdToast.simple()
                                    .textContent('Κάποιο σφάλμα προέκυψε')
                                    .position("bottom right")
                                    .hideDelay(3000));
                        });
                },
                create: function(o) {
                    $http.post('api/clinic-names/sector/'+ vm.selectedSectorId, vm.selectedClinicNames)
                        .success(function () { KendoGrid.refresh(vm.sectorClinicNamesGridOptions); })
                        .error(function (response) {});
                }
            },
            schema: {
                data: function (data) {
                    if(data != undefined) {
                        data.content.forEach(function (item) {
                            if (item.clinicName === null) item.clinicName = {};
                        })
                    }
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        id: { editable: false, nullable: true },
                        clinicName: { defaultValue: {} }
                    }
                }
            },
            serverFiltering: true,
            serverSorting: true,
            serverPaging: true,
            pageSize: 20
        });

        grid.columns = [
            {
                field: "clinicName.name", title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];

        vm.sectorClinicNamesGridOptions = {
            dataSource: grid.dataSource,
            toolbar: [{name :"create", text: "Προσθήκη Κλινικής"},
                { template: "<div class='right'><label>Σύνολο: {{vm.sectorClinicNamesGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" },
                { template: "<div class='k-button k-button-icontext' ng-click='vm.deleteSectorClinicNames()'>" +
                            "<span class='material-icons md-light md-26 pull-left'>delete_forever</span>Διαγραφή Κλινικής από Μονάδα Υγείας</div>",
                    overflow: "never" }],
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            selectable: true,
            columns: grid.columns,
            edit: function(e) {
                vm.initPopupGrid = true;
                KendoGrid.refresh(vm.clinicNamesGridOptions);
                vm.selectedClinicNames = [];
                KendoGrid.defaultCustomEditPopup(e.container, 1300, 1290);
            },
            editable: {
                mode: "popup",
                template: kendo.template(angular.element("#popup_editor").html())
            },
            selectedItems: function(dataItems) {
                vm.selectedSectorClinicNames = dataItems;
            },
            dataBound: function () {
                angular.element("#mainGridcheckbox").prop('checked', false);
                //toggle class on click
                angular.element(".checkbox").bind("change", function (e) {
                    angular.element(e.target).closest("tr").toggleClass("k-state-selected");
                });

                var view = this.dataSource.data();
                var me = this;

                var selectedSectorClinicNamesViewCounter = 0;
                angular.forEach( view, function(item) {
                    var foundItemById = $filter('filter')(vm.selectedSectorClinicNames, {id: item.id}, true)[0];
                    if(vm.selectedSectorClinicNames.indexOf(foundItemById) !== -1){
                        me.tbody.find("tr[data-uid='" + item.uid + "']")
                            .addClass("k-state-selected");
                        item.selected = true;
                        selectedSectorClinicNamesViewCounter++
                    }
                });
                if(selectedSectorClinicNamesViewCounter === view.length) {
                    angular.element("#mainGridcheckbox").prop('checked', true);
                }
            },
            cancel: function() {
                delete vm.initPopupGrid;
            },
            save: function() {
                delete vm.initPopupGrid;
            },
            noRecords: {
                template: "Δεν βρέθηκαν εγγραφές"
            }
        };

        grid.clinicNamesDataSource = new kendo.data.DataSource({
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }
                    $http.get('/api/clinic-names/sector/' + vm.selectedSectorId + '/suggest',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {});
                }
            },
            schema: {
                data: function (data) {
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id"
                }
            },
            serverFiltering: true,
            serverSorting: true,
            serverPaging: true,
            pageSize: 20
        });

        grid.clinicNamesColumns = [
            {
                field: "name", title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];

        vm.clinicNamesGridOptions = {
            dataSource: grid.clinicNamesDataSource,
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            selectable: true,
            columns: grid.clinicNamesColumns,
            selectedItems: function(dataItems) {
                vm.selectedClinicNames = dataItems;
            },
            dataBound: function () {
                angular.element("#popupGridcheckbox").prop('checked', false);
                //toggle class on click
                angular.element(".checkbox").bind("change", function (e) {
                    angular.element(e.target).closest("tr").toggleClass("k-state-selected");
                });

                var view = this.dataSource.data();
                var me = this;

                var selectedClinicNamesViewCounter = 0;
                angular.forEach( view, function(item) {
                    var foundItemById = $filter('filter')(vm.selectedClinicNames, {id: item.id}, true)[0];
                    if(vm.selectedClinicNames.indexOf(foundItemById) !== -1){
                        me.tbody.find("tr[data-uid='" + item.uid + "']")
                            .addClass("k-state-selected");
                        item.selected = true;
                        selectedClinicNamesViewCounter++
                    }
                });
                if(selectedClinicNamesViewCounter === view.length) {
                    angular.element("#popupGridcheckbox").prop('checked', true);
                }
            }
        };
    }
})();
