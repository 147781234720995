/**
 * Created by Kristy on 22/3/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('HospitalizationsAllController', HospitalizationsAllController);

    HospitalizationsAllController.$inject = ['$scope', '$filter','$http', '$timeout', 'KendoFilter', 'favouriteFilters','KendoToolbar', 'FavouriteFiltersService',
        'KendoGrid', 'MasterToKendoFiltersService', 'CustomDateUtils', 'CustomDataUtils', 'employee'];
    function HospitalizationsAllController ($scope, $filter, $http, $timeout, KendoFilter, favouriteFilters, KendoToolbar, FavouriteFiltersService, KendoGrid,
                                            MasterToKendoFiltersService, CustomDateUtils, CustomDataUtils, employee) {
        var vm = this;
        vm.pageHeading = $filter('translate')('sigmasfApp.hospitalization.hospitalizationsAll.title');
        vm.url = '/api/hospitalizations/analytics/comparison';
        vm.favouriteFilters = favouriteFilters.data.content;
        vm.employee = employee;
        vm.masterFilters = {};

        vm.disabled = true;
        vm.isEdited = vm.isRenameMode = false;
        vm.exportOnlyTotals = true;
        vm.selectedFilter = {};
        vm.mainGrid = {};
        vm.masterOptions = [];
        vm.filter = {};
        vm.view = 'hospitalizations';


        var setView = function() {
            var filterable = vm.masterFilters.from && vm.masterFilters.to ? {
                mode: "row"
            } : false;

            var sortable = vm.masterFilters.from && vm.masterFilters.to ? {
                mode: "multiple",
                allowUnsort: true,
                showIndexes: true
            } : false;

            vm.disabled = !vm.masterFilters.from || !vm.masterFilters.to;
            vm.mainGrid.setOptions({
                sortable: sortable,
                groupable: vm.masterFilters.from && vm.masterFilters.to,
                filterable: filterable
            });
        };
        $scope.$watchGroup(["vm.masterFilters.from", "vm.masterFilters.to"], function () { setView(); });

        vm.masterFiltersEdited = function(){
            var gridOptions = FavouriteFiltersService.getKendoOptions(vm.mainGrid);
            $timeout(function(){
                vm.isEdited = !!(vm.selectedFilter.kendoOptions && vm.masterOptions.length &&
                    (!angular.equals(vm.masterOptions, MasterToKendoFiltersService.getMasterFilters([], vm.masterFilters, vm.view)) || !angular.equals(JSON.parse(vm.selectedFilter.kendoOptions), gridOptions)) );
            });
        };
        $scope.$watch("vm.masterFilters", vm.masterFiltersEdited, true);

        vm.collapseAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-collapse").trigger("click"); };
        vm.expandAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-expand").trigger("click"); };

        //Date Filter
        vm.masterFiltersOnClick = function(e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');
            if (e.id === "today") {
                KendoToolbar.clearDatePickers();
                $timeout(function () {
                    vm.masterFilters.from = date;
                    vm.masterFilters.to = date;
                });
            }
            else if (e.id === "week") {
                KendoToolbar.clearDatePickers();
                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6
                $timeout(function () {
                    vm.masterFilters.from = firstWeekDay;
                    vm.masterFilters.to = lastWeekDay;
                });
            }
            else if (e.id === "month") {
                KendoToolbar.clearDatePickers();
                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                $timeout(function () {
                    vm.masterFilters.from = firstMonthDay;
                    vm.masterFilters.to = lastMonthDay;
                });
            }
            else if (e.id === "year") {
                KendoToolbar.clearDatePickers();
                $timeout(function () {
                    vm.masterFilters.from = new Date(date.getFullYear(), 0, 1);
                    vm.masterFilters.to = new Date();
                });
            }
        };

        vm.toolbarOptions = {
            items: KendoToolbar.getOptions(["buttonGroup", "year", "masterFilterFrom", "masterFilterTo", "search"], vm)
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.masterFilters.from = value;
                } else {
                    vm.masterFilters.from = null;
                }
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.masterFilters.to = value;
                } else {
                    vm.masterFilters.to = null;
                }
            }
        };

        var filterTemplate = function (args) {
            KendoFilter.getTemplate(args);
        };

        var grid = {};
        grid.columns = [
            {
                field: "patient.lastName",
                title: "Επώνυμο Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: filterTemplate,
                        showOperators: false
                    }
                },
                aggregates: ["count"],
                groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            },
            {
                field: "patient.firstName",
                title: "Όνομα Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: filterTemplate,
                        showOperators: false
                    }
                },
                aggregates: ["count"],
                groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            },
            /*{
                field: "customer.lastName",
                title: "Επώνυμο Επαγγελματία Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: filterTemplate,
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.firstName",
                title: "Όνομα Επαγγελματία Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: filterTemplate,
                        showOperators: false
                    }
                }
            },*/
            {
                field: "sector.name",
                title: "Όνομα Μονάδας Υγείας",
                filterable: {
                    cell: {
                        operator: "contains",
                        template: filterTemplate,
                        showOperators: false
                    }
                },
                aggregates: ["count"],
                groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            },
            {
                field: "sector.sectorType.name",
                title: "Τύπος Μονάδας Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: filterTemplate,
                        showOperators: false
                    }
                },
                aggregates: ["count"],
                groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            },
            {
                field: "customer.customerTitle.name",
                title: "Τύπος Επαγγελματία Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: filterTemplate,
                        showOperators: false
                    }
                },
                aggregates: ["count"],
                groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            },
            {
                field: "clinicName.name",
                title: "Κλινική",
                filterable: {
                    cell: {
                        operator: "contains",
                        template: filterTemplate,
                        showOperators: false
                    }
                },
                aggregates: ["count"],
                groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            },
            {
                field: "roomType.name",
                title: "Επιθυμητός Τύπος Δωματίου",
                filterable: {
                    cell: {
                        operator: "contains",
                        template: filterTemplate,
                        showOperators: false
                    }
                },
                aggregates: ["count"],
                groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            },
            {
                field: "finalRoomType.name",
                title: "Τελική Τοποθέτηση",
                filterable: {
                    cell: {
                        operator: "contains",
                        template: filterTemplate,
                        showOperators: false
                    }
                },
                aggregates: ["count"],
                groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            },
            {
                field: "hospitalizationType.name",
                title: "Παρατηρήσεις",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: filterTemplate,
                        showOperators: false
                    }
                },
                aggregates: ["count"],
                groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            },
            /*{
                field: "notes",
                title: "Σχόλια",
                filterable: {
                    cell: {
                        operator: "contains",
                        template: filterTemplate,
                        showOperators: false
                    }
                }
            },*/
            {field: "dateIssue", title: "Ημερομηνία Εισαγωγής", template: "#= dateIssue ? kendo.toString(kendo.parseDate(dateIssue), 'dddd, dd/MM/yyyy') : ''#",
                filterable: {
                    cell: {
                        operator: "eq",
                        showOperators: false
                    }
                },
                aggregates: ["count"],
                groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            },
            {
                field: "month", title: "Μήνας",
                filterable: false,
                aggregates: ["count"],
                groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            },
            {
                field: "customerFullName", title: "Επαγγελματίας Υγείας",
                filterable: false,
                aggregates: ["count"],
                groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            },
            {
                field: "customer.doctorSpecialty.name", title: "Ειδικότητα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: filterTemplate,
                        showOperators: false
                    }
                },
                aggregates: ["count"],
                groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            }
        ];
        grid.dataSource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function (o) {
                    if (o.data.filter === undefined) { o.data.filter = { filters: [] }; }
                    o.data.filter.filters = MasterToKendoFiltersService.getMasterFilters(o.data.filter.filters, vm.masterFilters, vm.view);

                    angular.forEach( o.data.filter.filters, function(item) {
                        if ( item.value instanceof Date) {
                            item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                        }
                    });

                    $http.get('/api/hospitalizations/all', {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {});
                }
            },
            schema: {
                data: function (data) {
                    data.forEach(function(item){
                        if (!item.sector) item.sector = {};
                        if (!item.sector.sectorType) item.sector.sectorType = {};
                        if (!item.clinicName) item.clinicName = {};
                        if (!item.roomType) item.roomType = {};
                        if (!item.finalRoomType) item.finalRoomType = {};
                        if (!item.hospitalizationType) item.hospitalizationType = {};
                        if (!item.patient) item.patient = {};
                        if (!item.customer) item.customer = { customerTitle: {} };
                        if (!item.customer.customerTitle) item.customer.customerTitle = {};
                        if (!item.customer.doctorSpecialty) item.customer.doctorSpecialty = {};
                    });
                    return data;
                },
                model: {
                    id: "id",
                    fields: {
                        id: {editable: false, nullable: false},
                        dateIssue: { type: "date" }
                    }
                }
            }
        });
        vm.mainGridOptions = {
            autoBind: false,
            dataSource: grid.dataSource,
            sortable: false,
            groupable: false,
            resizable: true,
            filterable: false,
            columns: grid.columns,
            toolbar: [
                { template: "<md-button class='md-raised md-mini md-primary' aria-label='collapseAll' ng-click='vm.collapseAll()'>Collapse All</md-button>" },
                { template: "<md-button class='md-raised md-mini md-primary' aria-label='expandAll' ng-click='vm.expandAll()'>Expand All</md-button>" },
                {
                    name: "onlyTotals", template: "<div style='float: right; margin-right: 10px;'><md-switch class='md-primary' style='top: 0' ng-model='vm.exportOnlyTotals'><label>Εξαγωγή μόνο σύνολα</label></md-switch></div>"
                },
                { name: "excel", template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                    "<span class='k-icon k-i-excel'></span>Export</md-button></div>" }
            ],
            excel: {
                allPages: true,
                fileName: "Ευρετήριο Εισαγωγών.xlsx"
            },
            dataBound: function (e) {
                vm.masterFiltersEdited();
                var data = e.sender.dataSource.data();
                if (data !== null && data !== undefined){
                    angular.forEach(data, function (item) {
                        if (item.dateIssue){
                            var monthIndex = item.dateIssue.getMonth() + 1;
                            item.month = (monthIndex > 9 ? monthIndex : '0' + monthIndex) + '. ' + CustomDateUtils.numberToMonth(item.dateIssue.getMonth());
                        }else {
                            item.month = '';
                        }

                        if (item.customer){
                            item.customerFullName = item.customer.lastName + " " + item.customer.firstName;
                        }else {
                            item.customerFullName = "";
                        }
                    });
                }
            },
            excelExport: function (e) {
                if (vm.exportOnlyTotals){
                    var result = CustomDataUtils.arrayRemove(e.workbook.sheets[0].rows, 'data'); // remove data
                    result[0].cells = []; // remove columns from header
                    for (var i=0; i<result.length; i++){
                        if (result[i].type === 'group-header'){
                            var val = result[i].cells[result[i].cells.length - 1].value;
                            var _val = angular.copy(val);

                            // push a new cell only with the value
                            result[i].cells.push({
                                value: CustomDataUtils.getValueFromHeaderLabel(String(val)),
                                background: "#dfdfdf",
                                color: "#333",
                                colSpan: 1
                            });

                            // Keep only month in header labels
                            result[i].cells[result[i].cells.length - 2].value = CustomDataUtils.removeTotalsFromHeaderLabel(String(_val));
                        }
                    }
                    e.workbook.sheets[0].rows = angular.copy(result);
                }
            }
        };
    }
})();
