/**
 * Created by Teo on 02/07/2018.
 */

(function() {
    'use strict';
    var isDlgOpen;

    angular
        .module('sigmasfApp')
        .controller('MdToastCtrl', MdToastCtrl);

    MdToastCtrl.$inject = ['$scope', '$mdToast', '$state'];

    function MdToastCtrl ($scope, $mdToast, $state) {
        $scope.closeToast = function() {
            if (isDlgOpen) return;

            $mdToast
                .hide()
                .then(function() {
                    isDlgOpen = false;
                });
        };

        $scope.showFollowUps = function(){
            $mdToast
                .hide()
                .then(function() {
                    isDlgOpen = false;
                    $state.go("followUpCalls");
                });
        };
    }
})();
