/**
 * Created by Kristy on 2/9/2016.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminCustomerViewEventsController', AdminCustomerViewEventsController);

    AdminCustomerViewEventsController.$inject = ['$http','$state', '$stateParams', '$filter', 'Principal', 'KendoFilter'];

    function AdminCustomerViewEventsController ($http, $state, $stateParams, $filter, Principal, KendoFilter) {
        var vm = this;
        vm.selectedUserId = $stateParams.id;

        vm.googleMapsRedirect = function(dataItem) {
            if (dataItem.latitude != 0 && dataItem.longitude != 0) {
                window.open(
                    'http://maps.google.com/maps?q=' + dataItem.latitude + ',' + dataItem.longitude,
                    '_blank' //This is what makes it open in a new window.
                );
            }
        };

        vm.imageChoice = function(dataItem) {
            return !(dataItem.latitude == 0 && dataItem.longitude == 0);
        };

        vm.clearDatePickers = function () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        };

        vm.masterFiltersOnClick = function (e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id == "today") {

                vm.clearDatePickers();

                vm.from = date;
                vm.to = date;

            }
            else if (e.id == "week") {

                vm.clearDatePickers();

                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6

                vm.from = firstWeekDay;
                vm.to = lastWeekDay;

            }
            else if (e.id == "month") {

                vm.clearDatePickers();

                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                vm.from = firstMonthDay;
                vm.to = lastMonthDay;
            }

            if ( e.target != undefined) {
                if (e.target[0].offsetParent.id == 'masterFiltersEvents') {
                    vm.eventsFrom = vm.from;
                    vm.eventsTo = vm.to;
                    vm.eventsGridOptions.dataSource.read();
                    //angular.element("#eventsGrid").data("kendoGrid").dataSource.read();
                }
            } else {
                if ( vm.selectedTabStrip == 2) {
                    vm.eventsFrom = vm.from;
                    vm.eventsTo = vm.to;
                    vm.eventsGridOptions.dataSource.read();
                    angular.element("#eventsGrid").data("kendoGrid").dataSource.read();
                }
            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup", id:"buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ]
                },
                { type: "separator" },
                { type: "separator" },
                { template: "<label>Από: </label>" },
                {
                    template: "<input kendo-date-picker id='from' options='vm.fromOptions'/>",
                    overflow: "never"
                },
                { template: "<label>Εώς: </label>" },
                {
                    template: "<input kendo-date-picker id='to' options='vm.toOptions'/>",
                    overflow: "never"
                }
            ]
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                vm.selectedTabStrip = angular.element("#tabstrip2").kendoTabStrip().data("kendoTabStrip").select().index();
                var value = this.value();
                if (value) {
                    if (vm.selectedTabStrip == 2) {
                        vm.eventsFrom = value;
                    }
                }
                vm.from = value;

                vm.masterFiltersOnClick(e);
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                vm.selectedTabStrip = angular.element("#tabstrip2").kendoTabStrip().data("kendoTabStrip").select().index();
                var value = this.value();
                if (value) {
                    if ( vm.selectedTabStrip == 2) {
                        vm.eventsTo = value;
                    }
                }
                vm.to = value;

                vm.masterFiltersOnClick(e);
            }
        };

        vm.eventsGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "eventCustomerSector.customerSector.customer.id", "operator": "eq",  "value": vm.selectedUserId}
                            ]
                        );

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        //master filters functionality
                        if(vm.eventsFrom && vm.eventsTo) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "startedAt", "operator": "from",  "value": $filter('date')(vm.eventsFrom, 'yyyy-MM-ddT00:00:00.000') + 'Z' },
                                    { "field": "startedAt", "operator": "to",  "value": $filter('date')(vm.eventsTo, 'yyyy-MM-ddT23:59:59.000') + 'Z' }
                                ]
                            );
                        }

                        $http.get('/api/me/events',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (data, operation) {
                        return data;
                    }
                },
                schema: {
                    data: function (data) {
                        if(data != undefined) {
                            data.content.forEach(function(item){

                                if (item.eventStatus === null) {
                                    item.eventStatus = {};
                                    item.eventStatus.name = "";
                                }

                                if(item.employee === null){
                                    item.employee = {
                                        account : {
                                            lastName : ""
                                        }
                                    }
                                }

                                if (item.eventCustomerSector === null) {
                                    if (item.agreementValuation === null) {
                                        item.agreementValuation = {
                                            name: ""
                                        };
                                    }

                                    if (item.customerSector === null) {
                                        item.customerSector = {
                                            sector: {
                                                address: "",
                                                city: {
                                                    name: ""
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    if (item.eventCustomerSector.agreementValuation === null) {
                                        item.eventCustomerSector.agreementValuation = {
                                            name: ""
                                        };
                                    }

                                    if (item.eventCustomerSector.customerSector == null) {
                                        item.eventCustomerSector.customerSector = {
                                            sector: {
                                                sectorType: {
                                                    name: ""
                                                }
                                            }
                                        };
                                    } else {
                                        if (item.eventCustomerSector.customerSector.sector == null) {
                                            item.eventCustomerSector.customerSector.sector = {
                                                name: "",
                                                sectorType: {
                                                    name: ""
                                                }
                                            };
                                        } else {
                                            if (item.eventCustomerSector.customerSector.sector.sectorType == null) {
                                                item.eventCustomerSector.customerSector.sector.sectorType = {
                                                    name: ""
                                                };
                                            }
                                        }
                                    }
                                }

                            });
                        }
                        return data.content;
                    },
                    total : function(data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            name: { validation: { required: true , validation: { required: true, min: 3}} },
                            startedAt: { type: 'date'}
                        }
                    }
                },
                serverSorting: true,
                serverFiltering: true,
                serverPaging: true,
                pageSize: 50
            }),
            pageable: true,
            sortable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: "cell",
            columns: [
                {field: "startedAt", title: "Ημερομηνία Επίσκεψης", template: "#= kendo.toString(kendo.parseDate(startedAt), 'dddd, dd/MM/yyyy HH:mm')#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    }, width: 220 },
                {field: "eventStatus.name", title: "Κατάσταση Επίσκεψης",attributes: {
                    class: "#=eventStatus.id == '1' ? 'red' : 'green' #"
                },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }, width: 190},
                {field: "eventCustomerSector.agreementValuation.name", title: "Αξιολόγηση", template: "#= (eventCustomerSector.agreementValuation == null || eventCustomerSector.agreementValuation.name == null ) ? ' ' : eventCustomerSector.agreementValuation.name #",
                    attributes: {   class: "#=eventCustomerSector.agreementValuation.id == '1' ? 'red' : eventCustomerSector.agreementValuation.id == '2' ? 'orange' : eventCustomerSector.agreementValuation.id == '3' ? 'purple' : eventCustomerSector.agreementValuation.id == '4' ? 'green' : 'black' #"
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }, width: 150},
                {
                    command:[
                        {name:"map2", template: "<md-button class='md-fab md-mini md-primary' aria-label='Google Maps' " +
                        "ng-class='{googlemap: vm.imageChoice(dataItem.eventCustomerSector), errormap: !vm.imageChoice(dataItem.eventCustomerSector)}' ng-click='vm.googleMapsRedirect(dataItem.eventCustomerSector)'>" +
                        "</md-button>"
                        }
                    ],
                    attributes: { class: "#= 'link-cell-color' #" },
                    title: "Τοποθεσία",
                    width: 85
                },
                {
                    title: "Μονάδα Υγείας",
                    columns: [
                        {field: "eventCustomerSector.customerSector.sector.sectorType.name", title: "Τύπος",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }, width: 150
                        },
                        { field: "eventCustomerSector.customerSector.sector.name", title: "Όνομα",
                            filterable: {
                                cell: {
                                    operator: "like",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.customerSector.sector.address", title: "Διεύθυνση",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.customerSector.sector.cityName", title: "Πόλη",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }, width: 170
                        }
                    ]
                },
                // {field: "eventCustomerSector.agreedPercent", title: "Ποσοστό Συχνότητας", template: "#= (eventCustomerSector.agreedPercent == null) ? ' ' : kendo.format('{0:p0}', eventCustomerSector.agreedPercent / 100)#",
                //     filterable: {
                //         cell: {
                //             operator: "startswith",
                //             template: function (args) {
                //                 KendoFilter.getTemplate(args);
                //             },
                //             showOperators: false
                //         }
                //     }},
                {field: "employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",
                    attributes: {
                        class: "link-cell link-cell-color"
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }, width: 200
                }
                // {field: "description", title: "Σημειώσεις",
                //     filterable: {
                //         cell: {
                //             operator: "like",
                //             template: function (args) {
                //                 KendoFilter.getTemplate(args);
                //             },
                //             showOperators: false
                //         }
                //     }}
            ],
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.eventsGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }],
            change: onChange
        };

        function onChange() {
            // Get selected cell
            var selected = this.select();
            // Get the row that the cell belongs to.
            var row = this.select().closest("tr");
            var cellIndex = selected[0].cellIndex;

            if(cellIndex === 8) {
                $state.go('userInfo', {id: this.dataItem(row).employee.id});
            }
        }
    }

})();
