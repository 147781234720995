/**
 * Created by Kristy on 7/5/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('competitors', {
                parent: 'masterData',
                url: '/competitors',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_REC_ADMINISTRATOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/masterData/competitors/competitors.html',
                        controller: 'MDCompetitorsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('masterData');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
