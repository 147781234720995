/**
 * Created by Kristy on 10/12/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('PatchReportPhoto', PatchReportPhoto);

    PatchReportPhoto.$inject = ['$http'];

    function PatchReportPhoto($http) {

        return {
            validationCheck: validationCheck
        };

        function validationCheck( gridData, patchPhotoType ) {
            var counter = 0;
            angular.forEach( gridData, function(item) {
                if( item.patchPhotoType == patchPhotoType) {
                    counter++
                }
            });

            switch (patchPhotoType) {
                case 'injury' :
                    return counter <= 3;
                    break;
                default :
                    return counter <= 4;
                    break;
            }
        }
    }
})();
