/**
 * Created by Kristy on 10/5/2018.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProspectivePatientViewController', ProspectivePatientViewController);

    ProspectivePatientViewController.$inject = ['entity', '$state'];

    function ProspectivePatientViewController (entity, $state) {
        var vm = this;
        vm.$state = $state;
        vm.prospectivePatient = entity;

        vm.goBack = function () {
            $state.go('prospectivePatients');
        };
    }

})();
