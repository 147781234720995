(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SectorDetailController', SectorDetailController);

    SectorDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Sector', 'SectorType', 'DoctorSpecialty', 'CustomerSector', 'Country', 'Region', 'Prefecture', 'City', 'CompanyType', 'TaxOffice', 'TaxStatus', 'ClinicName', 'SectorCategory'];

    function SectorDetailController($scope, $rootScope, $stateParams, entity, Sector, SectorType, DoctorSpecialty, CustomerSector, Country, Region, Prefecture, City, CompanyType, TaxOffice, TaxStatus, ClinicName, SectorCategory) {
        var vm = this;
        vm.sector = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:sectorUpdate', function(event, result) {
            vm.sector = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
