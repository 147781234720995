(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('DateUtils', DateUtils);

    DateUtils.$inject = ['$filter'];

    function DateUtils ($filter) {

        var service = {
            convertDateTimeFromServer : convertDateTimeFromServer,
            convertLocalDateFromServer : convertLocalDateFromServer,
            convertDateFromServer: convertDateFromServer,
            convertTimeFromServer: convertTimeFromServer,
            convertLocalDateToServer : convertLocalDateToServer,
            convertTimeToServer: convertTimeToServer,
            dateformat : dateformat
        };

        return service;

        function convertDateTimeFromServer (date) {
            if (date) {
                return new Date(date);
            } else {
                return null;
            }
        }

        function convertLocalDateFromServer (date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertDateFromServer (date) {
            if (date) {
                return new Date(date);
            }else {
                return null;
            }
        }

        function convertTimeFromServer (time) {
            if (time) {
                var dateWithTime = new Date();
                var parts = time.split(':');
                dateWithTime.setHours(+parts[0]);
                dateWithTime.setMinutes(parts[1]);
                dateWithTime.setSeconds(parts[2]);
                return dateWithTime;
            }else {
                return null;
            }
        }

        function convertLocalDateToServer (date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        function convertTimeToServer(time) {
            if (time) {
                return new Date(time).getTime();
            }else {
                return null;
            }
        }

        function dateformat () {
            return 'yyyy-MM-dd';
        }
    }

})();
