(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PatchReportItemDialogController', PatchReportItemDialogController);

    PatchReportItemDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PatchReportItem', 'PatchReport', 'PatchItem', 'Icd10'];

    function PatchReportItemDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PatchReportItem, PatchReport, PatchItem, Icd10) {
        var vm = this;
        vm.patchReportItem = entity;
        vm.patchreports = PatchReport.query();
        vm.patchitems = PatchItem.query();
        vm.icd10s = Icd10.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:patchReportItemUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.patchReportItem.id !== null) {
                PatchReportItem.update(vm.patchReportItem, onSaveSuccess, onSaveError);
            } else {
                PatchReportItem.save(vm.patchReportItem, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
