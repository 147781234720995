/**
 * Created by Kristy on 28/7/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('KendoValidator', KendoValidator);

    KendoValidator.$inject = [];

    function KendoValidator () {

        return {
            getCustomValidatorOptions : getCustomValidatorOptions
        };

        function getCustomValidatorOptions() {
            return {
                rules: {
                    custom: function (input) {

                        if (input.data('custom')) {
                            if (input.val() !== null && input.val() !== "" && angular.element(input.data('custom')).data("kendoComboBox").selectedIndex === -1) {
                                return false;
                            }
                        }
                        return true;
                    }
                },
                messages: {
                    custom: "Πρέπει να επιλέξετε από την λίστα",
                    maxlength: "Το κινητό τηλέφωνο θα πρέπει να περιέχει 10 χαρακτήρες."
                }
            }
        }
    }

})();
