/**
 * Created by Maria on 13/5/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('adminCustomersAllController', adminCustomersAllController);

    adminCustomersAllController.$inject = ['$scope', 'employee', 'customersView', 'favouriteFilters', '$filter','$http', 'SupervisorsService', 'MasterFiltersService', 'KendoFilter', '$timeout', 'FavouriteFiltersService', 'MasterToKendoFiltersService'];

    function adminCustomersAllController ($scope, employee, customersView, favouriteFilters, $filter, $http, SupervisorsService, MasterFiltersService, KendoFilter, $timeout, FavouriteFiltersService, MasterToKendoFiltersService) {
        var vm = this;
        vm.favouriteFilters = favouriteFilters.data.content;
        vm.employee = employee;
        SupervisorsService.setSelectedSupervisors([]);
        vm.masterFilters = { selectedSupervisors: SupervisorsService.getSelectedSupervisors(), selectedCustomers: [], selectedSpecialties: [], saw: true,
            isActive: true, selectedSoftwares: [], lastVisitDate: { operator: 'eqdate' } };
        vm.pageHeading = 'Ευρετήριο Γενικών Στοιχείων Επαγγελματία Υγείας';
        vm.customersURL = '/api/customers/all';
        vm.disabled = true;
        vm.isEdited = vm.isRenameMode = false;
        vm.selectedFilter = {};
        vm.mainGrid = {};
        vm.masterOptions = [];
        vm.filter = {};
        vm.view = 'customers';
        vm.customerView = customersView.data.content[0].value;

        var setView = function() {
            var filterable = vm.masterFilters.selectedCustomers.length || SupervisorsService.getSelectedSupervisors().length ? {
                mode: "row"
            } : false;

            vm.disabled = !vm.masterFilters.selectedCustomers.length && !SupervisorsService.getSelectedSupervisors().length;
            vm.mainGrid.setOptions({
                sortable: vm.masterFilters.selectedCustomers.length || SupervisorsService.getSelectedSupervisors().length,
                groupable: vm.masterFilters.selectedCustomers.length || SupervisorsService.getSelectedSupervisors().length,
                filterable: filterable
            });
        };

        $scope.$watch("vm.masterFilters.selectedSupervisors", function (value) {
            SupervisorsService.setSelectedSupervisors(value);
            setView();
            vm.employeesMulti.enable(!vm.masterFilters.selectedSupervisors.length);
        });
        $scope.$watch("vm.masterFilters.selectedCustomers", function () {
            setView();
            vm.supervisorsMulti.enable(!vm.masterFilters.selectedCustomers.length);
        });

        vm.masterFiltersEdited = function(){
            var gridOptions = FavouriteFiltersService.getKendoOptions(vm.mainGrid);
            $timeout(function(){
                vm.isEdited = !!(vm.selectedFilter.kendoOptions && vm.masterOptions.length &&
                    (!angular.equals(vm.masterOptions, MasterToKendoFiltersService.getMasterFilters([], vm.masterFilters, vm.view)) || !angular.equals(JSON.parse(vm.selectedFilter.kendoOptions), gridOptions)) );
            });
        };
        $scope.$watch("vm.masterFilters", vm.masterFiltersEdited, true);

        vm.collapseAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-collapse").trigger("click"); };
        vm.expandAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-expand").trigger("click"); };

        vm.supervisorsMultiOptions = MasterFiltersService.getSupervisorsComboBoxOptions();
        vm.supervisorsMultiOptions.change = function () {
            vm.employeesMulti.value('');
            vm.employeesMultiOptions.dataSource.filter({});
            vm.masterFilters.selectedCustomers = [];
            vm.employeesMulti.dataSource.read();
        };

        vm.employeesMultiOptions = MasterFiltersService.getEmployeesComboBoxOptions(["all", "none", "me"]);
        vm.employeesMultiOptions.dataSource.filter({});

        vm.specialtiesMultiDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function (o) {
                    var url = '/api/masterdata/doctor-specialties';
                    if (o.data.filter == undefined) {
                        o.data.filter = {filters: []};
                    }

                    o.data.page = 1;
                    o.data.pageSize = 10000;
                    $http.get(url,
                        {params: o.data})
                        .success(function (response) {
                            response.content.unshift({id: "none", name: "(Κενό)"});
                            o.success(response.content);
                        })
                        .error(function (response) {
                        });
                }
            }
        });
        vm.specialtiesMultiOptions = {
            dataSource: vm.specialtiesMultiDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id",
            placeholder: "Επιλογή Ειδικότητας",
            select: function (e) {
                var dataItem = this.dataSource.view()[e.item.index()];
                var values = this.value();
                var self = this;

                MasterFiltersService.selectAllNone(self, dataItem, values, ["none"]);
            }
        };

        var softwareDataSource = [{text: 'Επιλογή όλων', value: 'all'}, {text: 'Όχι', value: 'none'},
            {text: $filter('translate')('global.field.software.medexpress'), value: 'medexpress'},
            {text: $filter('translate')('global.field.software.medmobile'), value: 'medmobile'},
            {text: $filter('translate')('global.field.software.other'), value: 'other'}];
        vm.softwareOptions = {
            dataSource: softwareDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "text",
            dataValueField: "value",
            placeholder: "Επιλογή λογισμικού",
            select: function (e) {
                var dataItem = this.dataSource.view()[e.item.index()];
                var values = this.value();

                if (dataItem.value === "all" || dataItem.value === "none") {
                    this.value("");
                } else if (values.indexOf("all") !== -1) {
                    values = angular.element.grep(values, function(value) {
                        return value !== "all";
                    });
                    this.value(values);
                } else if (values.indexOf("none") !== -1) {
                    values = angular.element.grep(values, function(value) {
                        return value !== "none";
                    });
                    this.value(values);
                } else if (values.indexOf("other") !== -1) {
                    values = angular.element.grep(values, function(value) {
                        return value !== "other";
                    });
                    this.value(values);
                }
            }
        };

        vm.clearDatePickers = function () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        };

        vm.masterFiltersOnClick = function (e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id == "today") {
                vm.clearDatePickers();
                $timeout(function(){
                    vm.masterFilters.from = date;
                    vm.masterFilters.to = date;
                },0);
            }
            else if (e.id == "week") {

                vm.clearDatePickers();

                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6

                $timeout(function(){
                    vm.masterFilters.from = firstWeekDay;
                    vm.masterFilters.to = lastWeekDay;
                },0);
            }
            else if (e.id == "month") {

                vm.clearDatePickers();

                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                $timeout(function(){
                    vm.masterFilters.from = firstMonthDay;
                    vm.masterFilters.to = lastMonthDay;
                },0);
            }
        };

        vm.search = function () {
            if (vm.masterFilters.selectedCustomers.length || SupervisorsService.getSelectedSupervisors().length) {
                vm.mainGridOptions.dataSource.read();
            }
        };

        vm.openMenu = function($mdOpenMenu, ev) { $mdOpenMenu(ev); };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup", id: "buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ], overflow: "never", attributes: {class: "left"}
                },
                {type: "separator", overflow: "never", attributes: {class: "left"}},
                {
                    template: "<label>Από: </label><input class='inline' kendo-date-picker id='from' options='vm.fromOptions' k-ng-model='vm.masterFilters.from'/>",
                    overflow: "never",
                    attributes: {class: "left"}
                },
                {
                    template: "<label>Εώς: </label><input class='inline' kendo-date-picker id='to' options='vm.toOptions' k-ng-model='vm.masterFilters.to'/>",
                    overflow: "never",
                    attributes: {class: "left"}
                },
                {type: "separator", overflow: "never", attributes: {class: "left"}},
                {
                    template: "<label>Προιστάμενος: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.supervisorsMulti' multiple='multiple' " +
                    "k-options='vm.supervisorsMultiOptions' k-ng-model='vm.masterFilters.selectedSupervisors'></select>",
                    overflow: "never", attributes: {class: "left"}
                },
                {type: "separator", overflow: "never", attributes: {class: "left"}},
                {
                    template: "<label>Ιατρικός Επισκέπτης: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.employeesMulti' multiple='multiple' " +
                    "k-options='vm.employeesMultiOptions' k-ng-model='vm.masterFilters.selectedCustomers'></select>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    template: "<label>Ειδικότητα: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select multiple='multiple' " +
                    "k-options='vm.specialtiesMultiOptions' k-ng-model='vm.masterFilters.selectedSpecialties'></select>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    template: "<label>My Target: </label><md-checkbox aria-label='saw' ng-model='vm.masterFilters.saw' class='checkbox-toolbar green inline'></md-checkbox>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    template: "<label>Ενεργός: </label><md-checkbox aria-label='isActive' ng-model='vm.masterFilters.isActive' class='checkbox-toolbar green inline'></md-checkbox>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    template: "<div ng-if='vm.customerView === \"3\"'><label>Λογισμικό: </label><select kendo-multi-select multiple='multiple' id='multiSelect' name='multiSelect' class='inline' k-ng-model='vm.masterFilters.selectedSoftwares' " +
                    "                            k-options='vm.softwareOptions' class='col-fix'></select></div>",
                    overflow: "never", attributes: {class: "left left-space"}
                },
                {
                    template: "<div ng-if='vm.customerView === \"3\"'><label>Ημέρες Τελ. Επίσκεψης: </label>" +
                    "<input id='lastVisitDate' style='width: 5em;' kendo-numeric-text-box min='0' k-ng-model='vm.masterFilters.lastVisitDate.value' k-format='\"n0\"'/>" +
                    "<md-menu class='master-filter-btn'> <md-button aria-label='Open filter operators menu' class='md-icon-button' ng-click='vm.openMenu($mdOpenMenu, $event)'> " +
                    "<md-icon class='filter-list-icon'></md-icon> </md-button> " +
                    "<md-menu-content width='4'> " +
                    "<md-menu-item> <md-button ng-click='vm.masterFilters.lastVisitDate.operator = \"eqdate\" '> Ίσο</md-button></md-menu-item> " +
                    "<md-menu-item> <md-button ng-click='vm.masterFilters.lastVisitDate.operator = \"gte\" '> Μικρότερο ή Ίσο</md-button></md-menu-item> " +
                    "<md-menu-item> <md-button ng-click='vm.masterFilters.lastVisitDate.operator = \"lte\" '> Μεγαλύτερο ή Ίσο</md-button></md-menu-item> " +
                    "</md-menu-content> </md-menu></div>",
                    overflow: "never", attributes: {class: "left left-space"}
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary md-primary-light' aria-label='search' ng-click='vm.search()' ng-disabled='vm.disabled'>" +
                    "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>",
                    overflow: "never",
                    attributes: {class: "right"}
                }
            ]
        };

        vm.fromOptions = {
            change: function (e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    $timeout(function(){
                        vm.masterFilters.from = value;
                    },0);
                } else {
                    $timeout(function(){
                        vm.masterFilters.from = null;
                    },0);
                }
            }
        };

        vm.toOptions = {
            change: function (e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    $timeout(function(){
                        vm.masterFilters.to = value;
                    },0);
                } else {
                    $timeout(function(){
                        vm.masterFilters.to = null;
                    },0);
                }
            }
        };

        var filterTemplate = function (args) {
            KendoFilter.getTemplate(args);
        };

        var dayTimeFilterTemplate = function (args) {
            KendoFilter.getDayTimeTemplate(args);
        };

        var booleanFilterTemplate = function (args) {
            KendoFilter.getBooleanTemplate(args);
        };

        vm.mainGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function (o) {
                        if (o.data.filter == undefined) o.data.filter = {filters: []};
                        o.data.filter.filters = MasterToKendoFiltersService.getMasterFilters(o.data.filter.filters, vm.masterFilters, vm.view);

                        angular.forEach(o.data.filter.filters, function (item) {
                            if ( item.field === 'lastVisitDate' ) {
                                var dt = new Date();
                                dt.setDate( dt.getDate() - item.value );
                                item.value = dt;
                            }
                            if (item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        if(vm.customerView === '3') {
                            vm.customersURL = '/api/customers/main/all';
                        }

                        $http.get(vm.customersURL, {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    }
                },
                schema: {
                    data: function (data) {
                        data.forEach(function (item) {
                            if (item.customerCategory === null) item.customerCategory = {};
                            if (item.customerSubCategory === null) item.customerSubCategory = {};
                            if (item.doctorSpecialty === null) item.doctorSpecialty = {};
                            if (item.customerTitle === null) item.customerTitle = {};
                            if (item.sector === null || !item.sector) item.sector = {};
                            if (item.sector.sectorType === null || !item.sector.sectorType) item.sector.sectorType = {};
                            item.employeesString = "";
                            if (item.employees) {
                                item.employees.forEach(function (employeeName) {
                                    item.employeesString += "[" + employeeName + "] ";
                                });
                            }
                        });
                        return data;
                    },
                    parse: function (data) {
                        var dt = new Date();
                        dt.setHours(23, 59, 59);
                        data.forEach(function (item) {
                            item.lastVisitDate =
                                item.lastVisitDate ? Math.floor(Math.abs(new Date(item.lastVisitDate).getTime() - dt.getTime()) / (1000 * 60 * 60 * 24)) : '';
                        });
                        return data;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: {editable: false, nullable: false},
                            saw: {
                                type: "boolean",
                                defaultValue: true,
                                parse: function (value) {
                                    return !!(value == "1" || value == "true" || value == true);

                                }
                            },
                            isActive: {
                                type: "boolean",
                                defaultValue: true,
                                parse: function (value) {
                                    return !!(value == "1" || value == "true" || value == true);
                                }
                            },
                            gdpr: {
                                type: "boolean",
                                defaultValue: true,
                                parse: function (value) {
                                    return !!(value == "1" || value == "true" || value == true);

                                }
                            },
                            lastVisitDate: { type: 'number', editable: false}
                        }
                    }
                },
                aggregate: [{field: "lastName", aggregate: "count"},
                    {field: "firstName", aggregate: "count"},
                    {field: "doctorSpecialty.name", aggregate: "count"},
                    {field: "customerCategory.name", aggregate: "count"},
                    {field: "customerSubCategory.name", aggregate: "count"},
                    {field: "cellPhone", aggregate: "count"},
                    {field: "phone", aggregate: "count"},
                    {field: "email", aggregate: "count"},
                    {field: "dayTime", aggregate: "count"},
                    {field: "customerTitle.name", aggregate: "count"},
                    {field: "isActive", aggregate: "count"},
                    {field: "gdpr", aggregate: "count"}]
            }),
            sortable: false,
            groupable: false,
            filterable: false,
            resizable: true,
            columns: [
                {
                    field: "lastName",
                    title: "Επώνυμο",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    groupHeaderTemplate: "#= value # (Σύνολο: #=kendo.format('{0:n0}', count)#)",
                    width: 120
                },
                {
                    field: "firstName",
                    title: "Όνομα",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    groupHeaderTemplate: "#= (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)",
                    width: 100
                },
                {
                    field: "doctorSpecialty.name",
                    title: "Ειδικοτητα 1η",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    groupHeaderTemplate: "#= (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)",
                    width: 130
                },
                {
                    field: "customerCategory.name",
                    title: "Κατηγορία",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    groupHeaderTemplate: "#=  (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)",
                    width: 70
                },
                {
                    field: "customerSubCategory.name",
                    title: "Υποκατηγορία",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    groupHeaderTemplate: "#= (value == null) ? '' : value #(Σύνολο: #=kendo.format('{0:n0}', count)#)",
                    width: 90
                },
                {
                    field: "cellPhone",
                    title: "Κινητό",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    width: 92
                },
                {
                    field: "phone",
                    title: "Σταθερό Τηλ.",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    width: 92
                },
                {
                    hidden: vm.customerView === '3',
                    field: "email",
                    title: "Email",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    width: 151
                },
                {
                    hidden: vm.customerView === '3',
                    field: "dayTime",
                    title: "Ωράριο",
                    aggregates: ["count"],
                    template: "#= (dayTime == 'day') ? 'ΠΡΩΙΝΌ' : (dayTime == 'night') ? 'ΑΠΟΓΕΥΜΑΤΙΝΌ' : '' #",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            showOperators: false,
                            template: dayTimeFilterTemplate
                        }
                    },
                    groupHeaderTemplate: "#= (value == 'day') ? 'ΠΡΩΙΝΌ' : (value == 'night') ? 'ΑΠΟΓΕΥΜΑΤΙΝΌ' : '' #(Σύνολο: #=kendo.format('{0:n0}', count)#)",
                    width: 120
                },
                {
                    hidden: vm.customerView === '3',
                    field: "customerTitle.name",
                    title: "Τίτλος",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: filterTemplate,
                            showOperators: false
                        }
                    },
                    groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)",
                    width: 120
                },
                {
                    hidden: vm.customerView === '3',
                    field: "isActive",
                    title: "Ενεργός",
                    aggregates: ["count"],
                    template: "#= (isActive == '1') ? 'Ναι' : 'Όχι' #",
                    filterable: {
                        cell: {
                            operator: "eq",
                            showOperators: false,
                            template: booleanFilterTemplate
                        }
                    },
                    groupHeaderTemplate: "#=  (value == true) ? 'Ναι'  : 'Οχι' # (Σύνολο: #=kendo.format('{0:n0}', count)#)",
                    width: 110
                },
                {
                    hidden: vm.customerView !== '6',
                    field: "gdpr",
                    title: "GDPR",
                    aggregates: ["count"],
                    template: "#= (gdpr == '1') ? 'Ναι' : 'Όχι' #",
                    filterable: {
                        cell: {
                            operator: "eq",
                            showOperators: false,
                            template: booleanFilterTemplate
                        }
                    },
                    groupHeaderTemplate: "#=  (value == true) ? 'Ναι'  : 'Οχι' # (Σύνολο: #=kendo.format('{0:n0}', count)#)",
                    width: 110
                },
                {
                    hidden: vm.customerView !== '3',
                    field: "sector.sectorType.name",
                    title: "Τύπος",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 105
                },
                {
                    hidden: vm.customerView !== '3',
                    field: "sector.name", title: "Μονάδα",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 140
                },
                {
                    hidden: vm.customerView !== '3',
                    field: "sector.osfeBrick",
                    title: "Brick ΟΣΦΕ",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 115
                },
                {
                    hidden: vm.customerView !== '3',
                    field: "sector.cityName",
                    title: "Πόλη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 110
                },
                {
                    hidden: vm.customerView !== '3',
                    field: "sector.prefectureName",
                    title: "Νομός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 110
                },
                {
                    hidden: vm.customerView !== '3',
                    field: "software",
                    title: "Λογισμικό",
                    template: function (dataItem) {
                        return dataItem.software ? $filter('translate')('global.field.software.' + dataItem.software ) : 'Όχι';
                    },
                    filterable: false,
                    width: 100
                },
                {
                    hidden: vm.customerView !== '3',
                    field: "lastVisitDate", title: "Ημέρες Τελ. Επίσκεψης",
                    filterable: {
                        operators: {
                            number: {
                                eq: 'Ίσο',
                                gte: 'Μεγαλύτερο ή Ίσο',
                                lte: 'Μικρότερο ή Ίσο'
                            }
                        },
                        cell: {
                            template: function (args) {
                                args.element.kendoNumericTextBox({
                                    format: "n0",
                                    decimals: 0,
                                    min: 0
                                });

                            }
                        }
                    },
                    width: 140
                },
                {
                    hidden: vm.customerView !== '3',
                    field: "employeesString", title: "Ιατρικοί Επισκέπτες",
                    filterable: false,
                    sortable: false,
                    width: 140
                }
            ],
            toolbar: [
                {template: "<md-button class='md-raised md-mini md-primary md-primary-light' aria-label='collapseAll' ng-click='vm.collapseAll()'>Collapse All</md-button>"},
                {template: "<md-button class='md-raised md-mini md-primary md-primary-light' aria-label='expandAll' ng-click='vm.expandAll()'>Expand All</md-button>"},
                {
                    name: "excel",
                    template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label><md-button class='md-raised md-mini md-primary md-primary-light k-button-icontext k-grid-excel' aria-label='excel'>" +
                    "<span class='k-icon k-i-excel'></span>Export</md-button></div>"
                }
            ],
            excel: {
                allPages: true,
                fileName: "Επαγγελματίες Υγείας.xlsx"
            },
            dataBound: function () { vm.masterFiltersEdited(); }
        };

        $timeout(function () {
            MasterFiltersService.clearFilterEvent()
        });
    }
})();
