(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchaseStatusDeleteController',PurchaseStatusDeleteController);

    PurchaseStatusDeleteController.$inject = ['$uibModalInstance', 'entity', 'PurchaseStatus'];

    function PurchaseStatusDeleteController($uibModalInstance, entity, PurchaseStatus) {
        var vm = this;
        vm.purchaseStatus = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            PurchaseStatus.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
