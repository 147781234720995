/**
 * Created by Kristy on 12/9/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .directive('favouriteFiltersMain', favouriteFiltersMain);
    favouriteFiltersMain.$inject = ['FavouriteFiltersService', 'MasterToKendoFiltersService', 'FavouriteFilter'];
    function favouriteFiltersMain (FavouriteFiltersService, MasterToKendoFiltersService, FavouriteFilter) {

        return {
            restrict: 'A',
            scope: true,
            template: "<div id=\"favourite-filters\" resizable r-directions=\"['right']\" style=\"width: 200px;min-width: 200px!important;max-width: 500px;\">\n" +
            "        <div class=\"filter-panel-favourites-container\">\n" +
            "            <div class=\"filter-panel-section\">\n" +
            "                <h4 class=\"filter-title\">Αγαπημένα Φίλτρα</h4>\n" +
            "                <div class=\"filter-content\">\n" +
            "                    <ul ng-repeat=\"favouriteFilter in vm.favouriteFilters\" class=\"saved-filter filter-list favourite-filters\">\n" +
            "                        <li ng-mouseenter=\"hover=true\" ng-mouseleave=\"hover=false\">\n" +
            "                            <a ng-class=\"{'fav-filter-active': vm.selectedFilter.name === favouriteFilter.name}\" class=\"filter-link\"\n" +
            "                               ng-click=\"vm.favouriteFilterSelected(favouriteFilter);\">{{favouriteFilter.name}}</a>\n" +
            "                            <span ng-show=\"hover || vm.selectedFilter.name === favouriteFilter.name\">\n" +
            "                                <md-menu>\n" +
            "                                  <md-button class=\"md-icon-button filter-actions\" aria-label=\"clear\" ng-click=\"$mdOpenMenu($event);\">\n" +
            "                                    <md-icon ng-class=\"{'fav-filter-active': vm.selectedFilter.name === favouriteFilter.name}\"\n" +
            "                                             class=\"material-icons small-md-icon\">arrow_drop_down</md-icon>\n" +
            "                                  </md-button>\n" +
            "                                  <md-menu-content width=\"2\" id=\"fav-filter-menu-content\">\n" +
            "                                    <md-menu-item>\n" +
            "                                      <md-button ng-click=\"vm.deleteFavouriteFilter(favouriteFilter, $index)\" aria-label=\"clear\">\n" +
            "                                        <md-icon class=\"material-icons small-md-icon\">clear</md-icon> Διαγραφή\n" +
            "                                      </md-button>\n" +
            "                                    </md-menu-item>\n" +
            "                                    <md-menu-item>\n" +
            "                                      <md-button ng-click=\"vm.isRenameMode = true;vm.filter=favouriteFilter;vm.window.open().center()\" aria-label=\"rename\">\n" +
            "                                        <md-icon class=\"material-icons small-md-icon\">edit_mode</md-icon> Μετονομασία\n" +
            "                                      </md-button>\n" +
            "                                    </md-menu-item>\n" +
            "                                  </md-menu-content>\n" +
            "                                </md-menu>\n" +
            "                            </span>\n" +
            "                        </li>\n" +
            "\n" +
            "                    </ul>\n" +
            "                </div>\n" +
            "            </div>\n" +
            "        </div>\n" +
            "    </div>",
            controller: function ($scope) {
                var vm = $scope.$parent.vm;

                vm.favouriteFilterSelected = function (filter) {
                    vm.selectedFilter = filter;
                    vm.masterOptions = JSON.parse(filter.masterOptions);
                    vm.masterFilters = FavouriteFiltersService.clearMasterFilters();
                    vm.masterFilters = FavouriteFiltersService.setMasterOptions(vm.masterOptions, vm.masterFilters);
                    FavouriteFiltersService.setKendoOptions(JSON.parse(filter.kendoOptions), vm.mainGrid);
                    if (vm.mainGridOptions) {
                        vm.mainGridOptions.dataSource.read();
                    }
                };

                vm.saveFilter = function (selectedFilter) {
                    vm.filter = selectedFilter;
                    if (!vm.isRenameMode) {
                        vm.filter.employee = vm.employee.data;
                        if(vm.view === "events") {
                            vm.filter.viewName = vm.view + "_filter";
                        } else {
                            vm.filter.viewName = vm.view;
                        }

                        vm.masterOptions = MasterToKendoFiltersService.getMasterFilters([], vm.masterFilters, vm.filter.viewName);
                        vm.filter.kendoOptions = JSON.stringify(FavouriteFiltersService.getKendoOptions(vm.mainGrid));
                        vm.filter.masterOptions = JSON.stringify(vm.masterOptions);
                    }
                    FavouriteFilter.update({}, vm.filter,
                        function (result) {
                            if (!vm.isRenameMode) {

                                if(!vm.filter.id) vm.favouriteFilters.push(result);
                                else vm.masterFiltersEdited();
                                vm.favouriteFilterSelected(result);
                            }
                            vm.window.close()
                        });
                };

                vm.clearAllOptions = function() {
                    vm.masterFilters = FavouriteFiltersService.clearMasterFilters();
                    FavouriteFiltersService.setKendoOptions(null, vm.mainGrid);
                };

                vm.deleteFavouriteFilter = function(filter, index) {
                    FavouriteFilter.delete({id: filter.id},
                        function () { vm.favouriteFilters.splice(index, 1); });
                };
            }
        };
    }
})();
