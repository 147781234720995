/**
 * Created by Kristy on 19/7/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('HospitalizationsReportController', HospitalizationsReportController);

    HospitalizationsReportController.$inject = ['$filter','$http', 'KendoFilter'];

    function HospitalizationsReportController ($filter, $http, KendoFilter) {
        var vm = this;

        vm.pageHeading = $filter('translate')('sigmasfApp.hospitalization.report.title');
        vm.url = '/api/hospitalizations/analytics';

        var dataSource = {};
        vm.period = {};

        vm.filterGrid = function() {
            if (vm.period) {
                var filter = {};
                switch (vm.period.id) {
                    case 2:
                        filter = {
                            logic: "and",
                            filters: [
                                {field: "total", operator: "gte", value: 3},
                                {field: "total", operator: "lte", value: 9}
                            ]
                        };
                        break;
                    case 3:
                        filter = {
                            field: "total", operator: "gte", value: 10
                        };
                }
                vm.mainGridOptions.dataSource.filter(filter);
            }
        };

        //Period dropdown edit configuration
        dataSource.periodData = [
            {id: 1, name: $filter('translate')('sigmasfApp.hospitalization.report.all')},
            {id: 2, name: "3-9"}, {id: 3, name: ">=10"}];

        vm.periodOptions = {
            dataSource: {
                data: dataSource.periodData
            },
            dataTextField: "name",
            dataValueField: "id",
            change: function() {
                vm.filterGrid()
            }
        };

        var date = new Date();
        date.setDate(1); // going to 1st of the month
        date.setHours(-1); // going to last hour before this date even started.

        vm.gridColumns = [
            {
                title: "ΣΥΝΟΛΟ ΑΞΙΟΛΟΓΗΣΕΩΝ ΕΩΣ " + kendo.toString(date, 'dd-MM-yyyy'),
                columns: [
                    {
                        field: "customer",
                        title: "Επαγγελματίας Υγείας",
                        filterable: {
                            cell: {
                                operator: "contains",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        },
                        width: 200
                    },
                    {
                        field: "specialty",
                        title: "Ειδικότητα",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    {
                        field: "sector",
                        title: "Προέλευση",
                        filterable: {
                            cell: {
                                operator: "contains",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        },
                        width: 200
                    },
                    {
                        field: "total",
                        title: "Σύνολο",
                        filterable: {
                            cell: {
                                operator: "eq",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        },
                        attributes:{class:"numbers"}
                    }
                ]
            }
        ];

        var year = new Date();
        year.setFullYear(2011);
        var years = "";
        while ( year.getFullYear() <= new Date().getFullYear()) {
            years += year.getFullYear();
            if(year.getFullYear() !== new Date().getFullYear()) years += ",";
            vm.gridColumns[0].columns.push({
                field: "count" + year.getFullYear().toString(),
                title: year.getFullYear().toString(),
                filterable: {
                    cell: {
                        operator: "eq",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                attributes:{class:"numbers"}
            });
            year.setFullYear(year.getFullYear() + 1);
        }

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function (o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.years = years;
                        o.data.stopAtPreviousMonth = true;
                        $http.get(vm.url, {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    }
                },
                schema: {
                    parse: function(data) {
                        var modifiedData = [];
                        angular.forEach( data, function( item ) {
                            var found = $filter('filter')(modifiedData, {customerId: item.customerId}, true);
                            if (found.length) {
                                modifiedData[modifiedData.indexOf(found[0])].total += item.count;
                                if (modifiedData[modifiedData.indexOf(found[0])]["count" + item.year]) {
                                    modifiedData[modifiedData.indexOf(found[0])]["count" + item.year] += item.count;
                                } else {
                                    modifiedData[modifiedData.indexOf(found[0])]["count" + item.year] = item.count
                                }
                            } else {
                                item.total = item.count;
                                item["count" + item.year] =  item.count;
                                modifiedData.push(item);
                            }
                        });
                        return modifiedData;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: "id"
                        }
                    }
                }
            }),
            sortable: {
                mode: "multiple",
                allowUnsort: true,
                showIndexes: true
            },
            filterable: {
                mode: "row"
            },
            columns: vm.gridColumns,
            toolbar: [
                { name: "period", template: "<label>Σύνολο αξιολογήσεων: </label><select id='period' name='period' class='inline' kendo-drop-down-list " +
                    "k-options='vm.periodOptions' k-ng-model='vm.period'></select>" },
                { name: "excel", template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                "<span class='k-icon k-i-excel'></span>Export</md-button></div>" }
            ],
            excel: {
                allPages: true,
                fileName: "Σύνολο Αξιολογήσεων ανά Επαγγελματία Υγείας ανά έτος.xlsx"
            }
        };
    }
})();
