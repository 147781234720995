/**
 * Created by Teo on 02/05/2019.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchasesAggregatedPerEmployeeSectorProductController', PurchasesAggregatedPerEmployeeSectorProductController);

    PurchasesAggregatedPerEmployeeSectorProductController.$inject = ['$scope', '$filter', '$http', 'KendoGrid', 'CustomUtils', 'Alerts', '$timeout', 'KendoToolbar', 'CustomDataUtils', 'pharmacyVisitors', 'accountingDeps', 'KendoService', 'productCategories', 'purchaseProducts', 'purchaseStatuses'];

    function PurchasesAggregatedPerEmployeeSectorProductController ($scope, $filter, $http, KendoGrid, CustomUtils, Alerts, $timeout, KendoToolbar, CustomDataUtils, pharmacyVisitors, accountingDeps, KendoService, productCategories, purchaseProducts, purchaseStatuses) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(48);
        vm.showProgress = false;
        vm.masterFilters = {};
        vm.mainGrid = {};

        vm.selectedEmployeeId = null;
        vm.selectedSector = null;
        vm.selectedProductCategoryId = null;
        vm.selectedProduct = null;
        vm.purchaseStatusIdsString = constructPurchaseStatusIdsString();

        Array.prototype.push.apply(pharmacyVisitors, accountingDeps);
        vm.employeeOptions = {
            dataSource: {
                data: pharmacyVisitors
            },
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "fullName",
            dataValueField: "id",
            placeholder: "'Ολοι οι Ιατρικοί Επισκέπτες Φαρμακείων"
        };

        vm.sectorOptions = KendoService.initDefaultOptions(KendoService.initDataSource('/api/me/pharmacies', 1, 20), "like", "name", "id");
        vm.sectorOptions.placeholder = "Όλοι οι Επαγγελματίες Υγείας";
        vm.sectorOptions.valuePrimitive = false;
        vm.sectorOptions.autoBind = false;

        vm.productCategoryOptions = {
            dataSource: {
                data: productCategories
            },
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id",
            clearButton: false,
            placeholder: "ΟΛΑ",
            change: function (e) {
                var productComboBox = $("#productSelect").data("kendoComboBox");
                if (this.dataItem() === undefined || this.dataItem() === null){
                    productComboBox.enable(true);
                }else {
                    productComboBox.value(null);
                    productComboBox.enable(false);
                }
            }
        };

        vm.productsOptions = {
            dataSource: purchaseProducts,
            filter: "contains",
            dataTextField: "name",
            dataValueField: "id",
            clearButton: true,
            placeholder: "ΟΛΑ",
            change: function (e) {
                var productCategoryComboBox = $("#productCategorySelect").data("kendoComboBox");
                if (this.dataItem() === undefined || this.dataItem() === null){
                    productCategoryComboBox.enable(true);
                }else {
                    productCategoryComboBox.value(null);
                    productCategoryComboBox.enable(false);
                }
            }
        };

        vm.getData = function () {
            var cancel = false;
            var message = "";
            if (vm.masterFilters.from === null || vm.masterFilters.to === null ||
                vm.masterFilters.from === undefined || vm.masterFilters.to === undefined){
                cancel = true;
                message = "Επιλέξτε ημερομηνίες";
            }

            if (!cancel && (vm.masterFilters.from > vm.masterFilters.to)){
                cancel = true;
                message = "Η ημερομηνία 'Από' πρέπει να είναι μικρότερη από την ημερομηνία 'Εώς'"
            }

            if (cancel){
                Alerts.showAlert("Sigma SalesForce", message, "ΟΚ");
            }else {
                $("#mainGrid").data('kendoGrid').dataSource.data([]);
                vm.mainGridOptions.dataSource.read();
            }
        };

        vm.masterFiltersOnClick = function(e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');
            if (e.id === "today") {
                KendoToolbar.clearDatePickers();
                $timeout(function () {
                    vm.masterFilters.from = date;
                    vm.masterFilters.to = date;
                });
            }
            else if (e.id === "week") {
                KendoToolbar.clearDatePickers();
                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6
                $timeout(function () {
                    vm.masterFilters.from = firstWeekDay;
                    vm.masterFilters.to = lastWeekDay;
                });
            }
            else if (e.id === "month") {
                KendoToolbar.clearDatePickers();
                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                $timeout(function () {
                    vm.masterFilters.from = firstMonthDay;
                    vm.masterFilters.to = lastMonthDay;
                });
            }
            else if (e.id === "year") {
                KendoToolbar.clearDatePickers();
                $timeout(function () {
                    vm.masterFilters.from = new Date(date.getFullYear(), 0, 1);
                    vm.masterFilters.to = new Date();
                });
            }
        };

        vm.collapseAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-collapse").trigger("click"); };

        vm.expandAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-expand").trigger("click"); };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup", id:"buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ], overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Από: </label><input style='width: 100px;' class='inline' kendo-date-picker id='from' options='vm.fromOptions' k-ng-model='vm.masterFilters.from'/>", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Εώς: </label><input style='width: 100px;' class='inline' kendo-date-picker id='to' options='vm.toOptions' k-ng-model='vm.masterFilters.to'/>", overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Ιατρικός Επισκέπτης: </label><select name='employeeSelect' id='employeeSelect' style='width: 210px; margin-right: 2px' kendo-combo-box k-ng-model='vm.selectedEmployeeId' k-options='vm.employeeOptions' style='width: 60%'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Επαγγελματίας Υγείας: </label><select name='sectorSelect' id='sectorSelect' style='width: 180px; margin-right: 2px' kendo-combo-box k-ng-model='vm.selectedSector' k-options='vm.sectorOptions' style='width: 60%'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Κατ. Ειδών: </label><select style='width: 100px; margin-right: 2px' name='productCategorySelect' id='productCategorySelect' kendo-combo-box k-ng-model='vm.selectedProductCategoryId' k-options='vm.productCategoryOptions'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Είδος: </label><select style='width: 420px; margin-right: 2px' name='productSelect' id='productSelect' kendo-combo-box k-ng-model='vm.selectedProduct' k-options='vm.productsOptions'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<md-button class='md-raised md-mini md-primary' aria-label='collapseAll' ng-click='vm.collapseAll()'>Σύμπτυξη όλων</md-button>", overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<md-button class='md-raised md-mini md-primary' aria-label='expandAll' ng-click='vm.expandAll()'>Επέκταση Όλων</md-button>", overflow: "never", attributes: { class: "left" }
                },
                /*{ THIS WILL BE IMPLEMENTED WHEN WE UPDATE KENDO
                    template: "<md-button class='md-raised md-mini md-primary k-button-icontext k-button-icontext k-grid-excel' aria-label='search' ng-click='vm.exportData();' ng-disabled='vm.disabled'>" +
                    "<span class='k-icon k-i-excel'></span>EXPORT</md-button>", overflow: "never", attributes: { class: "right" }
                },*/
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary' aria-label='search' ng-click='vm.getData();' ng-disabled='vm.disabled'>" +
                    "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>", overflow: "never", attributes: { class: "right" }
                }
            ]
        };

        vm.fromOptions = {
            change: function() {
                var start = angular.element("#from").data("kendoDatePicker");
                var end = angular.element("#to").data("kendoDatePicker");
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.from = value;
                } else {
                    vm.masterFilters.from = null;
                }

                var startDate = vm.masterFilters.from,
                    endDate = vm.masterFilters.to;

                if (startDate) {
                    startDate = new Date(startDate);
                    startDate.setDate(startDate.getDate());
                    end.min(startDate);

                    //set range to year
                    var maxDate = new Date(startDate);
                    maxDate = new Date(maxDate.setFullYear(startDate.getFullYear() + 1));
                    end.max(maxDate);
                } else if (endDate) {
                    start.max(new Date(endDate));
                    end.max(new Date(2099, 11, 31));
                } else {
                    endDate = new Date();
                    start.max(endDate);
                    end.min(endDate);
                    end.max(new Date(2099, 11, 31));
                }
            }
        };

        vm.toOptions = {
            change: function() {
                var start = angular.element("#from").data("kendoDatePicker");
                var end = angular.element("#to").data("kendoDatePicker");
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.to = value;
                } else {
                    vm.masterFilters.to = null;
                }

                var endDate = vm.masterFilters.to,
                    startDate = vm.masterFilters.from;

                if (endDate) {
                    endDate = new Date(endDate);
                    endDate.setDate(endDate.getDate());
                    start.max(endDate);

                    //set range to year
                    var minDate = new Date(endDate);
                    minDate = new Date(minDate.setFullYear(endDate.getFullYear() - 1));
                    start.min(minDate);
                } else if (startDate) {
                    end.min(new Date(startDate));
                    start.min(new Date(1900, 0, 1));
                } else {
                    endDate = new Date();
                    start.max(endDate);
                    end.min(endDate);
                    start.min(new Date(1900, 0, 1));
                }
            }
        };

        // KEEP THIS in comments for groupHeaderColumnTemplate functionality when we update Kendo UI
        /*var dummyData2 = [
            {
                product: {
                    employee: {
                        account: {
                            fullName: "TOLI MARIA"
                        }
                    },
                    sector: {
                        name: "PHARMACY 1"
                    },
                    name: "DEPON 1",
                    totalNetPrice: 600,
                    execItemQuantities: 75,
                    totalSyfaNetPrice: 600,
                    execQuantitiesSyfa: 75,
                    totalMhSyfaNetPrice: 0,
                    execQuantityesMhSyfa: 0,
                    totalIatrNetPrice: 0,
                    execQuantitiesIatr: 0
                }
            },
            {
                product: {
                    employee: {
                        account: {
                            fullName: "TOLI MARIA"
                        }
                    },
                    sector: {
                        name: "PHARMACY 1"
                    },
                    name: "DEPON 2",
                    totalNetPrice: 500,
                    execItemQuantities: 65,
                    totalSyfaNetPrice: 500,
                    execQuantitiesSyfa: 65,
                    totalMhSyfaNetPrice: 0,
                    execQuantityesMhSyfa: 0,
                    totalIatrNetPrice: 0,
                    execQuantitiesIatr: 0
                }
            },
            {
                product: {
                    employee: {
                        account: {
                            fullName: "TOLI MARIA"
                        }
                    },
                    sector: {
                        name: "PHARMACY 1"
                    },
                    name: "DEPON 3",
                    totalNetPrice: 400,
                    execItemQuantities: 55,
                    totalSyfaNetPrice: 400,
                    execQuantitiesSyfa: 65,
                    totalMhSyfaNetPrice: 0,
                    execQuantityesMhSyfa: 0,
                    totalIatrNetPrice: 0,
                    execQuantitiesIatr: 0
                }
            },
            {
                product: {
                    employee: {
                        account: {
                            fullName: "TOLI MARIA"
                        }
                    },
                    sector: {
                        name: "PHARMACY 2"
                    },
                    name: "DEPON 1",
                    totalNetPrice: 300,
                    execItemQuantities: 45,
                    totalSyfaNetPrice: 300,
                    execQuantitiesSyfa: 45,
                    totalMhSyfaNetPrice: 0,
                    execQuantityesMhSyfa: 0,
                    totalIatrNetPrice: 0,
                    execQuantitiesIatr: 0
                }
            },
            {
                product: {
                    employee: {
                        account: {
                            fullName: "TOLI MARIA"
                        }
                    },
                    sector: {
                        name: "PHARMACY 2"
                    },
                    name: "DEPON 2",
                    totalNetPrice: 100,
                    execItemQuantities: 25,
                    totalSyfaNetPrice: 100,
                    execQuantitiesSyfa: 25,
                    totalMhSyfaNetPrice: 0,
                    execQuantityesMhSyfa: 0,
                    totalIatrNetPrice: 0,
                    execQuantitiesIatr: 0
                }
            },
            {
                product: {
                    employee: {
                        account: {
                            fullName: "TSIALIKIS VAGGELIS"
                        }
                    },
                    sector: {
                        name: "PHARMACY 4"
                    },
                    name: "DEPON 1",
                    totalNetPrice: 150,
                    execItemQuantities: 30,
                    totalSyfaNetPrice: 150,
                    execQuantitiesSyfa: 30,
                    totalMhSyfaNetPrice: 0,
                    execQuantityesMhSyfa: 0,
                    totalIatrNetPrice: 0,
                    execQuantitiesIatr: 0
                }
            },
            {
                product: {
                    employee: {
                        account: {
                            fullName: "TSIALIKIS VAGGELIS"
                        }
                    },
                    sector: {
                        name: "PHARMACY 4"
                    },
                    name: "DEPON 2",
                    totalNetPrice: 200,
                    execItemQuantities: 35,
                    totalSyfaNetPrice: 200,
                    execQuantitiesSyfa: 35,
                    totalMhSyfaNetPrice: 0,
                    execQuantityesMhSyfa: 0,
                    totalIatrNetPrice: 0,
                    execQuantitiesIatr: 0
                }
            }
        ];
        var group2 = [
            {
                field: "product.employee.account.fullName",
                aggregates: [
                    { field: "product.totalNetPrice", aggregate: "sum"},
                    { field: "product.execItemQuantities", aggregate: "sum"},
                    { field: "product.totalSyfaNetPrice", aggregate: "sum"},
                    { field: "product.execQuantitiesSyfa", aggregate: "sum"},
                    { field: "product.totalMhSyfaNetPrice", aggregate: "sum"},
                    { field: "product.execQuantityesMhSyfa", aggregate: "sum"},
                    { field: "product.totalIatrNetPrice", aggregate: "sum"},
                    { field: "product.execQuantitiesIatr", aggregate: "sum"}
                ]
            }
            // {field: "product.sector.name"}
        ];
        var aggregate2 = [
            { field: "product.name", aggregate: "count"},
            { field: "product.totalNetPrice", aggregate: "sum"},
            { field: "product.execItemQuantities", aggregate: "sum"},
            { field: "product.totalSyfaNetPrice", aggregate: "sum"},
            { field: "product.execQuantitiesSyfa", aggregate: "sum"},
            { field: "product.totalMhSyfaNetPrice", aggregate: "sum"},
            { field: "product.execQuantityesMhSyfa", aggregate: "sum"},
            { field: "product.totalIatrNetPrice", aggregate: "sum"},
            { field: "product.execQuantitiesIatr", aggregate: "sum"}
        ];
        var columns2 = [
            {
                field: "product.name",
                title: "Προιόν",
                width: "250px"
            },
            {
                field: "product.employee.account.fullName",
                title: "Ιατρικός Επισκέπτης",
                width: "250px"
            },
            {
                field: "product.sector.name",
                title: "Φαρμακείο",
                width: "250px",
                aggregates: ["count"]
            },
            {
                field: "product.totalNetPrice",
                title: "Τζίρος",
                width: "250px",
                aggregates: ["sum"],
                groupHeaderColumnTemplate: "Sum: #=sum#"
            },
            {
                field: "product.execItemQuantities",
                title: "Τεμάχια",
                width: "250px",
                aggregates: ["sum"],
                groupHeaderColumnTemplate: "Sum: #=sum#"
            },
            {
                field: "product.totalSyfaNetPrice",
                title: "Τζίρος. ΣΥΦΑ",
                width: "250px",
                aggregates: ["sum"],
                groupHeaderColumnTemplate: "Sum: #=sum#"
            },
            {
                field: "product.execQuantitiesSyfa",
                title: "ΤΕΜ. ΣΥΦΑ",
                width: "250px",
                aggregates: ["sum"],
                groupHeaderColumnTemplate: "Sum: #=sum#"
            },
            {
                field: "product.totalMhSyfaNetPrice",
                title: "Τζίρος. ΜΗΣΥΦΑ",
                width: "250px",
                aggregates: ["sum"],
                groupHeaderColumnTemplate: "Sum: #=sum#"
            },
            {
                field: "product.execQuantityesMhSyfa",
                title: "ΤΕΜ. ΜΗΣΥΦΑ",
                width: "250px",
                aggregates: ["sum"],
                groupHeaderColumnTemplate: "Sum: #=sum#"
            },
            {
                field: "product.totalIatrNetPrice",
                title: "Τζίρος. ΙΑΤΡ",
                width: "250px",
                aggregates: ["sum"],
                groupHeaderColumnTemplate: "Sum: #=sum#"
            },
            {
                field: "product.execQuantitiesIatr",
                title: "ΤΕΜ. ΙΑΤΡ",
                width: "250px",
                aggregates: ["sum"],
                groupHeaderColumnTemplate: "Sum: #=sum#"
            }
        ];
        $(document).ready(function () {
            console.log(kendo.version);
            $("#grid").kendoGrid({
                dataSource: {
                    type: "odata",
                    data: dummyData2,
                    group: group2,
                    aggregate: aggregate2
                    /!*batch: true,
                    transport: {
                        read: function (o) {
                            vm.showProgress = true;
                            $http.get(constructUrl())
                                .success(function (response) {
                                    o.success(response);
                                    vm.showProgress = false;
                                })
                                .error(function (error) {
                                    o.error(error);
                                    vm.showProgress = false;
                                    Alerts.showAlert("Sigma SalesForce", "Λυπούμαστε. Κάποιο σφάλμα προσέκυψε!", "ΟΚ");
                                    console.log(error);
                                });
                        }
                    },
                    schema: {
                        model: {
                            id: "id",
                            fields: {
                                numberOfCustomers: { type: "number" },
                                numberOfPurchases: { type: "number" },
                                totalNetPrice: { type: "number" },
                                totalDiscountPrice: { type: "number" },
                                execItemQuantities: { type: "number" },
                                totalPresentPrice: { type: "number" },
                                execPresentItemQuantities: { type: "number" },
                                totalSyfaNetPrice: { type: "number" },
                                execQuantitiesSyfa: { type: "number" },
                                totalMhsyfaNetPrice: { type: "number" },
                                execQuantitiesMhSyfa: { type: "number" },
                                totalIatrNetPrice: { type: "number" },
                                execQuantitiesIatr: { type: "number" }
                            }
                        },
                        data: function (data) {
                            console.log(data);
                            if (data != null && data !== undefined && data.length > 0){
                                angular.forEach(data, function (item) {
                                    item.employee.fullName = item.employee.account.lastName + " " + item.employee.account.firstName;
                                    if (item.numberOfCustomers === null) item.numberOfCustomers = 0;
                                    if (item.numberOfPurchases === null) item.numberOfPurchases = 0;
                                    if (item.totalNetPrice === null) item.totalNetPrice = 0;
                                    if (item.totalDiscountPrice === null) item.totalDiscountPrice = 0;
                                    if (item.execItemQuantities === null) item.execItemQuantities = 0;
                                    if (item.totalPresentPrice === null) item.totalPresentPrice = 0;
                                    if (item.execPresentItemQuantities === null) item.execPresentItemQuantities = 0;
                                    if (item.totalSyfaNetPrice === null) item.totalSyfaNetPrice = 0;
                                    if (item.execQuantitiesSyfa === null) item.execQuantitiesSyfa = 0;
                                    if (item.totalMhsyfaNetPrice === null) item.totalMhsyfaNetPrice = 0;
                                    if (item.execQuantitiesMhSyfa === null) item.execQuantitiesMhSyfa = 0;
                                    if (item.totalIatrNetPrice === null) item.totalIatrNetPrice = 0;
                                    if (item.execQuantitiesIatr === null) item.execQuantitiesIatr = 0;
                                });
                            }
                            return data;
                        }
                    },
                    aggregate: [
                        {field: "numberOfCustomers", aggregate: "sum"},
                        {field: "numberOfPurchases", aggregate: "sum"},
                        {field: "totalNetPrice", aggregate: "sum"},
                        {field: "totalDiscountPrice", aggregate: "sum"},
                        {field: "execItemQuantities", aggregate: "sum"},
                        {field: "totalPresentPrice", aggregate: "sum"},
                        {field: "execPresentItemQuantities", aggregate: "sum"},
                        {field: "totalSyfaNetPrice", aggregate: "sum"},
                        {field: "execQuantitiesSyfa", aggregate: "sum"},
                        {field: "totalMhsyfaNetPrice", aggregate: "sum"},
                        {field: "execQuantitiesMhSyfa", aggregate: "sum"},
                        {field: "totalIatrNetPrice", aggregate: "sum"},
                        {field: "execQuantitiesIatr", aggregate: "sum"}
                    ]*!/
                },
                sortable: true,
                scrollable: false,
                pageable: true,
                groupable: true,
                columns: columns2
            });
        });*/

        vm.mainGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function (o) {
                        vm.showProgress = true;
                        $http.get("/api/purchase-items/all", {params: getFilters().data})
                            .success(function (response) {
                                o.success(response);
                                vm.showProgress = false;
                                console.log(response);
                            })
                            .error(function (error) {
                                o.error(error);
                                vm.showProgress = false;
                                Alerts.showAlert("Sigma SalesForce", "Λυπούμαστε. Κάποιο σφάλμα προσέκυψε!", "ΟΚ");
                                console.log(error);
                            });
                    }
                },
                group: [
                    {
                        field: "purchase.employee.fullName",
                        aggregates: [
                            { field: "quantity", aggregate: "sum" },
                            { field: "netPrice", aggregate: "sum" }
                        ]
                    },
                    {
                        field: "purchase.sector.name",
                        aggregates: [
                            { field: "quantity", aggregate: "sum" },
                            { field: "netPrice", aggregate: "sum" }
                        ]
                    },
                    {
                        field: "product.name",
                        aggregates: [
                            { field: "quantity", aggregate: "sum" },
                            { field: "netPrice", aggregate: "sum" }
                        ]
                    }
                ],
                aggregate: [
                    {field: "netPrice", aggregate: "sum"},
                    {field: "quantity", aggregate: "sum"}
                ],
                schema: {
                    data: function (data) {
                        if (data != null && data !== undefined && data.length > 0){
                            angular.forEach(data, function(item) {
                                item.purchase.employee.fullName = item.purchase.employee.account.lastName + " " + item.purchase.employee.account.firstName;
                                if (!item.purchase){
                                    item.purchase = {
                                        employee: { fullName: "" },
                                        sector: { name: "" }
                                    }
                                }else {
                                    if (!item.product){
                                        item.product = {
                                            name: "",
                                            productCategories: []
                                        }
                                    }
                                }
                            });
                        }

                        return data;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: "id",
                            issueDate: { type: 'date' }
                        }
                    }
                },
                serverFiltering: false,
                serverPaging: false,
                pageSize: 100
            }),
            sortable: false,
            groupable: false,
            filterable: false,
            resizable: false,
            pageable: true,
            scrollable: true,
            columns: [
                {
                    field: "purchase.employee.fullName",
                    title: "Ιατρικός Επισκέπτης",
                    groupHeaderTemplate: "<i class=\"material-icons\" style='font-size: 14px;'>assignment_ind</i> " +
                    "<span>#= typeof value !== 'undefined' ? value : '' # - </span> " +
                    "<span style='color: red'> Τεμάχια: #= aggregates.quantity.sum#, Τζίρος: #= kendo.format('{0:c}', aggregates.netPrice.sum)# </span>"
                },
                {
                    field: "purchase.sector.name",
                    title: "Επαγγελματίας Υγείας",
                    groupHeaderTemplate: "<i class=\"material-icons\" style='font-size: 14px;'>business</i> " +
                    "<span>#= typeof value !== 'undefined' ? value : '' # - </span>" +
                    "<span style='color: red'> Τεμάχια: #= aggregates.quantity.sum#, Τζίρος: #= kendo.format('{0:c}', aggregates.netPrice.sum)# </span>"
                },
                {
                    field: "product.name",
                    title: "Προιόν",
                    groupHeaderTemplate: "<i class=\"material-icons\" style='font-size: 14px;'>shopping_basket</i> " +
                    "<span>#= typeof value !== 'undefined' ? value : '' # - </span>" +
                    "<span style='color: red'> Τεμάχια: #= aggregates.quantity.sum#, Τζίρος: #= kendo.format('{0:c}', aggregates.netPrice.sum)# </span>"
                },
                {
                    field: "productCategories",
                    title: "Κατηγορία",
                    template: productCategoriesDisplay
                },
                {
                    field: "quantity",
                    title: "Τεμάχια",
                    template: "#= (quantity == null) ? '' : quantity#",
                    footerTemplate: "#=kendo.format('{0:n0}', sum)#",
                    aggregates: ["sum"]
                },
                {
                    field: "netPrice",
                    title: "Τζίρος",
                    template: "#= (netPrice == null) ? '' : kendo.format('{0:c}', netPrice)#",
                    footerTemplate: "#=kendo.toString(sum, 'C')#",
                    aggregates: ["sum"]
                }
            ],
            height: vm.contentHeight,
            noRecords: {
                template: "Δεν βρέθηκαν εγγραφές"
            },
            excel: {
                allPages: true,
                fileName: "ΓραμμέςΠαραγγελιών.xlsx"
            },
            excelExport: function (e) {
                if (vm.exportOnlyTotals){
                    // Check if user has applied at least 1 grouping into the grid, by checking the length of columns
                    // Same length means that the grid hasn't any grouping
                    if (e.workbook.sheets[0].columns.length !== vm.mainGridOptions.columns.length){
                        e.workbook.sheets[0].columns[0].autoWidth = true;
                        var result = CustomDataUtils.arrayRemove(e.workbook.sheets[0].rows, 'data'); // remove data
                        result = CustomDataUtils.arrayRemove(result, 'footer'); // also remove footer

                        //Define column headers
                        var index = result[0].cells.length;
                        while (index--) if (index < 6 && index !== 0) result[0].cells.splice(index, 1);
                        result[0].cells.splice(1, 0, {background: "#7a7a7a", color: "#fff", value: "Σύνολο Παραγγελιών", colSpan: 1, rowSpan: 1});

                        for (var i=0; i<result.length; i++){
                            if (result[i].type === 'group-header'){
                                var val = result[i].cells[result[i].cells.length - 1].value;
                                var _val = angular.copy(val);

                                //Get group column name
                                result[i].cells[0].value = CustomDataUtils.getGroupColumn(result[i].cells[0].value);
                                result[i].cells[0].colSpan = 0;

                                var values = CustomDataUtils.getValueFromHeaderLabel(val.substring(val.indexOf("Σύνολο: "))); // get val after a certain string
                                if ( values ) {
                                    for(var j = 0; j < values.length; j++) {
                                        result[i].cells.push({
                                            value: CustomDataUtils.convertToNumberFormat(values[j]),
                                            background: "#dfdfdf",
                                            color: "#333",
                                            colSpan: 1
                                        });
                                        if(result[i].cells.length > 2)
                                            result[i].cells[result[i].cells.length-1].format = "#,##0.00 €";
                                    }
                                }

                                // Keep only month in header labels
                                // result[i].cells[result[i].cells.length - 2].value = CustomDataUtils.removeTotalsFromHeaderLabel(String(_val));
                            }
                        }
                        e.workbook.sheets[0].rows = angular.copy(result);
                    } else {
                        e.preventDefault();
                        Alerts.showAlert("Sigma SalesForce", "Θα πρέπει να εφαρμόσετε τουλάχιστον 1 group by στη λίστα για να εξάγετε μόνο σύνολα. Ειδάλλως, κλείστε τον διακόπτη Εξαγωγή μόνο σύνολα και ξαναπροσπαθήστε.", "ΟΚ");
                    }
                } else {
                    var sheet = e.workbook.sheets[0];
                    var groupHeaders = vm.mainGrid.getOptions().dataSource.group.length;

                    //fix purchaseStatus column's width
                    sheet.columns[groupHeaders + 1].autoWidth = false;
                    sheet.columns[groupHeaders + 1].width = 160;

                    //fix date column's width
                    if (!groupHeaders.length) {
                        sheet.columns[0].autoWidth = false;
                        sheet.columns[0].width = 90;
                    }

                    //convert to number and set format
                    for (var rowIndex = 1; rowIndex < sheet.rows.length; rowIndex++) {
                        var row = sheet.rows[rowIndex];
                        for (var cellIndex = 5; cellIndex < row.cells.length; cellIndex ++) {
                            var cell = row.cells[groupHeaders + cellIndex];
                            if (cell) {
                                if (typeof cell.value === 'string' || cell.value instanceof String) cell.value = CustomDataUtils.convertToNumberFormat(cell.value);
                                cell.format = "#,##0.00 €";
                            }
                        }
                    }

                }
            }
        };

        vm.clearDatePickers = function () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        };

        vm.exportData = function () {
            var grid = $("#mainGrid").data("kendoGrid");
            grid.saveAsExcel();
        };

        function getFilters(){
            var filters = {
                data: {
                    "page": 1, // actually not used by the server
                    "pageSize": 20, // actually not used by the server
                    filter: {
                        logic: "and",
                        filters: [{
                            "operator": "from",
                            "value": $filter('date')(vm.masterFilters.from, 'yyyy-MM-dd'),
                            "field": "purchase.issueDate"
                        }, {
                            "field": "to",
                            "operator": $filter('date')(vm.masterFilters.to, 'yyyy-MM-dd'),
                            "value": "purchase.issueDate"
                        }]
                    }
                }
            };

            if (vm.purchaseStatusIdsString !== null){
                filters.data.filter.filters.push({
                    "operator": "in",
                    "field": "purchase.purchaseStatus.id",
                    "value": vm.purchaseStatusIdsString
                });
            }

            if (vm.selectedEmployeeId !== null){
                filters.data.filter.filters.push({
                    "operator": "eq",
                    "value": vm.selectedEmployeeId,
                    "field": "purchase.employee.id"
                });
            }

            if (vm.selectedSector !== null && vm.selectedSector.id){
                filters.data.filter.filters.push({
                    "operator": "eq",
                    "value": vm.selectedSector.id,
                    "field": "purchase.sector.id"
                });
            }

            if (vm.selectedProduct !== null){
                filters.data.filter.filters.push({
                    "operator": "eq",
                    "value": vm.selectedProduct.id,
                    "field": "product.id"
                });
            }

            if (vm.selectedProductCategoryId !== null){
                filters.data.filter.filters.push({
                    "operator": "in",
                    "value": vm.selectedProductCategoryId,
                    "field": "product.productCategories.id"
                });
            }

            return filters;
        }

        function productCategoriesDisplay(data) {
            if(data.product.productCategories) {
                return data.product.productCategories.map(function(elem) {
                    return elem.name
                }).join(', ');
            }
            return "";
        }

        function constructPurchaseStatusIdsString(){
            var idsString = null;
            if (purchaseStatuses !== null && purchaseStatuses.data && purchaseStatuses.data.content.length > 0){
                idsString = "";
                angular.forEach(purchaseStatuses.data.content, function (item) {
                    idsString += item.id + ",";
                });

                if (idsString.endsWith(",")) {
                    idsString = idsString.slice(0, -1);
                }
            }

            return idsString;
        }

        $(window).on("resize", function() {
            kendo.resize($("#mainGrid"));
        });

        // Click today when screen opens
        $timeout(function () {
            $("#today").click();
        });
    }
})();
