(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ChangeTableDetailController', ChangeTableDetailController);

    ChangeTableDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ChangeTable'];

    function ChangeTableDetailController($scope, $rootScope, $stateParams, entity, ChangeTable) {
        var vm = this;
        vm.changeTable = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:changeTableUpdate', function(event, result) {
            vm.changeTable = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
