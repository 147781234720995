/**
 * Created by Teo on 06/11/2019.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AssignationsController', AssignationsController);

    AssignationsController.$inject = ['$state'];

    function AssignationsController ($state) {
        var vm = this;
        vm.$state = $state;

    }
})();
