/**
 * Created by gmogas on 4/11/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('KendoService', KendoService);

    KendoService.$inject = ['$http', '$filter'];

    function KendoService($http, $filter) {

        var service = {
            initDefaultDataSource: function (url) {
                return new kendo.data.DataSource({
                    transport: {
                        read: function(o) {
                            if (o.data.filter == undefined) {
                                o.data.filter = { filters: [] };
                            }

                            o.data.page = 1;
                            o.data.pageSize = 100000;
                            $http.get( url,
                                {params: o.data})
                                .success(function (response) {
                                    o.success(response.content);
                                })
                                .error(function (response) {
                                    console.log(response);
                                });
                        }
                    }
                });
            },
            initDataSource: function (url, page, pageSize, filters, sort, initialValue) {
                if (!filters) filters = [];
                return new kendo.data.DataSource({
                    transport: {
                        read: function(o) {
                            if (o.data.filter == undefined) {
                                o.data.filter = { filters: [] };
                            }

                            if (filters) {
                                angular.forEach( filters, function(item) {
                                    o.data.filter.filters = o.data.filter.filters.concat(
                                        [ item ]
                                    );
                                })
                            }

                            if (sort) {
                                o.data.sort = sort;
                            }

                            o.data.page = page;
                            o.data.pageSize = pageSize;
                            $http.get( url,
                                {params: o.data})
                                .success(function (response) {
                                    o.success(response.content);
                                })
                                .error(function (response) {});
                        }
                    },
                    //**************** Remove this because Kendo searchable dropdown stop functioning  *****************************//
                    /*schema: {
                        data: function (data) {
                            if (data.content !== undefined && initialValue) {
                                var item = $filter('filter')(data.content, {id: initialValue.id}, true);
                                if (!item.length) {
                                    data.content.push(initialValue);
                                }
                            }
                            return data.content;
                        }
                    },*/
                    //**************** / Remove this because Kendo searchable dropdown stop functioning  *****************************//
                    serverFiltering: true
                });
            },
            initDefaultOptions: function (dataSource, filter, dataTextField, dataValueField, valuePrimitive) {
                return {
                    dataSource: dataSource,
                    filter: filter,
                    valuePrimitive: valuePrimitive === undefined ? true : valuePrimitive,
                    dataTextField: dataTextField,
                    dataValueField: dataValueField
                }
            }
        };

        return service;

    }
})();
