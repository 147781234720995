/**
 * Created by Teo on 07/08/2019.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('employeeCustomerSectors', {
                parent: 'adminEmployeeView',
                url: '/customer-sectors',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR', 'ROLE_ACCOUNTING_DEP', 'ROLE_REC_ADMINISTRATOR', 'ROLE_PHARM_SUPERVISOR'],
                    pageTitle: 'sigmaCrmApp.customer.home.title'
                },
                templateUrl: 'app/displays/adminEmployees/customerSectors/customerSectors.html',
                controller: 'AdminEmployeeViewCustomerSectorsController',
                controllerAs: 'vm',
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    customersView: ['ApplicationParameters', function (ApplicationParameters) {
                        return ApplicationParameters.customersView();
                    }],
                    selectedEmployee: ['$http', '$stateParams', function ($http, $stateParams) {
                        return $http.get('api/employees/' + $stateParams.id).then(function (response) {
                            return response.data;
                        });
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
