(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('trade-policy-rule-effect', {
            parent: 'entity',
            url: '/trade-policy-rule-effect?page&sort&search',
            data: {
                authorities: ['ROLE_SYSTEM'],
                pageTitle: 'sigmasfApp.tradePolicyRuleEffect.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/trade-policy-rule-effect/trade-policy-rule-effects.html',
                    controller: 'TradePolicyRuleEffectController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tradePolicyRuleEffect');
                    $translatePartialLoader.addPart('tradePolicyRuleEffectTypeEnum');
                    $translatePartialLoader.addPart('tradePolicyRuleEffectSeverityEnum');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('trade-policy-rule-effect-detail', {
            parent: 'entity',
            url: '/trade-policy-rule-effect/{id}',
            data: {
                authorities: ['ROLE_SYSTEM'],
                pageTitle: 'sigmasfApp.tradePolicyRuleEffect.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/trade-policy-rule-effect/trade-policy-rule-effect-detail.html',
                    controller: 'TradePolicyRuleEffectDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tradePolicyRuleEffect');
                    $translatePartialLoader.addPart('tradePolicyRuleEffectTypeEnum');
                    $translatePartialLoader.addPart('tradePolicyRuleEffectSeverityEnum');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'TradePolicyRuleEffect', function($stateParams, TradePolicyRuleEffect) {
                    return TradePolicyRuleEffect.get({id : $stateParams.id});
                }]
            }
        })
        .state('trade-policy-rule-effect.new', {
            parent: 'trade-policy-rule-effect',
            url: '/new',
            data: {
                authorities: ['ROLE_SYSTEM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trade-policy-rule-effect/trade-policy-rule-effect-dialog.html',
                    controller: 'TradePolicyRuleEffectDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                effectType: null,
                                severity: null,
                                discountPercent: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('trade-policy-rule-effect', null, { reload: true });
                }, function() {
                    $state.go('trade-policy-rule-effect');
                });
            }]
        })
        .state('trade-policy-rule-effect.edit', {
            parent: 'trade-policy-rule-effect',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_SYSTEM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trade-policy-rule-effect/trade-policy-rule-effect-dialog.html',
                    controller: 'TradePolicyRuleEffectDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TradePolicyRuleEffect', function(TradePolicyRuleEffect) {
                            return TradePolicyRuleEffect.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('trade-policy-rule-effect', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('trade-policy-rule-effect.delete', {
            parent: 'trade-policy-rule-effect',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_SYSTEM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trade-policy-rule-effect/trade-policy-rule-effect-delete-dialog.html',
                    controller: 'TradePolicyRuleEffectDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['TradePolicyRuleEffect', function(TradePolicyRuleEffect) {
                            return TradePolicyRuleEffect.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('trade-policy-rule-effect', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
