(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProductLotDetailController', ProductLotDetailController);

    ProductLotDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ProductLot', 'Product'];

    function ProductLotDetailController($scope, $rootScope, $stateParams, entity, ProductLot, Product) {
        var vm = this;
        vm.productLot = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:productLotUpdate', function(event, result) {
            vm.productLot = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
