/**
 * Created by Kristy on 30/9/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PendingPatchReportsController', PendingPatchReportsController);

    PendingPatchReportsController.$inject = ['$http', '$scope', '$q','$filter', 'PatchReportGridsService', 'NotificationOptions', 'DetailGridsService', 'PatchItemMasterFilter', 'DataUtils', 'PatchReportPhoto', 'KendoFilter'];

    function PendingPatchReportsController ($http, $scope, $q, $filter, PatchReportGridsService, NotificationOptions, DetailGridsService, PatchItemMasterFilter, DataUtils, PatchReportPhoto, KendoFilter) {
        var vm = this;
        vm.detailGridsService = DetailGridsService;
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");
        // vm.executePatchReport = function(e) {
        //     e.preventDefault();
        //     // e.target is the DOM element representing the button
        //     var tr = $(e.target).closest("tr"); // get the current table row (tr)
        //     // get the data bound to the current table row
        //     var data = this.dataItem(tr);
        //
        //     $http.put('api/patch-reports/' + data.id + '/execute', data)
        //         .success(function (response,status,headers) {
        //             notification.show({
        //                 message: "Η εγγραφή εκτελέστηκε με επιτυχία!"
        //             }, "success");
        //             vm.pendingGridOptions.dataSource.read();
        //         })
        //         .error(function (response) {
        //             notification.show({
        //                 message: "Η ενημέρωση των στοιχείων απέτυχε!"
        //             }, "error");
        //             console.log(response);
        //         });
        // };

        vm.openPhotoWindow = function (e) {
            e.preventDefault();
            // e.target is the DOM element representing the button
            var tr = $(e.target).closest("tr"); // get the current table row (tr)
            // get the data bound to the current table row
            vm.photo = this.dataItem(tr);

            vm.photoWindow.title(vm.photo.patchPhotoName);
            vm.photoWindow.center().open();
            vm.canceler = $q.defer();
            $http.get('api/patch-photos/' + vm.photo.patchPhotoName, {timeout: vm.canceler.promise})
                .success(function (response,status,headers) {
                    vm.patchPhoto = response;
                })
                .error(function (response) {
                    console.log(response);
                });
        };

        vm.closePhotoWindow = function () {
            vm.canceler.resolve();
            delete vm.patchPhoto;
        };

        vm.savePhoto = function () {
            var dataURI = "data:image/png;base64," + vm.patchPhoto;
            kendo.saveAs({
                dataURI: dataURI,
                fileName: vm.photo.patchPhotoName
            });
        };

        vm.pendingGridOptions = PatchReportGridsService.getGridOptions('pending');

        vm.onSelectImage = function(e) {
            var file = e.files[0].rawFile;
            console.log(file);
        };

        vm.patchPhotoTypeDataSource = [
            { id: 'injury', name: 'Τραύμα' },
            { id: 'booklet', name: 'Βιβλιάριο' },
            { id: 'report', name: 'Χαρτί γνωμάτευσης' }
        ];

        vm.setPatchPhoto = function ($file) {

            if ($file) {
                if ($file.size > 1024000) {
                    notification.show({
                        message: "Το αρχείο δεν πρέπει να ξεπερνάει τα 500kB!"
                    }, "error");
                    return;
                }
            }

            if(!$scope.$$phase) {
                $scope.$apply(function() {
                    vm.patchPhotoName = $file.name;
                });
            }

            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    vm.patchReportPhoto = base64Data;
                });
            }
        };

        vm.deletePatchPhoto = function() {
            delete vm.patchReportPhoto;
            delete vm.patchPhotoName;
        };

        vm.gridColumns = [
            {field: "patchPhotoName", title: "Όνομα αρχείου"},
            {field: "patchPhotoType", title: "Τύπος φωτογραφίας",
                template: "#= (patchPhotoType == 'injury') ? 'Τραύμα' : (patchPhotoType == 'booklet') ? 'Βιβλιάριο' : (patchPhotoType == 'report') ? 'Χαρτί γνωμάτευσης' : ''#"},
            {
                command:[
                    { name:"show", text: "Προβολή", click: vm.openPhotoWindow }
                ],
                title: "&nbsp",
                width:90
            }
        ];


        // Principal.hasAuthority('ROLE_MANAGEMENT').then( function (result) {
        //     if (result) {
        //         vm.gridColumns.unshift({
        //             command:[
        //                 { name:"execute", text: "Εκτέλεση", click: vm.executePatchReport }
        //             ],
        //             attributes: { class: "#= 'green-color' #" },
        //             title: "&nbsp",
        //             width:90
        //         });
        //
        //         vm.pendingGrid.setOptions({
        //             columns: vm.gridColumns
        //         });
        //     }
        // });
    }
})();
