/**
 * Created by Maria on 11/5/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminCycleReportController', AdminCycleReportController);

    AdminCycleReportController.$inject = ['$scope', '$state', '$filter', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants','$http', '$rootScope', '$location', 'KendoFilter'];

    function AdminCycleReportController ($scope, $state, $filter, ParseLinks, AlertService, pagingParams, paginationConstants, $http, $rootScope, $location, KendoFilter) {
        var vm = this;

        vm.pageHeading = 'Πλανο Ενημερωσης';

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        $http.get('/api/customers/cycle/report',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (data, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        if(data.content != undefined) {
                            data.content.forEach(function(item){

                                if(item.doctorSpecialty === null){
                                    item.doctorSpecialty = {};
                                    item.doctorSpecialty.name = "";
                                }

                                if(item.customerCategory === null){
                                    item.customerCategory = {};
                                    item.customerCategory.name = "";
                                }

                                if(item.dayTime !== null){
                                    if(item.dayTime === "day") {
                                        item.dayTime = "ΠΡΩΙΝΌ";
                                    }else if(item.dayTime === "night"){
                                        item.dayTime = "ΑΠΟΓΕΥΜΑΤΙΝΌ";
                                    }
                                }

                                if(item.subcategory !== null){
                                    if(item.subcategory === "one") {
                                        item.subcategory = 1;
                                    }else if(item.subcategory === "two"){
                                        item.subcategory = 2;
                                    }
                                }

                                if(item.lastEventStartedAt && item.customerCategory){
                                    if(item.customerCategory.circleDaysNo) {
                                        var newDate = new Date();
                                        item.lastEventStartedAt = new Date(item.lastEventStartedAt);
                                        newDate.setDate(newDate.getDate()-item.customerCategory.circleDaysNo);

                                        var timeDiff = Math.abs(newDate.getTime() - item.lastEventStartedAt.getTime());
                                        item.circleDaysNoEvent = Math.ceil(timeDiff / (1000 * 3600 * 24));
                                    }
                                }

                            });
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true},
                            isActive:{
                                type: "boolean",
                                defaultValue: true,
                                parse: function(value) {
                                    return !!(value == "1" || value == "true" || value == true);

                                }
                            },
                            saw:{
                                type: "boolean",
                                defaultValue: true,
                                parse: function(value) {
                                    return !!(value == "1" || value == "true" || value == true);

                                }
                            },
                            doctorSpecialty:{defaultValue: {id: null, name : null}},
                            customerCategory:{defaultValue: { id : null, name : null}},
                            subcategory:{defaultValue: null},
                            dayTime:{defaultValue: null},
                            lastEventStartedAt: { type: 'date'},
                            circleDaysNoEvent: { type: 'number', defaultValue: null }
                        }
                    }
                },
                pageSize: 100,
                serverPaging: true,
                serverSorting: true,
                scrollable:false,
                serverFiltering :true
            }),
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            columns: [
                {
                    field: "lastName",
                    title: "Επώνυμο",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:140
                },
                {
                    field: "firstName",
                    title: "Όνομα",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "doctorSpecialty.name",
                    title: "Ειδικοτητα 1η",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "customerCategory.name",
                    title: "Κατηγορία",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "subcategory",
                    title: "Υποκατηγορία",
                    template: "#= (subcategory == 1) ? '1' : (subcategory == 2) ? '2' : '' #",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            showOperators: false,
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "1", value: "one" },
                                            { text: "2", value: "two" }]
                                    }),
                                    valuePrimitive: true

                                })
                            }
                        }
                    }
                },
                { field: "lastEventStartedAt", title: "Ημερομηνία Τελευταίας Επίσκεψης",
                    template: "#= lastEventStartedAt ? kendo.toString(kendo.parseDate(lastEventStartedAt), 'dddd, dd/MM/yyyy HH:mm') : ''#",
                    filterable: false,
                    sortable: false
                },
                { field: "circleDaysNoEvent", title: "Ημέρες Χωρίς Επίσκεψης",
                    filterable: false,
                    sortable: false }
            ],
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }]
        };

        vm.detailedGridOptions = function (dataItem) {

            if (dataItem != undefined) {
                return {
                    dataSource: {
                        batch: true,
                        transport: {
                            read: function(o) {
                                var url = '/api/customers/' + dataItem.id + '/employees';

                                $http.get(url)
                                    .success(function (response,status,headers) {
                                        vm.total = headers()['x-total-count'];
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        },
                        schema: {
                            data: function (data) {
                                if (data != undefined) {
                                    data.forEach(function (item) {

                                        if (!item.account) {
                                            item.account = {
                                                firstName: "",
                                                lastName: "",
                                                login: ""
                                            };
                                        }

                                        if(item.lastEventStartedAt && dataItem.customerCategory){
                                            if(dataItem.customerCategory.circleDaysNo) {
                                                var newDate = new Date();
                                                item.lastEventStartedAt = new Date(item.lastEventStartedAt);
                                                newDate.setDate(newDate.getDate()-dataItem.customerCategory.circleDaysNo);

                                                var timeDiff = Math.abs(newDate.getTime() - item.lastEventStartedAt.getTime());
                                                item.circleDaysNoEvent = Math.ceil(timeDiff / (1000 * 3600 * 24));
                                            }
                                        }
                                    });
                                }
                                return data;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: { editable: false, nullable: false },
                                    account: { defaultValue: {} },
                                    lastEventStartedAt: { type: 'date'},
                                    circleDaysNoEvent: { type: 'number', defaultValue: null }
                                }
                            }
                        },
                        pageSize: 15
                    },
                    pageable: true,
                    columns: [
                        {
                            field: "account.login",
                            title: "Όνομα σύνδεσης"
                        },
                        {
                            field: "account.firstName",
                            title: "Όνομα"
                        },
                        {
                            field: "account.lastName",
                            title: "Επώνυμο"
                        },
                        { field: "lastEventStartedAt", title: "Ημερομηνία Τελευταίας Επίσκεψης",
                            template: "#= lastEventStartedAt ? kendo.toString(kendo.parseDate(lastEventStartedAt), 'dddd, dd/MM/yyyy HH:mm') : ''#",
                            filterable: false,
                            sortable: false
                        },
                        { field: "circleDaysNoEvent", title: "Ημέρες Χωρίς Επίσκεψης",
                            filterable: false,
                            sortable: false }
                    ]
                };
            }
        };
    }
})();
