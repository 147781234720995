(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CountryPhoneCodeDetailController', CountryPhoneCodeDetailController);

    CountryPhoneCodeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'CountryPhoneCode'];

    function CountryPhoneCodeDetailController($scope, $rootScope, $stateParams, entity, CountryPhoneCode) {
        var vm = this;
        vm.countryPhoneCode = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:countryPhoneCodeUpdate', function(event, result) {
            vm.countryPhoneCode = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
