(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('RejectionReasonDeleteController',RejectionReasonDeleteController);

    RejectionReasonDeleteController.$inject = ['$uibModalInstance', 'entity', 'RejectionReason'];

    function RejectionReasonDeleteController($uibModalInstance, entity, RejectionReason) {
        var vm = this;
        vm.rejectionReason = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            RejectionReason.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
