(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CustomerDetailController', CustomerDetailController);

    CustomerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'Customer', 'DoctorSpecialty', 'CustomerCategory', 'CustomerSector', 'CustomerTitle', 'CustomerSubCategory', 'CustomerType', 'Collaboration'];

    function CustomerDetailController($scope, $rootScope, $stateParams, DataUtils, entity, Customer, DoctorSpecialty, CustomerCategory, CustomerSector, CustomerTitle, CustomerSubCategory, CustomerType, Collaboration) {
        var vm = this;
        vm.customer = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:customerUpdate', function(event, result) {
            vm.customer = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
    }
})();
