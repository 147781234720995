/**
 * Created by gmogas on 11/1/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchasesController', PurchasesController);

    PurchasesController.$inject = ['$http','$state','$filter', 'Principal', 'KendoService', 'KendoFilter','KendoGrid', 'ApplicationParameters', 'CustomUtils'];

    function PurchasesController ($http, $state, $filter, Principal, KendoService, KendoFilter, KendoGrid, ApplicationParameters, CustomUtils) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(64);
        ApplicationParameters.hasModule('Purchase_Pharmacies', true);
        vm.principal = Principal;
        vm.pageHeading = 'Παραγγελιες';
        vm.disabled = false;
        vm.validation = true;
        var purchasesGrid = angular.element('#purchasesGrid');
        var dataSource = {};
        vm.showProgress = false;

        dataSource.purchaseStatusDataSource = KendoService.initDefaultDataSource('/api/masterdata/purchase-statuses');
        vm.purchaseStatusOptions = KendoService.initDefaultOptions(dataSource.purchaseStatusDataSource, "startswith", "name", "id");
        vm.purchaseStatusOptions.change = function() { KendoGrid.refresh(vm.purchasesGridOptions); };
        vm.purchaseStatusOptions.dataBound = function () {
            var self = this;
            Principal.hasAuthority('ROLE_STOREHOUSE').then( function (result) {
                if (result) {
                    self.select(3);
                    self.trigger("change");
                }
                vm.disabled = !!result;
                KendoGrid.refresh(vm.purchasesGridOptions);
            });
        };

        function getPurchaseAddress (sector) {
            return {
                shippingAddress: sector.address,
                shippingAddressNum:  secto.addressNum,
                shippingCityName:  sector.cityName,
                shippingPrefectureName:  sector.prefectureName,
                shippingCountryName:  sector.countryName,
                shippingPostalCode:  sector.postalCode
            }
        }

        vm.clearDatePickers = function () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        };

        vm.masterFiltersOnClick = function (e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id == "today") {

                vm.clearDatePickers();

                vm.from = date;
                vm.to = date;

            }
            else if (e.id == "week") {

                vm.clearDatePickers();

                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6

                vm.from = firstWeekDay;
                vm.to = lastWeekDay;

            }
            else if (e.id == "month") {

                vm.clearDatePickers();

                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                vm.from = firstMonthDay;
                vm.to = lastMonthDay;
            }

            purchasesGrid.data("kendoGrid").dataSource.read();
        };

        vm.purchasesColumns = [];
        if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_ACCOUNTING_DEP'])){
            vm.purchasesColumns = [
                {
                    field: "issueDate",
                    title: "Ημερομηνία",
                    template: "#= kendo.toString(kendo.parseDate(issueDate), 'dddd, dd/MM/yyyy')#",
                    filterable: {
                        cell: {
                            operator: "eqdateonly",
                            showOperators: false
                        }
                    }/*,
                    width: 50*/
                },
                {
                    field: "purchaseStatus.name",
                    title: "Κατάσταση",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }/*,
                    width: 60*/
                },
                {
                    field: "employee.account.firstName",
                    title: "Όνομα Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }/*,
                    width: 70*/
                },
                {
                    field: "employee.account.lastName",
                    title: "Επίθετο Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }/*,
                    width: 70*/
                },
                {
                    field: "sector.name",
                    title: "Επαγγελματίας Υγείας",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }/*,
                    width: 120*/
                },
                {
                    field: "sector.vat",
                    title: "Α.Φ.Μ Επαγγελματία Υγείας",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }/*,
                    width: 40*/
                },
                {
                    field: "sector.companyType.name",
                    title: "Εταιρική μορφή",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }/*,
                    width: 60*/
                },
                {
                    field: "comment",
                    title: "Σχόλια",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }/*,
                    width: 100*/
                },
                {
                    field: "finalPrice",
                    title: "Σύνολο",
                    template: "#= kendo.format('{0:c}', finalPrice || 0) #",
                    filterable: {
                        cell: {
                            operator: "eq",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }/*,
                    width: 50*/
                },
                {
                    field: "picker.account.firstName",
                    title: "Όνομα Αποθηκάριου",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }/*,
                    width: 70*/
                },
                {
                    field: "picker.account.lastName",
                    title: "Επίθετο Αποθηκάριου",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }/*,
                    width: 70*/
                },
                {
                    field: "eventComments",
                    title: "Σχόλια Event",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }/*,
                    width: 100*/
                },
                { command: { text: "ΕΠΕΞΕΡΓΑΣΙΑ ΑΠΟ \n ΛΟΓΙΣΤΗΡΙΟ", click: accountingEdit }, title: " ", width: "180px" }
            ];
        }else if (Principal.hasAnyAuthority(['ROLE_STOREHOUSE'])){
            vm.purchasesColumns = [
                {
                    field: "issueDate",
                    title: "Ημερομηνία",
                    template: "#= kendo.toString(kendo.parseDate(issueDate), 'dddd, dd/MM/yyyy')#",
                    filterable: {
                        cell: {
                            operator: "eqdateonly",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "purchaseStatus.name",
                    title: "Κατάσταση",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "employee.account.firstName",
                    title: "Όνομα",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "employee.account.lastName",
                    title: "Επίθετο",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "sector.name",
                    title: "Επαγγελματίας Υγείας",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "sector.vat",
                    title: "Α.Φ.Μ Επαγγελματία Υγείας",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "sector.companyType.name",
                    title: "Εταιρική μορφή",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "comment",
                    title: "Σχόλια",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "picker.account.firstName",
                    title: "Όνομα Αποθηκάριου",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "picker.account.lastName",
                    title: "Επίθετο Αποθηκάριου",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "finalPrice",
                    title: "Σύνολο",
                    template: "#= kendo.format('{0:c}', finalPrice || 0) #",
                    filterable: {
                        cell: {
                            operator: "eq",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "eventComments",
                    title: "Σχόλια Event",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                }
            ];
        } else {
            vm.purchasesColumns = [
                {
                    field: "issueDate",
                    title: "Ημερομηνία",
                    template: "#= kendo.toString(kendo.parseDate(issueDate), 'dddd, dd/MM/yyyy')#",
                    filterable: {
                        cell: {
                            operator: "eqdateonly",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "purchaseStatus.name",
                    title: "Κατάσταση",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "employee.account.firstName",
                    title: "Όνομα",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "employee.account.lastName",
                    title: "Επίθετο",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "sector.name",
                    title: "Επαγγελματίας Υγείας",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "sector.vat",
                    title: "Α.Φ.Μ Επαγγελματία Υγείας",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "sector.companyType.name",
                    title: "Εταιρική μορφή",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "comment",
                    title: "Σχόλια",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "finalPrice",
                    title: "Σύνολο",
                    template: "#= kendo.format('{0:c}', finalPrice || 0) #",
                    filterable: {
                        cell: {
                            operator: "eq",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "picker.account.firstName",
                    title: "Όνομα Αποθηκάριου",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "picker.account.lastName",
                    title: "Επίθετο Αποθηκάριου",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "eventComments",
                    title: "Σχόλια Event",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
            ];
        }

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ]
                },
                { type: "separator" },
                { type: "separator" },
                { template: "<label>Από: </label>" },
                {
                    template: "<input kendo-date-picker id='from' options='vm.fromOptions'/>",
                    overflow: "never"
                },
                { template: "<label>Εώς: </label>" },
                {
                    template: "<input kendo-date-picker id='to' options='vm.toOptions'/>",
                    overflow: "never"
                },
                {
                    template: "<label>Κατάσταση: </label><select id='purchaseStatus' name='purchaseStatus' class='inline' kendo-combo-box='vm.purchaseStatusCombo' " +
                    "k-options='vm.purchaseStatusOptions' k-ng-model='vm.purchaseStatus'></select>",
                    overflow: "never", attributes: { class: "left" }
                }
            ]
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.from = value;
                }

                vm.masterFiltersOnClick(e);
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.to = value;
                }

                vm.masterFiltersOnClick(e);
            }
        };

        vm.onAddPurchaseClick = function () {
            $state.go('purchaseNew');
        };

        vm.onExcelExportClick = function () {
            const grid = $("#purchasesGrid").data("kendoGrid");
            grid.saveAsExcel();
            vm.showProgress = true;
        };

        const exportedColumns = [
            "issueDate",
            "employee.account.firstName",
            "employee.account.lastName",
            "sector.name", "sector.vat",
            "purchaseStatus.name",
            "comment"
        ];
        var exportFlag = false;
        vm.purchasesGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        //master filters functionality
                        if(vm.from && vm.to) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "issueDate", "operator": "fromdate",
                                        "value": vm.from },
                                    { "field": "issueDate", "operator": "todate",
                                        "value": vm.to }
                                ]
                            );
                        }

                        if (vm.purchaseStatus) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "purchaseStatus.id", "operator": "eq",
                                        "value": vm.purchaseStatus }
                                ]
                            );
                        }

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                            }
                        });

                        $http.get('/api/purchases/search',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    create: function(o) {
                        var data = o.data.models[0];
                        data.picker = null;
                        data.employee = vm.employee;
                        if(!data.paymentMethod.id) data.paymentMethod = null;

                        angular.extend(data, getPurchaseAddress(data.sector));

                        $http.post('/api/purchases', data)
                            .success(function (response) {
                                o.success(response);

                                var purchaseItems = vm.purchaseItemsOptions.dataSource.data();
                                angular.forEach( purchaseItems, function (item) {
                                    item.quantityExecuted = 0;
                                });
                                angular.forEach(purchaseItems, function(item) {
                                    item.purchase = { id: response.id };
                                });

                                $http.post('/api/purchase-items/list', purchaseItems)
                                    .success(function (response) {
                                        o.success(response);
                                        KendoGrid.refresh(vm.purchasesGridOptions);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema : {
                    data: function (data) {
                        if(data.content) {
                            angular.forEach(data.content, function(item) {
                                if(!item.picker) item.picker = { account: {} };
                                if(!item.purchaseStatus) item.purchaseStatus = { name: "" };
                                if(!item.sector) item.sector = { name: "" };
                                if(!item.sector.companyType) item.sector.companyType = { name: "" };
                            })

                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: "id",
                            issueDate: { type: 'date', defaultValue: new Date()},
                            purchaseStatus: { defaultValue: { id: null, name: "" }},
                            paymentMethod: { defaultValue: { id: null, name: "" }},
                            employee: { defaultValue: { account: { firstName: "", lastName: "" } } },
                            sector: { defaultValue: { name: "", companyType: {name:""} }},
                            picker: { defaultValue: { account: { firstName: "", lastName: "" } } },
                            purchaseNumberingRow: { defaultValue: { id: null, name: "" }}
                        }
                    }
                },
                pageSize: 100,
                serverPaging: true,
                serverSorting: true,
                sort: { field: "issueDate", dir: "desc" },
                scrollable:false,
                serverFiltering :true
            }),
            sortable: true,
            pageable: {
                messages: {
                    display: "{0} - {1} από {2} Παραγγελίες", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                    empty: "Δεν βρέθηκαν Παραγγελίες",
                    page: "Σελίδα",
                    allPages: "Όλα",
                    of: "από {0}", // {0} is total amount of pages.
                    itemsPerPage: "εγγραφές ανά σελίδα",
                    first: "Πήγαινε στη πρώτη σελίδα",
                    previous: "Πήγαινε στη τελευταία σελίδα",
                    next: "Πήγαινε στην επόμενη σελίδα",
                    last: "Πήγαινε στη τελευταία σελίδα",
                    refresh: "Ανανέωση"
                }
            },
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: true,
            height: vm.contentHeight,
            columns:  vm.purchasesColumns,
            noRecords: {
                template: "Δεν βρέθηκαν Παραγγελίες"
            },
            excel: {
                allPages: true,
                fileName: "Παραγγελίες.xlsx"
            },
            excelExport: function (e) {
                if (!exportFlag){
                    for (var i=0; i<e.sender.columns.length; i++) {
                        if (exportedColumns.indexOf(e.sender.columns[i].field) === -1) {
                            e.sender.hideColumn(i);
                        }
                    }

                    e.preventDefault();
                    exportFlag = true;
                    setTimeout(function () {
                        e.sender.saveAsExcel();
                    });
                }else {
                    for (var j=0; j<e.sender.columns.length; j++) {
                        if (exportedColumns.indexOf(e.sender.columns[j].field) === -1) {
                            e.sender.showColumn(j);
                        }
                    }
                    exportFlag = false;
                    vm.showProgress = false;
                }
            }
        };

        vm.onSelection = function(data) {
            var selectedId = data.id;
            $state.go('purchaseItems', {id: selectedId});
        };

        function accountingEdit(e){
            e.preventDefault();
            var dataItem = this.dataItem($(e.currentTarget).closest("tr"));
            $state.go('purchaseAccountingView', {id: dataItem.id});
        }
    }
})();
