(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('TaxStatusClassDetailController', TaxStatusClassDetailController);

    TaxStatusClassDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TaxStatusClass', 'TaxClass', 'TaxStatus'];

    function TaxStatusClassDetailController($scope, $rootScope, $stateParams, entity, TaxStatusClass, TaxClass, TaxStatus) {
        var vm = this;
        vm.taxStatusClass = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:taxStatusClassUpdate', function(event, result) {
            vm.taxStatusClass = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
