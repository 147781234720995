(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('TempSectorDetailController', TempSectorDetailController);

    TempSectorDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TempSector', 'SectorType', 'DoctorSpecialty', 'Sector', 'CustomerSector', 'Country', 'Region', 'Prefecture', 'City', 'CompanyType', 'TaxOffice', 'TaxStatus', 'SectorCategory'];

    function TempSectorDetailController($scope, $rootScope, $stateParams, entity, TempSector, SectorType, DoctorSpecialty, Sector, CustomerSector, Country, Region, Prefecture, City, CompanyType, TaxOffice, TaxStatus, SectorCategory) {
        var vm = this;
        vm.tempSector = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:tempSectorUpdate', function(event, result) {
            vm.tempSector = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
