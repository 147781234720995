/**
 * Created by Teo on 24/07/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('leadManagementAggregated', {
                    abstract: true,
                    parent: 'app',
                    url: '/leadManagement/aggregated',
                    data: {
                        authorities: ['ROLE_SYSTEM', 'ROLE_REC_MANAGEMENT', 'ROLE_REC_ADMINISTRATOR', 'ROLE_ADMIN'],
                        pageTitle: 'sigmaCrmApp.prospectivePatients.home.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/displays/adminIndex/leadManagementAggregated/leadManagementAggregated.html',
                            controller: 'LeadManagementAggregatedController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        search: null
                    },
                    resolve: {
                        pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                            return {
                                search: $stateParams.search
                            };
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                })

    }

})();
