/**
 * Created by Kristy on 10/5/2018.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProspectivePatientViewInfoController', ProspectivePatientViewInfoController);

    ProspectivePatientViewInfoController.$inject = [ '$http', '$filter', '$timeout', '$state', 'KendoService', 'NotificationOptions', 'Alerts', 'KendoNotifications', 'entity', 'ProspectivePatient', 'Attendant', 'Patient', 'professions', 'relationshipTypes', 'CustomUtils'];

    function ProspectivePatientViewInfoController ( $http, $filter, $timeout, $state, KendoService, NotificationOptions, Alerts, KendoNotifications, entity, ProspectivePatient, Attendant, Patient, professions, relationshipTypes, CustomUtils) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(58);
        var dataSource = {};
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");
        vm.professions = professions;
        vm.relationshipTypes = relationshipTypes;
        vm.showAttendantLayout = false;
        vm.showAttendantActions = true;
        vm.showAttendantSecondaryActions = false;
        vm.showConvertButton = false;
        vm.prospectivePatient = entity;
        vm.attendant = {};
        if (vm.prospectivePatient.attendant){
            vm.attendant = vm.prospectivePatient.attendant;
            setAttendantFullName();
        }

        //Attendants Combobox
        dataSource.attendant = new kendo.data.DataSource({
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: function (o) {
                    var url = '/api/attendants/search';
                    if (o.data.filter === undefined) o.data.filter = { filters: [] };

                    o.data.page = 1;
                    o.data.pageSize = 1;
                    $http.get(url, {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            },
            schema: {
                parse: function (data) {
                    var attendant = $filter('filter')(data, {id: vm.prospectivePatient.attendant.id}, true);
                    if (!attendant.length) {
                        data.push(vm.prospectivePatient.attendant);
                    }
                    data.forEach(function (item) {
                        item.fullName = item.lastName + " " + item.firstName
                    });
                    return data;
                }
            }
        });
        vm.attendantOptions = KendoService.initDefaultOptions(dataSource.attendant, "startswith", "fullName", "id", false);
        vm.attendantOptions.placeholder = "Επιλογή συνοδού";

        //Professions Combobox
        dataSource.professions = KendoService.initDefaultDataSource('/api/professions/search');
        vm.professionOptions = KendoService.initDefaultOptions(dataSource.professions, "startswith", "name", "id", false);

        vm.update = function() {
            if (vm.validator.validate()) {
                vm.prospectivePatient.attendant = vm.attendant.id > 0 ? vm.attendant : null;
                ProspectivePatient.update({}, vm.prospectivePatient,
                    function () {
                        KendoNotifications.onSaveSuccess(notification)
                    },
                    function () {
                        KendoNotifications.onSaveError(notification)
                    })
            } else {
                KendoNotifications.onSaveError(notification);
            }
        };

        vm.onAttendantActionButtonsClick = function (action, ev) {
            switch (action){
                case "add":
                    if (vm.attendant.id > 0){
                        // Ask if there is an attendant
                        Alerts.showConfirmDialog(ev, "Sigma SalesForce", "Προσθήκη νέου Συνοδού? Ο παλιός θα διαγραφεί!", true, "ΝΑΙ", "OXI", function () {
                            // Positive
                            vm.attendant = {};
                            vm.showAttendantLayout = true;
                            vm.showAttendantActions = false;
                            vm.showAttendantSecondaryActions = true;
                        }, function () {
                            // Negative
                        });
                    }else {
                        vm.showAttendantLayout = true;
                        vm.showAttendantActions = false;
                        vm.showAttendantSecondaryActions = true;
                    }
                    break;
                case "edit":
                    vm.showAttendantLayout = true;
                    vm.showAttendantActions = false;
                    vm.showAttendantSecondaryActions = true;
                    break;
                case "delete":
                    Alerts.showConfirmDialog(ev, "Sigma SalesForce", "Διαγραφή Συνοδού?", true, "ΝΑΙ", "OXI", function () {
                        // Positive
                        vm.attendant = {};
                    }, function () {
                        // Negative
                    });
                    break;
                case "save":
                    if (vm.attendantValidator.validate()){
                        Attendant.update({}, vm.attendant,
                            function (result) {
                                KendoNotifications.showSuccessNotification(notification, "Επιτυχής καταχώρηση Συνοδού");
                                vm.attendant = result;
                                vm.prospectivePatient.attendant = vm.attendant;
                                setAttendantFullName();
                                vm.onAttendantActionButtonsClick("close");
                            },
                            function () {
                                KendoNotifications.showErrorNotification(notification, "Κάποιο σφάλμα προέκυψε κατά την καταχώρηση του Συνοδού!");
                            });
                    }else {
                        Alerts.showAlert("Sigma SalesForce", "Ελέγξτε τα στοιχεία του Συνοδού!", "Ok")
                    }
                    break;
                case "close":
                    vm.showAttendantLayout = false;
                    vm.showAttendantActions = true;
                    vm.showAttendantSecondaryActions = false;
                    break;
            }
        };

        vm.convertToPatient = function () {
            if (vm.prospectivePatient && vm.prospectivePatient.id > 0){
                var cancel = false;
                var message = "";

                if (vm.prospectivePatient.firstName === null ||
                    vm.prospectivePatient.firstName === '' ||
                    vm.prospectivePatient.firstName === undefined){
                    cancel = true;
                    message = "Απαραίτητο πεδίο Όνομα";
                }

                if (vm.prospectivePatient.lastName === null ||
                    vm.prospectivePatient.lastName === '' ||
                    vm.prospectivePatient.lastName === undefined){
                    cancel = true;
                    message = "Απαραίτητο πεδίο Επώνυμο";
                }

                if (cancel){
                    Alerts.showAlert("Sigma SalesForce", message, "Ok");
                }else {
                    var patientForSave = {
                        firstName: vm.prospectivePatient.firstName,
                        lastName: vm.prospectivePatient.lastName,
                        email: vm.prospectivePatient.email,
                        yearOfBirth: vm.prospectivePatient.birthYear,
                        phone: vm.prospectivePatient.phone,
                        notes: vm.prospectivePatient.notes,
                        prospectivePatient: {
                            id: vm.prospectivePatient.id
                        },
                        amka: vm.prospectivePatient.amka
                    };

                    Patient.save(patientForSave, function (result) {
                        KendoNotifications.showSuccessNotification(notification, "Επιτυχής μετατροπή Πιθανού Ασθενή σε Ασθενή");
                        vm.showConvertButton = false;
                        // Go to New Hospitalization with this Patient
                        $timeout(function () {
                            //$state.go('hospitalizationView', {patientId: result.id});
                            $state.go('patientInfo', {id: result.id}); // ECRM-321
                        }, 600);
                    }, function (error) {
                        console.log(error);
                        var errorMessage = "Κάποιο σφάλμα προέκυψε κατά την μετατροπή Πιθανού Ασθενή σε Ασθενή!";
                        if (error.data.message &&
                            error.data.message.includes("Cannot save Patient with ProspectivePatient id that exists")){
                            errorMessage = "Ο Ασθενής υπάρχει ήδη!";
                        }
                        KendoNotifications.showErrorNotification(notification, errorMessage);
                    });
                }
            }else {

            }
        };

        function setAttendantFullName(){
            vm.attendant.fullName = vm.attendant.lastName + " " + vm.attendant.firstName;
        }

        // Search Patient with this ProspectivePatient to show/hide convert btn
        // Removed ECRM-351
       /* var patientFilters = {
            field: "prospectivePatient.id",
            operator: "eq",
            value: vm.prospectivePatient.id
        };
        $http.get('/api/patients/search',
            {params:{
                    page: "1",
                    pageSize: "1",
                    filter : { filters: [patientFilters] }}})
            .success(function (response) {
                // Show/hide convert button if patient with prospectivePatient id exists
                vm.showConvertButton = !(response.content && response.content.length > 0);
            })
            .error(function (error) {
                console.log(error);
            });*/
    }

})();
