(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MDPrefectureController', MDPrefectureController);

    MDPrefectureController.$inject = ['$scope', 'KendoGrid', '$http', '$filter', 'KendoFilter'];

    function MDPrefectureController ($scope, KendoGrid, $http, $filter, KendoFilter) {
        var vm = this;

        vm.pageHeading = $filter('translate')('masterData.prefectures.title');

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/masterdata/prefectures',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    create: function(o) {
                        var data = o.data.models[0];
                        $http.post('/api/prefectures', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    update: function(o) {
                        var data = o.data.models[0];
                        $http.put('/api/prefectures', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (data, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        } else {
                            return data;
                        }

                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            name: { validation: { required: true , validation: { required: true, min: 3}}},
                            updatedAt: { editable: false }
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            toolbar: [{name :"create", text: "Προσθήκη Νέας εγγραφής"},
                { template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }],
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "name", title: "Όνομα",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "nameEn", title: "Όνομα (Αγγλικά)",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    command:[
                        {name:"edit", text: "Επεξεργασία"}
                    ],
                    text: "Επεξεργασία",
                    title: "&nbsp;"
                }
            ],
            edit: function() { KendoGrid.defaultEditPopup(this._editContainer); },
            editable: "popup"
        };

        vm.detailedGridOptions = function (dataItem) {
            if (dataItem != undefined) {
                return {
                    dataSource: {
                        batch: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }
                                o.data.filter.filters.push({field: "prefecture.id", operator: "eq", value: dataItem.id});
                                $http.get('/api/masterdata/cities',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            },
                            create: function(o) {
                                var data = o.data.models[0];
                                data.prefecture = {id : dataItem.id};

                                $http.post('/api/cities', data)
                                    .success(function (response) {
                                        o.success(response);
                                        angular.element("#detailGrid").data("kendoGrid").dataSource.read();
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            },
                            update: function(o) {
                                var data = o.data.models[0];
                                $http.put('/api/cities', data)
                                    .success(function (response) {
                                        o.success(response);
                                        angular.element("#detailGrid").data("kendoGrid").dataSource.read();
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            },
                            parameterMap: function (data, operation) {
                                if (operation !== 'read') {
                                    return JSON.stringify(data.models);
                                } else {
                                    return data;
                                }
                            }
                        },
                        schema: {
                            data: function (data) {
                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: { editable: false, nullable: true },
                                    name: { validation: { required: true , validation: { required: true, min: 3}} }
                                }
                            }
                        },
                        serverFiltering: true,
                        serverSorting: true,
                        serverPaging: true,
                        pageSize: 15
                    },
                    toolbar: [{name :"create", text: "Προσθήκη Νέας εγγραφής"}],
                    pageable: true,
                    sortable: true,
                    filterable: {
                        mode: "row"
                    },
                    columns: [
                        { field: "name", title: "Όνομα",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }},
                        {
                            field: "nameEn", title: "Όνομα (Αγγλικά)",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {
                            command:[ {name:"edit", text: "Επεξεργασία"}],
                            title: "&nbsp;"
                        }
                    ],
                    editable: "popup",
                    edit: function() { KendoGrid.defaultEditPopup(this._editContainer); }
                };
            }
        };

    }
})();
