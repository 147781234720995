/**
 * Created by Kristy on 22/6/2016.
 */
(function () {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminSchedulesController', AdminSchedulesController);

    AdminSchedulesController.$inject = ['$scope', '$q', '$compile', '$timeout', '$filter', 'Principal', 'MassSchedules', 'KendoGrid', 'ApplicationParameters', 'KendoFilter', '$http', 'customersView', '$location'];

    function AdminSchedulesController($scope, $q, $compile, $timeout, $filter, Principal, MassSchedules, KendoGrid, ApplicationParameters, KendoFilter, $http, customersView, $location) {
        var vm = this;

        vm.pageHeading = 'Ατζεντα';
        vm.userAuth = '';
        vm.selectedCustomers = [];
        var startTime = new Date();
        startTime.setHours(8, 0, 0, 0);
        ApplicationParameters.hasModule('Scheduling', true);
        vm.customerView = customersView.data.content[0].value;

        Principal.identity().then(function (account) {
            vm.account = account;
            vm.tooltipContent = kendo.template($("#template").html());

            if (account.authorities.indexOf('ROLE_MANAGEMENT') > -1 || account.authorities.indexOf('ROLE_REC_MANAGEMENT') > -1
                || account.authorities.indexOf('ROLE_SUPERVISOR') > -1) {
                vm.userAuth = 'ROLE_MANAGEMENT';

                var viewModel = new kendo.observable({
                    ownerMultiDataSource: new kendo.data.DataSource({
                        serverSorting: true,
                        transport: {
                            read: function (o) {
                                if(account.authorities.indexOf('ROLE_SUPERVISOR') > -1){
                                    var url = '/api/me/employees';
                                    if (o.data.sort == undefined || !o.data.sort.length) {
                                        o.data.sort = {"field": "account.lastName", "dir": "asc"};
                                    }
                                    o.data.page = 1;
                                    o.data.pageSize = 100;
                                    $http.get(url,
                                        {params: o.data})
                                        .success(function (response) {
                                            o.success(response.content);
                                            if (response.content !== null && response.content !== undefined) {
                                                response.content.forEach(function (employee) {
                                                    if (employee.account && employee.account.id === vm.account.id) {
                                                        var multiSelect = $("#ownerMultiSelect").data("kendoMultiSelect");
                                                        multiSelect.value(employee);
                                                        multiSelect.trigger("change");
                                                    }
                                                });
                                            }
                                        })
                                        .error(function (response) {
                                            console.log(response);
                                        });
                                } else {
                                    var url = '/api/employees/all';
                                    if (o.data.sort == undefined || !o.data.sort.length) {
                                        o.data.sort = {"field": "account.lastName", "dir": "asc"};
                                    }
                                    $http.get(url,
                                        {params: o.data})
                                        .success(function (response) {
                                            o.success(response);
                                        })
                                        .error(function (response) {
                                            console.log(response);
                                        });
                                }
                            }
                        },
                        schema: {
                            parse: function (data) {
                                data.forEach(function (item) {
                                    item.name = item.account.lastName + " " + item.account.firstName;
                                });
                                return data;
                            }
                        }
                    }),
                    schedulerData: new kendo.data.SchedulerDataSource({
                        batch: true,
                        transport: {
                            read: function (o) {
                                var scheduler = angular.element("#scheduler").data("kendoScheduler");
                                var view = scheduler.view();

                                if (o.data.filter == undefined) {
                                    o.data.filter = {
                                        filters: [
                                            {
                                                "field": "startedAt", "operator": "from",
                                                "value": $filter('date')(view.startDate(), 'yyyy-MM-ddT00:00:00.000') + 'Z'
                                            },
                                            {
                                                "field": "startedAt", "operator": "to",
                                                "value": $filter('date')(view.endDate(), 'yyyy-MM-ddT23:59:59.000') + 'Z'
                                            }
                                        ]
                                    };
                                }

                                if (vm.selectedCustomers.length) {
                                    o.data.filter.filters.push(
                                        {
                                            "field": "employee.id", "operator": "in",
                                            "value": vm.selectedCustomers.toString()
                                        }
                                    );
                                    $http.get('/api/events-and-schedules/all', {params: o.data})
                                        .success(function (response, status, headers) {
                                            o.success(response);
                                        })
                                        .error(function (response) {
                                            console.log(response);
                                        });
                                } else {
                                    o.success([]);
                                }
                            },
                            parameterMap: function (options, operation) {
                                if (operation !== "read" && options.models) {
                                    return {models: kendo.stringify(options.models)};
                                }
                            }
                        },
                        schema: {
                            parse: function (data) {
                                if (data) {
                                    data.forEach(function (item) {
                                        if (item.eventCategoryType.eventCategory.id === 1) {
                                            // Episkepsh / Rantevou
                                            if (item.startedAt === null) {
                                                item.isSchedule = '1';
                                                item.start = item.eventSchedule.startedAt;
                                                item.end = item.eventSchedule.finishedAt;
                                            } else {
                                                if (item.startedAt !== null && !item.eventSchedule) {
                                                    item.isSchedule = '2';
                                                } else {
                                                    item.isSchedule = '3';
                                                }
                                                item.start = item.startedAt;
                                                item.end = item.finishedAt;
                                            }
                                            item.duration = ((new Date(item.end) - new Date(item.start)) / 1000 / 60) << 0;
                                        } else {
                                            // Ektos Pediou
                                            item.start = item.startedAt;
                                            item.end = item.finishedAt;
                                            item.isSchedule = '4';
                                            //item.isAllDay = true;
                                        }
                                    });
                                }
                                return data;
                            },
                            model: {
                                id: "taskId",
                                fields: {
                                    taskId: {from: "id", type: "number", editable: false},
                                    start: {type: "date", from: "start", editable: false},
                                    end: {type: "date", from: "end", editable: false},
                                    title: {
                                        from: "title",
                                        defaultValue: "Χωρίς Τίτλο",
                                        validation: {required: true},
                                        editable: false
                                    },
                                    durationTime: {type: "number", from: "duration"}
                                }
                            }
                        },
                        serverFiltering: true,
                        move: function (e) {
                            e.preventDefault();
                        },
                        moveStart: function (e) {
                            e.preventDefault();
                        },
                        moveEnd: function (e) {
                            e.preventDefault();
                        },
                        resize: function (e) {
                            e.preventDefault();
                        },
                        resizeStart: function (e) {
                            e.preventDefault();
                        },
                        resizeEnd: function (e) {
                            e.preventDefault();
                        }
                    })
                });

                //DropDown Options
                vm.ownerMultiOptions = {
                    dataSource: viewModel.ownerMultiDataSource,
                    change: function (e) {
                        var scheduler = angular.element("#scheduler").data("kendoScheduler");
                        scheduler.dataSource.read();
                    },
                    filter: "startswith",
                    valuePrimitive: true,
                    dataTextField: "name",
                    dataValueField: "id",
                    placeholder: "Επιλογή Ιατρικών Επισκεπτών"
                };

                vm.schedulerOptions = {
                    toolbar: ["pdf"],
                    pdf: {
                        fileName: "Ατζέντα.pdf"
                    },
                    dataSource: viewModel.schedulerData,
                    resources: [
                        {
                            field: "isSchedule",
                            dataSource: [
                                {text: "Schedule", value: '1', color: "#007fff!important"},
                                {text: "Event", value: '2', color: "#BE1C1C!important"},
                                {text: "WasScheduled", value: '3', color: "#ff5c33!important"},
                                {text: "OtherEvent", value: '4', color: "#757575!important"}
                            ]
                        }
                    ],
                    dataBound: function (e) {
                        if (this.view().name === "day") {
                            vm.view = "day";
                        } else if (this.view().name === "workWeek" || this.view().name === "week") {
                            vm.view = "week";
                        } else if (this.view().name === "month") {
                            vm.view = "month";
                        }


                    },
                    date: new Date(),
                    startTime: startTime,
                    allDaySlot: false,
                    messages: {
                        allDay: "Όλη την ημέρα",
                        today: "Τρέχουσα Ημέρα",
                        showFullDay: "Προβολή ολόκληρης της ημέρας",
                        showWorkDay: "Προβολή ωραρίου εργασίας",
                        "cancel": "Κλείσιμο",
                        "editor": {
                            "title": "Τίτλος",
                            "start": "Ξεκινάει",
                            "end": "Τελειώνει",
                            "eventSchedule.description": "Περιγραφή"
                        }
                    },
                    height: 600,
                    views: [
                        {type: "day", title: "Ημέρα"},
                        {type: "workWeek", title: "Εργάσιμες Ημέρες", selected: true},
                        {type: "week", title: "Εβδομάδα"},
                        {type: "month", title: "Μήνας"}
                    ],
                    selectable: true,
                    editable: false,
                    autoBind: false,
                    change: function (e) {
                        //using $timeout to trigger the change
                        $timeout(function () {
                            vm.event = e.events[0];
                        }, 0);
                    }
                };
            } else if (account.authorities.indexOf('ROLE_HEALTH_VISITOR') > -1 ||
                account.authorities.indexOf('ROLE_REC_HEALTH_VISITOR') > -1 || account.authorities.indexOf('ROLE_PHARM_SUPERVISOR') > -1) {
                vm.userAuth = 'ROLE_HEALTH_VISITOR';
                vm.validationError = false;

                var viewModel2 = new kendo.observable({
                    ownerMultiDataSource: new kendo.data.DataSource({
                        serverSorting: true,
                        transport: {
                            read: function (o) {
                                var url = '/api/me/employees';
                                if (o.data.sort == undefined || !o.data.sort.length) {
                                    o.data.sort = {"field": "account.lastName", "dir": "asc"};
                                }
                                o.data.page = 1;
                                o.data.pageSize = 100;
                                $http.get(url,
                                    {params: o.data})
                                    .success(function (response) {
                                        o.success(response.content);
                                        if (response.content !== null && response.content !== undefined) {
                                            // Preselect current user in ownerMultiSelect
                                            response.content.forEach(function (employee) {
                                                if (employee.account && employee.account.id === vm.account.id) {
                                                    var multiSelect = $("#ownerMultiSelect2").data("kendoMultiSelect");
                                                    multiSelect.value(employee);
                                                    multiSelect.trigger("change");
                                                }
                                            });
                                        }
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        },
                        schema: {
                            parse: function (data) {
                                data.forEach(function (item) {
                                    item.name = item.account.lastName + " " + item.account.firstName;
                                });
                                return data;
                            }
                        }
                    }),
                    schedulerData: new kendo.data.SchedulerDataSource({
                        batch: true,
                        transport: {
                            read: function (o) {
                                var scheduler = angular.element("#scheduler2").data("kendoScheduler");
                                var view = scheduler.view();

                                if (o.data.filter == undefined) {
                                    o.data.filter = {
                                        filters: [
                                            {
                                                "field": "startedAt", "operator": "from",
                                                "value": $filter('date')(view.startDate(), 'yyyy-MM-ddT00:00:00.000') + 'Z'
                                            },
                                            {
                                                "field": "startedAt", "operator": "to",
                                                "value": $filter('date')(view.endDate(), 'yyyy-MM-ddT23:59:59.000') + 'Z'
                                            }
                                        ]
                                    };
                                }

                                if (vm.selectedCustomers.length) {
                                    o.data.filter.filters.push(
                                        {
                                            "field": "employee.id", "operator": "in",
                                            "value": vm.selectedCustomers.toString()
                                        }
                                    );

                                    $http.get('/api/me/events-and-schedules/all', {params: o.data})
                                        .success(function (response) {
                                            o.success(response);
                                        })
                                        .error(function (response) {
                                        });
                                } else {
                                    o.success([]);
                                }
                            },
                            create: function (o) {
                                var data = o.data.models[0];
                                data.id = null;
                                data.eventCategoryType = {
                                    id: 1
                                };
                                if (!vm.massCustomerSectors.length) {
                                    var date = new Date(data.start);
                                    date.setTime(date.getTime() + (data.duration * 60 * 1000));
                                    angular.extend(data.eventSchedule, {
                                        startedAt: data.start,
                                        finishedAt: date
                                    });

                                    data.eventCustomerSector.customerSector.id = vm.currentCustomerSector.id;

                                    $http.post('/api/events', data)
                                        .success(function (response, status, headers) {
                                            var scheduler = $("#scheduler2").data("kendoScheduler");
                                            scheduler.dataSource.read();
                                        })
                                        .error(function (response) {
                                            console.log(response);
                                        });
                                } else {
                                    if (vm.validationError == false) {
                                        $http.post('/api/events/all', vm.previewArray)
                                            .success(function (response, status, headers) {
                                                var scheduler = angular.element("#scheduler2").data("kendoScheduler");
                                                scheduler.dataSource.read();
                                            })
                                            .error(function (response) {
                                                console.log(response);
                                            });
                                    }


                                }


                            },
                            update: function (o) {
                                var data = o.data.models[0];
                                data.eventCategoryType = {
                                    id: 1
                                };
                                var date = new Date(data.start);
                                date.setTime(date.getTime() + (data.duration * 60 * 1000));
                                if (!data.startedAt) {
                                    data.eventSchedule.startedAt = data.start;
                                    data.eventSchedule.finishedAt = date;
                                } else {
                                    data.startedAt = data.start;
                                    data.finishedAt = date;
                                }

                                data.eventCustomerSector.agreementValuation = null;
                                if (vm.customerChanged) {
                                    data.eventCustomerSector.customerSector.id = vm.currentCustomerSector.id;
                                    vm.customerChanged = false;
                                }

                                $http.put('/api/events', data)
                                    .success(function (response, status, headers) {
                                        var scheduler = angular.element("#scheduler2").data("kendoScheduler");
                                        scheduler.dataSource.read();
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            },
                            parameterMap: function (options, operation) {
                                if (operation !== "read" && options.models) {
                                    return {models: kendo.stringify(options.models)};
                                }
                            }
                        },
                        schema: {
                            data: function (data) {
                                if (data) {
                                    data.forEach(function (item) {
                                        if (!item.eventCustomerSector) {
                                            item.eventCustomerSector = {
                                                customerSector: {
                                                    customer: {
                                                        lastName: "",
                                                        firstName: "",
                                                        doctorSpecialty: {}
                                                    },
                                                    sector: {
                                                        address: ""
                                                    }
                                                },
                                                agreementValuation: {
                                                    name: ""
                                                },
                                                communicationType: {
                                                    name: ""
                                                }
                                            };
                                        } else {
                                            if (item.eventCustomerSector.agreementValuation === null) {
                                                item.eventCustomerSector.agreementValuation = {};
                                                item.eventCustomerSector.agreementValuation.name = "";
                                            }
                                            if (item.eventCustomerSector.communicationType === null) {
                                                item.eventCustomerSector.communicationType = {};
                                                item.eventCustomerSector.communicationType.name = "";
                                            }

                                            if (item.eventSchedule === null) {
                                                item.eventSchedule = {
                                                    description: ""
                                                }
                                            }
                                        }
                                    });
                                }

                                return data;
                            },
                            parse: function (data) {
                                if (data) {
                                    data.forEach(function (item) {
                                        if (item.eventCategoryType.eventCategory.id === 1) {
                                            // Episkepsh / Rantevou
                                            if (item.startedAt === null) {
                                                item.isSchedule = '1';
                                                item.start = item.eventSchedule.startedAt;
                                                item.end = item.eventSchedule.finishedAt;
                                            } else {
                                                if (item.startedAt !== null && !item.eventSchedule) {
                                                    item.isSchedule = '2';
                                                } else {
                                                    item.isSchedule = '3';
                                                }
                                                item.start = item.startedAt;
                                                item.end = item.finishedAt;
                                            }
                                            item.duration = ((new Date(item.end) - new Date(item.start)) / 1000 / 60) << 0;
                                        } else {
                                            // Ektos Pediou
                                            item.start = item.startedAt;
                                            item.end = item.finishedAt;
                                            item.isSchedule = '4';
                                            //item.isAllDay = true;
                                        }

                                    });
                                }
                                return data;
                            },
                            model: {
                                id: "taskId",
                                fields: {
                                    taskId: {from: "id", type: "number", editable: false},
                                    start: {type: "date", from: "start"},
                                    end: {type: "date", from: "end"},
                                    durationTime: {type: "number", defaultValue: 15, from: "duration"},
                                    title: {
                                        from: "title",
                                        defaultValue: 'ΠΡΟΣΩΠΙΚΗ ΕΠΙΣΚΕΨΗ',
                                        validation: {required: true}
                                    },
                                    eventSchedule: {defaultValue: {}},
                                    firstShiftFrom: {
                                        type: "date",
                                        template: "#= kendo.toString(kendo.parseDate(start.setHours(9, 0 ,0 ,0)), 'HH:mm')#"
                                    },
                                    firstShiftTo: {
                                        type: "date",
                                        template: "#= kendo.toString(kendo.parseDate(start.setHours(14, 0 ,0 ,0)), 'HH:mm')#"
                                    },
                                    secondShiftFrom: {
                                        type: "date",
                                        template: "#= kendo.toString(kendo.parseDate(start.setHours(17, 0 ,0 ,0)), 'HH:mm')#"
                                    },
                                    secondShiftTo: {
                                        type: "date",
                                        template: "#= kendo.toString(kendo.parseDate(start.setHours(21, 0 ,0 ,0)), 'HH:mm')#"
                                    },
                                    eventCustomerSector: {
                                        defaultValue: {
                                            communicationType: {defaultValue: {}},
                                            customerSector: {
                                                defaultValue: {
                                                    customer: {defaultValue: {doctorSpecialty: {defaultValue: {}}}},
                                                    sector: {defaultValue: {}}
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        serverFiltering: true,
                        move: function (e) {
                            e.preventDefault();
                        },
                        moveStart: function (e) {
                            e.preventDefault();
                        },
                        moveEnd: function (e) {
                            e.preventDefault();
                        },
                        resize: function (e) {
                            e.preventDefault();
                        },
                        resizeStart: function (e) {
                            e.preventDefault();
                        },
                        resizeEnd: function (e) {
                            e.preventDefault();
                        }
                    })
                });

                vm.ownerMultiOptions = {
                    dataSource: viewModel2.ownerMultiDataSource,
                    change: function (e) {
                        var scheduler = angular.element("#scheduler2").data("kendoScheduler");
                        scheduler.dataSource.read();
                    },
                    filter: "startswith",
                    valuePrimitive: true,
                    dataTextField: "name",
                    dataValueField: "id",
                    placeholder: "Επιλογή Ιατρικού Επισκέπτη"
                };

                vm.schedulerOptions = {
                    toolbar: ["pdf"],
                    pdf: {
                        fileName: "Ατζέντα.pdf"
                    },
                    dataSource: viewModel2.schedulerData,
                    dataBound: function (e) {
                        if (this.view().name === "day") {
                            vm.view = "day";
                        } else if (this.view().name === "workWeek" || this.view().name === "week") {
                            vm.view = "week";
                        } else if (this.view().name === "month") {
                            vm.view = "month";
                        }


                    },
                    resources: [
                        {
                            field: "isSchedule",
                            dataSource: [
                                {text: "Schedule", value: '1', color: "#007fff!important"},
                                {text: "Event", value: '2', color: "#BE1C1C!important"},
                                {text: "WasScheduled", value: '3', color: "#ff5c33!important"},
                                {text: "OtherEvent", value: '4', color: "#757575!important"}
                            ]
                        }
                    ],
                    date: new Date(),
                    startTime: startTime,
                    allDaySlot: false,
                    messages: {
                        today: "Τρέχουσα Ημέρα",
                        showFullDay: "Προβολή ολόκληρης της ημέρας",
                        showWorkDay: "Προβολή ωραρίου εργασίας",
                        "cancel": "Κλείσιμο",
                        "save": "Αποθήκευση",
                        "editor": {
                            "title": "Τίτλος Ραντεβού",
                            "start": "Ημερομηνία",
                            "end": "Τελειώνει",
                            "eventSchedule.description": "Περιγραφή"
                        }
                    },
                    height: 600,
                    views: [
                        {type: "day", title: "Ημέρα"},
                        {type: "workWeek", title: "Εργάσιμες Ημέρες", selected: true},
                        {type: "week", title: "Εβδομάδα"},
                        {type: "month", title: "Μήνας"}
                    ],
                    editable: false,
                    /*editable: {
                        destroy: false,
                        mode: "popup",
                        template: kendo.template(angular.element("#popup_editor").html())
                    },*/
                    edit: function (e) {
                        vm.initPopupGrid = true;
                        vm.currentEvent = e.event;
                        vm.massSelect = false;
                        vm.canMasSchedule = true;
                        vm.shiftType = 'dayNight';
                        vm.page = 'firstPage';
                        vm.btnNextName = 'Επόμενο';
                        vm.btnBackName = 'Πίσω';
                        vm.massCustomerSectors = [];
                        vm.search_text = "";
                        vm.masterFilter = {};
                        vm.allSelected = false;

                        angular.element("div.k-edit-form-container").width(1000);
                        var wnd = angular.element(e.container).data("kendoWindow");
                        wnd.setOptions({
                            title: "Ραντεβού"
                        });

                        vm.currentCustomerSector = e.event.eventCustomerSector.customerSector;

                        //choose readonly editor if is not a schedule
                        if (!e.event.isNew()) {
                            if (!e.event.startedAt) vm.isSchedule = '1';
                            else {
                                if (!e.event.eventSchedule) {
                                    vm.isSchedule = '2';
                                } else {
                                    vm.isSchedule = '3';
                                }
                            }

                            vm.canMasSchedule = false;

                            if (vm.isSchedule !== '1') {
                                wnd.setOptions({
                                    title: "Δραστηριότητα"
                                });

                                e.container
                                    .find(".k-edit-buttons") // find edit buttons
                                    .remove(); // remove the checkbox wrapper
                            }
                        } else {
                            vm.isSchedule = '1';

                            var date = e.event.start;

                            //set default date for shifts
                            e.event.set("firstShiftFrom", new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 0, 0, 0));
                            e.event.set("firstShiftTo", new Date(date.getFullYear(), date.getMonth(), date.getDate(), 14, 0, 0, 0));
                            e.event.set("secondShiftFrom", new Date(date.getFullYear(), date.getMonth(), date.getDate(), 17, 0, 0, 0));
                            e.event.set("secondShiftTo", new Date(date.getFullYear(), date.getMonth(), date.getDate(), 21, 0, 0, 0));

                        }

                        //reload popup grid
                        KendoGrid.refresh(vm.customerSectorGridOptions);
                    },
                    save: function () {
                        delete vm.initPopupGrid;
                    },
                    cancel: function () {
                        delete vm.initPopupGrid;
                    }
                };

                vm.defineState = function (btn) {
                    var updateBtn = angular.element(".k-edit-buttons .k-button.k-scheduler-update");

                    switch (vm.page) {
                        case 'firstPage':
                            vm.page = 'secondPage';
                            vm.btnNextName = 'Επόμενο';
                            vm.showBackBtn = true;
                            break;
                        case 'secondPage':
                            if (btn == 'next') {
                                vm.page = 'thirdPage';
                                var grid = angular.element("#previewGrid").data("kendoGrid");
                                grid.dataSource.read();
                                updateBtn.show();
                                vm.showNextBtn = false;

                                vm.sortableOptions = {
                                    filter: ".k-grid tr[data-uid]",
                                    hint: $.noop,
                                    cursor: "move",
                                    placeholder: function (element) {
                                        return element
                                            .clone()
                                            .removeAttr("uid")
                                            .addClass("k-state-hover")
                                            .addClass("k-state-selected")
                                            .addClass("draggable");
                                        //.css("opacity", 0.65);
                                    },
                                    container: ".k-grid tbody",
                                    change: function (e) {
                                        var sortableGrid = vm.sortableGrid,
                                            dataItem = sortableGrid.dataSource.getByUid(e.item.data("uid"));

                                        sortableGrid.dataSource.remove(dataItem);
                                        sortableGrid.dataSource.insert(e.newIndex, dataItem);
                                        vm.massCustomerSectors = sortableGrid.dataSource.data();
                                        grid.dataSource.read();
                                    }
                                };
                            } else {
                                vm.page = 'firstPage';
                            }
                            vm.btnNextName = 'Επόμενο';
                            break;
                        case 'thirdPage':
                            vm.page = 'secondPage';
                            vm.showNextBtn = true;
                            updateBtn.hide();
                            break;
                    }
                };

                vm.toggleMassSchedules = function (e) {
                    var editBtn = angular.element(".k-edit-buttons .k-button");
                    var buttonDiv = angular.element(".k-edit-buttons.k-state-default");
                    var nextBtn = angular.element("#next");
                    var backBtn = angular.element("#back");
                    var btnshtml = '<a class="k-button" id="back" ng-show="vm.showBackBtn" ng-model="vm.page" ng-click="vm.defineState(\'back\')">{{vm.btnBackName}}</a>' +
                        '<a class="k-button k-primary" id="next" ng-show="vm.showNextBtn" ng-model="vm.page" ng-click="vm.defineState(\'next\')">{{vm.btnNextName}}</a>';
                    var temp = $compile(btnshtml)($scope);
                    switch (vm.massSelect) {
                        case true:
                            editBtn.hide();
                            buttonDiv.prepend(temp);
                            vm.showBackBtn = false;
                            vm.showNextBtn = true;
                            break;
                        case false:
                            vm.massCustomerSectors = [];
                            editBtn.show();
                            nextBtn.remove();
                            backBtn.remove();
                            break;
                    }
                };

                vm.communicationTypesComboBoxDataSource = new kendo.data.DataSource({
                    serverFiltering: true,
                    transport: {
                        read: function (o) {
                            var url = '/api/masterdata/communication-types';
                            o.data.page = 1;
                            o.data.pageSize = 1000;
                            $http.get(url, {params: o.data})
                                .success(function (response) {
                                    o.success(response.content);
                                })
                                .error(function (response) {
                                    console.log(response);
                                });
                        }
                    }
                });

                vm.communicationTypesComboBoxOptions = {
                    dataSource: vm.communicationTypesComboBoxDataSource,
                    filter: "startswith",
                    valuePrimitive: true,
                    dataTextField: "name",
                    dataValueField: "id",
                    dataBound: function () {
                        if (!this.value() && this.selectedIndex == -1) {
                            this.select(0);
                            this.trigger("change");
                        }
                    },
                    change: function (e) {
                        var value = this.text();
                        //change title according to communication type
                        $('input[name=eventTitle]').val(value).trigger('change');
                    }
                };

                //Customers Grid
                vm.search = function () {
                    vm.masterFilter = {"field": "masterFilter", "operator": "search", "value": vm.search_text};
                    angular.element("#customersMeGrid").data("kendoGrid").dataSource.read();
                };
                vm.toolbarOptions = {
                    items: [
                        {
                            template: "<label>Αναζητήστε επαγγελματία υγείας: </label>" +
                                "<input type='text' class='k-input k-textbox' id='masterFilter' ng-model='vm.search_text' ng-change='vm.search()' />",
                            overflow: "never"
                        }
                    ]
                };

                vm.massCustomerSectors = [];

                vm.onChange = function (e, dataItem) {
                    var grid = e.sender;

                    //using $timeout to trigger the change
                    $timeout(function () {
                        vm.currentCustomerSector = dataItem;
                    }, 0);
                    vm.customerChanged = true;
                    vm.currentEvent.dirty = true;
                };

                vm.gridColumns = [
                    {field: "id", filterable: false, hidden: true},
                    {
                        title: "Επαγγελματίας Υγείας",
                        columns: [
                            {
                                field: "customer.lastName", title: "Επώνυμο",
                                filterable: {
                                    cell: {
                                        operator: "startswith",
                                        template: function (args) {
                                            KendoFilter.getTemplate(args);
                                        },
                                        showOperators: false
                                    }
                                }
                            },
                            {
                                field: "customer.firstName", title: "Όνομα",
                                filterable: {
                                    cell: {
                                        operator: "startswith",
                                        template: function (args) {
                                            KendoFilter.getTemplate(args);
                                        },
                                        showOperators: false
                                    }
                                }
                            },
                            {
                                field: "customer.doctorSpecialty.name", title: "Ειδικότητα",
                                filterable: {
                                    cell: {
                                        operator: "startswith",
                                        template: function (args) {
                                            KendoFilter.getTemplate(args);
                                        },
                                        showOperators: false
                                    }
                                }
                            }
                        ]
                    },
                    {
                        title: "Μονάδα Υγείας",
                        columns: [
                            {
                                field: "sector.name", title: "Όνομα",
                                filterable: {
                                    cell: {
                                        operator: "like",
                                        template: function (args) {
                                            KendoFilter.getTemplate(args);
                                        },
                                        showOperators: false
                                    }
                                }
                            },
                            {
                                field: "sector.address", title: "Διεύθυνση",
                                filterable: {
                                    cell: {
                                        operator: "startswith",
                                        template: function (args) {
                                            KendoFilter.getTemplate(args);
                                        },
                                        showOperators: false
                                    }
                                }
                            },
                            {
                                field: "sector.cityName", title: "Πόλη",
                                filterable: {
                                    cell: {
                                        operator: "startswith",
                                        template: function (args) {
                                            KendoFilter.getTemplate(args);
                                        },
                                        showOperators: false
                                    }
                                }
                            },
                            {
                                field: "sector.postalCode", title: "Τ.Κ",
                                filterable: {
                                    cell: {
                                        operator: "startswith",
                                        template: function (args) {
                                            KendoFilter.getTemplate(args);
                                        },
                                        showOperators: false
                                    }
                                }
                            },
                            {
                                field: "sector.prefectureName", title: "Νομός",
                                filterable: {
                                    cell: {
                                        operator: "startswith",
                                        template: function (args) {
                                            KendoFilter.getTemplate(args);
                                        },
                                        showOperators: false
                                    }
                                }
                            }
                        ]
                    }
                ];

                vm.customerSectorGridOptions = {
                    autoBind: false,
                    dataSource: new kendo.data.DataSource({
                        batch: true,
                        transport: {
                            read: function (o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = {filters: []};
                                }

                                if (o.data.sort == undefined || !o.data.sort.length) {
                                    o.data.sort = {"field": "customer.lastName", "dir": "asc"};
                                }

                                //masterFilter functionality
                                if (vm.masterFilter) {
                                    o.data.filter.filters.push(vm.masterFilter);
                                }

                                $http.get('/api/me/customer-sectors',
                                    {params: o.data})
                                    .success(function (response, status, headers) {
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            },
                            parameterMap: function (data, operation) {
                                if (operation !== 'read') {
                                    return JSON.stringify(data.models);
                                }
                                return data;
                            }
                        },
                        schema: {
                            data: function (data) {
                                if (data.content != undefined) {
                                    data.content.forEach(function (item) {
                                        if (item.customer.doctorSpecialty === null) {
                                            item.customer.doctorSpecialty = {
                                                name: ""
                                            };
                                        }

                                        if (item.sector == null) {
                                            item.sector = {
                                                name: ""
                                            }
                                        }

                                    });
                                }
                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: {editable: false, nullable: true},
                                    sector: {defaultValue: {id: null, name: ""}},
                                    customer: {defaultValue: {id: null, firstName: "", lastName: ""}}
                                }
                            }
                        },
                        serverFiltering: true,
                        serverSorting: true,
                        serverPaging: true,
                        pageSize: 40
                    }),
                    selectedItems: function (dataItems) {
                        vm.massCustomerSectors = dataItems;
                    },
                    sortable: true,
                    pageable: true,
                    filterable: {
                        mode: "row"
                    },
                    selectable: true,
                    scrolable: true,
                    columns: vm.gridColumns,
                    dataBound: function (e) {
                        angular.element("#customersMeGridcheckbox").prop('checked', false);
                        //toggle class on click
                        angular.element(".checkbox").bind("change", function (e) {
                            angular.element(e.target).closest("tr").toggleClass("k-state-selected");
                        });

                        var view = this.dataSource.data();
                        var me = this;

                        var massCustomerSectorsViewCounter = 0;
                        angular.forEach(view, function (item) {
                            var foundItemById = $filter('filter')(vm.massCustomerSectors, {id: item.id}, true)[0];
                            if (vm.massCustomerSectors.indexOf(foundItemById) !== -1) {
                                me.tbody.find("tr[data-uid='" + item.uid + "']")
                                    .addClass("k-state-selected");
                                item.selected = true;
                                massCustomerSectorsViewCounter++
                            }
                        });
                        if (massCustomerSectorsViewCounter == view.length) {
                            angular.element("#customersMeGridcheckbox").prop('checked', true);
                        }
                    }
                };

                vm.gridPreviewColumns = [
                    {field: "id", filterable: false, hidden: true},
                    {
                        title: "Επαγγελματίας Υγείας",
                        columns: [
                            {field: "eventCustomerSector.customerSector.customer.lastName", title: "Επώνυμο"},
                            {field: "eventCustomerSector.customerSector.customer.firstName", title: "Όνομα"},
                            {
                                field: "eventCustomerSector.customerSector.customer.doctorSpecialty.name",
                                title: "Ειδικότητα"
                            }
                        ]
                    },
                    {
                        title: "Μονάδα Υγείας",
                        columns: [
                            {field: "eventCustomerSector.customerSector.sector.name", title: "Όνομα"},
                            {field: "eventCustomerSector.customerSector.sector.cityName", title: "Πόλη"},
                            {field: "eventCustomerSector.customerSector.sector.prefectureName", title: "Νομός"},
                            {field: "eventCustomerSector.customerSector.sector.address", title: "Διεύθυνση"},
                            {field: "eventCustomerSector.customerSector.sector.postalCode", title: "Τ.Κ"}
                        ]
                    },
                    {
                        field: "eventSchedule.startedAt",
                        template: "#= kendo.toString(kendo.parseDate(eventSchedule.startedAt), 'HH:mm')#",
                        title: "Ώρα Έναρξης"
                    },
                    {
                        field: "eventSchedule.finishedAt",
                        template: "#= kendo.toString(kendo.parseDate(eventSchedule.finishedAt), 'HH:mm')#",
                        title: "Ώρα Λήξης"
                    }
                ];

                vm.previewGridOptions = {
                    dataSource: new kendo.data.DataSource({
                        transport: {
                            read: function (options) {
                                var scheduler = angular.element("#scheduler2").data("kendoScheduler");
                                if (scheduler) {
                                    var length = scheduler.dataSource.data().length;
                                    var event = scheduler.dataSource.at(length - 1);
                                    event.secondShift = false;
                                }

                                //create each schedule - define startedAt & finishedAt of eventSchedule
                                vm.validationError = false;
                                var restartIndex = 0;
                                vm.previewArray = [];
                                angular.forEach(vm.massCustomerSectors, function (item, index) {
                                    var schedule = MassSchedules.createSchedule(vm.massCustomerSectors, vm.shiftType, event, item, index, restartIndex);
                                    //restart index if second shift
                                    if (schedule.secondShift) {
                                        restartIndex++;
                                    }
                                    if (schedule !== 'validationError') {
                                        vm.previewArray.push(schedule);
                                    } else {
                                        vm.validationError = true;
                                    }

                                });
                                options.success(vm.previewArray); // where data is the local data array
                            }
                        },
                        schema: {
                            model: {
                                id: "id",
                                fields: {
                                    id: {editable: false, nullable: true},
                                    'eventCustomerSector.customerSector.sector': {defaultValue: {id: null, name: ""}},
                                    'eventCustomerSector.customerSector.customer': {
                                        defaultValue: {
                                            id: null,
                                            firstName: "",
                                            lastName: ""
                                        }
                                    },
                                    'eventSchedule.startedAt': {type: "date", defaultValue: null},
                                    'eventSchedule.finishedAt': {type: "date", defaultValue: null},

                                }
                            }
                        },
                        pageSize: 40
                    }),
                    sortable: true,
                    pageable: true,
                    selectable: true,
                    columns: vm.gridPreviewColumns
                };
            }
        });
    }
})();
