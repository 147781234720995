/**
 * Created by Kristy Kavvada on 11/2/2020.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminEmployeeViewMedReportsWithLinesController', AdminEmployeeViewMedReportsWithLinesController);

    AdminEmployeeViewMedReportsWithLinesController.$inject = ['$http','$state', '$stateParams', '$filter', 'Principal', 'KendoFilter', 'CustomUtils', 'KendoService', 'MasterFilters'];

    function AdminEmployeeViewMedReportsWithLinesController ($http, $state, $stateParams, $filter, Principal, KendoFilter, CustomUtils, KendoService, MasterFilters) {
        var vm = this;
        vm.selectedUserId = $stateParams.id;
        var months = CustomUtils.getMonths(false);
        var years = CustomUtils.getYears(2017, true);
        vm.masterFilters = { selectedMonths: [], selectedYears: [], isActive: true };

        vm.medReportsGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "isActive", "operator": "eqbool", "value": vm.masterFilters.isActive }
                            ]
                        );

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "employee.id", "operator": "eq",  "value": vm.selectedUserId}
                            ]
                        );

                        if (vm.masterFilters.selectedMonths.length > 0) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "fromDate", "operator": "inMonths",
                                        "value": vm.masterFilters.selectedMonths.toString() },
                                    { "field": "toDate", "operator": "inMonths",
                                        "value": vm.masterFilters.selectedMonths.toString() }
                                ]
                            );
                        }

                        if (vm.masterFilters.selectedYears.length > 0) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "fromDate", "operator": "inYears",
                                        "value": vm.masterFilters.selectedYears.toString() },
                                    { "field": "toDate", "operator": "inYears",
                                        "value": vm.masterFilters.selectedYears.toString() }
                                ]
                            );
                        }

                        $http.get('/api/me/med-reports',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (data) {
                        return data;
                    }
                },
                schema: {
                    data: function (data) {
                        if(data != undefined) {
                            data.content.forEach(function(item){
                            });
                        }
                        return data.content;
                    },
                    total : function(data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            fromDate: { type: 'date'},
                            toDate: { type: 'date'},
                            checkDigit1: { type: 'number'},
                            checkDigit2: { type: 'number'}
                        }
                    }
                },
                serverSorting: true,
                serverFiltering: true,
                serverPaging: true,
                pageSize: 50
            }),
            pageable: true,
            sortable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: "cell",
            columns: [
                {
                    field: "fromDate",
                    title: "Μήνας",
                    template: "#= fromDate == null ? '' : kendo.toString(kendo.parseDate(fromDate), 'MMMM')#",
                    filterable: false
                },
                {
                    field: "toDate",
                    title: "Έτος",
                    template: "#= toDate == null ? '' : kendo.toString(kendo.parseDate(toDate), 'yyyy')#",
                    filterable: false
                },
                {
                    field: "checkDigit1", title: "Check Digit",
                    filterable: false,
                    width: 140
                },
                {
                    field: "checkDigit2", title: "Check Digit 2",
                    filterable: false,
                    width: 140
                },
                {
                    field: "code", title: "Κωδικός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "customer.lastName", title: "Επώνυμο Επαγγελματία Υγείας",
                    attributes: {
                        class: "link-cell link-cell-color"
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }, width: 200
                },
                {field: "customer.firstName", title: "Όνομα Επαγγελματία Υγείας",
                    attributes: {
                        class: "link-cell link-cell-color"
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }, width: 200
                }
            ],
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.medReportsGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }],
            change: onChange
        };

        vm.medReportLinesGridOptions = function (dataItem) {
            if (dataItem != undefined) {
                return {
                    dataSource: {
                        batch: true,
                        transport: {
                            read: function(o) {
                                vm.showProgress = true;
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.filter.filters.push({field: "medReport.id", operator: "eq", value: dataItem.id});
                                $http.get('/api/med-report-lines/search',
                                    {params: o.data})
                                    .success(function (response) {
                                        o.success(response);
                                        vm.showProgress = false;
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                        vm.showProgress = false;
                                    });
                            }
                        },
                        schema: {
                            data: function (data) {
                                if(data != undefined) {
                                    data.content.forEach(function(item){
                                    });
                                }
                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: { editable: false, nullable: true },
                                    sum: { type: "number" },
                                    marketSharePercentage: { type: "number" },
                                    numberOfChanges: { type: "number" }
                                }
                            }
                        },
                        serverFiltering: true,
                        serverSorting: true,
                        serverPaging: true,
                        pageSize: 15
                    },
                    pageable: true,
                    sortable: true,
                    filterable: {
                        mode: "row"
                    },
                    columns: [
                        {field: "medicine.name", title: "Όνομα Σκευάσματος",
                            filterable: {
                                cell: {
                                    operator: "like",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "medicine.barcode", title: "Barcode Σκευάσματος",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {
                            field: "sum", title: "Σύνολο",
                            filterable: {
                                operators: {
                                    number: {
                                        eq: 'Ίσο',
                                        gte: 'Μεγαλύτερο ή Ίσο',
                                        lte: 'Μικρότερο ή Ίσο'
                                    }
                                },
                                cell: {
                                    template: function (args) {
                                        args.element.kendoNumericTextBox({
                                            format: "n0",
                                            decimals: 0,
                                            min: 0
                                        });

                                    }
                                }
                            }
                        },
                        // {
                        //     field: "marketSharePercentage",
                        //     title: "Μερίδιο Αγοράς (%)",
                        //     attributes:{style:"text-align:right;"},
                        //     template: "#= (marketSharePercentage == null) ? '' : kendo.format('{0:p0}', marketSharePercentage / 100)#",
                        //     filterable: {
                        //         cell: {
                        //             operator: "eq",
                        //             template: function (args) {
                        //                 args.element.kendoNumericTextBox({
                        //                     format: "{0:n0}"
                        //                 });
                        //             },
                        //             showOperators: false
                        //         }
                        //     }
                        // },
                        // {
                        //     field: "numberOfChanges", title: "Αριθμός Αλλαγών",
                        //     filterable: {
                        //         operators: {
                        //             number: {
                        //                 eq: 'Ίσο',
                        //                 gte: 'Μεγαλύτερο ή Ίσο',
                        //                 lte: 'Μικρότερο ή Ίσο'
                        //             }
                        //         },
                        //         cell: {
                        //             template: function (args) {
                        //                 args.element.kendoNumericTextBox({
                        //                     format: "n0",
                        //                     decimals: 0,
                        //                     min: 0
                        //                 });
                        //
                        //             }
                        //         }
                        //     }
                        // }
                    ],
                    editable: false
                };
            }
        };

        vm.monthsMultiOptions = KendoService.initDefaultOptions(months, "startswith", "value", "id");
        vm.yearsMultiOptions = KendoService.initDefaultOptions(years, "startswith", "value", "value");
        vm.masterFilters.selectedYears.push(years[0].value); //2020
        vm.toolbarOptions = {
            items: [
                {
                    template: "<label>Μήνες: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select multiple='multiple' " +
                    "k-options='vm.monthsMultiOptions' k-ng-model='vm.masterFilters.selectedMonths'></select>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    template: "<label>Έτη: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select multiple='multiple' " +
                    "k-options='vm.yearsMultiOptions' k-ng-model='vm.masterFilters.selectedYears'></select>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    template: "<label>Ενεργό: </label><md-checkbox aria-label='isActive' ng-model='vm.masterFilters.isActive' class='checkbox-toolbar green inline'></md-checkbox>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary md-primary-light' aria-label='search' ng-click='vm.search()' ng-disabled='vm.disabled'>" +
                    "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>",
                    overflow: "never",
                    attributes: {class: "right"}
                }
            ]
        };

        vm.search = function () {
            vm.medReportsGridOptions.dataSource.read();
        };

        function onChange() {
            // Get selected cell
            var selected = this.select();
            // Get the row that the cell belongs to.
            var row = this.select().closest("tr");
            var cellIndex = selected[0].cellIndex;

            if(cellIndex === 5 || cellIndex === 6) {
                $state.go('customerInfo', {id: this.dataItem(row).customer.id});
            }
        }
    }

})();
