/**
 * Created by Kristy on 1/6/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('RecDoctorViewSectorsController', RecDoctorViewSectorsController);

    RecDoctorViewSectorsController.$inject = ['$http','$compile', '$stateParams','$filter', '$timeout', 'KendoGrid', 'NotificationOptions', '$scope', 'KendoFilter'];

    function RecDoctorViewSectorsController ($http, $compile, $stateParams, $filter, $timeout, KendoGrid, NotificationOptions, $scope, KendoFilter) {
        var vm = this;
        vm.selectedUserId = $stateParams.id;
        vm.newSector = false;
        vm.massSelect = true;
        vm.kendoGrid = KendoGrid;
        vm.hasAssignations = vm.myTarget = true;

        $http.get('/api/employees/me')
            .success(function (response) {
                vm.geodata = response.geodata;

                vm.refreshCombobox = function(filter, combobox) {
                    combobox.filter(filter);
                    combobox.read();
                };

                if(!vm.geodata) {

                    var prefecturesDataSource = new kendo.data.DataSource({
                        serverFiltering: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter === undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.page = 1;
                                o.data.pageSize = 100000;
                                $http.get('/api/masterdata/prefectures',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response.content);
                                    })
                                    .error(function (response) {});
                            }
                        }
                    });

                    //DropDown Options
                    vm.prefecturesComboBoxOptions = {
                        dataSource: prefecturesDataSource,
                        filter: "startswith",
                        valuePrimitive: false,
                        dataTextField: "name",
                        dataValueField: "id",
                        change: function(e){
                            var filter = {};
                            if(this.value() && !isNaN(this.value())) {
                                filter = {
                                    "field": "prefecture.id",
                                    "operator": "eq",
                                    "value": this.value()
                                };
                            }
                            vm.refreshCombobox(filter, vm.citiesComboBoxOptions.dataSource);
                        }
                    };

                    var citiesDataSource = new kendo.data.DataSource({
                        serverFiltering: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter === undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.page = 1;
                                o.data.pageSize = 50;
                                $http.get('/api/masterdata/cities',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response.content);
                                    })
                                    .error(function (response) {});
                            }
                        }
                    });

                    //DropDown Options
                    vm.citiesComboBoxOptions = {
                        dataSource: citiesDataSource,
                        filter: "startswith",
                        valuePrimitive: false,
                        dataTextField: "name",
                        dataValueField: "id",
                        template: "<table>${name} &nbsp;" +
                        "<span class='hint'>(${prefecture.name})</span></table>",
                        change: function(){
                            var filter = {};
                            if(this.dataItem(this.select())) {
                                filter = {
                                    "field"   : "id",
                                    "operator": "eq",
                                    "value"   : this.dataItem(this.select()).prefecture.id
                                };
                                vm.refreshCombobox(filter, vm.prefecturesComboBoxOptions.dataSource);
                                //prefecturesCombo.one("dataBound", function() { this.select(0) });
                                //prefecturesCombo.trigger("change");
                            } else {
                                vm.refreshCombobox(filter, vm.prefecturesComboBoxOptions.dataSource);
                            }
                        }
                    };
                } else {
                    var geodataDataSource = new kendo.data.DataSource({
                        serverFiltering: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter === undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.page = 1;
                                o.data.pageSize = 100;
                                $http.get('/api/masterdata/geodata',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response.content);
                                    })
                                    .error(function (response) {});
                            }
                        },
                        schema: {
                            parse: function(data) {
                                angular.forEach(data, function(item) {
                                    item.masterFilter = "";
                                    if(item.street !==null) {
                                        item.masterFilter =  item.street + ", ";
                                    }
                                    item.masterFilter = item.masterFilter + item.city + ", " + item.postalCode + ", " + item.prefecture;
                                });
                                return data;
                            }
                        }
                    });

                    vm.masterFilterComboBoxOptions = {
                        dataSource: geodataDataSource,
                        filter: "search",
                        dataTextField: "masterFilter",
                        dataValueField: "id",
                        headerTemplate:"<div class='dropdown-header k-widget k-header'>" +
                        "<span>Διεύθυνση</span>"+
                        "<span>Πόλη</span>"+
                        "<span>Τ.Κ.</span>"+
                        "<span>Νομός</span></div>",
                        template:
                            "#= street != null ? street + ', ' + city + ', ' + postalCode + ', ' + prefecture : city + ', ' + postalCode + ', ' + prefecture #",
                        placeholder: "Αναζητήστε Διεύθυνση, Πόλη, Τ.Κ., Νομό",
                        change: function(e){
                            //initialize inputs (autocomplete)
                            if(this.dataItem(this.select())) {
                                var self = this;
                                //using $timeout to trigger the change
                                $timeout(function(){
                                    vm.saveNewSector.prefectureName = self.dataItem(self.select()).prefecture;
                                    vm.saveNewSector.cityName = self.dataItem(self.select()).city;
                                    vm.saveNewSector.address = self.dataItem(self.select()).street;
                                    vm.saveNewSector.postalCode = self.dataItem(self.select()).postalCode;
                                    vm.suggestBricks(vm.saveNewSector.postalCode);
                                },0);
                            }
                        }
                    };
                }
            })
            .error(function (response) {});

        var comboBoxes = ["#masterFilter","#sectorPosition", "#sectorType", "#prefectures", "#cities"]; //add "#regions" if wanted

        var validator = angular.element("#form").kendoValidator({
            rules: {
                custom: function (input) {

                    if (input.data('custom')) {
                        if (input.val() !== null && input.val() !== "" && angular.element(input.data('custom')).data("kendoComboBox").selectedIndex === -1) {
                            return false;
                        }
                    }

                    return true;
                }
            },
            messages: {
                custom: "Πρέπει να επιλέξετε από την λίστα",
                maxlength: "Το κινητό τηλέφωνο θα πρέπει να περιέχει 10 χαρακτήρες."
            }
        }).data("kendoValidator");
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");

        var sectorPositionsDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get('/api/masterdata/sector-positions',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            }
        });

        //DropDown Options
        vm.sectorPositionComboBoxOptions = {
            dataSource: sectorPositionsDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };

        var sectorTypeFilter = function () {
            return {
                "field"   : "isPrivate",
                "operator": "eq",
                "value"   : vm.newSector
            };
        };

        var sectorTypesDataSource = new kendo.data.DataSource({
            filter: sectorTypeFilter(),
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }
                    o.data.filter.filters.push({ "field": "sectorTypeCategory", "operator": "startswith", "value": 'hcu'});

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get('/api/masterdata/sector-types',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            }
        });

        //DropDown Options
        vm.sectorTypeComboBoxOptions = {
            dataSource: sectorTypesDataSource,
            filterable: true,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id",
            suggest: true,
            cascade: function() {
                angular.element("#sectors").data("kendoComboBox").text("");
            }
        };

        var sectorsDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 20;
                    $http.get('api/customer-sectors/customers/' + vm.selectedUserId +'/sectors/suggest',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            }
        });

        //DropDown Options
        vm.sectorsComboBoxOptions = {
            dataSource: sectorsDataSource,
            cascadeFrom: "sectorType",
            cascadeFromField: "sectorType.id",
            filter: "like",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id",
            template: "#= name # <br/>(#= prefectureName #, #= cityName #)"
        };

        vm.cancelSector= function (){
            vm.newSector = false;
            vm.sectorTypeComboBoxOptions.dataSource.filter(sectorTypeFilter());
            vm.savedSector = false;
        };

        //Save New Sector
        vm.updateSector = function(){

            if (validator.validate()) {

                var url = 'api/sectors';

                $http.post(url,vm.saveNewSector)
                    .success(function (response) {
                        notification.show({
                            message: "Η ενημέρωση των στοιχείων ήταν επιτυχής!"
                        }, "success");
                        vm.savedSector = true;

                        vm.newSectorObj = response;
                        vm.sectorsComboBoxOptions.dataSource.add(response);
                        angular.element("#sectors").data("kendoComboBox").value(response.id);

                    })
                    .error(function (response) {});
            }else{
                notification.show({
                    message: "Η ενημέρωση των στοιχείων απέτυχε!"
                }, "error");
            }
        };

        function employeeCustomerSectorsDisplay(data) {
            return data.employeeCustomerSectors.map(function(elem) {
                return elem.employee.account.fullName
            }).join(', ');
        }

        vm.sectorsGridColumns = [
            {command:[{name:"edit", text: ""}], title: "&nbsp;", width:140},
            {
                title: "Στοιχεία Μονάδας Υγείας",
                columns: [
                    {
                        field: "sector.name", title: "Μονάδα Υγείας",
                        filterable: {
                            cell: {
                                operator: "contains",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    {
                        field: "sector.sectorType.name",
                        title: "Τύπος",
                        filterable: {
                            cell: {
                                operator: "contains",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    }
                ]
            },
            {field: "startedAt", title: "Ημερομηνία Έναρξης", template: "#= kendo.toString(kendo.parseDate(startedAt), 'dddd, dd/MM/yyyy')#",
                filterable: {
                    cell: {
                        operator: "eq",
                        showOperators: false
                    }
                }
            },
            {field: "endedAt", title: "Ημερομηνία Λήξης", template: "#= (endedAt == null) ? '' : kendo.toString(kendo.parseDate(endedAt), 'dddd, dd/MM/yyyy')#",
                filterable: {
                    cell: {
                        operator: "eq",
                        showOperators: false
                    }
                }
            },
            {template: '<md-checkbox class="checkbox-toolbar customers green inline"  aria-label="main" ng-model="dataItem.main" ng-disabled="true"></md-checkbox>',
                field: "main", title: "Βασική Μονάδα",
                // editor: function(container){
                //     angular.element('<md-checkbox ng-model="dataItem.main"  aria-label="main" ' +
                //         'class="checkbox-toolbar green inline"></md-checkbox>')
                //         .appendTo(container);
                // },
                filterable: {
                    cell: {
                        operator: "eq",
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "Ναι", value: "true" },
                                        { text: "Όχι", value: "false" }]
                                }),
                                valuePrimitive: true

                            })
                        },
                        showOperators: false
                    }
                }
            }
        ];

        vm.sectorsGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        $http.get('/api/customers/' + vm.selectedUserId + '/me/customer-sectors')
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    },
                    update: function(o) {
                        vm.saveSector = o.data.models[0];

                        //if deleted make active false
                        angular.forEach(vm.saveSector.employeeCustomerSectorsAll, function(item) {
                            var notFound = $filter('filter')(vm.saveSector.employeeCustomerSectors, {id: item.id}, true)[0];
                            if (vm.saveSector.employeeCustomerSectors.indexOf(notFound) === -1) {
                                item.active = false;
                                vm.saveSector.employeeCustomerSectors.push(item);
                            }
                        });

                        if(!vm.saveSector.sectorPosition.id) {
                            vm.saveSector.sectorPosition = null;
                        }
                        if(!vm.saveSector.sector.parent.id) {
                            vm.saveSector.sector.parent = null;
                        }

                        vm.saveSector.startedAt = kendo.toString(vm.saveSector.startedAt,"yyyy-MM-dd");
                        vm.saveSector.endedAt = kendo.toString(vm.saveSector.endedAt,"yyyy-MM-dd");

                        var url = 'api/customer-sectors';

                        $http.put(url,vm.saveSector)
                            .success(function (response) {
                                angular.element("#sectorsGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {});
                    },
                    parameterMap: function (options, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(options.data.models);
                        } else {
                            return data;
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        if(data !== undefined) {
                            data.forEach(function(item){

                                if(item.sector === null){
                                    item.sector = {
                                        name: "",
                                        address: "",
                                        cityName: "",
                                        prefectureName: ""
                                    };
                                } else if (item.sector.parent === null) {
                                    item.sector.parent = { name: "" };
                                }

                                if(item.sector.sectorType === null){
                                    item.sector.sectorType = {};
                                    item.sector.sectorType.name = "";
                                }

                                if(item.sectorPosition === null){
                                    item.sectorPosition = {};
                                    item.sectorPosition.name = "";
                                }

                                if(item.employeeCustomerSectors === null){
                                    item.employeeCustomerSectors = {
                                        employee: {
                                            account: {}
                                        }
                                    };
                                }

                            });
                        }

                        return data;
                    },
                    parse: function(data) {
                        angular.forEach(data, function(item) {
                            if (item.startedAt !== undefined) {
                                item.startedAt = kendo.toString(kendo.parseDate(item.startedAt), 'dd/MM/yyyy');
                            }

                            if (item.endedAt !== undefined) {
                                item.endedAt = kendo.toString(kendo.parseDate(item.endedAt), 'dd/MM/yyyy');
                            }

                            //copy employeeCustomerSectors so to splice in foreach
                            var employeeCustomerSectorsToShow = angular.copy(item.employeeCustomerSectors);
                            //copy for backup
                            item.employeeCustomerSectorsAll = angular.copy(item.employeeCustomerSectors);
                            if(item.employeeCustomerSectors !== null) {
                                angular.forEach(item.employeeCustomerSectors, function(employeeCustomerSector) {
                                    if(employeeCustomerSector.active === false) {
                                        var foundItemById = $filter('filter')(employeeCustomerSectorsToShow, {id: employeeCustomerSector.id}, true)[0];
                                        employeeCustomerSectorsToShow.splice(employeeCustomerSectorsToShow.indexOf(foundItemById), 1);
                                    }
                                });

                                item.employeeCustomerSectors = employeeCustomerSectorsToShow;
                                angular.forEach(item.employeeCustomerSectors, function(employeeCustomerSector) {
                                    employeeCustomerSector.employee.account.fullName =
                                        employeeCustomerSector.employee.account.lastName + " " + employeeCustomerSector.employee.account.firstName;
                                });
                            }
                        });
                        return data;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            sector: { defaultValue : { id : null, name : null} },
                            'sector.name': { editable: false },
                            sectorPosition: {defaultValue : { id : null, name : null}},
                            phone : { defaultValue : null},
                            startedAt: { type: 'date'},
                            endedAt: { type: 'date', defaultValue : null},
                            'employeeCustomerSectors': {},
                            sectorParent: { from: "sector.parent", defaultValue : { id : null, name : null} },
                            'sectorParent.name': { editable: false },
                            'sector.address': { editable: false },
                            'sector.addressNum': { editable: false },
                            'sector.postalCode': { editable: false },
                            'sector.brick': { editable: false },
                            'sector.osfeBrick': { editable: false },
                            'sector.cityName': { editable: false },
                            'sector.prefectureName': { editable: false },
                            main: { editable: true, type: "boolean" }
                        }
                    }
                }
            }),
            sortable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            columns: vm.sectorsGridColumns,
            toolbar: [{ name: "New Customer Sector",
                text: "New Customer Sector", template: "<input type='button' class='k-button' value = 'Προσθήκη Μονάδας Υγείας' ng-click='vm.openWindow()' />" },
                { template: "<div class='right'><label>Σύνολο: {{vm.sectorsGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }],
            edit: function(e) {
                e.model.dirty = true;
                vm.employeeDataItems = angular.copy(e.model);
                if(!e.model.isNew()) {
                    if (e.model.main) angular.element("[name=main]").prop("disabled", true);
                }
                this._editContainer.addClass("default-popup");
                vm.kendoGrid.defaultCustomEditPopup(this._editContainer, 1000, 470);
                // angular.element(e.container).width(1000);
                angular.element(e.container).data("kendoWindow").center();
            },
            editable: {
                mode: "popup"
            },
            save: function (e) {
                var customerSectorsGrid = angular.element("#sectorsGrid").data("kendoGrid");
                customerSectorsGrid.refresh();
            }
        };

        vm.clearWindow = function () {
            //clear inputs
            vm.newCustomerSector = vm.saveNewSector = null;

            //clear kendoComboBox
            angular.forEach(comboBoxes, function (item) {
                var filter = {};
                var comboBox = angular.element(item).data("kendoComboBox");
                if(comboBox) {
                    comboBox.value("");
                    if (item === "#cities") {
                        vm.refreshCombobox(filter, vm.citiesComboBoxOptions.dataSource);
                    } else if (item === "#prefectures") {
                        vm.refreshCombobox(filter, vm.prefecturesComboBoxOptions.dataSource);
                    }
                }
            });

            //clear employees selected
            vm.selectedEmployees = [];
        };

        vm.openWindow = function(){
            vm.initPopupGrid = true;
            var wdw = angular.element("#popup-new-customSector").data("kendoWindow");
            vm.clearWindow();
            wdw.center().open();

            vm.newSector = false;
            vm.sectorTypeComboBoxOptions.dataSource.filter(sectorTypeFilter());
            vm.savedSector = false;
            vm.todayDate = new Date();
            $("#started_at").data("kendoDatePicker").value(vm.todayDate);
            //clear dataItems selected
            vm.statusChanged = "opened";
        };

        vm.closeWindow = function() {
            var wdw = angular.element("#popup-new-customSector").data("kendoWindow");
            delete vm.newSectorObj;
            wdw.close();
            delete vm.initPopupGrid;
            //clear dataItems selected
            vm.statusChanged = "closed";
        };

        vm.saveCustomerSectors = function() {
            var wdw = angular.element("#popup-new-customSector").data("kendoWindow");

            var url = 'api/customer-sectors';

            vm.choices = [];
            vm.selectedEmployees.forEach(function(item,index){
                vm.choices.push(
                    {active: true,
                        myTarget: vm.myTarget,
                        employee: {
                            id: item.id
                        },
                        id: null
                    }
                )
            });

            vm.newCustomerSector.employeeCustomerSectors =  vm.choices;
            vm.newCustomerSector.id =  null;
            vm.newCustomerSector.customer = {
                id :  vm.selectedUserId
            };

            vm.newCustomerSector.startedAt =  vm.todayDate;
            if (vm.newSectorObj) {
                vm.newCustomerSector.sector = vm.newSectorObj;
            }

            $http.post(url,  vm.newCustomerSector)
                .success(function (response) {
                    angular.element("#sectorsGrid").data("kendoGrid").dataSource.read();
                    delete vm.newSectorObj;
                    wdw.close();
                })
                .error(function (response) {});
            //clear dataItems selected
            vm.statusChanged = "saved";

        };

        vm.timeToString = function(date) {
            return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        };

        vm.gridColumns = [
            {
                title: "Στοιχεία Χρήστη",
                columns: [
                    {
                        field: "account.login",
                        title: "Όνομα σύνδεσης",
                        editor: function(container, options) {
                            angular.element('<select id="comboBox" data-bind="value:account.id"></select>')
                                .appendTo(container)
                                .kendoComboBox({
                                    dataSource: vm.usersComboBoxDataSource,
                                    filter: "startswith",
                                    change: function(e) {
                                        var index = e.sender.selectedIndex;
                                        $('#firstName').data('kendoComboBox').select(index);
                                        $('#lastName').data('kendoComboBox').select(index);
                                    },
                                    valuePrimitive: true,
                                    dataTextField: "login",
                                    dataValueField: "id",
                                    template: "<table><span width='100px'><strong>(${login}) </strong></span>" +
                                    "<span width='50px'>${firstName} </span>" +
                                    "<span width='50px'>${lastName}</span></table>"
                                });
                        },
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    {
                        field: "account.firstName",
                        title: "Όνομα",
                        editor: function(container, options) {
                            angular.element('<select id="firstName" data-bind="value:account.firstName"></select>')
                                .appendTo(container)
                                .kendoComboBox({
                                    enable: false,
                                    dataSource: vm.usersComboBoxDataSource,
                                    dataTextField: "firstName"
                                });
                        },
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    {
                        field: "account.lastName",
                        title: "Επώνυμο",
                        editor: function(container, options) {
                            angular.element('<select id="lastName" data-bind="value:account.lastName"></select>')
                                .appendTo(container)
                                .kendoComboBox({
                                    enable: false,
                                    dataSource: vm.usersComboBoxDataSource,
                                    dataTextField: "lastName"
                                });
                        },
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    }
                ]
            },
            {
                field: "phone",
                title: "Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];

        vm.selectedEmployees = [];

        //POPUP grid
        vm.employeeAllGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/me/employees',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    },
                    parameterMap: function (data, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        } else {
                            return data;
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        if(data !== undefined) {
                            data.content.forEach(function(item){

                                if (!item.account) {
                                    item.account = {
                                        firstName: "",
                                        lastName: "",
                                        login: ""
                                    };
                                }

                                if (!item.supervisor) {
                                    item.supervisor = {
                                        account: {fullName: ""}
                                    }
                                } else {
                                    if (item.supervisor.account.lastName && item.supervisor.account.firstName) {
                                        item.supervisor.account.fullName =
                                            item.supervisor.account.lastName + " " + item.supervisor.account.firstName;
                                    } else if (!item.supervisor.account.firstName) {
                                        item.supervisor.account.fullName = item.supervisor.account.lastName;
                                    } else if (!item.supervisor.account.lastName) {
                                        item.supervisor.account.fullName = item.supervisor.account.firstName;
                                    }
                                }
                            });
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: false },
                            district : {defaultValue: {}},
                            password: { type: 'password', editable: true, nullable: false, defaultValue: null },
                            supervisor: { defaultValue: { account: { defaultValue: {}} }},
                            account: { defaultValue: {} },
                            mobileVersion: {editable:false}
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            sortable: true,
            pageable: true,
            selectable: true,
            scrolable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            columns: vm.gridColumns,
            selectedItems: function(dataItems) {
                vm.selectedEmployees = dataItems;
            },
            dataBound: function (e) {
                angular.element("#employeeAllGridcheckbox").prop('checked', false);
                //toggle class on click
                angular.element(".checkbox").bind("change", function (e) {
                    angular.element(e.target).closest("tr").toggleClass("k-state-selected");
                });

                var view = this.dataSource.data();
                var me = this;

                if (vm.selectedEmployees) {
                    var selectedEmployeesViewCounter = 0;
                    angular.forEach( view, function(item) {
                        var foundItemById = $filter('filter')(vm.selectedEmployees, {id: item.id}, true)[0];
                        if(vm.selectedEmployees.indexOf(foundItemById) !== -1){
                            me.tbody.find("tr[data-uid='" + item.uid + "']")
                                .addClass("k-state-selected");
                            item.selected = true;
                            selectedEmployeesViewCounter++
                        }
                    });
                    if(selectedEmployeesViewCounter === view.length) {
                        angular.element("#employeeAllGridcheckbox").prop('checked', true);
                    }
                }
            }
        };

        vm.addSector = function(){
            vm.newSector = true;
            vm.sectorTypeComboBoxOptions.dataSource.filter(sectorTypeFilter());
            vm.saveNewSector = {};
        };


        vm.suggestBricks = function($value) {
            var data = {};
            data.filter ={
                filters: [
                    {
                        "field": "postalCode",
                        "operator": "eq",
                        "value": $value,
                        "ignoreCase": true
                    }
                ]};

            data.page = 1;
            data.pageSize = 1;


            $http.get('/api/masterdata/bricks',
                {params: data})
                .success(function (response) {
                    if (response.content !== null
                        && response.content instanceof Array
                        && response.content.length > 0) {
                        vm.saveNewSector.brick = response.content[0].territoryNo;
                    }
                })
                .error(function (response) {});


            $http.get('/api/masterdata/brick-osfes',
                {params: data})
                .success(function (response) {
                    if (response.content !== null
                        && response.content instanceof Array
                        && response.content.length > 0) {
                        vm.saveNewSector.osfeBrick = response.content[0].territoryName;
                    }
                })
                .error(function (response) {});
        };

        vm.toggleSelectAll = function (ev) {
            vm.allSelected = !vm.allSelected;
            var grid = $(ev.target).closest("[kendo-grid]").data("kendoGrid");
            angular.forEach( grid.dataSource.data(), function( dataItem ) {
                dataItem.myTarget = vm.allSelected;
            });
        };

        vm.onMyTargetClick = function (dataItem) {
            dataItem.myTarget = !dataItem.myTarget;
            if (!dataItem.myTarget) {
                vm.allSelected = dataItem.myTarget;
            }
        };

        //check customer type
        $http.get('api/customers/' + vm.selectedUserId)
            .success(function (response) {
                vm.customer = response;
                vm.allSelected = false;

                var foundItemById = $filter('filter')(vm.customer.customerTypes, {id: 1}, true)[0];

                if ( vm.customer.customerTypes.indexOf(foundItemById) !== -1) {
                    var grid = angular.element("#sectorsGrid").data("kendoGrid");

                    vm.sectorsGridColumns.push({
                        field: "employeeCustomerSectors",
                        title: "Ιατρικοί Επισκέπτες",
                        filterable: false,
                        template: employeeCustomerSectorsDisplay,
                        editor: function (container, options) {
                            angular.element('<select id="employeeCustomerSectors" style="width:800px;" multiple="multiple" data-bind="value: employeeCustomerSectors"/>')
                                .appendTo(container)
                                .kendoMultiSelect({
                                    dataSource: new kendo.data.DataSource({
                                        serverFiltering: true,
                                        transport: {
                                            read: function (o) {
                                                if (o.data.filter === undefined) {
                                                    o.data.filter = {filters: []};
                                                }

                                                o.data.page = 1;
                                                o.data.pageSize = 10000;
                                                $http.get('/api/me/employees',
                                                    {params: o.data})
                                                    .success(function (response) {
                                                        o.success(response.content);
                                                    })
                                                    .error(function (response) {});
                                            }
                                        },
                                        schema: {
                                            parse: function(data) {
                                                angular.forEach(data, function(item) {
                                                    //define object for data binding
                                                    if(item.account !== null) {
                                                        item.active = item.myTarget = true;
                                                        item.employee = {
                                                            account : {
                                                                fullName: item.account.lastName + " " + item.account.firstName
                                                            },
                                                            id: item.id
                                                        }
                                                    }
                                                    //if exists but active false keep employeeCustomerSectors id
                                                    if ( vm.employeeDataItems.employeeCustomerSectorsAll ) {
                                                        if (vm.employeeDataItems.employeeCustomerSectorsAll.length) {
                                                            var foundItemByEmployeeId = $filter('filter')(vm.employeeDataItems.employeeCustomerSectorsAll, {employee: {id: item.id}}, true)[0];
                                                            if (vm.employeeDataItems.employeeCustomerSectorsAll.indexOf(foundItemByEmployeeId) !== -1) {
                                                                item.id = foundItemByEmployeeId.id
                                                            } else {
                                                                item.id = null;
                                                            }
                                                        } else {
                                                            item.id = null;
                                                        }
                                                    }

                                                });
                                                return data;
                                            }
                                        }
                                    }),
                                    valuePrimitive: false,
                                    dataTextField: "employee.account.fullName",
                                    dataValueField: "employee.id"
                                });

                            vm.myTargetGridOptions = {
                                dataSource : new kendo.data.DataSource({
                                    data: angular.element("#employeeCustomerSectors").data("kendoMultiSelect").dataItems(),
                                    pageSize: 10,
                                    schema: {
                                        model: {
                                            id: "id",
                                            fields: {
                                                id: { editable: false, nullable: true },
                                                'employee.account.fullName': { editable: false },
                                                myTarget: { type: "boolean" }
                                            }
                                        }
                                    }
                                }),
                                sortable: true,
                                pageable: true,
                                scrolable: true,
                                editable: true,
                                filterable: {
                                    mode: "row"
                                },
                                resizable: true,
                                columns: [
                                    {
                                        field: "employee.account.fullName",
                                        title: "Επιλεγμένοι Ιατρικοί Επισκέπτες",
                                        filterable: {
                                            cell: {
                                                operator: "startswith",
                                                template: function (args) {
                                                    KendoFilter.getTemplate(args);
                                                },
                                                showOperators: false
                                            }
                                        }
                                    },
                                    {
                                        template: '<input type="checkbox" ng-click="vm.onMyTargetClick( dataItem );" ng-checked="dataItem.myTarget" class="checkbox"/>', //
                                        title: "<label>myTarget </label>" +
                                        "<input id='checkboxMyTarget' type='checkbox' class='checkbox' ng-checked='vm.allSelected' " +
                                        "ng-click='vm.toggleSelectAll($event)'/>",
                                        width: 100,
                                        filterable: false
                                    }
                                ]
                            };

                            angular.element($compile('<div kendo-grid id="employeeCustomerSectorsGrid" style="width:800px;" k-options="vm.myTargetGridOptions" ' +
                                                        'data-bind="source: employeeCustomerSectors"></div>')($scope))
                                .appendTo(container);
                        }
                    });

                    grid.setOptions({
                        columns: vm.sectorsGridColumns
                    });
                } else {
                    vm.hasAssignations = false;
                }
            })
            .error(function (response) {});
    }
})();
