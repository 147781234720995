(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('EmployeeCustomerSectorDetailController', EmployeeCustomerSectorDetailController);

    EmployeeCustomerSectorDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'EmployeeCustomerSector', 'Employee', 'CustomerSector', 'Sector'];

    function EmployeeCustomerSectorDetailController($scope, $rootScope, $stateParams, entity, EmployeeCustomerSector, Employee, CustomerSector, Sector) {
        var vm = this;
        vm.employeeCustomerSector = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:employeeCustomerSectorUpdate', function(event, result) {
            vm.employeeCustomerSector = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
