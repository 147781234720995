(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MDProductsController', MDProductsController);

    MDProductsController.$inject = ['$scope', 'DateUtils', '$http', '$filter', 'KendoFilter', 'DataUtils', 'ApplicationParameters', 'KendoGrid', 'CustomUtils'];

    function MDProductsController ($scope, DateUtils, $http, $filter, KendoFilter, DataUtils, ApplicationParameters, KendoGrid, CustomUtils) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(67);
        vm.pageHeading = $filter('translate')('masterData.products.title');
        vm.selectedProductCategories = [];

        function productCategoriesDisplay(data, container) {
            if(data.productCategories) {
                return data.productCategories.map(function(elem) {
                    return elem.name
                }).join(', ');
            }
            return "";
        }

        vm.measurementUnitsDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get('/api/masterdata/measurement-units',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        vm.taxClassesDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get('/api/tax-classes/search',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        vm.setImage = function ($file, product) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                console.log(product);
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        product.image = base64Data;
                        product.imageContentType = $file.type;
                    });
                });
            }
        };

        var purchasePharmaciesColumns = [ "barcode", "code", "supplierCode", "atcCode",
            "packaging", "defaultDiscount", "availableForVisit", "availableForPurchase" ];

        vm.onAddProductClick = function () {
            const grid = $("#mainGrid").data("kendoGrid");
            grid.addRow();
        };

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        if(vm.selectedProductCategories.length) {
                            o.data.filter.filters.push(
                                { "field": "productCategories.id", "operator": "in",
                                    "value": vm.selectedProductCategories.toString() }
                            );
                        }

                        $http.get('/api/masterdata/products',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    create: function(o) {
                        var data = o.data.models[0];
                        if(!data.measurementUnit.id)
                            data.measurementUnit = null;
                        if(!data.taxClass.id)
                            data.taxClass = null;

                        $http.post('/api/products', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    update: function(o) {
                        var data = o.data.models[0];
                        if(!data.measurementUnit.id)
                            data.measurementUnit = null;
                        if(!data.taxClass.id)
                            data.taxClass = null;

                        $http.put('/api/products', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (data, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        } else {
                            return data;
                        }

                    }
                },
                schema: {
                    data: function (data) {
                        if(data.content != undefined) {
                            data.content.forEach(function (item) {
                                if (item.measurementUnit === null) item.measurementUnit = {};
                                if (item.taxClass === null) item.taxClass = {};
                            });
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            shortName: { validation: { required: true , validation: { required: true, min: 3}} },
                            activeSubstance: { validation: { required: false , validation: { required: true, min: 3}} },
                            updatedAt: { editable: false },
                            defaultDiscount: { editable: true, nullable: true, type: "number"},
                            availableForVisit: { type:"boolean" },
                            availableForPurchase: { type:"boolean" },
                            measurementUnit:{defaultValue: {id: null, name : null}},
                            taxClass:{defaultValue: {id: null, name : null}}
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            dataBound: function() {
                //var grid = $('#mainGrid').data('kendoGrid');
                var self = this;

                ApplicationParameters.hasModule('Purchase_Pharmacies', false).then(function(response) {
                    if (response.data.content[0].value != 1) {
                        self.tbody.find("tr.k-master-row>.k-hierarchy-cell>a").hide();
                    //     angular.forEach( grid.columns, function (item, index) {
                    //         if( purchasePharmaciesColumns.indexOf( item.field ) !== -1 ) {
                    //             grid.hideColumn(index);
                    //         }
                    //     });
                    }
                    // vm.initPopupGrid = true;
                });
            },
            sortable: true,
            pageable: {
                messages: {
                    display: "{0} - {1} από {2} Προιόντα", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                    empty: "Δεν βρέθηκαν Προιόντα",
                    page: "Σελίδα",
                    allPages: "Όλα",
                    of: "από {0}", // {0} is total amount of pages.
                    itemsPerPage: "εγγραφές ανά σελίδα",
                    first: "Πήγαινε στη πρώτη σελίδα",
                    previous: "Πήγαινε στη τελευταία σελίδα",
                    next: "Πήγαινε στην επόμενη σελίδα",
                    last: "Πήγαινε στη τελευταία σελίδα",
                    refresh: "Ανανέωση"
                }
            },
            noRecords: {
                template: "Δεν βρέθηκαν Προιόντα"
            },
            filterable: {
                mode: "row"
            },
            columns: [
                {field: "name", title: "Προϊόν",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "activeSubstance", title: "Δραστική",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {
                    field: "productCategories",
                    title: "Κατηγορίες Προϊόντων",
                    filterable: {
                        cell: {
                            operator: "in",
                            showOperators: false,
                            template: function (args) {
                                args.element.removeAttr('data-bind').kendoMultiSelect({
                                    dataSource: new kendo.data.DataSource({
                                        serverFiltering: true,
                                        transport: {
                                            read: function (o) {
                                                if (o.data.filter == undefined) {
                                                    o.data.filter = {filters: []};
                                                }
                                                o.data.page = 1;
                                                o.data.pageSize = 10000;
                                                $http.get('/api/masterdata/product-categories',
                                                    {params: o.data})
                                                    .success(function (response, status, headers) {
                                                        response.content.unshift({id:"none", name :"(Κενό)"});
                                                        o.success(response.content);
                                                    })
                                                    .error(function (response) {
                                                        console.log(response);
                                                    });
                                            }
                                        }
                                    }),
                                    valuePrimitive: true,
                                    dataTextField: "name",
                                    dataValueField: "id",
                                    change: function () {
                                        vm.selectedProductCategories = this.value();
                                        vm.mainGridOptions.dataSource.read();
                                    },
                                    select: function (e) {
                                        var dataItem = this.dataSource.view()[e.item.index()];
                                        var values = this.value();

                                        if (dataItem.id === "none") {
                                            this.value("");
                                        } else if (values.indexOf("none") !== -1) {
                                            values = angular.element.grep(values, function (value) {
                                                return value !== "none";
                                            });
                                            this.value(values);
                                        }
                                    }
                                })
                            }
                        }
                    },
                    template: productCategoriesDisplay,
                    editor: function (container, options) {
                        angular.element('<select id="productCategories" multiple="multiple" data-bind="value: productCategories"/>')
                            .appendTo(container)
                            .kendoMultiSelect({
                                dataSource: new kendo.data.DataSource({
                                    serverFiltering: true,
                                    transport: {
                                        read: function (o) {
                                            if (o.data.filter == undefined) {
                                                o.data.filter = {filters: []};
                                            }

                                            o.data.page = 1;
                                            o.data.pageSize = 10000;
                                            $http.get('/api/masterdata/product-categories',
                                                {params: o.data})
                                                .success(function (response, status, headers) {
                                                    o.success(response.content);
                                                })
                                                .error(function (response) {
                                                    console.log(response);
                                                });
                                        }
                                    }
                                }),
                                valuePrimitive: false,
                                dataTextField: "name",
                                dataValueField: "id"
                            });
                    },
                    sortable: false
                },
                {field: "barcode", title: "Barcode",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "code", title: "Κωδικός Είδους",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "supplierCode", title: "Κωδικός Προμηθευτή",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "atcCode", title: "ATC Code",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "packaging", title: "Συσκευασία",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "defaultDiscount", title: "Έκπτωση είδους", template: "#= (defaultDiscount == null) ? ' ' : kendo.format('{0:p0}', defaultDiscount / 100)#",
                    editor: function(container,options){
                        angular.element('<input data-bind="value: defaultDiscount">')
                            .appendTo(container)
                            .kendoNumericTextBox({format:"{0:#\\%}"});
                    },
                    filterable: {
                        cell: {
                            operator: "eq",
                            template: function (args) {
                                args.element.kendoNumericTextBox({
                                    format: "{0:#\\%}"
                                });
                            },
                            showOperators: false
                        }
                    }},
                {
                    field: "measurementUnit.name",
                    title: "Μονάδα Μέτρησης",
                    editor: function(container, options) {
                        angular.element('<select id="measurement-unit" data-bind="value:measurementUnit.id"></select>')
                            .appendTo(container)
                            .kendoComboBox({
                                dataSource: vm.measurementUnitsDataSource,
                                filter: "startswith",
                                valuePrimitive: true,
                                dataTextField: "name",
                                dataValueField: "id"
                            });
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 145
                },
                {
                    field: "taxClass.name",
                    title: "Φ.Π.Α. Είδους",
                    editor: function(container, options) {
                        angular.element('<select id="tax-class" data-bind="value:taxClass.id"></select>')
                            .appendTo(container)
                            .kendoComboBox({
                                dataSource: vm.taxClassesDataSource,
                                filter: "startswith",
                                valuePrimitive: true,
                                dataTextField: "name",
                                dataValueField: "id"
                            });
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 145
                },
                {template: '<md-checkbox class="checkbox-toolbar customers green inline"  aria-label="availableForVisit" ng-model="dataItem.availableForVisit" ' +
                    'ng-disabled="true"></md-checkbox>',
                    field: "availableForVisit", title: "Είδη για επίσκεψη",
                    editor: function(container,options){
                        angular.element('<md-checkbox ng-model="dataItem.availableForVisit"  aria-label="availableForVisit" ' +
                                'class="checkbox-toolbar customers green inline"></md-checkbox>')
                            .appendTo(container);
                    },
                    filterable: {
                        cell: {
                            operator: "eqbool",
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Ναι", value: "true" },
                                            { text: "Όχι", value: "false" }]
                                    }),
                                    valuePrimitive: true

                                })
                            },
                            showOperators: false
                        }
                    }},
                {template: '<md-checkbox class="checkbox-toolbar customers green inline"  aria-label="availableForPurchase" ng-model="dataItem.availableForPurchase" ' +
                'ng-disabled="true"></md-checkbox>',
                    field: "availableForPurchase", title: "Είδη για παραγγελια",
                    editor: function(container,options){
                        angular.element('<md-checkbox ng-model="dataItem.availableForPurchase"  aria-label="availableForPurchase" ' +
                            'class="checkbox-toolbar customers green inline"></md-checkbox>')
                            .appendTo(container);
                    },
                    filterable: {
                        cell: {
                            operator: "eqbool",
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Ναι", value: "true" },
                                            { text: "Όχι", value: "false" }]
                                    }),
                                    valuePrimitive: true

                                })
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    template: '<md-checkbox class="checkbox-toolbar customers green inline"  aria-label="calcPFS" ng-model="dataItem.calcPFS" ' +
                        'ng-disabled="true"></md-checkbox>',
                    field: "calcPFS", title: "ΠΦΣ",
                    editor: function(container,options){
                        angular.element('<md-checkbox ng-model="dataItem.calcPFS"  aria-label="calcPFS" ' +
                            'class="checkbox-toolbar customers green inline"></md-checkbox>')
                            .appendTo(container);
                    },
                    filterable: {
                        cell: {
                            operator: "eqbool",
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Ναι", value: "true" },
                                            { text: "Όχι", value: "false" }]
                                    }),
                                    valuePrimitive: true

                                })
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "image", title: "Φωτογραφία είδους",hidden:true,
                    editor: function (container, options) {
                        angular.element('<div ng-if="dataItem.image" class="form-group">' +
                            '<img data-ng-src="{{\'data:\' + dataItem.imageContentType + \';base64,\' + dataItem.image}}" style="max-width: 80%;"/>' +
                            '</div></br><md-button type="button" class="md-button md-ink-ripple md-raised md-warn photo-btn" ngf-select ngf-change="vm.setImage($file, dataItem)" accept="image/*">' +
                            '{{ (dataItem.image == null) ? "Προσθήκη εικόνας" : "Αλλαγή εικόνας" }}</md-button><md-button type="button" class="md-button md-ink-ripple md-raised md-warn photo-btn" ' +
                            'ng-click="dataItem.image=null;dataItem.imageContentType=null;" ng-if="dataItem.image !== null">Διαγραφή εικόνας</md-button>')
                            .appendTo(container);
                    }
                },
                {
                    command:[
                        {name:"edit", text: "Επεξεργασία"}
                    ],
                    text: "Επεξεργασία",
                    title: "&nbsp;",
                    width: 150
                }
            ],
            height: vm.contentHeight,
            edit: function(e) {
                e.model.dirty = true;
                KendoGrid.defaultEditPopup(this._editContainer);
            },
            editable: { mode: "popup" }
        };

        vm.dateValidation = function(input, params) {
            var startedDate = angular.element("[name=startedDate]").data("kendoDatePicker").value();
            var endedDate = angular.element("[name=endedDate]").data("kendoDatePicker").value();

            if (input.is("[name=endedDate]")) {
                var gridData= vm.initialGridData;

                var highest = { startedDate: null };
                for (var i=gridData.length-1; i>=0; i--) {
                    if (gridData[i].id != vm.editRow.id) {
                        var tmp = gridData[i].startedDate;
                        if (tmp > highest.startedDate) {
                            highest = gridData[i];
                        }
                    }
                }
                if(highest.startedDate) {
                    if (startedDate && endedDate) {
                        input.attr("data-customValidation-msg", "Η «Ημερομηνία Από» δεν μπορεί να είναι μεταγενέστερη της «Ημερομηνίας Εως».");

                        if (startedDate > endedDate) {
                            return false;
                        }
                    }

                    if ( (startedDate >= highest.startedDate && (startedDate <= highest.endedDate || highest.endedDate == null)) ||
                        (endedDate >= highest.startedDate && (endedDate <= highest.endedDate || highest.endedDate == null))) {
                        input.attr("data-customValidation-msg", "Υπάρχουν αποθηκευμένες τιμές γι'αυτές τις ημερομηνίες.");
                        return false;
                    }
                }

            }
            return true;
        };

        vm.detailedGridOptions = function (dataItem) {
            if (dataItem != undefined) {
                return {
                    dataSource: {
                        batch: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                angular.forEach( o.data.filter.filters, function(item) {
                                    if ( item.value instanceof Date) {
                                        item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                                    }
                                });

                                o.data.filter.filters.push({field: "product.id", operator: "eq", value: dataItem.id});
                                $http.get('/api/product-prices/search',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            },
                            create: function(o) {
                                var data = o.data.models[0];
                                data.product = {id : dataItem.id};
                                data.startedDate = DateUtils.convertLocalDateToServer(data.startedDate);
                                data.endedDate = DateUtils.convertLocalDateToServer(data.endedDate);

                                $http.post('/api/product-prices', data)
                                    .success(function (response) {
                                        o.success(response);
                                        angular.element("#detailGrid").data("kendoGrid").dataSource.read();
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            },
                            update: function(o) {
                                var data = o.data.models[0];
                                data.startedDate = DateUtils.convertLocalDateToServer(data.startedDate);
                                data.endedDate = DateUtils.convertLocalDateToServer(data.endedDate);

                                $http.put('/api/product-prices', data)
                                    .success(function (response) {
                                        o.success(response);
                                        angular.element("#detailGrid").data("kendoGrid").dataSource.read();
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        },
                        schema: {
                            data: function (data) {
                                vm.initialGridData = data.content;
                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: { editable: false, nullable: true },
                                    startedDate: {type: 'date', defaultValue: "", nullable: true, validation: { customValidation: vm.dateValidation }},
                                    endedDate: {type: 'date', defaultValue: "", nullable: true, validation: { customValidation: vm.dateValidation }},
                                    retailPrice: { editable: true, nullable: true, type: "number" },
                                    wholesalePrice: { editable: true, nullable: true, type: "number"},
                                    costPrice: { editable: true, nullable: true, type: "number"}
                                }
                            }
                        },
                        serverFiltering: true,
                        serverSorting: true,
                        serverPaging: true,
                        pageSize: 15
                    },
                    toolbar: [{name :"create", text: "Προσθήκη Νέας εγγραφής"}],
                    pageable: true,
                    sortable: true,
                    filterable: {
                        mode: "row"
                    },
                    columns: [
                        {
                            field: "startedDate",
                            title: "Ημερομηνία Από",
                            template: "#= startedDate == null ? '' : kendo.toString(kendo.parseDate(startedDate), 'dddd, dd/MM/yyyy')#",
                            filterable: {
                                cell: {
                                    operator: "eqdateonly",
                                    showOperators: false
                                }
                            }
                        },
                        {
                            field: "endedDate",
                            title: "Ημερομηνία Εως",
                            template: "#= endedDate == null ? '' : kendo.toString(kendo.parseDate(endedDate), 'dddd, dd/MM/yyyy')#",
                            filterable: {
                                cell: {
                                    operator: "eqdateonly",
                                    showOperators: false
                                }
                            }
                        },
                        {field: "retailPrice", title: "Τιμή Λιανικής", template: "#= (retailPrice == null) ? ' ' : kendo.format('{0:c2}', retailPrice)#",
                            editor: function(container,options){

                                angular.element('<input data-bind="value: retailPrice">')
                                    .appendTo(container)
                                    .kendoNumericTextBox({format:"{0:c2}", min: 0});
                            },
                            filterable: {
                                cell: {
                                    operator: "eq",
                                    template: function (args) {
                                        args.element.kendoNumericTextBox({format: "{0:c2}"});
                                    },
                                    showOperators: false
                                }
                            }},
                        {field: "wholesalePrice", title: "Τιμή Χονδρικής", template: "#= (wholesalePrice == null) ? ' ' : kendo.format('{0:c2}', wholesalePrice)#",
                            editor: function(container,options){

                                angular.element('<input data-bind="value: wholesalePrice">')
                                    .appendTo(container)
                                    .kendoNumericTextBox({format:"{0:c2}", min: 0});
                            },
                            filterable: {
                                cell: {
                                    operator: "eq",
                                    template: function (args) {
                                        args.element.kendoNumericTextBox({
                                            format: "{0:c2}"
                                        });
                                    },
                                    showOperators: false
                                }
                            }},
                        {field: "costPrice", title: "Τιμή Κόστους", template: "#= (costPrice == null) ? ' ' : kendo.format('{0:c2}', costPrice)#",
                            editor: function(container,options){

                                angular.element('<input data-bind="value: costPrice">')
                                    .appendTo(container)
                                    .kendoNumericTextBox({format:"{0:c2}", min: 0});
                            },
                            filterable: {
                                cell: {
                                    operator: "eq",
                                    template: function (args) {
                                        args.element.kendoNumericTextBox({
                                            format: "{0:c2}"
                                        });
                                    },
                                    showOperators: false
                                }
                            }},
                        {
                            command:[ {name:"edit", text: "Επεξεργασία"}],
                            title: "&nbsp;"
                        }
                    ],
                    editable: "popup",
                    edit: function(e) {
                        vm.editRow = e.model;
                        var updateBtnTxt = "Αποθήκευση",
                            cancelBtnTxt = "Ακύρωση",
                            curr_container = this._editContainer;

                        var validator = this.editable.validatable;

                        e.container.find("[name=startedDate]").change(function() {
                            validator.validateInput($("[name=endedDate]"));
                        });

                        e.container.find("[name=endedDate]").change(function() {
                            validator.validateInput($("[name=startedDate]"));
                        });

                        //sets the text of the "Update" button
                        $(curr_container).find("a.k-grid-update").text(updateBtnTxt);
                        //sets the text of the "Cancel" button
                        $(curr_container).find("a.k-grid-cancel").text(cancelBtnTxt);
                    }
                };
            }
        };

    }
})();
