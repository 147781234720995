(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('TradePolicyRuleDetailController', TradePolicyRuleDetailController);

    TradePolicyRuleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TradePolicyRule', 'TradePolicy'];

    function TradePolicyRuleDetailController($scope, $rootScope, $stateParams, entity, TradePolicyRule, TradePolicy) {
        var vm = this;
        vm.tradePolicyRule = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:tradePolicyRuleUpdate', function(event, result) {
            vm.tradePolicyRule = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
