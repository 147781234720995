/**
 * Created by Kristy on 29/1/2020.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MedReportController', MedReportController);

    MedReportController.$inject = [ '$state' ];

    function MedReportController ( $state ) {
        var vm = this;
        vm.$state = $state;
        vm.pageHeading = "Συνολα & Συγκριτικα";
    }
})();
