/**
 * Created by Kristy on 21/12/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminCustomerProductsAllController', AdminCustomerProductsAllController);

    AdminCustomerProductsAllController.$inject = ['$scope', 'employee', '$timeout', 'favouriteFilters','$http', 'FavouriteFiltersService', 'MasterToKendoFiltersService', 'KendoFilter', 'MasterFiltersService'];

    function AdminCustomerProductsAllController ($scope, employee, $timeout, favouriteFilters, $http, FavouriteFiltersService, MasterToKendoFiltersService, KendoFilter, MasterFiltersService) {
        var vm = this;

        vm.favouriteFilters = favouriteFilters.data.content;
        vm.employee = employee;
        vm.masterFilters = { selectedEmployees: [], selectedCustomers: [], selectedProducts: [] };
        vm.pageHeading = 'Ενημέρωση ανά Επαγγελματία Υγείας για Φαρμακα';
        vm.disabled = true;
        vm.isEdited = vm.isRenameMode = false;
        vm.selectedFilter = {};
        vm.mainGrid = {};
        vm.masterOptions = [];
        vm.filter = {};
        vm.view = 'customerProducts';

        var setView = function() {
            var filterable = vm.masterFilters.selectedEmployees.length && vm.masterFilters.from && vm.masterFilters.to ? {
                mode: "row"
            } : false;

            vm.disabled = !vm.masterFilters.selectedEmployees.length || !vm.masterFilters.from || !vm.masterFilters.to;
            vm.mainGrid.setOptions({
                sortable: vm.masterFilters.selectedEmployees.length && vm.masterFilters.from && vm.masterFilters.to,
                filterable: filterable
            });
        };

        $scope.$watchGroup(["vm.masterFilters.selectedEmployees", "vm.masterFilters.from", "vm.masterFilters.to"], function () { setView(); });
        vm.masterFiltersEdited = function(){
            var gridOptions = FavouriteFiltersService.getKendoOptions(vm.mainGrid);
            $timeout(function(){
                vm.isEdited = !!(vm.selectedFilter.kendoOptions && vm.masterOptions.length &&
                    (!angular.equals(vm.masterOptions, MasterToKendoFiltersService.getMasterFilters([], vm.masterFilters, vm.view)) || !angular.equals(JSON.parse(vm.selectedFilter.kendoOptions), gridOptions)) );
            });
        };
        $scope.$watch("vm.masterFilters", vm.masterFiltersEdited, true);

        vm.employeesMultiOptions = MasterFiltersService.getEmployeesComboBoxOptions(["all", "me"]);
        vm.productsMultiDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function (o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = {filters: []};
                    }

                    o.data.page = 1;
                    o.data.pageSize = 150;
                    $http.get('/api/masterdata/products',
                        {params: o.data})
                        .success(function (response) {
                            response.content.unshift({id:"all", name:"Επιλογή όλων", prefecture: {}});
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        vm.productsMultiOptions = {
            dataSource: vm.productsMultiDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id",
            select: function(e) {
                var dataItem = this.dataSource.view()[e.item.index()];
                var values = this.value();
                var self = this;

                MasterFiltersService.selectAllNone(self, dataItem, values, ["all"]);
            }
        };
        vm.customersMultiOptions = MasterFiltersService.getCustomersComboBoxOptions();

        //refresh filters
        var masterFilters = [vm.employeesMultiOptions, vm.customersMultiOptions];
        angular.forEach(masterFilters, function(masterFilter) {
            masterFilter.dataSource.filter({});
        });

        //Date Filter

        vm.clearDatePickers = function () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        };

        vm.toolbarOptions = {
            items: [
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Από: </label><input class='inline' kendo-date-picker id='from' options='vm.fromOptions' k-ng-model='vm.masterFilters.from'/>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Εώς: </label><input class='inline' kendo-date-picker id='to' options='vm.toOptions' k-ng-model='vm.masterFilters.to'/>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Προϊόν: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select multiple='multiple' " +
                    "k-options='vm.productsMultiOptions' k-ng-model='vm.masterFilters.selectedProducts'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Ιατρικός Επισκέπτης: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.employeesMulti' multiple='multiple' " +
                    "k-options='vm.employeesMultiOptions' k-ng-model='vm.masterFilters.selectedEmployees'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Επαγγελματίας Υγείας: </label><select id='multiSelectCustomers' name='multiSelect' class='inline' kendo-multi-select multiple='multiple' " +
                    "k-options='vm.customersMultiOptions' k-ng-model='vm.masterFilters.selectedCustomers'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary' aria-label='search' ng-click='vm.mainGridOptions.dataSource.read()' ng-disabled='vm.disabled'>" +
                    "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>", attributes: { class: "right" }
                }
            ]
        };

        vm.fromOptions = {
            change: function() {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.from = value;
                } else {
                    vm.masterFilters.from = null;
                }
            }
        };

        vm.toOptions = {
            change: function() {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.masterFilters.to = value;
                } else {
                    vm.masterFilters.to = null;
                }
            }
        };

        vm.resetGridColumns = function() {
             return [
                {
                    field: "rowObject.name",
                    title: "Προϊόν",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                }
            ];
        };

        vm.gridColumns = vm.resetGridColumns();

        vm.mainGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function (o) {
                        if (o.data.filter == undefined) o.data.filter = { filters: [] };
                        o.data.filter.filters = MasterToKendoFiltersService.getMasterFilters(o.data.filter.filters, vm.masterFilters, vm.view);
                        o.data.filter.filters.push({
                            field: "event.eventCustomerSector.customerSector",
                            operator: "isnotnull",
                            value: "null"
                        });

                        $http.get('api/agreements/count-stats', {params: o.data})
                            .success(function (response) {
                                vm.gridColumns = vm.resetGridColumns();
                                o.success(response);
                                if (response.length) {
                                    angular.forEach(response[0].rowValues, function (item, index) {
                                        vm.gridColumns.push({
                                            field: "rowValues[" + index + "].value",
                                            title: "Εβδομάδα " + item.columnName.match(/[a-zA-Z]+|[0-9]+/g)[1],
                                            filterable: false
                                        });
                                    });

                                    var grid = $('#mainGrid').data('kendoGrid');
                                    grid.setOptions({
                                        columns: vm.gridColumns
                                    });
                                }
                                angular.element("#mainGrid").data("kendoGrid").refresh();
                            })
                            .error(function (response) {});
                    }
                },
                schema: {
                    data: function (data) {
                        if(data != undefined) {
                            data.forEach(function(item){
                                if (!item.rowValues.length) {
                                    angular.forEach( data[0].rowValues, function (rowValue) {
                                        item.rowValues.push({columnName: rowValue.columnName, value: null})
                                    })
                                }
                            });
                        }
                        return data;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: false }
                        }
                    }
                }
            }),
            sortable: false,
            resizable: true,
            filterable: false,
            columns: vm.gridColumns,
            toolbar: [
                { name: "excel", template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                "<span class='k-icon k-i-excel'></span>Export</md-button></div>" }
            ],
            excel: {
                allPages: true,
                fileName: "Επισκέψεις ανά Ιατρικό Επισκέπτη.xlsx"
            },
            dataBound: function () { vm.masterFiltersEdited(); }
        };

        $timeout(function () {
            MasterFiltersService.clearFilterEvent()
        });
    }
})();
