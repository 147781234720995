/**
 * Created by gmogas on 11/1/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('purchases', {
                parent: 'app',
                url: '/purchases',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SUPERVISOR', 'ROLE_PHARM_VISITOR', 'ROLE_STOREHOUSE', 'ROLE_ACCOUNTING_DEP', 'ROLE_PHARM_SUPERVISOR'],
                    pageTitle: 'sigmaCrmApp.pharmacies.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/purchases/purchases.html',
                        controller: 'PurchasesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('purchaseView', {
                abstract: true,
                parent: 'app',
                url: '/purchases/search?id:purchaseId',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR', 'ROLE_PHARM_VISITOR', 'ROLE_STOREHOUSE', 'ROLE_ACCOUNTING_DEP', 'ROLE_PHARM_SUPERVISOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/purchases/purchaseView.html',
                        controller: 'PurchaseViewController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    employees: ['Employee', function (Employee) {
                        return Employee.query({page: 0, size: 300}).$promise;
                    }],
                    purchase: ['$http', '$stateParams', function ($http, $stateParams) {
                        return $http.get('api/purchases/' + $stateParams.id).then(function (response) {
                            return response.data;
                        });
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('purchaseNew', {
                parent: 'app',
                url: '/purchases/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR', 'ROLE_PHARM_VISITOR', 'ROLE_STOREHOUSE', 'ROLE_ACCOUNTING_DEP', 'ROLE_PHARM_SUPERVISOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/purchases/purchaseNew.html',
                        controller: 'PurchaseNewController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    employees: ['Employee', function (Employee) {
                        return Employee.query({page: 0, size: 300}).$promise;
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('purchasesBacklog', {
                parent: 'app',
                url: '/purchases/backlog',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR', 'ROLE_STOREHOUSE', 'ROLE_ACCOUNTING_DEP', 'ROLE_PHARM_SUPERVISOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/purchases/purchaseBacklog.html',
                        controller: 'PurchaseBacklogController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('purchaseAccountingView', {
                parent: 'app',
                url: '/purchases/accountingSearch?id:purchaseId',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR', 'ROLE_PHARM_VISITOR', 'ROLE_STOREHOUSE', 'ROLE_ACCOUNTING_DEP', 'ROLE_PHARM_SUPERVISOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/purchases/purchaseAccountingView.html',
                        controller: 'PurchaseAccountingViewController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    employees: ['Employee', function (Employee) {
                        return Employee.query({page: 0, size: 300}).$promise;
                    }],
                    purchase: ['$http','$stateParams', function ($http, $stateParams) {
                        return $http.get('api/purchases/' + $stateParams.id).then(function (response) {
                            return response.data;
                        });
                    }],
                    purchaseItems: ['$http','$stateParams', function ($http, $stateParams) {
                        return $http.get('/api/purchase-items/search',
                            {params: {
                                    filter: {
                                        filters: [{
                                            field: "purchase.id",
                                            operator: "eq",
                                            value: $stateParams.id
                                        }]
                                    }
                                }}).then(function (response) {
                                    return response.data.content});
                    }],
                    purchasePresentItems: ['$http','$stateParams', function ($http, $stateParams) {
                        return $http.get('/api/purchase-present-items/search',
                            {params: {
                                    filter: {
                                        filters: [{
                                            field: "purchase.id",
                                            operator: "eq",
                                            value: $stateParams.id
                                        }]
                                    }
                                }}).then(function (response) {
                            return response.data.content});
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
