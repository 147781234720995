/**
 * Created by gmogas on 18/1/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchaseViewController', PurchaseViewController);

    PurchaseViewController.$inject = ['$window', '$q', '$state', '$http','KendoService', 'KendoFilter', '$stateParams', 'Principal', 'NotificationOptions', 'Purchase', 'PurchaseGrids', '$timeout', 'employees', 'Alerts', '$filter', 'purchase'];

    function PurchaseViewController ($window, $q, $state, $http, KendoService, KendoFilter, $stateParams, Principal, NotificationOptions, Purchase, PurchaseGrids, $timeout, employees, Alerts, $filter, purchase) {
        var vm = this;
        vm.purchase = parsePurchase(purchase);
        vm.employees = employees;
        vm.purchaseGrids = PurchaseGrids;
        vm.purchaseGrids.setExportState(false);
        vm.sectorSearchWindowVisible = false;
        vm.selectedTab = 'purchaseItems'; // indicates which tab is selected for show/hide export button

        vm.selectedPurchaseId = $stateParams.id;
        vm.$state = $state;
        vm.pageHeading = 'Επεξεργασια Παραγγελιας: ' + vm.selectedPurchaseId;
        vm.options = {
            spinners: false,
            format: "c2",
            decimals: 2
        };

        Principal.hasAuthority('ROLE_STOREHOUSE').then( function (result) { vm.isStorehouse = !!result; });

        var sectorSearchWindow = angular.element('#sectorSearchWindow');

        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");

        var purchaseStatusDataSource =  new kendo.data.DataSource({
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get( '/api/masterdata/purchase-statuses',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            },
            sort: { field: "code", dir: "asc" }
        });
        var purchaseItemsColumnsHidden = [ "button", "quantityExecuted", "productPrice", "netPrice", "discountPercent", "discountPrice",
            "discountFinalPercent", "discountFinalPrice", "totalPrice", "discountSummary", "edit", "delete",
            "taxPrice", "finalPrice"];
        var purchasePresentItemsColumnsHidden = [ "button"];

        var employeesDataSource = new kendo.data.DataSource({
            data: vm.employees,
            schema: {
                parse: function (employeesArray) {
                    if (employeesArray && employeesArray.length > 0){
                        var length = employeesArray.length;
                        var dataItem;
                        var idx = 0;
                        for (; idx < length; idx++) {
                            dataItem = employeesArray[idx];
                            dataItem.fullName = dataItem.account.lastName + " " + dataItem.account.firstName;
                        }
                    }

                    return employeesArray;
                }
            }
        });
        vm.employeesComboBoxOptions = KendoService.initDefaultOptions(employeesDataSource, "startswith", "fullName", "id", false);
        vm.employeesComboBoxOptions.cascade = function () { vm.purchase.employee = this.dataItem(); };

        vm.purchaseStatusOptions = {
            dataSource: purchaseStatusDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };

        vm.pickerDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: function (o) {
                    var url = '/api/me/employees';
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }
                    if (o.data.sort == undefined || !o.data.sort.length) {
                        o.data.sort = { "field": "account.lastName",  "dir": "asc" };
                    }
                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            { "field": "account.authorities", "operator": "eq",
                                "value": "ROLE_STOREHOUSE" }
                        ]
                    );
                    o.data.page = 1;
                    o.data.pageSize = 20;
                    $http.get(url,
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            },
            schema: {
                parse: function (data) {
                    data.forEach(function (item) {
                        item.employee= {
                            account: {
                                fullName: item.account.lastName + " " + item.account.firstName
                            }
                        }
                    });
                    return data;
                }
            }
        });

        vm.pickerDirty = false;
        vm.pickerOptions = {
            dataSource: vm.pickerDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "employee.account.fullName",
            dataValueField: "id",
            placeholder: "Επιλογή αποθηκάριου",
            change: function () {
                vm.pickerDirty = true;
            }
        };

        vm.dataSource = {};
        vm.dataSource.paymentMethods = KendoService.initDefaultDataSource('/api/payment-methods/search');
        vm.paymentMethodOptions = {
            dataSource: vm.dataSource.paymentMethods,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id",
            placeholder: "Επιλογή τρόπου πληρωμής"
        };

        var sectorTypeCategories = ["pharmacy", "beautyCenter"];
        vm.dataSource.sectorsDataSource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) o.data.filter = { filters: [] };

                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            { "field": "sectorType.sectorTypeCategory", "operator": "in",
                                "value": sectorTypeCategories.toString() }
                        ]
                    );

                    angular.forEach( o.data.filter.filters, function(item) {
                        if ( item.value instanceof Date) {
                            item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                        }
                    });

                    $http.get('/api/me/sectors',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response);
                        })
                        .error(function (response) {});
                }
            },
            schema : {
                data: function (data) {
                    angular.forEach( data.content, function (item) {
                        if ( item.companyType == null ) {
                            item.companyType = {};
                        }
                        if ( item.taxStatus == null ) {
                            item.taxStatus = {};
                        }
                        if ( item.taxOffice == null ) {
                            item.taxOffice = {};
                        }
                    });
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        id: "id",
                        name : { editable: false },
                        vat : { editable: false },
                        address : { editable: false },
                        addressNum : { editable: false },
                        cityName : { editable: false },
                        prefectureName : { editable: false },
                        brick : { editable: false },
                        osfeBrick : { editable: false },
                        phone : { editable: false },
                        createdDate: { type: 'date', editable: false},
                        companyType: { defaultValue: { id: null, name: null }},
                        taxStatus: { defaultValue: { id: null, name: null }},
                        taxOffice: { defaultValue: { id: null, name: null }},
                        creditControlCheck: { type: "boolean" }
                    }
                }
            },
            pageSize: 20,
            serverPaging: true,
            serverSorting: true,
            scrollable:false,
            serverFiltering :true
        });
        vm.sectorsComboBoxOptions = KendoService.initDefaultOptions(vm.dataSource.sectorsDataSource, "startswith", "name", "id");
        vm.sectorsComboBoxOptions.valuePrimitive = false;
        vm.sectorsComboBoxOptions.autoBind = false;
        vm.sectorsComboBoxOptions.change = function() {
            $timeout( function() { if(vm.purchase.sector) vm.purchase.sector.shippingAddress = vm.purchase.sector.address; });
            if(vm.purchase.sector) {
                if (vm.purchase.sector.address) {
                    angular.element("#sectorAddress").css('width', ((vm.purchase.sector.address.length + 1) * 10) + 'px');
                    angular.element("#sectorShippingAddress").css('width', ((vm.purchase.sector.address.length + 1) * 10) + 'px');
                }
            }
        };
        vm.onSectorsGridSelection = function(e) {
            e.preventDefault();
            var data = this.dataItem(angular.element(e.currentTarget).closest("tr"));

            $timeout( function() {
                vm.purchase.sector = data;
                vm.purchase.sector.shippingAddress = vm.purchase.sector.address;
                if(vm.purchase.sector.address) {
                    angular.element("#sectorAddress").css('width', ((vm.purchase.sector.address.length + 1) * 10) + 'px');
                    angular.element("#sectorShippingAddress").css('width', ((vm.purchase.sector.address.length + 1) * 10) + 'px');
                }
            });
            angular.element("#sectorName").data('kendoComboBox').text(data.name);
            sectorSearchWindow.data("kendoWindow").close();
        };
        vm.sectorSearchWindowOptions = {
            title: "Επιλέξτε επαγγελματία υγείας",
            visible: false,
            modal: true,
            width: '90%'
        };
        vm.sectorsColumns = [
            {
                field: "code",
                title: "Κωδικός Ε.Ε.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "name",
                title: "Επωνυμία",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "vat",
                title: "Α.Φ.Μ.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "address",
                title: "Διεύθυνση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "addressNum",
                title: "Αριθμός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "cityName",
                title: "Πόλη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "prefectureName",
                title: "Νομός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "postalCode", title: "Τ.Κ.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "phone",
                title: "Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "sectorType.sectorTypeCategory",
                title: "Τύπος",
                template: "#= (sectorType.sectorTypeCategory == 'beautyCenter') ? 'Κέντρο Αισθητικής' : (sectorType.sectorTypeCategory == 'pharmacy') ? 'Φαρμακείο' : '' #",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "Κέντρο Αισθητικής", value: "beautyCenter" },
                                        { text: "Φαρμακείο", value: "pharmacy" }]
                                }),
                                valuePrimitive: true

                            })
                        },
                        showOperators: false
                    }
                }
            },
            {template: '<md-checkbox class="checkbox-toolbar customers green inline"  aria-label="selectable" ng-model="dataItem.creditControlCheck" ' +
                'ng-disabled="true"></md-checkbox>',
                field: "creditControlCheck", title: "Πιστωτικός Έλεγχος",
                filterable: {
                    cell: {
                        operator: "eqbool",
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "Ναι", value: "true" },
                                        { text: "Όχι", value: "false" }]
                                }),
                                valuePrimitive: true

                            })
                        },
                        showOperators: false
                    }
                }},
            {
                command: {
                    text: "Επιλογή",
                    click: vm.onSectorsGridSelection
                }
            }
        ];
        vm.sectorsGridOptions = {
            autoBind: false,
            dataSource: vm.dataSource.sectorsDataSource,
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            columns:  vm.sectorsColumns
        };

        vm.openSectorsGrid = function() {
            sectorSearchWindow.data("kendoWindow").center().open();
            vm.sectorSearchWindowVisible = true;
            vm.sectorsGridOptions.dataSource.read();
        };

        vm.closeSectorsGrid = function() {
            vm.sectorSearchWindowVisible = false;
        };

        vm.update = function(ev) {
            if(vm.purchase.picker && vm.pickerDirty) { vm.purchase.picker.id = vm.purchase.picker.fullName; }
            if(vm.purchase.picker) {
                if(!vm.purchase.picker.id) { vm.purchase.picker = null; }
            }
            if(vm.purchase.paymentMethod) {
                if(!vm.purchase.paymentMethod.id) { vm.purchase.paymentMethod = null; }
            }
            vm.purchase.issueDate = kendo.toString(kendo.parseDate(vm.purchase.issueDate), 'yyyy-MM-dd');
            if (vm.purchase.desirableExecutionDate !== null &&
                vm.purchase.desirableExecutionDate !== undefined &&
                vm.purchase.desirableExecutionDate !== ""){
                vm.purchase.desirableExecutionDate = kendo.toString(kendo.parseDate(vm.purchase.desirableExecutionDate), 'yyyy-MM-dd');
            }

            if (vm.validator.validate()) {
                Purchase.update(vm.purchase).$promise.then(
                    //success
                    function(response){
                        vm.purchase = parsePurchase(response);
                        if (vm.purchase.purchaseStatus) {
                            PurchaseGrids.setIsEditable(vm.purchase.purchaseStatus.isEditable);
                        }

                        notification.show({
                            message: "Η ενημέρωση των στοιχείων ήταν επιτυχής!"
                        }, "success");
                    },
                    //error
                    function( error ){
                        if (error.status && error.status === 409) {
                            Alerts.showConfirmDialog(ev, $filter('translate')('error.purchase.purchaseConcurrencyFailureTitle'),
                                $filter('translate')('error.purchase.purchaseConcurrencyFailureMessage'),
                                false, $filter('translate')('entity.action.refresh'), $filter('translate')('entity.action.cancel'), function () {
                                // on confirm
                                $window.location.reload();
                            }, function () {
                                // on cancel
                            });
                        }else {
                            notification.show({
                                message: $filter('translate')('error.purchase.updateFailedMessage')
                            }, "error");
                        }
                    }
                );
            } else {
                notification.show({
                    message: $filter('translate')('error.purchase.validateFailedMessage')
                }, "error");
            }
        };

        vm.hideColumnsForPDF = function(hide, gridId, columnsHidden) {
            var hideColumnsDefer = $q.defer();
            var grid = angular.element('#' + gridId).data('kendoGrid');
            if (grid) {
                if (vm.isStorehouse && gridId === 'purchaseItemsForPDFGrid') columnsHidden.push("product.code");
                angular.forEach( grid.columns, function (item, index) {
                    if( columnsHidden.indexOf( item.field ) !== -1 ) {
                        if (hide) {
                            grid.hideColumn(index);
                        } else {
                            grid.showColumn(index);
                        }
                    }
                });
                hideColumnsDefer.resolve(true);
            }
            return hideColumnsDefer.promise;
        };

        vm.hideToolbarForPDF = function(hide) {
            var hideToolbarDefer = $q.defer();
            if (hide) angular.element("#purchaseItemsForPDFGrid .k-grid-toolbar").hide();
            else angular.element("#purchaseItemsForPDFGrid .k-grid-toolbar").show();
            hideToolbarDefer.resolve(true);
            return hideToolbarDefer.promise;
        };

        vm.increaseFontSizeForPDF = function (increase) {
            var increaseFontSizeDefer = $q.defer();
            if (increase){
                angular.element("#pdf-wrapper").css({'font-size':'180%'});
            }else {
                angular.element("#pdf-wrapper").css({'font-size':'100%'});
            }
            increaseFontSizeDefer.resolve(true);
            return increaseFontSizeDefer.promise;
        };

        vm.adjustColumnsForPDF = function(adjust, gridId) {
            var adjustColumnsDefer = $q.defer();
            var grid = angular.element('#' + gridId).data('kendoGrid');
            if (grid){
                var newOptions = angular.extend({}, grid.getOptions());

                angular.forEach( grid.columns, function (item, index) {
                    if (item.field === "product.code"){
                        //newOptions.columns[index].width = adjust ? "15%" : "auto";
                        newOptions.columns[index].width = adjust ? 130 : "auto";
                    }else if (item.field === "product.name"){
                        //newOptions.columns[index].width = adjust ? "75%" : "auto";
                        newOptions.columns[index].width = adjust ? 500 : "auto";
                    }else if (item.field === "quantity"){
                        //newOptions.columns[index].width = adjust ? "10%" : "auto";
                        newOptions.columns[index].width = adjust ? 60 : "auto";
                    }
                });

                adjustColumnsDefer.resolve(true);
                grid.setOptions(newOptions);
            }
            return adjustColumnsDefer.promise;
        };

        vm.exportPDF = function() {
            vm.purchaseGrids.setExportState(true);

            $q.all([
                vm.hideColumnsForPDF(true, 'purchaseItemsForPDFGrid', purchaseItemsColumnsHidden),
                vm.hideColumnsForPDF(true, 'purchasePresentItemsGrid', purchasePresentItemsColumnsHidden),
                vm.adjustColumnsForPDF(true, 'purchaseItemsForPDFGrid'),
                vm.hideToolbarForPDF(true),
                vm.increaseFontSizeForPDF(true),
                angular.element("#purchaseItemsGrid").data("kendoGrid").dataSource.read()
            ])
            .then(function() {
                kendo.drawing.drawDOM(angular.element("#pdf-wrapper"))
                    .then(function(group) {
                        // Render the result as a PDF file
                        return kendo.drawing.exportPDF(group, {
                            paperSize: "auto",
                            margin: { left: "1cm", top: "1cm", right: "1cm", bottom: "1cm" }
                        });
                    })
                    .done(function(data) {
                        kendo.saveAs({
                            dataURI: data,
                            fileName: "Παραγγελία.pdf"
                        });

                        $timeout(function (){
                            vm.purchaseGrids.setExportState(false);
                        }, 0);
                        vm.hideColumnsForPDF(false, 'purchaseItemsForPDFGrid', purchaseItemsColumnsHidden);
                        vm.hideColumnsForPDF(false, 'purchasePresentItemsGrid', purchasePresentItemsColumnsHidden);
                        vm.adjustColumnsForPDF(false, 'purchaseItemsForPDFGrid');
                        vm.hideToolbarForPDF(false);
                        vm.increaseFontSizeForPDF(false);
                        $window.location.reload();
                    });
            });
        };

        vm.onTabStripClick = function (selectedTab) {
            vm.selectedTab = selectedTab;
        };

        vm.goBack = function () {
            $state.go('purchases');
        };

        function parsePurchase(p) {
            if(p.netPrice) vm.netPrice = p.netPrice.toLocaleString();
            if(p.discountFinalPrice) vm.discountFinalPrice = p.discountFinalPrice.toLocaleString();
            if(p.totalPrice) vm.totalPrice = p.totalPrice.toLocaleString();
            if(p.taxPrice) vm.taxPrice = p.taxPrice.toLocaleString();
            if(p.finalPrice) vm.finalPrice = p.finalPrice.toLocaleString();

            if (!p.shippingAddressNum) p.shippingAddressNum = "";
            if (!p.shippingPostalCode) p.shippingPostalCode = "";

            p.fullAddress = p.shippingAddress + " "
                + p.shippingAddressNum + " "
                + p.shippingCityName + " "
                + p.shippingPrefectureName + " "
                + p.shippingCountryName + " "
                + p.shippingPostalCode;

            if (p.picker) {
                p.picker.fullName = p.picker.account.lastName + " " + p.picker.account.firstName
            }

            return angular.copy(p);
        }
    }
})();
