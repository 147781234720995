/**
 * Created by Kristy on 21/7/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('HospitalizationsComparisonReportController', HospitalizationsComparisonReportController);

    HospitalizationsComparisonReportController.$inject = ['$filter','$http', '$timeout', 'KendoFilter', 'KendoGrid'];

    function HospitalizationsComparisonReportController ($filter, $http, $timeout, KendoFilter, KendoGrid) {
        var vm = this;
        console.log(new Date());
        vm.pageHeading = $filter('translate')('sigmasfApp.hospitalization.comparisonReport.title');
        vm.url = '/api/hospitalizations/analytics/comparison';
        vm.period = {};
        var dataSource = {};
        vm.date = new Date();
        vm.date.setMonth(vm.date.getMonth() - 1);
        var month = new Date().getMonth();
        if (month === 0) {
            month = 12
        }

        var today = new Date();
        today.setMonth(today.getMonth() - 1);
        var lastYear = today.getFullYear() - 1;
        var currentYear = today.getFullYear();

        var getMonthlyFilter = function () {
            return {
                analyticsType: "clinics",
                period1: lastYear + "," + month,
                period2: currentYear + "," + month
            }
        };
        var monthly = getMonthlyFilter();

        vm.monthOptions = {
            start: "year",
            depth: "year",
            format: "MMMM",
            change: function () {
                vm.period = { id: 1 };
                month = this.value().getMonth() + 1;
                monthly = getMonthlyFilter();
                vm.filterGrid()
            }
        };

        vm.periodFilter = monthly;

        vm.filterGrid = function() {
            if (vm.period) {
                vm.periodFilter = {};
                var gridElement = angular.element("#mainGrid").data("kendoGrid");
                switch (vm.period.id) {
                    case 1:
                        vm.periodFilter = monthly;
                        grid.columns[0].title = kendo.toString(vm.date, "MMMM");
                        gridElement.setOptions({
                            columns: grid.columns
                        });
                        break;
                    case 2:
                        vm.periodFilter = {
                            analyticsType: "clinics",
                            period1: lastYear + ",Q1",
                            period2: currentYear + ",Q1"
                        };
                        grid.columns[0].title =
                            $filter('translate')('sigmasfApp.hospitalization.comparisonReport.Quarter');
                        gridElement.setOptions({
                            columns: grid.columns
                        });
                        break;
                    case 3:
                        vm.periodFilter = {
                            analyticsType: "clinics",
                            period1: lastYear + ",S1",
                            period2: currentYear + ",S1"
                        };
                        grid.columns[0].title =
                            $filter('translate')('sigmasfApp.hospitalization.comparisonReport.Semester');
                        gridElement.setOptions({
                            columns: grid.columns
                        });
                        break;
                    case 4:
                        vm.periodFilter = {
                            analyticsType: "clinics",
                            period1: lastYear + ",N",
                            period2: currentYear + ",N"
                        };
                        grid.columns[0].title =
                            $filter('translate')('sigmasfApp.hospitalization.comparisonReport.Nine Months');
                        gridElement.setOptions({
                            columns: grid.columns
                        });
                        break;
                    case 5:
                        vm.periodFilter = {
                            analyticsType: "clinics",
                            period1: lastYear,
                            period2: currentYear
                        };
                        grid.columns[0].title =
                            $filter('translate')('sigmasfApp.hospitalization.comparisonReport.year');
                        gridElement.setOptions({
                            columns: grid.columns
                        });
                        break;
                }
                KendoGrid.refresh(vm.mainGridOptions);
            }
        };

        //Period dropdown edit configuration
        dataSource.periodData = [
            {id: 1, name: $filter('translate')('sigmasfApp.hospitalization.comparisonReport.monthly')},
            {id: 2, name: $filter('translate')('sigmasfApp.hospitalization.comparisonReport.3months')},
            {id: 3, name: $filter('translate')('sigmasfApp.hospitalization.comparisonReport.6months')},
            {id: 4, name: $filter('translate')('sigmasfApp.hospitalization.comparisonReport.9months')},
            {id: 5, name: $filter('translate')('sigmasfApp.hospitalization.comparisonReport.year')}
        ];
        vm.periodOptions = {
            dataSource: {
                data: dataSource.periodData
            },
            dataTextField: "name",
            dataValueField: "id",
            change: function() {
                var value = this.value();
                vm.date = null;
                if (value === '1') {
                    vm.date = new Date();
                    vm.date.setMonth(vm.date.getMonth() - 1);
                    month = vm.date.getMonth() + 1;
                    monthly = getMonthlyFilter();
                }
                vm.filterGrid();
            }
        };

        var computePercentage = function (dataItem) {
            var value  = dataItem.difference.sum / (dataItem.count1.sum !== 0 ? dataItem.count1.sum : 1) * 100;
            return Math.round(value);
        };

        var grid = {};
        grid.columns = [
            {
                title: kendo.toString(vm.date, "MMMM"),
                columns: [
                    {
                        field: "sector",
                        title: "Νοσοκομείο",
                        filterable: {
                            cell: {
                                operator: "contains",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    {
                        field: "clinic",
                        title: "Κλινική",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        },
                        groupFooterTemplate: "ΣΥΝΟΛΟ",
                        footerTemplate: "ΣΥΝΟΛΟ ΑΞΙΟΛΟΓΗΣΕΩΝ"
                    },
                    {
                        field: "count1",
                        title: lastYear.toString(),
                        filterable: {
                            cell: {
                                operator: "eq",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        },
                        attributes: {class: "numbers"},
                        groupFooterTemplate: "<div class='numbers'>#=sum ? sum : 0#</div>",
                        footerTemplate: "<div class='numbers'>#=sum ? sum : 0#</div>"
                    },
                    {
                        field: "count2",
                        title: currentYear.toString(),
                        filterable: {
                            cell: {
                                operator: "eq",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        },
                        attributes: {class: "numbers"},
                        groupFooterTemplate: "<div class='numbers'>#=sum ? sum : 0#</div>",
                        footerTemplate: "<div class='numbers'>#=sum ? sum : 0#</div>"
                    },
                    {
                        field: "difference",
                        title: "Διαφορά",
                        filterable: {
                            cell: {
                                operator: "eq",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        },
                        attributes: {class: "numbers"},
                        groupFooterTemplate: "<div class='numbers'>#=sum ? sum : 0#</div>",
                        footerTemplate: "<div class='numbers'>#=sum ? sum : 0#</div>"
                    },
                    {
                        field: "percentage",
                        template: "#=percentage + '%'#",
                        title: "Ποσοστό",
                        filterable: {
                            cell: {
                                operator: "eq",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        },
                        attributes: {class: "numbers"},
                        groupFooterTemplate: function(dataItem) { return '<div class="numbers">' + computePercentage(dataItem) + '%</div>' },
                        footerTemplate: function(dataItem) { return '<div class="numbers">' + computePercentage(dataItem) + '%</div>' }
                    }
                ]
            }
        ];
        grid.dataSource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function (o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }
                    angular.extend(o.data, vm.periodFilter);

                    $http.get(vm.url, {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {});
                }
            },
            schema: {
                model: {
                    id: "id",
                    fields: {
                        id: "id"
                    }
                }
            },
            group: {
                field: "sector", aggregates: [
                    { field: "count1", aggregate: "sum" },
                    { field: "count2", aggregate: "sum"},
                    { field: "difference", aggregate: "sum" },
                    { field: "percentage", aggregate: "sum" }
                ]
            },
            aggregate: [
                { field: "count1", aggregate: "sum" },
                { field: "count2", aggregate: "sum" },
                { field: "difference", aggregate: "sum" },
                { field: "percentage", aggregate: "sum" }
            ]
        });

        vm.mainGridOptions = {
            dataSource: grid.dataSource,
            sortable: {
                mode: "multiple",
                allowUnsort: true,
                showIndexes: true
            },
            filterable: {
                mode: "row"
            },
            groupable: true,
            columns: grid.columns,
            toolbar: [
                { name: "period", template: "<label>Χρονικό Διάστημα: </label><select id='period' name='period' class='inline' kendo-drop-down-list " +
                    "k-options='vm.periodOptions' k-ng-model='vm.period'></select>" },
                { name: "month", template: "<input kendo-date-picker ng-show='vm.date' k-ng-model='vm.date' k-options='vm.monthOptions'/>"},
                { name: "excel", template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                "<span class='k-icon k-i-excel'></span>Export</md-button></div>" }
            ],
            excel: {
                allPages: true,
                fileName: "Σύγκριση Αξιολογήσεων ανά Κλινική.xlsx"
            },
            excelExport: function(e) {
                //Remove html tags from excel workbook
                var rows = e.workbook.sheets[0].rows;

                for (var ri = 0; ri < rows.length; ri++) {
                    var row = rows[ri];
                    if (row.type === "group-footer" || row.type === "footer") {
                        for (var ci = 0; ci < row.cells.length; ci++) {
                            var cell = row.cells[ci];
                            if (cell.value) {
                                if (angular.element(cell.value).text()) {
                                    cell.value = angular.element(cell.value).text();
                                    // Set the alignment
                                    cell.hAlign = "right";
                                } else {
                                    cell.value = angular.element(cell.value).selector;
                                }
                            }
                        }
                    }
                }
            }
        };
    }
})();
