(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('TempCustomerSectorDialogController', TempCustomerSectorDialogController);

    TempCustomerSectorDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'TempCustomerSector', 'TempCustomer', 'Sector', 'TempSector', 'SectorPosition', 'CustomerSector'];

    function TempCustomerSectorDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, TempCustomerSector, TempCustomer, Sector, TempSector, SectorPosition, CustomerSector) {
        var vm = this;
        vm.tempCustomerSector = entity;
        vm.tempcustomers = TempCustomer.query();
        vm.sectors = Sector.query();
        vm.tempsectors = TempSector.query();
        vm.sectorpositions = SectorPosition.query();
        vm.approvedcustomersectors = CustomerSector.query({filter: 'tempcustomersector-is-null'});
        $q.all([vm.tempCustomerSector.$promise, vm.approvedcustomersectors.$promise]).then(function() {
            if (!vm.tempCustomerSector.approvedCustomerSector || !vm.tempCustomerSector.approvedCustomerSector.id) {
                return $q.reject();
            }
            return CustomerSector.get({id : vm.tempCustomerSector.approvedCustomerSector.id}).$promise;
        }).then(function(approvedCustomerSector) {
            vm.approvedcustomersectors.push(approvedCustomerSector);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:tempCustomerSectorUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.tempCustomerSector.id !== null) {
                TempCustomerSector.update(vm.tempCustomerSector, onSaveSuccess, onSaveError);
            } else {
                TempCustomerSector.save(vm.tempCustomerSector, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startedAt = false;
        vm.datePickerOpenStatus.endedAt = false;
        vm.datePickerOpenStatus.issuedDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
