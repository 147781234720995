(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PatchCustomerDetailController', PatchCustomerDetailController);

    PatchCustomerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PatchCustomer', 'SocialInsurance', 'Country', 'Region', 'Prefecture', 'City'];

    function PatchCustomerDetailController($scope, $rootScope, $stateParams, entity, PatchCustomer, SocialInsurance, Country, Region, Prefecture, City) {
        var vm = this;
        vm.patchCustomer = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:patchCustomerUpdate', function(event, result) {
            vm.patchCustomer = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
