(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('RelationshipTypeDetailController', RelationshipTypeDetailController);

    RelationshipTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'RelationshipType'];

    function RelationshipTypeDetailController($scope, $rootScope, $stateParams, entity, RelationshipType) {
        var vm = this;
        vm.relationshipType = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:relationshipTypeUpdate', function(event, result) {
            vm.relationshipType = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
