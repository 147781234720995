(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('EventStatusDetailController', EventStatusDetailController);

    EventStatusDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'EventStatus', 'EventType'];

    function EventStatusDetailController($scope, $rootScope, $stateParams, entity, EventStatus, EventType) {
        var vm = this;
        vm.eventStatus = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:eventStatusUpdate', function(event, result) {
            vm.eventStatus = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
