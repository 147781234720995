(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchaseItemDetailController', PurchaseItemDetailController);

    PurchaseItemDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PurchaseItem', 'Product', 'Purchase', 'MeasurementUnit'];

    function PurchaseItemDetailController($scope, $rootScope, $stateParams, entity, PurchaseItem, Product, Purchase, MeasurementUnit) {
        var vm = this;
        vm.purchaseItem = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:purchaseItemUpdate', function(event, result) {
            vm.purchaseItem = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
