/**
 * Created by Teo on 11/05/2020.
 */

(function() {
    'use strict';
    var isDlgOpen;

    angular
        .module('sigmasfApp')
        .controller('EditCustomerSectorDialogCtrl', EditCustomerSectorDialogCtrl);

    EditCustomerSectorDialogCtrl.$inject = ['$scope', '$mdDialog', '$http', '$state', 'Alerts', 'customerSector', 'KendoService', 'CustomDataUtils', 'Principal'];

    function EditCustomerSectorDialogCtrl ($scope, $mdDialog, $http, $state, Alerts, customerSector, KendoService, CustomDataUtils, Principal) {
        $scope.customerSector = {
            sector: {
                sectorType: {}
            },
            customer: {}
        };
        $scope.fullEdit = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SUPERVISOR']);
        $http.get('/api/employees/me')
            .success(function (response) {
                $scope.employee = response;
                $scope.customerSector = prepare(customerSector);
            })
            .error(function () {
                Alerts.showAlert("Sigma SalesForce", "Κάποιο σφάλμα προέκυψε κατά τη ταυτοποίηση χρήστη", "ΟΚ");
            });

        $scope.sectorPositionComboBoxOptions = KendoService.initDefaultOptions(KendoService.initDefaultDataSource('/api/masterdata/sector-positions'), "startswith", "name", "id");
        $scope.workingHoursComboBoxOptions = {
            dataSource: new kendo.data.DataSource({
                data: [
                    { value: 'morning', text: 'Πρωί'},
                    { value: 'afternoon', text: 'Απόγευμα'},
                    { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα'}
                ]
            }),
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "text",
            dataValueField: "value"
        };
        $scope.searchEmployeesComboBoxOptions = {
            placeholder: "Αναζήτηση Ιατρικού Επισκέπτη",
            /*filter: "contains",*/
            dataSource: new kendo.data.DataSource({
                serverFiltering: true,
                transport: {
                    read: function (o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = {filters: []};
                        }

                        o.data.page = 1;
                        o.data.pageSize = 10000;
                        $http.get('/api/me/employees',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response.content);
                            })
                            .error(function (response) {});
                    }
                },
                schema: {
                    parse: function(data) {
                        angular.forEach(data, function(item) {
                            //define object for data binding
                            if(item.account != null) {
                                item.active = item.myTarget = true;
                                item.employee = {
                                    account : {
                                        fullName: item.account.lastName + " " + item.account.firstName
                                    },
                                    id: item.id
                                }
                            }
                        });
                        return data;
                    }
                }
            }),
            valuePrimitive: false,
            dataTextField: "employee.account.fullName",
            dataValueField: "employee.id",
            select: function (e) {
                const ecs = {
                    id: null,
                    active: true,
                    myTarget: true,
                    employee: e.dataItem,
                    // customerSector: angular.copy($scope.customerSector), // this gives circular reference
                    sector: null
                };

                $scope.addEmployeeCustomerSector(ecs);
                setTimeout(function () {
                    angular.element("#employees").data("kendoComboBox").value("");
                });
            }
        };

        $scope.addEmployeeCustomerSector = function (ecs) {
            if (CustomDataUtils.isNotNil(ecs)) {
                if ($scope.customerSector.employeeCustomerSectors.filter(function (e) { return e.employee.id === ecs.employee.id }).length < 1) {
                    // Not exist, add it
                    $scope.customerSector.employeeCustomerSectors.push(ecs);
                }else {
                    // Exist, make it active
                    for (var i=0; i<$scope.customerSector.employeeCustomerSectors.length; i++) {
                        if ($scope.customerSector.employeeCustomerSectors[i].employee.id === ecs.employee.id) {
                            $scope.customerSector.employeeCustomerSectors[i].active = true;
                            break;
                        }
                    }
                }
                angular.element(window).triggerHandler('resize'); // this refresh md-list
            }
        };

        $scope.removeEmployeeCustomerSector = function (ecs) {
            if (CustomDataUtils.isNotNil(ecs)) {
                ecs.active = false;
                angular.element(window).triggerHandler('resize'); // this refresh md-list
            }
        };

        $scope.getTotalActiveEmployees = function () {
            var total = 0;
            if (CustomDataUtils.isNotEmpty($scope.customerSector.employeeCustomerSectors)){
                $scope.customerSector.employeeCustomerSectors.forEach(function (ecs) {
                    if (ecs.active === true) {
                        total++;
                    }
                })
            }

            return total;

        };

        $scope.isMyTargetDisabled = function (ecs) {
            if ($scope.fullEdit) {
                return false;
            }else {
                return ecs.employee.id !== $scope.employee.id;
            }
        };

        $scope.onEmployeeCustomerSectorClick = function (ecs) {
            // exist to make md-list clickable
        };

        $scope.navigateToSector = function () {
            $state.go('sectorInfo', {id: $scope.customerSector.sector.id});
            $scope.closeDialog(null);
        };

        $scope.showNavigateToSectorButton = function () {
            if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SUPERVISOR'])){
                return true;
            }else {
                return CustomDataUtils.isNotNil($scope.customerSector.sector.sectorType) && $scope.customerSector.sector.sectorType.isPrivate === true;
            }
        };

        /**
         * Closes the dialog.
         * @param result Should be 'success', 'error', null
         */
        $scope.closeDialog = function (result) {
            if (isDlgOpen) return;

            isDlgOpen = false;

            if (CustomDataUtils.isNotNil(result)) {
                switch(result) {
                    case 'success':
                        // hide calls the success callback
                        $mdDialog.hide(result);
                        break;
                    case 'error':
                        // cancel calls the error callback
                        $mdDialog.cancel(result);
                        break
                }
            }else {
                // hide calls the success callback
                $mdDialog.hide(null);
            }
        };

        $scope.update = function () {
            if (CustomDataUtils.isNotNil($scope.customerSector.id)) {
                if (CustomDataUtils.isNotNil($scope.customerSector.startedAt)) {
                    $scope.customerSector.startedAt = kendo.toString(formatLocalDateToServer($scope.customerSector.startedAt), "yyyy-MM-dd");
                }
                if (CustomDataUtils.isNotNil($scope.customerSector.endedAt)) {
                    $scope.customerSector.endedAt = kendo.toString(formatLocalDateToServer($scope.customerSector.endedAt), "yyyy-MM-dd");
                }

                $http.put('/api/customer-sectors', $scope.customerSector).success(function () {
                    $scope.closeDialog('success');
                }).error(function () {
                    $scope.closeDialog('error');
                    // Alerts.showAlert("Sigma SalesForce", "Κάποιο σφάλμα προέκυψε", "ΟΚ");
                });
            }
        };

        function prepare(customerSectorObject) {
            var result = angular.copy(customerSectorObject);
            if (CustomDataUtils.isNotNil(result)) {
                if (CustomDataUtils.isNotNil(result.startedAt)) {
                    result.startedAt = kendo.toString(kendo.parseDate(result.startedAt), 'dd/MM/yyyy');
                }
                if (CustomDataUtils.isNotNil(result.endedAt)) {
                    result.endedAt = kendo.toString(kendo.parseDate(result.endedAt), 'dd/MM/yyyy');
                }
                if (!CustomDataUtils.isNotNil(result.sector)) {
                    result.sector = {};
                }
                if (!CustomDataUtils.isNotNil(result.customer)) {
                    result.customer = {};
                }
                if (!CustomDataUtils.isNotEmpty(result.employeeCustomerSectors)) {
                    result.employeeCustomerSectors = [];
                }
            }

            return result;
        }

        /**
         * Converts a date string to Date object
         * @param dateString The date string in dd/MM/yyyy format
         * @returns A Date object
         */
        function formatLocalDateToServer(dateString) {
            if (CustomDataUtils.isNotNil(dateString)) {
                var dateParts = dateString.split("/");
                return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            }

            return dateString;
        }
    }
})();
