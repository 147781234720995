(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('RoomCostDialogController', RoomCostDialogController);

    RoomCostDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'RoomCost', 'CostType', 'RoomType'];

    function RoomCostDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, RoomCost, CostType, RoomType) {
        var vm = this;
        vm.roomCost = entity;
        vm.costtypes = CostType.query();
        vm.roomtypes = RoomType.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:roomCostUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.roomCost.id !== null) {
                RoomCost.update(vm.roomCost, onSaveSuccess, onSaveError);
            } else {
                RoomCost.save(vm.roomCost, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
