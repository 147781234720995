/**
 * Created by Kristy on 31/8/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PendingChangesHistoryController', PendingChangesHistoryController);

    PendingChangesHistoryController.$inject = ['$http','$state', '$stateParams','$filter', '$timeout', 'KendoFilter', 'Approvals'];

    function PendingChangesHistoryController ($http, $state, $stateParams, $filter, $timeout, KendoFilter, Approvals) {
        var vm = this;
        vm.saw = true;
        vm.sectorType = null;

        function showOldValue(container) {
            return Approvals.calculateValue(container, container.oldValue);
        }

        function showNewValue(container) {
            return Approvals.calculateValue(container, container.value);
        }

        vm.statusDataSource = new kendo.data.DataSource({
            data: [
                { value: 'approved', text: "ΕΓΚΡΙΘΗΚΕ"},
                { value: 'rejected',  text: "ΑΠΟΡΡΙΦΘΗΚΕ"}
            ]
        });

        vm.pendingHistoryGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "status", "operator": "noteq",  "value": "pending"}
                            ]
                        );

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "sectorType", "operator": "sectortype",  "value": vm.sectorType}
                            ]
                        );

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        $http.get('api/me/changes',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (options, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            createdDate: {type: 'date'}
                        }
                    }
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                pageSize: 50
            }),
            sortable: true,
            pageable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            toolbar: [
                { template: "<div class='right'><label>Σύνολο: {{vm.pendingHistoryGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }
            ],
            columns: [
                {field: "createdDate", title: "Ημερομηνία", template: "#= createdDate ? kendo.toString(kendo.parseDate(createdDate), 'dddd, dd/MM/yyyy HH:mm') : ''#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    },
                    width:200
                },
                {
                    title: "Αλλαγές",
                    template:"<span class='label label-info'>#: changeTable.nameGr#</span>", width:160
                },
                {field: "customer.lastName", title: "Επώνυμο Επαγγελματία Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {field: "customer.firstName", title: "Όνομα Επαγγελματία Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {field: "employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {field: "employee.account.firstName", title: "Όνομα Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {field: "changeField.nameGr", title: "Πεδίο",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {field: "oldValue", title: "Από",
                    template: showOldValue,
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {field: "value", title: "Σε",
                    template: showNewValue,
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {field: "status", title: "Κατάσταση",
                    template: "#= (status == 'approved') ? 'ΕΓΚΡΙΘΗΚΕ' : (status == 'rejected') ? 'ΑΠΟΡΡΙΦΘΗΚΕ' : '' #",
                    attributes: {
                        class: "#=status == 'approved' ? 'green' : status == 'rejected' ? 'red' : 'black' #"
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            showOperators: false,
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataSource: vm.statusDataSource,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    valuePrimitive: true

                                })
                            }
                        }
                    },
                    width:160
                }
            ]
        };

        vm.sectorTypeOptions = {
            dataSource: [
                { text: 'Φαρμακοποιοί', value: "pharmacy" },
                { text: 'Επαγγελματίες Υγείας', value: "hcu" },
                { text: 'Υπεύθυνοι Κέντρου', value: "beautyCenter" }
            ],
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "text",
            dataValueField: "value",
            change: function () {
                var self = this;
                $timeout(function() {
                    vm.sectorType = self.value();
                    vm.pendingHistoryGridOptions.dataSource.read();
                });
            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    template: "<label>Τύπος Επαγγελματία Υγείας: </label><select id='sectorType' name='sectorType' class='inline' kendo-combo-box " +
                    "k-options='vm.sectorTypeOptions'></select>",
                    overflow: "never", attributes: { class: "left" }
                }
            ]
        };
    }
})();
