/**
 * Created by Kristy on 07/9/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('FavouriteFiltersService', FavouriteFiltersService);

    FavouriteFiltersService.$inject = ['SupervisorsService'];

    function FavouriteFiltersService(SupervisorsService) {

        return {
            setMasterOptions: setMasterOptions,
            clearMasterFilters: clearMasterFilters,
            getKendoOptions: getKendoOptions,
            setKendoOptions: setKendoOptions
        };

        function setMasterOptions(options, masterFilters) {
            angular.forEach(options, function (option) {
                var multiSelect = [];
                if( typeof option.value === "string" ) multiSelect = option.value === "none" ? [option.value] : option.value === "" ? ["all"] : option.value.split(',').map(function (item) {
                    return parseInt(item, 10);
                });

                if (option.field.endsWith("appointmentDate")){
                    // This for extra filters 'appointmentDate - from/to' in LeadManagement reports
                    if (option.operator === "from") masterFilters.from1 = new Date(option.value.split(".")[0]);
                    if (option.operator === "to") masterFilters.to1 = new Date(option.value.split(".")[0]);
                }else {
                    if (option.operator === "from") masterFilters.from = new Date(option.value.split(".")[0]);
                    if (option.operator === "to") masterFilters.to = new Date(option.value.split(".")[0]);
                }
                if (option.operator === "in_with_hierarchy") {
                    masterFilters.selectedSupervisors = option.value === "" ? [] : option.value.split(',').map(function (item) {
                        return parseInt(item, 10);
                    });
                    SupervisorsService.setSelectedSupervisors(masterFilters.selectedSupervisors)
                }

                if (option.field === "fromDate") {
                    if (option.operator === "inMonths") masterFilters.selectedMonths = multiSelect;
                    if (option.operator === "inYears") masterFilters.selectedYear = multiSelect;
                }
                if (option.operator === "in" && option.field === "medReportLines.medicine.id")
                    masterFilters.selectedMedicines = multiSelect;
                if ((option.operator === "in" && option.field === "customerSectors.employeeCustomerSectors.employee.id") || option.field.indexOf("customer.id") !== -1)
                    masterFilters.selectedCustomers = multiSelect;
                if (option.operator === "in" && option.field === "employeeCustomerSectors.employee.id")
                    masterFilters.selectedCustomerSectors = multiSelect;
                if (option.field === "doctorSpecialty.id")
                    masterFilters.selectedSpecialties = option.value === "none" ? [option.value] : option.value === "" ? [] : option.value.split(',').map(function (item) {
                        return parseInt(item, 10);
                    });
                if (option.field === "callType.id"){
                    masterFilters.selectedCallTypes = option.value === "none" ? [option.value] : option.value === "" ? [] : option.value.split(',').map(function (item) {
                        return parseInt(item, 10);
                    });
                }
                if (option.operator === "in" && (option.field === "employee.id" || option.field === "event.employee.id"))
                    masterFilters.selectedEmployees = multiSelect;
                if (option.field.indexOf("agreementValuation.id") !== -1)
                    masterFilters.selectedAgreementValuations = multiSelect;
                if (option.field.indexOf("product.id") !== -1)
                    masterFilters.selectedProducts = multiSelect;
                if (option.field === "sector.id")
                    masterFilters.selectedPharmacies = multiSelect;
                if (option.field === "sector.cityName")
                    masterFilters.selectedCities = option.value === "none" ? [{id : option.value}] : option.value === "" ? [{ id: "all" }] : JSON.parse(option.value);
                if (option.field === "sector.prefectureName")
                    masterFilters.selectedPrefectures = option.value === "none" ? [{id : option.value}] : option.value === "" ? [{ id: "all" }] : JSON.parse(option.value);
                if (option.field === "sector.sectorType.id")
                    masterFilters.selectedSectorTypes = multiSelect;
                if (option.field === "customerSectors.employeeCustomerSectors.myTarget") masterFilters.saw = option.value;
                if (option.field === "isActive") masterFilters.isActive = option.value;
                if (option.field === "eventStatus.id") masterFilters.eventStatus = option.value;
                if (option.field.indexOf("myTarget") !== -1) masterFilters.myTarget = option.value;
                if (option.field.indexOf("hospitalizations") !== -1) masterFilters.hospitalizations = option.value;
                if (option.field.indexOf("bookedAppointment") !== -1) masterFilters.bookedAppointment = option.value;
            });

            return masterFilters;
        }

        function clearMasterFilters() {
            angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
            SupervisorsService.setSelectedSupervisors([]);
            return {
                selectedSupervisors: [],
                selectedCustomers: [],
                selectedSpecialties: [],
                selectedCallTypes: [],
                saw: true,
                isActive: true,
                myTarget: true,
                hospitalizations: false,
                bookedAppointment: false,
                selectedCustomerSectors: [],
                selectedCities: [],
                selectedPrefectures: [],
                selectedSectorTypes: [],
                selectedEmployees: [],
                selectedProducts: [],
                selectedAgreementValuations: [],
                selectedPharmacies: [],
                selectedMedicines: [],
                selectedMonths: [],
                selectedYear: null};
        }

        function getKendoOptions(grid) {
            return {
                "filters": grid.getOptions().dataSource.filter ? grid.getOptions().dataSource.filter.filters : [],
                "sort": grid.getOptions().dataSource.sort ? grid.getOptions().dataSource.sort : [],
                "group": grid.getOptions().dataSource.group,
                "aggregate": grid.getOptions().dataSource.aggregate ? grid.getOptions().dataSource.aggregate : []
            };
        }

        function setKendoOptions(options, grid) {

            if (options) {
                grid.dataSource.query({
                    filter: options.filters ? options.filters : [],
                    sort: options.sort ? options.sort : [],
                    group: options.group ? options.group : [],
                    aggregate: options.aggregate ? options.aggregate : []
                });
            }
            else {
                if (grid.getOptions().sortable) {
                    grid.dataSource.query({
                        filter: [],
                        sort: [],
                        group: []
                    });
                }
            }
        }
    }
})();
