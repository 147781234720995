/**
 * Created by Kristy on 1/6/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('RecDoctorViewNotesController', RecDoctorViewNotesController);

    RecDoctorViewNotesController.$inject = ['$http', '$stateParams', 'Principal', 'NotificationOptions'];

    function RecDoctorViewNotesController ($http, $stateParams, Principal, NotificationOptions) {
        var vm = this;

        // if (!Principal.hasAnyAuthority(['ROLE_REC_ADMINISTRATOR'])) {
        //     vm.readOnly = true;
        // }

        vm.customer = {};
        vm.selectedUserId = $stateParams.id;

        $http.get('api/customers/' + vm.selectedUserId)
            .success(function (response) {
                vm.customer = response;
            })
            .error(function (response) {});

        var validator = angular.element("#form").kendoValidator({}).data("kendoValidator");
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");

        vm.update = function() {
            if (validator.validate()) {
                $http.put('api/customers', vm.customer)
                    .success(function (response) {
                        notification.show({
                            message: "Η ενημέρωση των στοιχείων ήταν επιτυχής!"
                        }, "success");
                    })
                    .error(function (response) {
                        notification.show({
                            message: "Η ενημέρωση των στοιχείων απέτυχε!"
                        }, "error");
                    });
            }else{
                notification.show({
                    message: "Η ενημέρωση των στοιχείων απέτυχε!"
                }, "error");
            }

        };
    }

})();
