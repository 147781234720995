(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('TaxStatusDetailController', TaxStatusDetailController);

    TaxStatusDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TaxStatus'];

    function TaxStatusDetailController($scope, $rootScope, $stateParams, entity, TaxStatus) {
        var vm = this;
        vm.taxStatus = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:taxStatusUpdate', function(event, result) {
            vm.taxStatus = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
