/**
 * Created by Kristy on 19/12/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SectorsAssignationsController', SectorsAssignationsController);

    SectorsAssignationsController.$inject = ['$filter','$http', '$state', 'PharmacyAssignations', 'KendoFilter'];

    function SectorsAssignationsController ($filter, $http, $state, PharmacyAssignations, KendoFilter) {
        var vm = this;

        vm.selectedEmployees = [];
        vm.massSelect = vm.myTarget = true;

        vm.employeesDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: function (o) {
                    var url = '/api/me/employees';

                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }
                    if (o.data.sort == undefined || !o.data.sort.length) {
                        o.data.sort = { "field": "account.lastName",  "dir": "asc" };
                    }

                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            { "field": "account.authorities.name", "operator": "in",
                                "value": "ROLE_PHARM_VISITOR", "ignoreCase": true}
                        ]
                    );

                    o.data.page = 1;
                    o.data.pageSize = 20;
                    $http.get(url,
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });


                }
            },
            schema: {
                parse: function (data) {
                    data.forEach(function (item) {
                        item.employee = {
                            account: {
                                fullName: item.account.lastName + " " + item.account.firstName
                            }
                        };
                    });
                    return data;
                }
            }
        });

        vm.employeesMultiSelectOptions = {
            dataSource: vm.employeesDataSource,
            change: function (e) {
                if (vm.selectedEmployees) {
                    PharmacyAssignations.setEmployees(vm.selectedEmployees);

                    vm.sectorGridOptions.dataSource.read();
                }
            },
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "employee.account.fullName",
            dataValueField: "id",
            placeholder: "Επιλογή ιατρικού επισκέπτη/επισκεπτών"
        };

        vm.employeesComboBoxOptions = {
            dataSource: vm.employeesDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "employee.account.fullName",
            dataValueField: "id",
            placeholder: "Επιλογή ιατρικού επισκέπτη",
            change: function() {
                PharmacyAssignations.setEmployee(this.dataItem(this.select()));
            }
        };

        vm.toolbarOptions = {
            items: [
                { template: "<label>Μεταφορά φαρμακείου από ιατρικό επισκέπτη </label>" },
                {
                    template: "<select id='multiSelect' name='multiSelect' kendo-multi-select multiple='multiple' " +
                    "k-ng-model='vm.selectedEmployees' k-options='vm.employeesMultiSelectOptions'></select>",
                    overflow: "never"
                },
                { template: "<label>σε ιατρικό επισκέπτη </label>" },
                {
                    template: "<select id='comboBox' name='comboBox' kendo-combo-box k-options='vm.employeesComboBoxOptions' k-ng-model='vm.newEmployee'></select>",
                    overflow: "never"
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-warn' ui-sref='previewPharmacyAssignations'>Επόμενο" +
                    "<md-icon class='arrow-right'></md-icon></md-button>", attributes: { class: "right" }
                }
            ]
        };

        vm.gridColumns = [
            { field:"id", filterable: false, hidden : true },
            {
                field: "name",
                title: "Επωνυμία",
                filterable: {
                    cell: {
                        operator: "contains",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "vat",
                title: "Α.Φ.Μ.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "companyType.name",
                title: "Εταιρική Μορφή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            // {
            //     field: "taxOffice.name",
            //     title: "Δ.Ο.Υ.",
            //     filterable: {
            //         cell: {
            //             operator: "startswith",
            //             showOperators: false
            //         }
            //     }
            // },
            {
                field: "address",
                title: "Διεύθυνση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "addressNum",
                title: "Αριθμός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "cityName",
                title: "Πόλη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "prefectureName",
                title: "Νομός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "postalCode", title: "Τ.Κ.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "brick",
                title: "Brick",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "osfeBrick",
                title: "Brick ΟΣΦΕ",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "phone",
                title: "Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "phone2",
                title: "Τηλέφωνο 2",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];

        vm.sectorGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        if (o.data.sort == undefined || !o.data.sort.length) {
                            o.data.sort = { "field": "customer.lastName",  "dir": "asc" };
                        }

                        if (vm.selectedEmployees.length) {
                            o.data.filter.filters.push(
                                {
                                    "field": "employee.id", "operator": "in",
                                    "value": vm.selectedEmployees.toString()
                                }
                            );

                            $http.get('/api/me/employee-customer-sectors/pharmacies/assignations',
                                {params: o.data})
                                .success(function (response,status,headers) {
                                    o.success(response);
                                })
                                .error(function (response) {
                                    console.log(response);
                                });
                        } else {
                            o.success([]);
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        if(data != undefined) {
                            data.forEach(function(item){
                                if(item.companyType === null){
                                    item.companyType = {
                                        name: ""
                                    };
                                }
                                if(item.taxOffice === null){
                                    item.taxOffice = {
                                        name: ""
                                    };
                                }
                            });
                        }
                        return data;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true }
                        }
                    }
                },
                pageSize: 100
            }),
            selectedItems: function(dataItems) {
                PharmacyAssignations.setSelectedSectors(dataItems);
            },
            sortable: true,
            scrollable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            selectable: true,
            columns: vm.gridColumns,
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.sectorGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }]
        };
    }
})();
