(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadHospitalizationDiseaseDetailController', LeadHospitalizationDiseaseDetailController);

    LeadHospitalizationDiseaseDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'LeadHospitalizationDisease', 'Disease', 'LeadHospitalization'];

    function LeadHospitalizationDiseaseDetailController($scope, $rootScope, $stateParams, entity, LeadHospitalizationDisease, Disease, LeadHospitalization) {
        var vm = this;
        vm.leadHospitalizationDisease = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:leadHospitalizationDiseaseUpdate', function(event, result) {
            vm.leadHospitalizationDisease = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
