(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('DiscountReasonDeleteController',DiscountReasonDeleteController);

    DiscountReasonDeleteController.$inject = ['$uibModalInstance', 'entity', 'DiscountReason'];

    function DiscountReasonDeleteController($uibModalInstance, entity, DiscountReason) {
        var vm = this;
        vm.discountReason = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            DiscountReason.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
