(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchaseItemDialogController', PurchaseItemDialogController);

    PurchaseItemDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PurchaseItem', 'Product', 'Purchase', 'MeasurementUnit'];

    function PurchaseItemDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PurchaseItem, Product, Purchase, MeasurementUnit) {
        var vm = this;
        vm.purchaseItem = entity;
        vm.products = Product.query();
        vm.purchases = Purchase.query();
        vm.measurementunits = MeasurementUnit.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:purchaseItemUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.purchaseItem.id !== null) {
                PurchaseItem.update(vm.purchaseItem, onSaveSuccess, onSaveError);
            } else {
                PurchaseItem.save(vm.purchaseItem, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
