/**
 * Created by Teo on 02/05/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('leadCalls', {
                parent: 'app',
                url: '/leadCalls',
                data: {
                    authorities: ['ROLE_REC_MANAGEMENT', 'ROLE_REC_FRONT_DESK'],
                    pageTitle: 'sigmaCrmApp.global.menu.lead-management.lead-calls'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/leadCalls/leadCalls.html',
                        controller: 'LeadCallsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('leadCallView', {
                parent: 'app',
                url: '/leadCalls/search?id:leadCallId',
                data: {
                    authorities: ['ROLE_REC_MANAGEMENT', 'ROLE_REC_FRONT_DESK'],
                    pageTitle: 'sigmaCrmApp.leadCall.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/leadCalls/leadCallsView.html',
                        controller: 'LeadCallsViewController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    professions: ['Profession', function (Profession) {
                        return Profession.query({page: 0, size: 200}).$promise;
                    }],
                    relationshipTypes: ['RelationshipType', function (RelationshipType) {
                        return RelationshipType.query({page: 0, size: 200}).$promise;
                    }],
                    nativeLanguages: ['NativeLanguage', function (NativeLanguage) {
                        return NativeLanguage.query({page: 0, size: 200}).$promise;
                    }],
                    callTypes: ['CallType', function (CallType) {
                        return CallType.query({page: 0, size: 200}).$promise;
                    }],
                    hospitalizationTypes: ['HospitalizationType', function (HospitalizationType) {
                        return HospitalizationType.query({page: 0, size: 200}).$promise;
                    }],
                    costTypes: ['CostType', function (CostType) {
                        return CostType.query({page: 0, size: 200}).$promise;
                    }],
                    roomTypes: ['RoomType', function (RoomType) {
                        return RoomType.query({page: 0, size: 200}).$promise;
                    }],
                    transportations: ['Transportation', function (Transportation) {
                        return Transportation.query({page: 0, size: 200}).$promise;
                    }],
                    discountReasons: ['DiscountReason', function (DiscountReason) {
                        return DiscountReason.query({page: 0, size: 200}).$promise;
                    }],
                    discountRates: ['DiscountRate', function (DiscountRate) {
                        return DiscountRate.query({page: 0, size: 200}).$promise;
                    }],
                    competitors: ['Competitor', function (Competitor) {
                        return Competitor.query({page: 0, size: 200}).$promise;
                    }],
                    addDiseases: ['AddDisease', function (AddDisease) {
                        return AddDisease.query({page: 0, size: 200}).$promise;
                    }],
                    addConditions: ['AddCondition', function (AddCondition) {
                        return AddCondition.query({page: 0, size: 200}).$promise;
                    }],
                    diseaseTypes: ['DiseaseTypeService', function (DiseaseTypeService) {
                        return DiseaseTypeService.search({
                            sort: {
                                "field": "sortOrder",
                                "dir": "asc"
                            },
                            page: 1,
                            pageSize: 200
                            // you can add filters here
                        }).$promise;
                    }],
                    diseases: ['Disease', function (Disease) {
                        return Disease.query({page: 0, size: 200}).$promise;
                    }],
                    offerStatuses: ['OfferStatus', function (OfferStatus) {
                        return OfferStatus.query({page: 0, size: 200}).$promise;
                    }],
                    communicationStatuses: ['CommunicationStatus', function (CommunicationStatus) {
                        return CommunicationStatus.query({page: 0, size: 200}).$promise;
                    }],
                    countryPhoneCodes: ['CountryPhoneCode', function (CountryPhoneCode) {
                        return CountryPhoneCode.query({page: 0, size: 200}).$promise;
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('leadCall');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
