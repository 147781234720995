(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SectorPositionDialogController', SectorPositionDialogController);

    SectorPositionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SectorPosition'];

    function SectorPositionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, SectorPosition) {
        var vm = this;
        vm.sectorPosition = entity;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:sectorPositionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.sectorPosition.id !== null) {
                SectorPosition.update(vm.sectorPosition, onSaveSuccess, onSaveError);
            } else {
                SectorPosition.save(vm.sectorPosition, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
