/**
 * Created by Kristy Kavvada on 27/2/2020.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('KendoChangeEvent', KendoChangeEvent);

    KendoChangeEvent.$inject = ['$state'];

    function KendoChangeEvent ($state) {

        return {
            onChange : onChange
        };

        function onChange (self, cellIndexes, view) {
            // Get selected cell
            var selected = self.select();
            // Get the row that the cell belongs to.
            var row = self.select().closest("tr");
            var cellIndex = selected[0].cellIndex;

            if(cellIndexes.includes(cellIndex)) {
                switch (view) {
                    case 'employee':
                        $state.go('userInfo', {id: self.dataItem(row).employee.id});
                        break;
                    default:
                        break;
                }
            }
        }
    }

})();
