(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SectorPositionDetailController', SectorPositionDetailController);

    SectorPositionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'SectorPosition'];

    function SectorPositionDetailController($scope, $rootScope, $stateParams, entity, SectorPosition) {
        var vm = this;
        vm.sectorPosition = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:sectorPositionUpdate', function(event, result) {
            vm.sectorPosition = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
