/**
 * Created by Kristy on 12/6/2017.
 */

(function () {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('CalculateAge', CalculateAge);

    function CalculateAge () {

        return {
            getAgeFromBirthYear : getAgeFromBirthYear,
            getBirthYearFromAge : getBirthYearFromAge
        };

        function getAgeFromBirthYear (birthYear) {
            var ageDif = Date.now() - birthYear.getTime();
            var ageDate = new Date(ageDif);
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        }

        function getBirthYearFromAge (age) {
            var date = new Date();
            date.setFullYear(date.getFullYear() - age);
            return date;
        }
    }
})();
