(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('FollowupStatusDetailController', FollowupStatusDetailController);

    FollowupStatusDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'FollowupStatus'];

    function FollowupStatusDetailController($scope, $rootScope, $stateParams, entity, FollowupStatus) {
        var vm = this;
        vm.followupStatus = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:followupStatusUpdate', function(event, result) {
            vm.followupStatus = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
