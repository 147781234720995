(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ClinicNameDetailController', ClinicNameDetailController);

    ClinicNameDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ClinicName'];

    function ClinicNameDetailController($scope, $rootScope, $stateParams, entity, ClinicName) {
        var vm = this;
        vm.clinicName = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:clinicNameUpdate', function(event, result) {
            vm.clinicName = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
