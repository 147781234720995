(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('HospitalizationTypeParentDeleteController',HospitalizationTypeParentDeleteController);

    HospitalizationTypeParentDeleteController.$inject = ['$uibModalInstance', 'entity', 'HospitalizationTypeParent'];

    function HospitalizationTypeParentDeleteController($uibModalInstance, entity, HospitalizationTypeParent) {
        var vm = this;
        vm.hospitalizationTypeParent = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            HospitalizationTypeParent.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
