/**
 * Created by Teo on 05/04/2019.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchasesAggregatedPerEmployeeControllerComparative', PurchasesAggregatedPerEmployeeControllerComparative);

    PurchasesAggregatedPerEmployeeControllerComparative.$inject = ['$http', 'KendoGrid', 'CustomUtils', 'Alerts', '$timeout', 'pharmacyVisitors', 'accountingDeps'];

    function PurchasesAggregatedPerEmployeeControllerComparative ($http, KendoGrid, CustomUtils, Alerts, $timeout, pharmacyVisitors, accountingDeps) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(50);
        vm.showProgress = false;

        var allYears = CustomUtils.getYears(2016, true);
        vm.selectedYears = [allYears[0]];
        vm.yearsOptions = {
            dataSource: {
                data: allYears
            },
            dataTextField: "value",
            dataValueField: "value",
            maxSelectedItems: 2
        };

        var allMonths = CustomUtils.getMonths(false);
        vm.selectedMonths = [];
        vm.monthsOptions = {
            dataSource: {
                data: allMonths
            },
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "value",
            dataValueField: "id",
            placeholder: "Όλος ο χρόνος"
        };

        Array.prototype.push.apply(pharmacyVisitors, accountingDeps);
        vm.selectedEmployees = [];
        vm.employeesOptions = {
            dataSource: {
                data: pharmacyVisitors
            },
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "fullName",
            dataValueField: "id",
            placeholder: "'Ολοι οι Ιατρικοί Επισκέπτες Φαρμακείων"
        };

        vm.exportData = function () {
            var grid = $("#mainGrid").data("kendoGrid");
            grid.saveAsExcel();
        };

        vm.getData = function () {
            if (vm.selectedYears.length < 1){
                Alerts.showAlert("Sigma SalesForce", "Επιλέξτε ένα ή δύο έτη", "ΟΚ");
            }else {
                $("#mainGrid").data().kendoGrid.destroy();
                $("#mainGrid").empty();
                vm.showProgress = true;
                $http.get("/api/purchases/totals-per-employee-comparative?years=" + yearObjectsToArray(vm.selectedYears) + "&months=" + vm.selectedMonths + "&employeeIds=" + employeeObjectsToArray(vm.selectedEmployees))
                    .success(function (response) {
                        vm.showProgress = false;
                        generateGrid(response);
                    })
                    .error(function (error) {
                        vm.showProgress = false;
                        console.log(error);
                        Alerts.showAlert("Sigma SalesForce", "Λυπούμαστε. Κάποιο σφάλμα προσέκυψε!", "ΟΚ");
                    });
            }
        };

        function generateGrid(response){
            $("#mainGrid").kendoGrid({
                dataSource: {
                    transport: {
                        read: function (options) {
                            options.success(response);
                        }
                    },
                    schema: {
                        parse: function (data) {
                            console.log(data);
                            if (data !== null && data !== undefined && data.length > 0){
                                for (var i=0; i<data.length; i++){
                                    if (i === 2 || i === 3 || i === 4 || i === 5 || i === 8 || i === 11 || i === 14 || i === 17) {
                                        // Format all non-integer values with 2 decimals
                                        for (var j=0; j<data[i].results.length; j++){
                                            data[i].results[j].value = kendo.toString(parseFloat(data[i].results[j].value), 'n2');
                                        }
                                    }else {
                                        // Format all integer values without decimals
                                        for (var k=0; k<data[i].results.length; k++){
                                            data[i].results[k].value = kendo.toString(parseFloat(data[i].results[k].value), 'n0');
                                        }
                                    }
                                }
                            }

                            return data;
                        }
                    }
                },
                columns: generateColumns(response),
                height: vm.contentHeight,
                pageable: false,
                editable: false,
                resizable: true,
                scrollable: true,
                sortable: true,
                group: {
                    field: "employee"
                },
                dataBound: function () {
                    $timeout(function() {
                        kendo.resize($("#mainGrid"));
                    });
                },
                excel: {
                    allPages: true,
                    fileName: "Συγκριτικά Παραγγελιών ανά Ιατρικό Επισκέπτη.xlsx"
                }
            });
        }

        function generateColumns(response){
            var columns = [];
            columns.push({
                field: "label",
                title: " ",
                width: "335px"
            }, {
                field: "value",
                title: "Σύνολο Όλων",
                template: sumTemplate,
                width: "130px"
            });

            var employeeIteration = { id: 0 }; // dummy initial employee
            for (var i=0; i<response[0].results.length; i++){
                if (response[0].results[i].employee.id !== employeeIteration.id){
                    // Add a new title column
                    columns.push({
                        title: response[0].results[i].employee.account.lastName + " " + response[0].results[i].employee.account.firstName,
                        columns: [{
                            field: "results[" + i + "].value",
                            title: response[0].results[i].label,
                            width: "140px"
                            //template: "#= kendo.toString(" + "parseFloat(results[" + i + "].value)" + ", 'n2') #"
                        }]
                    });
                }else {
                    // Add a simple column
                    columns[columns.length - 1].columns.push({
                        field: "results[" + i + "].value",
                        title: response[0].results[i].label,
                        width: "140px"
                        //template: "#= kendo.toString(" + "parseFloat(results[" + i + "].value)" + ", 'n2') #"
                    });
                }

                employeeIteration = response[0].results[i].employee; // hold the previous employee, to know when to change column title
            }

            return columns;
        }

        /**
         * Calculates the sum of each row.
         * Note that it sums only the 'Σύνολο' columns.
         * @param data The whole row of the grid.
         * @returns {number} The total sum of the row.
         */
        function sumTemplate(data) {
            var result = 0;
            if (data !== null && data.results !== null && data.results !== undefined){
                angular.forEach(data.results, function (item) {
                    if (item.label === "Σύνολο Ιατρικού Επισκέπτη"){
                        // All item.values here are strings
                        // First remove dots (.) and then replace commas with dots from values so the number conversions runs ok
                        var s = item.value.replace(".", "").replace(",", ".");
                        result += parseFloat(s);
                    }
                });
            }

            return Number.isInteger(result) ? result.toString() : kendo.toString(result, 'n2');
        }

        function yearObjectsToArray(selectedYears){
            var arr = [];
            angular.forEach(selectedYears, function (yearObject) {
                arr.push(yearObject.value);
            });
            return arr;
        }

        function employeeObjectsToArray(selectedEmployees) {
            var arr = [];
            angular.forEach(selectedEmployees, function (employeeId) {
                arr.push(employeeId);
            });
            return arr;
        }

        $(window).on("resize", function() {
            kendo.resize($("#mainGrid"));
        });
    }
})();
