(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('TempCustomerSectorDetailController', TempCustomerSectorDetailController);

    TempCustomerSectorDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TempCustomerSector', 'TempCustomer', 'Sector', 'TempSector', 'SectorPosition', 'CustomerSector'];

    function TempCustomerSectorDetailController($scope, $rootScope, $stateParams, entity, TempCustomerSector, TempCustomer, Sector, TempSector, SectorPosition, CustomerSector) {
        var vm = this;
        vm.tempCustomerSector = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:tempCustomerSectorUpdate', function(event, result) {
            vm.tempCustomerSector = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
