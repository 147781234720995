(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AttendantDetailController', AttendantDetailController);

    AttendantDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Attendant', 'Profession', 'RelationshipType', 'CountryPhoneCode'];

    function AttendantDetailController($scope, $rootScope, $stateParams, entity, Attendant, Profession, RelationshipType, CountryPhoneCode) {
        var vm = this;
        vm.attendant = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:attendantUpdate', function(event, result) {
            vm.attendant = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
