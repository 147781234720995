(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchaseStatusDetailController', PurchaseStatusDetailController);

    PurchaseStatusDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PurchaseStatus'];

    function PurchaseStatusDetailController($scope, $rootScope, $stateParams, entity, PurchaseStatus) {
        var vm = this;
        vm.purchaseStatus = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:purchaseStatusUpdate', function(event, result) {
            vm.purchaseStatus = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
