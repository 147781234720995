/**
 * Created by Kristy on 23/6/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('MasterFilters', MasterFilters);

    MasterFilters.$inject = [ '$filter' ];

    function MasterFilters ( $filter ) {

        return {
            addDateFilter : addDateFilter,
            addDateFilterGMT : addDateFilterGMT,
            addDateOnlyFilter : addDateOnlyFilter,
            addMasterFilter : addMasterFilter
        };

        function addDateFilter ( field, from, to ) {
            return [
                { "field": field, "operator": "from",
                    "value": $filter('date')(from, 'yyyy-MM-ddT00:00:00.000') + 'Z' },
                { "field": field, "operator": "to",
                    "value": $filter('date')(to, 'yyyy-MM-ddT23:59:59.000') + 'Z' }
            ]
        }

        function addDateFilterGMT ( field, from, to ) {
            const fromDate = new Date(from.toLocaleString("en-US", {timeZone: 'Europe/London'}));
            return [
                { "field": field, "operator": "from",
                    "value": $filter('date')(fromDate, 'yyyy-MM-ddT21:00:00.000') + 'Z'},
                { "field": field, "operator": "to",
                    "value": $filter('date')(to, 'yyyy-MM-ddT20:59:59.000') + 'Z'}
            ]
        }

        function addDateOnlyFilter ( field, from, to ) {
            return [
                { "field": field, "operator": "from",
                    "value": $filter('date')(from, 'yyyy-MM-dd') },
                { "field": field, "operator": "to",
                    "value": $filter('date')(to, 'yyyy-MM-dd') }
            ]
        }

        function addMasterFilter ( field, operator, value ) {
            return [
                {
                    "field": field, "operator": operator,
                    "value": value
                }
            ]
        }
    }

})();
