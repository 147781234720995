(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MDSortOrdersController', MDSortOrdersController);

    MDSortOrdersController.$inject = ['$scope', '$state', '$http', '$filter', 'KendoService', 'KendoGrid', 'KendoFilter', 'ApplicationParameters'];

    function MDSortOrdersController ($scope, $state, $http, $filter, KendoService, KendoGrid, KendoFilter, ApplicationParameters) {
        var vm = this;

        vm.pageHeading = $filter('translate')('masterData.sortOrders.title');
        ApplicationParameters.hasModule('Purchase_Pharmacies', true);
        var productCategoriesDataSource = KendoService.initDefaultDataSource('/api/masterdata/product-categories');
        vm.productCategoriesOptions = {
            dataSource: productCategoriesDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id",
            change: function() { KendoGrid.refresh(vm.mainGridOptions); }
        };
        vm.productCategoriesOptions.dataSource.filter({
            field: 'isSalesCategory',
            operator: 'eq',
            value: true
        });

        var presentListDataSource = KendoService.initDefaultDataSource('/api/present-lists/search');
        vm.presentListOptions = {
            dataSource: presentListDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "description",
            dataValueField: "id",
            change: function() { KendoGrid.refresh(vm.mainGridOptions); }
        };
        vm.presentListOptions.dataSource.filter({
            field: 'selectable',
            operator: 'eq',
            value: true
        });

        vm.sortCategory = true;
        vm.currentCategory = true;

        vm.toolbarOptions = {
            items: [
                { type: "separator", overflow: "never" },
                { type: "separator", overflow: "never" },
                {
                    template: "<label>Επιλογή σειράς: </label>" +
                        "<div class='inline'>" +
                        "<md-radio-group style='padding-right: 10px; padding-left: 10px' layout='row' ng-model='vm.sortCategory'>" +
                            "<md-radio-button ng-value='true' class='md-primary'> Προϊόντα </md-radio-button>" +
                            "<md-radio-button ng-value='false' class='md-primary'> Δώρα </md-radio-button>" +
                        "</md-radio-group>" +
                        "</div>",
                    overflow: "never", class: "left-space"
                },
                {
                    template:
                    "<div ng-hide='!vm.sortCategory'>" +
                        "<label>Κατηγορία Προϊόντων: </label>" +
                        "<select id='productCategory' name='productCategory' class='inline' kendo-combo-box='vm.productCategoryCombo' " +
                        "k-options='vm.productCategoriesOptions' k-ng-model='vm.productCategory'></select>" +
                    "</div>",
                    overflow: "never", class: "left-space"
                },
                {
                    template:
                    "<div ng-hide='vm.sortCategory'>" +
                        "<label>Λίστα Δώρων: </label>" +
                        "<select id='presentList' name='presentList' class='inline' kendo-combo-box='vm.presentListCombo' " +
                        "k-options='vm.presentListOptions' k-ng-model='vm.presentList'></select>" +
                    "</div>",
                    overflow: "never", class: "left-space"
                }
            ]
        };

        vm.sortableOptions = {
            filter: ".k-grid tr[data-uid]",
            hint: $.noop,
            cursor: "move",
            // hint:function(element) {
            //     return element.clone().addClass("hint");
            // },
            placeholder: function(element) {
                //return element.clone().addClass("placeholder").text("Τοποθετήστε εδώ!");
                return element
                    .clone()
                    .removeAttr("uid")
                    .addClass("k-state-hover")
                    .addClass("k-state-selected")
                    .addClass("draggable");
            },
            container: ".k-grid tbody",
            change: function(e) {
                var sortableGrid = vm.sortableGrid,
                    dataItem = sortableGrid.dataSource.getByUid(e.item.data("uid"));
                sortableGrid.dataSource.remove(dataItem);
                sortableGrid.dataSource.insert(e.newIndex, dataItem);
                var data = sortableGrid.dataSource.data();

                angular.forEach( data, function (item, index){
                    item.sortOrder = index + 1;
                });

                if(vm.currentCategory){
                    $http.put('/api/product-category-products/mass', data)
                        .success(function (response,status,headers) {
                            console.log(response);
                            // o.success(response);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                } else {
                    $http.put('/api/present-list-products/mass', data)
                        .success(function (response,status,headers) {
                            console.log(response);
                            // o.success(response);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        };

        vm.mainGridOptions = {
            autoBind:false,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }
                        if (o.data.sort == undefined || !o.data.sort.length) {
                            o.data.sort = { "field": "sortOrder",  "dir": "asc" };
                        }

                        if(vm.sortCategory){
                            if(vm.productCategory) {
                                o.data.filter.filters.push(
                                    { "field": "productCategory.id", "operator": "eq",
                                        "value": vm.productCategory }
                                );
                                $http.get('/api/product-category-products/search',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                                vm.currentCategory = vm.sortCategory;
                            } else {
                                o.success([]);
                            }
                        } else {
                            if(vm.presentList) {
                                o.data.filter.filters.push(
                                    { "field": "presentList.id", "operator": "eq",
                                        "value": vm.presentList }
                                );
                                $http.get('/api/present-list-products/search',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                                vm.currentCategory = vm.sortCategory;
                            } else {
                                o.success([]);
                            }
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            updatedAt: { editable: false }
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 200
            }),
            sortable: true,
            pageable: true,
            selectable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                { field:"id", filterable: false, hidden : true },
                {
                    field: "product.image", title: "Φωτογραφία είδους",
                    template: '<div ng-if="dataItem.product.image" class="form-group">' +
                            '<img data-ng-src="{{\'data:\' + dataItem.product.imageContentType + \';base64,\' + dataItem.product.image}}" width="100px"/>' +
                            '</div>',
                    filterable: false
                },
                { field: "product.barcode", title: "Barcode",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                { field: "product.code", title: "Κωδικός Είδους",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                { field: "product.name", title: "Προϊόν",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                }
            ],
            editable: false
        };
    }
})();
