/**
 * Created by Kristy on 10/3/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MDTradePoliciesController', MDTradePoliciesController);

    MDTradePoliciesController.$inject = ['$state', 'DateUtils', 'KendoGrid', '$http', '$filter', 'KendoFilter'];

    function MDTradePoliciesController ($state, DateUtils, KendoGrid, $http, $filter, KendoFilter) {
        var vm = this;

        vm.pageHeading = $filter('translate')('masterData.tradePolicies.title');

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                            }
                        });

                        $http.get('/api/trade-policies/search',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    create: function(o) {
                        $('a.k-grid-update').hide();
                        var data = o.data.models[0];
                        data.fromDate = DateUtils.convertLocalDateToServer(data.fromDate);
                        data.toDate = DateUtils.convertLocalDateToServer(data.toDate);
                        $http.post('/api/trade-policies', data)
                            .success(function (response) {
                                o.success(response);
                                $('a.k-grid-update').show();
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                                $('a.k-grid-update').show();
                            });
                    },
                    update: function(o) {
                        $('a.k-grid-update').hide();
                        var data = o.data.models[0];
                        data.fromDate = DateUtils.convertLocalDateToServer(data.fromDate);
                        data.toDate = DateUtils.convertLocalDateToServer(data.toDate);
                        $http.put('/api/trade-policies', data)
                            .success(function (response) {
                                o.success(response);
                                $('a.k-grid-update').show();
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                $('a.k-grid-update').show();
                                console.log(response);
                            });
                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            code: { validation: { required: true , validation: { required: true, min: 3}}},
                            fromDate: { type: 'date'},
                            toDate: { type: 'date'}
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            toolbar: [{name :"create", text: "Προσθήκη Νέας εγγραφής"},
                { template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }],
            sortable: true,
            pageable: true,
            selectable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "code", title: "Κωδικός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "description", title: "Περιγραφή",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "fromDate",
                    title: "Ημερομηνία Ισχύος Από",
                    template: "#= fromDate == null ? '' : kendo.toString(kendo.parseDate(fromDate), 'dddd, dd/MM/yyyy')#",
                    filterable: {
                        cell: {
                            operator: "eqdateonly",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "toDate",
                    title: "Ημερομηνία Ισχύος Εώς",
                    template: "#= toDate == null ? '' : kendo.toString(kendo.parseDate(toDate), 'dddd, dd/MM/yyyy')#",
                    filterable: {
                        cell: {
                            operator: "eqdateonly",
                            showOperators: false
                        }
                    }
                },
                {
                    command:[
                        {name:"edit", text: "Επεξεργασία"}
                    ],
                    text: "Επεξεργασία",
                    title: "&nbsp;"
                }
            ],
            edit: function() { KendoGrid.defaultEditPopup(this._editContainer); },
            editable: "popup"
        };

        vm.onSelection = function(data) {
            var selectedId = data.id;
            $state.go('tradePolicyRules', {id: selectedId});
        };

    }
})();
