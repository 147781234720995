(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('EventTypeDetailController', EventTypeDetailController);

    EventTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'EventType'];

    function EventTypeDetailController($scope, $rootScope, $stateParams, entity, EventType) {
        var vm = this;
        vm.eventType = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:eventTypeUpdate', function(event, result) {
            vm.eventType = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
