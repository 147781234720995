/**
 * Created by Maria on 13/5/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('adminEventsOther', {
                parent: 'adminIndex',
                url: '/events/other',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SUPERVISOR']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/adminIndex/adminEventsOther/adminEventsOther.html',
                        controller: 'adminEventsOtherController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    employee: ['$http', function($http) {
                        return $http.get('/api/employees/me')
                    }],
                    favouriteFilters: ['$http', function($http) {
                        return $http.get('/api/favourite-filters/search', {params: { filter: { filters: [{
                            "field": "viewName", "operator": "eq",
                            "value": "otherEvents"
                        }] } }} );
                    }],
                    customersView: ['ApplicationParameters', function (ApplicationParameters) {
                        return ApplicationParameters.customersView();
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

    }

})();
