(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('BrickDeleteController',BrickDeleteController);

    BrickDeleteController.$inject = ['$uibModalInstance', 'entity', 'Brick'];

    function BrickDeleteController($uibModalInstance, entity, Brick) {
        var vm = this;
        vm.brick = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Brick.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
