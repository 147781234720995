/**
 * Created by Teo on 04/09/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadManagementAggregatedPieController', LeadManagementAggregatedPieController);

    LeadManagementAggregatedPieController.$inject = ['$http', 'CustomUtils', 'Alerts'];

    function LeadManagementAggregatedPieController ($http, CustomUtils, Alerts) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(60);
        vm.data1 = []; vm.data2 = []; vm.data3 = []; vm.data4 = [];
        var colors1 = [], colors2 = [], colors3 = [], colors4 = [];
        var dataSource = {};
        var allMonths = CustomUtils.getMonths(false);
        vm.sumLabel = ""; vm.sum1Label = ""; vm.sum2Label = ""; vm.sum3Label = ""; vm.sum4Label = "";
        vm.selectedLabelMonths = []; // Holds month labels for chips

        dataSource.yearsData = CustomUtils.getYears(2017, true);
        vm.year = dataSource.yearsData[0];
        vm.yearsOptions = {
            dataSource: {
                data: dataSource.yearsData
            },
            dataTextField: "value",
            dataValueField: "value",
            change: function() {
                getData();
                monthsIndexToLabels();
            }
        };

        vm.selectedMonths = [];
        vm.monthsOptions = {
            dataSource: {
                data: allMonths
            },
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "value",
            dataValueField: "id",
            placeholder: "Όλος ο χρόνος",
            change: function (e) {
                getData();
                monthsIndexToLabels();
            }
        };

        $(document).ready(createCharts);
        $(document).bind("kendo:skinChange", createCharts);
        getData();
        monthsIndexToLabels();

        vm.exportPDF = function () {
            console.log("export");
            kendo.drawing.drawDOM($("#pieChartsContainer"), {
                paperSize: "auto",//"A3",
                landscape: true
                // margin: { left: "1cm", top: "1cm", right: "1cm", bottom: "1cm" },
                //template: $("#page-template").html()
            })
                .then(function (group) {
                    return kendo.drawing.exportPDF(group);
                })
                .done(function (data) {
                    kendo.saveAs({
                        dataURI: data,
                        fileName: "Συγκεντρωτικά Lead Management_" + vm.year.value + ".pdf"
                    });
                });
        };

        function createCharts() {
            $("#chart1").kendoChart({
                title: {
                    position: "top",
                    text: "Ενημερώσεις που κατέληξαν σε Εισαγωγή"
                },
                dataSource: {
                    transport: {
                        read: function (o) {
                            o.success(vm.data1);
                        }
                    }
                },
                seriesColors: colors1,
                legend: {
                    visible: false
                },
                seriesDefaults: {
                    type: "pie",
                    labels: {
                        visible: true,
                        background: "transparent",
                        template: "#= category #: \n #= value#"
                    }
                },
                tooltip: {
                    visible: true,
                    format: "{0}"
                },
                series: [{
                    field: "total",//"rrp",
                    categoryField: "label",//"monthyear",
                    name:"RRP"
                }]
            });

            $("#chart2").kendoChart({
                title: {
                    position: "top",
                    text: "Ενημερώσεις ΔιαΖώσης"
                },
                dataSource: {
                    transport: {
                        read: function (o) {
                            o.success(vm.data2);
                        }
                    }
                },
                seriesColors: colors2,
                legend: {
                    visible: false
                },
                seriesDefaults: {
                    type: "pie",
                    labels: {
                        visible: true,
                        background: "transparent",
                        template: "#= category #: \n #= value#"
                    }
                },
                tooltip: {
                    visible: true,
                    format: "{0}"
                },
                series: [{
                    field: "total",//"rrp",
                    categoryField: "label",//"monthyear",
                    name:"RRP"
                }]
            });

            $("#chart3").kendoChart({
                title: {
                    position: "top",
                    text: "Ενημερώσεις ανά Κύριο Τύπο Ασφάλισης"
                },
                dataSource: {
                    transport: {
                        read: function (o) {
                            o.success(vm.data3);
                        }
                    }
                },
                seriesColors: colors3,
                legend: {
                    visible: false
                },
                seriesDefaults: {
                    type: "pie",
                    labels: {
                        visible: true,
                        background: "transparent",
                        template: "#= category #: \n #= value#"
                    }
                },
                tooltip: {
                    visible: true,
                    format: "{0}"
                },
                series: [{
                    field: "total",//"rrp",
                    categoryField: "label",//"monthyear",
                    name:"RRP"
                }]
            });

            $("#chart4").kendoChart({
                title: {
                    position: "top",
                    text: "Ενημερώσεις ΔιαΖώσης #2"
                },
                dataSource: {
                    transport: {
                        read: function (o) {
                            o.success(vm.data4);
                        }
                    }
                },
                seriesColors: colors4,
                legend: {
                    visible: false
                },
                seriesDefaults: {
                    type: "pie",
                    labels: {
                        visible: true,
                        background: "transparent",
                        template: "#= category #: \n #= value#"
                    }
                },
                tooltip: {
                    visible: true,
                    format: "{0}"
                },
                series: [{
                    field: "total",//"rrp",
                    categoryField: "label",//"monthyear",
                    name:"RRP"
                }]
            });
        }

        function getData(){
            vm.data1 = []; vm.data2 = []; vm.data3 = []; vm.data4 = [];
            vm.sumLabel = ""; vm.sum1Label = ""; vm.sum2Label = ""; vm.sum3Label = ""; vm.sum4Label = "";
            //colors1 = []; colors2 = []; colors3 = []; colors4 = [];
            $http.get("/api/lead-calls/analytics?year=" + vm.year.value + "&months=" + vm.selectedMonths)
                .success(function (response) {
                    console.log(response);
                    if (response && response.length > 0){
                        // 1st Graph
                        if (response[0].total > 0 && response[1].total > 0){
                            vm.data1.push({
                                // Δεν κατέληξαν σε Εισαγωγή
                                label: "Δεν κατέληξαν σε Εισαγωγή - " + (((response[0].total - response[1].total) / response[0].total) * 100).toFixed(2) + "%",
                                total: response[0].total - response[1].total
                            });
                            colors1.push(CustomUtils.getRandomColor());
                            vm.data1.push({
                                // Ενημερώσεις που κατέληξαν σε Εισαγωγή
                                label: response[1].label + " - " + ((response[1].total / response[0].total) * 100).toFixed(2) + "%",
                                total: response[1].total
                            });
                            colors1.push(CustomUtils.getRandomColor());

                            vm.sumLabel = "Σύνολο Ενημερώσεων: " + response[0].total;
                            vm.sum1Label = "Σύνολο: " + response[0].total;
                        }

                        // 2nd Graph
                        if (response[0].total > 0 && response[response.length - 4].total > 0){
                            vm.data2.push({
                                // Υπόλοιπες Ενημερώσεις
                                label: "Τηλεφωνικές Ενημερώσεις - " + (((response[0].total - response[response.length - 4].total) / response[0].total) * 100).toFixed(2) + "%",
                                total: response[0].total - response[response.length - 4].total
                            });
                            colors2.push(CustomUtils.getRandomColor());
                            vm.data2.push({
                                // Ενημερώσεις που έγιναν διαζώσης
                                label: response[response.length - 4].label + " - " + ((response[response.length - 4].total / response[0].total) * 100).toFixed(2) + "%",
                                total: response[response.length - 4].total
                            });
                            colors2.push(CustomUtils.getRandomColor());

                            vm.sum2Label = "Σύνολο: " + response[0].total;
                        }

                        // 3rd Graph
                        var count = 0;
                        for (var i=2; i<=response.length - 5; i++){
                            // Calculate sum for setting percentage later below
                            count += response[i].total;
                        }
                        for (var j=2; j<=response.length - 5; j++){
                            vm.data3.push({
                                // Ενημερώσεις ΙΔΙΩΤΙΚΑ/../TAMEIO που κατέληξαν σε εισαγωγή
                                label: response[j].label + " - " + ((response[j].total / count) * 100).toFixed(2) + "%",
                                total: response[j].total
                            });
                            colors3.push(CustomUtils.getRandomColor());
                        }
                        count > 0 ? vm.sum3Label = "Σύνολο: " + count : vm.sum3Label = "";

                        // 4th Graph
                        if (response[0].total > 0 && response[response.length - 2].total > 0 &&
                            response[response.length - 3].total > 0){
                            vm.data4.push({
                                // Ενημερώσεις δια ζώσης που ΔΕΝ έγιναν εισαγωγή
                                label: response[response.length - 2].label + " - " +
                                        ((response[response.length - 2].total / (response[response.length - 2].total + response[response.length - 3].total)) * 100).toFixed(2) + "%",
                                total: response[response.length - 2].total
                            });
                            colors4.push(CustomUtils.getRandomColor());
                            vm.data4.push({
                                // Από τις διαζώσης πόσες έγιναν εισαγωγή
                                label: response[response.length - 3].label + " - " +
                                        ((response[response.length - 3].total / (response[response.length - 2].total + response[response.length - 3].total)) * 100).toFixed(2) + "%",
                                total: response[response.length - 3].total
                            });
                            colors4.push(CustomUtils.getRandomColor());

                            vm.sum4Label = "Σύνολο: " + (response[response.length - 2].total + response[response.length - 3].total);
                        }
                    }else {
                        vm.sumLabel = ""; vm.sum1Label = ""; vm.sum2Label = ""; vm.sum3Label = ""; vm.sum4Label = "";
                    }

                    $("#chart1").data("kendoChart").dataSource.read();
                    //$("#chart1").data("kendoChart").refresh();

                    $("#chart2").data("kendoChart").dataSource.read();
                    //$("#chart2").data("kendoChart").refresh();

                    $("#chart3").data("kendoChart").dataSource.read();
                    //$("#chart3").data("kendoChart").refresh();

                    $("#chart4").data("kendoChart").dataSource.read();
                    //$("#chart4").data("kendoChart").refresh();
                })
                .error(function (error) {
                    console.log(error);
                    vm.sumLabel = ""; vm.sum1Label = ""; vm.sum2Label = ""; vm.sum3Label = ""; vm.sum4Label = "";
                    Alerts.showAlert("Sigma SalesForce", "Λυπούμαστε. Κάποιο σφάλμα προσέκυψε!", "ΟΚ");
                });
        }

        function monthsIndexToLabels() {
            if (vm.selectedMonths.length === 0){
                vm.selectedLabelMonths = ["Όλος ο χρόνος"];
                vm.selectedLabelMonths.push("#" + vm.year.value)
            }else {
                vm.selectedLabelMonths = ["#" + vm.year.value];
                angular.forEach(vm.selectedMonths, function (value, key) {
                    vm.selectedLabelMonths.push(allMonths[value - 1].value);
                });
            }
        }
    }
})();
