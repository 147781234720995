(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', '$http', '$mdDialog', 'ApplicationParameters'];

    function NavbarController ($state, Auth, Principal, ProfileService, LoginService, $http, $mdDialog, ApplicationParameters) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.ApprovalsEnabled = false;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.customerView = '1';
        ApplicationParameters.hasModule('Customers_View', false).then(function(response) {
            vm.customerView  = response.data.content[0].value;
        });

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerDisabled = response.swaggerDisabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.onEventCycleClick = onEventCycleClick;
        vm.$state = $state;

        vm.productStates = ['products', 'productCategories', 'sortOrders', 'productLots', 'productLotMass'];
        vm.activeMasterDataState = activeMasterDataState;

        function activeMasterDataState() {
            var result = true;
            angular.forEach( vm.productStates, function ( item ) {
                if (vm.$state.includes(item)) {
                    result = false;
                }
            });
            return result;
        }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function onEventCycleClick(ev) {
            $mdDialog.show({
                controller: 'EventCycleMdDialogCtrl',
                templateUrl: 'app/components/event-cycle-md-dialog/event-cycle-md-dialog-template.html',
                clickOutsideToClose: false,
                targetEvent: ev
            });
        }

        vm.currentUser = {};
        vm.getCurrentUserInfo = function () {
            if (Principal.isAuthenticated()){
                vm.currentUser = Principal.identity();
            } else {
                vm.currentUser = {};
            }
        };

        $http.get('/api/employees/me')
            .success(function (response,status,headers) {
                vm.user = response;
            })
            .error(function (response) {
                console.log(response);
            });

        function getMenuVisibility (self) {
            var item = angular.element(self).children()[1];
            var left = item.offsetLeft;
            var width = item.offsetWidth;

            while (item.offsetParent) {
                item = item.offsetParent;
                left += item.offsetLeft;
            }

            return left >= window.pageXOffset &&
                (left + width) <= (window.pageXOffset + window.innerWidth);
        }

        //check submenu visibility
        angular.element(".dropdown-submenu").hover(
            function() {
                angular.element( this ).children().removeClass("navbar-pull-left");
                if (!getMenuVisibility( this )) {
                    var submenu = angular.element(".dropdown-submenu .dropdown-menu");
                    submenu.addClass( "navbar-pull-left" );
                }
            }, function() {}
        );

        //check menu visibility
        angular.element(".navbar").on('click', '.dropdown',
            function() {
                angular.element(this).children().removeClass("dropdown-pull-left");
                angular.element(this).addClass("open");

                if (!angular.element(this).find('.dropdown-submenu').length) {
                    if (!getMenuVisibility( this )) {
                        var submenu = angular.element(this).find(".dropdown-menu");
                        submenu.addClass("dropdown-pull-left");
                    }
                }
            }
        );


    }
})();
