(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CustomerSubCategoryDeleteController',CustomerSubCategoryDeleteController);

    CustomerSubCategoryDeleteController.$inject = ['$uibModalInstance', 'entity', 'CustomerSubCategory'];

    function CustomerSubCategoryDeleteController($uibModalInstance, entity, CustomerSubCategory) {
        var vm = this;
        vm.customerSubCategory = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            CustomerSubCategory.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
