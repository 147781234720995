/**
 * Created by Teo on 25/07/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('followUpAll', {
                parent: 'adminIndex',
                url: '/followUp/all',
                data: {
                    authorities: ['ROLE_SYSTEM', 'ROLE_REC_MANAGEMENT', 'ROLE_REC_ADMINISTRATOR', 'ROLE_REC_FRONT_DESK']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/adminIndex/followUpAll/followUpAll.html',
                        controller: 'FollowUpAllController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    employee: ['$http', function($http) {
                        return $http.get('/api/employees/me')
                    }],
                    favouriteFilters: ['$http', function($http) {
                        return $http.get('/api/favourite-filters/search', {params: { filter: { filters: [{
                                        "field": "viewName", "operator": "eq",
                                        "value": "followUp"
                                    }] } }} );
                    }],
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

    }

})();
