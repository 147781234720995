/**
 * Created by Kristy Kavvada on 26/2/2020.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MedReportPopupController', MedReportPopupController);

    MedReportPopupController.$inject = ['$scope', '$timeout','$http', 'MedReportService', 'KendoService'];

    function MedReportPopupController ($scope, $timeout, $http, MedReportService, KendoService) {
        var vm = this;
        vm.medReport = {};

        $scope.$on('medReport:updated', function(event,data) {
            vm.medReport = data ? data : {};
            vm.medReport.fromToDate = vm.medReport.toDate;
            vm.medReport.customer ?
                vm.medReport.customer.fullName = vm.medReport.customer.lastName + " " + vm.medReport.customer.firstName :  {};
            console.log(vm.medReport);
        });

        vm.employeesComboBoxOptions = KendoService.initDefaultOptions(MedReportService.getEmployeesDatasource(),
            "startswith", "fullName", "id", false);

        vm.dateSelectorOptions = {
            start: "year",
            depth: "year"
        };
    }
})();
