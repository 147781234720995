(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MeasurementUnitDetailController', MeasurementUnitDetailController);

    MeasurementUnitDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'MeasurementUnit'];

    function MeasurementUnitDetailController($scope, $rootScope, $stateParams, entity, MeasurementUnit) {
        var vm = this;
        vm.measurementUnit = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:measurementUnitUpdate', function(event, result) {
            vm.measurementUnit = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
