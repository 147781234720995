(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('EventCategoryTypeDeleteController',EventCategoryTypeDeleteController);

    EventCategoryTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'EventCategoryType'];

    function EventCategoryTypeDeleteController($uibModalInstance, entity, EventCategoryType) {
        var vm = this;
        vm.eventCategoryType = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            EventCategoryType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
