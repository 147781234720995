/**
 * Created by Kristy on 19/12/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PreviewPharmacyAssignationsController', PreviewPharmacyAssignationsController);

    PreviewPharmacyAssignationsController.$inject = ['$filter', '$scope','$http', '$state', '$mdToast', 'PharmacyAssignations', 'NotificationOptions','KendoFilter'];

    function PreviewPharmacyAssignationsController ($filter, $scope, $http, $state, $mdToast, PharmacyAssignations, NotificationOptions, KendoFilter) {
        var vm = this;

        vm.success = vm.error = "";
        vm.allSelected = false;
        vm.employee = PharmacyAssignations.getEmployee();
        vm.disableSave = !!(!PharmacyAssignations.getEmployee() || !PharmacyAssignations.getSelectedSectors().length);
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");

        vm.assign = function () {
            var sectors = PharmacyAssignations.getSelectedSectors();
            var employees = PharmacyAssignations.getEmployees();
            var employeeID = PharmacyAssignations.getEmployee().id;

            var assignations = {
                "employeeToId": employeeID,
                "employeeFromIds": employees,
                "sectors": sectors
            };

            $mdToast.show({
                hideDelay   : 0,
                position    : 'top right',
                controller  : 'ToastCtrl',
                templateUrl : 'app/components/md-toast/toast-template.html'
            });
            vm.disableSave = true;
            $http.put('/api/employee-customer-sectors/pharmacies/mass', assignations)
                .success(function (response) {
                    $mdToast
                        .hide()
                        .then(function () {
                        });
                    notification.show({
                        message: "Η μαζική ανάθεση πραγματοποιήθηκε με επιτυχία!"
                    }, "success");
                })
                .error(function (response) {
                    $mdToast
                        .hide()
                        .then(function () {
                        });
                    notification.show({
                        message: "Η ενημέρωση των στοιχείων απέτυχε!"
                    }, "error");
                    console.log(response);
                });
        };

        vm.toolbarPreviewOptions = {
            items: [
                {
                    template: "<md-button class='next-button-toolbar md-raised md-warn' ui-sref='sectorsPharmacyAssignations'>" +
                    "<md-icon class='arrow-left'></md-icon>Προηγούμενο</md-button>"
                },
                { template: "<label>Μεταφορά επιλεγμένου φαρμακείου στον ιατρικό επισκέπτη </label>" },
                {
                    template: "<md-input-container class='md-block' flex-gt-sm=''>" +
                                "<md-icon class='assignment-ind'></md-icon><input aria-label='employee' ng-model='vm.employee.employee.account.fullName' ng-readonly='true'>" +
                            "</md-input-container>",
                    overflow: "never"
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-warn' ng-click='vm.assign()' ng-disabled='vm.disableSave'>" +
                    "Τέλος<md-icon class='done'></md-icon></md-button>",
                    attributes: { class: "right" }
                }
            ]
        };

        vm.removeItem = function(id) {
            var gridData = vm.previewGridOptions.dataSource.data();
            angular.forEach(gridData, function (item) {
                if (item.id.toString() === id.toString()){
                    gridData.remove(item);
                }
            });
            PharmacyAssignations.setSelectedSectors(gridData);
        };

        vm.toggleSelectAll = function (ev) {
            vm.allSelected = !vm.allSelected;
            var grid = $(ev.target).closest("[kendo-grid]").data("kendoGrid");
            angular.forEach( grid.dataSource.data(), function( dataItem ) {
                dataItem.myTarget = vm.allSelected;
            });
        };

        vm.gridColumns = [
            { field:"id", filterable: false, hidden : true },
            {
                field: "name",
                title: "Επωνυμία",
                filterable: {
                    cell: {
                        operator: "contains",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "vat",
                title: "Α.Φ.Μ.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "companyType.name",
                title: "Εταιρική Μορφή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            // {
            //     field: "taxOffice.name",
            //     title: "Δ.Ο.Υ.",
            //     filterable: {
            //         cell: {
            //             operator: "startswith",
            //             showOperators: false
            //         }
            //     }
            // },
            {
                field: "address",
                title: "Διεύθυνση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "addressNum",
                title: "Αριθμός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "cityName",
                title: "Πόλη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "prefectureName",
                title: "Νομός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "postalCode", title: "Τ.Κ.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "brick",
                title: "Brick",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "osfeBrick",
                title: "Brick ΟΣΦΕ",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "phone",
                title: "Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "phone2",
                title: "Τηλέφωνο 2",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            { "template": "<md-button class='md-icon-button' aria-label='Remove' ng-click='vm.removeItem(#=id#)'>" +
            "<md-icon class='remove'></md-icon></md-button>", width: 90 }
        ];

        vm.previewGridOptions = {
            dataSource: new kendo.data.DataSource({
                data  : PharmacyAssignations.getSelectedSectors(),
                schema: {
                    model: {
                        id: "id"
                    }
                },
                pageSize: 100
            }),
            sortable: true,
            scrollable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            columns: vm.gridColumns,
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.previewGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }]
        };
    }
})();
