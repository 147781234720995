/**
 * Created by Teo on 25/07/2019.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PatchReportsAllController', PatchReportsAllController);

    PatchReportsAllController.$inject = ['$scope', '$filter','$http', '$timeout', 'KendoFilter', 'KendoToolbar', 'FavouriteFiltersService',
        'KendoGrid', 'MasterToKendoFiltersService', 'MasterFiltersService', 'employee', 'CustomUtils'];

    function PatchReportsAllController ($scope, $filter, $http, $timeout, KendoFilter, KendoToolbar, FavouriteFiltersService, KendoGrid,
                                          MasterToKendoFiltersService, MasterFiltersService, employee, CustomUtils) {
        var vm = this;
        vm.pageHeading = "Αναφορα Γνωματευσεων";
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(56);
        vm.employee = employee;
        vm.masterFilters = { selectedCallTypes: [], hospitalizations: false};

        vm.disabled = true;
        vm.isEdited = vm.isRenameMode = false;
        vm.selectedFilter = {};
        vm.mainGrid = {};
        vm.masterOptions = [];
        vm.filter = {};
        vm.view = 'patchReports';

        var setView = function() {
            var filterable = vm.masterFilters.from && vm.masterFilters.to ? {
                mode: "row"
            } : false;

            var sortable = vm.masterFilters.from && vm.masterFilters.to ? {
                mode: "multiple",
                allowUnsort: true,
                showIndexes: true
            } : false;

            vm.disabled = !vm.masterFilters.from || !vm.masterFilters.to;
            vm.mainGrid.setOptions({
                sortable: sortable,
                groupable: vm.masterFilters.from && vm.masterFilters.to,
                filterable: filterable
            });
        };
        $scope.$watchGroup(["vm.masterFilters.from", "vm.masterFilters.to"], function () { setView(); });

        vm.masterFiltersEdited = function(){
            var gridOptions = FavouriteFiltersService.getKendoOptions(vm.mainGrid);
            $timeout(function(){
                vm.isEdited = !!(vm.selectedFilter.kendoOptions && vm.masterOptions.length &&
                    (!angular.equals(vm.masterOptions, MasterToKendoFiltersService.getMasterFilters([], vm.masterFilters, vm.view)) || !angular.equals(JSON.parse(vm.selectedFilter.kendoOptions), gridOptions)) );
            });
        };
        $scope.$watch("vm.masterFilters", vm.masterFiltersEdited, true);

        //Date Filter
        vm.masterFiltersOnClick = function(e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');
            if (e.id === "today") {
                KendoToolbar.clearDatePickers();
                $timeout(function () {
                    vm.masterFilters.from = date;
                    vm.masterFilters.to = date;
                });
            }
            else if (e.id === "week") {
                KendoToolbar.clearDatePickers();
                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6
                $timeout(function () {
                    vm.masterFilters.from = firstWeekDay;
                    vm.masterFilters.to = lastWeekDay;
                });
            }
            else if (e.id === "month") {
                KendoToolbar.clearDatePickers();
                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                $timeout(function () {
                    vm.masterFilters.from = firstMonthDay;
                    vm.masterFilters.to = lastMonthDay;
                });
            }
            else if (e.id === "year") {
                KendoToolbar.clearDatePickers();
                $timeout(function () {
                    vm.masterFilters.from = new Date(date.getFullYear(), 0, 1);
                    vm.masterFilters.to = new Date();
                });
            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup", id: "buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" },
                        { text: "Έτος", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "year" }
                    ], overflow: "never", attributes: {class: "left"}
                },
                {type: "separator", overflow: "never", attributes: {class: "left"}},
                {
                    template: "<label>Από: </label><input class='inline' kendo-date-picker id='from' onkeydown='return false' options='vm.fromOptions' k-ng-model='vm.masterFilters.from'/>",
                    overflow: "never",
                    attributes: {class: "left"}
                },
                {
                    template: "<label>Εώς: </label><input class='inline' kendo-date-picker id='to' onkeydown='return false' options='vm.toOptions' k-ng-model='vm.masterFilters.to'/>",
                    overflow: "never",
                    attributes: {class: "left"}
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary md-primary-light' aria-label='search' ng-click='vm.mainGridOptions.dataSource.read()' ng-disabled='vm.disabled'>" +
                    "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>",
                    overflow: "never",
                    attributes: {class: "right"}
                }
            ]
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.masterFilters.from = value;
                } else {
                    vm.masterFilters.from = null;
                }
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.masterFilters.to = value;
                } else {
                    vm.masterFilters.to = null;
                }
            }
        };

        vm.collapseAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-collapse").trigger("click"); };
        vm.expandAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-expand").trigger("click"); };

        vm.mainGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function (o) {
                        if (o.data.filter === undefined) { o.data.filter = { filters: [] }; }
                        o.data.filter.filters = MasterToKendoFiltersService.getMasterFilters(o.data.filter.filters, vm.masterFilters, vm.view);
                        $http.get('/api/patch-report-items/all', {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    }
                },
                schema: {
                    data: function (data) {
                        if(data !== null && data !== undefined) {
                            data.forEach(function(item){
                                if (!item.patchReport) {
                                    item.patchReport = {
                                        patchCustomer: {},
                                        employee: {},
                                        materialCategory: {},
                                        customerSector: {}
                                    };
                                }else {
                                    if (!item.patchReport.patchCustomer) item.patchReport.patchCustomer = {};
                                    if (!item.patchReport.employee) item.patchReport.employee = {};
                                    if (!item.patchReport.materialCategory) item.patchReport.materialCategory = {};
                                    if (!item.patchReport.customerSector) item.patchReport.customerSector = {};
                                }

                                if (!item.icd10) item.icd10 = {}
                            });
                        }
                        return data;
                    },
                    parse: function (data) {
                        angular.forEach(data, function (item) {
                            item.issueDate = kendo.toString(kendo.parseDate(item.patchReport.issueDate), 'dd/MM/yyyy');
                            item.approvalDate = kendo.toString(kendo.parseDate(item.patchReport.approvalDate), 'dd/MM/yyyy');
                            item.executedDate = kendo.toString(kendo.parseDate(item.patchReport.executedDate), 'dd/MM/yyyy');
                        });
                        return data;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: {editable: false, nullable: false},
                            issueDate: { type: "date" },
                            approvalDate: { type: "date" },
                            executedDate: { type: "date" }
                        }
                    }
                }
            }),
            sortable: true,
            groupable: false,
            resizable: true,
            filterable: false,
            columns: [
                {field: "issueDate", title: "Ημερ/νια Γνωμάτευσης",
                    template: "#= issueDate ? kendo.toString(kendo.parseDate(issueDate), 'dddd, dd/MM/yyyy') : ''#",
                    filterable: {
                        cell: {
                            operator: "eq", // Put 'eqdateonly' if you enable server filtering
                            showOperators: false
                        }
                    }
                },
                {field: "patchReport.patchCustomer.lastName", title: "Επώνυμο Ασθενή",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "patchReport.patchCustomer.firstName", title: "Όνομα Ασθενή",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "patchReport.patchCustomer.amka", title: "ΑΜΚΑ Ασθενή",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "patchReport.customerSector.customer.lastName", title: "Επώνυμο Επαγγελματία Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "patchReport.customerSector.customer.firstName", title: "Όνομα Επαγγελματία Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "patchReport.customerSector.sector.name", title: "Όνομα Μονάδας Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "patchReport.customerSector.sector.address", title: "Διεύθυνση",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "patchReport.customerSector.sector.cityName", title: "Πόλη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "patchReport.barcode", title: "Barcode",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "patchReport.materialCategory.name", title: "Κατηγορία Υλικού",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "patchReport.employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "patchReport.employee.account.firstName", title: "Όνομα Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "approvalDate", title: "Ημερ/νια Έγκρισης",
                    template: "#= approvalDate ? kendo.toString(kendo.parseDate(approvalDate), 'dddd, dd/MM/yyyy') : ''#",
                    filterable: {
                        cell: {
                            operator: "eq",
                            showOperators: false
                        }
                    }
                },
                {field: "patchReport.checkDoctor", title: "Ελεγκτής Επαγγελματίας Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "icd10.code", title: "Κωδικός Διάγνωσης",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "icd10.title", title: "Όνομα Διάγνωσης",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                }
            ],
            toolbar: [
                { template: "<md-button class='md-raised md-mini md-primary' aria-label='collapseAll' ng-click='vm.collapseAll()'>Collapse All</md-button>" },
                { template: "<md-button class='md-raised md-mini md-primary' aria-label='expandAll' ng-click='vm.expandAll()'>Expand All</md-button>" },
                { name: "excel", template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                    "<span class='k-icon k-i-excel'></span>Export</md-button></div>" }
            ],
            excel: {
                allPages: true,
                fileName: "Αναφορά Γνωματεύσεων.xlsx"
            },
            dataBound: function () {
                vm.masterFiltersEdited();
                $timeout(function() {
                    kendo.resize($("#mainGrid"));
                });
            }
        };

        $(window).on("resize", function() {
            kendo.resize($("#mainGrid"));
        });

        // Click week when screen opens
        $timeout(function () {
            $("#week").click();
        });
    }
})();
