/**
 * Created by Kristy on 29/8/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('previewAssignations', {
                parent: 'assignations',
                url: '/previewAssignations',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR']
                },
                templateUrl: 'app/displays/assignations/transfers/preview/preview.html',
                controller: 'PreviewAssignationsController',
                controllerAs: 'vm',
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

    }

})();
