(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ApplicationParameterDetailController', ApplicationParameterDetailController);

    ApplicationParameterDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ApplicationParameter'];

    function ApplicationParameterDetailController($scope, $rootScope, $stateParams, entity, ApplicationParameter) {
        var vm = this;
        vm.applicationParameter = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:applicationParameterUpdate', function(event, result) {
            vm.applicationParameter = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
