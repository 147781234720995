/**
 * Created by Kristy on 24/2/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchasePresentItemsController', PurchasePresentItemsController);

    PurchasePresentItemsController.$inject = ['PurchasePresentItemLot', '$http', '$filter', '$timeout', '$stateParams', 'PurchaseGrids', 'KendoGrid', 'KendoFilter', 'KendoNotifications', 'PurchasePresentItem', 'ApplicationParameters', 'Principal', 'Alerts', '$window'];

    function PurchasePresentItemsController (PurchasePresentItemLot, $http, $filter, $timeout, $stateParams, PurchaseGrids, KendoGrid, KendoFilter, KendoNotifications, PurchasePresentItem, ApplicationParameters, Principal, Alerts, $window) {
        var vm = this;
        var firstTime = true;
        vm.showProgress = false;

        ApplicationParameters.hasModule('Purchase_Pharmacies', true);
        vm.selectedPurchaseId = $stateParams.id;
        vm.purchaseGrids = PurchaseGrids;
        vm.kendoGrid = KendoGrid;
        vm.purchasePresentItemsGridHeading = $filter('translate')('sigmasfApp.purchase.purchasePresentItemsGrid.title');
        vm.purchaseItemsGridHeading = $filter('translate')('sigmasfApp.purchase.purchaseItemsGrid.title');
        vm.quantityLot = 1;
        vm.options = {
            format: "n2",
            decimals: 2
        };

        vm.purchasePresentItemsGridOptions = PurchaseGrids.getPurchasePresentItemsGridOptions(vm.selectedPurchaseId);
        vm.purchasePresentItemsGridOptions.dataBound = function () {
            Principal.hasAuthority('ROLE_STOREHOUSE').then(function (result) {
                if (result === true){
                    // Hide 'price' column for ROLE_STOREHOUSE
                    var index = null;
                    if (vm.purchasePresentItemsGridOptions && vm.purchasePresentItemsGridOptions.columns &&
                        vm.purchasePresentItemsGridOptions.columns.length > 0){
                        for (var i=0; i<vm.purchasePresentItemsGridOptions.columns.length; i++){
                            if (vm.purchasePresentItemsGridOptions.columns[i].field === 'price'){
                                index = i;
                                break;
                            }
                        }
                        if (index !== null){
                            angular.element("#purchasePresentItemsGrid").data("kendoGrid").hideColumn(index);
                        }
                    }
                    // Also hide 'price' column for ROLE_STOREHOUSE from purchaseItemsForPDFGrid
                    if (vm.purchaseItemsForPDFGridOptions && vm.purchaseItemsForPDFGridOptions.columns &&
                        vm.purchaseItemsForPDFGridOptions.columns.length > 0){
                        for (var j=0; j<vm.purchaseItemsForPDFGridOptions.columns.length; j++){
                            if (vm.purchaseItemsForPDFGridOptions.columns[j].field === 'price'){
                                index = j;
                                break;
                            }
                        }
                        if (index !== null){
                            angular.element("#purchaseItemsForPDFGrid").data("kendoGrid").hideColumn(index);
                        }
                    }
                }
            });
        };
        // vm.purchaseItemsGridOptions = PurchaseGrids.getPurchaseItemsGridOptions(vm.selectedPurchaseId);
        vm.purchaseItemsForPDFGridOptions = PurchaseGrids.getPurchaseItemsGridOptions(vm.selectedPurchaseId);

        vm.addPurchasePresentItemLot = function(dataItem) {
            //expand row
            var grid = angular.element("#purchasePresentItemsGrid");
            var row = grid.find("[data-uid=" + dataItem.uid +"]");
            grid.data("kendoGrid").expandRow(row);

            //add row in detailed grid
            var detailedGrid = row.next('tr').find('#detailGrid');
            $timeout(function (){
                detailedGrid.data("kendoGrid").addRow();
            }, 500);
        };

        vm.addPresentProductLots = function(){
            var data = {
                quantity: vm.quantityLot,
                idNumber: vm.barcode,
                purchaseId: vm.selectedPurchaseId
            };

            var request = {
                method: 'POST',
                url: 'api/product-lots/dispense-presents',
                data: data
            };

            $http(request).then(function successCallback(response) {
                if (response.data) {
                    var grid = angular.element("#purchasePresentItemsGrid").data("kendoGrid");
                    var dataItem = grid.dataSource.get(response.data.id);
                    dataItem.quantityExecuted = response.data.quantityExecuted;
                    vm.quantityLot = 1;
                    vm.barcode = null;
                    angular.element("#quantityLot").data("kendoNumericTextBox").focus();
                    // Refresh grid
                    vm.kendoGrid.refresh(vm.purchasePresentItemsGridOptions)
                }
            }, function errorCallback(response) {

                var centered = $("#centeredNotification").kendoNotification({
                    stacking: "down",
                    autoHideAfter: 0,
                    show: KendoNotifications.onShow,
                    button: true,
                    hideOnClick: false,
                    width: 500,
                    height: 250
                }).data("kendoNotification");

                centered.show(' ' + $filter('translate')('global.messages.error.' + response.data.params[0] + ' '), "error");
            });
        };

        vm.detailedGridOptions = function (dataItem) {
            if (dataItem != undefined) {
                return {
                    dataSource: {
                        batch: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.filter.filters.push({field: "purchasePresentItem.id", operator: "eq", value: dataItem.id});
                                $http.get('/api/purchase-present-item-lots/search',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response);
                                        vm.showProgress = false;
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                        vm.showProgress = false;
                                    });
                            },
                            create: function(o) {
                                vm.showProgress = true;
                                var data = o.data.models[0];
                                data.purchasePresentItem = {id : dataItem.id};

                                $http.post('/api/purchase-present-item-lots', data)
                                    .success(function (response) {
                                        o.success(response);
                                        dataItem.quantityExecuted = response.purchasePresentItem.quantityExecuted;
                                        dataItem.quantity = response.purchasePresentItem.quantity;
                                        dataItem.version = response.purchasePresentItem.version;

                                        vm.showProgress = false;
                                    })
                                    .error(function (response) {
                                        o.error(response);
                                        vm.showProgress = false;
                                        console.log(response);
                                        Alerts.showAlert("Sigma SalesForce", "Κάποιο σφάλμα προέκυψε κατά την δημιουργία Παρτίδας!\n" + response.message, "ΟΚ");
                                        vm.kendoGrid.refresh(vm.purchasePresentItemsGridOptions)
                                    });
                            },
                            update: function(o) {
                                vm.showProgress = true;
                                var data = o.data.models[0];

                                $http.put('/api/purchase-present-item-lots', data)
                                    .success(function (response) {
                                        o.success(response);
                                        dataItem.quantityExecuted = response.purchasePresentItem.quantityExecuted;
                                        dataItem.quantity = response.purchasePresentItem.quantity;
                                        dataItem.version = response.purchasePresentItem.version;

                                        vm.showProgress = false;
                                    })
                                    .error(function (error) {
                                        o.error(error);
                                        vm.showProgress = false;
                                        if (error.message && error.message.includes("error.concurrencyFailure")) {
                                            Alerts.showConfirmDialog(null, $filter('translate')('error.purchasePresentItemLot.purchasePresentItemLotConcurrencyFailureTitle'),
                                                $filter('translate')('error.purchasePresentItemLot.purchasePresentItemLotConcurrencyFailureMessage'),
                                                false, $filter('translate')('entity.action.refresh'), $filter('translate')('entity.action.cancel'), function () {
                                                    // on confirm
                                                    $window.location.reload();
                                                }, function () {
                                                    // on cancel
                                                });
                                        }else {
                                            Alerts.showAlert("Sigma SalesForce", "Κάποιο σφάλμα προέκυψε κατά την δημιουργία Παρτίδας!\n" + error.message, "ΟΚ");
                                            vm.kendoGrid.refresh(vm.purchaseItemsGridOptions);
                                        }
                                    });
                            },
                            destroy: function(o) {
                                var data = o.data.models[0];
                                PurchasePresentItemLot.delete({id: data.id},
                                    function () {
                                        console.log("PurchasePresentItemLot with id: " + data.id + ", successfully deleted!");
                                        var grid = angular.element("#purchasePresentItemsGrid");
                                        grid.data("kendoGrid").dataSource.read();
                                    });

                            }
                        },
                        requestEnd: function(e) {
                            var type = e.type;
                            if (type == "create" || type == "destroy" ) {
                                var dataSource = this;
                                dataSource.read();
                            }
                            vm.showProgress = false;
                        },
                        schema: {
                            data: function (data) {
                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: { editable: false, nullable: true },
                                    quantity: { editable: true, nullable: false, type: "number", validation: { min: 1,
                                            reqvalidation: function (input) {
                                                if (firstTime) {
                                                    firstTime=false;
                                                    return true;
                                                }
                                                if (input.is("[name='quantity']") && input.val() === "") {
                                                    input.attr("data-reqvalidation-msg", "Η ποσότητα είναι υποχρεωτική");
                                                    return false;
                                                }
                                                return true;
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        serverFiltering: true,
                        serverSorting: true,
                        serverPaging: true,
                        pageSize: 15
                    },
                    pageable: true,
                    sortable: true,
                    filterable: {
                        mode: "row"
                    },
                    columns: [
                        {
                            field: "name",
                            title: "Παρτίδα",
                            filterable: false
                        },
                        {
                            field: "quantity",
                            title: "Ποσότητα", format: "{0:n0}",
                            filterable: false
                        },
                        {
                            field: "destroy",
                            filterable: false,
                            command: [
                                {
                                    name: "destroy",
                                    text: ""
                                }
                            ],
                            title: "&nbsp;"
                        }
                    ],
                    editable: "inline",
                    selectable: "row",
                    navigatable: true,
                    rowTemplate: kendo.template(angular.element("#gridPresentItemsRowTemplate").html()),
                    edit: function(e) {
                        var data = this._data;
                        var item = e.model;

                        var quantityDefaultValue = PurchaseGrids.getQuantityDefaultValue(dataItem.quantity, data, item);

                        if(e.model.isNew()) {
                            e.model.set("quantity", quantityDefaultValue);
                        }
                        e.container.find("input[name=quantity]").data("kendoNumericTextBox").setOptions({
                            max: quantityDefaultValue
                        });
                    },
                    // This event causes problems with the PurchasePresentItem.ExecQuantity.
                    // It runs before the update of PurchasePresentItemLot
                    /*save: function(e) {
                        console.log("detailedGridOptions::save");
                        var data = this._data;
                        dataItem.quantityExecuted = 0;
                        angular.forEach( data, function (item) {
                            dataItem.quantityExecuted += item.quantity;
                        });
                        if (dataItem.quantityExecuted <= dataItem.quantity) {
                            dataItem.measurementUnit = null;
                            PurchasePresentItem.update(dataItem,
                                function (success) {},
                                function (error) {}
                            );
                        }
                    },*/
                    dataBound: function() {
                        angular.element("#detailGrid tbody tr").on("click", function(e) {
                            if (!angular.element(this).hasClass('k-grid-edit-row')) {
                                angular.element("#detailGrid").data("kendoGrid").editRow(angular.element(this));
                            }
                        });
                    }
                };
            }
        };
    }
})();
