(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadParameterDetailController', LeadParameterDetailController);

    LeadParameterDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'LeadParameter'];

    function LeadParameterDetailController($scope, $rootScope, $stateParams, entity, LeadParameter) {
        var vm = this;
        vm.leadParameter = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:leadParameterUpdate', function(event, result) {
            vm.leadParameter = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
