/**
 * Created by Teo on 04/09/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadManagementAggregatedGridController', LeadManagementAggregatedGridController);

    LeadManagementAggregatedGridController.$inject = ['$http', 'KendoGrid', 'CustomUtils', 'Alerts'];

    function LeadManagementAggregatedGridController ($http, KendoGrid, CustomUtils, Alerts) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(60);
        vm.results = [];

        var dataSource = {};
        dataSource.yearsData = CustomUtils.getYears(2017, true);
        vm.year = dataSource.yearsData[0];
        vm.yearsOptions = {
            dataSource: {
                data: dataSource.yearsData
            },
            dataTextField: "value",
            dataValueField: "value",
            change: function() {
                KendoGrid.refresh(vm.mainGridOptions);
                // This how you update grid title, but when export to excel, the title remains the same
                //vm.gridColumns[0].title = "Συγκεντρωτικά Lead Management για το έτος " + vm.year.value;
                //$("#mainGrid th[data-field=gridTitle]").contents().last().replaceWith("Συγκεντρωτικά Lead Management για το έτος " + vm.year.value);
            }
        };

        vm.gridColumns = [
            {
                //field: "gridTitle",
                //title: "Συγκεντρωτικά Lead Management για το έτος " + vm.year.value,
                columns: [
                    {
                        field: "label",
                        title: " ",
                        filterable: false
                    },
                    {
                        field: "total",
                        title: "Σύνολο",
                        filterable: false
                    },
                    {
                        field: "january",
                        title: "Ιανουάριος",
                        filterable: false
                    },
                    {
                        field: "february",
                        title: "Φεβρουάριος",
                        filterable: false
                    },
                    {
                        field: "march",
                        title: "Μάρτιος",
                        filterable: false
                    },
                    {
                        field: "april",
                        title: "Απρίλιος",
                        filterable: false
                    },
                    {
                        field: "may",
                        title: "Μάιος",
                        filterable: false
                    },
                    {
                        field: "june",
                        title: "Ιούνιος",
                        filterable: false
                    },
                    {
                        field: "july",
                        title: "Ιούλιος",
                        filterable: false
                    },
                    {
                        field: "august",
                        title: "Αύγουστος",
                        filterable: false
                    },
                    {
                        field: "september",
                        title: "Σεπτέμβριος",
                        filterable: false
                    },
                    {
                        field: "october",
                        title: "Οκτώβριος",
                        filterable: false
                    },
                    {
                        field: "november",
                        title: "Νοέμβριος",
                        filterable: false
                    },
                    {
                        field: "december",
                        title: "Δεκέμβριος",
                        filterable: false
                    }
                ]
            }
        ];
        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function (o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = { filters: [] };
                        }
                        $http.get("/api/lead-calls/analytics?year=" + vm.year.value)
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (error) {
                                console.log(error);
                                Alerts.showAlert("Sigma SalesForce", "Λυπούμαστε. Κάποιο σφάλμα προσέκυψε!", "ΟΚ");
                            });
                    }
                },
                schema: {
                    /*parse: function(data) {
                        angular.forEach( data, function( item ) {
                            switch (item.label){
                                case "countLeadCalls":
                                    item.label = "Σύνολο Ενημερώσεων";
                                    break;
                                case "countLeadCallsWithHospitalization":
                                    item.label = "Ενημερώσεις που κατέληξαν σε Εισαγωγή";
                                    break;
                                case "countEopyyLeadCallsWithHospitalization":
                                    item.label = "Ενημερώσεις ΕΟΠΥΥ που κατέληξαν σε εισαγωγή";
                                    break;
                                case "countPrivateLeadCallsWithHospitalization":
                                    item.label = "Ενημερώσεις Ιδιωτικά που κατέληξαν σε εισαγωγή";
                                    break;
                                case "countInPersonLeadCalls":
                                    item.label = "Ενημερώσεις που έγιναν διαζώσης";
                                    break;
                                case "countInPersonLeadCallsWithHospitalization":
                                    item.label = "Από τις διαζώσης πόσες έγιναν εισαγωγή";
                                    break;
                                case "countInPersonLeadCallsWithoutHospitalization":
                                    item.label = "Ενημερώσεις δια ζώσης που ΔΕΝ έγιναν εισαγωγή";
                                    break;
                                case "countInPersonLeadCallsWithoutHospitalizationWithFollowupCall":
                                    item.label = "Ενημερώσεις δια ζώσης που ΔΕΝ έγιναν εισαγωγή και έγινε Follow UP";
                                    break;
                            }
                        });
                        return data;
                    },*/
                    model: {}
                }
            }),
            resizable: true,
            columns: vm.gridColumns,
            toolbar: [
                { name: "period", template: "<label style='padding-left: 5px;'>Έτος: </label><select id='year' name='year' class='inline' kendo-drop-down-list " +
                    "k-options='vm.yearsOptions' k-ng-model='vm.year' style='padding-left: 10px;'></select>" },
                { name: "excel", template: "<div class='right'><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                    "<span class='k-icon k-i-excel'></span>Export</md-button></div>" }
            ],
            excel: {
                allPages: true,
                fileName: "Συγκεντρωτικά Lead Management_" + vm.year.value + ".xlsx"
            }
        };
    }
})();
