/**
 * Created by Kristy on 21/8/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MDPurchaseStatusesController', MDPurchaseStatusesController);

    MDPurchaseStatusesController.$inject = ['KendoGrid', '$http', '$filter', 'KendoFilter', 'PurchaseStatus'];

    function MDPurchaseStatusesController (KendoGrid, $http, $filter, KendoFilter, PurchaseStatus) {
        var vm = this;

        vm.pageHeading = $filter('translate')('masterData.purchaseStatuses.title');
        var onSaveSuccess = function () {
            KendoGrid.refresh(vm.mainGridOptions);
        };

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = { filters: [] };
                        }
                        $http.get('/api/masterdata/purchase-statuses',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    },
                    create: function(o) {
                        var data = o.data.models[0];
                        if(!data.cancellable) data.cancellable = false;
                        if(!data.isEditable) data.isEditable = false;

                        PurchaseStatus.update(data, onSaveSuccess);
                    },
                    update: function(o) {
                        var data = o.data.models[0];
                        PurchaseStatus.update(data, onSaveSuccess);
                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            name: { validation: { required: true , min: 3}}
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            toolbar: [{name :"create", text: "Προσθήκη Νέας εγγραφής"},
                { template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }],
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "name", title: "Όνομα",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    template: '<md-checkbox class="checkbox-toolbar green inline"  aria-label="isEditable" ng-model="dataItem.isEditable" ng-disabled="true"></md-checkbox>',
                    field: "isEditable", title: "Επεξεργασία",
                    editor: function(container){
                        angular.element('<md-checkbox ng-model="dataItem.isEditable"  aria-label="isEditable" class="checkbox-toolbar green inline"></md-checkbox>')
                            .appendTo(container);
                    },
                    filterable: {
                        cell: {
                            operator: "eqbool",
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Ναι", value: "true" },
                                            { text: "Όχι", value: "false" }]
                                    }),
                                    valuePrimitive: true

                                })
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    template: '<md-checkbox class="checkbox-toolbar green inline"  aria-label="cancellable" ng-model="dataItem.cancellable" ng-disabled="true"></md-checkbox>',
                    field: "cancellable", title: "Ακυρωμένη",
                    editor: function(container){
                        angular.element('<md-checkbox ng-model="dataItem.cancellable"  aria-label="cancellable" class="checkbox-toolbar green inline"></md-checkbox>')
                            .appendTo(container);
                    },
                    filterable: {
                        cell: {
                            operator: "eqbool",
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Ναι", value: "true" },
                                            { text: "Όχι", value: "false" }]
                                    }),
                                    valuePrimitive: true

                                })
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    command:[
                        {name:"edit", text: "Επεξεργασία"}
                    ],
                    text: "Επεξεργασία",
                    title: "&nbsp;"
                }
            ],
            edit: function(e) {
                e.model.dirty = true;
                KendoGrid.defaultEditPopup(this._editContainer);
            },
            editable: "popup"
        };

    }
})();
