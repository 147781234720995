/**
 * Created by Kristy on 1/6/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('RecDoctorViewSchedulerController', RecDoctorViewSchedulerController);

    RecDoctorViewSchedulerController.$inject = ['$http','$state', '$stateParams','$filter', 'Principal', 'ApplicationParameters'];

    function RecDoctorViewSchedulerController ($http, $state, $stateParams, $filter, Principal, ApplicationParameters) {
        var vm = this;
        if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR', 'ROLE_REC_MANAGEMENT'])) {
            vm.canEdit = true;
        }else {
            vm.readOnly = true;
        }
        ApplicationParameters.hasModule('Scheduling', true);
        vm.customer = {};
        vm.selectedUserId = $stateParams.id;
        $http.get('api/customers/' + vm.selectedUserId)
            .success(function (response) {
                vm.customer = response;
            })
            .error(function (response) {});

        vm.tooltipContent = kendo.template($("#template").html());
        if (vm.readOnly) {
            var startTime = new Date();
            startTime.setHours(8,0,0,0);

            var viewModel2 = new kendo.observable({
                schedulerData: new kendo.data.SchedulerDataSource ({
                    batch: true,
                    transport: {
                        read: function(o){
                            var scheduler = $("#scheduler").data("kendoScheduler");
                            var view = scheduler.view();

                            if (o.data.filter === undefined) {
                                o.data.filter = { filters: [
                                    { "field": "eventCustomerSector.customerSector.customer.id", "operator": "eq",
                                        "value": vm.selectedUserId },
                                    { "field": "startedAt", "operator": "from",
                                        "value": $filter('date')(view.startDate(), 'yyyy-MM-ddT00:00:00.000') + 'Z' },
                                    { "field": "startedAt", "operator": "to",
                                        "value": $filter('date')(view.endDate(), 'yyyy-MM-ddT23:59:59.000') + 'Z' }
                                ] };
                            }

                            $http.get('/api/me/events-and-schedules/all', { params: o.data })
                                .success(function (response,status,headers) {
                                    o.success(response);
                                })
                                .error(function (response) {});
                        },
                        create: function(o){
                            var data = o.data.models[0];
                            data.eventCategoryType = {
                                id: 1
                            };

                            var date = new Date(data.start);
                            date.setTime(date.getTime() + (data.duration*60*1000));
                            angular.extend(data.eventSchedule, {
                                startedAt: data.start,
                                finishedAt: date
                            });

                            data.id = null;
                            data.eventCustomerSector.id = null;

                            $http.post('/api/events', data)
                                .success(function (response,status,headers) {
                                    o.success([response]);
                                    var scheduler = $("#scheduler").data("kendoScheduler");
                                    scheduler.dataSource.read();
                                })
                                .error(function (response) {});
                        },
                        update: function(o){
                            var data = o.data.models[0];
                            data.eventCategoryType = {
                                id: 1
                            };
                            var date = new Date(data.start);
                            date.setTime(date.getTime() + (data.duration*60*1000));
                            if(!data.startedAt) {
                                data.eventSchedule.startedAt = data.start;
                                data.eventSchedule.finishedAt = date;
                            } else {
                                data.startedAt = data.start;
                                data.finishedAt = date;
                            }

                            data.eventCustomerSector.agreementValuation = null;

                            $http.put('/api/events', data)
                                .success(function (response,status,headers) {
                                    o.success([response]);
                                    var scheduler = $("#scheduler").data("kendoScheduler");
                                    scheduler.dataSource.read();
                                })
                                .error(function (response) {});
                        }
                    },
                    schema: {
                        data: function (data) {
                            if(data) {
                                data.forEach( function (item) {
                                    if (!item.eventCustomerSector) {
                                        item.eventCustomerSector = {
                                            customerSector: {
                                                customer: {
                                                    last_name: "",
                                                    first_name: ""
                                                },
                                                sector: {
                                                    address: "",
                                                    city: {
                                                        name: ""
                                                    }
                                                }
                                            },
                                            agreementValuation: {
                                                name: ""
                                            },
                                            communicationType: {
                                                name: ""
                                            }
                                        };
                                    } else {
                                        if (item.eventCustomerSector.agreementValuation === null) {
                                            item.eventCustomerSector.agreementValuation = {};
                                            item.eventCustomerSector.agreementValuation.name = "";
                                        }
                                        if (item.eventCustomerSector.communicationType === null) {
                                            item.eventCustomerSector.communicationType = {};
                                            item.eventCustomerSector.communicationType.name = "";
                                        }
                                    }

                                    if (item.eventSchedule === null) {
                                        item.eventSchedule = {
                                            description: ""
                                        };
                                    }
                                });
                            }

                            return data;
                        },
                        parse: function (data) {
                            if(data) {
                                data.forEach( function (item) {
                                    if (item.startedAt === null) {
                                        item.isSchedule = true;
                                        item.start = item.eventSchedule.startedAt;
                                        item.end = item.eventSchedule.finishedAt;
                                    } else {
                                        item.isSchedule = false;
                                        item.start = item.startedAt;
                                        item.end = item.finishedAt;
                                    }

                                    item.duration = ((new Date(item.end) - new Date(item.start))/1000/60) << 0;
                                });
                            }
                            return data;
                        },
                        model: {
                            id: "taskId",
                            fields: {
                                taskId: { from: "id", type: "number", editable: false },
                                start: { type: "date", from: "start" },
                                end: { type: "date", from: "end" },
                                durationTime: { type: "number", defaultValue: 15, from: "duration" },
                                title: { from: "title", defaultValue: "ΠΡΟΣΩΠΙΚΗ ΕΠΙΣΚΕΨΗ", validation: { required: true } },
                                eventSchedule: { defaultValue: {} },
                                eventCustomerSector: {
                                    defaultValue: {
                                        communicationType: { defaultValue: {} },
                                        customerSector: {
                                            defaultValue: { customer: {defaultValue: {}}, sector: {defaultValue: {}}}
                                        }
                                    }
                                }
                            }
                        }
                    },
                    serverFiltering: true
                })
            });

            //vm.checkAvailability = function (e) {
            //    if (e.event.eventCustomerSector.customerSector.customer) {
            //        if (e.event.eventCustomerSector.customerSector.customer.id != vm.selectedUserId) {
            //            e.preventDefault();
            //        }
            //    }
            //};

            vm.schedulerOptions = {
                dataSource: viewModel2.schedulerData,
                dataBound: function(e) {
                    if (this.view().name === "day") {
                        vm.view = "day";
                    } else if (this.view().name === "workWeek" || this.view().name === "week") {
                        vm.view = "week";
                    } else if (this.view().name === "month") {
                        vm.view = "month";
                    }
                },
                resources: [
                    {
                        field: "isSchedule",
                        dataSource: [
                            { text: "Event", value: true, color: "#007fff!important" },
                            { text: "Schedule", value: false, color: "#ff5c33!important" }
                        ]
                    }
                ],
                date: new Date(),
                startTime: startTime,
                allDaySlot: false,
                messages: {
                    today: "Τρέχουσα Ημέρα",
                    showFullDay: "Προβολή ολόκληρης της ημέρας",
                    showWorkDay: "Προβολή ωραρίου εργασίας",
                    "cancel": "Κλείσιμο",
                    "save": "Αποθήκευση",
                    "editor": {
                        "title": "Τίτλος Ραντεβού",
                        "start": "Ημερομηνία",
                        "end": "Τελειώνει"
                    }
                },
                height: 600,
                views: [
                    { type: "day", title: "Ημέρα" },
                    { type: "workWeek", title: "Εργάσιμες Ημέρες", selected: true },
                    { type: "week", title: "Εβδομάδα" },
                    { type: "month", title: "Μήνας" }
                ],
                editable: {
                    destroy: false,
                    mode: "popup",
                    template: kendo.template(angular.element("#popup_editor2").html())
                },
                //resize: function(e) {
                //    vm.checkAvailability(e);
                //},
                //move: function(e) {
                //    vm.checkAvailability(e);
                //},
                //save: function(e) {
                //    vm.checkAvailability(e);
                //},
                edit: function(e) {
                    $("div.k-edit-form-container").width(1000);
                    var wnd = $(e.container).data("kendoWindow");
                    wnd.setOptions({
                        title: "Ραντεβού"
                    });

                    e.container
                        .find("[name=isAllDay]") // find the all day checkbox
                        .parent() // get its wrapper
                        .prev() // get the label wrapper
                        .remove() // remove the label wrapper
                        .end() // get back to the checkbox wrapper
                        .remove(); // remove the checkbox wrapper

                    e.container
                        .find("[name=recurrenceRule]") // find the recurrence rule
                        .parent() // get its wrapper
                        .prev() // get the label wrapper
                        .remove() // remove the label wrapper
                        .end() // get back to the checkbox wrapper
                        .remove(); // remove the checkbox wrapper

                    vm.currentCustomer = e.event.eventCustomerSector.customerSector.customer;
                    vm.currentSector = e.event.eventCustomerSector.customerSector.sector;

                    //choose readonly editor if is not a schedule
                    if(!e.event.isNew()) {
                        vm.customerChanged = false;
                        vm.isSchedule = !e.event.startedAt;

                        if(!vm.isSchedule) {
                            wnd.setOptions({
                                title: "Δραστηριότητα"
                            });
                        }

                        if(!vm.isSchedule || vm.currentCustomer.id !== vm.selectedUserId) {
                            e.container
                                .find(".k-edit-buttons") // find edit buttons
                                .remove(); // remove the checkbox wrapper
                        }

                        //read customerSectors
                        var sector = $("#sector").data("kendoComboBox");
                        sector.dataSource.read();
                    } else {
                        vm.customerChanged = true;
                        vm.currentCustomer = vm.customer;
                        vm.isSchedule = true;
                    }
                }
            };

            vm.customerSectorComboBoxDataSource= new kendo.data.DataSource({
                serverFiltering: true,
                transport: {
                    read: function(o) {
                        if(vm.currentCustomer) {
                            var filter =  { "filters" : [ { "field": "customer.id", "operator": "eq",  "value": vm.currentCustomer.id } ] }
                        }
                        var url = '/api/me/customer-sectors';
                        o.data.page = 1;
                        o.data.pageSize = 1000;
                        o.data.filter = filter;
                        $http.get(url, {params: o.data})
                            .success(function (response) {
                                o.success(response.content);
                            })
                            .error(function (response) {});
                    }
                }
            });

            vm.communicationTypesComboBoxDataSource= new kendo.data.DataSource({
                serverFiltering: true,
                transport: {
                    read: function(o) {
                        var url = '/api/masterdata/communication-types';
                        o.data.page = 1;
                        o.data.pageSize = 1000;
                        $http.get(url, {params: o.data })
                            .success(function (response) {
                                o.success(response.content);
                            })
                            .error(function (response) {});
                    }
                }
            });

            vm.communicationTypesComboBoxOptions = {
                dataSource: vm.communicationTypesComboBoxDataSource,
                filter: "startswith",
                valuePrimitive: true,
                dataTextField: "name",
                dataValueField: "id",
                dataBound: function() {
                    if (!this.value() && this.selectedIndex === -1) {
                        this.select(0);
                        this.trigger("change");
                    }
                }
            };

            vm.sectorComboBoxOptions = {
                dataSource: vm.customerSectorComboBoxDataSource,
                filter: "startswith",
                valuePrimitive: true,
                dataTextField: "sector.name",
                dataValueField: "id",
                dataBound: function() {
                    if (vm.customerChanged) {
                        this.select(0);
                        this.trigger("change");
                    }
                }
            };
        }

    }
})();
