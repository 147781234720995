/**
 * Created by gmogas on 8/12/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminPharmacyViewEventsController', AdminPharmacyViewEventsController);

    AdminPharmacyViewEventsController.$inject = ['$stateParams', '$http', '$filter', 'KendoFilter'];

    function AdminPharmacyViewEventsController ($stateParams, $http, $filter, KendoFilter) {
        var vm = this;
        vm.pageHeading = 'Επισκέψεις στο φαρμακείο';
        vm.selectedSectorId = $stateParams.id;

        var grid = angular.element('#eventsGrid');

        var urls = {};
        urls.sectorEvents = '/api/sector/'+vm.selectedSectorId+'/events';

        // DataSources
        vm.dataSource = {};
        vm.dataSource.grid = {};
        vm.dataSource.grid.events = new kendo.data.DataSource({
            autoBind: false,
            transport: {
                read: function (o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    angular.forEach( o.data.filter.filters, function(item) {
                        if ( item.value instanceof Date) {
                            item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                        }
                    });

                    $http.get(urls.sectorEvents,{params: o.data})
                        .success(function (response) {
                            //console.log(response);
                            o.success(response);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            },
            schema: {
                data: function (data) {
                    if(data != undefined) {
                        data.content.forEach(function(item){

                            if (item.eventStatus === null) {
                                item.eventStatus = {};
                                item.eventStatus.name = "";
                            }

                            if(item.employee === null){
                                item.employee = {
                                    account : {
                                        lastName : ""
                                    }
                                }
                            }

                            if (item.eventCustomerSector === null) {
                                if (item.agreementValuation === null) {
                                    item.agreementValuation = {
                                        name: ""
                                    };
                                }

                                if (item.customerSector === null) {
                                    item.customerSector = {
                                        sector: {
                                            address: "",
                                            city: {
                                                name: ""
                                            }
                                        }
                                    }
                                }
                            } else {
                                if (item.eventCustomerSector.agreementValuation === null) {
                                    item.eventCustomerSector.agreementValuation = {
                                        name: ""
                                    };
                                }
                            }

                        });
                    }
                    return data.content;
                },
                total : function(data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        id: { editable: false, nullable: true },
                        name: { validation: { required: true , validation: { required: true, min: 3}} },
                        startedAt: { type: 'date'}
                    }
                }
            },
            batch: true,
            serverSorting: true,
            serverFiltering: true,
            serverPaging: true,
            pageSize: 50
        });

        // Grid Columns
        vm.gridColumns = {};
        vm.gridColumns.events = [
            {
                field: "startedAt",
                title: "Ημερομηνία Επίσκεψης",
                template: "#= kendo.toString(kendo.parseDate(startedAt), 'dddd, dd/MM/yyyy HH:mm')#",
                filterable: {
                    cell: {
                        operator: "eqdate",
                        showOperators: false
                    }
                }
            },
            {
                field: "eventStatus.name",
                title: "Κατάσταση Επίσκεψης",
                attributes: {
                    class: "#=eventStatus.id == '1' ? 'red' : 'green' #"
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "eventCustomerSector.agreementValuation.name",
                title: "Αξιολόγηση",
                template: "#= (eventCustomerSector.agreementValuation == null || eventCustomerSector.agreementValuation.name == null ) ? ' ' : eventCustomerSector.agreementValuation.name #",
                attributes: {
                    class: "#=eventCustomerSector.agreementValuation.id == '1' ? 'red' : eventCustomerSector.agreementValuation.id == '2' ? 'orange' : eventCustomerSector.agreementValuation.id == '3' ? 'purple' : eventCustomerSector.agreementValuation.id == '4' ? 'green' : 'black' #"
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                command: [
                    {
                        name:"map2",
                        template: "<md-button class='md-fab md-mini md-primary' aria-label='Google Maps' " + "ng-class='{googlemap: vm.imageChoice(dataItem.eventCustomerSector), errormap: !vm.imageChoice(dataItem.eventCustomerSector)}' ng-click='vm.googleMapsRedirect(dataItem.eventCustomerSector)'></md-button>"
                    }
                ],
                attributes: { class: "#= 'link-cell-color' #" },
                title: "Τοποθεσία",
                width:120
            },
            {
                field: "eventCustomerSector.agreedPercent",
                title: "Ποσοστό Συχνότητας",
                template: "#= (eventCustomerSector.agreedPercent == null) ? ' ' : kendo.format('{0:p0}', eventCustomerSector.agreedPercent / 100)#",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "employee.account.lastName",
                title: "Επώνυμο Ιατρικού Επισκέπτη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "description",
                title: "Σημειώσεις",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];

        vm.gridOptions = {};
        vm.gridOptions.events = {
            dataSource: vm.dataSource.grid.events,
            pageable: true,
            sortable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            columns: vm.gridColumns.events
        };
    }
})();
