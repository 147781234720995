(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CustomerSectorDeleteController',CustomerSectorDeleteController);

    CustomerSectorDeleteController.$inject = ['$uibModalInstance', 'entity', 'CustomerSector'];

    function CustomerSectorDeleteController($uibModalInstance, entity, CustomerSector) {
        var vm = this;

        vm.customerSector = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CustomerSector.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
