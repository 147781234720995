/**
 * Created by Kristy Kavvada on 5/12/2017.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminEmployeeViewBeautyCentersController', AdminEmployeeViewBeautyCentersController);

    AdminEmployeeViewBeautyCentersController.$inject = ['$http','KendoGrid', '$stateParams', '$filter', 'KendoFilter', 'ApplicationParameters', 'CustomUtils'];

    function AdminEmployeeViewBeautyCentersController ($http, KendoGrid, $stateParams, $filter, KendoFilter, ApplicationParameters, CustomUtils) {
        var vm = this;
        ApplicationParameters.hasModule('Visit_Beauty_Centers', true);
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(60);
        vm.selectedEmployees =
            vm.selectedAssignees = [];
        vm.massSelect = true;

        vm.selectedUser = {};
        vm.selectedUser.id = $stateParams.id;

        vm.sectorGrid = {};
        vm.customerSectorSuggestGrid = {};

        vm.sectorGrid.columns = [
            {
                field: "sector.name", title: "Επωνυμία",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "sector.vat",
                title: "Α.Φ.Μ.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "sector.companyType.name",
                title: "Εταιρική Μορφή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "sector.address", title: "Διεύθυνση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "sector.addressNum", title: "Αριθμός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "sector.postalCode", title: "T.K.",
                filterable: {
                    cell: {
                        operator: "contains",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "sector.cityName", title: "Πόλη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "sector.prefectureName", title: "Νομός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "sector.phone", title: "Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];
        vm.sectorGrid.options = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {

                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "active", "operator": "eqbool",  "value": true }
                            ]
                        );

                        var url = "/api/employee/"+vm.selectedUser.id+"/sectors";

                        o.data.sectorTypeCategory = "beautyCenter";

                        $http.get(url,
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema: {
                    data: function (data) {
                        if(data.content != undefined) {
                            data.content.forEach(function(item){
                                if(item.customerSector === null) item.customerSector = {};
                                if (item.sector) {
                                    if(!item.sector.companyType) item.sector.companyType = {};
                                    if(!item.sector.taxOffice) item.sector.taxOffice = {};
                                } else {
                                    item.sector = {
                                        companyType: {},
                                        taxOffice: {}
                                    }
                                }

                            });
                        }

                        return data.content;
                    },
                    total : function(data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true }
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            height: vm.contentHeight,
            sortable: true,
            pageable: true,
            selectable: true,
            resizable: true,
            scrollable: true,
            filterable: {
                mode: "row"
            },
            columns: vm.sectorGrid.columns,
            toolbar: [
                {
                    name: "Assign",
                    text: "Assign Customer Sector",
                    template: "<input id='assignToSectorsBtn' type='button' class='k-button' value = 'Ανάθεση σε Κέντρα Αισθητικής' ng-click='vm.openWindow()' />"
                },
                {
                    name: "ReAssign",
                    text: "ReAssign",
                    template: function (dataItem) {
                        return "<input type='button' class='k-button' value = 'Διαγραφή Ιατρικού Επισκέπτη από Κέντρα Αισθητικής' ng-click='vm.reAssign()' />" ;
                    }
                },
                { template: "<div class='right'><label>Σύνολο: {{vm.sectorGrid.options.dataSource.total()}}</label></div>",
                    overflow: "never" }
            ],
            selectedItems: function(dataItems) {
                vm.selectedAssignees = dataItems;
            },
            dataBound: function (e) {
                angular.element("#sectorsGridcheckbox").prop('checked', false);
                //toggle class on click
                angular.element(".checkbox").bind("change", function (e) {
                    angular.element(e.target).closest("tr").toggleClass("k-state-selected");
                });

                var view = this.dataSource.data();
                var me = this;

                var selectedAssigneesViewCounter = 0;
                angular.forEach( view, function(item) {
                    var foundItemById = $filter('filter')(vm.selectedAssignees, {id: item.id}, true)[0];
                    if(vm.selectedAssignees.indexOf(foundItemById) !== -1){
                        me.tbody.find("tr[data-uid='" + item.uid + "']")
                            .addClass("k-state-selected");
                        item.selected = true;
                        selectedAssigneesViewCounter++
                    }
                });
                if(selectedAssigneesViewCounter == view.length) {
                    angular.element("#sectorsGridcheckbox").prop('checked', true);
                }
            }
        };

        vm.customerSectorSuggestGrid.columns = [
            {
                field: "name", title: "Επωνυμία",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "vat",
                title: "Α.Φ.Μ.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "companyType.name",
                title: "Εταιρική Μορφή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "address", title: "Διεύθυνση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "addressNum", title: "Αριθμός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "postalCode", title: "T.K.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "cityName", title: "Πόλη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "prefectureName", title: "Νομός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "phone", title: "Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];
        vm.customerSectorSuggestGrid.options = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        //filter beautyCenters
                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "sectorType.sectorTypeCategory", "operator": "eq",  "value": 'beautyCenter'}
                            ]
                        );

                        $http.get('/api/customer-sectors/employees/' + vm.selectedUser.id + '/sectors/suggest',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema: {
                    data: function (data) {
                        if(data != undefined) {
                            data.content.forEach(function(item){
                                if(!item.companyType) item.companyType = {};
                            });
                        }
                        return data.content;
                    },
                    total : function(data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            sector: {defaultValue : { id : null, name : null}},
                            sectorPosition: {defaultValue : { id : null, name : null}},
                            startedAt: { type: 'date'},
                            endedAt: { type: 'date', defaultValue : null},
                            customer: { defaultValue : { id : null, firstName : null, lastName : null}}
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 10
            }),
            sortable: true,
            pageable: true,
            selectable: true,
            filterable: {
                mode: "row"
            },
            columns: vm.customerSectorSuggestGrid.columns,
            selectedItems: function(dataItems) {
                vm.selectedEmployees = dataItems;
            },
            dataBound: function (e) {
                angular.element("#customerSectorSuggestGridcheckbox").prop('checked', false);
                //toggle class on click
                angular.element(".checkbox").bind("change", function (e) {
                    angular.element(e.target).closest("tr").toggleClass("k-state-selected");
                });

                var view = this.dataSource.data();
                var me = this;

                if (vm.selectedEmployees) {
                    var selectedEmployeesViewCounter = 0;
                    angular.forEach( view, function(item) {
                        var foundItemById = $filter('filter')(vm.selectedEmployees, {id: item.id}, true)[0];
                        if(vm.selectedEmployees.indexOf(foundItemById) !== -1){
                            me.tbody.find("tr[data-uid='" + item.uid + "']")
                                .addClass("k-state-selected");
                            item.selected = true;
                            selectedEmployeesViewCounter++
                        }
                    });
                    if(selectedEmployeesViewCounter == view.length) {
                        angular.element("#customerSectorSuggestGridcheckbox").prop('checked', true);
                    }
                }
            },
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.customerSectorSuggestGrid.options.dataSource.total()}}</label></div>",
                overflow: "never" }]
        };

        vm.openWindow = function(){
            vm.selectedEmployees = [];

            vm.customerSectorSuggestGrid.options.dataSource.page(1);
            vm.customerSectorSuggestGrid.options.dataSource.filter({});
            vm.customerSectorSuggestGrid.options.dataSource.read();

            var wdw = angular.element("#popup-sector").data("kendoWindow");
            wdw.center().open();
        };

        vm.closeWindow = function() {
            var wdw = angular.element("#popup-sector").data("kendoWindow");
            wdw.close();
        };

        var url = 'api/employee-customer-sectors/mass';

        vm.saveSectors = function() {
            var wdw = angular.element("#popup-sector").data("kendoWindow");
            var postData = [];

            vm.selectedEmployees.forEach(function(item,key){
                var newPost = {};
                newPost.active = true;
                newPost.sector = {};
                newPost.sector.id = item.id;
                newPost.employee = {};
                newPost.employee.id = vm.selectedUser.id;
                postData.push(newPost)
            });

            $http.post(url, postData, {})
                .success(function (response) {
                    wdw.close();
                    vm.selectedEmployees = [];
                    // KendoGrid.refresh(vm.customerSectorSuggestGrid.οptions);
                    angular.element("#sectorsGrid").data("kendoGrid").dataSource.read();
                    angular.element("#sectorsGrid").data("kendoGrid").refresh();
                })
                .error(function (response) {
                    console.log(response);
                });
        };

        vm.reAssign = function () {
            var postData = [];

            vm.selectedAssignees.forEach(function(item,key){
                var newPost = {};
                newPost.active = false;
                newPost.sector = {};
                newPost.sector.id = item.sector.id;
                newPost.employee = {};
                newPost.employee.id = vm.selectedUser.id;
                postData.push(newPost)
            });

            $http.post(url, postData, {})
                .success(function (response) {
                    vm.selectedAssignees = [];
                    angular.element("#sectorsGrid").data("kendoGrid").dataSource.read();
                    angular.element("#sectorsGrid").data("kendoGrid").refresh();
                    // KendoGrid.refresh(vm.sectorGrid.οptions);
                })
                .error(function (response) {
                    console.log(response);
                });

        };
    }

})();
