/**
 * Created by Teo on 02/05/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadCallsViewController', LeadCallsViewController);

    LeadCallsViewController.$inject = ['$scope', '$state', '$http', '$filter', '$stateParams', '$timeout', 'LeadCallHelper', 'PdfBuilder', 'CustomDateUtils', 'LeadCallsGrids', 'KendoGrid', 'KendoFilter',
        'KendoService', 'NotificationOptions', 'KendoNotifications', 'Alerts', 'callTypes', 'professions', 'relationshipTypes',
        'nativeLanguages', 'hospitalizationTypes', 'costTypes', 'roomTypes', 'transportations', 'discountReasons',
        'discountRates', 'competitors', 'addDiseases', 'addConditions', 'diseaseTypes', 'diseases', 'offerStatuses', 'communicationStatuses', 'countryPhoneCodes', 'CalculateAge', 'ProspectivePatient',
        'Attendant', 'Patient', 'Customer', 'LeadCall', 'DateUtils', 'CustomUtils', 'HospitalizationService'];

    function LeadCallsViewController ($scope, $state, $http, $filter, $stateParams, $timeout, LeadCallHelper, PDFPdfBuilder, CustomDateUtils, LeadCallsGrids, KendoGrid, KendoFilter,
                                      KendoService, NotificationOptions, KendoNotifications, Alerts, callTypes, professions, relationshipTypes,
                                      nativeLanguages, hospitalizationTypes, costTypes, roomTypes, transportations, discountReasons,
                                      discountRates, competitors, addDiseases, addConditions, diseaseTypes, diseases, offerStatuses, communicationStatuses, countryPhoneCodes, CalculateAge, ProspectivePatient,
                                      Attendant, Patient, Customer, LeadCall, DateUtils, CustomUtils, HospitalizationService) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(61);
        var dataSource = {};
        var followUpGrid = {};
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");
        var previousTab = "tabLeadCall";
        vm.title = 'Δημιουργία Νέας Κλήσης';
        vm.saveButtonLabel = 'Αποθήκευση Κλήσης';

        vm.expandCollapseButtonLabel = vm.expandCollapseButtonLabel_attendant = "Περισσότερα Πεδία";
        vm.expandCollapseIcon = vm.expandCollapseIcon_attendant = 'expand_more';
        vm.isExpanded = vm.isExpanded_attendant = vm.disableCostType = false;

        // Init MasterData
        vm.callTypes = callTypes;
        vm.professions = professions;
        vm.relationshipTypes = relationshipTypes;
        vm.nativeLanguages = nativeLanguages;
        vm.hospitalizationTypes = hospitalizationTypes;
        vm.costTypes = costTypes;
        vm.roomTypes = roomTypes;
        setupRoomTypes();
        vm.transportations = transportations;
        vm.discountReasons = discountReasons;
        vm.discountRates = discountRates;
        vm.competitors = competitors;
        vm.addDiseases = addDiseases;
        setupAddDiseases();
        vm.addConditions = addConditions;
        setupAddConditions();
        vm.diseaseTypes = diseaseTypes;
        vm.diseases = diseases;
        setupDiseasesWithTypes();
        vm.leadHospitalizationDiseases = [];
        vm.leadOfferRoomTypes = [];
        vm.offerStatuses = offerStatuses;
        vm.communicationStatuses = communicationStatuses;
        vm.countryPhoneCodes = countryPhoneCodes;

        if ($stateParams.id){
            // Edit Mode
            vm.title = 'Επεξεργασία Κλήσης';

            LeadCall.get({id : $stateParams.id}).$promise.then(function (data) {
                vm.leadCall = data;
                if (vm.leadCall){
                    vm.copied_leadCall = angular.copy(vm.leadCall);
                    // Quick workaround for setup callType radio button
                    vm.leadCall.callType = vm.callTypes.find(function (callType) {
                        return vm.leadCall.callType.id === callType.id;
                    });
                    vm.startDate = vm.startTime = vm.leadCall.dateTime;
                    vm.appointmentDate = vm.appointmentTime = vm.leadCall.appointmentDate;

                    if (vm.leadCall.prospectivePatient !== null && vm.leadCall.prospectivePatient !== undefined){
                        vm.prospectivePatient = angular.copy(vm.leadCall.prospectivePatient);//vm.leadCall.prospectivePatient;
                        if (vm.leadCall.prospectivePatient.attendant !== null && vm.leadCall.prospectivePatient.attendant !== undefined){
                            vm.attendant = angular.copy(vm.leadCall.prospectivePatient.attendant);//vm.leadCall.prospectivePatient.attendant;
                        }
                    }
                    vm.setData(["prospectivePatient", "attendant", "oldPatient", "oldPatientDoctor"]);

                    if (vm.leadCall.leadHospitalizations[0]){
                        vm.leadHospitalization = vm.leadCall.leadHospitalizations[0];
                        vm.copied_leadHospitalization = angular.copy(vm.leadHospitalization);
                        if (vm.leadHospitalization.addDiseases != null && vm.leadHospitalization.addDiseases.length > 0){
                            setAddDiseases();
                        }

                        if (vm.leadHospitalization.addConditions != null && vm.leadHospitalization.addConditions.length > 0){
                            setAddConditions();
                        }

                        // Get LeadHospitalizationDiseases by leadHospitalizationId
                        var leadHospitalizationDiseasefilter = {
                            field: "leadHospitalization.id",
                            operator: "eq",
                            value: vm.leadCall.leadHospitalizations[0].id
                        };
                        $http.get('/api/lead-hospitalization-diseases/search',
                            {params:{
                                    page: "1",
                                    pageSize: "200",
                                    filter : { filters: [leadHospitalizationDiseasefilter] }}})
                            .success(function (response) {
                                vm.leadHospitalizationDiseases = response.content;
                                setDiseases();
                            })
                            .error(function (error) {
                                console.log(error);
                            });
                    }

                    // Get LeadOffer
                    $http.get('/api/lead-offers/full/' + vm.leadCall.id)
                        .success(function (response) {
                            vm.leadOffer = response.leadOffer;
                            vm.leadOfferRoomTypes = response.leadOfferRoomTypes;
                            vm.leadDiscount = vm.leadOffer.leadDiscount;

                            if (vm.leadOffer.firstEstimationDate != null){
                                vm.isFirstEstimationDate = true;
                            }

                            vm.hasFinalDiscount = (vm.leadOffer.leadDiscount && vm.leadOffer.leadDiscount.finalDiscount && vm.leadOffer.leadDiscount.finalDiscount.id > 0) ||
                                (vm.leadOffer.leadDiscount && vm.leadOffer.leadDiscount.finalReason && vm.leadOffer.leadDiscount.finalReason.id > 0);
                            // Trigger cost calculation
                            vm.onRoomCostChange();
                            // Also set it's LeadOfferRoomTypes
                            setRoomTypes();
                        })
                        .error(function (error) {
                            console.log(error);
                            // No saved LeadOffer, set LeadCall to new LeadOffer
                            vm.leadOffer.leadCall = vm.leadCall;
                        });
                }
            });
        }

        // Init objects
        vm.leadCall = {
            noContact: false,
            isOldPatient: false,
            isOldPatientRelative: false,
            isImportant: false,
            bookedAppointment: false,
            callType: vm.callTypes != null && vm.callTypes.length > 0 ? vm.callTypes[1] : {},
            prospectivePatient: {
                isWrongPhone: false,
                isWrongContact: false,
                attendant: {
                    isWrongPhone: false,
                    isWrongContact: false,
                    profession: null,
                    relationshipType: null
                },
                profession: null,
                nativeLanguage: null
            },
            oldPatient: null,
            oldPatientDoctor: null,
            leadHospitalizations: []
        };
        vm.prospectivePatient = {
            isWrongPhone: false,
            isWrongContact: false,
            attendant: null/*{
                isWrongPhone: false,
                isWrongContact: false,
                profession: null,
                relationshipType: null
            }*/,
            profession: null,
            countryPhoneCode: vm.countryPhoneCodes !== null && vm.countryPhoneCodes.length > 0 ?
                vm.countryPhoneCodes.find(function (countryPhoneCode) { return countryPhoneCode.id === 1 }) : null,
            nativeLanguage: vm.nativeLanguages != null && vm.nativeLanguages.length > 0 ? vm.nativeLanguages[0] : {}
        };
        vm.attendant = {
            isWrongPhone: false,
            isWrongContact: false,
            profession: null,
            relationshipType: null,
            countryPhoneCode: vm.countryPhoneCodes !== null && vm.countryPhoneCodes.length > 0 ?
                vm.countryPhoneCodes.find(function (countryPhoneCode) { return countryPhoneCode.id === 1 }) : null,
        };
        vm.oldPatient = {};
        vm.oldPatientDoctor = {};
        vm.leadHospitalization = {
            possibleDischargeDate: null, // new Date(),
            diagnosisDate: null, // new Date(),
            hospitalizationDate: null,
            isClosedHospitalization: true,
            hadSurgery: false,
            isFollowingHospitalization: false,
            sector: null,
            hospitalizationType: vm.hospitalizationTypes != null && vm.hospitalizationTypes.length > 0 ? vm.hospitalizationTypes[0] : null,
            clinicName: null,
            communicationStatus: null,
            comments: "",
            surgeryType: "",
            addDiseases: [],
            addConditions: []
        };
        vm.leadOffer = {
            firstEstimationDate: null, // new Date(),
            openHospitalizationReference: false,
            wantsTransportation: false,
            attendantMeal: false,
            hasCompetitiveBid: false,
            costType: null,
            roomType: null,
            transportation: null, // vm.transportations != null && vm.transportations.length > 0 ? vm.transportations[0] : {},
            offerStatus: null,
            competitor: null,
            desirableRoomType: null,
            upgradedRoomType: null,
            roomServices: "",
            comments: "",
            leadDiscount: {
                hasRequestedDiscount: false,
                isEntitledToDiscount: false,
                requestedReason: null,
                entitledToReason: null,
                finalDiscount: null,
                finalReason: null
            },
            leadCall: {
                noContact: false,
                isOldPatient: false,
                isOldPatientRelative: false,
                isImportant: false,
                callType: vm.callTypes != null && vm.callTypes.length > 0 ? vm.callTypes[0] : {},
                prospectivePatient: {
                    isWrongPhone: false,
                    isWrongContact: false,
                    attendant: {
                        isWrongPhone: false,
                        isWrongContact: false,
                        profession: null,
                        relationshipType: null
                    },
                    profession: null,
                    nativeLanguage: null
                },
                oldPatient: null,
                oldPatientDoctor: null,
                leadHospitalizations: []
            },
            invitationText: null
        };
        vm.leadDiscount = {
            hasRequestedDiscount: false,
            isEntitledToDiscount: false,
            requestedReason: null,
            entitledToReason: null,
            finalDiscount: null,
            finalReason: null
        };
        vm.roomCost = null;
        vm.roomCostWithDiscount = null;
        // Keep a reference to initial objects for comparing
        vm._leadCall = angular.copy(vm.leadCall);
        vm._prospectivePatient = angular.copy(vm.prospectivePatient);
        vm._attendant = angular.copy(vm.attendant);
        vm._oldPatient = angular.copy(vm.oldPatient);
        vm._oldPatientDoctor = angular.copy(vm.oldPatientDoctor);
        vm._leadHospitalization = angular.copy(vm.leadHospitalization);
        vm._leadOffer = angular.copy(vm.leadOffer);
        vm._leadDiscount = angular.copy(vm.leadDiscount);

        // Init helper fields/attrs
        vm.isFirstEstimationDate = false;
        vm.hasFinalDiscount = false;
        vm.startDate = vm.startTime = new Date();
        vm.appointmentDate = vm.appointmentTime = null;
        vm.attendantFullName = vm.doctorFullName = vm.oldPatientFullName = vm.prospectivePatientFullName = "";
        vm.editProspectivePatientMode = vm.editAttendantMode = vm.editOldPatientMode = vm.editOldPatientDoctorMode =
            vm.focusYearOfBirth = vm.focusAge = vm.oldPatientFocusYearOfBirth = vm.oldPatientFocusAge = false;
        vm.showSaveButton = true;
        vm.showChargesTabButtons = false;
        vm.showDiseasesTabButtons = false;
        vm.showAddDiseaseOther = false;
        vm.today = new Date().getFullYear();

        vm.validatorOptions = {
            rules: {
                custom: function (input) {
                    var valid = true;
                    if (input.data('custom')) {
                        if (input.is("[name='prospectivePatientPhone']")){
                            valid = LeadCallHelper.validatePhone(vm.prospectivePatient.countryPhoneCode, vm.prospectivePatient.phone);
                        }else if (input.is("[name='attendantPhone']")){
                            valid = LeadCallHelper.validatePhone(vm.attendant.countryPhoneCode, vm.attendant.phone);
                        }
                    }
                    return valid;
                }
            },
            messages: { custom: "Η μοφρή τηλεφώνου είναι λάθος!" },
            validateOnBlur: false
        };

        // GeoData
        var geodataDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 100;
                    $http.get('/api/masterdata/geodata',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            },
            schema: {
                parse: function(data) {
                    angular.forEach(data, function(item) {
                        item.masterFilter = "";
                        if(item.street !== null) {
                            item.masterFilter =  item.street + ", ";
                        }
                        item.masterFilter = item.masterFilter + item.city + ", " + item.postalCode + ", " + item.prefecture;
                    });
                    return data;
                }
            }
        });
        vm.masterFilterComboBoxOptions = {
            dataSource: geodataDataSource,
            filter: "search",
            dataTextField: "masterFilter",
            dataValueField: "id",
            headerTemplate:"<div class='dropdown-header k-widget k-header'>" +
            "<span>Διεύθυνση</span>"+
            "<span>Πόλη</span>"+
            "<span>Τ.Κ.</span>"+
            "<span>Νομός</span></div>",
            template:
                "#= street != null ? street + ', ' + city + ', ' + postalCode + ', ' + prefecture : city + ', ' + postalCode + ', ' + prefecture #",
            placeholder: "Αναζητήστε Διεύθυνση, Πόλη, Τ.Κ., Νομό",
            change: function(){
                //initialize inputs (autocomplete)
                if(this.dataItem(this.select())) {
                    var self = this;
                    //using $timeout to trigger the change
                    $timeout(function(){
                        vm.prospectivePatient.prefectureName = self.dataItem(self.select()).prefecture;
                        vm.prospectivePatient.cityName = self.dataItem(self.select()).city;
                        vm.prospectivePatient.address = self.dataItem(self.select()).street;
                        vm.prospectivePatient.postalCode = self.dataItem(self.select()).postalCode;

                        var parentFilter = {};
                        if (vm.leadCall.prospectivePatient.prefectureName) {
                            parentFilter = {
                                "field": "prefectureName",
                                "operator": "eq",
                                "value": vm.leadCall.prospectivePatient.prefectureName
                            };
                        }
                    }, 0);
                }
            }
        };
        // Sector combobox edit configuration
        dataSource.sectorsDataSource = KendoService.initDataSource('/api/me/sectors', 1, 20);
        vm.sectorOptions = KendoService.initDefaultOptions(dataSource.sectorsDataSource, "like", "name", "id");
        vm.sectorOptions.placeholder = "Πληκτρολογήστε Νοσοκομείο";
        vm.sectorOptions.valuePrimitive = false;
        vm.sectorOptions.autoBind = false;
        // ClinicNames combobox edit configuration
        dataSource.clinicNamesDataSource = KendoService.initDataSource('/api/clinic-names/search', 1, 20);
        vm.clinicNamesOptions = KendoService.initDefaultOptions(dataSource.clinicNamesDataSource, "like", "name", "id");
        vm.clinicNamesOptions.placeholder = "ΠΛηκτρολογήστε Κλινική";
        vm.clinicNamesOptions.valuePrimitive = false;
        vm.clinicNamesOptions.autoBind = false;

        // Follow Up Grid
        followUpGrid.datasource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) { o.data.filter = { filters: [] }; }

                    angular.forEach( o.data.filter.filters, function(item) {
                        if ( item.value instanceof Date) {
                            item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                        }
                    });

                    o.data.filter.filters.push({
                        field: "leadCall.id",
                        operator: "eq",
                        value: (vm.leadCall.id && vm.leadCall.id > 0) ? vm.leadCall.id : $stateParams.id
                    });
                    $http.get('/api/followup-calls/search',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        });
                },
                create: function (o){
                    var data = o.data.models[0];
                    if (!data.followupType.id > 0){
                        data.followupType = null;
                    }
                    if (!data.rejectionReason.id > 0){
                        data.rejectionReason = null;
                    }
                    if (!data.followupStatus.id > 0){
                        data.followupStatus = null;
                    }
                    data.leadCall = {
                        id: (vm.leadCall.id && vm.leadCall.id > 0) ? vm.leadCall.id : $stateParams.id
                    };
                    data.date = DateUtils.convertLocalDateToServer(data.date);
                    $http.post('/api/followup-calls', data)
                        .success(function (response) {
                            o.success(response);
                            angular.element("#followUpsGrid").data("kendoGrid").dataSource.read();
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                },
                update: function (o){
                    var data = o.data.models[0];
                    if (!data.followupType.id > 0){
                        data.followupType = null;
                    }
                    if (!data.rejectionReason.id > 0){
                        data.rejectionReason = null;
                    }
                    if (!data.followupStatus.id > 0){
                        data.followupStatus = null;
                    }
                    data.date = DateUtils.convertLocalDateToServer(data.date);
                    $http.put('/api/followup-calls', data)
                        .success(function (response) {
                            o.success(response);
                            //KendoGrid.refresh(vm.followUpGridOptions)
                            angular.element("#followUpsGrid").data("kendoGrid").dataSource.read();
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            },
            schema : {
                data: function (data) {
                    if(data.content !== undefined) {
                        data.content.forEach(function(item){
                            if (item.followupType === null){
                                item.followupType = {};
                            }
                            if (item.rejectionReason === null){
                                item.rejectionReason = {};
                            }
                            if (item.leadCall === null){
                                item.leadCall = {};
                            }
                            if (item.followupStatus === null){
                                item.followupStatus = {};
                            }
                        });
                    }
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        date: {
                            type: "date",
                            validation: {
                                required: {
                                    message: "Επιλέξτε FollowUp Ημερ/νία"
                                }
                            }
                        },
                        willTheyCall: {
                            type: "boolean",
                            defaultValue: false,
                            parse: function(value) {
                                return (value === "1" || value === "true" || value === true);

                            }
                        },
                        completed: {
                            type: "boolean",
                            defaultValue: false,
                            parse: function(value) {
                                return (value === "1" || value === "true" || value === true);

                            }
                        },
                        followupType: {
                            defaultValue: {id: null, name : null},
                            validation: {
                                required: {
                                    message: "Επιλέξτε Τύπο Follow Up"
                                }
                            }
                        },
                        /*leadCall: {
                            defaultValue: {id: (vm.leadCall.id && vm.leadCall.id > 0) ? vm.leadCall.id : $stateParams.id, name : null}
                        },*/
                        rejectionReason: {
                            defaultValue: {id: null, name : null}
                        },
                        followupStatus: {
                            defaultValue: {id: null, name : null}
                        }
                    }
                }
            },
            pageSize: 100,
            serverPaging: true,
            serverSorting: true,
            scrollable:false,
            serverFiltering :true
        });
        followUpGrid.columns = [
            {
                template: "<md-button class='md-raised md-mini' style='min-width: 50px;margin: 0;' aria-label='add'>" +
                " <i class='dark-grey material-icons fixed-icons-2'>edit</i></md-button>",
                title: "&nbsp;",
                width: 80,
                selectable: false,
                command:[
                    {name:"edit", text: ""}
                ]
            },
            {field: "date", title: "FollowUp - Ημερ/νία", template: "#= date ? kendo.toString(kendo.parseDate(date), 'dddd, dd/MM/yyyy') : ''#",
                filterable: {
                    cell: {
                        operator: "eqdateonly",
                        showOperators: false
                    }
                }
            },
            {template: '<md-checkbox class="checkbox-toolbar customers green inline"  aria-label="selectable" ng-model="dataItem.willTheyCall" ' +
                'ng-disabled="true"></md-checkbox>',
                field: "willTheyCall",
                title: "Θα Καλέσουν",
                filterable: {
                    cell: {
                        operator: "eqbool",
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "Ναι", value: "true" },
                                        { text: "Όχι", value: "false" }]
                                }),
                                valuePrimitive: true
                            })
                        },
                        showOperators: false
                    }
                },
                width: 160
            },
            {template: '<md-checkbox class="checkbox-toolbar customers green inline"  aria-label="selectable" ng-model="dataItem.completed" ' +
                'ng-disabled="true"></md-checkbox>',
                field: "completed",
                title: "Ολοκληρωμένο",
                filterable: {
                    cell: {
                        operator: "eqbool",
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "Ναι", value: "true" },
                                        { text: "Όχι", value: "false" }]
                                }),
                                valuePrimitive: true
                            })
                        },
                        showOperators: false
                    }
                },
                width: 160
            },
            {
                field: "followupType.name",
                title: "Τύπος",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                editor: function(container, options) {
                    vm.followUpTypeComboBoxDataSource= new kendo.data.DataSource({
                        transport: {
                            read: function(o) {
                                var url = '/api/followup-types';
                                $http.get(url)
                                    .success(function (response) {
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        },
                        schema: {
                            parse: function (data) {
                                return data;
                            }
                        }
                    });

                    angular.element('<select id="followUp-type" name="followUpType" required data-required-msg="Επιλέξτε Τύπο Follow Up" data-bind="value:followupType.id"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: vm.followUpTypeComboBoxDataSource,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        }).kendoValidator().data("kendoValidator");
                }
            },
            {
                field: "rejectionReason.name",
                title: "Λόγος Απόρριψης",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                editor: function(container, options) {
                    vm.rejectionReasonComboBoxDataSource= new kendo.data.DataSource({
                        transport: {
                            read: function(o) {
                                var url = '/api/rejection-reasons';
                                $http.get(url)
                                    .success(function (response) {
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        },
                        schema: {
                            parse: function (data) {
                                return data;
                            }
                        }
                    });

                    angular.element('<select data-bind="value:rejectionReason.id"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: vm.rejectionReasonComboBoxDataSource,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                }
            },
            {
                field: "followupStatus.name",
                title: "Κατάσταση Κλήσης",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                editor: function(container, options) {
                    vm.followUpStatusComboBoxDataSource= new kendo.data.DataSource({
                        transport: {
                            read: function(o) {
                                var url = '/api/followup-statuses';
                                $http.get(url)
                                    .success(function (response) {
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        },
                        schema: {
                            parse: function (data) {
                                return data;
                            }
                        }
                    });

                    angular.element('<select data-bind="value:followupStatus.id"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: vm.followUpStatusComboBoxDataSource,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                }
            },
            {
                field: "comments",
                title: "Σχόλια",
                filterable: {
                    cell: {
                        operator: "contains",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                editor: function (container, options) {
                    // Change simple input to textarea
                    $('<textarea rows="5" cols="30" data-bind="value: ' + options.field + '"></textarea>').appendTo(container);
                }
            }
        ];
        vm.followUpGridOptions = {
            dataSource: followUpGrid.datasource,
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: true,
            columns:  followUpGrid.columns,
            editable: {
                mode: "popup"
            },
            edit: function() {
                KendoGrid.defaultEditPopup(this._editContainer);
                //fix css in grid column header
                //angular.element( "div[data-container-for='comments']" ).addClass("k-textarea");
                //angular.element( "div[data-container-for='comments']" ).css("height", "30px").addClass("k-textarea")
            },
            toolbar: [{name :"create", text: "Προσθήκη Follow Up"},
                { template: "<div class='right'><label>Σύνολο: {{vm.followUpGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }],
            save: function(e){
                //vm.editMode = false;
            }
        };

        vm.goBack = function(ev){
            Alerts.showConfirmDialog(ev, "Sigma SalesForce", "Θα θέλατε να αποθηκεύσετε πριν την έξοδο?", true, "ΝΑΙ", "OXI", function () {
                vm.save(true);
            }, function () {
                //$state.go('leadCalls');
                history.back();
            });
        };

        vm.showInfo = function (title, info) {
            Alerts.showAlert(title, info, "Ok");
        };

        vm.openWindow = function (grid) {
            if (vm.popupGridOptions) KendoGrid.refresh(vm.popupGridOptions);
            vm.popupGridOptions = LeadCallsGrids.getGridOptions(grid);

            vm.window.title(LeadCallsGrids.getGridTitle(grid));
            vm.window.center().open();
        };

        vm.dataItemSelected = function () {
            var grid = angular.element("#popupGrid").data("kendoGrid");
            var selectedItem = grid.dataItem(grid.select());
            if (selectedItem) {
                switch (vm.popupGridOptions.grid) {
                    case "prospectivePatient":
                        vm.leadCall.prospectivePatient = selectedItem;
                        //vm.leadCall.isOldPatient = true; // ECRM-264, removed
                        vm.toggle("prospectivePatient", "clear");
                        vm.toggle("attendant", "clear");
                        vm.setData(["prospectivePatient", "attendant"]);
                        break;
                    case "attendant":
                        vm.leadCall.prospectivePatient.attendant = selectedItem;
                        vm.prospectivePatient.attendant = selectedItem;
                        vm.setData(["attendant"]);
                        break;
                    case "oldPatient":
                        vm.leadCall.oldPatient = selectedItem;
                        vm.setData(["oldPatient"]);
                        break;
                    case "oldPatientDoctor":
                        vm.leadCall.oldPatientDoctor = selectedItem.customer;
                        vm.setData(["oldPatientDoctor"]);
                        break;
                }
            }
            vm.window.close();
        };

        vm.setData = function (model) {
            if( vm.leadCall.prospectivePatient && model.indexOf("prospectivePatient") !== -1 ) {
                if (vm.leadCall.prospectivePatient.lastName !== null && vm.leadCall.prospectivePatient.firstName !== null){
                    vm.leadCall.prospectivePatient.fullName = vm.leadCall.prospectivePatient.lastName + " " + vm.leadCall.prospectivePatient.firstName;
                }else {
                    vm.leadCall.prospectivePatient.fullName = "";
                }
            }
            if( vm.leadCall.prospectivePatient && vm.leadCall.prospectivePatient.attendant && model.indexOf("attendant") !== -1 ) {
                if (vm.leadCall.prospectivePatient.attendant.lastName !== null && vm.leadCall.prospectivePatient.attendant.firstName !== null){
                    vm.leadCall.prospectivePatient.attendant.fullName = vm.leadCall.prospectivePatient.attendant.lastName + " " + vm.leadCall.prospectivePatient.attendant.firstName;
                }else {
                    // Set empty space to Attendant.fullName because Attendant can have null first and last name.
                    vm.leadCall.prospectivePatient.attendant.fullName = " ";
                }
            }
            if( vm.leadCall.oldPatient && model.indexOf("oldPatient") !== -1 ) {
                if (vm.leadCall.oldPatient.lastName !== null && vm.leadCall.oldPatient.firstName !== null){
                    vm.leadCall.oldPatient.fullName = vm.leadCall.oldPatient.lastName + " " + vm.leadCall.oldPatient.firstName;
                }else {
                    vm.leadCall.oldPatient.fullName = "";
                }
            }
            if( vm.leadCall.oldPatientDoctor && model.indexOf("oldPatientDoctor") !== -1 ) {
                if (vm.leadCall.oldPatientDoctor.lastName !== null && vm.leadCall.oldPatientDoctor.firstName !== null){
                    vm.leadCall.oldPatientDoctor.fullName = vm.leadCall.oldPatientDoctor.lastName + " " + vm.leadCall.oldPatientDoctor.firstName;
                }else {
                    vm.leadCall.oldPatientDoctor.fullName = "";
                }
            }
        };

        vm.toggle = function(model, action) {
            if (!model) {
                model = vm.popupGridOptions.grid;
                vm.window.close();
            }
            //var date;
            switch (model) {
                case "prospectivePatient": // ProspectivePatient
                    vm.editProspectivePatientMode = action === "add" || action === "edit";
                    if ( action === "add" || action === "erase") {
                        if (vm.prospectivePatient.id === null || vm.prospectivePatient.id === undefined || vm.prospectivePatient.id === ''){
                            // Only reset prospectivePatient if he hasn't an id ECRM-281
                            vm.prospectivePatient = angular.copy(vm._prospectivePatient);
                        }
                    }
                    if ( action === "edit" ) { vm.prospectivePatient = vm.leadCall.prospectivePatient; }
                    if ( action === "erase" ) {
                        // for not loosing attendant obj when erase prospectivePatient
                        var pp = angular.copy(vm._prospectivePatient);
                        pp.attendant = vm.leadCall.prospectivePatient.attendant;
                        vm.leadCall.prospectivePatient = angular.copy(pp);
                    }
                    if ( action === "go") {
                        if (vm.prospectivePatient && vm.prospectivePatient.id > 0){
                            $state.go('prospectivePatientInfo', {id: vm.prospectivePatient.id});
                        }else if (vm.leadCall.prospectivePatient && vm.leadCall.prospectivePatient.id > 0){
                            $state.go('prospectivePatientInfo', {id: vm.leadCall.prospectivePatient.id});
                        }
                    }
                    if ( action === "clear"){
                        vm.expandCollapseButtonLabel = "Περισσότερα Πεδία";
                        vm.expandCollapseIcon = 'expand_more';
                        vm.isExpanded = false;
                    }
                    break;
                case "attendant": // Attendant
                    vm.editAttendantMode = action === "add" || action === "edit";
                    if ( action === "add" || action === "erase" ) vm.attendant = angular.copy(vm._attendant);
                    if ( action === "edit" ) vm.attendant = vm.leadCall.prospectivePatient.attendant;
                    if ( action === "erase" ) { vm.leadCall.prospectivePatient.attendant = angular.copy(vm._attendant); }
                    if ( action === "clear"){
                        vm.expandCollapseButtonLabel_attendant = "Περισσότερα Πεδία";
                        vm.expandCollapseIcon_attendant = 'expand_more';
                        vm.isExpanded_attendant = false;
                    }
                    break;
                case "oldPatient": // Patient
                    vm.editOldPatientMode = action === "add" || action === "edit";
                    if ( action === "add" ) vm.oldPatient = angular.copy(vm._oldPatient);
                    if ( action === "edit" ) {
                        vm.oldPatient = vm.leadCall.oldPatient;
                    }
                    if ( action === "erase" ) vm.leadCall.oldPatient = angular.copy(vm._oldPatient);
                    break;
                case "oldPatientDoctor": // Customer
                    vm.editOldPatientDoctorMode = action === "add" || action === "edit";
                    if ( action === "add" || action === "erase" ) vm.oldPatientDoctor = angular.copy(vm._oldPatientDoctor);
                    if ( action === "edit" ) vm.oldPatientDoctor = vm.leadCall.oldPatientDoctor;
                    if ( action === "erase" ) vm.leadCall.oldPatientDoctor = angular.copy(vm._oldPatientDoctor);
                    break
            }
        };

        vm.toggleKeypad = function(keypad) {
            switch (keypad) {
                case "yearOfBirth":
                    vm.focusYearOfBirth = true;
                    if(vm.prospectivePatient.birthYear) {
                        vm.focusYearOfBirth = !(vm.prospectivePatient.birthYear.toString().length === 4);
                    }
                    vm.focusAge = false;
                    break;
                case "age":
                    vm.focusAge = true;
                    if(vm.prospectivePatient.age) {
                        vm.focusAge = !(vm.prospectivePatient.age.toString().length >= 2);
                    }
                    vm.focusYearOfBirth = false;
                    break;
                case "oldPatientYearOfBirth":
                    vm.oldPatientFocusYearOfBirth = true;
                    if(vm.oldPatient.yearOfBirth) {
                        vm.oldPatientFocusYearOfBirth = !(vm.oldPatient.yearOfBirth.toString().length === 4);
                    }
                    vm.oldPatientFocusAge = false;
                    break;
                case "oldPatientAge":
                    vm.oldPatientFocusAge = true;
                    if(vm.oldPatient.age) {
                        vm.oldPatientFocusAge = !(vm.oldPatient.age.toString().length >= 2);
                    }
                    vm.oldPatientFocusYearOfBirth = false;
                    break;
            }
        };

        vm.hideKeypadOnBlur = function(e, element) {
            switch (element) {
                case "yearOfBirth" :
                    vm.focusYearOfBirth = false;
                    if (e.relatedTarget)
                        vm.focusYearOfBirth = e.relatedTarget.parentNode.parentNode.id === "yearOfBirthKeypad";
                    break;
                case "age" :
                    vm.focusAge = false;
                    if (e.relatedTarget)
                        vm.focusAge = e.relatedTarget.parentNode.parentNode.id === "ageKeypad";
                    break;
                case "yearOfBirthKeypad" :
                    vm.focusYearOfBirth = false;
                    if (e.relatedTarget)
                        vm.focusYearOfBirth = e.relatedTarget.parentNode.parentNode.id === "yearOfBirth";
                    break;
                case "ageKeypad" :
                    vm.focusAge = false;
                    if (e.relatedTarget)
                        vm.focusAge = e.relatedTarget.parentNode.parentNode.id === "age";
                    break;
                case "oldPatientYearOfBirth":
                    vm.oldPatientFocusYearOfBirth = false;
                    if (e.relatedTarget)
                        vm.oldPatientFocusYearOfBirth = e.relatedTarget.parentNode.parentNode.id === "oldPatientYearOfBirthKeypad";
                    break;
                case "oldPatientAge":
                    vm.oldPatientFocusAge = false;
                    if (e.relatedTarget)
                        vm.oldPatientFocusAge = e.relatedTarget.parentNode.parentNode.id === "oldPatientAgeKeypad";
                    break;
                case "oldPatientYearOfBirthKeypad":
                    vm.oldPatientFocusYearOfBirth = false;
                    if (e.relatedTarget)
                        vm.oldPatientFocusYearOfBirth = e.relatedTarget.parentNode.parentNode.id === "oldPatientYearOfBirth";
                    break;
                case "oldPatientAgeKeypad":
                    vm.oldPatientFocusAge = false;
                    if (e.relatedTarget)
                        vm.oldPatientFocusAge = e.relatedTarget.parentNode.parentNode.id === "oldPatientAge";
                    break;
            }
        };

        vm.toggleSwitch = function(model, value){
            switch (model) {
                case "isOldPatientRelative":
                    if (!value) {
                        vm.oldPatient = angular.copy(vm._oldPatient);
                    }
                    break;
                case "isFirstEstimationDate":
                    if (!value){
                        vm.leadOffer.firstEstimationDate = null;
                    }else {
                        vm.leadOffer.firstEstimationDate = new Date();
                    }
                    break;
                case "hasRequestedDiscount":
                    if (!value) {
                        vm.leadDiscount.discountReason = {};
                    }
                    break;
                case "isEntitledToDiscount":
                    if (!value) {
                        vm.leadDiscount.entitledToReason = null;
                    }
                    break;
                case "wantsTransportation":
                    if (!value) {
                        vm.leadOffer.transportation = null;
                        vm.leadOffer.comments = "";
                    }
                    break;
                case "hasCompetitiveBid":
                    if (value) {
                        vm.leadOffer.competitor = {};
                    }
                    break;
                case "hasFinalDiscount":
                    if (!value){
                        vm.leadDiscount.finalDiscount = null;
                        vm.leadDiscount.finalReason = null;
                    }
                    break;
                case "hadSurgery":
                    if (!value){
                        vm.leadHospitalization.surgeryType = "";
                    }
                    break;
                case "bookedAppointment":
                    if (!value){
                        vm.appointmentDate = null;
                        vm.appointmentTime = null;
                    }
                    break;
            }
        };

        vm.tabHeaderClick = function (tab, ev) {
            showSaveDialog(tab, ev);
            switch (tab){
                case "tabLeadCall":
                    vm.showSaveButton = true;
                    vm.saveButtonLabel = "Αποθήκευση Κλήσης";
                    vm.showChargesTabButtons = false;
                    vm.showDiseasesTabButtons = false;
                    break;
                case "tabHospitalization":
                    vm.showSaveButton = true;
                    vm.saveButtonLabel = "Αποθήκευση Νοσηλείας";
                    vm.showChargesTabButtons = false;
                    vm.showDiseasesTabButtons = false;
                    break;
                case "tabCharges":
                    vm.showSaveButton = false;
                    vm.saveButtonLabel = "Αποθήκευση Κλήσης";
                    vm.showChargesTabButtons = true;
                    vm.showDiseasesTabButtons = false;
                    vm.leadOffer.invitationText === null ?
                        vm.leadOffer.invitationText = "Αξιότιμε κ. " + getFullNameForPdf() :
                        vm.leadOffer.invitationText;
                    break;
                case "tabDiseases":
                    vm.showSaveButton = false;
                    vm.saveButtonLabel = "Αποθήκευση Κλήσης";
                    vm.showChargesTabButtons = false;
                    vm.showDiseasesTabButtons = true;
                    break;
                case "tabFollowup":
                    vm.showSaveButton = false;
                    vm.saveButtonLabel = "Αποθήκευση Κλήσης";
                    vm.showChargesTabButtons = false;
                    vm.showDiseasesTabButtons = false;
                    KendoGrid.refresh(vm.followUpGridOptions);
                    break;
            }
        };

        vm.onRoomCostChange = function () {
            // Comment-out for ECRM-287
            /*if (vm.leadOffer.costType && vm.leadOffer.costType.id > 0 &&
                vm.leadOffer.roomType && vm.leadOffer.roomType.id > 0){
                var costTypeFilter = {
                    field: "costType.id",
                    operator: "eq",
                    value: vm.leadOffer.costType.id
                };
                var roomTypeFilter = {
                    field: "roomType.id",
                    operator: "eq",
                    value: vm.leadOffer.roomType.id
                };

                $http.get('/api/room-costs/search',
                    {params:{
                            page: "1",
                            pageSize: "1",
                            filter : { filters: [costTypeFilter, roomTypeFilter] }}})
                    .success(function (response) {
                        vm.roomCost = response.content[0];
                        // Trigger cost calculation with discount
                        vm.onFinalDiscountChange();
                    })
                    .error(function (error) {
                        console.log(error);
                    });
            }else {
                vm.roomCost = null;
            }*/
        };

        vm.onCostTypeChange = function () {
            ////////////// New ECRM-306 - 8 /////////////////////////
            var selectedCostType = vm.leadOffer.costType;
            if (selectedCostType.name && vm.hospitalizationTypes &&
                (selectedCostType.name.toUpperCase().includes("ΤΑΜΕΙΟ"))){
                for (var i=0; i<vm.hospitalizationTypes.length; i++){
                    if (vm.hospitalizationTypes[i].name && vm.hospitalizationTypes[i].name.toUpperCase().includes("ΕΟΠΥΥ")){
                        vm.leadHospitalization.hospitalizationType = vm.hospitalizationTypes[i];
                        break;
                    }
                }
            }
            /////////////////////////////////////////////////////////

            ////////////// Comment-out for ECRM-287 /////////////////
            /*var selectedCostType = vm.leadOffer.costType;
            // Filter RoomTypes by selected costType
            var costTypeFilter = {
                field: "costType.id",
                operator: "eq",
                value: selectedCostType.id
            };
            $http.get('/api/room-costs/search',
                {params:{
                        page: "1",
                        pageSize: "200",
                        filter : { filters: [costTypeFilter] }}})
                .success(function (response) {
                    vm.roomTypes = getRoomTypesFromRoomCosts(response.content);
                    vm.onRoomCostChange();
                })
                .error(function (error) {
                    console.log(error);
                });*/
            //////////////////////////////////////////////////////////
        };

        vm.onRoomTypeChange = function () {
            // Comment-out for ECRM-287
            //vm.onRoomCostChange();
        };

        vm.onFinalDiscountChange = function () {
            // Comment-out for ECRM-287
            /*if (vm.roomCost !== null && vm.leadDiscount.finalDiscount !== null && vm.leadDiscount.finalDiscount.id > 0){
                vm.roomCostWithDiscount = vm.roomCost.cost - ((vm.leadDiscount.finalDiscount.value / 100) * vm.roomCost.cost);
            }else {
                vm.roomCostWithDiscount = null;
            }*/
        };

        vm.onDiseaseChange = function(disease){
            if (disease.id === 9 && disease.selected === true){
                // Set CostType to id=3 and disable it
                vm.leadOffer.costType = vm.costTypes.find(function (element) {
                    return element.id === 3;
                });
                vm.onCostTypeChange();
                vm.disableCostType = true;
            }else {
                vm.disableCostType = false;
            }
        };

        vm.onHospitalizationTypeChange = function () {
            var selectedHospitalizationType = vm.leadHospitalization.hospitalizationType;
            if (selectedHospitalizationType.name && vm.costTypes &&
                (selectedHospitalizationType.name.toUpperCase().includes("ΕΟΠΥΥ"))){
                for (var i=0; i<vm.costTypes.length; i++){
                    if (vm.costTypes[i].name && vm.costTypes[i].name.toUpperCase().includes("ΤΑΜΕΙΟ")){
                        vm.leadOffer.costType = vm.costTypes[i];
                        break;
                    }
                }
            }
        };

        /**
         * Triggered when an addDiseaseItem has changed.
         * It shows addDiseaseOther text area below addDiseases list.
         * @param item The AddDisease object that changed.
         */
        vm.onAddDiseaseChange = function (item) {
            if (item.name && (item.name.toUpperCase().includes("ΑΛΛΟ") ||
                    item.name.toUpperCase().includes("ΆΛΛΟ") ||
                    item.name.toUpperCase().includes("'ΑΛΛΟ"))){
                if (item.selected){
                    vm.showAddDiseaseOther = true;
                }else {
                    vm.showAddDiseaseOther = false;
                    vm.leadHospitalization.addDiseaseOther = "";
                }
            }
        };

        vm.leadOfferRoomTypeChange = function (item) {
            if (item.selected === true){
                // give focus to input
                $timeout(function () {
                    angular.element("#roomTypePrice" + item.id).focus();
                }, 20);
            }else {
                item.price = '';
            }
        };

        vm.expandClick = function (which) {
            var p;
            switch (which){
                case "prospectivePatient":
                    p = $('#fieldsContainer');
                    if (vm.isExpanded === true){
                        // Collapse
                        p.css('height','170px');
                        vm.expandCollapseButtonLabel = "Περισσότερα Πεδία";
                        vm.expandCollapseIcon = 'expand_more';
                    }else {
                        // Expand
                        p.css('height','auto');
                        vm.expandCollapseButtonLabel = "Λιγότερα Πεδία";
                        vm.expandCollapseIcon = 'expand_less';
                    }
                    vm.isExpanded = !vm.isExpanded;
                    break;
                case "attendant":
                    p = $('#fieldsContainerAttendant');
                    if (vm.isExpanded_attendant === true){
                        // Collapse
                        p.css('height','280');
                        vm.expandCollapseButtonLabel_attendant = "Περισσότερα Πεδία";
                        vm.expandCollapseIcon_attendant = 'expand_more';
                    }else {
                        // Expand
                        p.css('height','auto');
                        vm.expandCollapseButtonLabel_attendant = "Λιγότερα Πεδία";
                        vm.expandCollapseIcon_attendant = 'expand_less';
                    }
                    vm.isExpanded_attendant = !vm.isExpanded_attendant;
                    break;
            }
        };

        vm.prospectivePatientAge = null;
        vm.prospectivePatientBirthYearOnChange = function(birthYear){
            if (birthYear != null && birthYear.length === 4 && new Date().getFullYear() >= birthYear){
                vm.prospectivePatientAge = (new Date().getFullYear() - birthYear).toString();
            }else {
                vm.prospectivePatientAge = null;
            }
        };
        vm.prospectivePatientAgeChange = function (age) {
            if (age === null || age.length === 0){
                vm.prospectivePatient.birthYear = null;
            }else if (age !== null && age < 130){
                vm.prospectivePatient.birthYear = new Date().getFullYear() - age;
            }else {
                vm.prospectivePatient.birthYear = null;
            }
        };
        vm.oldPatientAge = null;
        vm.oldPatientBirthYearOnChange = function(birthYear){
            if (birthYear != null && birthYear.length === 4 && new Date().getFullYear() >= birthYear){
                vm.oldPatientAge = (new Date().getFullYear() - birthYear).toString();
            }else {
                vm.oldPatientAge = null;
            }
        };

        /**
         * Create or Update a specific model.
         * ProspectivePatient and attendant cases not used! Check vm.updateProspectivePatientAndAttendant();
         * @param model
         */
        vm.update = function(model){
            switch (model) {
                case "prospectivePatient":
                    if (vm.prospectivePatientValidator.validate()){
                        // Assign Attendant if exists
                        if (vm.attendant && vm.attendant.id > 0){
                            vm.prospectivePatient.attendant.id = vm.attendant.id;
                        }else if (vm.leadCall.prospectivePatient.attendant && vm.leadCall.prospectivePatient.attendant.id > 0){
                            vm.prospectivePatient.attendant.id = vm.leadCall.prospectivePatient.attendant.id;
                        }
                        ProspectivePatient.update({}, vm.prospectivePatient,
                            function (result) {
                                KendoNotifications.showSuccessNotification(notification, "Επιτυχής καταχώρηση Πιθανού Ασθενή");
                                vm.toggle("prospectivePatient", "clear");
                                vm.leadCall.prospectivePatient = result;
                                vm.setData(["prospectivePatient"]);
                                vm.setData(["attendant"]);
                            },
                            function () {
                                KendoNotifications.showErrorNotification(notification, "Κάποιο σφάλμα προέκυψε κατά την καταχώρηση του Πιθανού Ασθενή!");
                            });
                    }else {
                        KendoNotifications.showErrorNotification(notification, "Ελέγξτε τα στοιχεία του Πιθανού Ασθενή!");
                    }
                    break;
                case "attendant":
                    if (vm.attendantValidator.validate()){
                        Attendant.update({}, vm.attendant,
                            function (result) {
                                KendoNotifications.showSuccessNotification(notification, "Επιτυχής καταχώρηση Συνοδού");
                                vm.toggle("attendant", "clear");
                                //vm.prospectivePatient.attendant = result;
                                vm.leadCall.prospectivePatient.attendant = result;
                                vm.setData(["attendant"]);
                                // Also update ProspectivePatient if exists
                                if (vm.leadCall.prospectivePatient && vm.leadCall.prospectivePatient.id > 0){
                                    vm.leadCall.prospectivePatient.attendant.id = result.id;
                                    ProspectivePatient.update({}, vm.leadCall.prospectivePatient,
                                        function (result) {
                                            KendoNotifications.showSuccessNotification(notification, "Επιτυχής καταχώρηση Πιθανού Ασθενή με Συνοδό");
                                            //vm.toggle("prospectivePatient", "clear");
                                            vm.leadCall.prospectivePatient = result;
                                            vm.setData(["prospectivePatient"]);
                                            vm.setData(["attendant"]);
                                        },
                                        function () {
                                            KendoNotifications.showErrorNotification(notification, "Κάποιο σφάλμα προέκυψε κατά την ενημέρωση του Πιθανού Ασθενή με Συνοδό!");
                                        });
                                }
                            },
                            function () {
                                KendoNotifications.showErrorNotification(notification, "Κάποιο σφάλμα προέκυψε κατά την καταχώρηση του Συνοδού!");
                            });
                    }else {
                        KendoNotifications.showErrorNotification(notification, "Ελέγξτε τα στοιχεία του Συνοδού!");
                    }
                    break;
                case "oldPatient":
                    if (vm.oldPatientValidator.validate()){
                        Patient.update({}, vm.oldPatient,
                            function (result) {
                                KendoNotifications.showSuccessNotification(notification, "Επιτυχής καταχώρηση Παλιού Ασθενή");
                                vm.toggle("oldPatient", "clear");
                                vm.leadCall.oldPatient = result;
                                vm.setData(["oldPatient"]);
                            },
                            function () {
                                KendoNotifications.showErrorNotification(notification, "Κάποιο σφάλμα προέκυψε κατά την καταχώρηση του Πιθανού Ασθενή!");
                            });
                    }else {
                        KendoNotifications.showErrorNotification(notification, "Ελέγξτε τα στοιχεία του Παλιού Ασθενή!");
                    }
                    break;
                case "oldPatientDoctor":
                    if (vm.oldPatientDoctorValidator.validate()){
                        Customer.update({}, vm.oldPatientDoctor,
                            function (result) {
                                KendoNotifications.showSuccessNotification(notification, "Επιτυχής καταχώρηση Επαγγελματία Υγείας");
                                vm.toggle("oldPatientDoctor", "clear");
                                vm.leadCall.oldPatientDoctor = result;
                                vm.setData(["oldPatientDoctor"]);
                            },
                            function () {
                                KendoNotifications.showErrorNotification(notification, "Κάποιο σφάλμα προέκυψε κατά την καταχώρηση του Επαγγελματία Υγείας!");
                            });
                    }else {
                        KendoNotifications.showErrorNotification(notification, "Ελέγξτε τα στοιχεία του Επαγγελματία Υγείας!");
                    }
                    break;
                case "convertToPatient":
                    if (vm.leadCall.id === null || vm.leadCall.id === undefined ||
                        vm.leadCall.id === '' || !vm.leadCall.id > 0){
                        Alerts.showConfirmDialog(null, "Sigma SalesForce",
                            "Θα πρέπει να αποθηκέυσετε πρώτα τη Κλήση. Αποθήκευση τώρα?",
                            true, "ΝΑΙ", "OXI", function () {
                            vm.save(false);
                        }, function () {});
                    }else {
                        if (vm.prospectivePatient && vm.prospectivePatient.id > 0){
                            // First check if there is any Hospitalization with this selected leadCall.id
                            HospitalizationService.search({
                                page: 1, pageSize: 5,
                                filter: {
                                    filters: [
                                        {
                                            field: "leadCall.id",
                                            operator: "eq",
                                            value: vm.leadCall.id
                                        }
                                    ]
                                }
                            }).$promise.then(function(result){
                                if (result.length > 0){
                                    Alerts.showAlert("Sigma SalesForce",
                                        "Η συγκεκριμένη Κλήση #" + vm.leadCall.id + " έχει γίνει ήδη Εισαγωγή με ημερομηνία εισαγωγής " + result[0].dateIssue,
                                        "Ok");
                                }else {
                                    // There aren't any Hospitalizations with this leadCall.id, continue
                                    var cancel = false;
                                    var message = "";

                                    if (vm.prospectivePatient.firstName === null ||
                                        vm.prospectivePatient.firstName === '' ||
                                        vm.prospectivePatient.firstName === undefined){
                                        cancel = true;
                                        message = "Απαραίτητο πεδίο Όνομα";
                                    }

                                    if (vm.prospectivePatient.lastName === null ||
                                        vm.prospectivePatient.lastName === '' ||
                                        vm.prospectivePatient.lastName === undefined){
                                        cancel = true;
                                        message = "Απαραίτητο πεδίο Επώνυμο";
                                    }

                                    if (cancel){
                                        Alerts.showAlert("Sigma SalesForce", message, "Ok");
                                    }else {
                                        var patientForSave = {
                                            firstName: vm.prospectivePatient.firstName,
                                            lastName: vm.prospectivePatient.lastName,
                                            email: vm.prospectivePatient.email,
                                            yearOfBirth: vm.prospectivePatient.birthYear,
                                            phone: vm.prospectivePatient.phone,
                                            notes: vm.prospectivePatient.notes,
                                            prospectivePatient: {
                                                id: vm.prospectivePatient.id
                                            },
                                            amka: vm.prospectivePatient.amka
                                        };

                                        Patient.save(patientForSave, function (result) {
                                            KendoNotifications.showSuccessNotification(notification, "Επιτυχής μετατροπή Πιθανού Ασθενή σε Ασθενή");
                                            vm.toggle("prospectivePatient", "clear");
                                            // Go to New Hospitalization with this Patient
                                            $timeout(function () {
                                                $state.go('hospitalizationView', {patientId: result.id,
                                                    leadCallId: vm.leadCall.id !== null && vm.leadCall.id > 0 ? vm.leadCall.id : null});
                                            }, 600);
                                        }, function (error) {
                                            console.log(error);
                                            if (error.data.message &&
                                                error.data.message.includes("Cannot save Patient with ProspectivePatient id that exists")){
                                                KendoNotifications.showErrorNotification(notification, "Ο Ασθενής υπάρχει ήδη! Μεταφορά στην Εισαγωγή...");
                                                $timeout(function () {
                                                    // error.data.params[0]: contains PatientId
                                                    $state.go('hospitalizationView', {patientId: error.data.params[0] !== null && error.data.params[0] !== undefined ? error.data.params[0] : null,
                                                        leadCallId: vm.leadCall.id != null && vm.leadCall.id > 0 ? vm.leadCall.id : null});
                                                }, 600);
                                            }else {
                                                KendoNotifications.showErrorNotification(notification, "Κάποιο σφάλμα προέκυψε κατά την μετατροπή Πιθανού Ασθενή σε Ασθενή!");
                                            }
                                        });
                                    }
                                }
                            });
                        }else {
                            Alerts.showAlert("Sigma SalesForce", "Θα πρέπει να έχει καταχωρηθεί πρώτα ο Πιθανός Ασθενής", "Ok");
                        }
                    }
                    break;
            }
        };

        /**
         * Updates or creates Attendant and ProspectivePatient
         */
        vm.updateProspectivePatientAndAttendant = function () {
            if (LeadCallHelper.hasAttendant(vm.attendant)){
                if (LeadCallHelper.hasProspectivePatient(vm.prospectivePatient)){
                    // Save Attendant then save ProspectivePatient
                    //console.log("Save Attendant then save ProspectivePatient");
                    if (vm.validator.validate()){
                        if (vm.validator.validate()){
                            Attendant.update({}, vm.attendant,
                                function (result) {
                                    KendoNotifications.showSuccessNotification(notification, "Επιτυχής καταχώρηση Συνοδού");
                                    if (vm.leadCall.prospectivePatient) {
                                        vm.leadCall.prospectivePatient.attendant = result;
                                    }
                                    vm.prospectivePatient.attendant = result;
                                    vm.attendant = result;
                                    vm.toggle("attendant", "clear");
                                    vm.setData(["attendant"]);
                                    ProspectivePatient.update({}, vm.prospectivePatient,
                                        function (result) {
                                            KendoNotifications.showSuccessNotification(notification, "Επιτυχής καταχώρηση Πιθανού Ασθενή με Συνοδό");
                                            vm.leadCall.prospectivePatient = result;
                                            vm.prospectivePatient = result;
                                            vm.toggle("prospectivePatient", "clear");
                                            vm.setData(["prospectivePatient", "attendant"]);
                                        },
                                        function () {
                                            KendoNotifications.showErrorNotification(notification, "Κάποιο σφάλμα προέκυψε κατά την καταχώρηση του Πιθανού Ασθενή με Συνοδό!");
                                        });
                                },
                                function () {
                                    KendoNotifications.showErrorNotification(notification, "Κάποιο σφάλμα προέκυψε κατά την καταχώρηση του Συνοδού!");
                                });
                        }else {
                            KendoNotifications.showErrorNotification(notification, "Ελέγξτε τα στοιχεία του Πιθανού Ασθενή!");
                            document.getElementById("prospectivePatient").scrollIntoView();
                        }
                    }else {
                        KendoNotifications.showErrorNotification(notification, "Ελέγξτε τα στοιχεία του Συνοδού!");
                        document.getElementById("attendant").scrollIntoView();
                    }
                }else {
                    // Save Attendant then save an empty ProspectivePatient
                    //console.log("Save Attendant then save an empty ProspectivePatient");
                    if (vm.validator.validate()){
                        Attendant.update({}, vm.attendant,
                            function (result) {
                                KendoNotifications.showSuccessNotification(notification, "Επιτυχής καταχώρηση Συνοδού");
                                if (vm.leadCall.prospectivePatient.attendant){
                                    vm.leadCall.prospectivePatient.attendant = result;
                                }
                                vm.prospectivePatient.attendant = result;
                                vm.attendant = result;
                                vm.toggle("attendant", "clear");
                                vm.setData(["attendant"]);
                                ProspectivePatient.update({}, vm.prospectivePatient,
                                    function (result) {
                                        KendoNotifications.showSuccessNotification(notification, "Επιτυχής καταχώρηση Πιθανού Ασθενή με Συνοδό");
                                        vm.leadCall.prospectivePatient = result;
                                        vm.prospectivePatient = result;
                                        vm.toggle("prospectivePatient", "clear");
                                        vm.setData(["prospectivePatient", "attendant"]);
                                    },
                                    function () {
                                        KendoNotifications.showErrorNotification(notification, "Κάποιο σφάλμα προέκυψε κατά την καταχώρηση του Πιθανού Ασθενή με Συνοδό!");
                                    });
                            },
                            function () {
                                KendoNotifications.showErrorNotification(notification, "Κάποιο σφάλμα προέκυψε κατά την καταχώρηση του Συνοδού!");
                            });
                    }else {
                        KendoNotifications.showErrorNotification(notification, "Ελέγξτε τα στοιχεία του Συνοδού!");
                        document.getElementById("attendant").scrollIntoView();
                    }
                }
            }else {
                if (LeadCallHelper.hasProspectivePatient(vm.prospectivePatient)){
                    //console.log("Save ProspectivePatient ONLY!!");
                    // Save ProspectivePatient ONLY!!
                    if (vm.validator.validate()){
                        ProspectivePatient.update({}, vm.prospectivePatient,
                            function (result) {
                                KendoNotifications.showSuccessNotification(notification, "Επιτυχής καταχώρηση Πιθανού Ασθενή με Συνοδό");
                                vm.leadCall.prospectivePatient = result;
                                vm.prospectivePatient = result;
                                vm.toggle("prospectivePatient", "clear");
                                vm.setData(["prospectivePatient", "attendant"]);
                            },
                            function () {
                                KendoNotifications.showErrorNotification(notification, "Κάποιο σφάλμα προέκυψε κατά την καταχώρηση του Πιθανού Ασθενή με Συνοδό!");
                            });
                    }else {
                        KendoNotifications.showErrorNotification(notification, "Ελέγξτε τα στοιχεία του Πιθανού Ασθενή!");
                        document.getElementById("prospectivePatient").scrollIntoView();
                    }
                }else {
                    // Show message
                    Alerts.showAlert("Sigma SalesForce",
                        "Θα πρέπει να συμπληρώσετε κάποια βασικά στοιχεία για τουλάχιστον ένα πρόσωπο (Πιθανός Ασθενής / Συνοδός).",
                        "Ok");
                }
            }
        };

        /**
         * Save LeadCall with LeadHospitalization
         * @param exit Boolean that indicates to exit screen after successful save
         */
        vm.save = function(exit){
            var cancel = false;
            var message = "";

            if (vm.startDate === null || vm.startDate === '' || vm.startDate === undefined ||
                vm.startTime === null || vm.startTime === '' || vm.startTime === undefined){
                cancel = true;
                message = "Θα πρέπει να επιλέξετε Ημερομηνία και Ώρα!";
            }

            if (vm.leadCall.callType === null || vm.leadCall.callType === '' || vm.leadCall.callType === undefined){
                cancel = true;
                message = "Θα πρέπει να επιλέξετε Τύπο Κλήσης!"
            }

            if (cancel){
                Alerts.showAlert("Sigma SalesForce", message, "Ok");
            }else {
                if (LeadCallHelper.hasLeadHospitalization(vm._leadHospitalization, vm.leadHospitalization)){
                    vm.leadHospitalization.addDiseases = getSelectedAddDiseases();
                    vm.leadHospitalization.addConditions = getSelectedAddConditions();
                    vm.leadCall.leadHospitalizations[0] = vm.leadHospitalization;
                }

                vm.leadCall.dateTime = CustomDateUtils.getDateTime(vm.startDate, vm.startTime);
                if (vm.appointmentDate !== null && vm.appointmentDate !== '' && vm.appointmentDate !== undefined &&
                    vm.appointmentTime !== null && vm.appointmentTime !== '' && vm.appointmentTime !== undefined){
                    vm.leadCall.appointmentDate = CustomDateUtils.getDateTime(vm.appointmentDate, vm.appointmentTime);
                }else {
                    vm.leadCall.appointmentDate = null;
                }

                vm.leadCall = LeadCallHelper.prepareLeadCall(vm.leadCall);
                $http.post('/api/lead-calls/full', vm.leadCall)
                    .success(function (response) {
                        console.log("Success Save LeadCall!");
                        KendoNotifications.showSuccessNotification(notification, "Επιτυχής καταχώρηση Κλήσης");
                        vm.leadCall = response;
                        vm.leadOffer.leadCall = vm.leadCall;
                        vm.leadCall.callType = vm.callTypes.find(function (callType) {return vm.leadCall.callType.id === callType.id;});
                        vm.setData(["prospectivePatient", "attendant", "oldPatient", "oldPatientDoctor"]);
                        //vm.copied_leadCall = angular.copy(vm.leadCall);
                        $scope.leadCallForm.$setPristine();
                        //vm.copied_leadHospitalization = angular.copy(vm.leadCall.leadHospitalizations[0]);
                        $scope.leadHospitalizationForm.$setPristine();
                        vm.title = 'Επεξεργασία Κλήσης';
                        if (exit){
                            //$state.go('leadCalls');
                            history.back();
                        }
                    })
                    .error(function (error) {
                        console.log("Error Save LeadCall!");
                        console.log(error);
                        KendoNotifications.showErrorNotification(notification, "Κάποιο σφάλμα προέκυψε κατά την καταχώρηση Κλήσης!");
                    });
            }
        };

        /**
         * Save LeadOffer with LeadDiscount
         */
        vm.saveLeadOffer = function () {
            if (LeadCallHelper.hasLeadOffer(vm._leadOffer, vm.leadOffer)){
                var cancel = false;
                var message = "";

                if (vm.leadOffer.leadCall === null || vm.leadOffer.leadCall === undefined ||
                    vm.leadOffer.leadCall.id === null || vm.leadOffer.leadCall.id === undefined){
                    cancel = true;
                    message = "Θα πρέπει ανατεθεί Κλήση στη Προσφορά!"
                }

                if (cancel){
                    Alerts.showAlert("Sigma SalesForce", message, "Ok");
                }else {
                    if (LeadCallHelper.hasLeadDiscount(vm._leadDiscount, vm.leadDiscount)){
                        vm.leadOffer.leadDiscount = vm.leadDiscount;
                    }

                    vm.leadOfferDTO = {
                        leadOffer: vm.leadOffer,
                        leadOfferRoomTypes: getSelectedLeadOfferRoomTypes()
                    };
                    $http.post('/api/lead-offers/full', vm.leadOfferDTO)
                        .success(function (response) {
                            console.log("Success Save LeadOffer!");
                            KendoNotifications.showSuccessNotification(notification, "Επιτυχής καταχώρηση Χρέωσης");
                            vm.leadOffer = response.leadOffer;
                            vm.hasFinalDiscount = (vm.leadOffer.leadDiscount && vm.leadOffer.leadDiscount.finalDiscount && vm.leadOffer.leadDiscount.finalDiscount.id > 0) ||
                                (vm.leadOffer.leadDiscount && vm.leadOffer.leadDiscount.finalReason && vm.leadOffer.leadDiscount.finalReason.id > 0);
                            $scope.chargesForm.$setPristine();
                        })
                        .error(function (error) {
                            console.log("Error Save LeadOffer!");
                            console.log(error);
                            KendoNotifications.showErrorNotification(notification, "Κάποιο σφάλμα προέκυψε κατά την καταχώρηση Προσφοράς!");
                        });
                }
            }else {
                $scope.chargesForm.$setPristine();
                Alerts.showAlert("Sigma SalesForce", "Δεν έχετε επιλέξει στοιχεία Προσφοράς!", "Ok");
            }
        };

        /**
         * Save LeadHospitalization Diseases
         */
        vm.saveDiseases = function () {
            var newDiseases = getSelectedDiseases();
            if (LeadCallHelper.hasModifyDiseases(vm.leadHospitalizationDiseases, newDiseases)){
                var cancel = false;
                var message = "";

                if (vm.leadCall.leadHospitalizations[0] === null || vm.leadCall.leadHospitalizations[0] === undefined ||
                    vm.leadCall.leadHospitalizations[0].id === null || vm.leadCall.leadHospitalizations[0].id === undefined){
                    cancel = true;
                    message = "Θα πρέπει υπάρχει Νοσηλεία για να αποθηκεύσετε Ασθένειες!"
                }

                if (cancel){
                    Alerts.showAlert("Sigma SalesForce", message, "Ok");
                }else {
                    var leadHospitalizationDiseases = [];
                    for (var i=0; i < newDiseases.length; i++){
                        var leadHospitalizationDisease = {};
                        leadHospitalizationDisease.comments = null;
                        leadHospitalizationDisease.disease = newDiseases[i];
                        leadHospitalizationDisease.leadHospitalization = vm.leadCall.leadHospitalizations[0];
                        leadHospitalizationDiseases.push(leadHospitalizationDisease);
                    }

                    $http.post('/api/lead-hospitalization/' + vm.leadCall.leadHospitalizations[0].id + '/diseases/all', leadHospitalizationDiseases)
                        .success(function (response) {
                            console.log("Success Save LeadHospitalizationDiseases!");
                            KendoNotifications.showSuccessNotification(notification, "Επιτυχής καταχώρηση Ασθενειών");
                            vm.leadHospitalizationDiseases = response;
                            $scope.diseasesForm.$setPristine();
                        })
                        .error(function (error) {
                            console.log("Error Save LeadHospitalizationDiseases!");
                            console.log(error);
                            KendoNotifications.showErrorNotification(notification, "Κάποιο σφάλμα προέκυψε κατά την καταχώρηση Ασθενειών!");
                        });
                }
            }else {
                Alerts.showAlert("Sigma SalesForce", "Δεν υπάρχει κάποια αλλαγή στις Ασθένεις!", "Ok");
                $scope.diseasesForm.$setPristine();
            }
        };

        /**
         * Creates an Offer object, passes it to pdfBuilder
         * and opens the pdf in new tab
         */
        vm.printOffer = function (ev) {
            var object = {
                pdfPronouncement: vm.leadOffer.invitationText,
                date: new Date(),
                fullName: getFullNameForPdf()
            };
            if (vm.leadOffer.attendantMeal){
                object.attendantMealPrice = 15;
            }
            if (vm.leadOffer.transportation && vm.leadOffer.transportation.id > 0){
                object.transportation = vm.leadOffer.transportation;
            }
            if (vm.roomCost != null && vm.roomCost.id > 0){
                object.roomCost = vm.roomCost;
            }
            if (vm.leadDiscount.finalDiscount != null && vm.leadDiscount.finalDiscount.id > 0){
                object.finalDiscount = vm.leadDiscount.finalDiscount;
            }

            if (isDiseaseSelected(9)){
                // relief_treatment.pdf
                PDFPdfBuilder.makeReliefTherapyOfferWithImages(object)
            }else {
                if (vm.leadOffer.costType !== null &&
                    vm.leadOffer.costType !== undefined &&
                    vm.leadOffer.costType.id > 0 &&
                    vm.leadOffer.costType.name !== null &&
                    vm.leadOffer.costType.name !== undefined){
                    if ((vm.leadOffer.costType.name.toUpperCase().includes("ΤΑΜΕΙΟ")) ||
                        (vm.leadOffer.costType.name.toUpperCase().includes("ΤΑΜΕΊΟ"))){
                        // fund_hospitalization.pdf
                        PDFPdfBuilder.makeFundOfferWithImages(object);
                    }else if ((vm.leadOffer.costType.name.toUpperCase().includes("ΙΔΙΩΤΙΚΑ")) ||
                        (vm.leadOffer.costType.name.toUpperCase().includes("ΙΔΙΩΤΙΚΆ"))){
                        // private_hospitalization.pdf
                        PDFPdfBuilder.makePrivateOfferWithImages(object);
                    }else {
                        Alerts.showAlert("Sigma SalesForce", "Δεν έχει επιλεχθεί το σωστό Κόστος για εκτύπωση!", "Ok");
                    }
                }else {
                    Alerts.showAlert("Sigma SalesForce", "Δεν έχει επιλεχθεί το σωστό Κόστος για εκτύπωση!", "Ok");
                }
            }
        };

        vm.shareWithEmail = function () {
            var receivers = '';
            var subject = 'Απαραίτητες Πληροφορίες και Παροχές';
            var body = vm.leadOffer.invitationText;
            const LINEBREAK = '%0D%0A';

            if (vm.leadCall.prospectivePatient &&
                vm.leadCall.prospectivePatient.attendant &&
                vm.leadCall.prospectivePatient.attendant.email){
                receivers += vm.leadCall.prospectivePatient.attendant.email + ";";
                //subject = 'Προσφορά για ' + vm.leadCall.prospectivePatient.attendant.lastName + " " + vm.leadCall.prospectivePatient.attendant.firstName;
                //body += 'Αξιότιμη / Αξιότιμε κ. ' + vm.leadCall.prospectivePatient.attendant.lastName + " " + vm.leadCall.prospectivePatient.attendant.firstName + LINEBREAK;
                //body += vm.leadOffer.invitationText;
            }else if (vm.prospectivePatient &&
                vm.prospectivePatient.attendant &&
                vm.prospectivePatient.attendant.email){
                receivers += vm.prospectivePatient.attendant.email + ";";
                //subject = 'Προσφορά για ' + vm.prospectivePatient.attendant.lastName + " " + vm.prospectivePatient.attendant.firstName;
                //body += 'Αξιότιμη / Αξιότιμε κ. ' + vm.prospectivePatient.attendant.lastName + " " + vm.prospectivePatient.attendant.firstName + LINEBREAK;
                //body += vm.leadOffer.invitationText;
            }

            if (receivers === ''){
                // if there isn't Attendant's email, set ProspectivePatient's
                if (vm.leadCall.prospectivePatient &&
                    vm.leadCall.prospectivePatient.email){
                    receivers += vm.leadCall.prospectivePatient.email + ";";
                    //subject = 'Προσφορά για ' + vm.leadCall.prospectivePatient.lastName + " " + vm.leadCall.prospectivePatient.firstName;
                    //body += 'Αξιότιμη / Αξιότιμε κ. ' + vm.leadCall.prospectivePatient.lastName + " " + vm.leadCall.prospectivePatient.firstName + LINEBREAK;
                    //body += vm.leadOffer.invitationText;
                }else if (vm.prospectivePatient && vm.prospectivePatient.email){
                    receivers += vm.prospectivePatient.email + ";";
                    //subject = 'Προσφορά για ' + vm.prospectivePatient.lastName + " " + vm.prospectivePatient.firstName;
                    //body += 'Αξιότιμη / Αξιότιμε κ. ' + vm.prospectivePatient.attendant.lastName + " " + vm.prospectivePatient.attendant.firstName + LINEBREAK;
                    //body += vm.leadOffer.invitationText;
                }
            }

            body += LINEBREAK + 'Σας αποστέλλουμε όλες τις απαραίτητες πληροφορίες που αφορούν την εισαγωγή στο Κέντρο μας.' + LINEBREAK;
            body += 'Παραμένουμε στη διάθεση σας.' + LINEBREAK;
            body += 'Με εκτίμηση,' + LINEBREAK;
            //body += LINEBREAK + 'Υποδοχή  - Γραφείο Κίνησης' + LINEBREAK;
            //body += 'Ασθενών Κλειστής Νοσηλείας';

            if (receivers === ''){
                // If neither Attendant's / ProspectivePatient's exist..
                //subject = 'Προσφορά';
                //body = 'Αξιότιμη / Αξιότιμε κ.' + LINEBREAK;
                body = vm.leadOffer.invitationText + LINEBREAK;
                body += LINEBREAK + 'Σας αποστέλλουμε όλες τις απαραίτητες πληροφορίες που αφορούν την εισαγωγή στο Κέντρο μας.' + LINEBREAK;
                body += 'Παραμένουμε στη διάθεση σας.' + LINEBREAK;
                body += 'Με εκτίμηση,' + LINEBREAK;
                //body += LINEBREAK + 'Υποδοχή  - Γραφείο Κίνησης' + LINEBREAK;
                //body += 'Ασθενών Κλειστής Νοσηλείας';
                window.location.href = "mailto:" + '?subject=' + subject + '&body=' + body;
                return;
            }

            window.location.href = 'mailto:' + receivers + '?subject=' + subject + '&body=' + body;
        };

        // Adds boolean 'selected' attribute to each element of vm.addDiseases array
        function setupAddDiseases(){
            if (vm.addDiseases !== null && vm.addDiseases.length > 0){
                for (var i=0; i < vm.addDiseases.length; i++){
                    vm.addDiseases[i].selected = false;
                }
            }
        }

        // Adds boolean 'selected' attribute to each element of vm.addConditions array
        function setupAddConditions() {
            if (vm.addConditions !== null && vm.addConditions.length > 0){
                for (var i=0; i < vm.addConditions.length; i++){
                    vm.addConditions[i].selected = false;
                }
            }
        }

        // Adds boolean 'selected' and 'price' attribute to each element of vm.roomTypes array
        function setupRoomTypes(){
            if (vm.roomTypes !== null && vm.roomTypes.length > 0){
                for (var i=0; i < vm.roomTypes.length; i++){
                    vm.roomTypes[i].selected = false;
                    vm.roomTypes[i].price = '';
                }
            }
        }

        /**
         * Adds a diseases array inside every diseaseType item (need it for ui) if disease has the specific diseaseType.
         * Adds boolean selected attribute in every disease element of vm.diseaseType.diseases
         */
        function setupDiseasesWithTypes(){
            if (vm.diseaseTypes != null && vm.diseaseTypes.length > 0){
                for (var i=0; i < vm.diseaseTypes.length; i++){
                    vm.diseaseTypes[i].diseases = [];
                    //vm.diseaseTypes[i].selected = false;
                    if (vm.diseases != null && vm.diseases.length > 0){
                        for (var j=0; j < vm.diseases.length; j++){
                            vm.diseases[j].selected = false;
                            if (vm.diseases[j].diseaseType && vm.diseaseTypes[i].id === vm.diseases[j].diseaseType.id){
                                vm.diseaseTypes[i].diseases.push(vm.diseases[j]);
                            }
                        }
                    }
                }
            }
        }

        // Binds leadHospitalization's selected addDiseases into ui
        function setAddDiseases(){
            if (vm.leadHospitalization.addDiseases != null &&
                vm.leadHospitalization.addDiseases.length > 0){
                for (var i=0; i < vm.leadHospitalization.addDiseases.length; i++){
                    for (var j=0; j < vm.addDiseases.length; j++){
                        if (vm.leadHospitalization.addDiseases[i].id === vm.addDiseases[j].id){
                            vm.addDiseases[j].selected = true;
                            // This to show showAddDiseaseOther field on edit
                            if (vm.addDiseases[j].name && (vm.addDiseases[j].name.toUpperCase().includes("ΑΛΛΟ") ||
                                    vm.addDiseases[j].name.toUpperCase().includes("ΆΛΛΟ") ||
                                    vm.addDiseases[j].name.toUpperCase().includes("ΑΛΛΟ"))){
                                vm.showAddDiseaseOther = vm.addDiseases[j].selected;
                            }
                        }
                    }
                }
            }
        }

        // Binds leadHospitalization's selected addConditions into ui
        function setAddConditions() {
            if (vm.leadHospitalization.addConditions != null &&
                vm.leadHospitalization.addConditions.length > 0){
                for (var i=0; i < vm.leadHospitalization.addConditions.length; i++){
                    for (var j=0; j < vm.addConditions.length; j++){
                        if (vm.leadHospitalization.addConditions[i].id === vm.addConditions[j].id){
                            vm.addConditions[j].selected = true;
                        }
                    }
                }
            }
        }

        // Binds leadHospitalizationDiseases into ui
        function setDiseases() {
            if (vm.leadHospitalizationDiseases != null && vm.leadHospitalizationDiseases.length > 0){
                for (var i=0; i < vm.leadHospitalizationDiseases.length; i++){
                    for (var j=0; j < vm.diseases.length; j++){
                        if (vm.leadHospitalizationDiseases[i].disease.id === vm.diseases[j].id){
                            vm.diseases[j].selected = true;
                        }
                    }
                }
            }
        }

        // Binds leadOffer's selected roomTypes into ui
        function setRoomTypes(){
            if (vm.leadOfferRoomTypes !== null && vm.leadOfferRoomTypes.length > 0 &&
                vm.roomTypes !== null && vm.roomTypes.length > 0){
                for (var i=0; i < vm.leadOfferRoomTypes.length; i++){
                    for (var j=0; j < vm.roomTypes.length; j++){
                        if (vm.leadOfferRoomTypes[i].roomType.id === vm.roomTypes[j].id){
                            vm.roomTypes[j].selected = true;
                            vm.roomTypes[j].price = vm.leadOfferRoomTypes[i].discussedPrice;
                        }
                    }
                }
            }
        }

        // Get selected addDiseases
        function getSelectedAddDiseases(){
            var selectedAddDiseases = [];
            for (var i=0; i < vm.addDiseases.length; i++){
                if (vm.addDiseases[i].selected){
                    selectedAddDiseases.push(vm.addDiseases[i]);
                }
            }

            return selectedAddDiseases;
        }

        // Get selected addConditions
        function getSelectedAddConditions(){
            var selectedAddConditions = [];
            for (var i=0; i < vm.addConditions.length; i++){
                if (vm.addConditions[i].selected){
                    selectedAddConditions.push(vm.addConditions[i]);
                }
            }

            return selectedAddConditions;
        }

        // Get selected diseases
        function getSelectedDiseases(){
            var selectedDiseases = [];
            for (var i=0; i < vm.diseaseTypes.length; i++){
                for (var j=0; j < vm.diseaseTypes[i].diseases.length; j++){
                    if (vm.diseaseTypes[i].diseases[j].selected){
                        selectedDiseases.push(vm.diseaseTypes[i].diseases[j]);
                    }
                }
            }

            return selectedDiseases;
        }

        // Get selected LeadOfferRoomTypes
        function getSelectedLeadOfferRoomTypes(){
            var selectedLeadOfferRoomTypes = [];
            for (var i=0; i < vm.roomTypes.length; i++){
                if (vm.roomTypes[i].selected === true){
                    selectedLeadOfferRoomTypes.push({
                        leadOffer: vm.leadOffer,
                        roomType: vm.roomTypes[i],
                        discussedPrice: vm.roomTypes[i].price
                    });
                }
            }

            return selectedLeadOfferRoomTypes;
        }

        /**
         * Shows a dialog for saving LeadCall / LeadHospitalization / LeadOffer / LeadDiscount / addedDiseases.
         * Dialog will be displayed when switching from tab 'tabHospitalization' or tab 'tabLeadCall' to any other tab.
         * Also dialog will be displayed when switching from tab 'tabCharges' or 'tabDiseases' or 'tabFollowup' to any other tab.
         * @param currentTab The tab that user is about to go
         * @param ev Click event for mdDialog
         */
        function showSaveDialog(currentTab, ev) {
            if (currentTab !== previousTab){
                var msg = "Έχει πραγματοποιηθεί αλλαγή σε ";
                if (previousTab === "tabLeadCall" || previousTab === "tabHospitalization"){
                    if (currentTab !== "tabLeadCall" && currentTab !== "tabHospitalization"){
                        if ($scope.leadCallForm.$dirty === true || $scope.leadHospitalizationForm.$dirty === true){
                            if ($scope.leadCallForm.$dirty === true && $scope.leadHospitalizationForm.$dirty){
                                msg += "Κλήση και Νοσηλεία";
                            }else if ($scope.leadCallForm.$dirty === true) {
                                msg += "Κλήση";
                            }else if ($scope.leadHospitalizationForm.$dirty === true){
                                msg += "Νοσηλεία";
                            }
                            msg += ". Αποθήκευση?";
                            Alerts.showConfirmDialog(ev, "Sigma SalesForce", msg, true, "ΝΑΙ", "OXI", function () {
                                vm.save(false);
                            }, function () {});
                        }
                    }
                }else if (previousTab === "tabDiseases"){
                    if ($scope.diseasesForm.$dirty === true){
                        msg += "Ιατρικό Ιστορικό. Αποθήκευση?";
                        Alerts.showConfirmDialog(ev, "Sigma SalesForce", msg, true, "ΝΑΙ", "OXI", function () {
                            vm.saveDiseases();
                        }, function () {});
                    }
                }else if (previousTab === "tabCharges"){
                    if ($scope.chargesForm.$dirty === true){
                        msg += "Χρεώσεις. Αποθήκευση?";
                        Alerts.showConfirmDialog(ev, "Sigma SalesForce", msg, true, "ΝΑΙ", "OXI", function () {
                            vm.saveLeadOffer();
                        }, function () {});
                    }
                }

                previousTab = currentTab;
            }

            // This was with custom equals
            /*if (currentTab === "tabCharges" || currentTab === "tabDiseases" || currentTab === "tabFollowup"){
                if (previousTab === "tabHospitalization" || previousTab === "tabLeadCall"){
                    var hasTouchLeadCall = $scope.leadCallForm.$dirty; //!LeadCallHelper.equalsLeadCall(vm.copied_leadCall, vm.leadCall);
                    var hasTouchLeadHospitalization = $scope.leadHospitalizationForm.$dirty; //!LeadCallHelper.equalsLeadHospitalization(vm.copied_leadHospitalization, vm.leadHospitalization);
                    // console.log("LeadCall hasChanged: " + hasTouchLeadCall);
                    // console.log("LeadHospitalization hasChanged: " + hasTouchLeadHospitalization);
                    if (hasTouchLeadCall || hasTouchLeadHospitalization){
                        var msg = "Έχει πραγματοποιηθεί αλλαγή σε ";
                        if (hasTouchLeadCall && hasTouchLeadHospitalization){
                            msg += "Κλήση και Νοσηλεία";
                        }else if (hasTouchLeadCall) {
                            msg += "Κλήση";
                        }else if (hasTouchLeadHospitalization){
                            msg += "Νοσηλεία";
                        }
                        msg += ". Αποθήκευση?";
                        Alerts.showConfirmDialog(ev, "Sigma SalesForce", msg, true, "ΝΑΙ", "OXI", function () {
                            vm.save(false);
                        }, function () {});
                    }
                }
            }*/
        }

        /**
         * Checks if a specific disease is selected in diseasesTab, by Disease.id
         * @param diseaseId The disease id to check
         * @returns {boolean} True if it is selected, false if not
         */
        function isDiseaseSelected(diseaseId) {
            var is = false;
            var selectedDiseases = getSelectedDiseases();
            if (selectedDiseases !== null && selectedDiseases !== undefined && selectedDiseases.length > 0){
                for (var i=0; i < selectedDiseases.length; i++){
                    if (selectedDiseases[i].id === diseaseId){
                        is = true;
                        break;
                    }
                }
            }

            return is;
        }

        function getRoomTypesFromRoomCosts(roomCosts){
            var roomTypes = [];

            if (roomCosts !== null && roomCosts !== undefined && roomCosts.length > 0){
                for (var i=0; i<roomCosts.length; i++){
                    roomTypes.push(roomCosts[i].roomType);
                }
            }

            return roomTypes;
        }

        function openPDF(uri){
            var link = angular.element('<a href="' + uri + '" target="_blank"></a>');
            angular.element(document.body).append(link);
            link[0].click();
            link.remove();
        }

        function openPDFinNewTab(dataUri){
            //var string = document.output(dataUri);
            var iframe = "<iframe width='100%' height='100%' src='" + dataUri + "'></iframe>"
            var x = window.open();
            x.document.open();
            x.document.write(iframe);
            x.document.close();
        }

        function getFullNameForPdf(){
            var fullName = "";
            if (vm.leadCall.prospectivePatient !== null){
                if (vm.leadCall.prospectivePatient.attendant !== null &&
                    vm.leadCall.prospectivePatient.attendant.lastName &&
                    vm.leadCall.prospectivePatient.attendant.firstName){
                    // Get Attendant's
                    fullName = vm.leadCall.prospectivePatient.attendant.lastName + " " +
                        vm.leadCall.prospectivePatient.attendant.firstName;
                }else {
                    // Get ProspectivePatient's
                    fullName = vm.leadCall.prospectivePatient.lastName + " " + vm.leadCall.prospectivePatient.firstName;
                }
            }

            return fullName;
        }

        if (!$stateParams.id){
            //vm.toggle('prospectivePatient', 'add');
            vm.editProspectivePatientMode = true;
            vm.editAttendantMode = true;
        }

        /**
         * This fixes verticalDivider height since verticalDivider
         * is inside scrollable container (need to be inside timeout)
         */
        $timeout(function(){
            vm.chargesFormContentScrollHeight = angular.element("#chargesForm")[0].scrollHeight - 20;
            vm.leadHospitalizationContentScrollHeight = angular.element("#leadHospitalizationForm")[0].scrollHeight - 20;
        }, 10);
    }
})();
