(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('EventCustomerSectorDetailController', EventCustomerSectorDetailController);

    EventCustomerSectorDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'EventCustomerSector', 'CommunicationType', 'CustomerSector', 'AgreementValuation', 'Event', 'Sector'];

    function EventCustomerSectorDetailController($scope, $rootScope, $stateParams, entity, EventCustomerSector, CommunicationType, CustomerSector, AgreementValuation, Event, Sector) {
        var vm = this;
        vm.eventCustomerSector = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:eventCustomerSectorUpdate', function(event, result) {
            vm.eventCustomerSector = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
