(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CommunicationTypeDeleteController',CommunicationTypeDeleteController);

    CommunicationTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'CommunicationType'];

    function CommunicationTypeDeleteController($uibModalInstance, entity, CommunicationType) {
        var vm = this;
        vm.communicationType = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            CommunicationType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
