/**
 * Created by Teo on 26/03/2019.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('HospitalizationsAggregatedPerDoctorController', HospitalizationsAggregatedPerDoctorController);

    HospitalizationsAggregatedPerDoctorController.$inject = ['$http', 'KendoGrid', 'CustomUtils', 'Alerts', '$timeout'];

    function HospitalizationsAggregatedPerDoctorController ($http, KendoGrid, CustomUtils, Alerts, $timeout) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(55);
        vm.sumLabel = "";
        vm.showProgress = false;

        var allYears = CustomUtils.getYears(2016, true);
        vm.selectedYears = [allYears[0]];
        vm.yearsOptions = {
            dataSource: {
                data: allYears
            },
            dataTextField: "value",
            dataValueField: "value",
            maxSelectedItems: 2
        };

        var allMonths = CustomUtils.getMonths(false);
        vm.selectedMonths = [];
        vm.monthsOptions = {
            dataSource: {
                data: allMonths
            },
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "value",
            dataValueField: "id",
            placeholder: "Όλος ο χρόνος"
        };

        vm.exportData = function () {
            var grid = $("#mainGrid").data("kendoGrid");
            grid.saveAsExcel();
        };

        vm.getData = function () {
            if (vm.selectedYears.length < 1){
                Alerts.showAlert("Sigma SalesForce", "Επιλέξτε ένα ή δύο έτη", "ΟΚ");
            }else {
                $("#mainGrid").data().kendoGrid.destroy();
                $("#mainGrid").empty();
                vm.showProgress = true;
                $http.get("/api/hospitalizations/totals?type=doctors&years=" + yearObjectsToArray(vm.selectedYears) + "&months=" + vm.selectedMonths)
                    .success(function (response) {
                        vm.showProgress = false;
                        generateGrid(response);
                    })
                    .error(function (error) {
                        vm.showProgress = false;
                        console.log(error);
                        Alerts.showAlert("Sigma SalesForce", "Κάποιο σφάλμα προέκυψε", "ΟΚ");
                    });
            }
        };

        function generateGrid(response){
            $("#mainGrid").kendoGrid({
                dataSource: {
                    transport: {
                        read: function (options) {
                            options.success(response);
                        }
                    }
                },
                columns: generateColumns(response),
                height: vm.contentHeight,
                pageable: false,
                editable: false,
                resizable: true,
                scrollable: true,
                sortable: true,
                dataBound: function () {
                    $timeout(function() {
                        kendo.resize($("#mainGrid"));
                    });
                },
                excel: {
                    allPages: true,
                    fileName: "Σύνολα Εισαγωγών ανά Επαγγελματία Υγείας.xlsx"
                }
            });
            vm.sumLabel = "Σύνολο: " + response.length;
        }

        function generateColumns(response){
            var columns = [];
            columns.push({
                field: "label",
                title: "'Ονομα Κλινικής",
                width: 140
            });
            for (var i=0; i<response[0].results.length; i++){
                columns.push({
                    field: "results[" + i + "].value",
                    title: response[0].results[i].key,
                    width: 95
                })
            }
            return columns;
        }

        function yearObjectsToArray(selectedYears){
            var arr = [];
            angular.forEach(selectedYears, function (yearObject) {
                arr.push(yearObject.value);
            });
            return arr;
        }

        $(window).on("resize", function() {
            console.log("resize");
            kendo.resize($("#mainGrid"));
        });
    }
})();
