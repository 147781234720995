(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CountryPhoneCodeDeleteController',CountryPhoneCodeDeleteController);

    CountryPhoneCodeDeleteController.$inject = ['$uibModalInstance', 'entity', 'CountryPhoneCode'];

    function CountryPhoneCodeDeleteController($uibModalInstance, entity, CountryPhoneCode) {
        var vm = this;
        vm.countryPhoneCode = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            CountryPhoneCode.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
