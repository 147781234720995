/**
 * Created by Maria on 27/5/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SectorViewController', SectorViewController);

    SectorViewController.$inject = [ 'entity', '$state' ];

    function SectorViewController ( entity, $state ) {
        var vm = this;

        vm.$state = $state;
        vm.profilePicture = "hospital_icon.png";
        vm.sector = entity;

        vm.goBack = function () {
            window.history.back();
        };
    }
})();
