(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('DiseaseTypeDetailController', DiseaseTypeDetailController);

    DiseaseTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'DiseaseType'];

    function DiseaseTypeDetailController($scope, $rootScope, $stateParams, entity, DiseaseType) {
        var vm = this;
        vm.diseaseType = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:diseaseTypeUpdate', function(event, result) {
            vm.diseaseType = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
