/**
 * Created by Kristy on 20/4/2017.
 */
(function () {
    'use strict';

    angular
        .module('sigmasfApp')
        .service('PurchaseGrids', PurchaseGrids);

    PurchaseGrids.$inject = ['$filter', '$http', '$timeout', 'KendoGrid', 'PurchaseItem', 'KendoService', 'NotificationOptions', 'KendoNotifications', 'Alerts', '$window'];
    function PurchaseGrids ($filter, $http, $timeout, KendoGrid, PurchaseItem, KendoService, NotificationOptions, KendoNotifications, Alerts, $window) {
        var exportState;
        var isEditable;
        var dataSource = {};
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");
        dataSource.codesDataSource = KendoService.initDefaultDataSource('/api/masterdata/products');
        dataSource.productsDataSource = KendoService.initDefaultDataSource('/api/masterdata/products');

        var setEditVisibility = function( value ) {
            var purchaseItemsGrid = angular.element("#purchaseItemsGrid").data("kendoGrid");
            if (!value) {
                //hide delete command column and create button on toolbar
                purchaseItemsGrid.hideColumn(12);
                angular.element('#purchaseItemsGrid .k-grid-add').hide();
            } else {
                //show delete command column and create button on toolbar
                purchaseItemsGrid.showColumn(12);
                angular.element('#purchaseItemsGrid .k-grid-add').show();
            }
        };

        var purchaseItemsColumns = [
            {
                field: "button",
                title: " ",
                template: "<button class='md-raised md-primary md-button md-ink-ripple' ng-click='vm.addPurchaseItemLot(dataItem)' " +
                        "ng-disabled='dataItem.quantity <= dataItem.quantityExecuted'>" +
                        "<md-icon ng-bind='\"add\"' style='font-size: 20px;'></md-icon>Προσθήκη</button>",
                width: 150
            },
            {
                field: "product.code",
                title: "Κωδικός είδους",
                attributes: {class:"product-code"},
                template: "#= product.code ? product.code : ''#",
                editor: function(container) {
                    angular.element('<select id="productCode" data-bind="value:product"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: dataSource.codesDataSource,
                            dataBound: function (e) {
                                var dataSource = e.sender.dataSource;
                                angular.forEach( dataSource.data(), function(item) {
                                    if (!item.code) {
                                        dataSource.remove(item);
                                    }
                                });
                            },
                            filter: "contains",
                            dataTextField: "code",
                            dataValueField: "id"
                        });
                }
            },
            {
                field: "product.name",
                title: "Περιγραφή είδους",
                template: "#= product ? product.name : ''#",
                editor: function(container) {
                    angular.element('<select id="productName" data-bind="value:product"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: dataSource.productsDataSource,
                            filter: "contains",
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                }
            },
            // {
            //     field: "lotNumber",
            //     title: "Παρτίδες Φαρμάκων"
            // },
            {
                field: "quantity",
                title: "Ποσότητα",
                footerTemplate: "Σύνολο: #=kendo.format('{0:n0}', sum)#"
            },
            {
                field: "quantityExecuted",
                title: "Εκτελεσμένη Ποσότητα",
                editor: function(container,options){
                    angular.element("<input id='quantityExecuted' data-bind='value: quantityExecuted'>")
                        .appendTo(container)
                        .kendoNumericTextBox({ format:"{0:#}", min: 0 });
                },
                width: 200,
                footerTemplate: "Σύνολο: #=kendo.format('{0:n0}', sum)#"
            },
            {
                field: "productPrice",
                title: "Τιμή",
                attributes:{style:"text-align:right;"},
                template: "#= (productPrice == null) ? '' : kendo.format('{0:c}', productPrice)#"
            },
            {
                field: "totalPrice",
                title: "Μικτή Αξία",
                attributes:{style:"text-align:right;"},
                template: "#= (totalPrice == null) ? '' : kendo.format('{0:c}', totalPrice)#"
            },
            // {
            //     field: "discountPercent",
            //     title: "Έκπτωση(%)",
            //     template: "#= (discountPercent == null) ? '' : kendo.format('{0:p0}', discountPercent / 100)#"
            // },
            // {
            //     field: "discountPrice",
            //     title: "Έκπτωση",
            //     attributes:{style:"text-align:right;"},
            //     template: "#= (discountPrice == null) ? '' : kendo.format('{0:c}', discountPrice)#"
            // },
            // {
            //     field: "discountFinalPercent",
            //     title: "Συνολική Έκπτωση(%)",
            //     template: "#= (discountFinalPercent == null) ? '' : kendo.format('{0:p0}', discountFinalPercent / 100)#"
            // },
            {
                field: "discountSummary",
                title: "Συνολική έκπτωση",
                attributes:{style:"text-align:right;"},
                template: "#= (discountSummary == null) ? '' : kendo.format('{0:c}', discountSummary)#",
                width: "8%"
            },
            {
                field: "netPrice",
                title: "Καθαρή αξία",
                attributes:{style:"text-align:right;"},
                template: "#= (netPrice == null) ? '' : kendo.format('{0:c}', netPrice)#"
            },
            {
                field: "taxPrice",
                title: "Αξία Φ.Π.Α.",
                attributes:{style:"text-align:right;"},
                template: "#= (taxPrice == null) ? '' : kendo.format('{0:c}', taxPrice)#"
            },
            {
                field: "finalPrice",
                title: "Συνολική Αξία",
                attributes:{style:"text-align:right;"},
                template: "#= (finalPrice == null) ? '' : kendo.format('{0:c}', finalPrice)#"
            },
            {
                field: "edit",
                command: [
                    {name: "edit", text: ""}
                ],
                title: "&nbsp;",
                width: 110
            },
            {
                field: "destroy",
                command: [
                    {
                        name: "destroy", text: "",
                        template: "<button data-command='destroy' class='k-button k-button-icontext k-grid-delete' aria-label='Delete'>" +
                        "<i class='material-icons' style='vertical-align: inherit;color: rgb(255,87,34)'>delete_forever</i>" +
                        "</button>"
                    }
                ],
                title: "&nbsp;",
                width: 110
            }
        ];
        var purchasePresentItemsColumns = [
            {
                field: "button",
                title: " ",
                template: "<md-button class='md-raised md-primary md-button md-ink-ripple' ng-click='vm.addPurchasePresentItemLot(dataItem)' " +
                "ng-disabled='dataItem.quantity <= dataItem.quantityExecuted'>" +
                "<md-icon ng-bind='\"add\"' style='font-size: 20px;'></md-icon>Προσθήκη</md-button>",
                width: "8%"
            },
            {
                field: "product.name",
                title: "Προϊόν"
            },
            {
                field: "quantity",
                title: "Ποσότητα"
            },
            {
                field: "quantityExecuted",
                title: "Εκτελεσμένη Ποσότητα"
            },
            {
                field: "price",
                title: "Τιμή",
                template: "#= price ? price + ' €' : ''#"
            }
        ];

        var purchaseItemsDatasource = function (selectedPurchaseId) {
            return new kendo.data.DataSource({
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "purchase.id", "operator": "eq",  "value": selectedPurchaseId }
                            ]
                        );

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                            }
                        });

                        $http.get('/api/purchase-items/search',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    },
                    create: function (o){
                        var data = o.data;
                        data.purchase = { id: selectedPurchaseId };
                        data.quantityExecuted = 0;
                        PurchaseItem.update(data,
                            function() {
                                KendoNotifications.onSaveSuccess(notification);
                                KendoGrid.refresh(angular.element("#purchaseItemsGrid").data("kendoGrid"));
                            },
                            function() { KendoNotifications.onSaveError(notification); });
                    },
                    update: function (o){
                        var data = o.data;
                        if(data.measurementUnit && !data.measurementUnit.id)
                            data.measurementUnit = null;
                        PurchaseItem.update(data, function() {
                            KendoGrid.refresh(angular.element("#purchaseItemsGrid").data("kendoGrid"));
                        }, function(error){
                            if (error.status && error.status === 409) {
                                Alerts.showConfirmDialog(null, $filter('translate')('error.purchaseItem.purchaseItemConcurrencyFailureTitle'),
                                    $filter('translate')('error.purchaseItem.purchaseItemConcurrencyFailureMessage'),
                                    false, $filter('translate')('entity.action.refresh'), $filter('translate')('entity.action.cancel'), function () {
                                        // on confirm
                                        $window.location.reload();
                                    }, function () {
                                        // on cancel
                                    });
                            }else {
                                KendoNotifications.onSaveError(notification);
                            }
                        });
                    },
                    destroy: function (o){
                        $http.delete('/api/purchase-items-with-purchase-item-lots/' + o.data.id)
                            .success(function () {
                                o.success(o.data.id);
                                KendoNotifications.onDeleteSuccess(notification);
                                KendoGrid.refresh(angular.element("#purchaseItemsGrid").data("kendoGrid"));
                            })
                            .error(function () { KendoNotifications.onDeleteError(notification); });
                    }
                },
                schema : {
                    data: function (data) {
                        if(data.content != undefined) {
                            data.content.forEach(function(item){
                                if (item.product === null) item.product = {};
                                if (item.measurementUnit === null) item.measurementUnit = {};
                            });
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true},
                            button: { editable: false, nullable: true},
                            product: { defaultValue: { id: null, code: "", name: "" } },
                            'measurementUnit.name': { editable: false, nullable: true},
                            quantity: { type: "number", editable: true, nullable: true, validation: { min: 0 }},
                            quantityExecuted: { editable: false, nullable: true, type: "number" },
                            productPrice: { editable: false, nullable: true},
                            totalPrice: { editable: false, nullable: true},
                            discountSummary: { editable: false, nullable: true},
                            netPrice: { editable: false, nullable: true},
                            taxPrice: { editable: false, nullable: true},
                            finalPrice: { editable: false, nullable: true}
                        }
                    }
                },
                aggregate: [{field: "quantity", aggregate: "sum"},
                    {field: "quantityExecuted", aggregate: "sum"}],
                sort: ({ field: "createdDate", dir: "desc" }),
                pageSize: 100,
                serverPaging: true,
                serverSorting: true,
                scrollable:false,
                serverFiltering :true
            });
        };
        var purchasePresentItemsDatasource = function (selectedPurchaseId) {
            return new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "purchase.id", "operator": "eq",  "value": selectedPurchaseId }
                            ]
                        );

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                            }
                        });

                        $http.get('/api/purchase-present-items/search',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema : {
                    data: function (data) {
                        if(data.content != undefined) {
                            data.content.forEach(function(item){
                                if (item.product === null) item.product = {};
                            });
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: "id"
                        }
                    }
                },
                pageSize: 500,
                serverPaging: true,
                serverSorting: true,
                scrollable:false,
                serverFiltering :true
            });
        };

        var columnsToHide = ["productPrice", "totalPrice", "discountSummary", "netPrice", "taxPrice", "finalPrice"];
        return {
            getPurchasePresentItemsGridOptions: function ( selectedPurchaseId ) {
                return {
                    dataSource: purchasePresentItemsDatasource(selectedPurchaseId),
                    resizable: true,
                    columns:  purchasePresentItemsColumns,
                    toolbar: [
                        { template: "<div class='form-group left'><label class='control-label col-sm-2'>Ποσότητα: </label>" +
                            "<div class='col-sm-6 col-lg-3'>" +
                            "<input kendo-numeric-text-box id='quantityLot' k-options='vm.options' ng-model='vm.quantityLot' class='currency-alignment' min='0'/>" +
                            "</div></div>" },
                        { template: "<div class='form-group left'><label class='control-label col-sm-2'>Barcode: </label>" +
                            "<div class='col-sm-6 col-lg-3'>" +
                            "<input id='barcode' class='k-textbox' name='barcode' ng-model='vm.barcode'/></div></div>" },
                        { template: "<div class='form-group left'><md-button style='margin: 0!important;' class='md-raised md-primary md-button md-ink-ripple' aria-label='submit' ng-click='vm.addPresentProductLots()'>" +
                            "<md-icon ng-bind='\"add\"' style='font-size: 20px;'></md-icon>Καταχώρηση</md-button></div>" },
                        { name: "refresh", template: "<div class='right'><md-button style='padding: 0!important;' class='md-icon-button md-accent md-warn' aria-label='refresh' ng-click='vm.kendoGrid.refresh(vm.purchasePresentItemsGridOptions)'>" +
                            "<md-icon class='material-icons'>refresh</md-icon></md-button></div>" }
                    ]
                };
            },
            getPurchaseItemsGridOptions: function ( selectedPurchaseId ) {
                return {
                    dataSource: purchaseItemsDatasource(selectedPurchaseId),
                    save: function () {
                        var grid = this;
                        $timeout(function () {
                            grid.refresh();
                        })
                    },
                    navigatable: true,
                    resizable: true,
                    columns:  purchaseItemsColumns,
                    toolbar: [
                        { template: "<div class='form-group left'><label class='control-label col-sm-2'>Ποσότητα: </label>" +
                        "<div class='col-sm-6 col-lg-3'>" +
                        "<input kendo-numeric-text-box id='quantityLot' k-options='vm.options' ng-model='vm.quantityLot' class='currency-alignment' min='0' />" +
                        "</div></div>" },
                        { template: "<div class='form-group left'><label class='control-label col-sm-2'>Barcode: </label>" +
                        "<div class='col-sm-6 col-lg-3'>" +
                        "<input id='barcode' class='k-textbox' name='barcode' ng-model='vm.barcode'/></div></div>" },
                        { template: "<div class='form-group left'><md-button style='margin: 0!important;' class='md-raised md-primary md-button md-ink-ripple' aria-label='submit' ng-click='vm.addProductLots()'>" +
                        "<md-icon ng-bind='\"add\"' style='font-size: 20px;'></md-icon>Καταχώρηση</md-button></div>" },
                        { name: "refresh", template: "<div class='right'><md-button style='padding: 0!important;' class='md-icon-button md-accent md-warn' aria-label='refresh' ng-click='vm.kendoGrid.refresh(vm.purchaseItemsGridOptions)'>" +
                        "<md-icon class='material-icons'>refresh</md-icon></md-button></div>" },
                        { name: "excel", template: "<div class='right'><md-button style='margin: 0!important;' class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                        "<span class='k-icon k-i-excel'></span>Export</md-button></div>" },
                        { template: "<div class='clearfix'></div>" },
                        { name :"create", text: "Προσθήκη Νέας Γραμμής" }
                    ],
                    excel: {
                        allPages: true,
                        fileName: "Γραμμές Παραγγελιών.xlsx"
                    },
                    pageable: true,
                    editable: {
                        mode: "inline",
                        confirmation: false // the confirmation message for destroy command
                    },
                    edit: function(e) {
                        if (!e.model.isNew()) {
                            angular.element("#productCode").kendoComboBox({ enable: false });
                            angular.element("#productName").kendoComboBox({ enable: false });

                            angular.element("#productCode").data("kendoComboBox").text(e.model.product.code);
                            angular.element("#productName").data("kendoComboBox").text(e.model.product.name);
                        }
                        KendoGrid.defaultEditInline(this._editContainer);
                    },
                    excelExport: function(e) {
                        e.preventDefault();
                        setTimeout(function () {
                            $("#purchaseItemLotsGrid").data("kendoGrid").saveAsExcel();
                        });
                    }
                };
            },
            getExportState: function() {
                return exportState;
            },
            setExportState: function( value ) {
                exportState = value;
            },
            getQuantityDefaultValue: function( quantity, data, dataItem ) {
                var defaultValue = quantity;
                angular.forEach( data, function (item) {
                    if (dataItem.id != item.id) {
                        defaultValue -= item.quantity;
                    }
                });
                return defaultValue;
            },
            setIsEditable: function( value ) {
                isEditable = value;
                setEditVisibility(isEditable)
            },
            setVisibility: function(isStorehouse) {
                var purchaseItemsGrid = angular.element("#purchaseItemsGrid").data("kendoGrid");
                if (isStorehouse && purchaseItemsGrid) {
                    //hide columns from ROLE_STOREHOUSE
                    angular.forEach( purchaseItemsGrid.columns, function (item, index) {
                        if( columnsToHide.indexOf( item.field ) !== -1 ) {
                            purchaseItemsGrid.hideColumn(index);
                        }
                    });
                }
            }
        };

    }
})();
