(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AgreementDeleteController',AgreementDeleteController);

    AgreementDeleteController.$inject = ['$uibModalInstance', 'entity', 'Agreement'];

    function AgreementDeleteController($uibModalInstance, entity, Agreement) {
        var vm = this;
        vm.agreement = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Agreement.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
