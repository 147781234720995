/**
 * Created by Maria on 26/5/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('applicationParameters', {
                parent: 'app',
                url: '/applicationParameters',
                data: {
                    authorities: ['ROLE_SYSTEM']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/applicationParameters/applicationParameters.html',
                        controller: 'ApplicationParametersController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

    }

})();
