/**
 * Created by Kristy on 1/6/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('RecDoctorsController', RecDoctorsController);

    RecDoctorsController.$inject = ['$scope', '$http','$state', 'Principal', 'KendoFilter', 'KendoGrid', 'KendoService'];

    function RecDoctorsController ($scope, $http, $state, Principal, KendoFilter, KendoGrid, KendoService) {
        var vm = this;

        vm.pageHeading = 'Επαγγελματίες Υγείας';
        vm.canEdit = Principal.hasAuthority(['ROLE_REC_ADMINISTRATOR', 'ROLE_REC_MANAGEMENT']);

        vm.addEditFunctionality = function() {
            var grid = angular.element("#mainGrid").data("kendoGrid");
            vm.gridColumns.unshift({
                command: [
                    {name: "edit", text: ""}
                ],
                title: "&nbsp;",
                width: 80
            });

            vm.gridToolbar.unshift({name: "create", text: "Προσθήκη Νέου Επαγγελματία Υγείας"});

            grid.setOptions({
                columns: vm.gridColumns,
                editable: {
                    mode: "popup"
                },
                edit: function() {
                    KendoGrid.defaultEditPopup(this._editContainer);
                    //fix css
                    angular.element( "input[name='isActive']" ).parent().addClass("k-checkbox-custom");
                },
                toolbar: vm.gridToolbar
            });
        };

        //Data for DropDown
        vm.specialtyDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get('/api/masterdata/doctor-specialties',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            }
        });

        //CustomerTitle combobox edit configuration
        var dataSource = {};
        dataSource.customerTitlesDataSource = KendoService.initDataSource('/api/masterdata/customer-titles', 1, 20);

        vm.url = "/api/customers";

        vm.gridColumns = [
            {
                field: "lastName",
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {
                field: "firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {
                field: "amka",
                title: "A.M.K.A.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {
                field: "doctorSpecialty.name",
                title: "Ειδικοτητα 1η",
                editor: function(container, options) {
                    angular.element('<select id="doctor-specialty" data-bind="value:doctorSpecialty.id" required="required"' +
                        'data-required-msg="Η ειδικότητα του Επαγγελματία Υγείας είναι υποχρεωτική"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: vm.specialtyDataSource,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {
                field: "customerTitle.name",
                title: "Τύπος Επαγγελματία Υγείας",
                editor: function(container, options) {
                    angular.element('<select id="customer-title" data-bind="value:customerTitle.id"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: dataSource.customerTitlesDataSource,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {
                field: "cellPhone",
                title: "Κινητό",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 79
            },
            {
                field: "phone",
                title: "Σταθερό Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 151
            },
            {
                field: "email",
                title: "Email",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 151
            },
            {
                field: "isActive",
                title: "Ενεργός",
                filterable: {
                    cell: {
                        operator: "eqbool",
                        showOperators: false,
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "Ναι", value: "true" },
                                        { text: "Όχι", value: "false" }]
                                }),
                                valuePrimitive: true

                            })
                        }
                    }
                },
                width: 133,
                template: "#= (isActive == '1') ? 'Ναι' : 'Όχι' #"
            }
        ];

        vm.gridToolbar = [{ template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label></div>",
            overflow: "never" }];

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/me/customers',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    },
                    create: function (o){

                        var data = o.data.models[0];
                        if(!data.doctorSpecialty.id)
                            data.doctorSpecialty = null;

                        $http.post(vm.url, data)
                            .success(function (response) {
                                o.success(response);
                                $state.go('recDoctorInfo', {id: response.id});
                            })
                            .error(function (response) {});
                    },
                    update: function (o){

                        var data = o.data.models[0];

                        if(!data.doctorSpecialty.id)
                            data.doctorSpecialty = null;

                        $http.put(vm.url, data)
                            .success(function () {
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {});
                    }
                },
                batch: true,
                schema: {
                    data: function (data) {
                        if(data.content !== undefined) {
                            data.content.forEach(function(item){
                                if(item.doctorSpecialty === null)
                                    item.doctorSpecialty = { name: ""};
                                if(!item.customerTitle) item.customerTitle = {};
                            });
                        }
                        return data.content;

                    },
                    total: function (data) {
                      return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true},
                            lastName: { validation: { required: { message: "Το επώνυμο του Επαγγελματία Υγείας είναι υποχρεωτικό" } } },
                            firstName: { validation: { required: { message: "Το όνομα του Επαγγελματία Υγείας είναι υποχρεωτικό" } } },
                            //amka: { validation: { required: { message: "Το A.M.K.A. του Επαγγελματία Υγείας είναι υποχρεωτικό" } } },
                            isActive:{
                                type: "boolean",
                                defaultValue: true,
                                parse: function(value) {
                                    return (value === "1" || value === "true" || value === true);

                                }
                            },
                            doctorSpecialty:{defaultValue: {id: null, name : null}},
                            customerTitle:{defaultValue: {id: null, name : null}}
                        }
                    }
                },
                pageSize: 100,
                serverPaging: true,
                serverSorting: true,
                scrollable:false,
                serverFiltering :true
            }),
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: true,
            columns: vm.gridColumns,
            save: function (e) {
                var customersGrid = angular.element("#mainGrid").data("kendoGrid");
                customersGrid.refresh();
            },
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }]
        };

        $scope.$on("kendoRendered", function () {
            if(vm.canEdit) vm.addEditFunctionality();
        });

        vm.onSelection = function(data) {
            $state.go('recDoctorInfo', {id: data.id});
        };

    }
})();
