(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('RoomCostDetailController', RoomCostDetailController);

    RoomCostDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'RoomCost', 'CostType', 'RoomType'];

    function RoomCostDetailController($scope, $rootScope, $stateParams, entity, RoomCost, CostType, RoomType) {
        var vm = this;
        vm.roomCost = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:roomCostUpdate', function(event, result) {
            vm.roomCost = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
