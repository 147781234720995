(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('FollowupCallDetailController', FollowupCallDetailController);

    FollowupCallDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'FollowupCall', 'FollowupType', 'LeadCall', 'RejectionReason', 'FollowupStatus', 'Competitor'];

    function FollowupCallDetailController($scope, $rootScope, $stateParams, entity, FollowupCall, FollowupType, LeadCall, RejectionReason, FollowupStatus, Competitor) {
        var vm = this;
        vm.followupCall = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:followupCallUpdate', function(event, result) {
            vm.followupCall = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
