/**
 * Created by Teo on 14/05/2018.
 */

(function () {
    'use strict';

    angular
        .module('sigmasfApp')
        .service('LeadCallHelper', LeadCallHelper);

    LeadCallHelper.$inject = ['DateUtils'];

    function LeadCallHelper (DateUtils) {
        return {
            prepareLeadCall : prepareLeadCall,
            leadHospitalizationEqual : leadHospitalizationEqual,
            leadOfferEqual : leadOfferEqual,
            leadDiscountEqual : leadDiscountEqual,
            hasLeadHospitalization : hasLeadHospitalization,
            hasLeadOffer : hasLeadOffer,
            hasLeadDiscount : hasLeadDiscount,
            hasModifyDiseases : hasModifyDiseases,
            equalsLeadCall : equalsLeadCall,
            equalsLeadHospitalization : equalsLeadHospitalization,
            isUndefinedOrNull : isUndefinedOrNull,
            hasProspectivePatient: hasProspectivePatient,
            hasAttendant: hasAttendant,
            validateProspectivePatient: validateProspectivePatient,
            validateAttendant: validateAttendant,
            validatePhone: validatePhone
        };

        function prepareLeadCall(leadCall){
            if (leadCall.prospectivePatient && !leadCall.prospectivePatient.id) leadCall.prospectivePatient = null;
            if (leadCall.oldPatient && !leadCall.oldPatient.id) leadCall.oldPatient = null;
            if (leadCall.oldPatientDoctor && !leadCall.oldPatientDoctor.id) leadCall.oldPatientDoctor = null;
            if (leadCall.leadHospitalizations[0] !== null && leadCall.leadHospitalizations[0] !== undefined){
                if (leadCall.leadHospitalizations[0].possibleDischargeDate !== null && leadCall.leadHospitalizations[0].possibleDischargeDate !== undefined){
                    leadCall.leadHospitalizations[0].possibleDischargeDate = DateUtils.convertLocalDateToServer(leadCall.leadHospitalizations[0].possibleDischargeDate);
                }

                if (leadCall.leadHospitalizations[0].diagnosisDate !== null && leadCall.leadHospitalizations[0].diagnosisDate !== undefined){
                    leadCall.leadHospitalizations[0].diagnosisDate = DateUtils.convertLocalDateToServer(leadCall.leadHospitalizations[0].diagnosisDate);
                }

                if (leadCall.leadHospitalizations[0].hospitalizationDate !== null && leadCall.leadHospitalizations[0].hospitalizationDate !== undefined){
                    leadCall.leadHospitalizations[0].hospitalizationDate = DateUtils.convertLocalDateToServer(leadCall.leadHospitalizations[0].hospitalizationDate);
                }
            }

            //console.log(leadCall);

            return leadCall;
        }

        function leadHospitalizationEqual(initObject, object) {
            if (initObject.hospitalizationType.id === object.hospitalizationType.id &&
                initObject.isClosedHospitalization === object.isClosedHospitalization &&
                initObject.hadSurgery === object.hadSurgery &&
                initObject.surgeryType === object.surgeryType &&
                initObject.clinicName.id === object.clinicName.id &&
                initObject.possibleDischargeDate.getTime() === object.possibleDischargeDate.getTime() &&
                initObject.isFollowingHospitalization === object.isFollowingHospitalization &&
                initObject.diagnosisDate.getTime() === object.diagnosisDate.getTime() &&
                initObject.comments === object.comments){
                return true;
            }else {
                return false;
            }
        }

        function leadOfferEqual (initObject, object){
            if (initObject.costType.id === object.costType.id &&
                initObject.roomType.id === object.roomType.id &&
                initObject.firstEstimationDate.getTime() === object.firstEstimationDate.getTime() &&
                initObject.desirableRoomType.id === object.desirableRoomType.id &&
                initObject.upgradedRoomType.id === object.upgradedRoomType.id &&
                initObject.transportation.id === object.transportation.id &&
                initObject.wantsTransportation === object.wantsTransportation &&
                initObject.attendantMeal === object.attendantMeal &&
                initObject.hasCompetitiveBid === object.hasCompetitiveBid &&
                initObject.competitor.id === object.competitor.id){
                return true;
            }else {
                return false;
            }
        }

        function leadDiscountEqual(initObject, object) {
            if (initObject.hasRequestedDiscount === object.hasRequestedDiscount &&
                initObject.discountReason.id === object.discountReason.id &&
                initObject.isEntitledToDiscount === object.isEntitledToDiscount &&
                initObject.entitledToReason.id === object.entitledToReason.id &&
                initObject.finalDiscount.id === object.finalDiscount.id &&
                initObject.finalReason.id === object.finalReason.id &&
                initObject.wantsTransportation === object.wantsTransportation){
                return true;
            }else {
                return false;
            }
        }

        /**
         * Checks if a LeadHospitalization object has changed from an
         * initial LeadHospitalization object.
         * WARNING: This does not compare 2 LeadHospitalization objects for equality!
         * For example, it checks if object has sector, not if it is the same as initObject.
         * @param initObject The initial LeadHospitalization object
         * @param object The LeadHospitalization object that is about to be saved
         * @returns {boolean} True if object is different from initialObject, false if not
         */
        function hasLeadHospitalization(initObject, object){
            var has = false;

            if (object.hospitalizationType && object.hospitalizationType.id > 0){
                console.log("has LeadHospitalization.hospitalizationType");
                return true;
            }

            if (object.clinicName && object.clinicName.id > 0){
                console.log("has LeadHospitalization.clinicName");
                return true;
            }

            if (object.sector && object.sector.id > 0){
                console.log("has LeadHospitalization.sector");
                return true;
            }

            if (initObject.isClosedHospitalization !== object.isClosedHospitalization){
                console.log("has LeadHospitalization.isClosedHospitalization");
                return true;
            }

            if (initObject.hadSurgery !== object.hadSurgery){
                console.log("has LeadHospitalization.hadSurgery");
                return true;
            }

            if (initObject.surgeryType !== object.surgeryType){
                console.log("has LeadHospitalization.surgeryType");
                return true;
            }

            // if (initObject.possibleDischargeDate.getTime() !== object.possibleDischargeDate.getTime()){
            //     console.log("has possibleDischargeDate");
            //     return true;
            // }
            if (object.possibleDischargeDate !== null){
                console.log("has LeadHospitalization.possibleDischargeDate");
                return true;
            }

            if (initObject.isFollowingHospitalization !== object.isFollowingHospitalization){
                console.log("has LeadHospitalization.isFollowingHospitalization");
                return true;
            }

            // if (initObject.diagnosisDate.getTime() !== object.diagnosisDate.getTime()){
            //     console.log("has diagnosisDate");
            //     return true;
            // }
            if (object.diagnosisDate !== null){
                console.log("has LeadHospitalization.diagnosisDate");
                return true;
            }

            if (initObject.comments !== object.comments){
                console.log("has LeadHospitalization.comments");
                return true;
            }

            console.log(object.addDiseases);
            if (/*typeof object.addDiseases !== 'undefined' && */object.addDiseases.length > 0){
                console.log("has LeadHospitalization.addDiseases");
                return true;
            }

            console.log(object.addConditions);
            if (/*typeof object.addConditions !== 'undefined' && */object.addConditions.length > 0){
                console.log("has LeadHospitalization.addConditions");
                return true;
            }

            return has;
        }

        /**
         * Checks if a LeadOffer object has changed from an
         * initial LeadOffer object.
         * WARNING: This does not compare 2 LeadOffer objects for equality!
         * For example, it checks if object has roomType, not if it is the same as initObject.
         * @param initObject The initial LeadOffer object
         * @param object The LeadOffer object that is about to be saved
         * @returns {boolean} True if object is different from initialObject, false if not
         */
        function hasLeadOffer(initObject, object){
            var has = false;

            if (object.costType && object.costType.id > 0){
                console.log("has LeadOffer.costType");
                return true;
            }

            if (object.roomType && object.roomType.id > 0){
                console.log("has LeadOffer.roomType");
                return true;
            }

            if (object.desirableRoomType && object.desirableRoomType.id > 0){
                console.log("has LeadOffer.desirableRoomType");
                return true;
            }

            if (object.upgradedRoomType && object.upgradedRoomType.id > 0){
                console.log("has LeadOffer.upgradedRoomType");
                return true;
            }

            if (object.transportation && object.transportation.id > 0){
                console.log("has LeadOffer.transportation");
                return true;
            }

            if (object.competitor && object.competitor.id > 0){
                console.log("has LeadOffer.competitor");
                return true;
            }

            // if (initObject.firstEstimationDate.getTime() !== object.firstEstimationDate.getTime()){
            //     console.log("has LeadOffer.possibleDischargeDate");
            //     return true;
            // }
            if (object.firstEstimationDate !== null){
                console.log("has LeadOffer.firstEstimationDate");
                return true;
            }

            if (initObject.wantsTransportation !== object.wantsTransportation){
                console.log("has LeadOffer.wantsTransportation");
                return true;
            }

            if (initObject.attendantMeal !== object.attendantMeal){
                console.log("has LeadOffer.attendantMeal");
                return true;
            }

            if (initObject.hasCompetitiveBid !== object.hasCompetitiveBid){
                console.log("has LeadOffer.hasCompetitiveBid");
                return true;
            }

            return has;
        }

        /**
         * Checks if a LeadDiscount object has changed from an
         * initial LeadDiscount object.
         * WARNING: This does not compare 2 LeadDiscount objects for equality!
         * For example, it checks if object has hasRequestedDiscount, not if it is the same as initObject.
         * @param initObject The initial LeadDiscount object
         * @param object The LeadDiscount object that is about to be saved
         * @returns {boolean} True if object is different from initialObject, false if not
         */
        function hasLeadDiscount(initObject, object){
            var has = false;

            if (initObject.hasRequestedDiscount !== object.hasRequestedDiscount){
                console.log("has LeadDiscount.hasRequestedDiscount");
                return true;
            }

            if (object.discountReason && object.discountReason.id > 0){
                console.log("has LeadDiscount.discountReason");
                return true;
            }

            if (initObject.isEntitledToDiscount !== object.isEntitledToDiscount){
                console.log("has LeadDiscount.isEntitledToDiscount");
                return true;
            }

            if (object.entitledToReason && object.entitledToReason.id > 0){
                console.log("has LeadDiscount.entitledToReason");
                return true;
            }

            if (object.finalDiscount && object.finalDiscount.id > 0){
                console.log("has LeadDiscount.finalDiscount");
                return true;
            }

            if (object.finalReason && object.finalReason.id > 0){
                console.log("has LeadDiscount.finalReason");
                return true;
            }

            return has;
        }

        /**
         * Checks if a LeadOffer object is equal with other.
         * Inside objects are compared only with their ids.
         * @param o1 Object to compare
         * @param o2 Object to compare
         * @returns {boolean} True if arguments are equal
         */
        function equalsLeadCall(o1, o2){
            // console.log("Comparing LeadCalls ...");
            // console.log(o1);
            // console.log(o2);
            if (isUndefinedOrNull(o1) && isUndefinedOrNull(o2)){
                console.log("both objects are null or undefined");
                return true;
            }
            if (!isUndefinedOrNull(o1) && isUndefinedOrNull(o2)){
                console.log("obj2 is null or undefined");
                return false;
            }
            if (!isUndefinedOrNull(o2) && isUndefinedOrNull(o1)){
                console.log("obj1 is null or undefined");
                return false;
            }
            if (moment(o1.dateTime).isSame(o2.dateTime, 'minutes') === false){
                console.log("object.dateTime differs");
                return false;
            }
            if (o1.noContact !== o2.noContact){
                console.log("object.noContact differs");
                return false;
            }
            if (o1.isOldPatient !== o2.isOldPatient){
                console.log("object.isOldPatient differs");
                return false;
            }
            if (o1.isOldPatientRelative !== o2.isOldPatientRelative){
                console.log("object.isOldPatientRelative differs");
                return false;
            }

            if (!isUndefinedOrNull(o1.callType.id) && !isUndefinedOrNull(o2.callType.id)){
                if (angular.equals(o1.callType.id, o2.callType.id) === false){
                    console.log("object.callType differs");
                    return false;
                }
            }else {
                if (!isUndefinedOrNull(o1.callType.id) && isUndefinedOrNull(o2.callType.id)){
                    console.log("object1.callType is null");
                    return false;
                }
                if (!isUndefinedOrNull(o2.callType.id) && isUndefinedOrNull(o1.callType.id)){
                    console.log("object2.callType is null");
                    return false;
                }
            }

            if (!isUndefinedOrNull(o1.prospectivePatient) && !isUndefinedOrNull(o2.prospectivePatient)){
                if (angular.equals(o1.prospectivePatient.id, o2.prospectivePatient.id) === false){
                    console.log("object.prospectivePatient differs");
                    return false;
                }
            }else {
                if (!isUndefinedOrNull(o1.prospectivePatient) && isUndefinedOrNull(o2.prospectivePatient)){
                    console.log("object1.prospectivePatient is null");
                    return false;
                }
                if (!isUndefinedOrNull(o2.prospectivePatient) && isUndefinedOrNull(o1.prospectivePatient)){
                    console.log("object2.prospectivePatient is null");
                    return false;
                }
            }

            if (!isUndefinedOrNull(o1.oldPatient) && !isUndefinedOrNull(o2.oldPatient)){
                if (angular.equals(o1.oldPatient.id, o2.oldPatient.id) === false){
                    console.log("object.oldPatient differs");
                    return false;
                }
            }else {
                if (!isUndefinedOrNull(o1.oldPatient) && isUndefinedOrNull(o2.oldPatient)){
                    console.log("object1.oldPatient is null");
                    return false;
                }
                if (!isUndefinedOrNull(o2.oldPatient) && isUndefinedOrNull(o1.oldPatient)){
                    console.log("object2.oldPatient is null");
                    return false;
                }
            }

            if (!isUndefinedOrNull(o1.oldPatientDoctor) && !isUndefinedOrNull(o2.oldPatientDoctor)){
                if (angular.equals(o1.oldPatientDoctor.id, o2.oldPatientDoctor.id) === false){
                    console.log("object.oldPatientDoctor differs");
                    return false;
                }
            }else {
                if (!isUndefinedOrNull(o1.oldPatientDoctor) && isUndefinedOrNull(o2.oldPatientDoctor)){
                    console.log("object1.oldPatientDoctor is null");
                    return false;
                }
                if (!isUndefinedOrNull(o2.oldPatientDoctor) && isUndefinedOrNull(o1.oldPatientDoctor)){
                    console.log("object2.oldPatientDoctor is null");
                    return false;
                }
            }

            return true;
        }

        /**
         * Checks if a LeadHospitalization object is equal with other.
         * Inside objects are compared only with their ids.
         * @param o1 Object to compare
         * @param o2 Object to compare
         * @returns {boolean} True if arguments are equal
         */
        function equalsLeadHospitalization(o1, o2){
            console.log("Comparing LeadHospitalizations ...");
            console.log(o1);
            console.log(o2);
            if (isUndefinedOrNull(o1) && isUndefinedOrNull(o2)){
                console.log("both objects are null or undefined");
                return true;
            }
            if (!isUndefinedOrNull(o1) && isUndefinedOrNull(o2)){
                console.log("obj2 is null or undefined");
                return false;
            }
            if (!isUndefinedOrNull(o2) && isUndefinedOrNull(o1)){
                console.log("obj1 is null or undefined");
                return false;
            }
            if (o1.isClosedHospitalization !== o2.isClosedHospitalization){
                console.log("object.isClosedHospitalization differs");
                return false;
            }
            if (o1.hadSurgery !== o2.hadSurgery){
                console.log("object.hadSurgery differs");
                return false;
            }
            if (angular.equals(o1.surgeryType, o2.surgeryType) === false){
                console.log("object.surgeryType differs");
                return false;
            }
            if (angular.equals(o1.sectorOther, o2.sectorOther) === false){
                console.log("object.sectorOther differs");
                return false;
            }
            if (!isUndefinedOrNull(o1.diagnosisDate) && !isUndefinedOrNull(o2.diagnosisDate)){
                if (moment(o1.diagnosisDate).isSame(o2.diagnosisDate, 'minutes') === false){
                    console.log("object.diagnosisDate differs");
                    return false;
                }
            }else {
                if (!isUndefinedOrNull(o1.diagnosisDate) && isUndefinedOrNull(o2.diagnosisDate)){
                    console.log("object1.diagnosisDate is null");
                    return false;
                }
                if (!isUndefinedOrNull(o2.diagnosisDate) && isUndefinedOrNull(o1.diagnosisDate)){
                    console.log("object2.diagnosisDate is null");
                    return false;
                }
            }
            if (!isUndefinedOrNull(o1.possibleDischargeDate) && !isUndefinedOrNull(o2.possibleDischargeDate)){
                if (moment(o1.possibleDischargeDate).isSame(o2.possibleDischargeDate, 'minutes') === false){
                    console.log("object.possibleDischargeDate differs");
                    return false;
                }
            }else {
                if (!isUndefinedOrNull(o1.possibleDischargeDate) && isUndefinedOrNull(o2.possibleDischargeDate)){
                    console.log("object1.possibleDischargeDate is null");
                    return false;
                }
                if (!isUndefinedOrNull(o2.possibleDischargeDate) && isUndefinedOrNull(o1.possibleDischargeDate)){
                    console.log("object2.possibleDischargeDate is null");
                    return false;
                }
            }
            if (o1.isFollowingHospitalization !== o2.isFollowingHospitalization){
                console.log("object.isFollowingHospitalization differs");
                return false;
            }
            if (angular.equals(o1.comments, o2.comments) === false){
                console.log("object.comments differs");
                return false;
            }
            if (angular.equals(o1.addDiseaseOther, o2.addDiseaseOther) === false){
                console.log("object.addDiseaseOther differs");
                return false;
            }

            if (!isUndefinedOrNull(o1.sector) && !isUndefinedOrNull(o2.sector)){
                if (angular.equals(o1.sector.id, o2.sector.id) === false){
                    console.log("object.sector differs");
                    return false;
                }
            }else {
                if (!isUndefinedOrNull(o1.sector) && isUndefinedOrNull(o2.sector)){
                    console.log("object1.sector is null");
                    return false;
                }
                if (!isUndefinedOrNull(o2.sector) && isUndefinedOrNull(o1.sector)){
                    console.log("object2.sector is null");
                    return false;
                }
            }

            if (!isUndefinedOrNull(o1.hospitalizationType) && !isUndefinedOrNull(o2.hospitalizationType)){
                if (angular.equals(o1.hospitalizationType.id, o2.hospitalizationType.id) === false){
                    console.log("object.hospitalizationType differs");
                    return false;
                }
            }else {
                if (!isUndefinedOrNull(o1.hospitalizationType) && isUndefinedOrNull(o2.hospitalizationType)){
                    console.log("object1.hospitalizationType is null");
                    return false;
                }
                if (!isUndefinedOrNull(o2.hospitalizationType) && isUndefinedOrNull(o1.hospitalizationType)){
                    console.log("object2.hospitalizationType is null");
                    return false;
                }
            }

            if (!isUndefinedOrNull(o1.clinicName) && !isUndefinedOrNull(o2.clinicName)){
                if (angular.equals(o1.clinicName.id, o2.clinicName.id) === false){
                    console.log("object.clinicName differs");
                    return false;
                }
            }else {
                if (!isUndefinedOrNull(o1.clinicName) && isUndefinedOrNull(o2.clinicName)){
                    console.log("object1.clinicName is null");
                    return false;
                }
                if (!isUndefinedOrNull(o2.clinicName) && isUndefinedOrNull(o1.clinicName)){
                    console.log("object2.clinicName is null");
                    return false;
                }
            }

            return true;
        }

        function hasModifyDiseases(leadHospitalizationDiseases, selectedDiseases) {
            var has = false;

            // console.log("leadHospitalizationDiseases: ");
            // console.log(leadHospitalizationDiseases);
            // console.log("selectedDiseases: ");
            // console.log(selectedDiseases);

            if (leadHospitalizationDiseases === null || leadHospitalizationDiseases.length === 0){
                if (selectedDiseases != null && selectedDiseases.length > 0){
                    // case: there wasn't any diseases, has added new
                    console.log("there wasn't any diseases, has added new");
                    return true;
                }
            }

            if (selectedDiseases === null || selectedDiseases.length === 0){
                if (leadHospitalizationDiseases != null && leadHospitalizationDiseases.length > 0){
                    // case: there was diseases, has remove them all
                    console.log("there was diseases, has remove them all");
                    return true;
                }
            }

            if (leadHospitalizationDiseases != null && leadHospitalizationDiseases.length > 0 &&
                selectedDiseases != null && selectedDiseases.length > 0){
                var diseases = [];

                for (var i=0; i < leadHospitalizationDiseases.length; i++) {
                    diseases.push(leadHospitalizationDiseases[i].disease);
                }

                if (diseases.length !== selectedDiseases.length){
                    // case: there was diseases and there are new but lengths are different
                    console.log("there was diseases and there are new but lengths are different");
                    return true;
                }else {
                    // case: oldDiseases length is equal to new diseases, check items
                    console.log("oldDiseases length is equal to new diseases, check items");
                    for (var j = diseases.length; j--;){
                        if (diseases[j].id !== selectedDiseases[j].id){
                            has = true;
                            break;
                        }
                    }
                }
            }

            console.log("has not made any changes in Diseases!");

            return has;
        }

        function hasProspectivePatient(prospectivePatient){
            var has = false;

            if (prospectivePatient !== null && prospectivePatient !== undefined) {

                if (prospectivePatient.firstName !== null && prospectivePatient.firstName !== undefined && prospectivePatient.firstName.length > 0){
                    has = true;
                    return has;
                }

                if (prospectivePatient.lastName !== null && prospectivePatient.lastName !== undefined && prospectivePatient.lastName.length > 0){
                    has = true;
                    return has;
                }

                if (prospectivePatient.phone !== null && prospectivePatient.phone !== undefined && prospectivePatient.phone.length > 0){
                    has = true;
                    return has;
                }

                if (prospectivePatient.email !== null && prospectivePatient.email !== undefined && prospectivePatient.email.length > 0){
                    has = true;
                    return has;
                }

                if (prospectivePatient.mobile !== null && prospectivePatient.mobile !== undefined && prospectivePatient.mobile.length > 0){
                    has = true;
                    return has;
                }

                if (prospectivePatient.birthYear !== null && prospectivePatient.birthYear !== undefined && prospectivePatient.birthYear.length > 0){
                    has = true;
                    return has;
                }

                if (prospectivePatient.countryName !== null && prospectivePatient.countryName !== undefined && prospectivePatient.countryName.length > 0){
                    has = true;
                    return has;
                }

                if (prospectivePatient.regionName !== null && prospectivePatient.regionName !== undefined && prospectivePatient.regionName.length > 0){
                    has = true;
                    return has;
                }

                if (prospectivePatient.prefectureName !== null && prospectivePatient.prefectureName !== undefined && prospectivePatient.prefectureName.length > 0){
                    has = true;
                    return has;
                }

                if (prospectivePatient.prefectureName !== null && prospectivePatient.prefectureName !== undefined && prospectivePatient.prefectureName.length > 0){
                    has = true;
                    return has;
                }

                if (prospectivePatient.cityName !== null && prospectivePatient.cityName !== undefined && prospectivePatient.cityName.length > 0){
                    has = true;
                    return has;
                }

                if (prospectivePatient.postalCode !== null && prospectivePatient.postalCode !== undefined && prospectivePatient.postalCode.length > 0){
                    has = true;
                    return has;
                }

                if (prospectivePatient.address !== null && prospectivePatient.address !== undefined && prospectivePatient.address.length > 0){
                    has = true;
                    return has;
                }

                if (prospectivePatient.addressNum !== null && prospectivePatient.addressNum !== undefined && prospectivePatient.addressNum.length > 0){
                    has = true;
                    return has;
                }

                if (prospectivePatient.postalCode !== null && prospectivePatient.postalCode !== undefined && prospectivePatient.postalCode.length > 0){
                    has = true;
                    return has;
                }

                if (prospectivePatient.profession !== null && prospectivePatient.profession !== undefined && prospectivePatient.profession.id > 0){
                    has = true;
                    return has;
                }

                /*if (prospectivePatient.nativeLanguage !== null && prospectivePatient.nativeLanguage !== undefined && prospectivePatient.nativeLanguage.id > 0){
                    has = true;
                    return has;
                }*/

            }

            return has;
        }

        function hasAttendant(attendant){
            var has = false;

            if (attendant !== null && attendant !== undefined) {

                if (attendant.firstName !== null && attendant.firstName !== undefined && attendant.firstName.length > 0){
                    has = true;
                    return has;
                }

                if (attendant.lastName !== null && attendant.lastName !== undefined && attendant.lastName.length > 0){
                    has = true;
                    return has;
                }

                if (attendant.phone !== null && attendant.phone !== undefined && attendant.phone.length > 0){
                    has = true;
                    return has;
                }

                if (attendant.email !== null && attendant.email !== undefined && attendant.email.length > 0){
                    has = true;
                    return has;
                }

                if (attendant.profession !== null && attendant.profession !== undefined && attendant.profession.id > 0){
                    has = true;
                    return has;
                }

                if (attendant.relationshipType !== null && attendant.relationshipType !== undefined && attendant.relationshipType.id > 0){
                    has = true;
                    return has;
                }

            }

            return has;
        }

        function validateAttendant(attendant){
            var isValid = true;

            if (attendant === null || attendant === undefined){
                isValid = false;
                return isValid;
            }

            if (attendant.firstName === null || attendant.firstName === undefined){
                isValid = false;
                return isValid;
            }

            if (attendant.lastName === null || attendant.lastName === undefined){
                isValid = false;
                return isValid;
            }

            return isValid;
        }

        function validateProspectivePatient(prospectivePatient){
            var isValid = true;

            if (prospectivePatient === null || prospectivePatient === undefined){
                isValid = false;
                return isValid;
            }

            if (prospectivePatient.firstName === null || prospectivePatient.firstName === undefined){
                isValid = false;
                return isValid;
            }

            if (prospectivePatient.lastName === null || prospectivePatient.lastName === undefined){
                isValid = false;
                return isValid;
            }

            return isValid;
        }

        function isUndefinedOrNull (val) {
            return angular.isUndefined(val) || val === null
        }

        /**
         * Custom validation for phone. Atm it works only for Greek numbers.
         * Note that phone is nullable, so it is valid if it is null.
         * CountryPhone.id == 1 is for Greece.
         * @param countryPhoneCode The countryCode for different Country validations
         * @param phone The actual phone to validate
         * @returns {boolean} True if is valid, false if not
         */
        function validatePhone(countryPhoneCode, phone){
            var isValid = false;

            if (countryPhoneCode && countryPhoneCode.id === 1){
                // Greece Validation
                if (phone && phone !== null && phone.length > 0){
                    // Make validations
                    if (isNumeric(phone)){
                        isValid = phone.length === 10 && (phone.startsWith("6") || phone.startsWith("2"))
                    }else {
                        isValid = false;
                    }
                }else {
                    isValid = true;
                }
            }else {
                isValid = true;
            }

            return isValid;
        }

        /**
         * Checks if a string has only numbers
         * @param n The string to check
         * @returns {boolean} True if it is, false if not
         */
        function isNumeric(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }
    }
})();
