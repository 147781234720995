/**
 * Created by Kristy on 24/5/2017.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminEmployeeViewEmployeeInfoController', AdminEmployeeViewEmployeeInfoController);

    AdminEmployeeViewEmployeeInfoController.$inject = ['$http', '$window', 'EmployeeViewService', 'NotificationOptions', 'ApplicationParameters', 'Principal'];

    function AdminEmployeeViewEmployeeInfoController ($http, $window, EmployeeViewService, NotificationOptions, ApplicationParameters, Principal) {
        var vm = this;
        vm.north = 'ΒΟΡΕΙΑ ΕΛΛΑΔΑ';
        vm.south = 'ΝΟΤΙΑ ΕΛΛΑΔΑ';
        vm.westAndPeloponnese = 'ΔΥΤ. ΕΛΛΑΔΟΣ & ΠΕΛΟΠΟΝΝΗΣΟΥ';

        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN']);

        $http.get('api/employees/' + EmployeeViewService.getSelectedUser().id)
            .success(function (response) {
                vm.employee = response;

                if(vm.employee.district === 'north') {
                    vm.employee.district = { id: 1, name: vm.north };
                } else if(vm.employee.district === 'south') {
                    vm.employee.district = { id: 2, name: vm.south };
                } else if(vm.employee.district === 'westAndPeloponnese') {
                    vm.employee.district = { id: 3, name: vm.westAndPeloponnese };
                }

                if (vm.employee.supervisor) {
                    vm.employee.supervisor.name =
                        vm.employee.supervisor.account.lastName + " " + vm.employee.supervisor.account.firstName;
                }

                $http.get('api/users/' + vm.employee.account.login)
                    .success(function (response) {
                        vm.user = response;
                    })
                    .error(function (response) {
                        console.log(response);
                    });
            })
            .error(function (response) {
                console.log(response);
            });

        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");

        vm.districtDataSource = [
            { id: 1, name: vm.north },
            { id: 2, name: vm.south },
            { id: 3, name: vm.westAndPeloponnese }
        ];

        vm.districtComboBoxOptions = {
            dataSource: vm.districtDataSource,
            valuePrimitive: false,
            filter: "startswith",
            dataTextField: "name",
            dataValueField: "id",
            placeholder: "Επιλογή κατηγορίας..."
        };

        vm.employeesComboBoxDataSource= new kendo.data.DataSource({
            transport: {
                read: function(o) {
                    var url = '/api/employees/available-supervisors';

                    $http.get(url, {params: {id: EmployeeViewService.getSelectedUser().id}})
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            },
            schema: {
                parse: function (data) {
                    data.forEach( function (item) {
                        item.name = item.account.lastName + " " + item.account.firstName;
                    });
                    return data;
                }
            },
            sort:{field:"account.lastName", dir:"asc"}
        });

        vm.employeesComboBoxOptions = {
            dataSource: vm.employeesComboBoxDataSource,
            filter: "contains",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };

        var employeeValidator = angular.element("#form").kendoValidator({
            rules: {
                custom: function (input) {

                    if (input.data('custom')) {
                        if (input.val() !== null && input.val() !== "" && angular.element(input.data('custom')).data("kendoComboBox").selectedIndex === -1) {
                            return false;
                        }
                    }

                    return true;
                }
            },
            messages: {
                custom: "Πρέπει να επιλέξετε από την λίστα",
                maxlength: "Το κινητό τηλέφωνο θα πρέπει να περιέχει 10 χαρακτήρες."
            }
        }).data("kendoValidator");

        vm.updateEmployee = function() {
            vm.updatedEmployee = angular.copy(vm.employee);
            vm.updatedUser = angular.copy(vm.user);

            if(vm.updatedEmployee.district) {
                if (vm.updatedEmployee.district.id == 1) {
                    vm.updatedEmployee.district = 'north';
                } else if (vm.updatedEmployee.district.id == 2) {
                    vm.updatedEmployee.district = 'south';
                } else if (vm.updatedEmployee.district.id == 3) {
                    vm.updatedEmployee.district = 'westAndPeloponnese';
                }
            }

            if (!vm.updatedEmployee.supervisor.id) {
                vm.updatedEmployee.supervisor = null;
            }

            if (employeeValidator.validate()) {
                $http.put('/api/employees', vm.updatedEmployee)
                    .success(function (response) {
                        if (employeeValidator.validate()) {
                            $http.put('/api/users', vm.updatedUser)
                                .success(function (response) {
                                    notification.show({
                                        message: "Η ενημέρωση των στοιχείων ήταν επιτυχής!"
                                    }, "success");
                                })
                                .error(function (response) {
                                    console.log(response);
                                });
                        }else{
                            notification.show({
                                message: "Η ενημέρωση των στοιχείων απέτυχε!"
                            }, "error");
                        }
                    })
                    .error(function (response) {
                        console.log(response);
                    });
            }else{
                notification.show({
                    message: "Η ενημέρωση των στοιχείων απέτυχε!"
                }, "error");
            }
        };
    }

})();
