(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MedReportDeleteController',MedReportDeleteController);

    MedReportDeleteController.$inject = ['$uibModalInstance', 'entity', 'MedReport'];

    function MedReportDeleteController($uibModalInstance, entity, MedReport) {
        var vm = this;
        vm.medReport = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            MedReport.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
