/**
 * Created by Kristy on 19/12/2016.
 */
(function () {
    'use strict';

    angular
        .module('sigmasfApp')
        .service('PharmacyAssignations', PharmacyAssignations);

    PharmacyAssignations.$inject = ['$filter'];
    function PharmacyAssignations ($filter) {
        var selectedSectors,
            employees = [];
        var employee;

        return {
            getSelectedSectors: function () {
                return selectedSectors;
            },
            setSelectedSectors: function (value) {
                selectedSectors = value;
            },
            getEmployees: function () {
                return employees;
            },
            setEmployees: function (value) {
                employees = value;
            },
            getEmployee: function () {
                return employee;
            },
            setEmployee: function (value) {
                employee = value;
            }
        };

    }
})();
