/**
 * Created by Kristy on 02/09/2019.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SchedulesAllController', SchedulesAllController);

    SchedulesAllController.$inject = ['$scope', '$filter','$http', '$timeout', 'KendoFilter', 'favouriteFilters','KendoToolbar', 'FavouriteFiltersService',
        'KendoGrid', 'MasterToKendoFiltersService', 'employee', 'CustomUtils'];

    function SchedulesAllController ($scope, $filter, $http, $timeout, KendoFilter, favouriteFilters, KendoToolbar, FavouriteFiltersService, KendoGrid,
                                          MasterToKendoFiltersService, employee, CustomUtils) {
        var vm = this;
        vm.pageHeading = "Ραντεβού";
        vm.contentWidth = CustomUtils.getWidthPixelsFromPercentage(84);
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(51);
        vm.url = '/api/event-schedules/all';
        vm.favouriteFilters = favouriteFilters.data.content;
        vm.employee = employee;
        vm.masterFilters = {};

        vm.disabled = true;
        vm.isEdited = vm.isRenameMode = false;
        vm.selectedFilter = {};
        vm.mainGrid = {};
        vm.masterOptions = [];
        vm.filter = {};
        vm.view = 'schedules';

        var setView = function() {
            var filterable = vm.masterFilters.from && vm.masterFilters.to ? {
                mode: "row"
            } : false;

            var sortable = vm.masterFilters.from && vm.masterFilters.to ? {
                mode: "multiple",
                allowUnsort: true,
                showIndexes: true
            } : false;

            vm.disabled = !vm.masterFilters.from || !vm.masterFilters.to;
            vm.mainGrid.setOptions({
                sortable: sortable,
                groupable: vm.masterFilters.from && vm.masterFilters.to,
                filterable: filterable
            });
        };
        $scope.$watchGroup(["vm.masterFilters.from", "vm.masterFilters.to"], function () { setView(); });

        vm.masterFiltersEdited = function(){
            var gridOptions = FavouriteFiltersService.getKendoOptions(vm.mainGrid);
            $timeout(function(){
                vm.isEdited = !!(vm.selectedFilter.kendoOptions && vm.masterOptions.length &&
                    (!angular.equals(vm.masterOptions, MasterToKendoFiltersService.getMasterFilters([], vm.masterFilters, vm.view)) || !angular.equals(JSON.parse(vm.selectedFilter.kendoOptions), gridOptions)) );
            });
        };
        $scope.$watch("vm.masterFilters", vm.masterFiltersEdited, true);

        //Date Filter
        vm.masterFiltersOnClick = function(e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');
            if (e.id === "today") {
                KendoToolbar.clearDatePickers();
                $timeout(function () {
                    vm.masterFilters.from = date;
                    vm.masterFilters.to = date;
                });
            }
            else if (e.id === "week") {
                KendoToolbar.clearDatePickers();
                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6
                $timeout(function () {
                    vm.masterFilters.from = firstWeekDay;
                    vm.masterFilters.to = lastWeekDay;
                });
            }
            else if (e.id === "month") {
                KendoToolbar.clearDatePickers();
                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                $timeout(function () {
                    vm.masterFilters.from = firstMonthDay;
                    vm.masterFilters.to = lastMonthDay;
                });
            }
            else if (e.id === "year") {
                KendoToolbar.clearDatePickers();
                $timeout(function () {
                    vm.masterFilters.from = new Date(date.getFullYear(), 0, 1);
                    vm.masterFilters.to = new Date();
                });
            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup", id: "buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ], overflow: "never", attributes: {class: "left"}
                },
                {type: "separator", overflow: "never", attributes: {class: "left"}},
                {
                    template: "<label>Από: </label><input class='inline' kendo-date-picker id='from' onkeydown='return false' options='vm.fromOptions' k-ng-model='vm.masterFilters.from'/>",
                    overflow: "never",
                    attributes: {class: "left"}
                },
                {
                    template: "<label>Εώς: </label><input class='inline' kendo-date-picker id='to' onkeydown='return false' options='vm.toOptions' k-ng-model='vm.masterFilters.to'/>",
                    overflow: "never",
                    attributes: {class: "left"}
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary md-primary-light' aria-label='search' ng-click='vm.mainGridOptions.dataSource.read()' ng-disabled='vm.disabled'>" +
                    "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>",
                    overflow: "never",
                    attributes: {class: "right"}
                }
            ]
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.masterFilters.from = value;
                } else {
                    vm.masterFilters.from = null;
                }
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.masterFilters.to = value;
                } else {
                    vm.masterFilters.to = null;
                }
            }
        };

        var grid = {};
        grid.columns = [
            {
               title: "Επαγγελματίας Υγείας",
               columns: [
                   {
                       field: "eventCustomerSector.customerSector.customer.lastName",
                       title: "Επώνυμο Επαγγελματία Υγείας",
                       filterable: {
                           cell: {
                               operator: "startswith",
                               template: function (args) {
                                   KendoFilter.getTemplate(args);
                               },
                               showOperators: false
                           }
                       }
                   },
                   {
                       field: "eventCustomerSector.customerSector.customer.firstName",
                       title: "Όνομα Επαγγελματία Υγείας",
                       filterable: {
                           cell: {
                               operator: "startswith",
                               template: function (args) {
                                   KendoFilter.getTemplate(args);
                               },
                               showOperators: false
                           }
                       }
                   },
                   {
                       field: "eventCustomerSector.customerSector.customer.doctorSpecialty.name",
                       title: "Ειδικότητα 1η",
                       filterable: {
                           cell: {
                               operator: "startswith",
                               template: function (args) {
                                   KendoFilter.getTemplate(args);
                               },
                               showOperators: false
                           }
                       }
                   },
                   {
                       field: "eventCustomerSector.customerSector.customer.customerCategory.name",
                       title: "Κατηγορία",
                       filterable: {
                           cell: {
                               operator: "startswith",
                               template: function (args) {
                                   KendoFilter.getTemplate(args);
                               },
                               showOperators: false
                           }
                       },
                       width: 94
                   },
                   {
                       field: "eventCustomerSector.customerSector.customer.customerSubCategory.name",
                       title: "Υποκατηγορία",
                       filterable: {
                           cell: {
                               operator: "startswith",
                               template: function (args) {
                                   KendoFilter.getTemplate(args);
                               },
                               showOperators: false
                           }
                       },
                       width: 115
                   },
                   {
                       field: "eventCustomerSector.customerSector.customer.collaboration.name",
                       title: "Συνεργασία",
                       filterable: {
                           cell: {
                               operator: "startswith",
                               template: function (args) {
                                   KendoFilter.getTemplate(args);
                               },
                               showOperators: false
                           }
                       },
                       width: 100
                   }
               ]
            },
            {
                field: "eventSchedule.startedAt",
                title: "Ημερομηνία Ραντεβού",
                template: "#= eventSchedule.startedAt ? kendo.toString(kendo.parseDate(eventSchedule.startedAt), 'dddd, dd/MM/yyyy HH:mm') : ''#",
                filterable: false,
                groupable: false
            },
            {
                title: "Μονάδα Υγείας",
                columns: [
                    {
                        field: "eventCustomerSector.sector.sectorType.name",
                        title: "Τύπος",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    {
                        field: "eventCustomerSector.sector.name",
                        title: "Όνομα",
                        filterable: {
                            cell: {
                                operator: "contains",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    {
                        field: "eventCustomerSector.sector.address",
                        title: "Διεύθυνση",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    {
                        field: "eventCustomerSector.sector.cityName",
                        title: "Πόλη",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    {
                        field: "eventCustomerSector.sector.prefectureName",
                        title: "Νομός",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    }
                ]
            }
        ];
        grid.dataSource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function (o) {
                    if (o.data.filter === undefined) { o.data.filter = { filters: [] }; }
                    o.data.filter.filters = MasterToKendoFiltersService.getMasterFilters(o.data.filter.filters, vm.masterFilters, vm.view);
                    // o.data.filter.filters.push({
                    //     field: "eventCustomerSector.customerSector",
                    //     operator: "isnotnull",
                    //     value: "null"
                    // });

                    $http.get(vm.url, {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {});
                }
            },
            schema: {
                data: function (data) {
                    if(data !== null && data !== undefined) {
                        data.forEach(function(item){
                            if (!item.eventCustomerSector) {
                                item.eventCustomerSector = {
                                    customerSector: {
                                        customer: {
                                            last_name: "",
                                            first_name: "",
                                            doctorSpecialty: {},
                                            customerCategory: {},
                                            customerSubCategory: {},
                                            collaboration: {}
                                        },
                                        sector: {
                                            address: "",
                                            city: {
                                                name: ""
                                            }
                                        }
                                    },
                                    sector: {
                                        address: "",
                                        sectorType: {}
                                    }
                                };
                            } else {
                                if (!item.eventCustomerSector.customerSector) {
                                    item.eventCustomerSector.customerSector = {
                                        customer: {
                                            last_name: "",
                                            first_name: "",
                                            doctorSpecialty: {},
                                            customerCategory: {},
                                            customerSubCategory: {},
                                            collaboration: {}
                                        },
                                        sector: {
                                            address: "",
                                            city: {
                                                name: ""
                                            }
                                        }
                                    };
                                } else {
                                    if (!item.eventCustomerSector.customerSector.customer.doctorSpecialty)
                                        item.eventCustomerSector.customerSector.customer.doctorSpecialty = {};

                                    if (!item.eventCustomerSector.customerSector.customer.customerCategory)
                                        item.eventCustomerSector.customerSector.customer.customerCategory = {};

                                    if (!item.eventCustomerSector.customerSector.customer.customerSubCategory)
                                        item.eventCustomerSector.customerSector.customer.customerSubCategory = {};

                                    if (!item.eventCustomerSector.customerSector.customer.collaboration)
                                        item.eventCustomerSector.customerSector.customer.collaboration = {};
                                }

                                if (item.eventCustomerSector.sector == null) {
                                    item.eventCustomerSector.sector = {
                                        sectorType: {}
                                    };
                                }

                                if (item.eventCustomerSector.sector.sectorType == null) {
                                    item.eventCustomerSector.sector.sectorType = {
                                        name: ""
                                    };
                                }
                            }
                        });
                    }
                    return data;
                },
                model: {
                    id: "id",
                    fields: {
                        id: {editable: false, nullable: false},
                        'eventSchedule.startedAt': { type: 'date'}
                    }
                }
            }
        });
        vm.mainGridOptions = {
            autoBind: false,
            dataSource: grid.dataSource,
            sortable: false,
            groupable: false,
            resizable: true,
            filterable: false,
            columns: grid.columns,
            toolbar: [
                { name: "excel", template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                    "<span class='k-icon k-i-excel'></span>Export</md-button></div>" }
            ],
            excel: {
                allPages: true,
                fileName: "Αναφορά Ραντεβού.xlsx"
            },
            dataBound: function () { vm.masterFiltersEdited(); }
        };
    }
})();
