/**
 * Created by Maria on 16/5/2016.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminPharmacyViewController', AdminPharmacyViewController);

    AdminPharmacyViewController.$inject = ['$rootScope', '$http','$state', '$stateParams', '$filter', 'Principal', '$scope'];

    function AdminPharmacyViewController ($rootScope, $http, $state, $stateParams, $filter, Principal, $scope) {
        var vm = this;

        if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_ACCOUNTING_DEP', 'ROLE_PHARM_SUPERVISOR'])) {
            vm.canEdit = true;
        }else {
            vm.readOnly = true;
        }

        vm.pageHeading = 'Επεξεργασια Φαρμακειου';
        vm.profilePicture = "hospital_icon.png";
        vm.selectedSectorId = $stateParams.id;
        vm.$state = $state;


        $http.get('api/sectors/' + vm.selectedSectorId)
            .success(function (response) {
                vm.sector = response;
                if (vm.sector && vm.sector.name){
                    vm.pageHeading = vm.sector.name;
                }
            })
            .error(function (response) {
                console.log(response);
            });

        kendo.Backbone = kendo.Backbone || {};

        kendo.Backbone.DataSource = kendo.data.DataSource.extend({
            init: function(options) {
                if (options.transport && options.transport.read) {
                    options.transport.read.type = 'GET';
                    options.transport.read.dataType = 'json';
                    options.transport.read.contentType = 'application/json;charset=UTF-8';
                    options.transport.read.parameterMap = function (data, operation) {
                        return JSON.stringify(data);
                    }
                }
                kendo.data.DataSource.fn.init.call(this, options);
            }
        });

        vm.goBack = function () {
            $state.go('adminPharmacies');
        };
    }

})();
