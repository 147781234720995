(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CustomerTitleDeleteController',CustomerTitleDeleteController);

    CustomerTitleDeleteController.$inject = ['$uibModalInstance', 'entity', 'CustomerTitle'];

    function CustomerTitleDeleteController($uibModalInstance, entity, CustomerTitle) {
        var vm = this;
        vm.customerTitle = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            CustomerTitle.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
