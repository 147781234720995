(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('EmployeeCustomerSectorSearch', EmployeeCustomerSectorSearch);

    EmployeeCustomerSectorSearch.$inject = ['$resource'];

    function EmployeeCustomerSectorSearch($resource) {
        var resourceUrl =  'api/_search/employee-customer-sectors/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
