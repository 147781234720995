(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MDPresentListsController', MDPresentListsController);

    MDPresentListsController.$inject = ['$scope', 'ApplicationParameters', '$http', '$filter', 'KendoFilter', 'KendoGrid', '$mdToast', 'Alerts', 'CustomUtils'];

    function MDPresentListsController ($scope, ApplicationParameters, $http, $filter, KendoFilter, KendoGrid, $mdToast, Alerts, CustomUtils) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(65);
        vm.massSelect = true;
        vm.quantity = 1;
        vm.kendoGrid = KendoGrid;
        vm.pageHeading = $filter('translate')('masterData.presentLists.title');

        ApplicationParameters.hasModule('Purchase_Pharmacies', true);

        vm.productsDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function (o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = {filters: []};
                    }

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get('/api/masterdata/products',
                        {params: o.data})
                        .success(function (response, status, headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        vm.onAddPresentListClick = function () {
            const grid = $("#mainGrid").data("kendoGrid");
            grid.addRow();
        };

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/present-lists/search',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    create: function(o) {
                        var data = o.data.models[0];
                        $http.post('/api/present-lists', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    update: function(o) {
                        var data = o.data.models[0];
                        $http.put('/api/present-lists', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            code: { validation: { required: true }},
                            selectable: { type:"boolean" }
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            sortable: true,
            pageable: {
                messages: {
                    display: "{0} - {1} από {2} Λίστες Δώρων", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                    empty: "Δεν βρέθηκαν Λίστες Δώρων",
                    page: "Σελίδα",
                    allPages: "Όλα",
                    of: "από {0}", // {0} is total amount of pages.
                    itemsPerPage: "εγγραφές ανά σελίδα",
                    first: "Πήγαινε στη πρώτη σελίδα",
                    previous: "Πήγαινε στη τελευταία σελίδα",
                    next: "Πήγαινε στην επόμενη σελίδα",
                    last: "Πήγαινε στη τελευταία σελίδα",
                    refresh: "Ανανέωση"
                }
            },
            noRecords: {
                template: "Δεν βρέθηκαν Λίστες Δώρων"
            },
            height: vm.contentHeight,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "code", title: "Κωδικός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "description", title: "Περιγραφή",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {template: '<md-checkbox class="checkbox-toolbar customers green inline"  aria-label="selectable" ng-model="dataItem.selectable" ' +
                'ng-disabled="true"></md-checkbox>',
                    field: "selectable", title: "Λίστα Επιλογής",
                    editor: function(container,options){
                        angular.element('<md-checkbox ng-model="dataItem.selectable"  aria-label="selectable" ' +
                            'class="checkbox-toolbar customers green inline"></md-checkbox>')
                            .appendTo(container);
                    },
                    filterable: {
                        cell: {
                            operator: "eqbool",
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Ναι", value: "true" },
                                            { text: "Όχι", value: "false" }]
                                    }),
                                    valuePrimitive: true

                                })
                            },
                            showOperators: false
                        }
                    }},
                {
                    command:[
                        {name:"edit", text: "Επεξεργασία"}
                    ],
                    text: "Επεξεργασία",
                    title: "&nbsp;"
                }
            ],
            edit: function(e) {
                e.model.dirty = true;
                KendoGrid.defaultEditPopup(this._editContainer);
            },
            editable: "popup"
        };

        vm.clearPopupWindow = function() {
            vm.quantity = 1;
            vm.selectedProducts = [];
            vm.newPresentListProducts = [];
            vm.selectedPresentList = null;
        };

        vm.openCustomPopup = function(presentList){
            vm.initPopupGrid = true;

            var wdw = angular.element("#popup-new-grid").data("kendoWindow");
            vm.clearPopupWindow();

            vm.selectedPresentList = presentList;
            wdw.center().open();
            vm.statusChanged = "opened";
        };

        vm.closeCustomPopup = function() {
            var wdw = angular.element("#popup-new-grid").data("kendoWindow");
            delete vm.initPopupGrid;
            wdw.close();
            vm.statusChanged = "closed";
        };

        vm.savePresentListProducts = function() {
            var wdw = angular.element("#popup-new-grid").data("kendoWindow");

            vm.newPresentListProducts = [];
            vm.selectedProducts.forEach(function(item,index){
                vm.newPresentListProducts.push({
                    id: null,
                    presentList: {
                        id: vm.selectedPresentList.id
                    },
                    product: {
                        id: item.id
                    },
                    quantity: vm.quantity
                });
            });

            const listSize = vm.newPresentListProducts.length;
            $http.post('api/present-list-products/mass',  vm.newPresentListProducts)
                .success(function (response) {
                    KendoGrid.refresh(angular.element("#detailGrid").data("kendoGrid"));
                    wdw.close();
                    if (response.length === 0) {
                        Alerts.showAlert("Sigma SalesForce", "Δεν προστέθηκε κανένα Προϊον επειδή όλα τα Προϊοντα που επιλέξατε υπάρχουν ήδη στη λίστα δώρων.", "ΟΚ");
                    }else if (response.length < listSize) {
                        Alerts.showAlert("Sigma SalesForce", "Προστέθηκαν " + response.length + " από " + listSize + " Προϊοντα. Τα υπόλοιπα Προϊοντα δεν προτέθηκαν επειδή υπάρχουν ήδη στη λίστα δώρων.", "ΟΚ");
                    }else {
                        $mdToast.show(
                            $mdToast.simple()
                                .textContent('Επιτυχής Προσθήκη')
                                .position('bottom right')
                                .hideDelay(2500));
                    }
                })
                .error(function (response) {
                    console.log(response);
                    $mdToast.show(
                        $mdToast.simple()
                            .textContent('Κάποιο σφάλμα προέκυψε κατά την προσθήκη Προϊοντων στη λίστα δώρων')
                            .position('bottom right')
                            .hideDelay(2500));
                });
            vm.statusChanged = "saved";
        };

        vm.detailedGridOptions = function (dataItem) {
            if (dataItem !== undefined) {
                return {
                    dataSource: {
                        batch: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.filter.filters.push({field: "presentList.id", operator: "eq", value: dataItem.id});
                                $http.get('/api/present-list-products/search',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            },
                            create: function(o) {
                                var data = o.data.models[0];
                                data.presentList = {id : dataItem.id};

                                $http.post('/api/present-list-products', data)
                                    .success(function (response) {
                                        o.success(response);
                                        angular.element("#detailGrid").data("kendoGrid").dataSource.read();
                                        $mdToast.show(
                                            $mdToast.simple()
                                                .textContent('Επιτυχής προσθήκη')
                                                .position('bottom right')
                                                .hideDelay(2500));
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                        o.error(response);
                                        $mdToast.show(
                                            $mdToast.simple()
                                                .textContent($filter('translate')('global.messages.error.' + response.message))
                                                .position('bottom right')
                                                .hideDelay(2500));
                                    });
                            },
                            update: function(o) {
                                var data = o.data.models[0];
                                console.log(data);

                                $http.put('/api/present-list-products', data)
                                    .success(function (response) {
                                        o.success(response);
                                        angular.element("#detailGrid").data("kendoGrid").dataSource.read();
                                        $mdToast.show(
                                            $mdToast.simple()
                                                .textContent('Επιτυχής ενημέρωση')
                                                .position('bottom right')
                                                .hideDelay(2500));
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                        o.error(response);
                                        $mdToast.show(
                                            $mdToast.simple()
                                                .textContent($filter('translate')('global.messages.error.' + response.message))
                                                .position('bottom right')
                                                .hideDelay(2500));
                                    });
                            }
                        },
                        schema: {
                            data: function (data) {
                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: { editable: false, nullable: true },
                                    product: { defaultValue: {}},
                                    quantity: { editable: true, nullable: true, type: "number" },
                                    active: { editable: true, type: 'boolean' }
                                }
                            }
                        },
                        serverFiltering: true,
                        serverSorting: true,
                        serverPaging: true,
                        pageSize: 15
                    },
                    toolbar: [{ name: "custom-add",
                        text: "Προσθήκη Νέου Προιόν στη Λίστα",
                        template: "<input type='button' class='k-button' value = 'Προσθήκη Νέου Προιόν στη Λίστα' ng-click='vm.openCustomPopup(dataItem)' />" }],
                    pageable: true,
                    sortable: true,
                    filterable: {
                        mode: "row"
                    },
                    columns: [
                        {field: "product.name", title: "Προϊόν",
                            editor: function(container, options) {
                                angular.element('<select id="product-name" data-bind="value:product.id" k-ng-model="product"></select>')
                                    .appendTo(container)
                                    .kendoComboBox({
                                        dataSource: vm.productsDataSource,
                                        filter: "startswith",
                                        change: function(e) {
                                            var index = e.sender.selectedIndex;
                                            $('#product-code').data('kendoComboBox').select(index);
                                        },
                                        valuePrimitive: true,
                                        dataTextField: "name",
                                        dataValueField: "id"
                                    });
                            },
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }},
                        {field: "product.code", title: "Κωδικός Είδους",
                            editor: function(container, options) {
                                angular.element('<select id="product-code" data-bind="value:product.code"></select>')
                                    .appendTo(container)
                                    .kendoComboBox({
                                        dataSource: vm.productsDataSource,
                                        enable: false,
                                        dataTextField: "code"
                                    });
                            },
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }},
                        {field: "quantity", title: "Ποσότητα",
                            template: "#= (quantity == null) ? ' ' : kendo.format('{0:n0}', quantity)#",
                            filterable: {
                                cell: {
                                    operator: "eq",
                                    template: function (args) {
                                        args.element.kendoNumericTextBox({
                                            format: "{0:n0}"
                                        });
                                    },
                                    showOperators: false
                                }
                            }},
                        {template: '<md-checkbox class="checkbox-toolbar customers green inline"  aria-label="selectable" ng-model="dataItem.active" ' +
                            'ng-disabled="true"></md-checkbox>',
                            field: "active", title: "Ενεργό",
                            editor: function(container,options){
                                angular.element('<md-checkbox ng-model="dataItem.active"  aria-label="active" ' +
                                    'class="checkbox-toolbar customers green inline"></md-checkbox>')
                                    .appendTo(container);
                            },
                            filterable: {
                                cell: {
                                    operator: "eqbool",
                                    template: function (args) {
                                        args.element.kendoDropDownList({
                                            autoBind:false,
                                            dataTextField: "text",
                                            dataValueField: "value",
                                            dataSource: new kendo.data.DataSource({
                                                data: [{ text: "Ναι", value: "true" },
                                                    { text: "Όχι", value: "false" }]
                                            }),
                                            valuePrimitive: true

                                        })
                                    },
                                    showOperators: false
                                }
                            }},
                        {
                            command:[ {name:"edit", text: "Επεξεργασία"}],
                            title: "&nbsp;"
                        }
                    ],
                    editable: "popup",
                    edit: function(e) {
                        vm.editRow = e.model;
                        e.model.dirty = true;

                        var updateBtnTxt = "Αποθήκευση",
                            cancelBtnTxt = "Ακύρωση",
                            curr_container = this._editContainer;

                        //sets the text of the "Update" button
                        $(curr_container).find("a.k-grid-update").text(updateBtnTxt);
                        //sets the text of the "Cancel" button
                        $(curr_container).find("a.k-grid-cancel").text(cancelBtnTxt);
                    }
                };
            }
        };

        vm.selectedProducts = [];
        vm.popupProductsGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/masterdata/products',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: false }
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            sortable: true,
            pageable: true,
            selectable: true,
            scrolable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            columns: [
                {field: "name", title: "Προϊόν",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "code", title: "Κωδικός Είδους",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }}
            ],
            selectedItems: function(dataItems) {
                vm.selectedProducts = dataItems;
            },
            dataBound: function (e) {
                angular.element("#popupProductsGridcheckbox").prop('checked', false);
                //toggle class on click
                angular.element(".checkbox").bind("change", function (e) {
                    angular.element(e.target).closest("tr").toggleClass("k-state-selected");
                });

                var view = this.dataSource.data();
                var me = this;

                if (vm.selectedProducts) {
                    var selectedProductsViewCounter = 0;
                    angular.forEach( view, function(item) {
                        var foundItemById = $filter('filter')(vm.selectedProducts, {id: item.id}, true)[0];
                        if(vm.selectedProducts.indexOf(foundItemById) !== -1){
                            me.tbody.find("tr[data-uid='" + item.uid + "']")
                                .addClass("k-state-selected");
                            item.selected = true;
                            selectedProductsViewCounter++
                        }
                    });
                    if(selectedProductsViewCounter == view.length) {
                        angular.element("#popupProductsGridcheckbox").prop('checked', true);
                    }
                }
            }
        };
    }
})();
