(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('DoctorSpecialtyDialogController', DoctorSpecialtyDialogController);

    DoctorSpecialtyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DoctorSpecialty'];

    function DoctorSpecialtyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DoctorSpecialty) {
        var vm = this;
        vm.doctorSpecialty = entity;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:doctorSpecialtyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.doctorSpecialty.id !== null) {
                DoctorSpecialty.update(vm.doctorSpecialty, onSaveSuccess, onSaveError);
            } else {
                DoctorSpecialty.save(vm.doctorSpecialty, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
