(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PatchReportDeleteController',PatchReportDeleteController);

    PatchReportDeleteController.$inject = ['$uibModalInstance', 'entity', 'PatchReport'];

    function PatchReportDeleteController($uibModalInstance, entity, PatchReport) {
        var vm = this;
        vm.patchReport = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            PatchReport.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
