(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadParameterDeleteController',LeadParameterDeleteController);

    LeadParameterDeleteController.$inject = ['$uibModalInstance', 'entity', 'LeadParameter'];

    function LeadParameterDeleteController($uibModalInstance, entity, LeadParameter) {
        var vm = this;
        vm.leadParameter = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            LeadParameter.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
