(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadHospitalizationDeleteController',LeadHospitalizationDeleteController);

    LeadHospitalizationDeleteController.$inject = ['$uibModalInstance', 'entity', 'LeadHospitalization'];

    function LeadHospitalizationDeleteController($uibModalInstance, entity, LeadHospitalization) {
        var vm = this;
        vm.leadHospitalization = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            LeadHospitalization.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
