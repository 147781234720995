(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('WorkingDayDetailController', WorkingDayDetailController);

    WorkingDayDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'WorkingDay', 'CustomerSector'];

    function WorkingDayDetailController($scope, $rootScope, $stateParams, entity, WorkingDay, CustomerSector) {
        var vm = this;
        vm.workingDay = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:workingDayUpdate', function(event, result) {
            vm.workingDay = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
