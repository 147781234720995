/**
 * Created by Kristy on 4/10/2016.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminCustomerViewPatchReportsController', AdminCustomerViewPatchReportsController);

    AdminCustomerViewPatchReportsController.$inject = ['$http','$state', 'ApplicationParameters', 'PatchItemMasterFilter'];

    function AdminCustomerViewPatchReportsController ($http, $state, ApplicationParameters, PatchItemMasterFilter) {
        var vm = this;
        vm.selectedPatchItems = PatchItemMasterFilter.getSelectedPatchItems();
        ApplicationParameters.hasModule('Patches', true);

        vm.patchItemsMultiOptions = PatchItemMasterFilter.getPatchItemsMultiOptions();

        vm.toolbarOptions = {
            items: [
                { template: "<label>Επίθεμα: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.patchItemsMulti' multiple='multiple' " +
                "k-options='vm.patchItemsMultiOptions' k-ng-model='vm.selectedPatchItems'></select>", overflow: "never" }
            ]
        };
    }

})();
