/**
 * Created by Kristy Kavvada on 29/11/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('BeautyCenterViewCustomersController', BeautyCenterViewCustomersController);

    BeautyCenterViewCustomersController.$inject = ['$http', '$stateParams', 'Principal', 'Customer', 'CustomerSector', 'KendoGrid'];

    function BeautyCenterViewCustomersController ($http, $stateParams, Principal, Customer, CustomerSector, KendoGrid) {
        var vm = this;

        vm.canEditCustomerSector = Principal.hasAnyAuthority(['ROLE_SYSTEM', 'ROLE_ADMIN', 'ROLE_MANAGEMENT'])
        vm.selectedSectorId = $stateParams.id;
        vm.selectedCustomerTypes = [];
        vm.pageHeading = 'Επαφές Κέντρου Αισθητικής';
        vm.editableItem = {};

        var urls = {};
        urls.sectorCustomerSectors = '/api/sectors/'+vm.selectedSectorId+'/customer-sectors';

        // DataSources
        vm.dataSource = {};
        vm.dataSource.grid = {};
        vm.dataSource.grid.customerSector = new kendo.data.DataSource({
            autoBind: false,
            transport: {
                read: function (o) {
                    $http.get(urls.sectorCustomerSectors,{})
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {});
                },
                create: function (o) {
                    Customer.save(o.data.customer,
                        function(result) {
                            o.data.customer = result;
                            CustomerSector.save(o.data,
                                function() {
                                    vm.gridOptions.customerSector.dataSource.read();
                                }, onCustomerSectorSaveError);
                        }, onCustomerSaveError);
                },
                update: function (o) {
                    Customer.update(o.data.customer,
                        function(result) {
                            o.data.customer = result;
                            CustomerSector.update(o.data,
                                function() {
                                    vm.gridOptions.customerSector.dataSource.read();
                                }, onCustomerSectorSaveError);
                        }, onCustomerSaveError);
                }
            },
            schema: {
                data: function (data) {
                    if(data != undefined) {
                        data.forEach(function(item) {
                            item.types = "";
                            if (item.customer != null && item.customer.customerTypes != null) {
                                item.customer.customerTypes.forEach(function (type) {
                                    if (item.types === "") {
                                        item.types = type.name;
                                    } else {
                                        item.types = item.types + ", " + type.name;
                                    }
                                })
                            }
                        });
                    }
                    return data;
                },
                model: {
                    id: "id",
                    fields: {
                        id: { editable: false, nullable: true },
                        startedAt: { type: 'date', defaultValue: new Date()},
                        endedAt: { type: 'date', defaultValue : null},
                        customer: { defaultValue: {} }
                    }
                }
            },
            pageSize: 100,
            scrollable:false
        });

        // Grid Columns
        vm.gridColumns = {};
        vm.gridColumns.customerSector = [
            {
                hidden: !vm.canEditCustomerSector,
                command:
                    {
                        name: "edit",
                        text: ""
                    },
                title: "&nbsp;",
                width: 80
            },
            {
                field: "types",
                title: "Θέση",
                filterable: false
            },
            {
                field: "customer.lastName",
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.amka",
                title: "Α.Μ.Κ.Α.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.cellPhone",
                title: "Κινητό",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.phone",
                title: "Σταθερό Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.email",
                title: "Email",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            },
            {
                field: "startedAt",
                template: "#= startedAt ? kendo.toString(kendo.parseDate(startedAt), 'dddd, dd/MM/yyyy') : ''#",
                title: "Από",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            },
            {
                field: "endedAt",
                template: "#= endedAt ? kendo.toString(kendo.parseDate(endedAt), 'dddd, dd/MM/yyyy') : ''#",
                title: "Έως",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            }
        ];

        vm.customerTypes = [
            {id : 6, name : "ΥΠΕΥΘΥΝΟΣ ΚΕΝΤΡΟΥ"}
        ];

        vm.options = {};
        vm.options.customerTypes = {
            dataTextField: "name",
            dataValueField: "id",
            valuePrimitive: true,
            autoBind: true,
            dataSource: {
                data : vm.customerTypes
            }
        };

        vm.gridOptions = {};
        vm.gridOptions.customerSector = {
            dataSource: vm.dataSource.grid.customerSector,
            resizable: true,
            selectable: true,
            columns: vm.gridColumns.customerSector,
            toolbar: [ {name :"create", text: "Προσθήκη Νέας επαφής"} ],
            editable: {
                mode: "popup",
                template: kendo.template(angular.element("#popup-customer").html())
            },
            edit: function (e) {
                e.model.dirty = true;
                e.model.sector = { id: vm.selectedSectorId };
                if(e.model.isNew()) {
                    e.model.set("customer.customerTypes", vm.customerTypes);
                }

                KendoGrid.defaultEditPopup(this._editContainer);
            }
        };

        var onCustomerSaveError = function() {
            console.log("error on saving the customer");
        };

        var onCustomerSectorSaveError = function() {
            console.log("error on saving the customer sector");
        };
    }
})();
