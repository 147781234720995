(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('OfferStatusDetailController', OfferStatusDetailController);

    OfferStatusDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'OfferStatus'];

    function OfferStatusDetailController($scope, $rootScope, $stateParams, entity, OfferStatus) {
        var vm = this;
        vm.offerStatus = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:offerStatusUpdate', function(event, result) {
            vm.offerStatus = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
