/**
 * Created by Kristy on 14/9/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('SupervisorsService', SupervisorsService);

    SupervisorsService.$inject = ['$http', '$timeout'];

    function SupervisorsService($http, $timeout) {
        var selectedSupervisors = [];

        return {
            getSelectedSupervisors: function () {
                return selectedSupervisors;
            },
            setSelectedSupervisors: function (value) {
                selectedSupervisors = value;
            }
        };
    }
})();
