/**
 * Created by Teo on 26/03/2019.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('HospitalizationsAggregatedController', HospitalizationsAggregatedController);

    HospitalizationsAggregatedController.$inject = ['$state'];

    function HospitalizationsAggregatedController ($state) {
        var vm = this;
        vm.$state = $state;
        vm.pageHeading = "Συνολα Εισαγωγων";
    }
})();
