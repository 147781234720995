(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PatchItemDetailController', PatchItemDetailController);

    PatchItemDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PatchItem'];

    function PatchItemDetailController($scope, $rootScope, $stateParams, entity, PatchItem) {
        var vm = this;
        vm.patchItem = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:patchItemUpdate', function(event, result) {
            vm.patchItem = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
