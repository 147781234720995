/**
 * Created by Teo on 23/05/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('PdfBuilder', PdfBuilder);

    PdfBuilder.$inject = [];

    function PdfBuilder () {
        const LINEBREAK = '%0D%0A';

        return {
            createOffer : createOffer,
            makeFundOffer : makeFundOffer,
            makeFundOfferWithImages : makeFundOfferWithImages,
            makePrivateOffer: makePrivateOffer,
            makePrivateOfferWithImages: makePrivateOfferWithImages,
            makeReliefTherapyOffer : makeReliefTherapyOffer,
            makeReliefTherapyOfferWithImages : makeReliefTherapyOfferWithImages,
            makeDemoOffer : makeDemoOffer,
            makeLeadManagementAggregatedReport: makeLeadManagementAggregatedReport
        };

        function createOffer(object) {
            var lorem = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in suscipit purus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Vivamus nec hendrerit felis. Morbi aliquam facilisis risus eu lacinia. Sed eu leo in turpis fringilla hendrerit. Ut nec accumsan nisl. Suspendisse rhoncus nisl posuere tortor tempus et dapibus elit porta. Cras leo neque, elementum a rhoncus ut, vestibulum non nibh. Phasellus pretium justo turpis. Etiam vulputate, odio vitae tincidunt ultricies, eros odio dapibus nisi, ut tincidunt lacus arcu eu elit. Aenean velit erat, vehicula eget lacinia ut, dignissim non tellus. Aliquam nec lacus mi, sed vestibulum nunc. Suspendisse potenti. Curabitur vitae sem turpis. Vestibulum sed neque eget dolor dapibus porttitor at sit amet sem. Fusce a turpis lorem. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae;\nMauris at ante tellus. Vestibulum a metus lectus. Praesent tempor purus a lacus blandit eget gravida ante hendrerit. Cras et eros metus. Sed commodo malesuada eros, vitae interdum augue semper quis. Fusce id magna nunc. Curabitur sollicitudin placerat semper. Cras et mi neque, a dignissim risus. Nulla venenatis porta lacus, vel rhoncus lectus tempor vitae. Duis sagittis venenatis rutrum. Curabitur tempor massa tortor.';
            // create a document and pipe to a blob
            var doc = new PDFDocument();
            var stream = doc.pipe(blobStream());

            // draw some text
            doc.fontSize(25)
                .text('Here is some vector graphics...', 100, 80);

            // some vector graphics
            doc.save()
                .moveTo(100, 150)
                .lineTo(100, 250)
                .lineTo(200, 250)
                .fill("#FF3300");

            doc.circle(280, 200, 50)
                .fill("#6600FF");

            // an SVG path
            doc.scale(0.6)
                .translate(470, 130)
                .path('M 250,75 L 323,301 131,161 369,161 177,301 z')
                .fill('red', 'even-odd')
                .restore();

            // and some justified text wrapped into columns
            doc.text('And here is some wrapped text...', 100, 300)
                .font('Times-Roman', 13)
                .moveDown()
                .text(lorem, {
                    width: 412,
                    align: 'justify',
                    indent: 30,
                    columns: 2,
                    height: 300,
                    ellipsis: true
                });

            // end and display the document in the iframe to the right
            doc.end();
            stream.on('finish', function() {
                var file = stream.toBlobURL('application/pdf');
                window.open(file);
            });
        }

        /**
         * Builds a pdf document depending on values of object and
         * displays it in new browser tab. Original doc: fund_hospitalization.pdf.
         * @param object The offer object. Object's structure should be:
         * object {
         *   date: Date
         *   fullName: String,
         *   pdfPronouncement: String,
         *   attendantMealPrice: Double
         *   transportation: Transportation object
         *   roomCost: RoomCost object
         *   finalDiscount: DiscountRate object
         * }
         */
        function makeFundOffer(object)  {
            var doc = new PDFDocument({
                margin: 50
            });
            doc.info['Title'] = 'Προσφορά ' + object.fullName;
            doc.info['Author'] = 'EUROMEDICA-ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε.';
            var stream = doc.pipe(blobStream());
            var logoImage = null;
            var certImage = null;
            var statsImage = null;

            // Load custom font for Greek chars encoding problem
            var oReq = new XMLHttpRequest();
            //oReq.open("GET", "content/fonts/NotoSans-Regular.ttf", true);
            oReq.open("GET", "content/fonts/arial.ttf", true);
            oReq.responseType = "arraybuffer";
            oReq.onload = function(oEvent) {
                var arrayBuffer = oReq.response; // Note: not oReq.responseText
                if (arrayBuffer) {
                    doc.registerFont('mArial', arrayBuffer);
                    doc.font('mArial');

                    toDataURL('content/images/arogi_logo.jpg', function (dataUrl) {
                        logoImage = dataUrl;
                        toDataURL('content/images/arogi_cert.jpg', function (dataUrl) {
                            certImage = dataUrl;
                            toDataURL('content/images/arogi_stats.jpg', function (dataUrl) {
                                statsImage = dataUrl;
                                // Got all images, build the document
                                /****************
                                 *** 1st Page ***
                                 ****************/
                                doc.image(logoImage, {
                                    fit: [160, 210],
                                    align: 'left'
                                });
                                doc.image(certImage, 450, 40, {
                                    fit: [80, 80],
                                    align: 'right'
                                });

                                doc.font('mArial', 11)
                                    .text('Θεσσαλονίκη, ' + moment(object.date).format('DD/MM/YYYY'), 400, 140);
                                //doc.moveDown(2);
                                doc.font('mArial', 11)
                                    .text(object.pdfPronouncement, 30, 170);
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Αρχικά θα θέλαμε να σας ευχαριστήσουμε για την εμπιστοσύνη σας στις υπηρεσίες του κέντρου' +
                                        ' Αποκατάστασης και Αποθεραπείας Euromedica – Αρωγή Θεσσαλονίκης.');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Στη συνέχεια ακολουθεί μια σύντομη παρουσίαση του Κέντρου και περιγραφή των παροχών αυτού,' +
                                        ' προς τους νοσηλευόμενους ασθενείς.');
                                doc.moveDown(2);
                                doc.font('mArial', 15)
                                    .text('Πρότυπο Κέντρο στην Αποκατάσταση και Αποθεραπεία.');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Η Euromedica-Αρωγή Θεσσαλονίκης, το πρώτο και μοναδικό Κέντρο Αποκατάστασης στην' +
                                        ' περιοχή της Νοτιοανατολικής Ευρώπης, ανήκει στην επίλεκτη παγκόσμια ομάδα των 543 Κέντρων' +
                                        ' Αποκατάστασης, εκ των οποίων τα 517 στις Ηνωμένες Πολιτείες Αμερικής, τα οποία έχουν λάβει τη' +
                                        ' Διεθνή Διαπίστευση CARF, στην κατηγορία:');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('«Inpatient Rehabilitation Programmes – Hospital»', {align: 'center'});
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Η διαπίστευση αυτή, αποτελεί το υψηλότερο επίπεδο παγκόσμιας πιστοποίησης που απονέμεται σε' +
                                        ' ένα Κέντρο Αποκατάστασης και καταδεικνύει την υψηλή ποιότητα παρεχόμενων προγραμμάτων' +
                                        ' αποκατάστασης.');
                                doc.moveDown(2);
                                doc.font('mArial', 11)
                                    .text('Στο Κέντρο Αποκατάστασης και Αποθεραπείας Euromedica–Αρωγή Θεσσαλονίκης προσφέρουμε' +
                                        ' από το 2010 εξατομικευμένα προγράμματα αποκατάστασης και αποθεραπείας, προσεγγίζοντας' +
                                        ' ολιστικά τους ασθενείς με οξείες ή χρόνιες παθήσεις.');
                                doc.moveDown(2);
                                doc.font('mArial', 11)
                                    .text('Ως κύριο στόχο μας θέσαμε την ανάπτυξη μίας σύγχρονης αντίληψης για την Αποκατάσταση που' +
                                        ' θα συνεισφέρει στην προαγωγή της καλής υγείας και στον σεβασμό των ανθρώπων που πάσχουν' +
                                        ' και συγχρόνως, πασχίζουν να γίνουν καλά.');
                                doc.font('mArial', 8)
                                    .text('ΔΔ/36.2/01.03.2017', 30, 700);
                                doc.moveDown(1);
                                doc.font('mArial', 8)
                                    .text('Euromedica-Αρωγή ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε., Κέντρο Ιατρικής Αποθεραπείας και Αποκατάστασης', {align: 'center'});
                                doc.font('mArial', 8)
                                    .text('τέρμα 17ης Νοέμβρη, Πυλαία 54301 Θεσσαλονίκη', 100, 730, {continued: true})
                                    .font('Times-Roman', 8)
                                    .text('T. 2310986000 F. 2310986080 www.euromedica-arogi.gr', 104, 732);

                                /****************
                                 *** 2nd Page ***
                                 ****************/

                                doc.font('mArial', 15)
                                    .text('Οραμά μας είναι η Καλύτερη Υγεία για Ολους.', 30);
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Θέλουμε να ξεπερνούμε τις στατιστικές και τα προγνωστικά, εμπνέοντας δύναμη και ελπίδα. Να' +
                                        ' θέτουμε ρεαλιστικούς στόχους προόδου και να τους υπερβαίνουμε. Να είμαστε για τους ασθενείς' +
                                        ' μας, τις οικογένειές τους και την ιατρική κοινότητα σημείο αναφοράς στη φυσική ιατρική και' +
                                        ' Αποκατάσταση στην Ελλάδα και στη Νοτιοανατολική Ευρώπη.');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Η ειδική Ιατρική Ομάδα Αποκατάστασης σχεδιάζει και κάνει πράξη την αποθεραπεία των ασθενών' +
                                        ' μας. Τα μέλη της συνεδριάζουν εβδομαδιαία και συνεργάζονται στενά γιa την πρόοδο κάθε' +
                                        ' ασθενούς.');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Ο φυσίατρος είναι ο επικεφαλής ειδικός Επαγγελματίας Υγείας της Ομάδας Αποκατάστασης, ο οποίος' +
                                        ' προσεγγίζει ολιστικά τους ασθενείς, εκτιμά την κατάστασή τους, θέτει μαζί τους στόχους και' +
                                        ' επιβλέπει την εξέλιξη της αποθεραπείας τους, συντονίζοντας την ειδική Ιατρική Ομάδα' +
                                        ' Αποκατάστασης που αποτελείται από Ιατρό φυσικής Αποκατάστασης σε κάθε πτέρυγα νοσηλείας,' +
                                        ' Παθολόγο σε κάθε πτέρυγα νοσηλείας, Καρδιολόγο, Ορθοπεδικό, Εντατικολόγο, Νευρολόγο και' +
                                        ' συνεργαζόμενους εξωτερικούς επαγγελματίες υγείας όλων των ειδικοτήτων ανάλογα με την ανάγκη που θα' +
                                        ' προκύψει για κάθε ασθενή.');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Στο κέντρο Euromedica-Αρωγή Θεσσαλονίκης κάθε πρόγραμμα φυσικής Ιατρικής και' +
                                        ' Αποκατάστασης που εφαρμόζεται σχεδιάζεται με επίκεντρο τον ασθενή και τις ανάγκες του και' +
                                        ' μπορεί να συνδυάσει ένα εύρος υπηρεσιών από τα διαφορετικά τμήματα του Κέντρου:');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- τομέας φυσιοθεραπείας');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- τομέας υδροθεραπείας');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- τομέας εργοθεραπείας');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- τομέας λογοθεραπείας');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- τομέας ψυχολογικής υποστήριξης');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- τομέας διατροφής');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('το κέντρο Euromedica-Αρωγή Θεσσαλονίκης βρίσκεται σε απόσταση 10 λεπτών από το εμπορικό κέντρο' +
                                        ' της πόλης σε έναν κτίριο ', {continued: true})
                                    .font('Times-Roman', 13)
                                    .text('15.947 ', {continued: true})
                                    .font('mArial', 11)
                                    .text('τ.μ. που διαθέτει:');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- 200 κλίνες');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- 4.500 τ.μ. χώρους θεραπειών');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- 1.200 κλειστούς χώρους αθλητικών δραστηριοτήτων ΑΜΕΑ');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- 3 πισίνες και 1 υδατοδιάδρομος');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- 170 θέσεις στάθμευσης');
                                doc.font('mArial', 8)
                                    .text('ΔΔ/36.2/01.03.2017', 30, 700);
                                doc.moveDown(1);
                                //doc.moveDown(1);
                                doc.font('mArial', 8)
                                    .text('Euromedica-Αρωγή ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε., Κέντρο Ιατρικής Αποθεραπείας και Αποκατάστασης', {align: 'center'});
                                doc.font('mArial', 8)
                                    .text('τέρμα 17ης Νοέμβρη, Πυλαία 54301 Θεσσαλονίκη', 100, 730, {continued: true})
                                    .font('Times-Roman', 8)
                                    .text('T. 2310986000 F. 2310986080 www.euromedica-arogi.gr', 104, 732);

                                /****************
                                 *** 3rd Page ***
                                 ****************/

                                doc.font('mArial', 11)
                                    .text('Σε συνέχεια της επικοινωνίας μας , σας ενημερώνω ότι το κόστος της ημερήσιας συμμετοχής' +
                                        ' σχετικά με την νοσηλεία ασθενούς στο Κέντρο μας καθορίζεται ανάλογα με την επιλογή του τύπου' +
                                        ' του δωματίου και διαμορφώνεται ως εξής:', 30);
                                doc.moveDown(1);

                                // Draw a table
                                doc.lineCap('butt')
                                    .moveTo(270, 100)
                                    .lineTo(270, 240)
                                    .stroke();

                                var coordinates = {
                                    rowHeight: 100,
                                    leftHeight: 105,
                                    rightHeight: 105
                                };

                                row(doc, coordinates.rowHeight);
                                textInRowFirst(doc, 'Υπηρεσίες / Δωμάτιο', coordinates.leftHeight, 90);
                                textInRowSecond(doc, 'χρέωση / ημερα', coordinates.rightHeight, 350);
                                coordinates = addToCoordinates(coordinates, 20);
                                row(doc, coordinates.rowHeight);
                                textInRowFirst(doc, 'Νοσηλεία σε τρίκλινο δωμάτιο', coordinates.leftHeight, 30);
                                textInRowSecondForNumbers(doc, '€39', coordinates.rightHeight, 390);
                                coordinates = addToCoordinates(coordinates, 20);
                                row(doc, coordinates.rowHeight);
                                textInRowFirst(doc, 'Νοσηλεία σε δίκλινο δωμάτιο', coordinates.leftHeight, 30);
                                textInRowSecondForNumbers(doc, '€49', coordinates.rightHeight, 390);
                                coordinates = addToCoordinates(coordinates, 20);
                                row(doc, coordinates.rowHeight);
                                textInRowFirst(doc, 'Νοσηλεία σε μονόκλινο δωμάτιο', coordinates.leftHeight, 30);
                                textInRowSecondForNumbers(doc, '€110', coordinates.rightHeight, 390);
                                coordinates = addToCoordinates(coordinates, 20);
                                row(doc, coordinates.rowHeight);
                                textInRowFirst(doc, 'Νοσηλεία σε μονόκλινο δωμάτιο με συνοδό', coordinates.leftHeight, 30);
                                textInRowSecondForNumbers(doc, '€135', coordinates.rightHeight, 390);
                                coordinates = addToCoordinates(coordinates, 20);
                                row(doc, coordinates.rowHeight);
                                textInRowFirst(doc, 'Γεύμα συνοδού', coordinates.leftHeight, 30);
                                textInRowSecondForNumbers(doc, '€15', coordinates.rightHeight, 390);
                                coordinates = addToCoordinates(coordinates, 20);
                                row(doc, coordinates.rowHeight);
                                textInRowFirst(doc, 'Νοσηλεία σε ΧΑΦ', coordinates.leftHeight, 30);
                                textInRowSecondForNumbers(doc, '€220', coordinates.rightHeight, 390);

                                doc.font('mArial', 11)
                                    .text('Στις παραπάνω τιμές συμπεριλαμβάνεται ο Φ.Π.Α. ', 30, 250, {continued: true})
                                    .font('Times-Roman', 13)
                                    .text('(24%)', {continued: true})
                                    .font('mArial', 11)
                                    .text('. Η εξόφληση των νοσηλίων\nπραγματοποιείται κάθε 10 ημέρες νοσηλείας.');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Στο αναγραφόμενο κόστος συμπεριλαμβάνονται:');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- Η διαμονή');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- Πλήρης διατροφή με 3 γεύματα την ημέρα');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- 24ωρη νοσηλευτική φροντίδα');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- Η φαρμακευτική αγωγή που συνταγογραφείται');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- Υγειονομικό υλικό όπως πάνες, γάζες, σύριγγες κ.τ.λ.');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- Οι διαγνωστικές εξετάσεις ρουτίνας καθ’ όλη τη διάρκεια νοσηλείας');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- Συμμετοχή σε καθημερινό πρόγραμμα φυσιοθεραπευτικής αποκατάστασης Δευτέρα έως');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- Συμμετοχή σε καθημερινό πρόγραμμα φυσιοθεραπευτικής αποκατάστασης Δευτέρα έως και Σάββατο, με    4-5 συνεδρίες την ημέρα το οποίο μπορεί να περιλαμβάνει:');
                                doc.moveDown(1);
                                // inside list
                                doc.font('mArial', 11)
                                    .text('  - Φυσιοθεραπεία: Τεχνικές: ', 70, 447, {continued: true})
                                    .font('Times-Roman', 11)
                                    .text('BOBATH – PNF - MANUAL – MULLIGAN', 70, 449);
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('  - Εργοθεραπεία: Ρομποτικό ', 70, 461, {continued: true})
                                    .font('Times-Roman', 11)
                                    .text('ARMEO (', 70, 463, {continued: true})
                                    .font('mArial', 11)
                                    .text('κινητοποίηση άνω άκρου', 70, 461, {continued: true})
                                    .font('Times-Roman', 11)
                                    .text(')', 70, 463, {continued: true});
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('  – Αισθητηριακή Ολοκλήρωση', 70, 461);
                                doc.moveDown(1);
                                doc.font('Times-Roman', 11)
                                    .text('- LOKOMAT: ', 70, 476, {continued: true})
                                    .font('mArial', 11)
                                    .text('Ρομποτικό κινητοποίησης κάτω άκρων', 70, 474);

                                doc.font('Times-Roman', 11)
                                    .text('- REHACOM: ', 70, 489, {continued: true})
                                    .font('mArial', 11)
                                    .text('Αξιολόγηση και θεραπεία γνωσιακών ελλειμμάτων', 70, 487);

                                doc.font('Times-Roman', 11)
                                    .text('- BALANCE: ', 70, 501, {continued: true})
                                    .font('mArial', 11)
                                    .text('Εκπαίδευση ιδιοδεκτικότητας και συντονισμού στην όρθια θέση', 70, 499);

                                doc.font('mArial', 11)
                                    .text('- Άσκηση σε εικονικό περιβάλλον ', 70, 511, {continued: true})
                                    .font('Times-Roman', 11)
                                    .text('(Wii):', 70, 514, {continued: true})
                                    .font('mArial', 11)
                                    .text(' Επανεκπαίδευση χρονισμού των κινητικών αλυσίδων', 70, 511);

                                /*doc.font('mArial', 11)
                                    .text('- Λογοθεραπεία: Εκπαίδευση κατάποσης και διαχείριση τραχειοστομίας', 70, 523);

                                doc.font('mArial', 11)
                                    .text('- Υδροθεραπεία: Τεχνικές: ', 70, 535, {continued: true})
                                    .font('Times-Roman', 11)
                                    .text('HALLIWICK – BAD RAGAZ RING METHOD', 70, 537);

                                doc.font('mArial', 11)
                                    .text('- Χρήση εξειδικευμένων μηχανημάτων: ', 70, 547, {continued: true})
                                    .font('Times-Roman', 11)
                                    .text('TILT, CPM, ROLLATOR', 70, 549);

                                doc.font('mArial', 11)
                                    .text('- Πλήρως εξοπλισμένο γυμναστήριο', 70, 559);

                                doc.font('mArial', 11)
                                    .text('- Θεραπευτική άσκηση : ', 70, 571, {continued: true})
                                    .font('Times-Roman', 11)
                                    .text('YOGA, PILATES', 70, 573);

                                doc.font('mArial', 11)
                                    .text('Ο αριθμός και το είδος των θεραπειών καθορίζεται σύμφωνα με τις ανάγκες του ατόμου και' +
                                        ' κατόπιν αξιολόγησης της Ομάδας Αποκατάστασης.', 36);
                                doc.font('mArial', 11)
                                    .text('- Καθημερινή ιατρική παρακολούθηση από Ιατρό Φυσικής Αποκατάστασης και Παθολόγο' +
                                        ' και στην αξιολόγηση που απαιτείται από Νευρολόγο, Καρδιολόγο και Ορθοπεδικό' +
                                        ' - Διατροφολογική και ψυχολογική υποστήριξη', 30);*/

                                doc.font('mArial', 8)
                                    .text('ΔΔ/36.2/01.03.2017', 30, 700);
                                doc.moveDown(1);
                                doc.font('mArial', 8)
                                    .text('Euromedica-Αρωγή ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε., Κέντρο Ιατρικής Αποθεραπείας και Αποκατάστασης', {align: 'center'});
                                doc.font('mArial', 8)
                                    .text('τέρμα 17ης Νοέμβρη, Πυλαία 54301 Θεσσαλονίκη', 100, 730, {continued: true})
                                    .font('Times-Roman', 8)
                                    .text('T. 2310986000 F. 2310986080 www.euromedica-arogi.gr', 104, 732);

                                /****************
                                 *** 4th Page ***
                                 ****************/
                                doc.font('mArial', 11)
                                    .text('Δεν συμπεριλαμβάνονται στα ημερήσια νοσήλια:', 30);
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- Εξειδικευμένες διαγνωστικές εξετάσεις που τυχόν απαιτηθούν κατά τη διάρκεια της' +
                                        '  όπως νοσηλείας καλλιέργειες, αντιβιογράμματα, ακτινογραφίες κ.τ.λ.' +
                                        ' - Η φαρμακευτική αγωγή που δεν συνταγογραφείται' +
                                        ' - Νοσηλεία στο Χώρο Αυξημένης Φροντίδας' +
                                        ' - Μεταφορά με ασθενοφόρο' +
                                        ' - Οι επισκέψεις εξωτερικών συνεργαζόμενων επαγγελματιών υγείας' +
                                        ' - Γεύματα συνοδών όπως ημερήσια χρέωση €15 για 3 γεύματα' +
                                        ' - Αναλώσιμα υλικά όπως αλλαγή γαστροστομίας, τραχειοστομίας κ.τ.λ.' +
                                        ' - Τυχόν βοηθήματα βάδισης' +
                                        ' - Η χρήση αποκλειστικής νοσοκόμας');
                                doc.moveDown(1);

                                doc.font('mArial', 11)
                                    .text("", 30);
                                doc.font('mArial', 11)
                                    .text('Η διάρκεια παραμονής των ασθενών στο Κέντρο Euromedica Αρωγή κυμαίνεται από μερικές' +
                                        ' ημέρες έως αρκετές εβδομάδες, ανάλογα με τις ατομικές ανάγκες και την πρόοδο που' +
                                        ' παρουσιάζουν. Το Κέντρο Euromedica Αρωγή τηρεί για τον κάθε ασθενή τα χρονοδιαγράμματα' +
                                        ' που έχουν καθοριστεί από τον Εθνικό Οργανισμό Παροχής Υπηρεσιών Υγείας ΕΟΠΥΥ. Σύμφωνα' +
                                        ' με τα επίσημα στοιχεία του κέντρου, το 2017, η μέση διάρκεια παραμονής ήταν ', {continued: true})
                                    .font('Times-Roman', 13)
                                    .text('29,99 ', {continued: true})
                                    .font('mArial', 11)
                                    .text('ημέρες,\n' +
                                        ' δηλαδή αρκετά κάτω από τις κατευθυντήριες γραμμές του ΕΟΠΥΥ, χαμηλότερα και από τις 31,01' +
                                        ' ημέρες του έτους 2016.');
                                doc.moveDown(1);
                                doc.image(statsImage, {
                                    fit: [450, 750],
                                    align: 'left'
                                });
                                doc.font('mArial', 8)
                                    .text('ΔΔ/36.2/01.03.2017', 30, 700);
                                doc.moveDown(1);
                                doc.font('mArial', 8)
                                    .text('Euromedica-Αρωγή ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε., Κέντρο Ιατρικής Αποθεραπείας και Αποκατάστασης', {align: 'center'});
                                doc.font('mArial', 8)
                                    .text('τέρμα 17ης Νοέμβρη, Πυλαία 54301 Θεσσαλονίκη', 100, 730, {continued: true})
                                    .font('Times-Roman', 8)
                                    .text('T. 2310986000 F. 2310986080 www.euromedica-arogi.gr', 104, 732);

                                /****************
                                 *** 5th Page ***
                                 ****************/
                                doc.font('mArial', 11)
                                    .text('Το πρόγραμμα αποκατάστασης που θα ακολουθηθεί και ο τύπος και ο αριθμός των θεραπειών' +
                                        ' καθορίζεται σύμφωνα με τις ανάγκες του εκάστοτε ασθενούς και κατόπιν αξιολόγησης της Ομάδας' +
                                        ' Αποκατάστασης κατά την εισαγωγή του ασθενούς. Το ατομικό πρόγραμμα αποκατάστασης' +
                                        ' κοινοποιείται εγγράφως στον ασθενή και στους συνοδούς σε εβδομαδιαία βάση.', 30);
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Είμαι στη διάθεσή σας για οποιαδήποτε διευκρίνιση ή απορία προκύπτει από την ως άνω' +
                                        ' πληροφόρηση στην ηλεκτρονική διεύθυνση ', {continued: true})
                                    .font('Times-Roman', 13)
                                    .text("s.bakatselou@euromedica.gr ", {continued: true})
                                    .font('mArial', 11)
                                    .text("ή στα τηλέφωνα ", {continued: true})
                                    .font("Times-Roman", 13)
                                    .text("\n2310 986150.");
                                doc.moveDown(2);
                                doc.font('mArial', 11)
                                    .text('Με εκτίμηση,');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Σταυριάνα Μπακατσέλου' +
                                        ' Διευθύντρια Υποδοχής και Κίνησης Ασθενών');
                                doc.moveDown(1);
                                doc.font('mArial', 8)
                                    .text('ΔΔ/36.2/01.03.2017', 30, 700);
                                doc.moveDown(1);
                                doc.font('mArial', 8)
                                    .text('Euromedica-Αρωγή ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε., Κέντρο Ιατρικής Αποθεραπείας και Αποκατάστασης', {align: 'center'});
                                doc.font('mArial', 8)
                                    .text('τέρμα 17ης Νοέμβρη, Πυλαία 54301 Θεσσαλονίκη', 100, 730, {continued: true})
                                    .font('Times-Roman', 8)
                                    .text('T. 2310986000 F. 2310986080 www.euromedica-arogi.gr', 104, 732);

                                doc.end();
                                stream.on('finish', function() {
                                    var file = stream.toBlobURL('application/pdf');
                                    window.open(file);
                                });
                            });
                        });
                    });
                }
            };
            oReq.send(null);
        }

        function makeFundOfferWithImages(object) {
            var doc = new PDFDocument({
                margin: 45
            });
            doc.info['Title'] = 'Προσφορά ' + object.fullName;
            doc.info['Author'] = 'EUROMEDICA-ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε.';
            var stream = doc.pipe(blobStream());
            var logoImage = null;
            var certImage = null;
            var page1Image = null;
            var page2Image = null;
            var page3Image = null;
            var page4Image = null;
            var page5Image = null;

            // Load custom font for Greek chars encoding problem
            var oReq = new XMLHttpRequest();
            oReq.open("GET", "content/fonts/arial.ttf", true);
            oReq.responseType = "arraybuffer";
            oReq.onload = function(oEvent) {
                var arrayBuffer = oReq.response; // Note: not oReq.responseText
                if (arrayBuffer) {
                    doc.registerFont('mArial', arrayBuffer);
                    doc.font('mArial');

                    toDataURL('content/images/arogi_logo.jpg', function (dataUrl) {
                        logoImage = dataUrl;
                        toDataURL('content/images/arogi_cert.jpg', function (dataUrl) {
                            certImage = dataUrl;
                            toDataURL('content/images/fund_page1.jpg', function (dataUrl) {
                                page1Image = dataUrl;
                                toDataURL('content/images/fund_page2.jpg', function (dataUrl) {
                                    page2Image = dataUrl;
                                    toDataURL('content/images/fund_page3.jpg', function (dataUrl) {
                                        page3Image = dataUrl;
                                        toDataURL('content/images/fund_page4.jpg', function (dataUrl) {
                                            page4Image = dataUrl;
                                            toDataURL('content/images/fund_page5.jpg', function (dataUrl) {
                                                page5Image = dataUrl;
                                                /****************
                                                 *** 1st Page ***
                                                 ****************/
                                                doc.image(logoImage, {
                                                    fit: [160, 210],
                                                    align: 'left'
                                                });
                                                doc.image(certImage, 450, 40, {
                                                    fit: [80, 80],
                                                    align: 'right'
                                                });

                                                doc.font('mArial', 11)
                                                    .text('Θεσσαλονίκη, ' + moment(object.date).format('DD/MM/YYYY'), 400, 140);
                                                //doc.moveDown(2);
                                                doc.font('mArial', 11)
                                                    .text(object.pdfPronouncement, 55, 170);
                                                doc.moveDown(1);
                                                doc.image(page1Image, {
                                                    fit: [550, 600],
                                                    align: 'left'
                                                });
                                                /****************
                                                 *** 2nd Page ***
                                                 ****************/
                                                doc.addPage();
                                                doc.image(page2Image, {
                                                    fit: [800, 730],
                                                    align: 'left'
                                                });
                                                /****************
                                                 *** 3rd Page ***
                                                 ****************/
                                                doc.addPage();
                                                doc.image(page3Image, {
                                                    fit: [800, 730],
                                                    align: 'left'
                                                });
                                                /****************
                                                 *** 4th Page ***
                                                 ****************/
                                                doc.addPage();
                                                doc.image(page4Image, {
                                                    fit: [800, 730],
                                                    align: 'left'
                                                });
                                                /****************
                                                 *** 5th Page ***
                                                 ****************/
                                                doc.addPage();
                                                doc.image(page5Image, {
                                                    fit: [800, 730],
                                                    align: 'left'
                                                });


                                                doc.end();
                                                stream.on('finish', function() {
                                                    var file = stream.toBlobURL('application/pdf');
                                                    window.open(file);
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                }
            };
            oReq.send(null);
        }

        /**
         * Builds a pdf document depending on values of object and
         * displays it in new browser tab. Original doc: private_hospitalization.pdf
         * @param object The offer object. Object's structure should be:
         * object {
         *   date: Date
         *   fullName: String,
         *   pdfPronouncement: String,
         *   attendantMealPrice: Double
         *   transportation: Transportation object
         *   roomCost: RoomCost object
         *   finalDiscount: DiscountRate object
         * }
         */
        function makePrivateOffer(object) {
            var doc = new PDFDocument({
                margin: 50
            });
            doc.info['Title'] = 'Προσφορά ' + object.fullName;
            doc.info['Author'] = 'EUROMEDICA-ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε.';
            var stream = doc.pipe(blobStream());
            var logoImage = null;
            var certImage = null;
            var statsImage = null;

            // Load custom font for Greek chars encoding problem
            var oReq = new XMLHttpRequest();
            oReq.open("GET", "content/fonts/arial.ttf", true);
            oReq.responseType = "arraybuffer";
            oReq.onload = function(oEvent) {
                var arrayBuffer = oReq.response; // Note: not oReq.responseText
                if (arrayBuffer) {
                    doc.registerFont('mArial', arrayBuffer);
                    doc.font('mArial');

                    toDataURL('content/images/arogi_logo.jpg', function (dataUrl) {
                        logoImage = dataUrl;
                        toDataURL('content/images/arogi_cert.jpg', function (dataUrl) {
                            certImage = dataUrl;
                            toDataURL('content/images/arogi_stats.jpg', function (dataUrl) {
                                statsImage = dataUrl;
                                // Got all images, build the document
                                /****************
                                 *** 1st Page ***
                                 ****************/
                                doc.image(logoImage, {
                                    fit: [160, 210],
                                    align: 'left'
                                });
                                doc.image(certImage, 450, 40, {
                                    fit: [80, 80],
                                    align: 'right'
                                });

                                doc.font('mArial', 11)
                                    .text('Θεσσαλονίκη, ' + moment(object.date).format('DD/MM/YYYY'), 400, 140);
                                //doc.moveDown(2);
                                doc.font('mArial', 11)
                                    .text(object.pdfPronouncement, 30, 170);
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Αρχικά θα θέλαμε να σας ευχαριστήσουμε για την εμπιστοσύνη σας στις υπηρεσίες του κέντρου' +
                                        ' Αποκατάστασης και Αποθεραπείας Euromedica – Αρωγή Θεσσαλονίκης.');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Στη συνέχεια ακολουθεί μια σύντομη παρουσίαση του Κέντρου και περιγραφή των παροχών αυτού,' +
                                        ' προς τους νοσηλευόμενους ασθενείς.');
                                doc.moveDown(2);
                                doc.font('mArial', 15)
                                    .text('Πρότυπο Κέντρο στην Αποκατάσταση και Αποθεραπεία.');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Η Euromedica-Αρωγή Θεσσαλονίκης, το πρώτο και μοναδικό Κέντρο Αποκατάστασης στην' +
                                        ' περιοχή της Νοτιοανατολικής Ευρώπης, ανήκει στην επίλεκτη παγκόσμια ομάδα των 543 Κέντρων' +
                                        ' Αποκατάστασης, εκ των οποίων τα 517 στις Ηνωμένες Πολιτείες Αμερικής, τα οποία έχουν λάβει τη' +
                                        ' Διεθνή Διαπίστευση CARF, στην κατηγορία:');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('«Inpatient Rehabilitation Programmes – Hospital»', {align: 'center'});
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Η διαπίστευση αυτή, αποτελεί το υψηλότερο επίπεδο παγκόσμιας πιστοποίησης που απονέμεται σε' +
                                        ' ένα Κέντρο Αποκατάστασης και καταδεικνύει την υψηλή ποιότητα παρεχόμενων προγραμμάτων' +
                                        ' αποκατάστασης.');
                                doc.moveDown(2);
                                doc.font('mArial', 11)
                                    .text('Στο Κέντρο Αποκατάστασης και Αποθεραπείας Euromedica–Αρωγή Θεσσαλονίκης προσφέρουμε' +
                                        ' από το 2010 εξατομικευμένα προγράμματα αποκατάστασης και αποθεραπείας, προσεγγίζοντας' +
                                        ' ολιστικά τους ασθενείς με οξείες ή χρόνιες παθήσεις.');
                                doc.moveDown(2);
                                doc.font('mArial', 11)
                                    .text('Ως κύριο στόχο μας θέσαμε την ανάπτυξη μίας σύγχρονης αντίληψης για την Αποκατάσταση που' +
                                        ' θα συνεισφέρει στην προαγωγή της καλής υγείας και στον σεβασμό των ανθρώπων που πάσχουν' +
                                        ' και συγχρόνως, πασχίζουν να γίνουν καλά.');
                                doc.font('mArial', 8)
                                    .text('ΔΔ/36.2/01.03.2017', 30, 700);
                                doc.moveDown(1);
                                doc.font('mArial', 8)
                                    .text('EUROMEDICA-ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε., Κέντρο Ιατρικής Αποθεραπείας και Αποκατάστασης', {align: 'center'});
                                doc.font('mArial', 8)
                                    .text('Τέρμα 17ης Νοέμβρη, Πυλαία 54301 Θεσσαλονίκη', 100, 730, {continued: true})
                                    .font('Times-Roman', 8)
                                    .text('T. 2310986000 F. 2310986080 www.euromedica-arogi.gr', 104, 732);

                                /****************
                                 *** 2nd Page ***
                                 ****************/

                                doc.font('mArial', 15)
                                    .text('Όραμά μας είναι η Καλύτερη Υγεία για Όλους.', 30);
                                doc.moveDown(2);
                                doc.font('mArial', 11)
                                    .text('Θέλουμε να ξεπερνούμε τις στατιστικές και τα προγνωστικά, εμπνέοντας δύναμη και ελπίδα. Να\n' +
                                        'θέτουμε ρεαλιστικούς στόχους προόδου και να τους υπερβαίνουμε. Να είμαστε για τους ασθενείς\n' +
                                        'μας, τις οικογένειές τους και την ιατρική κοινότητα σημείο αναφοράς στη Φυσική Ιατρική και\n' +
                                        'Αποκατάσταση στην Ελλάδα και στη Νοτιοανατολική Ευρώπη.');
                                doc.moveDown(2);
                                doc.font('mArial', 11)
                                    .text('Η ειδική Ιατρική Ομάδα Αποκατάστασης σχεδιάζει και κάνει πράξη την αποθεραπεία των ασθενών\n' +
                                        'μας. Τα μέλη της συνεδριάζουν εβδομαδιαία και συνεργάζονται στενά γιa την πρόοδο κάθε\n' +
                                        'ασθενούς.');
                                doc.moveDown(2);
                                doc.font('mArial', 11)
                                    .text('Ο Φυσίατρος είναι ο επικεφαλής ειδικός Επαγγελματίας Υγείας της Ομάδας Αποκατάστασης, ο οποίος\n' +
                                        'προσεγγίζει ολιστικά τους ασθενείς, εκτιμά την κατάστασή τους, θέτει μαζί τους στόχους και\n' +
                                        'επιβλέπει την εξέλιξη της αποθεραπείας τους, συντονίζοντας την ειδική Ιατρική Ομάδα\n' +
                                        'Αποκατάστασης που αποτελείται από Ιατρό Φυσικής Αποκατάστασης σε κάθε πτέρυγα νοσηλείας,\n' +
                                        'Παθολόγο σε κάθε πτέρυγα νοσηλείας, Καρδιολόγο, Ορθοπεδικό, Εντατικολόγο, Νευρολόγο και\n' +
                                        'συνεργαζόμενους εξωτερικούς επαγγελματίες υγείας όλων των ειδικοτήτων ανάλογα με την ανάγκη που θα\n' +
                                        'προκύψει για κάθε ασθενή.');
                                doc.moveDown(2);
                                doc.font('mArial', 11)
                                    .text('Στο κέντρο Euromedica-Αρωγή Θεσσαλονίκης κάθε πρόγραμμα Φυσικής Ιατρικής και\n' +
                                        'Αποκατάστασης που εφαρμόζεται σχεδιάζεται με επίκεντρο τον ασθενή και τις ανάγκες του και\n' +
                                        'μπορεί να συνδυάσει ένα εύρος υπηρεσιών από τα διαφορετικά Τμήματα του Κέντρου:');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- Τομέας Φυσιοθεραπείας\n' +
                                        '- Τομέας Υδροθεραπείας\n' +
                                        '- Τομέας Εργοθεραπείας\n' +
                                        '- Τομέας Λογοθεραπείας\n' +
                                        '- Τομέας Ψυχολογικής Υποστήριξης\n' +
                                        '- Τομέας Διατροφής');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Το κέντρο Euromedica-Αρωγή Θεσσαλονίκης βρίσκεται σε απόσταση 10’ από το εμπορικό κέντρο\n' +
                                        'της πόλης σε έναν κτίριο ', {continued: true})
                                    .font('Times-Roman', 13)
                                    .text('15.947 ', {continued: true})
                                    .font('mArial', 11)
                                    .text('τ.μ. που διαθέτει:');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- 200 κλίνες\n' +
                                        '- 4.500 τ.μ. χώρους θεραπειών\n' +
                                        '- 1.200 κλειστούς χώρους αθλητικών δραστηριοτήτων ΑΜΕΑ\n' +
                                        '- 3 πισίνες και 1 υδατοδιάδρομος\n' +
                                        '- 170 θέσεις στάθμευσης\n');
                                doc.font('mArial', 8)
                                    .text('ΔΔ/36.2/01.03.2017', 30, 700);
                                doc.moveDown(1);
                                //doc.moveDown(1);
                                doc.font('mArial', 8)
                                    .text('EUROMEDICA-ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε., Κέντρο Ιατρικής Αποθεραπείας και Αποκατάστασης', {align: 'center'});
                                doc.font('mArial', 8)
                                    .text('Τέρμα 17ης Νοέμβρη, Πυλαία 54301 Θεσσαλονίκη', 100, 730, {continued: true})
                                    .font('Times-Roman', 8)
                                    .text('T. 2310986000 F. 2310986080 www.euromedica-arogi.gr', 104, 732);

                                /****************
                                 *** 3rd Page ***
                                 ****************/

                                doc.font('mArial', 11)
                                    .text('Το ημερήσιο ιδιωτικό κόστος ασθενούς στο Τμήμα Κλειστής Νοσηλείας του Κέντρου μας\n' +
                                        'καθορίζεται ανάλογα με την επιλογή του τύπου του δωματίου και διαμορφώνεται ως εξής:\n', 30);
                                doc.moveDown(1);

                                // Draw a table
                                doc.lineCap('butt')
                                    .moveTo(210, 100)
                                    .lineTo(210, 220)
                                    .stroke();

                                doc.lineCap('butt')
                                    .moveTo(350, 100)
                                    .lineTo(350, 220)
                                    .stroke();

                                var coordinates = {
                                    rowHeight: 100,
                                    leftHeight: 105,
                                    rightHeight: 105
                                };

                                row(doc, coordinates.rowHeight);
                                textInRowFirst(doc, 'ΤΥΠΟΣ ΔΩΜΑΤΙΟΥ', coordinates.leftHeight, 60);
                                textInRowSecond(doc, 'ΧΡΕΩΣΗ / ΗΜΕΡΑ', coordinates.rightHeight, 230);
                                textInRowSecond(doc, 'ΔΙΑΜΟΝΗ ΣΥΝΟΔΟΥ', coordinates.rightHeight, 380);
                                coordinates = addToCoordinates(coordinates, 20);
                                row(doc, coordinates.rowHeight);
                                textInRowFirst(doc, '3κλινο δωμάτιο', coordinates.leftHeight, 30);
                                textInRowSecondForNumbers(doc, '€190', coordinates.rightHeight, 260);
                                coordinates = addToCoordinates(coordinates, 20);
                                row(doc, coordinates.rowHeight);
                                textInRowFirst(doc, '2κλινο δωμάτιο', coordinates.leftHeight, 30);
                                textInRowSecondForNumbers(doc, '€230', coordinates.rightHeight, 260);
                                coordinates = addToCoordinates(coordinates, 20);
                                row(doc, coordinates.rowHeight);
                                textInRowFirst(doc, '1κλινο δωμάτιο', coordinates.leftHeight, 30);
                                textInRowSecondForNumbers(doc, '€270', coordinates.rightHeight, 260);
                                textInRowSecondForNumbers(doc, '€25', coordinates.rightHeight, 420);
                                coordinates = addToCoordinates(coordinates, 20);
                                row(doc, coordinates.rowHeight);
                                textInRowFirst(doc, '1κλινο δωμάτιο Λουξ', coordinates.leftHeight, 30);
                                textInRowSecondForNumbers(doc, '€340', coordinates.rightHeight, 260);
                                textInRowSecondForNumbers(doc, '€25', coordinates.rightHeight, 420);
                                coordinates = addToCoordinates(coordinates, 20);
                                row(doc, coordinates.rowHeight);
                                textInRowFirst(doc, 'ΧΑΦ', coordinates.leftHeight, 30);
                                textInRowSecondForNumbers(doc, '€370', coordinates.rightHeight, 260);

                                doc.font('mArial', 11)
                                    .text('Στις παραπάνω τιμές συμπεριλαμβάνεται ο Φ.Π.Α. ', 30, 240, {continued: true})
                                    .font('Times-Roman', 13)
                                    .text('(24%)', {continued: true})
                                    .font('mArial', 11)
                                    .text('. Η εξόφληση των νοσηλίων\nπραγματοποιείται κάθε 10 ημέρες νοσηλείας.');
                                doc.moveDown(2);
                                doc.font('mArial', 11)
                                    .text('Στο αναγραφόμενο κόστος συμπεριλαμβάνονται:');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- Η διαμονή\n' +
                                        '- Πλήρης διατροφή με 3 γεύματα την ημέρα\n' +
                                        '- 24ωρη νοσηλευτική φροντίδα\n' +
                                        '- Η φαρμακευτική αγωγή που συνταγογραφείται\n' +
                                        '- Υγειονομικό υλικό όπως πάνες, γάζες, σύριγγες κ.τ.λ.\n' +
                                        '- Οι διαγνωστικές εξετάσεις ρουτίνας καθ’ όλη τη διάρκεια νοσηλείας\n' +
                                        '- Συμμετοχή σε καθημερινό πρόγραμμα φυσιοθεραπευτικής αποκατάστασης Δευτέρα έως\n' +
                                        '  και Σάββατο, με 4-5 συνεδρίες την ημέρα το οποίο μπορεί να περιλαμβάνει:');
                                doc.moveDown(1);
                                // inside list
                                doc.font('mArial', 11)
                                    .text('- Φυσιοθεραπεία: Τεχνικές: ', 70, 447, {continued: true})
                                    .font('Times-Roman', 11)
                                    .text('BOBATH – PNF - MANUAL – MULLIGAN', 70, 449);

                                doc.font('mArial', 11)
                                    .text('- Εργοθεραπεία: Ρομποτικό ', 70, 461, {continued: true})
                                    .font('Times-Roman', 11)
                                    .text('ARMEO (', 70, 463, {continued: true})
                                    .font('mArial', 11)
                                    .text('κινητοποίηση άνω άκρου', 70, 461, {continued: true})
                                    .font('Times-Roman', 11)
                                    .text(')', 70, 463, {continued: true})
                                    .font('mArial', 11)
                                    .text(' – Αισθητηριακή Ολοκλήρωση', 70, 461);

                                doc.font('Times-Roman', 11)
                                    .text('- LOKOMAT: ', 70, 476, {continued: true})
                                    .font('mArial', 11)
                                    .text('Ρομποτικό κινητοποίησης κάτω άκρων', 70, 474);

                                doc.font('Times-Roman', 11)
                                    .text('- REHACOM: ', 70, 489, {continued: true})
                                    .font('mArial', 11)
                                    .text('Αξιολόγηση και θεραπεία γνωσιακών ελλειμμάτων', 70, 487);

                                doc.font('Times-Roman', 11)
                                    .text('- BALANCE: ', 70, 501, {continued: true})
                                    .font('mArial', 11)
                                    .text('Εκπαίδευση ιδιοδεκτικότητας και συντονισμού στην όρθια θέση', 70, 499);

                                doc.font('mArial', 11)
                                    .text('- Άσκηση σε εικονικό περιβάλλον ', 70, 511, {continued: true})
                                    .font('Times-Roman', 11)
                                    .text('(Wii):', 70, 514, {continued: true})
                                    .font('mArial', 11)
                                    .text(' Επανεκπαίδευση χρονισμού των κινητικών αλυσίδων', 70, 511);

                                doc.font('mArial', 11)
                                    .text('- Λογοθεραπεία: Εκπαίδευση κατάποσης και διαχείριση τραχειοστομίας', 70, 523);

                                doc.font('mArial', 11)
                                    .text('- Υδροθεραπεία: Τεχνικές: ', 70, 535, {continued: true})
                                    .font('Times-Roman', 11)
                                    .text('HALLIWICK – BAD RAGAZ RING METHOD', 70, 537);

                                doc.font('mArial', 11)
                                    .text('- Χρήση εξειδικευμένων μηχανημάτων: ', 70, 547, {continued: true})
                                    .font('Times-Roman', 11)
                                    .text('TILT, CPM, ROLLATOR', 70, 549);

                                doc.font('mArial', 11)
                                    .text('- Πλήρως εξοπλισμένο γυμναστήριο', 70, 559);

                                doc.font('mArial', 11)
                                    .text('- Θεραπευτική άσκηση : ', 70, 571, {continued: true})
                                    .font('Times-Roman', 11)
                                    .text('YOGA, PILATES', 70, 573);

                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Ο αριθμός και το είδος των θεραπειών καθορίζεται σύμφωνα με τις ανάγκες του ατόμου και\n' +
                                        'κατόπιν αξιολόγησης της Ομάδας Αποκατάστασης.', 36);
                                doc.font('mArial', 11)
                                    .text('- Καθημερινή ιατρική παρακολούθηση από Ιατρό Φυσικής Αποκατάστασης και Παθολόγο\n' +
                                        'και στην αξιολόγηση που απαιτείται από Νευρολόγο, Καρδιολόγο και Ορθοπεδικό\n' +
                                        '- Διατροφολογική και ψυχολογική υποστήριξη', 30);

                                doc.font('mArial', 8)
                                    .text('ΔΔ/36.2/01.03.2017', 30, 700);
                                doc.moveDown(1);
                                doc.font('mArial', 8)
                                    .text('EUROMEDICA-ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε., Κέντρο Ιατρικής Αποθεραπείας και Αποκατάστασης', {align: 'center'});
                                doc.font('mArial', 8)
                                    .text('Τέρμα 17ης Νοέμβρη, Πυλαία 54301 Θεσσαλονίκη', 100, 730, {continued: true})
                                    .font('Times-Roman', 8)
                                    .text('T. 2310986000 F. 2310986080 www.euromedica-arogi.gr', 104, 732);

                                /****************
                                 *** 4th Page ***
                                 ****************/
                                doc.font('mArial', 11)
                                    .text('Δεν συμπεριλαμβάνονται στα ημερήσια νοσήλια:', 30);
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('- Εξειδικευμένες διαγνωστικές εξετάσεις που τυχόν απαιτηθούν κατά τη διάρκεια της\n' +
                                        ' όπως νοσηλείας καλλιέργειες, αντιβιογράμματα, ακτινογραφίες κ.τ.λ.\n' +
                                        '- Η φαρμακευτική αγωγή που δεν συνταγογραφείται\n' +
                                        '- Νοσηλεία στο Χώρο Αυξημένης Φροντίδας\n' +
                                        '- Μεταφορά με ασθενοφόρο\n' +
                                        '- Οι επισκέψεις εξωτερικών συνεργαζόμενων επαγγελματιών υγείας\n' +
                                        '- Γεύματα συνοδών όπως ημερήσια χρέωση €15 για 3 γεύματα\n' +
                                        '- Αναλώσιμα υλικά όπως αλλαγή γαστροστομίας, τραχειοστομίας κ.τ.λ.\n' +
                                        '- Τυχόν βοηθήματα βάδισης\n' +
                                        '- Η χρήση αποκλειστικής νοσοκόμας');
                                doc.moveDown(1);

                                doc.font('mArial', 11)
                                    .text("", 30);
                                doc.font('mArial', 11)
                                    .text('Η διάρκεια παραμονής των ασθενών στο Κέντρο Euromedica Αρωγή κυμαίνεται από μερικές\n' +
                                        'ημέρες έως αρκετές εβδομάδες, ανάλογα με τις ατομικές ανάγκες και την πρόοδο που\n' +
                                        'παρουσιάζουν. Το Κέντρο Euromedica Αρωγή τηρεί για τον κάθε ασθενή τα χρονοδιαγράμματα\n' +
                                        'που έχουν καθοριστεί από τον Εθνικό Οργανισμό Παροχής Υπηρεσιών Υγείας ΕΟΠΥΥ. Σύμφωνα\n' +
                                        'με τα επίσημα στοιχεία του κέντρου, το 2017, η μέση διάρκεια παραμονής ήταν ', {continued: true})
                                    .font('Times-Roman', 13)
                                    .text('29,99 ', {continued: true})
                                    .font('mArial', 11)
                                    .text('ημέρες,\n' +
                                        'δηλαδή αρκετά κάτω από τις κατευθυντήριες γραμμές του ΕΟΠΥΥ, χαμηλότερα και από τις 31,01\n' +
                                        'ημέρες του έτους 2016.');
                                doc.moveDown(1);
                                doc.image(statsImage, {
                                    fit: [450, 750],
                                    align: 'left'
                                });
                                doc.font('mArial', 8)
                                    .text('ΔΔ/36.2/01.03.2017', 30, 700);
                                doc.moveDown(1);
                                doc.font('mArial', 8)
                                    .text('EUROMEDICA-ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε., Κέντρο Ιατρικής Αποθεραπείας και Αποκατάστασης', {align: 'center'});
                                doc.font('mArial', 8)
                                    .text('Τέρμα 17ης Νοέμβρη, Πυλαία 54301 Θεσσαλονίκη', 100, 730, {continued: true})
                                    .font('Times-Roman', 8)
                                    .text('T. 2310986000 F. 2310986080 www.euromedica-arogi.gr', 104, 732);

                                /****************
                                 *** 5th Page ***
                                 ****************/
                                doc.font('mArial', 11)
                                    .text('Το πρόγραμμα αποκατάστασης που θα ακολουθηθεί και ο τύπος και ο αριθμός των θεραπειών\n' +
                                        'καθορίζεται σύμφωνα με τις ανάγκες του εκάστοτε ασθενούς και κατόπιν αξιολόγησης της Ομάδας\n' +
                                        'Αποκατάστασης κατά την εισαγωγή του ασθενούς. Το ατομικό πρόγραμμα αποκατάστασης\n' +
                                        'κοινοποιείται εγγράφως στον ασθενή και στους συνοδούς σε εβδομαδιαία βάση.', 30);
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Είμαι στη διάθεσή σας για οποιαδήποτε διευκρίνιση ή απορία προκύπτει από την ως άνω\n' +
                                        'πληροφόρηση στην ηλεκτρονική διεύθυνση ', {continued: true})
                                    .font('Times-Roman', 13)
                                    .text("s.bakatselou@euromedica.gr ", {continued: true})
                                    .font('mArial', 11)
                                    .text("ή στα τηλέφωνα ", {continued: true})
                                    .font("Times-Roman", 13)
                                    .text("\n2310 986150.");
                                doc.moveDown(2);
                                doc.font('mArial', 11)
                                    .text('Με εκτίμηση,');
                                doc.moveDown(1);
                                doc.font('mArial', 11)
                                    .text('Σταυριάνα Μπακατσέλου\n' +
                                        'Διευθύντρια Υποδοχής και Κίνησης Ασθενών');
                                doc.moveDown(1);
                                doc.font('mArial', 8)
                                    .text('ΔΔ/36.2/01.03.2017', 30, 700);
                                doc.moveDown(1);
                                doc.font('mArial', 8)
                                    .text('EUROMEDICA-ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε., Κέντρο Ιατρικής Αποθεραπείας και Αποκατάστασης', {align: 'center'});
                                doc.font('mArial', 8)
                                    .text('Τέρμα 17ης Νοέμβρη, Πυλαία 54301 Θεσσαλονίκη', 100, 730, {continued: true})
                                    .font('Times-Roman', 8)
                                    .text('T. 2310986000 F. 2310986080 www.euromedica-arogi.gr', 104, 732);

                                doc.end();
                                stream.on('finish', function() {
                                    var file = stream.toBlobURL('application/pdf');
                                    window.open(file);
                                });
                            });
                        });
                    });
                }
            };
            oReq.send(null);
        }

        function makePrivateOfferWithImages(object){
            var doc = new PDFDocument({
                margin: 45
            });
            doc.info['Title'] = 'Προσφορά ' + object.fullName;
            doc.info['Author'] = 'EUROMEDICA-ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε.';
            var stream = doc.pipe(blobStream());
            var logoImage = null;
            var certImage = null;
            var page1Image = null;
            var page2Image = null;
            var page3Image = null;
            var page4Image = null;
            var page5Image = null;

            // Load custom font for Greek chars encoding problem
            var oReq = new XMLHttpRequest();
            oReq.open("GET", "content/fonts/arial.ttf", true);
            oReq.responseType = "arraybuffer";
            oReq.onload = function(oEvent) {
                var arrayBuffer = oReq.response; // Note: not oReq.responseText
                if (arrayBuffer) {
                    doc.registerFont('mArial', arrayBuffer);
                    doc.font('mArial');

                    toDataURL('content/images/arogi_logo.jpg', function (dataUrl) {
                        logoImage = dataUrl;
                        toDataURL('content/images/arogi_cert.jpg', function (dataUrl) {
                            certImage = dataUrl;
                            toDataURL('content/images/private_page1.jpg', function (dataUrl) {
                                page1Image = dataUrl;
                                toDataURL('content/images/private_page2.jpg', function (dataUrl) {
                                    page2Image = dataUrl;
                                    toDataURL('content/images/private_page3.jpg', function (dataUrl) {
                                        page3Image = dataUrl;
                                        toDataURL('content/images/private_page4.jpg', function (dataUrl) {
                                            page4Image = dataUrl;
                                            toDataURL('content/images/private_page5.jpg', function (dataUrl) {
                                                page5Image = dataUrl;
                                                /****************
                                                 *** 1st Page ***
                                                 ****************/
                                                doc.image(logoImage, {
                                                    fit: [160, 210],
                                                    align: 'left'
                                                });
                                                doc.image(certImage, 450, 40, {
                                                    fit: [80, 80],
                                                    align: 'right'
                                                });

                                                doc.font('mArial', 11)
                                                    .text('Θεσσαλονίκη, ' + moment(object.date).format('DD/MM/YYYY'), 400, 140);
                                                //doc.moveDown(2);
                                                doc.font('mArial', 11)
                                                    .text(object.pdfPronouncement, 55, 170);
                                                doc.moveDown(1);
                                                doc.image(page1Image, {
                                                    fit: [550, 600],
                                                    align: 'left'
                                                });
                                                /****************
                                                 *** 2nd Page ***
                                                 ****************/
                                                doc.addPage();
                                                doc.image(page2Image, {
                                                    fit: [800, 730],
                                                    align: 'left'
                                                });
                                                /****************
                                                 *** 3rd Page ***
                                                 ****************/
                                                doc.addPage();
                                                doc.image(page3Image, {
                                                    fit: [800, 730],
                                                    align: 'left'
                                                });
                                                /****************
                                                 *** 4th Page ***
                                                 ****************/
                                                doc.addPage();
                                                doc.image(page4Image, {
                                                    fit: [800, 730],
                                                    align: 'left'
                                                });
                                                /****************
                                                 *** 5th Page ***
                                                 ****************/
                                                doc.addPage();
                                                doc.image(page5Image, {
                                                    fit: [800, 730],
                                                    align: 'left'
                                                });


                                                doc.end();
                                                stream.on('finish', function() {
                                                    var file = stream.toBlobURL('application/pdf');
                                                    window.open(file);
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                }
            };
            oReq.send(null);
        }

        /**
         * Builds a pdf document depending on values of object and
         * displays it in new browser tab. Original doc: relief_treatment.pdf.
         * @param object The offer object. Object's structure should be:
         * object {
         *   date: Date
         *   fullName: String,
         *   pdfPronouncement: String,
         *   attendantMealPrice: Double
         *   transportation: Transportation object
         *   roomCost: RoomCost object
         *   finalDiscount: DiscountRate object
         * }
         */
        function makeReliefTherapyOffer(object) {
            var doc = new PDFDocument({
                margin: 50
            });
            doc.info['Title'] = 'Προσφορά ' + object.fullName;
            doc.info['Author'] = 'EUROMEDICA-ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε.';
            var stream = doc.pipe(blobStream());
            var logoImage = null;
            var certImage = null;

            // Load custom font for Greek chars encoding problem
            var oReq = new XMLHttpRequest();
            oReq.open("GET", "content/fonts/NotoSans-Regular.ttf", true);
            oReq.responseType = "arraybuffer";
            oReq.onload = function(oEvent) {
                var arrayBuffer = oReq.response; // Note: not oReq.responseText
                if (arrayBuffer) {
                    doc.registerFont('mArial', arrayBuffer);
                    doc.font('mArial');

                    toDataURL('content/images/arogi_logo.jpg', function (dataUrl) {
                        logoImage = dataUrl;
                        toDataURL('content/images/arogi_cert.jpg', function (dataUrl) {
                            certImage = dataUrl;
                            // Got all images, build the document
                            /****************
                             *** 1st Page ***
                             ****************/
                            doc.image(logoImage, {
                                fit: [160, 210],
                                align: 'left'
                            });
                            doc.image(certImage, 450, 40, {
                                fit: [80, 80],
                                align: 'right'
                            });

                            doc.font('mArial', 11)
                                .text('Θεσσαλονίκη, ' + moment(object.date).format('DD/MM/YYYY'), 400, 140);
                            //doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Αξιότιμη / Αξιότιμε κ. ' + object.fullName, 30, 170);
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Αρχικά θα θέλαμε να σας ευχαριστήσουμε για την εμπιστοσύνη σας στις υπηρεσίες του κέντρου' +
                                    ' Αποκατάστασης και Αποθεραπείας Euromedica – Αρωγή Θεσσαλονίκης.');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Στη συνέχεια ακολουθεί μια σύντομη παρουσίαση του Κέντρου και περιγραφή των παροχών αυτού,' +
                                    ' προς τους νοσηλευόμενους ασθενείς.');
                            doc.moveDown(2);
                            doc.font('mArial', 15)
                                .text('Πρότυπο Κέντρο στην Αποκατάσταση και Αποθεραπεία.');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Η Euromedica-Αρωγή Θεσσαλονίκης, το πρώτο και μοναδικό Κέντρο Αποκατάστασης στην' +
                                    ' περιοχή της Νοτιοανατολικής Ευρώπης, ανήκει στην επίλεκτη παγκόσμια ομάδα των 543 Κέντρων' +
                                    ' Αποκατάστασης, εκ των οποίων τα 517 στις Ηνωμένες Πολιτείες Αμερικής, τα οποία έχουν λάβει τη' +
                                    ' Διεθνή Διαπίστευση CARF, στην κατηγορία:');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('«Inpatient Rehabilitation Programmes – Hospital»', {align: 'center'});
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Η διαπίστευση αυτή, αποτελεί το υψηλότερο επίπεδο παγκόσμιας πιστοποίησης που απονέμεται σε' +
                                    ' ένα Κέντρο Αποκατάστασης και καταδεικνύει την υψηλή ποιότητα παρεχόμενων προγραμμάτων' +
                                    ' αποκατάστασης.');
                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Στο Κέντρο Αποκατάστασης και Αποθεραπείας Euromedica–Αρωγή Θεσσαλονίκης προσφέρουμε' +
                                    ' από το 2010 εξατομικευμένα προγράμματα αποκατάστασης και αποθεραπείας, προσεγγίζοντας' +
                                    ' ολιστικά τους ασθενείς με οξείες ή χρόνιες παθήσεις.');
                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Ως κύριο στόχο μας θέσαμε την ανάπτυξη μίας σύγχρονης αντίληψης για την Αποκατάσταση που' +
                                    ' θα συνεισφέρει στην προαγωγή της καλής υγείας και στον σεβασμό των ανθρώπων που πάσχουν' +
                                    ' και συγχρόνως, πασχίζουν να γίνουν καλά.');
                            doc.font('mArial', 8)
                                .text('ΔΔ/36.2/01.03.2017', 30, 700);
                            doc.moveDown(1);
                            doc.font('mArial', 8)
                                .text('EUROMEDICA-ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε., Κέντρο Ιατρικής Αποθεραπείας και Αποκατάστασης', {align: 'center'});
                            doc.font('mArial', 8)
                                .text('Τέρμα 17ης Νοέμβρη, Πυλαία 54301 Θεσσαλονίκη', 100, 730, {continued: true})
                                .font('Times-Roman', 8)
                                .text('T. 2310986000 F. 2310986080 www.euromedica-arogi.gr', 104, 732);

                            /****************
                             *** 2nd Page ***
                             ****************/

                            doc.font('mArial', 15)
                                .text('Ανακουφιστική Φροντίδα στη Euromedica-Αρωγή', 30);
                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Σύμφωνα με τον Παγκόσμιο Οργανισμό Υγείας η Ανακουφιστική Φροντίδα είναι η προσέγγιση' +
                                    ' που επιδιώκει τη βελτίωση της ποιότητας ζωής τόσο των ασθενών που πάσχουν από νοσήματα' +
                                    ' απειλητικά για τη ζωή τους, όσο και των οικογενειών τους, μέσω της πρόληψης και της' +
                                    ' ανακούφισης από το «υποφέρειν». Επιτυγχάνεται με την αντιμετώπιση των συμπτωμάτων που' +
                                    ' επιφέρει η ασθένεια ή η θεραπεία αυτής. Συχνά είναι δυνατόν να βελτιωθεί η ποιότητα ζωής των' +
                                    ' ασθενών, ανεξάρτητα από την πρόγνωση της ασθένειας τους, βοηθώντας τους να επιτύχουν' +
                                    ' τη μέγιστη λειτουργική τους ικανότητα και την αυτοτέλεια ή να αποκτήσουν την ανακούφιση από τα' +
                                    ' συμπτώματα δυσφορίας.');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Απευθύνεται σε ασθενείς τελικού σταδίου και σε ανθρώπους που πάσχουν από σοβαρά και' +
                                    ' προοδευτικά επιδεινούμενα νοσήματα ή καταστάσεις, καθώς και από καταστροφικές βλάβες,' +
                                    ' κάποιες από τις οποίες  είναι δυνατόν να αποβούν απειλητικές για τη ζωή, όπως:');
                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('- Νεοπλασματικά νοσήματα\n' +
                                    '- Βαριά καρδιακή ανεπάρκεια\n' +
                                    '- Χρόνια αναπνευστική ανεπάρκεια\n');
                            doc.font('Times-Roman', 11)
                                .text('- HIV-AIDS\n');
                            doc.font('mArial', 11)
                                .text('- Νευρολογικά και νευρομυϊκά νοσήματα. Παραπληγία, Τετραπληγία, Σκλήρυνση κατά Πλάκας, Μυασθένεια\n' +
                                    '- Ρευματικές και αυτοάνοσες παθήσεις κ.α.\n' +
                                    '- Χρόνια νεφρική ανεπάρκεια');
                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Η Ανακουφιστική Φροντίδα Euromedica-Αρωγή αποτελεί έργο μιας διεπιστημονικής ομάδας' +
                                    ' διάφορων ειδικοτήτων. Εκτός από τον επικεφαλής Ιατρό παθολόγο, φυσίατρο, αναισθησιολόγο και' +
                                    ' νευρολόγο, η ομάδα αποτελείται από  κλινικό ψυχολόγο, κλινικό φαρμακοποιό, νοσηλευτές με' +
                                    ' προϋπηρεσία σε Χώρο Αυξημένης Φροντίδας, εξειδικευμένους φυσιοθεραπευτές, κοινωνικούς' +
                                    ' λειτουργούς, διατροφολόγους και θρησκευτικό αντιπρόσωπο.');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Η διεπιστημονική ομάδα της Euromedica-Αρωγή συνεργάζεται με τους θεράποντες επαγγελματίες υγείας και την' +
                                    ' οικογένεια συντονίζοντας τις  προσπάθειες για παροχή φροντίδας, ώστε να κινητοποιήσει και να' +
                                    ' ανακουφίσει τον ασθενή από τα συμπτώματα της νόσου, πετυχαίνωντας καλύτερη ποιότητα ζωής.');
                            doc.font('mArial', 8)
                                .text('ΔΔ/36.2/01.03.2017', 30, 700);
                            doc.moveDown(1);
                            doc.font('mArial', 8)
                                .text('EUROMEDICA-ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε., Κέντρο Ιατρικής Αποθεραπείας και Αποκατάστασης', {align: 'center'});
                            doc.font('mArial', 8)
                                .text('Τέρμα 17ης Νοέμβρη, Πυλαία 54301 Θεσσαλονίκη', 100, 730, {continued: true})
                                .font('Times-Roman', 8)
                                .text('T. 2310986000 F. 2310986080 www.euromedica-arogi.gr', 104, 732);

                            /****************
                             *** 3rd Page ***
                             ****************/

                            doc.font('mArial', 11)
                                .text('Η υπηρεσία της Ανακουφιστικής Φροντίδας Euromedica – Αρωγή απευθύνεται σε ιδιώτες ασθενείς,' +
                                    ' στην Κλειστή Νοσηλεία, χωρίς χρήση ασφαλιστικού ταμείου. Το Ημερήσιο Κόστος διαμορφώνεται' +
                                    ' ως εξής:', 30);
                            doc.moveDown(1);

                            // Draw a table
                            doc.lineCap('butt')
                                .moveTo(270, 100)
                                .lineTo(270, 200)
                                .stroke();

                            var coordinates = {
                                rowHeight: 100,
                                leftHeight: 105,
                                rightHeight: 105
                            };

                            row(doc, coordinates.rowHeight);
                            textInRowFirst(doc, 'ΤΥΠΟΣ ΔΩΜΑΤΙΟΥ', coordinates.leftHeight, 90);
                            textInRowSecond(doc, 'ΧΡΕΩΣΗ / ΗΜΕΡΑ', coordinates.rightHeight, 350);
                            coordinates = addToCoordinates(coordinates, 20);
                            row(doc, coordinates.rowHeight);
                            textInRowFirst(doc, 'Νοσηλεία σε 2κλινο δωμάτιο', coordinates.leftHeight, 30);
                            textInRowSecondForNumbers(doc, '€160', coordinates.rightHeight, 390);
                            coordinates = addToCoordinates(coordinates, 20);
                            row(doc, coordinates.rowHeight);
                            textInRowFirst(doc, 'Νοσηλεία σε 1κλινο δωμάτιο', coordinates.leftHeight, 30);
                            textInRowSecondForNumbers(doc, '€220', coordinates.rightHeight, 390);
                            coordinates = addToCoordinates(coordinates, 20);
                            row(doc, coordinates.rowHeight);
                            textInRowFirst(doc, 'Νοσηλεία σε μονόκλινο δωμάτιο με συνοδό', coordinates.leftHeight, 30);
                            textInRowSecondForNumbers(doc, '€245', coordinates.rightHeight, 390);
                            coordinates = addToCoordinates(coordinates, 20);
                            row(doc, coordinates.rowHeight);
                            textInRowFirst(doc, 'Νοσηλεία στη μονάδα Αυξημένης Φροντίδας', coordinates.leftHeight, 30);
                            textInRowSecondForNumbers(doc, '€370', coordinates.rightHeight, 390);

                            doc.font('mArial', 11)
                                .text('Στις παραπάνω τιμές συμπεριλαμβάνεται ο Φ.Π.Α. ', 30, 250, {continued: true})
                                .font('Times-Roman', 13)
                                .text('(24%)', {continued: true})
                                .font('mArial', 11)
                                .text('. Η εξόφληση των νοσηλίων\nπραγματοποιείται κάθε 10 ημέρες νοσηλείας.');
                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Στο παραπάνω κόστος συμπεριλαμβάνονται:');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('- Η διαμονή σε κλίνη επιλογής του ασθενούς\n' +
                                    '- Πλήρης διατροφή με 3 γεύματα την ημέρα\n' +
                                    '- 24ωρη νοσηλευτική φροντίδα\n' +
                                    '- Το Υγειονομικό υλικό όπως πάνες, γάζες, σύριγγες κ.τ.λ.\n' +
                                    '- Οι διαγνωστικές εξετάσεις ρουτίνας καθ’ όλη τη διάρκεια νοσηλείας\n' +
                                    '- Συμμετοχή σε καθημερινό πρόγραμμα φυσιοθεραπευτικής αποκατάστασης Δευτέρα έως\n' +
                                    '  και Σάββατο, με 4-5 συνεδρίες την ημέρα. Το πρόγραμμα καθορίζεταθ σύμφωνα με τις ανάγκες\n' +
                                    '  του ασθενή και κατόπιν αξιολόγησης της Ομάδας Αποκατάστασης\n' +
                                    '- Καθημερινή ιατρική παρακολούθηση από Ιατρό Φυσικής Αποκατάστασης και Παθολόγο\n' +
                                    '  και στην αξιολόγηση που απαιτείται από νευρολογικό και ορθοπεδικό\n' +
                                    '- Διατροφολογική και ψυχολογική υποστήριξη');

                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Δεν συμπεριλαμβάνονται στα ημερήσια νοσήλια:', 30);
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('- Η φαρμακευτική αγωγή\n' +
                                    '- Εξειδικευμένες εργαστηριακές και απεικονιστικές εξετάσεις που τυχόν απαιτηθούν κατά τη\n' +
                                    '- διάρκεια της νοσηλείας όπως καλλιέργεις, αντιβιογράμματα, αξονικές, μαγνητικές.\n' +
                                    '- Οι επισκέψεις εξωτερικών επαγγελματιών υγείας άλλων ειδικοτήτων που δεν καλύπτονται από το Κέντρο.\n' +
                                    '- Γεύματα συνοδφών. Ημερήσια χρέωση €15 για 3 γεύματα.\n' +
                                    '- Αναλώσιμα υλικά όπως αλλαγή γαστρονομίας, τραχειοστομίας κ.τ.λ.\n');
                            doc.moveDown(1);
                            doc.font('mArial', 8)
                                .text('ΔΔ/36.2/01.03.2017', 30, 700);
                            doc.moveDown(1);
                            doc.font('mArial', 8)
                                .text('EUROMEDICA-ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε., Κέντρο Ιατρικής Αποθεραπείας και Αποκατάστασης', {align: 'center'});
                            doc.font('mArial', 8)
                                .text('Τέρμα 17ης Νοέμβρη, Πυλαία 54301 Θεσσαλονίκη', 100, 730, {continued: true})
                                .font('Times-Roman', 8)
                                .text('T. 2310986000 F. 2310986080 www.euromedica-arogi.gr', 104, 732);

                            /****************
                             *** 4th Page ***
                             ****************/

                            doc.addPage();
                            doc.font('mArial', 11)
                                .text('Είμαι στη διάθεσή σας για οποιαδήποτε διευκρίνιση ή απορία προκύπτει από την ως άνω\n' +
                                    'πληροφόρηση στην ηλεκτρονική διεύθυνση ', 30, 40, {continued: true})
                                .font('Times-Roman', 13)
                                .text("s.bakatselou@euromedica.gr ", {continued: true})
                                .font('mArial', 11)
                                .text("ή στα τηλέφωνα ", {continued: true})
                                .font("Times-Roman", 13)
                                .text("\n2310 986150.");
                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Με εκτίμηση,');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Σταυριάνα Μπακατσέλου\n' +
                                    'Διευθύντρια Υποδοχής και Κίνησης Ασθενών');
                            doc.moveDown(1);
                            doc.font('mArial', 8)
                                .text('ΔΔ/36.2/01.03.2017', 30, 700);
                            doc.moveDown(1);
                            doc.font('mArial', 8)
                                .text('EUROMEDICA-ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε., Κέντρο Ιατρικής Αποθεραπείας και Αποκατάστασης', {align: 'center'});
                            doc.font('mArial', 8)
                                .text('Τέρμα 17ης Νοέμβρη, Πυλαία 54301 Θεσσαλονίκη', 100, 730, {continued: true})
                                .font('Times-Roman', 8)
                                .text('T. 2310986000 F. 2310986080 www.euromedica-arogi.gr', 104, 732);

                            doc.end();
                            stream.on('finish', function() {
                                var file = stream.toBlobURL('application/pdf');
                                window.open(file);
                            });
                        });
                    });
                }
            };
            oReq.send(null);
        }

        function makeReliefTherapyOfferWithImages(object){
            var doc = new PDFDocument({
                margin: 45
            });
            doc.info['Title'] = 'Προσφορά ' + object.fullName;
            doc.info['Author'] = 'EUROMEDICA-ΑΡΩΓΗ ΘΕΣΣΑΛΟΝΙΚΗΣ Α.Ε.';
            var stream = doc.pipe(blobStream());
            var logoImage = null;
            var certImage = null;
            var page1Image = null;
            var page2Image = null;
            var page3Image = null;
            var page4Image = null;

            // Load custom font for Greek chars encoding problem
            var oReq = new XMLHttpRequest();
            oReq.open("GET", "content/fonts/arial.ttf", true);
            oReq.responseType = "arraybuffer";
            oReq.onload = function(oEvent) {
                var arrayBuffer = oReq.response; // Note: not oReq.responseText
                if (arrayBuffer) {
                    doc.registerFont('mArial', arrayBuffer);
                    doc.font('mArial');

                    toDataURL('content/images/arogi_logo.jpg', function (dataUrl) {
                        logoImage = dataUrl;
                        toDataURL('content/images/arogi_cert.jpg', function (dataUrl) {
                            certImage = dataUrl;
                            toDataURL('content/images/reliefTreatment_page1.jpg', function (dataUrl) {
                                page1Image = dataUrl;
                                toDataURL('content/images/reliefTreatment_page2.jpg', function (dataUrl) {
                                    page2Image = dataUrl;
                                    toDataURL('content/images/reliefTreatment_page3.jpg', function (dataUrl) {
                                        page3Image = dataUrl;
                                        toDataURL('content/images/reliefTreatment_page4.jpg', function (dataUrl) {
                                            page4Image = dataUrl;
                                            /****************
                                             *** 1st Page ***
                                             ****************/
                                            doc.image(logoImage, {
                                                fit: [160, 210],
                                                align: 'left'
                                            });
                                            doc.image(certImage, 450, 40, {
                                                fit: [80, 80],
                                                align: 'right'
                                            });

                                            doc.font('mArial', 11)
                                                .text('Θεσσαλονίκη, ' + moment(object.date).format('DD/MM/YYYY'), 400, 140);
                                            //doc.moveDown(2);
                                            doc.font('mArial', 11)
                                                .text(object.pdfPronouncement, 55, 170);
                                            doc.moveDown(1);
                                            doc.image(page1Image, {
                                                fit: [550, 600],
                                                align: 'left'
                                            });
                                            /****************
                                             *** 2nd Page ***
                                             ****************/
                                            doc.addPage();
                                            doc.image(page2Image, {
                                                fit: [800, 730],
                                                align: 'left'
                                            });
                                            /****************
                                             *** 3rd Page ***
                                             ****************/
                                            doc.addPage();
                                            doc.image(page3Image, {
                                                fit: [800, 730],
                                                align: 'left'
                                            });
                                            /****************
                                             *** 4th Page ***
                                             ****************/
                                            doc.addPage();
                                            doc.image(page4Image, {
                                                fit: [800, 730],
                                                align: 'left'
                                            });


                                            doc.end();
                                            stream.on('finish', function() {
                                                var file = stream.toBlobURL('application/pdf');
                                                window.open(file);
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                }
            };
            oReq.send(null);
        }

        /**
         * Builds a pdf document depending on values of object and
         * displays it in new browser tab. Used ONLY for demonstration.
         * @param object The offer object. Object's structure should be:
         * object {
         *   date: Date
         *   fullName: String
         *   attendantMealPrice: Double
         *   transportation: Transportation object
         *   roomCost: RoomCost object
         *   finalDiscount: DiscountRate object
         * }
         */
        function makeDemoOffer(object){
            var doc = new PDFDocument({
                margin: 50
            });
            doc.info['Title'] = 'Προσφορά ' + object.fullName;
            doc.info['Author'] = 'SigmaSoft S.A.';
            var stream = doc.pipe(blobStream());
            var logoImage = null;
            var statsImage = null;

            // Load custom font for Greek chars encoding problem
            var oReq = new XMLHttpRequest();
            oReq.open("GET", "content/fonts/arial.ttf", true);
            oReq.responseType = "arraybuffer";
            oReq.onload = function(oEvent) {
                var arrayBuffer = oReq.response; // Note: not oReq.responseText
                if (arrayBuffer) {
                    doc.registerFont('mArial', arrayBuffer);
                    doc.font('mArial');

                    toDataURL('content/images/SigmaSoft_logo-top.png', function (dataUrl) {
                        logoImage = dataUrl;
                        toDataURL('content/images/arogi_stats.jpg', function (dataUrl) {
                            statsImage = dataUrl;
                            // Got all images, build the document
                            /****************
                             *** 1st Page ***
                             ****************/
                            doc.image(logoImage, {
                                fit: [120, 170],
                                align: 'left'
                            });

                            doc.font('mArial', 11)
                                .text('Θεσσαλονίκη, ' + moment(object.date).format('DD/MM/YYYY'), 400, 140);
                            doc.font('mArial', 11)
                                .text('Αξιότιμη / Αξιότιμε κ. ' + object.fullName, 30, 170);
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Αρχικά θα θέλαμε να σας ευχαριστήσουμε για την εμπιστοσύνη σας στις υπηρεσίες του κέντρου' +
                                    ' Αποκατάστασης και Αποθεραπείας SigmaSoft Θεσσαλονίκης.');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Στη συνέχεια ακολουθεί μια σύντομη παρουσίαση του Κέντρου και περιγραφή των παροχών αυτού,' +
                                    ' προς τους νοσηλευόμενους ασθενείς.');
                            doc.moveDown(4);
                            doc.font('mArial', 15)
                                .text('Πρότυπο Κέντρο στην Αποκατάσταση και Αποθεραπεία.');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Η SigmaSoft Θεσσαλονίκης, το πρώτο και μοναδικό Κέντρο Αποκατάστασης στην' +
                                    ' περιοχή της Νοτιοανατολικής Ευρώπης, ανήκει στην επίλεκτη παγκόσμια ομάδα των 543 Κέντρων' +
                                    ' Αποκατάστασης, εκ των οποίων τα 517 στις Ηνωμένες Πολιτείες Αμερικής, τα οποία έχουν λάβει τη' +
                                    ' Διεθνή Διαπίστευση CARF, στην κατηγορία:');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('«Inpatient Rehabilitation Programmes – Hospital»', {align: 'center'});
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Η διαπίστευση αυτή, αποτελεί το υψηλότερο επίπεδο παγκόσμιας πιστοποίησης που απονέμεται σε' +
                                    ' ένα Κέντρο Αποκατάστασης και καταδεικνύει την υψηλή ποιότητα παρεχόμενων προγραμμάτων' +
                                    ' αποκατάστασης.');
                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Στο Κέντρο Αποκατάστασης και Αποθεραπείας SigmaSoft Θεσσαλονίκης προσφέρουμε' +
                                    ' από το 2010 εξατομικευμένα προγράμματα αποκατάστασης και αποθεραπείας, προσεγγίζοντας' +
                                    ' ολιστικά τους ασθενείς με οξείες ή χρόνιες παθήσεις.');
                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Ως κύριο στόχο μας θέσαμε την ανάπτυξη μίας σύγχρονης αντίληψης για την Αποκατάσταση που' +
                                    ' θα συνεισφέρει στην προαγωγή της καλής υγείας και στον σεβασμό των ανθρώπων που πάσχουν' +
                                    ' και συγχρόνως, πασχίζουν να γίνουν καλά.');

                            /****************
                             *** 2nd Page ***
                             ****************/
                            doc.addPage();
                            doc.font('mArial', 15)
                                .text('Όραμά μας είναι η Καλύτερη Υγεία για Όλους.');
                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Θέλουμε να ξεπερνούμε τις στατιστικές και τα προγνωστικά, εμπνέοντας δύναμη και ελπίδα. Να\n' +
                                    'θέτουμε ρεαλιστικούς στόχους προόδου και να τους υπερβαίνουμε. Να είμαστε για τους ασθενείς\n' +
                                    'μας, τις οικογένειές τους και την ιατρική κοινότητα σημείο αναφοράς στη Φυσική Ιατρική και\n' +
                                    'Αποκατάσταση στην Ελλάδα και στη Νοτιοανατολική Ευρώπη.');
                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Η ειδική Ιατρική Ομάδα Αποκατάστασης σχεδιάζει και κάνει πράξη την αποθεραπεία των ασθενών\n' +
                                    'μας. Τα μέλη της συνεδριάζουν εβδομαδιαία και συνεργάζονται στενά γιa την πρόοδο κάθε\n' +
                                    'ασθενούς.');
                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Ο Φυσίατρος είναι ο επικεφαλής ειδικός Επαγγελματίας Υγείας της Ομάδας Αποκατάστασης, ο οποίος\n' +
                                    'προσεγγίζει ολιστικά τους ασθενείς, εκτιμά την κατάστασή τους, θέτει μαζί τους στόχους και\n' +
                                    'επιβλέπει την εξέλιξη της αποθεραπείας τους, συντονίζοντας την ειδική Ιατρική Ομάδα\n' +
                                    'Αποκατάστασης που αποτελείται από Ιατρό Φυσικής Αποκατάστασης σε κάθε πτέρυγα νοσηλείας,\n' +
                                    'Παθολόγο σε κάθε πτέρυγα νοσηλείας, Καρδιολόγο, Ορθοπεδικό, Εντατικολόγο, Νευρολόγο και\n' +
                                    'συνεργαζόμενους εξωτερικούς επαγγελματίες υγείας όλων των ειδικοτήτων ανάλογα με την ανάγκη που θα\n' +
                                    'προκύψει για κάθε ασθενή.');
                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Στο κέντρο SigmaSoft Θεσσαλονίκης κάθε πρόγραμμα Φυσικής Ιατρικής και\n' +
                                    'Αποκατάστασης που εφαρμόζεται σχεδιάζεται με επίκεντρο τον ασθενή και τις ανάγκες του και\n' +
                                    'μπορεί να συνδυάσει ένα εύρος υπηρεσιών από τα διαφορετικά Τμήματα του Κέντρου:');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('- Τομέας Φυσιοθεραπείας\n' +
                                    '- Τομέας Υδροθεραπείας\n' +
                                    '- Τομέας Εργοθεραπείας\n' +
                                    '- Τομέας Λογοθεραπείας\n' +
                                    '- Τομέας Ψυχολογικής Υποστήριξης\n' +
                                    '- Τομέας Διατροφής');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Το κέντρο SigmaSoft Θεσσαλονίκης βρίσκεται σε απόσταση 5’ από το εμπορικό κέντρο\n' +
                                    'της πόλης σε έναν κτίριο 15.947 τ.μ. που διαθέτει:');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('- 200 κλίνες\n' +
                                    '- 4.500 τ.μ. χώρους θεραπειών\n' +
                                    '- 1.200 κλειστούς χώρους αθλητικών δραστηριοτήτων ΑΜΕΑ\n' +
                                    '- 3 πισίνες και 1 υδατοδιάδρομος\n' +
                                    '- 170 θέσεις στάθμευσης\n');

                            /****************
                             *** 3rd Page ***
                             ****************/
                            doc.addPage();
                            doc.font('mArial', 11)
                                .text('Σε συνέχεια της επικοινωνίας μας , σας ενημερώνω ότι το κόστος της ημερήσιας συμμετοχής\n' +
                                    'σχετικά με την νοσηλεία ασθενούς στο Κέντρο μας καθορίζεται ανάλογα με την επιλογή του τύπου\n' +
                                    'του δωματίου και διαμορφώνεται ως εξής:');
                            doc.moveDown(3);

                            // Draw a table
                            /*doc.lineCap('butt')
                                .moveTo(270, 100)
                                .lineTo(270, 240)
                                .stroke();*/

                            var sum = 0;
                            var totalSum = 0; // includes discount

                            // Headers row
                            row(doc, 100);
                            textInRowFirst(doc, 'ΥΠΗΡΕΣΙΕΣ / ΔΩΜΑΤΙΟ', 105);
                            textInRowSecond(doc, 'ΧΡΕΩΣΗ / ΗΜΕΡΑ', 105);
                            if (object.attendantMealPrice){
                                sum += object.attendantMealPrice;
                                row(doc, 120);
                                textInRowFirst(doc, 'Γεύμα Συνοδού', 125);
                                textInRowSecond(doc, '€ ' + object.attendantMealPrice.toString(), 125);
                            }
                            if (object.transportation){
                                sum += object.transportation.cost;
                                row(doc, 140);
                                textInRowFirst(doc, 'Μεταφορά', 145);
                                textInRowSecond(doc, '€ ' + object.transportation.cost.toString(), 145);
                            }
                            if (object.roomCost){
                                sum += object.roomCost.cost;
                                row(doc, 160);
                                textInRowFirst(doc, 'Νοσηλεία σε ' + object.roomCost.roomType.name + ' δωμάτιο', 165);
                                textInRowSecond(doc, '€ ' + object.roomCost.cost.toString(), 165);
                            }
                            row(doc, 180);
                            textInRowFirst(doc, 'Σύνολο', 185);
                            textInRowSecond(doc, '€ ' + sum.toString(), 185);
                            if (object.finalDiscount){
                                totalSum = sum - ((object.finalDiscount.value / 100) * sum);
                                row(doc, 200);
                                textInRowFirst(doc, 'Σύνολο με Έκπτωση - ' + object.finalDiscount.name, 205);
                                textInRowSecond(doc, '€ ' + totalSum.toString(), 205);
                            }

                            doc.font('mArial', 11)
                                .text('Στις παραπάνω τιμές συμπεριλαμβάνεται ο Φ.Π.Α.(24%). Η εξόφληση των νοσηλίων\n' +
                                    'πραγματοποιείται κάθε 10 ημέρες νοσηλείας.', 30, 250);
                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Στο αναγραφόμενο κόστος συμπεριλαμβάνονται:');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('- Η διαμονή\n' +
                                    '- Πλήρης διατροφή με 3 γεύματα την ημέρα\n' +
                                    '- 24ωρη νοσηλευτική φροντίδα\n' +
                                    '- Η φαρμακευτική αγωγή που συνταγογραφείται\n' +
                                    '- Υγειονομικό υλικό (πάνες, γάζες, σύριγγες κ.τ.λ.)\n' +
                                    '- Οι διαγνωστικές εξετάσεις ρουτίνας καθ’ όλη τη διάρκεια νοσηλείας\n' +
                                    '- Συμμετοχή σε καθημερινό πρόγραμμα φυσιοθεραπευτικής αποκατάστασης (Δευτέρα έως\n' +
                                    '  και Σάββατο), με 4-5 συνεδρίες την ημέρα. Το πρόγραμμα καθορίζεται σύμφωνα με τις\n' +
                                    '  ανάγκες του ατόμου και κατόπιν αξιολόγησης της Ομάδας Αποκατάστασης.\n' +
                                    '- Καθημερινή ιατρική παρακολούθηση από Ιατρό Φυσικής Αποκατάστασης και Παθολόγο\n' +
                                    '- και στην αξιολόγηση που απαιτείται από Νευρολόγο, Καρδιολόγο και Ορθοπεδικό\n' +
                                    '- Διατροφολογική και ψυχολογική υποστήριξη');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Δεν συμπεριλαμβάνονται στα ημερήσια νοσήλια:');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('- Εξειδικευμένες διαγνωστικές εξετάσεις που τυχόν απαιτηθούν κατά τη διάρκεια της\n' +
                                    '- νοσηλείας (καλλιέργειες, αντιβιογράμματα, ακτινογραφίες κ.τ.λ.)\n' +
                                    '- Η φαρμακευτική αγωγή που δεν συνταγογραφείται\n' +
                                    '- Νοσηλεία στο Χώρο Αυξημένης Φροντίδας\n' +
                                    '- Μεταφορά με ασθενοφόρο\n' +
                                    '- Οι επισκέψεις εξωτερικών συνεργαζόμενων επαγγελματιών υγείας\n' +
                                    '- Γεύματα συνοδών (ημερήσια χρέωση €15 για 3 γεύματα)\n' +
                                    '- Αναλώσιμα υλικά (αλλαγή γαστροστομίας, τραχειοστομίας κ.τ.λ.)\n' +
                                    '- Τυχόν βοηθήματα βάδισης\n' +
                                    '- Η χρήση αποκλειστικής νοσοκόμας');

                            /****************
                             *** 4th Page ***
                             ****************/
                            doc.addPage();
                            doc.font('mArial', 11)
                                .text('Η διάρκεια παραμονής των ασθενών στο Κέντρο SigmaSoft κυμαίνεται από μερικές\n' +
                                    'ημέρες έως αρκετές εβδομάδες, ανάλογα με τις ατομικές ανάγκες και την πρόοδο που\n' +
                                    'παρουσιάζουν. Το Κέντρο SigmaSoft τηρεί για τον κάθε ασθενή τα χρονοδιαγράμματα\n' +
                                    'που έχουν καθοριστεί από τον Εθνικό Οργανισμό Παροχής Υπηρεσιών Υγείας (ΕΟΠΥΥ). Σύμφωνα\n' +
                                    'με τα επίσημα στοιχεία του κέντρου, το 2017, η μέση διάρκεια παραμονής ήταν 29,99 ημέρες,\n' +
                                    'δηλαδή αρκετά κάτω από τις κατευθυντήριες γραμμές του ΕΟΠΥΥ, χαμηλότερα και από τις 31,01\n' +
                                    'ημέρες του έτους 2016.');
                            doc.moveDown(1);
                            doc.image(statsImage, {
                                fit: [450, 750],
                                align: 'left'
                            });
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Το πρόγραμμα αποκατάστασης που θα ακολουθηθεί και ο τύπος και ο αριθμός των θεραπειών\n' +
                                    'καθορίζεται σύμφωνα με τις ανάγκες του εκάστοτε ασθενούς και κατόπιν αξιολόγησης της Ομάδας\n' +
                                    'Αποκατάστασης κατά την εισαγωγή του ασθενούς. Το ατομικό πρόγραμμα αποκατάστασης\n' +
                                    'κοινοποιείται εγγράφως στον ασθενή και στους συνοδούς σε εβδομαδιαία βάση.');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Είμαι στη διάθεσή σας για οποιαδήποτε διευκρίνιση ή απορία προκύπτει από την ως άνω\n' +
                                    'πληροφόρηση στην ηλεκτρονική διεύθυνση info@sigmasoft.gr ή στα τηλέφωνα\n' +
                                    '2310 986150.');
                            doc.moveDown(2);
                            doc.font('mArial', 11)
                                .text('Με εκτίμηση,');
                            doc.moveDown(1);
                            doc.font('mArial', 11)
                                .text('Γιώργος Παπαδόπουλος\n' +
                                    'Διευθύντής Υποδοχής και Κίνησης Ασθενών');

                            doc.end();
                            stream.on('finish', function() {
                                var file = stream.toBlobURL('application/pdf');
                                window.open(file);
                            });
                        });
                    });
                }
            };
            oReq.send(null);
        }

        /**
         * Builds a pdf document depending on values of array and
         * displays it in new browser tab. It is strict for
         * Lead Management Aggregated Statistics.
         * @param array Array with values.
         *        array[index].label Is the row label
         *        array[index].value Is the row value
         * @param from Date string from in DD-MM-YYYY format
         * @param to Date string to in DD-MM-YYYY format
         */
        function makeLeadManagementAggregatedReport(array, from, to) {
            var doc = new PDFDocument({
                margin: 50
            });
            doc.info['Title'] = "Συγκεντρωτικά Lead Management";
            doc.info['Author'] = 'SigmaSalesForce';
            var stream = doc.pipe(blobStream());

            // Load custom font for Greek chars encoding problem
            var oReq = new XMLHttpRequest();
            oReq.open("GET", "content/fonts/arial.ttf", true);
            oReq.responseType = "arraybuffer";
            oReq.onload = function(oEvent) {
                var arrayBuffer = oReq.response; // Note: not oReq.responseText
                if (arrayBuffer) {
                    doc.registerFont('mArial', arrayBuffer);
                    doc.font('mArial');

                    doc.font('mArial', 15)
                        .text("Συγκεντρωτικά Lead Management    " + from.toString() + " με " + to.toString());
                    doc.moveDown(3);

                    var coordinates = {
                        rowHeight: 100,
                        leftHeight: 105,
                        rightHeight: 105
                    };
                    for (var i=0; i<array.length; i++){
                        coordinates = addToCoordinates(coordinates, 20);
                        row(doc, coordinates.rowHeight);
                        textInRowFirst(doc, i + 1 + ". " + array[i].label, coordinates.leftHeight, 30);
                        textInRowSecond(doc, array[i].value, coordinates.rightHeight, 480)
                    }

                    doc.end();
                    stream.on('finish', function() {
                        var file = stream.toBlobURL('application/pdf');
                        window.open(file);
                    });
                }
            };
            oReq.send(null);
        }

        function textInRowFirst(doc, text, height, width) {
            doc.y = height;
            doc.x = width; //30;
            doc.fillColor('black');
            doc.font('mArial', 11)
                .text(text, {
                paragraphGap: 5,
                indent: 5,
                align: 'justify',
                columns: 1
            });
            return doc
        }

        function textInRowFirstForPercentage(doc, text, height) {
            doc.y = height;
            doc.x = 30;
            doc.fillColor('black');
            doc.font('mArial', 11)
                .text(text, {
                    paragraphGap: 5,
                    indent: 5,
                    align: 'justify',
                    columns: 1,
                    continued: true
                })
                .font('Times-Roman', 12)
                .text(' %');
            return doc
        }

        function textInRowSecond(doc, text, height, width) {
            doc.y = height;
            doc.x = width; //270;
            doc.fillColor('black');
            doc.text(text, {
                paragraphGap: 5,
                indent: 5,
                align: 'justify',
                columns: 1
            });
            return doc
        }

        function textInRowSecondForNumbers(doc, text, height, width) {
            doc.y = height;
            doc.x = width; //270;
            doc.fillColor('black');
            doc.font('Times-Roman', 13)
                .text(text, {
                    paragraphGap: 5,
                    indent: 5,
                    align: 'justify',
                    columns: 1
                });
            return doc
        }

        function row(doc, height) {
            doc.lineJoin('miter')
                .rect(30, height, 500, 20)
                .stroke();
            return doc
        }

        function addToCoordinates(coordinatesObj, number){
            coordinatesObj.rowHeight += number;
            coordinatesObj.leftHeight += number;
            coordinatesObj.rightHeight += number;

            return coordinatesObj
        }

        // Converts image to base64 url string (pdfKit need it this way)
        function toDataURL(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function() {
                var reader = new FileReader();
                reader.onloadend = function() {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }
    }

})();
