/**
 * Created by Teo on 03/05/2019.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CustomProceduresController', CustomProceduresController);

    CustomProceduresController.$inject = ['$http', '$filter', 'CustomUtils'];

    function CustomProceduresController ($http, $filter, CustomUtils) {
        var vm = this;
        vm.pageHeading = $filter('translate')('global.menu.admin.custom-procedures');
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(64);
        vm.showProgress = false;

        vm.startProcess = function () {
            vm.showProgress = true;
        };
    }
})();
