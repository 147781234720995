/**
 * Created by Kristy on 24/5/2017.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminEmployeeViewSectorsController', AdminEmployeeViewSectorsController);

    AdminEmployeeViewSectorsController.$inject = ['$http', '$filter', 'KendoFilter', 'EmployeeViewService', 'CustomUtils', 'customersView'];

    function AdminEmployeeViewSectorsController ($http, $filter, KendoFilter, EmployeeViewService, CustomUtils, customersView) {
        var vm = this;
        vm.customerView = customersView.data.content[0].value;
        vm.selectedUser = EmployeeViewService.getSelectedUser();
        vm.selectedAssignees = [];
        vm.massSelect = vm.myTarget = true;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(60);

        vm.openWindow = function(){
            vm.selectedEmployees = [];

            vm.customerSectorSuggestGridOptions.dataSource.page(1);
            vm.customerSectorSuggestGridOptions.dataSource.filter({});
            vm.customerSectorSuggestGridOptions.dataSource.read();

            var wdw = angular.element("#popup-customer-sector").data("kendoWindow");
            wdw.center().open();
        };

        vm.reAssign = function () {

            vm.saveAssignees = [];

            vm.selectedAssignees.forEach(function(item,key){

                if(item.customerSector.sectorPosition) {
                    if(!item.customerSector.sectorPosition.name)
                        item.customerSector.sectorPosition = null;
                }

                vm.saveAssignees.push(
                    {
                        id: item.customerSector.id,
                        customer : {
                            id : item.customerSector.customer.id
                        },
                        sector : {
                            id : item.customerSector.sector.id
                        },
                        sectorPosition : item.customerSector.sectorPosition,
                        startedAt :  kendo.toString(item.customerSector.startedAt,"yyyy-MM-dd"),
                        endedAt : kendo.toString(item.customerSector.endedAt,"yyyy-MM-dd"),
                        phone : item.customerSector.phone,
                        employeeCustomerSectors :[
                            {active: false,
                                employee: {
                                    id: vm.selectedUser.id
                                },
                                id: item.id
                            }
                        ]
                    }
                )
            });

            var url = 'api/customer-sectors/all';

            $http.post(url, vm.saveAssignees)
                .success(function (response) {
                    angular.element("#customerSectorsGrid").data("kendoGrid").dataSource.read();
                    vm.selectedAssignees = [];
                })
                .error(function (response) {
                    console.log(response);
                });
        };

        vm.customerSectorGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {

                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "active", "operator": "eqbool",  "value": true }
                            ]
                        );

                        var url = "/api/employee/"+vm.selectedUser.id+"/customer-sectors";

                        $http.get(url,
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (options, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(options.data.models);
                        } else {
                            return data;
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        if(data.content != undefined) {
                            data.content.forEach(function(item){

                                if(item.customerSector === null){
                                    item.customerSector = {};
                                }

                                if(item.customerSector.sector === null){
                                    item.customerSector.sector = {};
                                    item.customerSector.sector.name = "";
                                    item.customerSector.sector.parent = {}
                                } else if (item.customerSector.sector.parent === null) {
                                    item.customerSector.sector.parent = { name: "" };
                                }

                                if(item.customerSector.sectorType === null){
                                    item.customerSector.sectorType = {};
                                    item.customerSector.sectorType.name = "";
                                }

                                if(item.customerSector.sectorPosition === null){
                                    item.customerSector.sectorPosition = {};
                                    item.customerSector.sectorPosition.name = "";
                                }

                                if(item.customerSector.customer === null){
                                    item.customerSector.customer = {};
                                    item.customerSector.customer.lastName = "";
                                    item.customerSector.customer.firstName = "";
                                    item.customerSector.customer.doctorSpecialty = {};
                                    item.customerSector.customer.doctorSpecialty.name = "";
                                }

                                if (item.customerSector.customer.doctorSpecialty === null){
                                    item.customerSector.customer.doctorSpecialty = {};
                                    item.customerSector.customer.doctorSpecialty.name = "";
                                }

                                if (item.customerSector.customer.customerCategory === null) {
                                    item.customerSector.customer.customerCategory = {};
                                    item.customerSector.customer.customerCategory.name = "";
                                }

                                if (item.customerSector.customer.customerSubCategory === null) {
                                    item.customerSector.customer.customerSubCategory = {};
                                    item.customerSector.customer.customerSubCategory.name = "";
                                }

                                if (item.myTarget === null || item.myTarget === undefined) {
                                    item.myTarget = false;
                                }

                            });
                        }

                        return data.content;
                    },
                    total : function(data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            'customerSector.startedAt': { type: 'date'},
                            'customerSector.endedAt': { type: 'date'},
                            sectorParent: { from: "customerSector.sector.parent", defaultValue : { id : null, name : null} }
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            height: vm.contentHeight,
            sortable: true,
            pageable: true,
            selectable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "customerSector.sector.name", title: "Όνομα Μονάδας Υγείας", width: 150,
                    filterable: {
                        cell: {
                            operator: "like",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "customerSector.sector.sectorType.name",
                    title: "Τύπος",
                    filterable: {
                        cell: {
                            operator: "like",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    title: "Επαγγελματίας Υγείας",
                    columns: [
                        {
                            field: "customerSector.customer.lastName",
                            title: "Επώνυμο",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {
                            field: "customerSector.customer.firstName",
                            title: "Όνομα",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {
                            field: "customerSector.customer.doctorSpecialty.name",
                            title: "Ειδικότητα",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {
                            field: "customerSector.customer.customerCategory.name",
                            title: "Κατηγορία",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            },
                            hidden: vm.customerView !== '1'
                        },
                        {
                            field: "customerSector.customer.customerSubCategory.name",
                            title: "Υποκατηγορία",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            },
                            hidden: vm.customerView !== '1'
                        }
                    ]
                },
                {
                    field: "sectorParent.name", title: "Ανήκει στη Μ.Υ.",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    hidden: vm.customerView === '1' || vm.customerView === '6'
                },
                {field: "customerSector.sector.address", title: "Διεύθυνση",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "customerSector.sector.addressNum", title: "Αριθμός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "customerSector.sector.postalCode", title: "T.K.",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    hidden: vm.customerView === '1' || vm.customerView === '6'
                },
                {field: "customerSector.sector.cityName", title: "Πόλη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "customerSector.sector.prefectureName", title: "Νομός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "customerSector.sector.brick", title: "Brick",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "customerSector.sector.osfeBrick", title: "Brick ΟΣΦΕ",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "customerSector.sectorPosition.name", title: "Νοσοκομειακή Θέση",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    hidden: vm.customerView === '1' || vm.customerView === '6'
                },
                {field: "customerSector.phone", title: "Τηλέφωνο",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    hidden: vm.customerView === '1' || vm.customerView === '6'
                },
                {field: "customerSector.startedAt", title: "Ημερομηνία Έναρξης", template: "#= (customerSector.startedAt == null) ? '' : kendo.toString(kendo.parseDate(customerSector.startedAt), 'dddd, dd/MM/yyyy')#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    }
                },
                {field: "customerSector.endedAt", title: "Ημερομηνία Λήξης", template: "#= (customerSector.endedAt == null) ? '' : kendo.toString(kendo.parseDate(customerSector.endedAt), 'dddd, dd/MM/yyyy')#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "myTarget",
                    title: "MyTarget",
                    filterable: {
                        cell: {
                            operator: "eqbool",
                            showOperators: false,
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Ναι", value: "true" },
                                            { text: "Όχι", value: "false" }]
                                    }),
                                    valuePrimitive: true
                                })
                            }
                        }
                    },
                    template: "#= (myTarget == '1') ? 'Ναι' : 'Όχι' #",
                    hidden: vm.customerView !== '1'
                }
            ],
            toolbar: [
                {
                    name: "Assign",
                    text: "Assign Customer Sector",
                    template: "<input id='assignToSectorsBtn' type='button' class='k-button' value = 'Ανάθεση σε Μονάδες Υγείας' ng-click='vm.openWindow()' />"//ng-if='vm.canEdit'
                },
                {
                    name: "ReAssign",
                    text: "ReAssign",
                    template: function (dataItem) {
                        return "<input type='button' class='k-button' value = 'Διαγραφή Ιατρικού Επισκέπτη από Μονάδα Υγείας' ng-click='vm.reAssign()' />" ;//ng-if='vm.canEdit'
                    }
                },
                { template: "<div class='right'><label>Σύνολο: {{vm.customerSectorGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }
            ],
            selectedItems: function(dataItems) {
                vm.selectedAssignees = dataItems;
            },
            dataBound: function (e) {
                angular.element("#customerSectorsGridcheckbox").prop('checked', false);
                //toggle class on click
                angular.element(".checkbox").bind("change", function (e) {
                    angular.element(e.target).closest("tr").toggleClass("k-state-selected");
                });

                var view = this.dataSource.data();
                var me = this;

                var selectedAssigneesViewCounter = 0;
                angular.forEach( view, function(item) {
                    var foundItemById = $filter('filter')(vm.selectedAssignees, {id: item.id}, true)[0];
                    if(vm.selectedAssignees.indexOf(foundItemById) !== -1){
                        me.tbody.find("tr[data-uid='" + item.uid + "']")
                            .addClass("k-state-selected");
                        item.selected = true;
                        selectedAssigneesViewCounter++
                    }
                });
                if(selectedAssigneesViewCounter == view.length) {
                    angular.element("#customerSectorsGridcheckbox").prop('checked', true);
                }
            }
        };

        vm.gridColumns = [
            {
                field: "sector.name", title: "Όνομα Μονάδας Υγείας",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "sector.sectorType.name", title: "Τύπος",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                title: "Επαγγελματίας Υγείας",
                columns: [
                    {
                        field: "customer.lastName",
                        title: "Επώνυμο Επαγγελματία Υγείας",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    {
                        field: "customer.firstName",
                        title: "Όνομα Επαγγελματία Υγείας",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    },
                    {
                        field: "customer.doctorSpecialty.name",
                        title: "Ειδικότητα",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        }
                    }
                ]
            },
            {field: "sector.postalCode", title: "T.K.",
                filterable: {
                    cell: {
                        operator: "contains",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "sector.cityName", title: "Πόλη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "sector.prefectureName", title: "Νομός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "sector.brick", title: "Brick",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "sector.osfeBrick", title: "Brick ΟΣΦΕ",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "sectorPosition.name", title: "Νοσοκομειακή Θέση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "phone", title: "Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "startedAt", title: "Ημερομηνία Έναρξης", template: "#= (startedAt == null) ? '' : kendo.toString(kendo.parseDate(startedAt), 'dddd, dd/MM/yyyy')#",
                filterable: {
                    cell: {
                        operator: "eq",
                        showOperators: false
                    }
                }
            },
            {field: "endedAt", title: "Ημερομηνία Λήξης", template: "#= (endedAt == null) ? '' : kendo.toString(kendo.parseDate(endedAt), 'dddd, dd/MM/yyyy')#",
                filterable: {
                    cell: {
                        operator: "eq",
                        showOperators: false
                    }
                }
            }
        ];

        //This is the POPUP grid
        vm.customerSectorSuggestGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        // if(vm.canEdit) {
                        $http.get('/api/customer-sectors/employees/' + vm.selectedUser.id + '/customer-sectors/suggest',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                        // }
                    },
                    parameterMap: function (data, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        } else {
                            return data;
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        if(data != undefined) {
                            data.content.forEach(function(item){


                                if(item.sector === null){
                                    item.sector = {};
                                    item.sector.name = ""
                                }

                                if(item.sector.sectorType === null){
                                    item.sector.sectorType = {};
                                    item.sector.sectorType.name = "";
                                }

                                if(item.sectorPosition === null){
                                    item.sectorPosition = {};
                                    item.sectorPosition.name = "";
                                }


                                if(item.customer === null){
                                    item.customer = {};
                                    item.customer.lastName = "";
                                    item.customer.firstName = "";
                                    item.customer.doctorSpecialty = {};
                                    item.customer.doctorSpecialty.name = "";
                                }

                                if (item.customer.doctorSpecialty === null){
                                    item.customer.doctorSpecialty = {};
                                    item.customer.doctorSpecialty.name = "";
                                }

                            });
                        }
                        return data.content;
                    },
                    total : function(data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            sector: {defaultValue : { id : null, name : null}},
                            sectorPosition: {defaultValue : { id : null, name : null}},
                            startedAt: { type: 'date'},
                            endedAt: { type: 'date', defaultValue : null},
                            customer: { defaultValue : { id : null, firstName : null, lastName : null}}
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 10
            }),
            sortable: true,
            pageable: true,
            selectable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: vm.gridColumns,
            selectedItems: function(dataItems) {
                vm.selectedEmployees = dataItems;
            },
            dataBound: function (e) {
                angular.element("#customerSectorSuggestGridcheckbox").prop('checked', false);
                //toggle class on click
                angular.element(".checkbox").bind("change", function (e) {
                    angular.element(e.target).closest("tr").toggleClass("k-state-selected");
                });

                var view = this.dataSource.data();
                var me = this;

                if (vm.selectedEmployees) {
                    var selectedEmployeesViewCounter = 0;
                    angular.forEach( view, function(item) {
                        var foundItemById = $filter('filter')(vm.selectedEmployees, {id: item.id}, true)[0];
                        if(vm.selectedEmployees.indexOf(foundItemById) !== -1){
                            me.tbody.find("tr[data-uid='" + item.uid + "']")
                                .addClass("k-state-selected");
                            item.selected = true;
                            selectedEmployeesViewCounter++
                        }
                    });
                    if(selectedEmployeesViewCounter == view.length) {
                        angular.element("#customerSectorSuggestGridcheckbox").prop('checked', true);
                    }
                }
            },
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.customerSectorSuggestGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }]
        };

        vm.closeWindow = function() {
            var wdw = angular.element("#popup-customer-sector").data("kendoWindow");
            wdw.close();
        };

        vm.saveCustomerSectors = function() {
            var wdw = angular.element("#popup-customer-sector").data("kendoWindow");


            angular.forEach( vm.selectedEmployees, function(selectedEmployee) {
                selectedEmployee.myTarget = vm.myTarget;
            });

            $http.post(' api/customer-sectors/employee/'+ vm.selectedUser.id, vm.selectedEmployees)
                .success(function (response) {
                    wdw.close();
                    vm.selectedEmployees = [];
                    angular.element("#customerSectorsGrid").data("kendoGrid").dataSource.read();
                    angular.element("#customerSectorsGrid").data("kendoGrid").refresh();
                })
                .error(function (response) {
                    console.log(response);
                });

        };
    }

})();
