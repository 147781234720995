(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('RoomTypeDialogController', RoomTypeDialogController);

    RoomTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'RoomType'];

    function RoomTypeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, RoomType) {
        var vm = this;
        vm.roomType = entity;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:roomTypeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.roomType.id !== null) {
                RoomType.update(vm.roomType, onSaveSuccess, onSaveError);
            } else {
                RoomType.save(vm.roomType, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
