/**
 * Created by Kristy on 6/6/2017.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('HospitalizationViewController', HospitalizationViewController);

    HospitalizationViewController.$inject = ['$scope', '$http', '$filter', '$stateParams', '$state', '$timeout', 'HospitalizationGrids', 'KendoGrid', 'KendoService', 'NotificationOptions', 'KendoNotifications', 'Hospitalization', 'CustomerSector', 'CalculateAge', 'Patient', 'Customer', 'Sector', 'Alerts', 'LeadCall', 'HospitalizationService'];

    function HospitalizationViewController ($scope, $http, $filter, $stateParams, $state, $timeout, HospitalizationGrids, KendoGrid, KendoService, NotificationOptions, KendoNotifications, Hospitalization, CustomerSector, CalculateAge, Patient, Customer, Sector, Alerts, LeadCall, HospitalizationService) {
        var vm = this;
        var dataSource = {};
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");
        var watchGroup = ['vm.hospitalization.patient.fullName', 'vm.hospitalization.customer.fullName',
            'vm.hospitalization.sector', 'vm.isCustomerSectorRequired', 'vm.hospitalization.hospitalizationType.id'];
        vm.hospitalization = vm.patient = vm.customer = vm.sector = vm.leadCall = {};
        vm.isCustomerSectorConnected = vm.isCustomerSectorRequired = true;
        vm.editPatientMode = vm.editCustomerMode = vm.editSectorMode = vm.focusYearOfBirth = vm.focusAge = false;
        vm.today = new Date().getFullYear();
        vm.selectedGrid = "";
        setPageHeading(false);

        $scope.$watchGroup( watchGroup ,function(newValues, oldValues) {
            if(newValues === oldValues) return;

            if (newValues[0] !== oldValues[0] && !!newValues[0])
                vm.validator.validateInput(angular.element("input[name=patient]"));
            if(!newValues[0]) vm.hospitalization.patient = null;

            if (newValues[1] !== oldValues[1] && !!newValues[1])
                vm.validator.validateInput(angular.element("input[name=customer]"));
            if(!newValues[1]) vm.hospitalization.customer = null;

            if (newValues[2] !== oldValues[2] && !!newValues[2])
                vm.validator.validateInput(angular.element("select[name=sector]"));

            if (newValues[3] === false) {
                vm.validator.validateInput(angular.element("input[name=customer]"));
                vm.validator.validateInput(angular.element("select[name=sector]"));
            }

            if (newValues[4] !== oldValues[4] && !!newValues[4])
                vm.validator.validateInput(angular.element("select[name=hospitalizationType]"));
        });

        vm.validatorOptions = {
            rules: {
                custom: function (input) {
                    if (input.data('custom')) {
                        if (input.val() !== null && input.val() !== "" &&
                            angular.element(input.data('custom')).data("kendoComboBox").selectedIndex === -1) {
                            return false;
                        }
                    }
                    return true;
                }
            },
            messages: { custom: "Πρέπει να επιλέξετε από την λίστα" },
            validateOnBlur: false
        };

        vm.calculateAge = function() {
            if (vm.patient.yearOfBirth > vm.today)
                vm.patient.yearOfBirth = vm.today.toString();

            if ( vm.patient.yearOfBirth.length === 4 ) {
                var date = new Date();
                date.setFullYear(vm.patient.yearOfBirth);
                vm.patient.age = CalculateAge.getAgeFromBirthYear(date);
            } else {
                vm.patient.age = "";
            }
            vm.toggleKeypad('yearOfBirth');
        };
        vm.calculateYearOfBirth = function() {
            if (vm.patient.age) {
                vm.patient.yearOfBirth = CalculateAge.getBirthYearFromAge(vm.patient.age).getFullYear();
            } else {
                vm.patient.yearOfBirth = "";
            }
            vm.toggleKeypad('age');
        };

        vm.setData = function (model) {
            if( vm.hospitalization.customer && model.indexOf("customer") !== -1 ) {
                vm.hospitalization.customer.fullName =  vm.hospitalization.customer.lastName + " " +  vm.hospitalization.customer.firstName;
                // vm.customerOptions.dataSource.add(vm.hospitalization.customer);
            }
            if( vm.hospitalization.sector && model.indexOf("sector") !== -1 ) {
                vm.sectorOptions.dataSource.add(vm.hospitalization.sector);
            }
            if( vm.hospitalization.patient && model.indexOf("patient") !== -1 ) {
                vm.hospitalization.patient.fullName = vm.hospitalization.patient.lastName + " " + vm.hospitalization.patient.firstName;
                if ( vm.hospitalization.patient.yearOfBirth instanceof Date)
                    vm.hospitalization.patient.yearOfBirth = vm.hospitalization.patient.yearOfBirth.getFullYear();
            }
            if( vm.hospitalization.clinicName && model.indexOf("clinicName") !== -1 ) {
                vm.clinicNameOptions.dataSource.add(vm.hospitalization.clinicName);
            }
        };

        if ($stateParams.id) {
            Hospitalization.get({id : $stateParams.id})
                .$promise.then(function(data) {
                    vm.hospitalization = data;
                    if(vm.hospitalization) vm.setData(["customer", "sector", "patient", "clinicName"]);
                    filterCustomerSector();
                });
        } else {
            vm.hospitalization.dateIssue = new Date();
            if ($stateParams.patientId){
                Patient.get({id: $stateParams.patientId}, function (result) {
                    vm.patient = result;
                    vm.hospitalization.patient = result;
                    vm.setData(["patient"]);
                }, function (error) {
                    console.log(error);
                });
            }
            if ($stateParams.leadCallId){
                LeadCall.get({id: $stateParams.leadCallId}, function (result) {
                    vm.leadCall = result;
                    vm.hospitalization.leadCall = result;
                    setPageHeading(true);
                }, function (error) {
                   console.log(error);
                });
            }
        }

        function filterCustomerSector () {
            if (vm.hospitalization.customer && vm.hospitalization.sector) {
                var data = { filter: { filters: [] },
                    page: 1, pageSize: 1};
                data.filter.filters.push(
                    { "field": "customer.id", "operator": "eq",
                        "value": vm.hospitalization.customer.id },
                    { "field": "sector.id", "operator": "eq",
                        "value": vm.hospitalization.sector.id }
                );
                data.sort={"field":"id","dir":"desc"};
                data.active = true;

                //Show connect sector with customer message if CustomerSector doesn't exist
                $http.get('/api/me/customer-sectors',
                    {params: data})
                    .success(function (response) {
                        vm.isCustomerSectorConnected = response.content.length;
                    });

                //Suggest ClinicName according to last hospitalization record for the selected CustomerSector
                $http.get('/api/hospitalizations/search',
                    {params: data})
                    .success(function (response) {
                        if (response.content.length) {
                            if (response.content[0].clinicName) {
                                $timeout(function () {
                                    if (vm.hospitalization.sector)
                                        vm.clinicNameOptions.dataSource.data(vm.hospitalization.sector.clinicNames);

                                    var foundItemById =
                                        $filter('filter')(Array.from(vm.clinicNameOptions.dataSource.data()), {id: response.content[0].clinicName.id}, true)[0];
                                    if ( vm.clinicNameOptions.dataSource.data().indexOf(foundItemById) === -1) {
                                        vm.hospitalization.clinicName = response.content[0].clinicName;
                                        vm.clinicNameOptions.dataSource.add(vm.hospitalization.clinicName);
                                    }
                                });
                            }
                        }
                    });
            }
        }

        //CustomerTitle combobox edit configuration
        dataSource.customerTitlesDataSource = KendoService.initDataSource('/api/masterdata/customer-titles', 1, 20);
        vm.customerTitleOptions = KendoService.initDefaultOptions(dataSource.customerTitlesDataSource, "startswith", "name", "id");
        vm.customerTitleOptions.valuePrimitive = false;

        //ClinicName combobox edit configuration
        dataSource.clinicNamesDataSource = KendoService.initDataSource('/api/clinic-names/search', 1, 200);
        dataSource.clinicNamesDataSource.options.serverFiltering = false;
        vm.clinicNameOptions = KendoService.initDefaultOptions(dataSource.clinicNamesDataSource, "contains", "name", "id");
        vm.clinicNameOptions.valuePrimitive = false;
        vm.clinicNameOptions.dataBound = function () {
            if (vm.hospitalization.clinicName) {
                var foundClinicNameById =
                    $filter('filter')(Array.from(vm.clinicNameOptions.dataSource.data()), {id: vm.hospitalization.clinicName.id}, true)[0];
                if (!foundClinicNameById) {
                    vm.hospitalization.clinicName = {};
                    angular.element("#clinicName").data("kendoComboBox").value('');
                }
            }
        };

        //Sector combobox edit configuration
        dataSource.sectorsDataSource = KendoService.initDataSource('/api/me/sectors', 1, 20);
        vm.sectorOptions = KendoService.initDefaultOptions(dataSource.sectorsDataSource, "like", "name", "id");
        vm.sectorOptions.valuePrimitive = false;
        vm.sectorOptions.autoBind = false;
        vm.sectorOptions.change = function(){
            var self = this;
            filterCustomerSector();
            $timeout(function () {
                if (self.dataItem(self.select())) {
                    if (self.dataItem(self.select()).clinicNames.length) {
                        vm.clinicNameOptions.dataSource.data(self.dataItem(self.select()).clinicNames);
                    } else {
                        vm.clinicNameOptions.dataSource.read();
                    }
                }
            });
        };

        //RoomType combobox edit configuration
        dataSource.roomTypesDataSource = KendoService.initDataSource('/api/room-types/search', 1, 20);
        vm.roomTypeOptions = KendoService.initDefaultOptions(dataSource.roomTypesDataSource, "startswith", "name", "id");

        //FinalRoomType combobox edit configuration
        dataSource.finalRoomTypesDataSource = dataSource.roomTypesDataSource;
        vm.finalRoomTypeOptions = KendoService.initDefaultOptions(dataSource.finalRoomTypesDataSource, "startswith", "name", "id");

        //HospitalizationType combobox edit configuration
        dataSource.hospitalizationTypesDataSource = KendoService.initDataSource('/api/hospitalization-types/search', 1, 20);
        vm.hospitalizationTypeOptions = KendoService.initDefaultOptions(dataSource.hospitalizationTypesDataSource, "startswith", "name", "id");
        vm.hospitalizationTypeOptions.dataBound = vm.hospitalizationTypeOptions.change = vm.hospitalizationTypeOptions.cascade = function () {
            var value = this.value();
            $timeout( function() {
                vm.isCustomerSectorRequired = value === '3' || value === null || value === "" || value === undefined;
            } );
        };

        //DoctorSpecialty combobox edit configuration
        dataSource.doctorSpecialtiesDataSource = KendoService.initDataSource('/api/masterdata/doctor-specialties', 1, 20);
        vm.doctorSpecialtyOptions = KendoService.initDefaultOptions(dataSource.doctorSpecialtiesDataSource, "startswith", "name", "id");

        //SectorType combobox edit configuration
        dataSource.sectorTypesDataSource = KendoService.initDataSource('/api/masterdata/sector-types', 1, 20);
        vm.sectorTypeOptions = KendoService.initDefaultOptions(dataSource.sectorTypesDataSource, "startswith", "name", "id");

        vm.save = function () {
            if (vm.validator.validate()) {
                if (vm.hospitalization.clinicName)
                    if (!vm.hospitalization.clinicName.id) vm.hospitalization.clinicName = null;
                if (vm.hospitalization.hospitalizationType)
                    if (!vm.hospitalization.hospitalizationType.id) vm.hospitalization.hospitalizationType = null;
                if (vm.hospitalization.roomType)
                    if (!vm.hospitalization.roomType.id) vm.hospitalization.roomType = null;
                // if (vm.hospitalization.patient)
                //     if (!vm.hospitalization.patient.id) vm.hospitalization.patient = null;
                if (vm.hospitalization.sector)
                    if (!vm.hospitalization.sector.id) vm.hospitalization.sector = null;
                // if (vm.hospitalization.customer)
                //     if (!vm.hospitalization.customer.id) vm.hospitalization.customer = null;
                if (vm.hospitalization.finalRoomType)
                    if (!vm.hospitalization.finalRoomType.id) vm.hospitalization.finalRoomType = null;

                var cancel = false;
                var message = "";

                if (vm.hospitalization.patient === null ||
                    vm.hospitalization.patient === undefined ||
                    !vm.hospitalization.patient.id > 0){
                    cancel = true;
                    message = "Καταχωρήστε ή επιλέξτε Ασθενή";
                }

                if (!cancel && (vm.hospitalization.customer === null ||
                    vm.hospitalization.customer === undefined ||
                    !vm.hospitalization.customer.id > 0)){
                    cancel = true;
                    message = "Καταχωρήστε ή επιλέξτε Επαγγελματία Υγείας";
                }

                if (!cancel && (vm.hospitalization.sector === null ||
                    vm.hospitalization.sector === undefined ||
                    !vm.hospitalization.sector.id > 0)){
                    cancel = true;
                    message = "Καταχωρήστε ή επιλέξτε Νοσοκομείο";
                }

                if (cancel){
                    Alerts.showAlert("Sigma SalesForce", message, "Ok");
                }else {
                    if (vm.hospitalization.leadCall && vm.hospitalization.leadCall.id > 0){
                        vm.hospitalization.leadCall.leadHospitalizations = null;
                    }
                    Hospitalization.update(vm.hospitalization,
                        function () {
                            KendoNotifications.onSaveSuccess(notification);
                            vm.isCustomerSectorConnected = true;
                            vm.isCustomerSectorRequired = true;
                        },
                        function () {
                            KendoNotifications.onSaveError(notification)
                        });

                    //clear view if on new entry view
                    if (!$stateParams.id) {
                        vm.hospitalization = {};
                        vm.hospitalization.dateIssue = new Date();
                    }

                    setPageHeading(false);
                }
            } else {
                KendoNotifications.onSaveError(notification);
            }
        };

        vm.openWindow = function (grid) {
            vm.selectedGrid = grid;
            if (vm.popupGridOptions) KendoGrid.refresh(vm.popupGridOptions);
            vm.popupGridOptions = HospitalizationGrids.getGridOptions(grid);

            vm.window.title(HospitalizationGrids.getGridTitle(grid));
            vm.window.center().open();
        };

        vm.dataItemSelected = function () {
            var grid = angular.element("#popupGrid").data("kendoGrid");
            var selectedItem = grid.dataItem(grid.select());
            if (selectedItem) {
                switch (vm.popupGridOptions.grid) {
                    case "patient":
                        vm.hospitalization.patient = selectedItem;
                        vm.setData(["patient"]);
                        break;
                    case "customer":
                        vm.hospitalization.customer = selectedItem.customer;
                        vm.hospitalization.customerTitle = selectedItem.customer.customerTitle;
                        vm.hospitalization.sector = selectedItem.sector;
                        vm.setData(["customer", "sector"]);
                        filterCustomerSector();
                        $timeout(function () {
                            if (selectedItem.sector.clinicNames) {
                                if (selectedItem.sector.clinicNames.length) {
                                    vm.clinicNameOptions.dataSource.data(selectedItem.sector.clinicNames);
                                } else {
                                    vm.clinicNameOptions.dataSource.read();
                                }
                            }
                        });
                        break;
                    case "sector":
                        vm.hospitalization.sector = selectedItem;
                        vm.setData(["sector"]);
                        filterCustomerSector();
                        $timeout(function () {
                            if (selectedItem) {
                                if (selectedItem.clinicNames.length) {
                                    vm.clinicNameOptions.dataSource.data(selectedItem.clinicNames);
                                } else {
                                    vm.clinicNameOptions.dataSource.read();
                                }
                            }
                        });
                        break;
                    case "prospectivePatient":
                        // Get Patient by selected ProspectivePatient
                        var patientFilter = {
                            field: "prospectivePatient.id",
                            operator: "eq",
                            value: selectedItem.id
                        };
                        $http.get('/api/patients/search',
                            {params:{
                                    page: "1",
                                    pageSize: "200",
                                    filter : { filters: [patientFilter] }}})
                            .success(function (response) {
                                console.log(response.content);
                                if (response.content === null || response.content.length === 0){
                                    // There is not any Patient from selected ProspectivePatient, show convert dialog
                                    Alerts.showConfirmDialog(null, "Sigma SalesForce", "Ο Πιθανός Ασθενείς που επιλέχθηκε δεν υπάρχει στο σύστημα ως κανονικός Ασθενής. Μετατροπή σε Ασθενή τώρα?", true, "ΝΑΙ", "OXI", function () {
                                        var patientForSave = {
                                            firstName: selectedItem.firstName,
                                            lastName: selectedItem.lastName,
                                            email: selectedItem.email,
                                            yearOfBirth: selectedItem.birthYear,
                                            phone: selectedItem.phone,
                                            notes: selectedItem.notes,
                                            prospectivePatient: {
                                                id: selectedItem.id
                                            },
                                            amka: selectedItem.amka
                                        };
                                        console.log(patientForSave);
                                        Patient.save(patientForSave, function (result) {
                                            console.log(result);
                                            KendoNotifications.showSuccessNotification(notification, "Επιτυχής μετατροπή Πιθανού Ασθενή σε Ασθενή");
                                            vm.hospitalization.patient = result;
                                            vm.setData(["patient"]);
                                        }, function (error) {
                                            console.log(error);
                                            var errorMessage = "Κάποιο σφάλμα προέκυψε κατά την μετατροπή Πιθανού Ασθενή σε Ασθενή!";
                                            if (error.data.message &&
                                                error.data.message.includes("Cannot save Patient with ProspectivePatient id that exists")){
                                                errorMessage = "Ο Ασθενής υπάρχει ήδη!";
                                            }
                                            KendoNotifications.showErrorNotification(notification, errorMessage);
                                        });
                                    }, function () {
                                        // User cancel convert to Patient
                                    });
                                }else {
                                    // There is a Patient from selected Patient, continue as usual
                                    vm.hospitalization.patient = selectedItem;
                                    vm.setData(["patient"]);
                                }
                            })
                            .error(function (error) {
                                console.log(error);
                            });
                        break;
                    case "leadCall":
                        // First check if there is any Hospitalization with this selected leadCall.id
                        HospitalizationService.search({
                            page: 1, pageSize: 5,
                            filter: {
                                filters: [
                                    {
                                        field: "leadCall.id",
                                        operator: "eq",
                                        value: selectedItem.id
                                    }
                                ]
                            }
                        }).$promise.then(function (result) {
                            if (result.length > 0){
                                Alerts.showAlert("Sigma SalesForce", "Η Κλήση που επιλέξατε έχει γίνει ήδη Εισαγωγή!", "Ok");
                            }else {
                                // There aren't any Hospitalizations with this leadCall.id, continue
                                var selectedPatientFilter = {
                                    field: "prospectivePatient.id",
                                    operator: "eq",
                                    value: selectedItem.prospectivePatient.id
                                };
                                $http.get('/api/patients/search',
                                    {params:{
                                            page: "1",
                                            pageSize: "200",
                                            filter : { filters: [selectedPatientFilter] }}})
                                    .success(function (response) {
                                        if (response.content === null || response.content.length === 0){
                                            // There is not any Patient from selected ProspectivePatient, show convert dialog
                                            Alerts.showConfirmDialog(null, "Sigma SalesForce", "Ο Πιθανός Ασθενείς που επιλέχθηκε δεν υπάρχει στο σύστημα ως κανονικός Ασθενής. Μετατροπή σε Ασθενή τώρα?", true, "ΝΑΙ", "OXI", function () {
                                                var patientForSave = {
                                                    firstName: selectedItem.prospectivePatient.firstName,
                                                    lastName: selectedItem.prospectivePatient.lastName,
                                                    email: selectedItem.prospectivePatient.email,
                                                    yearOfBirth: selectedItem.prospectivePatient.birthYear,
                                                    phone: selectedItem.prospectivePatient.phone,
                                                    notes: selectedItem.prospectivePatient.notes,
                                                    prospectivePatient: {
                                                        id: selectedItem.prospectivePatient.id
                                                    },
                                                    amka: selectedItem.prospectivePatient.amka
                                                };
                                                Patient.save(patientForSave, function (result) {
                                                    console.log(result);
                                                    KendoNotifications.showSuccessNotification(notification, "Επιτυχής μετατροπή Πιθανού Ασθενή σε Ασθενή");
                                                    vm.hospitalization.patient = result;
                                                    // Also set LeadCall into Hospitalization
                                                    vm.leadCall = selectedItem;
                                                    vm.hospitalization.leadCall = selectedItem;
                                                    setPageHeading(true);
                                                    vm.setData(["patient"]);
                                                }, function (error) {
                                                    console.log(error);
                                                    var errorMessage = "Κάποιο σφάλμα προέκυψε κατά την μετατροπή Πιθανού Ασθενή σε Ασθενή!";
                                                    if (error.data.message &&
                                                        error.data.message.includes("Cannot save Patient with ProspectivePatient id that exists")){
                                                        errorMessage = "Ο Ασθενής υπάρχει ήδη!";
                                                    }
                                                    KendoNotifications.showErrorNotification(notification, errorMessage);
                                                });
                                            }, function () {
                                                // User cancel convert to Patient
                                            });
                                        }else {
                                            // There is a Patient from selected Patient, continue as usual
                                            vm.hospitalization.patient = response.content[0];
                                            // Also set LeadCall into Hospitalization
                                            vm.leadCall = selectedItem;
                                            vm.hospitalization.leadCall = selectedItem;
                                            setPageHeading(true);
                                            vm.setData(["patient"]);
                                        }
                                    })
                                    .error(function (error) {
                                        console.log(error);
                                    });
                            }
                        });
                        break;
                }
            }
            vm.window.close();
        };

        //Connect customer with sector
        vm.addCustomerSector = function() {
            var data = {
                customer: vm.hospitalization.customer,
                sector: vm.hospitalization.sector,
                startedAt: new Date()
            };

            CustomerSector.update(data,
                function() {
                    KendoNotifications.onSaveSuccess(notification);
                    vm.isCustomerSectorConnected = true;
                },
                function(){ KendoNotifications.onSaveError(notification) });
        };

        //Toggle view
        vm.toggle = function(model, action) {
            if (!model) {
                model = vm.popupGridOptions.grid;
                vm.window.close();
            }
            switch (model) {
                case "patient":
                    vm.editPatientMode = action === "add" || action === "edit";
                    if ( action === "add" || action === "erase") vm.patient = {};
                    if ( action === "edit" ) {
                        if (vm.hospitalization.patient.yearOfBirth) {
                            var date = new Date();
                            date.setFullYear(vm.hospitalization.patient.yearOfBirth);
                            vm.hospitalization.patient.age = CalculateAge.getAgeFromBirthYear(date);
                        }
                        vm.patient = vm.hospitalization.patient;
                        vm.patient.age = vm.hospitalization.patient.age.toString();
                        vm.patient.yearOfBirth = vm.hospitalization.patient.yearOfBirth.toString();
                    }
                    if ( action === "erase" ) vm.hospitalization.patient = {};
                    break;
                case "customer":
                    vm.editCustomerMode = action === "add" || action === "edit";
                    if ( action === "add" || action === "erase" ) vm.customer = {};
                    if ( action === "edit" ) vm.customer = vm.hospitalization.customer;
                    if ( action === "erase" ) vm.hospitalization.customer = {};
                    break;
                case "sector":
                    vm.editSectorMode = action === "add" || action === "edit";
                    if ( action === "add" ) vm.sector = {};
                    if ( action === "edit" ) vm.sector = vm.hospitalization.sector;
                    if ( action === "erase" ) {
                        vm.hospitalization.sector = null;
                        angular.element("#sector").data("kendoComboBox").value("");
                    }
                    break;
            }
        };

        //Update Patient, Customer & Sector
        vm.update = function(model) {
            switch (model) {
                case "patient":
                    // vm.patient.yearOfBirth = kendo.toString( vm.patient.yearOfBirth, 'yyyy' );
                    if (vm.patientValidator.validate()) {
                        Patient.update({}, vm.patient,
                            function (result) {
                                KendoNotifications.onSaveSuccess(notification);
                                vm.toggle("patient", "clear");
                                vm.hospitalization.patient = result;
                                vm.setData(["patient"]);
                            },
                            function () {
                                KendoNotifications.onSaveError(notification)
                            });
                    } else {
                        KendoNotifications.onSaveError(notification);
                    }
                    break;
                case "customer":
                    vm.customer.isActive = true;
                    if (vm.customerValidator.validate()) {
                        Customer.update({}, vm.customer,
                            function( result ){
                                KendoNotifications.onSaveSuccess(notification);
                                vm.toggle("customer", "clear");
                                vm.hospitalization.customer = result;
                                filterCustomerSector();
                                vm.setData(["customer"]);
                            },
                            function(){ KendoNotifications.onSaveError(notification) });
                    } else {
                        KendoNotifications.onSaveError(notification);
                    }
                    break;
                case "sector":
                    if (vm.sectorValidator.validate()) {
                        Sector.update({}, vm.sector,
                            function (result) {
                                KendoNotifications.onSaveSuccess(notification);
                                vm.toggle("sector", "clear");
                                vm.hospitalization.sector = result;
                                filterCustomerSector();
                                var foundItemById =
                                    $filter('filter')(Array.from(vm.sectorOptions.dataSource.data()), {id: vm.hospitalization.sector.id}, true)[0];
                                if ( vm.sectorOptions.dataSource.data().indexOf(foundItemById) === -1) {
                                    vm.sectorOptions.dataSource.add(vm.hospitalization.sector);
                                    vm.clinicNameOptions.dataSource.read();
                                }

                                vm.setData(["sector"]);
                            },
                            function () {
                                KendoNotifications.onSaveError(notification)
                            });
                    } else {
                        KendoNotifications.onSaveError(notification);
                    }
                    break;
            }
        };

        vm.toggleKeypad = function(keypad) {
            switch (keypad) {
                case "yearOfBirth":
                    vm.focusYearOfBirth = true;
                    if(vm.patient.yearOfBirth) {
                        vm.focusYearOfBirth = !(vm.patient.yearOfBirth.toString().length === 4);
                    }
                    vm.focusAge = false;
                    break;
                case "age":
                    vm.focusAge = true;
                    if(vm.patient.age) {
                        vm.focusAge = !(vm.patient.age.toString().length >= 2);
                    }
                    vm.focusYearOfBirth = false;
                    break;
            }
        };

        vm.hideKeypadOnBlur = function(e, element) {
            switch (element) {
                case "yearOfBirth" :
                    vm.focusYearOfBirth = false;
                    if (e.relatedTarget)
                        vm.focusYearOfBirth = e.relatedTarget.parentNode.parentNode.id === "yearOfBirthKeypad";
                    break;
                case "age" :
                    vm.focusAge = false;
                    if (e.relatedTarget)
                        vm.focusAge = e.relatedTarget.parentNode.parentNode.id === "ageKeypad";
                    break;
                case "yearOfBirthKeypad" :
                    vm.focusYearOfBirth = false;
                    if (e.relatedTarget)
                        vm.focusYearOfBirth = e.relatedTarget.parentNode.parentNode.id === "yearOfBirth";
                    break;
                case "ageKeypad" :
                    vm.focusAge = false;
                    if (e.relatedTarget)
                        vm.focusAge = e.relatedTarget.parentNode.parentNode.id === "age";
                    break;
            }
        }

        function setPageHeading(fromLeadCall){
            if (fromLeadCall === true){
                vm.pageHeading = "Εισαγωγή από Κλήση #" + vm.hospitalization.leadCall.id;
            }else {
                vm.pageHeading = "Εισαγωγή";
            }
        }
    }
})();
