/**
 * Created by Kristy Kavvada on 4/1/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('Alerts', Alerts);

    Alerts.$inject = ['$mdDialog'];

    function Alerts ($mdDialog) {

        return {
            showAlert: showAlert,
            showAlertWithCloseCallback: showAlertWithCloseCallback,
            showConfirmDialog : showConfirmDialog,
            showPromptDialog: showPromptDialog
        };

        function showAlert (title, message, buttonOk) {
            $mdDialog.show(
                $mdDialog.alert()
                    .clickOutsideToClose(true)
                    .title(title)
                    .textContent(message)
                    .ariaLabel('Sigma Salesfoce App Dialog')
                    .ok(buttonOk)
            );
        }

        /**
         * Displays a Dialog
         * @param title Dialog's title
         * @param message Dialog's message
         * @param buttonOk Dialog's button label
         * @param onDismissCallback A callback to run when dialog will be dismissed.
         */
        function showAlertWithCloseCallback (title, message, buttonOk, onDismissCallback){
            $mdDialog.show(
                $mdDialog.alert()
                    .clickOutsideToClose(true)
                    .onRemove(onDismissCallback)
                    .title(title)
                    .textContent(message)
                    .ariaLabel('Sigma Salesfoce App Dialog')
                    .ok(buttonOk)
            );
        }

        /**
         * Displays a confirm Dialog with custom actions onConfirm and onCancel.
         * @param ev Event, usually $event from html. Can be null. If set, dialog comes animated from the button that clicked
         * @param title Dialog's title
         * @param message Dialog's message
         * @param clickOutsideToClose Whether to click outside of dialog to close
         * @param buttonOkLabel Dialog's label for confirm button
         * @param buttonCancelLabel Dialog's label for cancel button
         * @param onConfirmCallback Function to run onConfirm
         * @param onCancelCallback Function to run onCancel
         */
        function showConfirmDialog(ev, title, message, clickOutsideToClose, buttonOkLabel, buttonCancelLabel, onConfirmCallback, onCancelCallback) {
            var confirm = $mdDialog.confirm()
                .clickOutsideToClose(clickOutsideToClose)
                .title(title)
                .textContent(message)
                .ariaLabel('Sigma SalesForce')
                //.targetEvent(ev)
                .ok(buttonOkLabel)
                .cancel(buttonCancelLabel);
            if (ev !== null){ confirm.targetEvent(ev); }
            $mdDialog.show(confirm).then(onConfirmCallback, onCancelCallback);
        }

        /**
         * Displays a prompt dialog for user input. It has custom actions onConfirm and onCancel.
         * @param ev Event, usually $event from html. Can be null. If set, dialog comes animated from the button that clicked
         * @param title Dialog's title
         * @param message Dialog's message
         * @param placeHolder Input's placeholder text
         * @param initValue Input's initial value
         * @param buttonOkLabel Dialog's label for confirm button
         * @param buttonCancelLabel Dialog's label for cancel button
         * @param onConfirmCallback Function to run onConfirm
         * @param onCancelCallback Function to run onCancel
         */
        function showPromptDialog(ev, title, message, placeHolder, initValue, buttonOkLabel, buttonCancelLabel, onConfirmCallback, onCancelCallback){
            var confirm = $mdDialog.prompt()
                .title(title)
                .textContent(message)
                .placeholder(placeHolder)
                .ariaLabel(placeHolder)
                .initialValue(initValue)
                .targetEvent(ev)
                .ok(buttonOkLabel)
                .cancel(buttonCancelLabel);
            $mdDialog.show(confirm).then(onConfirmCallback, onCancelCallback);
        }
    }

})();
