(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SocialInsuranceDetailController', SocialInsuranceDetailController);

    SocialInsuranceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'SocialInsurance'];

    function SocialInsuranceDetailController($scope, $rootScope, $stateParams, entity, SocialInsurance) {
        var vm = this;
        vm.socialInsurance = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:socialInsuranceUpdate', function(event, result) {
            vm.socialInsurance = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
