(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminUsersController', AdminUsersController);

    AdminUsersController.$inject = ['$scope', 'Principal', 'KendoGrid', 'ApplicationParameters','$http', 'KendoFilter', 'CustomUtils'];

    function AdminUsersController ($scope, Principal, KendoGrid, ApplicationParameters, $http, KendoFilter, CustomUtils) {
        var vm = this;
        vm.canEdit = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_REC_ADMINISTRATOR', 'ROLE_REC_FRONT_DESK']);
        vm.pageHeading = 'Χρηστες';
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(65);

        vm.onAddUserClick = function () {
            const grid = $("#mainGrid").data("kendoGrid");
            grid.addRow();
        };

        vm.gridColumns = [
            {
                field: "login",
                title: "Όνομα σύνδεσης",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "lastName",
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "email",
                title: "Email",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "activated",
                title: "Ενεργός",
                template: "#= (activated == '1') ? 'Ναι' : 'Όχι' #",
                filterable: {
                    cell: {
                        operator: "eqbool",
                        showOperators: false,
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "Ναι", value: "true" },
                                        { text: "Όχι", value: "false" }]
                                }),
                                valuePrimitive: true
                            })
                        }
                    }
                }
            },
            {
                field: "authorities",
                title: "Ρόλοι",
                filterable: false,
                template: "#= authorities ? authorities.join(', '): ''#",
                editor: function (container, options) {
                    var dataSource= new kendo.data.DataSource({
                        data: [ 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_HEALTH_VISITOR', 'ROLE_MANAGEMENT', 'ROLE_SUPERVISOR',
                            'ROLE_ACCOUNTING_DEP', 'ROLE_STOREHOUSE', 'ROLE_REC_MANAGEMENT', 'ROLE_REC_ADMINISTRATOR',
                            'ROLE_REC_HEALTH_VISITOR', 'ROLE_REC_FRONT_DESK', 'ROLE_BI_VIEWER', 'ROLE_CHECKER' ]
                    });
                    angular.element("<select id='authorities' multiple='multiple' data-bind='value : authorities'/>")
                        .appendTo(container)
                        .kendoMultiSelect({
                            dataSource: dataSource
                        });

                    var hasPatchModulePromise = ApplicationParameters.hasModule('Patches', false);
                    var hasVisitPharmaciesModulePromise = ApplicationParameters.hasModule('Visit_Pharmacies', false);
                    var hasVisitBeautyCentersModulePromise = ApplicationParameters.hasModule('Visit_Beauty_Centers', false);

                    hasPatchModulePromise.success(function(result) {
                        if(result.content[0].value === "1") {
                            dataSource.data().push('ROLE_PATCH_VISITOR');
                            angular.element('#authorities').data('kendoMultiSelect').value(options.model.authorities);
                        }
                    });

                    hasVisitPharmaciesModulePromise.success(function(result) {
                        if(result.content[0].value === "1") {
                            dataSource.data().push('ROLE_PHARM_VISITOR');
                            dataSource.data().push('ROLE_PHARM_SUPERVISOR');
                            angular.element('#authorities').data('kendoMultiSelect').value(options.model.authorities);
                        }
                    });

                    hasVisitBeautyCentersModulePromise.success(function(result) {
                        if(result.content[0].value === "1") {
                            dataSource.data().push('ROLE_BC_VISITOR');
                            angular.element('#authorities').data('kendoMultiSelect').value(options.model.authorities);
                        }
                    });
                }
            }
        ];

        vm.gridToolbar = [{ template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label></div>",
            overflow: "never" }];

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/users/with-criteria',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    create: function (o){

                        var data = o.data.models[0];

                        $http.post('/api/users', data)
                            .success(function (response) {
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    update: function (o){

                        var data = o.data.models[0];

                        $http.put('/api/users', data)
                            .success(function (response) {
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (data, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        } else {
                            return data;
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        if(data != undefined) {
                            data.content.forEach(function(item){

                            });
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: false },
                            email: { type: 'email', editable: true, nullable: false, defaultValue: null,
                                required: true,
                                validation: {
                                    emailValidation: function (input) {

                                        if(input[0].name == 'email') {
                                            var value=$.trim(input.val());
                                            if (value.length === 0) {
                                                input.attr("data-emailValidation-msg", "Το email χρήστη απαιτείται");
                                                return false;
                                            }
                                        }

                                        return true;
                                    }
                                }
                            },
                            password: { type: 'password', editable: true, nullable: false, defaultValue: null },
                            activated: {
                                type: "boolean",
                                defaultValue: true,
                                parse: function (value) {
                                    return !!(value == "1" || value == "true" || value == true);

                                }
                            },
                            authorities: { defaultValue: [] }
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 100
            }),
            sortable: true,
            pageable: {
                messages: {
                    display: "{0} - {1} από {2} Χρηστες", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                    empty: "Δεν βρέθηκαν Χρηστες",
                    page: "Σελίδα",
                    allPages: "Όλα",
                    of: "από {0}", // {0} is total amount of pages.
                    itemsPerPage: "εγγραφές ανά σελίδα",
                    first: "Πήγαινε στη πρώτη σελίδα",
                    previous: "Πήγαινε στη τελευταία σελίδα",
                    next: "Πήγαινε στην επόμενη σελίδα",
                    last: "Πήγαινε στη τελευταία σελίδα",
                    refresh: "Ανανέωση"
                }
            },
            noRecords: {
                template: "Δεν βρέθηκαν Χρηστες"
            },
            filterable: {
                mode: "row"
            },
            resizable: true,
            columns: vm.gridColumns,
            height: vm.contentHeight,
            edit: function(e) {
                if (e.model.isNew() == false) {
                    $('input[name *= "login"]').attr("disabled", true);
                }
                KendoGrid.defaultEditPopup(this._editContainer);
            },
            editable: {
                mode: "popup"
            }
        };

        vm.addEditFunctionality = function() {
            var grid = angular.element('#mainGrid').data('kendoGrid');
            vm.gridColumns.unshift({
                command: [
                    {name: "edit", text: ""}
                ],
                title: "&nbsp;",
                width: 80
            });
            grid.setOptions({
                columns: vm.gridColumns
            });
        };

        $scope.$on("kendoRendered", function () {
            if(vm.canEdit) vm.addEditFunctionality();
        });
    }
})();
