/**
 * Created by Teo on 21/06/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('followUpCalls', {
                parent: 'app',
                url: '/followUpCalls',
                data: {
                    authorities: ['ROLE_REC_MANAGEMENT', 'ROLE_REC_FRONT_DESK'],
                    pageTitle: 'sigmaCrmApp.global.menu.lead-management.followup-calls'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/followUpCalls/followUpCalls.html',
                        controller: 'FollowUpCallsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            /*.state('leadCallView', {
                parent: 'app',
                url: '/leadCalls/search?id:leadCallId',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_REC_ADMINISTRATOR'],
                    pageTitle: 'sigmaCrmApp.leadCall.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/leadCalls/leadCallsView.html',
                        controller: 'LeadCallsViewController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    professions: ['Profession', function (Profession) {
                        return Profession.query({page: 0, size: 200}).$promise;
                    }],
                    relationshipTypes: ['RelationshipType', function (RelationshipType) {
                        return RelationshipType.query({page: 0, size: 200}).$promise;
                    }],
                    nativeLanguages: ['NativeLanguage', function (NativeLanguage) {
                        return NativeLanguage.query({page: 0, size: 200}).$promise;
                    }],
                    callTypes: ['CallType', function (CallType) {
                        return CallType.query({page: 0, size: 200}).$promise;
                    }],
                    hospitalizationTypes: ['HospitalizationType', function (HospitalizationType) {
                        return HospitalizationType.query({page: 0, size: 200}).$promise;
                    }],
                    clinicNames: ['ClinicName', function (ClinicName) {
                        return ClinicName.query({page: 0, size: 200}).$promise;
                    }],
                    costTypes: ['CostType', function (CostType) {
                        return CostType.query({page: 0, size: 200}).$promise;
                    }],
                    roomTypes: ['RoomType', function (RoomType) {
                        return RoomType.query({page: 0, size: 200}).$promise;
                    }],
                    transportations: ['Transportation', function (Transportation) {
                        return Transportation.query({page: 0, size: 200}).$promise;
                    }],
                    discountReasons: ['DiscountReason', function (DiscountReason) {
                        return DiscountReason.query({page: 0, size: 200}).$promise;
                    }],
                    discountRates: ['DiscountRate', function (DiscountRate) {
                        return DiscountRate.query({page: 0, size: 200}).$promise;
                    }],
                    competitors: ['Competitor', function (Competitor) {
                        return Competitor.query({page: 0, size: 200}).$promise;
                    }],
                    addDiseases: ['AddDisease', function (AddDisease) {
                        return AddDisease.query({page: 0, size: 200}).$promise;
                    }],
                    addConditions: ['AddCondition', function (AddCondition) {
                        return AddCondition.query({page: 0, size: 200}).$promise;
                    }],
                    diseaseTypes: ['DiseaseType', function (DiseaseType) {
                        return DiseaseType.query({page: 0, size: 200}).$promise;
                    }],
                    diseases: ['Disease', function (Disease) {
                        return Disease.query({page: 0, size: 200}).$promise;
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('leadCall');
                        return $translate.refresh();
                    }]
                }
            })*/
    }
})();
