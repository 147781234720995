/**
 * Created by gmogas on 14/7/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchaseBacklogController', PurchaseBacklogController);

    PurchaseBacklogController.$inject = ['$http', '$filter', 'KendoFilter', 'KendoToolbar', 'KendoGrid'];

    function PurchaseBacklogController ($http, $filter, KendoFilter, KendoToolbar, KendoGrid) {
        var vm = this;
        vm.pageHeading = "Ανεκτελεστες ποσοτητες";

        vm.purchaseItemsGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                batch: false,
                transport: {
                    read: function (o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = {filters: []};
                        }

                        if(vm.from && vm.to) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "purchase.issueDate", "operator": "fromdate",
                                        "value": $filter('date')(vm.from, 'yyyy-MM-dd')},
                                    { "field": "purchase.issueDate", "operator": "todate",
                                        "value": $filter('date')(vm.to, 'yyyy-MM-dd')}
                                ]
                            );
                        }
                        if (o.data.sort === undefined || !o.data.sort.length) {
                            o.data.sort = { "field": "purchase.issueDate",  "dir": "desc" };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                {"field": "quantity", "operator": "greaterThan", "value": "quantityExecuted"},
                                {"field": "purchase.purchaseStatus.cancellable", "operator": "noteqbool", "value": true}
                            ]
                        );

                        angular.forEach(o.data.filter.filters, function (item) {
                            if (item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                            }
                        });

                        $http.get('/api/purchase-items/search',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema: {
                    data: function (data) {
                        if (data.content != undefined) {
                            data.content.forEach(function (item) {
                                if (item.product === null) item.product = {};
                                if (item.measurementUnit === null) item.measurementUnit = {};

                                item.offset = item.quantity - item.quantityExecuted;
                            });
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: {editable: false, nullable: true},
                            'purchase.issueDate': {type: "date"},
                            'product.code': {editable: false, nullable: true},
                            'product.name': {editable: false, nullable: true},
                            quantity: {type: "number", editable: true, nullable: true, validation: {min: 0}},
                            quantityExecuted: {editable: false, nullable: true, type: "number"},
                            offset: {editable: false, nullable: true, type: "number"},
                        }
                    }
                },
                aggregate: [
                    { field: "offset", aggregate: "sum" },
                    { field: "quantity", aggregate: "sum" },
                    { field: "quantityExecuted", aggregate: "sum" }
                ],
                pageSize: 100,
                serverPaging: true,
                serverSorting: true,
                serverFiltering: true
            }),
            filterable: {
                mode: "row"
            },
            sortable: true,
            pageable: true,
            toolbar: ["excel"],
            excel: {
                allPages: true,
                fileName: "Ανεκτέλεστες ποσότητες.xlsx"
            },
            columns: [
                {
                    field: "purchase.issueDate",
                    title: "Ημερομηνία παραγγελίας",
                    template: "#= kendo.toString(kendo.parseDate(purchase.issueDate), 'dddd, dd/MM/yyyy')#",
                    filterable: {
                        cell: {
                            operator: "eqdateonly",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "purchase.code",
                    title: "Κωδικός παραγγελίας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "purchase.sector.name",
                    title: "Επαγγελματίας Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "purchase.purchaseStatus.name",
                    title: "Κατάσταση παραγγελίας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "product.code",
                    title: "Κωδικός είδους",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "product.name",
                    title: "Περιγραφή είδους",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }

                },
                {
                    field: "quantity",
                    title: "Ποσότητα",
                    aggregates: ["sum"],
                    footerTemplate: "#=sum#",
                    filterable: false
                },
                {
                    field: "quantityExecuted",
                    title: "Εκτελεσμένη Ποσότητα",
                    aggregates: ["sum"],
                    footerTemplate: "#=sum#",
                    filterable: false
                },
                {
                    field: "offset",
                    title: "Ανεκτέλεστη Ποσότητα",
                    aggregates: ["sum"],
                    footerTemplate: "#=sum#",
                    filterable: false
                }
            ]
        };

        vm.masterFiltersOnClick = function(e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id === "today") {
                KendoToolbar.clearDatePickers();
                vm.from = date;
                vm.to = date;
            }
            else if (e.id === "week") {
                KendoToolbar.clearDatePickers();
                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6
                vm.from = firstWeekDay;
                vm.to = lastWeekDay;
            }
            else if (e.id === "month") {
                KendoToolbar.clearDatePickers();
                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                vm.from = firstMonthDay;
                vm.to = lastMonthDay;
            }
            else if (e.id === "year") {
                KendoToolbar.clearDatePickers();
                vm.from = new Date(date.getFullYear(), 0, 1);
                vm.to = new Date();
            }
            KendoToolbar.setFrom(vm.from);
            KendoToolbar.setTo(vm.to);
            KendoGrid.refresh(vm.purchaseItemsGridOptions);
        };

        vm.toolbarOptions = {
            items: KendoToolbar.getOptions(["buttonGroup", "year", "from", "to"], vm)
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.from = value;
                } else {
                    vm.from = null;
                }

                vm.masterFiltersOnClick(e);
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.to = value;
                } else {
                    vm.to = null;
                }

                vm.masterFiltersOnClick(e);
            }
        };

    }
})();
