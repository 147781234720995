/**
 * Created by Kristy on 26/7/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CustomerSectorsController', CustomerSectorsController);

    CustomerSectorsController.$inject = ['$http','$compile', '$state', '$stateParams', '$filter', 'Alerts', 'KendoGrid', 'NotificationOptions', '$scope', 'KendoFilter', 'KendoService', 'CustomerSector', 'CustomerSectorsService', 'WorkingDay', 'EmployeesService', 'CustomDataUtils', 'Principal', '$mdDialog', 'employee'];

    function CustomerSectorsController ($http, $compile, $state, $stateParams, $filter, Alerts, KendoGrid, NotificationOptions, $scope, KendoFilter, KendoService, CustomerSector, CustomerSectorsService, WorkingDay, EmployeesService, CustomDataUtils, Principal, $mdDialog, employee) {
        var vm = this;
        vm.employee = employee.data;
        vm.$state = $state;
        vm.selectedUserId = $stateParams.id;
        vm.kendoGrid = KendoGrid;
        EmployeesService.setHasAssignations(true);
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");
        var comboBoxes = ["#masterFilter","#sectorPosition", "#sectorType", "#prefectures", "#cities"]; //add "#regions" if wanted

        vm.refreshCombobox = function(filter, combobox) {
            combobox.filter(filter);
            combobox.read();
        };

        var employeeCustomerSectorsDisplay = function (data) {
            return data.employeeCustomerSectors.map(function(elem) {
                return elem.employee.account.fullName
            }).join(', ');
        };
        var timeToString = function(date) {
            return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        };

        //SectorPositions Combobox
        var sectorPositionsDataSource = KendoService.initDefaultDataSource('/api/masterdata/sector-positions');
        vm.sectorPositionComboBoxOptions = KendoService.initDefaultOptions(sectorPositionsDataSource, "startswith", "name", "id");

        vm.onNavigateToSectorClick = function (e) {
            e.preventDefault();
            var dataItem = this.dataItem($(e.currentTarget).closest("tr"));
            if (CustomDataUtils.isNotNil(dataItem.sector)) {
                if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SUPERVISOR'])){
                    // Navigate to Sector details
                    $state.go('sectorInfo', {id: dataItem.sector.id});
                }else {
                    // Only navigate if sector isPrivate
                    if (CustomDataUtils.isNotNil(dataItem.sector.sectorType) && dataItem.sector.sectorType.isPrivate === true) {
                        $state.go('sectorInfo', {id: dataItem.sector.id});
                    }else {
                        Alerts.showAlert("Sigma SalesForce", "Η μετάβαση στη καρτέλα της Μονάδας Υγείας ισχύει μόνο για Ιδιωτικές Μονάδες Υγείας", "ΟΚ");
                    }
                }
            }
        };

        vm.showEditCustomerSectorDialog = function (e) {
            e.preventDefault();
            var dataItem = this.dataItem($(e.currentTarget).closest("tr"));
            if (CustomDataUtils.isNotNil(dataItem.id)) {
                $http.get('/api/customer-sectors/' + dataItem.id)
                    .success(function (response) {
                        $mdDialog.show({
                            controller: 'EditCustomerSectorDialogCtrl',
                            templateUrl: 'app/components/edit-customer-sector-dialog/edit-customer-sector-dialog.html',
                            clickOutsideToClose: false,
                            locals: {customerSector: response}
                        }).then(function (result) {
                            // Success callback
                            if (CustomDataUtils.isNotNil(result)) {
                                notification.show({
                                    message: "Η ενημέρωση των στοιχείων ήταν επιτυχής!"
                                }, "success");
                                // Refresh Grid
                                vm.sectorsGridOptions.dataSource.read();
                            }
                        }, function (error) {
                            // Error callback
                            notification.show({
                                message: "Η ενημέρωση των στοιχείων απέτυχε!"
                            }, "error");
                        });
                    })
                    .error(function () {
                        Alerts.showAlert("Sigma SalesForce", "Κάποιο σφάλμα προέκυψε κατά τη λήψη Μονάδας Υγείας", "ΟΚ");
                    });
            }
        };

        var grids = { sectorsGrid: {}, employeesGrid: {} };
        grids.sectorsGrid.columns = [
            {command:[{name:"customEdit", text: "Επεξ.", click: vm.showEditCustomerSectorDialog}], attributes: {
                    "class": "smaller-k-edit-btn"
                }, title: "&nbsp;", width:57},
            {
                command:[
                    { name:"view", text: "Μ.Υ.", click: vm.onNavigateToSectorClick }
                ],
                attributes: { class: "smaller-k-edit-btn" },
                title: "&nbsp",
                width: 57
            },
            {
                field: "sector.name", title: "Μονάδα Υγείας",
                filterable: {
                    cell: {
                        operator: "contains",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 155
            },
            {
                field: "sector.sectorType.name",
                title: "Τύπος",
                filterable: {
                    cell: {
                        operator: "contains",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }, width: 110
            },
            {field: "sectorParent.name", title: "Ανήκει στη Μ.Υ.",
                filterable: {
                    cell: {
                        operator: "contains",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {field: "sector.address", title: "Διεύθυνση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 100
            },
            {field: "sector.addressNum", title: "Αριθμός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 40
            },
            {field: "sector.postalCode", title: "Τ.Κ.",
                filterable: {
                    cell: {
                        operator: "contains",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 45
            },
            {field: "sector.cityName", title: "Πόλη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 120
            },
            {field: "sector.prefectureName", title: "Νομός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 120
            },
            {field: "sector.brick", title: "Brick", hidden: true,
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "sector.osfeBrick", title: "Brick ΟΣΦΕ",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 100
            },
            {field: "monday", title: "Δευτέρα",
                filterable: false,
                sortable: false,
                width: 35,
                editor: function(container, options) {
                    if (options.model.monday === 'morning') {
                        options.model.monday = { value: 'morning', text: 'Πρωί' };
                    } else if (options.model.monday === 'afternoon'){
                        options.model.monday = { value: 'afternoon', text: 'Απόγευμα' };
                    } else if (options.model.monday === 'morningAfternoon'){
                        options.model.monday = { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα' }
                    }

                    angular.element('<input data-bind="value: monday.value" k-ng-model="monday"/>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: new kendo.data.DataSource({
                                data: [
                                    { value: 'morning', text: 'Πρωί'},
                                    { value: 'afternoon', text: 'Απόγευμα'},
                                    { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα'}
                                ]
                            }),
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "text",
                            dataValueField: "value"
                        });
                },
                template: "#= (monday == 'morning') ? 'Π' : (monday == 'afternoon') ? 'Α' : (monday == 'morningAfternoon') ? 'Π/Α' : '' #"
            },
            {field: "tuesday", title: "Τρίτη",
                filterable: false,
                sortable: false,
                width: 35,
                editor: function(container, options) {
                    if (options.model.tuesday === 'morning') {
                        options.model.tuesday = { value: 'morning', text: 'Πρωί' };
                    } else if (options.model.tuesday === 'afternoon'){
                        options.model.tuesday = { value: 'afternoon', text: 'Απόγευμα' };
                    } else if (options.model.tuesday === 'morningAfternoon'){
                        options.model.tuesday = { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα' }
                    }

                    angular.element('<input data-bind="value: tuesday.value" k-ng-model="tuesday"/>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: new kendo.data.DataSource({
                                data: [
                                    { value: 'morning', text: 'Πρωί'},
                                    { value: 'afternoon', text: 'Απόγευμα'},
                                    { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα'}
                                ]
                            }),
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "text",
                            dataValueField: "value"
                        });
                },
                template: "#= (tuesday == 'morning') ? 'Π' : (tuesday == 'afternoon') ? 'Α' : (tuesday == 'morningAfternoon') ? 'Π/Α' : '' #"
            },
            {field: "wednesday", title: "Τετάρτη",
                filterable: false,
                sortable: false,
                width: 35,
                editor: function(container, options) {
                    if (options.model.wednesday === 'morning') {
                        options.model.wednesday = { value: 'morning', text: 'Πρωί' };
                    } else if (options.model.wednesday === 'afternoon'){
                        options.model.wednesday = { value: 'afternoon', text: 'Απόγευμα' };
                    } else if (options.model.wednesday === 'morningAfternoon'){
                        options.model.wednesday = { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα' }
                    }

                    angular.element('<input data-bind="value: wednesday.value" k-ng-model="wednesday"/>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: new kendo.data.DataSource({
                                data: [
                                    { value: 'morning', text: 'Πρωί'},
                                    { value: 'afternoon', text: 'Απόγευμα'},
                                    { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα'}
                                ]
                            }),
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "text",
                            dataValueField: "value"
                        });
                },
                template: "#= (wednesday == 'morning') ? 'Π' : (wednesday == 'afternoon') ? 'Α' : (wednesday == 'morningAfternoon') ? 'Π/Α' : '' #"
            },
            {field: "thursday", title: "Πέμπτη",
                filterable: false,
                sortable: false,
                width: 35,
                editor: function(container, options) {
                    if (options.model.thursday === 'morning') {
                        options.model.thursday = { value: 'morning', text: 'Πρωί' };
                    } else if (options.model.thursday === 'afternoon'){
                        options.model.thursday = { value: 'afternoon', text: 'Απόγευμα' };
                    } else if (options.model.thursday === 'morningAfternoon'){
                        options.model.thursday = { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα' }
                    }

                    angular.element('<input data-bind="value: thursday.value" k-ng-model="thursday"/>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: new kendo.data.DataSource({
                                data: [
                                    { value: 'morning', text: 'Πρωί'},
                                    { value: 'afternoon', text: 'Απόγευμα'},
                                    { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα'}
                                ]
                            }),
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "text",
                            dataValueField: "value"
                        });
                },
                template: "#= (thursday == 'morning') ? 'Π' : (thursday == 'afternoon') ? 'Α' : (thursday == 'morningAfternoon') ? 'Π/Α' : '' #"
            },
            {field: "friday", title: "Παρασκευή",
                filterable: false,
                sortable: false,
                width: 35,
                editor: function(container, options) {
                    if (options.model.friday === 'morning') {
                        options.model.friday = { value: 'morning', text: 'Πρωί' };
                    } else if (options.model.friday === 'afternoon'){
                        options.model.friday = { value: 'afternoon', text: 'Απόγευμα' };
                    } else if (options.model.friday === 'morningAfternoon'){
                        options.model.friday = { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα' }
                    }

                    angular.element('<input data-bind="value: friday.value" k-ng-model="friday"/>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: new kendo.data.DataSource({
                                data: [
                                    { value: 'morning', text: 'Πρωί'},
                                    { value: 'afternoon', text: 'Απόγευμα'},
                                    { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα'}
                                ]
                            }),
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "text",
                            dataValueField: "value"
                        });
                },
                template: "#= (friday == 'morning') ? 'Π' : (friday == 'afternoon') ? 'Α' : (friday == 'morningAfternoon') ? 'Π/Α' : '' #"
            },
            {template: '<md-checkbox class="checkbox-toolbar customers green inline"  aria-label="main" ng-model="dataItem.main" ng-disabled="true"></md-checkbox>',
                field: "main", title: "Βασική Μονάδα",
                // editor: function(container){
                //     angular.element('<md-checkbox ng-model="dataItem.main"  aria-label="main" ' +
                //         'class="checkbox-toolbar green inline"></md-checkbox>')
                //         .appendTo(container);
                // },
                filterable: {
                    cell: {
                        operator: "eq",
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "Ναι", value: "true" },
                                        { text: "Όχι", value: "false" }]
                                }),
                                valuePrimitive: true

                            })
                        },
                        showOperators: false
                    }
                }, width: 70
            },
            {field: "sectorPosition.name", title: "Νοσοκομειακή Θέση", hidden: true,
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                editor: function(container) {
                    angular.element('<select id="sector-position" data-bind="value:sectorPosition.id"></select>')
                        .appendTo(container)
                        .kendoComboBox(vm.sectorPositionComboBoxOptions);
                }
            },
            {
                field: "phone", title: "Τηλέφωνο", hidden: true,
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "startedAt", title: "Ημερομηνία Έναρξης", hidden: true, template: "#= kendo.toString(kendo.parseDate(startedAt), 'dddd, dd/MM/yyyy')#",
                filterable: {
                    cell: {
                        operator: "eq",
                        showOperators: false
                    }
                }
            },
            {field: "endedAt", title: "Ημερομηνία Λήξης", hidden: true, template: "#= (endedAt == null) ? '' : kendo.toString(kendo.parseDate(endedAt), 'dddd, dd/MM/yyyy')#",
                filterable: {
                    cell: {
                        operator: "eq",
                        showOperators: false
                    }
                }
            }
        ];

        grids.sectorsGrid.dataSource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function(o) {
                    $http.get('/api/customers/' + vm.selectedUserId + '/me/customer-sectors')
                        .success(function (response) {
                            vm.checkEmployeeCustomerSectors(response);
                            o.success(response);
                        })
                        .error(function (response) {});
                },
                update: function(o) {
                    vm.saveSector = o.data.models[0];

                    //if deleted make active false
                    angular.forEach(vm.saveSector.employeeCustomerSectorsAll, function(item) {
                        var notFound = $filter('filter')(vm.saveSector.employeeCustomerSectors, {id: item.id}, true)[0];
                        if (vm.saveSector.employeeCustomerSectors.indexOf(notFound) === -1) {
                            item.active = false;
                            vm.saveSector.employeeCustomerSectors.push(item);
                        }
                    });

                    if(!vm.saveSector.sectorPosition.id) vm.saveSector.sectorPosition = null;
                    if(!vm.saveSector.sector.parent.id) vm.saveSector.sector.parent = null;

                    vm.saveSector.startedAt = kendo.toString(vm.saveSector.startedAt,"yyyy-MM-dd");
                    vm.saveSector.endedAt = kendo.toString(vm.saveSector.endedAt,"yyyy-MM-dd");

                    if (vm.saveSector.monday) {
                        vm.saveSector.monday = vm.saveSector.monday.value;
                    }
                    if (vm.saveSector.tuesday) {
                        vm.saveSector.tuesday = vm.saveSector.tuesday.value;
                    }
                    if (vm.saveSector.wednesday) {
                        vm.saveSector.wednesday = vm.saveSector.wednesday.value;
                    }
                    if (vm.saveSector.thursday) {
                        vm.saveSector.thursday = vm.saveSector.thursday.value;
                    }
                    if (vm.saveSector.friday) {
                        vm.saveSector.friday = vm.saveSector.friday.value;
                    }

                    CustomerSector.update({}, vm.saveSector,
                        function () {
                            angular.element("#sectorsGrid").data("kendoGrid").dataSource.read();
                        });
                }
            },
            schema: {
                data: function (data) {
                    if(data.length) {
                        data.forEach(function(item){
                            if(item.sector === null) item.sector = {};
                            else if (item.sector.parent === null) item.sector.parent = {};

                            if(item.sector.sectorType === null) item.sector.sectorType = {};
                            if(item.sectorPosition === null) item.sectorPosition = {};
                            if(item.employeeCustomerSectors === null){
                                item.employeeCustomerSectors = {
                                    employee: {
                                        account: {}
                                    }
                                };
                            }

                            if (!item.monday) item.monday = {};
                            if (!item.tuesday) item.tuesday = {};
                            if (!item.wednesday) item.wednesday = {};
                            if (!item.thursday) item.thursday = {};
                            if (!item.friday) item.friday = {};
                        });
                    }
                    return data;
                },
                parse: function(data) {
                    angular.forEach(data, function(item) {
                        if (item.startedAt != undefined) item.startedAt = kendo.toString(kendo.parseDate(item.startedAt), 'dd/MM/yyyy');
                        if (item.endedAt != undefined) item.endedAt = kendo.toString(kendo.parseDate(item.endedAt), 'dd/MM/yyyy');

                        //copy employeeCustomerSectors so to splice in foreach
                        var employeeCustomerSectorsToShow = angular.copy(item.employeeCustomerSectors);
                        //copy for backup
                        item.employeeCustomerSectorsAll = angular.copy(item.employeeCustomerSectors);
                        if(item.employeeCustomerSectors != null) {
                            angular.forEach(item.employeeCustomerSectors, function(employeeCustomerSector) {
                                if(employeeCustomerSector.active == false) {
                                    var foundItemById = $filter('filter')(employeeCustomerSectorsToShow, {id: employeeCustomerSector.id}, true)[0];
                                    employeeCustomerSectorsToShow.splice(employeeCustomerSectorsToShow.indexOf(foundItemById), 1);
                                }
                            });

                            item.employeeCustomerSectors = employeeCustomerSectorsToShow;
                            angular.forEach(item.employeeCustomerSectors, function(employeeCustomerSector) {
                                employeeCustomerSector.employee.account.fullName =
                                    employeeCustomerSector.employee.account.lastName + " " + employeeCustomerSector.employee.account.firstName;
                            });
                        }
                    });
                    return data;
                },
                model: {
                    id: "id",
                    fields: {
                        id: { editable: false, nullable: true },
                        sector: { defaultValue : { id : null, name : null} },
                        'sector.name': { editable: false },
                        sectorPosition: {defaultValue : { id : null, name : null}},
                        phone : { defaultValue : null},
                        startedAt: { type: 'date'},
                        endedAt: { type: 'date', defaultValue : null},
                        'employeeCustomerSectors': {},
                        sectorParent: { from: "sector.parent", defaultValue : { id : null, name : null} },
                        'sectorParent.name': { editable: false },
                        'sector.address': { editable: false },
                        'sector.addressNum': { editable: false },
                        'sector.postalCode': { editable: false },
                        'sector.brick': { editable: false },
                        'sector.osfeBrick': { editable: false },
                        'sector.cityName': { editable: false },
                        'sector.prefectureName': { editable: false },
                        main: { editable: true, type:"boolean" },
                        monday: { editable: true},
                        tuesday: { editable: true},
                        wednesday: { editable: true},
                        thursday: { editable: true},
                        friday: { editable: true}
                    }
                }
            }
        });
        vm.sectorsGridOptions = CustomerSectorsService.getSectorsOptions(grids.sectorsGrid.dataSource, grids.sectorsGrid.columns);
        angular.extend( vm.sectorsGridOptions, {
            edit: function(e) {
                // Fired when edit a row (pencil) from main grid)
                e.model.dirty = true;
                vm.employeeDataItems = angular.copy(e.model);

                if(!e.model.isNew()) {
                    if (e.model.main) angular.element("[name=main]").prop("disabled", true);
                }

                this._editContainer.addClass("default-popup");
                this._editContainer.addClass("two-columns");
                vm.kendoGrid.defaultCustomEditPopup(this._editContainer, 1000, 470);
                angular.element(e.container).data("kendoWindow").center();
            },
            save: function () {
                var customerSectorsGrid = angular.element("#sectorsGrid").data("kendoGrid");
                customerSectorsGrid.refresh();
            }
        });

        vm.windowOptions = {
            appendTo: '#customSectorWindow'
        };

        vm.openWindow = function(){
            EmployeesService.setInitPopupGrid(true);
            var wdw = angular.element("#popup-new-customSector").data("kendoWindow");
            vm.clearWindow();
            wdw.center().open();

            //fix position
            angular.element("#popup-new-customSector").closest(".k-window")
                .css({ top: angular.element(window).height()/8 });

            CustomerSectorsService.setIsSectorSaved(false);

            //clear dataItems selected
            vm.statusChanged = "opened";
        };

        vm.clearWindow = function () {
            //clear kendoComboBox
            angular.forEach(comboBoxes, function (item) {
                var filter = {};
                var comboBox = angular.element(item).data("kendoComboBox");
                if(comboBox) {
                    comboBox.value("");
                    if (item == "#cities") vm.refreshCombobox(filter, CustomerSectorsService.getCitiesComboboxOptions().dataSource);
                    else if (item == "#prefectures") vm.refreshCombobox(filter,  CustomerSectorsService.getPrefecturesComboboxOptions().dataSource);
                }
            });
        };

        // Not used
        vm.detailedGridOptions = function (dataItem) {
            if (dataItem != undefined) {
                return {
                    dataSource: {
                        batch: true,
                        transport: {
                            read: function(o) {
                                $http.get('api/customer-sectors/' + dataItem.id + '/working-days', {params: o.data})
                                    .success(function (response) {
                                        o.success(response);
                                    })
                                    .error(function (response) {});
                            },
                            create: function(o) {
                                vm.newWorkingHour = {
                                    firstShiftFrom : null,
                                    firstShiftTo : null,
                                    secondShiftFrom : null,
                                    secondShiftTo : null,
                                    weekDayNo : null,
                                    customerSector : { id : dataItem.id }
                                };

                                vm.saveWorkingHour = o.data.models[0];

                                if(vm.saveWorkingHour.firstShiftFrom != null)
                                    vm.newWorkingHour.firstShiftFrom = timeToString(vm.saveWorkingHour.firstShiftFrom);
                                if(vm.saveWorkingHour.firstShiftTo != null)
                                    vm.newWorkingHour.firstShiftTo = timeToString(vm.saveWorkingHour.firstShiftTo);
                                if(vm.saveWorkingHour.secondShiftFrom != null)
                                    vm.newWorkingHour.secondShiftFrom = timeToString(vm.saveWorkingHour.secondShiftFrom);
                                if(vm.saveWorkingHour.secondShiftTo != null)
                                    vm.newWorkingHour.secondShiftTo = timeToString(vm.saveWorkingHour.secondShiftTo);
                                if(vm.saveWorkingHour.weekDayNo != null)
                                    vm.newWorkingHour.weekDayNo = vm.saveWorkingHour.weekDayNo;

                                WorkingDay.update({}, vm.newWorkingHour,
                                    function () {
                                        angular.element("#workingHoursGrid").data("kendoGrid").dataSource.read();
                                    });
                            },
                            update: function(o) {
                                vm.saveWorkingHour = o.data.models[0];
                                WorkingDay.update({}, vm.saveWorkingHour,
                                    function () {
                                        angular.element("#workingHoursGrid").data("kendoGrid").dataSource.read();
                                    });
                            },
                            destroy: function(o) {
                                vm.deleteWorkingHourId = o.data.models[0].id;
                                var url = 'api/working-days/' + vm.deleteWorkingHourId;
                                $http.delete(url)
                                    .success(function (response) {
                                        o.success(response);
                                    })
                                    .error(function (response) {});
                            }
                        },
                        schema: {
                            model: {
                                id: "id",
                                fields: {
                                    id: {editable: false, nullable: true},
                                    weekDayNo: { editable: true, nullable: false, validation: { min:1, max:7} },
                                    firstShiftFrom: { editable: true, nullable: false },
                                    firstShiftTo: { editable: true, nullable: false },
                                    secondShiftFrom: { editable: true, nullable: true },
                                    secondShiftTo: { editable: true, nullable: true }
                                }
                            }
                        }
                    },
                    columns: [
                        {field: "weekDayNo", title: "Ημέρα", decimals: 0, step: 1, format: "{0:n0}", editor: CustomerSectorsService.getDateEditor, template: "#= weekDayNo == '1' ? 'Δευτέρα' : weekDayNo == '2' ? 'Τρίτη': weekDayNo == '3' ? 'Τετάρτη': weekDayNo == '4' ? 'Πέμπτη': weekDayNo == '5' ? 'Παρασκευή': weekDayNo == '6' ? 'Σάββατο': weekDayNo == '7' ? 'Κυριακή' : null #"},
                        {field: "firstShiftFrom", title: "1η βάρδια από", format:"{0:HH:mm}", editor: CustomerSectorsService.getTimeEditor, template: "#= kendo.toString(kendo.parseDate(firstShiftFrom), 'HH:mm') #"},
                        {field: "firstShiftTo", title: "1η βάρδια έως", format:"{0:HH:mm}", editor: CustomerSectorsService.getTimeEditor, template: "#= kendo.toString(kendo.parseDate(firstShiftTo), 'HH:mm') #"},
                        {field: "secondShiftFrom", title: "2η βάρδια από", format:"{0:HH:mm}", editor: CustomerSectorsService.getTimeEditor, template: "#= secondShiftFrom == null ? '' : kendo.toString(kendo.parseDate(secondShiftFrom), 'HH:mm') #"},
                        {field: "secondShiftTo", title: "2η βάρδια έως", format:"{0:HH:mm}", editor: CustomerSectorsService.getTimeEditor, template: "#= secondShiftTo == null ? '' : kendo.toString(kendo.parseDate(secondShiftTo), 'HH:mm') #"},
                        {command:[{name:"edit", text: "Επεξεργασία"}], title: "&nbsp;", width:140},
                        {command:[{name:"destroy", text: "Διαγραφή"}], title: "&nbsp;", width:140}
                    ],
                    toolbar: [{name :"create", text: "Προσθήκη Νέου Ωραρίου"}],
                    edit: function() { KendoGrid.defaultEditPopup(this._editContainer); },
                    editable: {
                        confirmation: "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτήν την εγγραφή;",
                        mode: "popup"
                    }
                };
            }
        };

        vm.toggleSelectAll = function (ev) {
            vm.allSelected = !vm.allSelected;
            var grid = angular.element(ev.target).closest("[kendo-grid]").data("kendoGrid");
            angular.forEach( grid.dataSource.data(), function( dataItem ) {
                dataItem.myTarget = vm.allSelected;
            });
        };

        vm.onMyTargetClick = function (dataItem) {
            dataItem.myTarget = !dataItem.myTarget;
            if (!dataItem.myTarget) {
                vm.allSelected = dataItem.myTarget;
            }
        };

        var customersPromise = $http.get('api/customers/' + vm.selectedUserId);
        customersPromise.then(function(response) {
            vm.customer = response.data;
            vm.allSelected = false;
            var foundItemById = $filter('filter')(vm.customer.customerTypes, {id: 1}, true)[0];

            if ( vm.customer.customerTypes.indexOf(foundItemById) !== -1) vm.extendSectorsGridColumns();
            else EmployeesService.setHasAssignations(false);
        });

        vm.extendSectorsGridColumns = function () {
            // Comment-out this because it makes grid to make a double get request SIGMASF-2407
            /*var grid = angular.element("#sectorsGrid").data("kendoGrid");
            grids.sectorsGrid.columns.push({
                field: "employeeCustomerSectors",
                title: "Ιατρικοί Επισκέπτες", hidden: true,
                filterable: false,
                template: employeeCustomerSectorsDisplay,
                editor: function (container, options) {
                    angular.element('<select id="employeeCustomerSectors" style="width:800px;" multiple="multiple" data-bind="value: employeeCustomerSectors"/>')
                        .appendTo(container)
                        .kendoMultiSelect({
                            dataSource: new kendo.data.DataSource({
                                serverFiltering: true,
                                transport: {
                                    read: function (o) {
                                        if (o.data.filter == undefined) {
                                            o.data.filter = {filters: []};
                                        }

                                        o.data.page = 1;
                                        o.data.pageSize = 10000;
                                        $http.get('/api/me/employees',
                                            {params: o.data})
                                            .success(function (response) {
                                                o.success(response.content);
                                            })
                                            .error(function (response) {});
                                    }
                                },
                                schema: {
                                    parse: function(data) {
                                        angular.forEach(data, function(item) {
                                            //define object for data binding
                                            if(item.account != null) {
                                                item.active = item.myTarget = true;
                                                item.employee = {
                                                    account : {
                                                        fullName: item.account.lastName + " " + item.account.firstName
                                                    },
                                                    id: item.id
                                                }
                                            }
                                            //if exists but active false keep employeeCustomerSectors id
                                            if ( vm.employeeDataItems.employeeCustomerSectorsAll ) {
                                                if (vm.employeeDataItems.employeeCustomerSectorsAll.length) {
                                                    var foundItemByEmployeeId = $filter('filter')(vm.employeeDataItems.employeeCustomerSectorsAll, {employee: {id: item.id}}, true)[0];
                                                    if (vm.employeeDataItems.employeeCustomerSectorsAll.indexOf(foundItemByEmployeeId) !== -1) {
                                                        item.id = foundItemByEmployeeId.id
                                                    } else {
                                                        item.id = null;
                                                    }
                                                } else {
                                                    item.id = null;
                                                }
                                            }

                                        });
                                        return data;
                                    }
                                }
                            }),
                            valuePrimitive: false,
                            dataTextField: "employee.account.fullName",
                            dataValueField: "employee.id"
                        });

                    vm.myTargetGridOptions = {
                        dataSource : new kendo.data.DataSource({
                            data: angular.element("#employeeCustomerSectors").data("kendoMultiSelect").dataItems(),
                            pageSize: 10,
                            schema: {
                                model: {
                                    id: "id",
                                    fields: {
                                        id: { editable: false, nullable: true },
                                        'employee.account.fullName': { editable: false },
                                        myTarget: { type: "boolean" }
                                    }
                                }
                            }
                        }),
                        sortable: true,
                        pageable: true,
                        scrolable: true,
                        editable: true,
                        filterable: false/!*{
                            mode: "row"
                        }*!/,
                        resizable: true,
                        columns: [
                            {
                                field: "employee.account.fullName",
                                title: "Επιλεγμένοι Ιατρικοί Επισκέπτες"/!*,
                                filterable: {
                                    cell: {
                                        operator: "startswith",
                                        template: function (args) {
                                            KendoFilter.getTemplate(args);
                                        },
                                        showOperators: false
                                    }
                                }*!/
                            },
                            {
                                template: '<input type="checkbox" ng-click="vm.onMyTargetClick( dataItem );" ng-checked="dataItem.myTarget" class="checkbox"/>', //
                                title: "<label>myTarget </label>" +
                                "<input id='checkboxMyTarget' type='checkbox' class='checkbox' ng-checked='vm.allSelected' " +
                                "ng-click='vm.toggleSelectAll($event)'/>",
                                width: 100,
                                filterable: false
                            }
                        ]
                    };

                    angular.element($compile('<div kendo-grid id="employeeCustomerSectorsGrid" style="width:800px;" k-options="vm.myTargetGridOptions" ' +
                        'data-bind="source: employeeCustomerSectors"></div>')($scope))
                        .appendTo(container);
                }
            });

            grid.setOptions({
                columns: grids.sectorsGrid.columns
            });*/
        };

        /**
         * Gets a list of customerSectors (sectorsGrid) and search if at least one CustomerSector
         * belongs to current logged Employee. CustomerSector.employeeCustomerSectors contains employee with id equals
         * to current logged employee. If it has not, it navigates user to /customers screen.
         * The check runs only for user that is not ['ROLE_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SUPERVISOR']
         * @param customerSectorList A list of CustomerSectors
         */
        vm.checkEmployeeCustomerSectors = function (customerSectorList) {
            if (!Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SUPERVISOR'])) {
                var mineEcs = 0;
                if (CustomDataUtils.isNotEmpty(customerSectorList)) {
                    customerSectorList.forEach(function (cs) {
                        if (CustomDataUtils.isNotEmpty(cs.employeeCustomerSectors)) {
                            cs.employeeCustomerSectors.forEach(function (ecs) {
                                if (CustomDataUtils.isNotNil(ecs.employee) && ecs.active === true && ecs.employee.id === vm.employee.id ) {
                                    mineEcs++;
                                }
                            });
                        }
                    });
                }

                if (mineEcs === 0) {
                    $state.go('adminCustomers');
                }
            }
        }
    }
})();
