/**
 * Created by Teo on 05/04/2019.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchasesAggregatedController', PurchasesAggregatedController);

    PurchasesAggregatedController.$inject = ['$state'];

    function PurchasesAggregatedController ($state) {
        var vm = this;
        vm.$state = $state;
        vm.pageHeading = "Συνολα & Συγκριτικα Παραγγελιων";
    }
})();
