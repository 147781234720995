(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadHospitalizationDetailController', LeadHospitalizationDetailController);

    LeadHospitalizationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'LeadHospitalization', 'Sector', 'Competitor', 'HospitalizationType', 'ClinicName', 'AddDisease', 'AddCondition', 'CommunicationStatus'];

    function LeadHospitalizationDetailController($scope, $rootScope, $stateParams, entity, LeadHospitalization, Sector, Competitor, HospitalizationType, ClinicName, AddDisease, AddCondition, CommunicationStatus) {
        var vm = this;
        vm.leadHospitalization = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:leadHospitalizationUpdate', function(event, result) {
            vm.leadHospitalization = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
