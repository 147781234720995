(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('working-day', {
            parent: 'entity',
            url: '/working-day?page&sort&search',
            data: {
                authorities: ['ROLE_SYSTEM'],
                pageTitle: 'sigmasfApp.workingDay.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/working-day/working-days.html',
                    controller: 'WorkingDayController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('workingDay');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('working-day-detail', {
            parent: 'entity',
            url: '/working-day/{id}',
            data: {
                authorities: ['ROLE_SYSTEM'],
                pageTitle: 'sigmasfApp.workingDay.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/working-day/working-day-detail.html',
                    controller: 'WorkingDayDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('workingDay');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'WorkingDay', function($stateParams, WorkingDay) {
                    return WorkingDay.get({id : $stateParams.id});
                }]
            }
        })
        .state('working-day.new', {
            parent: 'working-day',
            url: '/new',
            data: {
                authorities: ['ROLE_SYSTEM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/working-day/working-day-dialog.html',
                    controller: 'WorkingDayDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                weekDayNo: null,
                                firstShiftFrom: null,
                                firstShiftTo: null,
                                secondShiftFrom: null,
                                secondShiftTo: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('working-day', null, { reload: true });
                }, function() {
                    $state.go('working-day');
                });
            }]
        })
        .state('working-day.edit', {
            parent: 'working-day',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_SYSTEM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/working-day/working-day-dialog.html',
                    controller: 'WorkingDayDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['WorkingDay', function(WorkingDay) {
                            return WorkingDay.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('working-day', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('working-day.delete', {
            parent: 'working-day',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_SYSTEM']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/working-day/working-day-delete-dialog.html',
                    controller: 'WorkingDayDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['WorkingDay', function(WorkingDay) {
                            return WorkingDay.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('working-day', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
