(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchasePresentItemDetailController', PurchasePresentItemDetailController);

    PurchasePresentItemDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PurchasePresentItem', 'Purchase', 'Product', 'PresentListProduct'];

    function PurchasePresentItemDetailController($scope, $rootScope, $stateParams, entity, PurchasePresentItem, Purchase, Product, PresentListProduct) {
        var vm = this;
        vm.purchasePresentItem = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:purchasePresentItemUpdate', function(event, result) {
            vm.purchasePresentItem = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
