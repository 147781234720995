/**
 * Created by Kristy on 22/2/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MDPurchaseNumberingRowsController', MDPurchaseNumberingRowsController);

    MDPurchaseNumberingRowsController.$inject = ['$scope', 'KendoGrid', '$http', '$filter', 'KendoFilter', 'ApplicationParameters'];

    function MDPurchaseNumberingRowsController ($scope, KendoGrid, $http, $filter, KendoFilter, ApplicationParameters) {
        var vm = this;

        vm.pageHeading = $filter('translate')('masterData.purchaseNumberingRows.title');

        ApplicationParameters.hasModule('Purchase_Pharmacies', true);

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/purchase-numbering-rows/search',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    create: function(o) {
                        var data = o.data.models[0];
                        $http.post('/api/purchase-numbering-rows', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    update: function(o) {
                        var data = o.data.models[0];
                        $http.put('/api/purchase-numbering-rows', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true }
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            toolbar: [{name :"create", text: "Προσθήκη Νέας εγγραφής"},
                { template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }],
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {field: "name", title: "Κωδικός Σειράς",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "lastUsedNumber", title: "Τελευταίος Αριθμός Σειράς",
                    filterable: {
                        cell: {
                            operator: "eq",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {
                    command:[
                        {name:"edit", text: "Επεξεργασία"}
                    ],
                    text: "Επεξεργασία",
                    title: "&nbsp;"
                }
            ],
            edit: function() { KendoGrid.defaultEditPopup(this._editContainer); },
            editable: "popup"
        };

    }
})();
