/**
 * Created by Kristy on 29/8/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ToastCtrl', ToastCtrl);

    ToastCtrl.$inject = ['$mdToast'];

    function ToastCtrl ($mdToast) {
        var vm = this;

        //vm.closeToast = function () {
        //    $mdToast
        //        .hide()
        //        .then(function () {
        //        });
        //};
    }
})();
