(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LastNameDetailController', LastNameDetailController);

    LastNameDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'LastName'];

    function LastNameDetailController($scope, $rootScope, $stateParams, entity, LastName) {
        var vm = this;
        vm.lastName = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:lastNameUpdate', function(event, result) {
            vm.lastName = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
