(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadOfferDialogController', LeadOfferDialogController);

    LeadOfferDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'LeadOffer', 'CostType', 'RoomType', 'Transportation', 'Competitor', 'LeadDiscount', 'LeadCall', 'OfferStatus'];

    function LeadOfferDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, LeadOffer, CostType, RoomType, Transportation, Competitor, LeadDiscount, LeadCall, OfferStatus) {
        var vm = this;
        vm.leadOffer = entity;
        vm.costtypes = CostType.query();
        vm.roomtypes = RoomType.query();
        vm.transportations = Transportation.query();
        vm.competitors = Competitor.query();
        vm.leaddiscounts = LeadDiscount.query();
        vm.leadcalls = LeadCall.query();
        vm.offerstatuses = OfferStatus.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:leadOfferUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.leadOffer.id !== null) {
                LeadOffer.update(vm.leadOffer, onSaveSuccess, onSaveError);
            } else {
                LeadOffer.save(vm.leadOffer, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.firstEstimationDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
