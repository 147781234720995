/**
 * Created by Kristy on 11/9/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('MasterToKendoFiltersService', MasterToKendoFiltersService);

    MasterToKendoFiltersService.$inject = ['SupervisorsService', '$filter', 'MasterFilters'];

    function MasterToKendoFiltersService(SupervisorsService, $filter, MasterFilters) {

        return {
            getMasterFilters: getMasterFilters
        };

        function getMasterFilters(data, masterFilters, view) {
            switch(view) {
                case 'customers':
                    if (masterFilters.from && masterFilters.to) {
                        data = data.concat( MasterFilters.addDateFilter("createdDate", masterFilters.from, masterFilters.to) );
                    }
                    if (masterFilters.selectedCustomers.length && !(SupervisorsService.getSelectedSupervisors().length && masterFilters.selectedCustomers[0] == 'all')) {
                        var selectedCustomers = masterFilters.selectedCustomers[0] === "all" ? "" :  masterFilters.selectedCustomers[0] === "none" ? masterFilters.selectedCustomers[0] : masterFilters.selectedCustomers.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "customerSectors.employeeCustomerSectors.employee.id", "in", selectedCustomers ) );
                    }
                    if ((SupervisorsService.getSelectedSupervisors().length && !masterFilters.selectedCustomers.length) || (SupervisorsService.getSelectedSupervisors().length && masterFilters.selectedCustomers[0] == 'all')) {
                        data = data.concat( MasterFilters.addMasterFilter( "customerSectors.employeeCustomerSectors.employee.id", "in_with_hierarchy", SupervisorsService.getSelectedSupervisors().toString() ) );
                    }
                    if (masterFilters.selectedSpecialties) {
                        var selectedSpecialties = masterFilters.selectedSpecialties[0] === "all" ? "" :  masterFilters.selectedSpecialties[0] === "none" ? masterFilters.selectedSpecialties[0] : masterFilters.selectedSpecialties.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "doctorSpecialty.id", "in", selectedSpecialties ) );
                    }
                    if(masterFilters.selectedSoftwares) {
                        if (masterFilters.selectedSoftwares.length) {
                            data = data.concat(
                                [
                                    {
                                        "field": "software",
                                        "operator": masterFilters.selectedSoftwares.indexOf('none') > -1 ? "isnull" : "in",
                                        "value": masterFilters.selectedSoftwares.indexOf('all') > -1 ? "medexpress,medmobile" :
                                            masterFilters.selectedSoftwares.indexOf('none') > -1 ? null :
                                                masterFilters.selectedSoftwares.toString()
                                    }
                                ]
                            );
                        }
                    }
                    if(masterFilters.lastVisitDate) {
                        if (masterFilters.lastVisitDate.value) {
                            data = data.concat(
                                [
                                    {
                                        "field": "lastVisitDate",
                                        "operator": masterFilters.lastVisitDate.operator ? masterFilters.lastVisitDate.operator : 'eqdate',
                                        "value": masterFilters.lastVisitDate.value
                                    }
                                ]
                            );
                        }
                    }
                    data = data.concat(
                        [
                            {
                                "field": "customerSectors.employeeCustomerSectors.myTarget", "operator": "eqbool",
                                "value": masterFilters.saw
                            }
                        ]
                    );
                    data = data.concat(
                        [
                            {
                                "field": "isActive", "operator": "eqbool",
                                "value": masterFilters.isActive
                            }
                        ]
                    );
                    break;
                case 'customerSectors':
                    if (masterFilters.from && masterFilters.to) {
                        data = data.concat( MasterFilters.addDateFilter("lastModifiedDate", masterFilters.from, masterFilters.to) );
                    }
                    if (masterFilters.selectedCustomerSectors.length && !(SupervisorsService.getSelectedSupervisors().length && masterFilters.selectedCustomerSectors[0] == 'all')) {
                        var selectedCustomerSectors = masterFilters.selectedCustomerSectors[0] === "all" ? "" :  masterFilters.selectedCustomerSectors[0] === "none" ? masterFilters.selectedCustomerSectors[0] : masterFilters.selectedCustomerSectors.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "employeeCustomerSectors.employee.id", "in", selectedCustomerSectors ) );
                        data = data.concat( MasterFilters.addMasterFilter( "employeeCustomerSectors.customerSectors.myTarget", "eqbool", masterFilters.myTarget ) );
                    }
                    if ((SupervisorsService.getSelectedSupervisors().length && !masterFilters.selectedCustomerSectors.length) || (SupervisorsService.getSelectedSupervisors().length && masterFilters.selectedCustomerSectors[0] == 'all')) {
                        data = data.concat( MasterFilters.addMasterFilter( "employeeCustomerSectors.employee.id", "in_with_hierarchy", SupervisorsService.getSelectedSupervisors().toString() ) );
                        data = data.concat( MasterFilters.addMasterFilter( "employeeCustomerSectors.customerSectors.myTarget", "eqbool", masterFilters.myTarget ) );
                    }
                    if (masterFilters.selectedCities.length) {
                        var cityArray = [];
                        angular.forEach(masterFilters.selectedCities, function (city) {
                            cityArray.push(city);
                        });

                        var selectedCities = masterFilters.selectedCities[0].id === "all" ? "" :  masterFilters.selectedCities[0].id === "none" ? masterFilters.selectedCities[0] : JSON.stringify(cityArray);
                        data = data.concat( MasterFilters.addMasterFilter( "sector.cityName", "in", selectedCities ) );
                    }
                    if (masterFilters.selectedPrefectures.length) {
                        var prefectureArray = [];
                        angular.forEach(masterFilters.selectedPrefectures, function (prefecture) {
                            prefectureArray.push(prefecture);
                        });

                        var selectedPrefectures = masterFilters.selectedPrefectures[0] === "all" ? "" :  masterFilters.selectedPrefectures[0] === "none" ? masterFilters.selectedPrefectures[0] : JSON.stringify(prefectureArray);
                        data = data.concat( MasterFilters.addMasterFilter( "sector.prefectureName", "in", selectedPrefectures ) );
                    }
                    if (masterFilters.selectedSectorTypes.length) {
                        console.log(masterFilters.selectedSectorTypes);
                        var selectedSectorTypes = masterFilters.selectedSectorTypes[0] === "all" ? "" :  masterFilters.selectedSectorTypes[0] === "none" ? masterFilters.selectedSectorTypes[0] : masterFilters.selectedSectorTypes.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "sector.sectorType.id", "in", selectedSectorTypes ) );
                    }
                    break;
                case 'events':
                    if(masterFilters.from && masterFilters.to) {
                        data = data.concat( MasterFilters.addDateFilterGMT("startedAt", masterFilters.from, masterFilters.to) );
                    }
                    if(masterFilters.selectedEmployees.length  && !(SupervisorsService.getSelectedSupervisors().length && masterFilters.selectedEmployees[0] == 'all')) {
                        var selectedEmployees = masterFilters.selectedEmployees[0] === "all" ? "" :  masterFilters.selectedEmployees[0] === "none" ? masterFilters.selectedEmployees[0] : masterFilters.selectedEmployees.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "employee.id", "in", selectedEmployees ) );
                    }
                    if((SupervisorsService.getSelectedSupervisors().length && !masterFilters.selectedEmployees.length) || (SupervisorsService.getSelectedSupervisors().length && masterFilters.selectedEmployees[0] == 'all')) {
                        data = data.concat( MasterFilters.addMasterFilter( "employee.id", "in_with_hierarchy", SupervisorsService.getSelectedSupervisors().toString() ) );
                    }
                    if(masterFilters.selectedCustomers) {
                        var selectedCustomers = masterFilters.selectedCustomers[0] === "all" ? "" : masterFilters.selectedCustomers.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "eventCustomerSector.customerSector.customer.id", "in", selectedCustomers ) );
                    }
                    if(masterFilters.eventStatus) {
                        data = data.concat(
                            [
                                { "field": "eventStatus.id", "operator": "eq",
                                    "value": masterFilters.eventStatus }
                            ]
                        );
                    }
                    if(masterFilters.selectedAgreementValuations) {
                        var selectedAgreementValuations = masterFilters.selectedAgreementValuations[0] === "all" ? "" :  masterFilters.selectedAgreementValuations[0] === "none" ? masterFilters.selectedAgreementValuations[0] : masterFilters.selectedAgreementValuations.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "eventCustomerSector.agreementValuation.id", "in", selectedAgreementValuations ) );
                    }
                    break;
                case 'events_filter':
                    if(masterFilters.from && masterFilters.to) {
                        data = data.concat( MasterFilters.addDateFilter("startedAt", masterFilters.from, masterFilters.to) );
                    }
                    if(masterFilters.selectedEmployees.length  && !(SupervisorsService.getSelectedSupervisors().length && masterFilters.selectedEmployees[0] == 'all')) {
                        var selectedEmployees = masterFilters.selectedEmployees[0] === "all" ? "" :  masterFilters.selectedEmployees[0] === "none" ? masterFilters.selectedEmployees[0] : masterFilters.selectedEmployees.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "employee.id", "in", selectedEmployees ) );
                    }
                    if((SupervisorsService.getSelectedSupervisors().length && !masterFilters.selectedEmployees.length) || (SupervisorsService.getSelectedSupervisors().length && masterFilters.selectedEmployees[0] == 'all')) {
                        data = data.concat( MasterFilters.addMasterFilter( "employee.id", "in_with_hierarchy", SupervisorsService.getSelectedSupervisors().toString() ) );
                    }
                    if(masterFilters.selectedCustomers) {
                        var selectedCustomers = masterFilters.selectedCustomers[0] === "all" ? "" : masterFilters.selectedCustomers.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "eventCustomerSector.customerSector.customer.id", "in", selectedCustomers ) );
                    }
                    if(masterFilters.eventStatus) {
                        data = data.concat(
                            [
                                { "field": "eventStatus.id", "operator": "eq",
                                    "value": masterFilters.eventStatus }
                            ]
                        );
                    }
                    if(masterFilters.selectedAgreementValuations) {
                        var selectedAgreementValuations = masterFilters.selectedAgreementValuations[0] === "all" ? "" :  masterFilters.selectedAgreementValuations[0] === "none" ? masterFilters.selectedAgreementValuations[0] : masterFilters.selectedAgreementValuations.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "eventCustomerSector.agreementValuation.id", "in", selectedAgreementValuations ) );
                    }
                    break;
                case 'otherEvents':
                    if(masterFilters.from && masterFilters.to) {
                        data = data.concat( MasterFilters.addDateFilter("dateFrom", masterFilters.from, masterFilters.to) );
                    }
                    if(masterFilters.selectedEmployees.length  && !(SupervisorsService.getSelectedSupervisors().length && masterFilters.selectedEmployees[0] == 'all')) {
                        var selectedEmployees = masterFilters.selectedEmployees[0] === "all" ? "" :  masterFilters.selectedEmployees[0] === "none" ? masterFilters.selectedEmployees[0] : masterFilters.selectedEmployees.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "employee.id", "in", selectedEmployees ) );
                    }
                    if((SupervisorsService.getSelectedSupervisors().length && !masterFilters.selectedEmployees.length) || (SupervisorsService.getSelectedSupervisors().length && masterFilters.selectedEmployees[0] == 'all')) {
                        data = data.concat( MasterFilters.addMasterFilter( "employee.id", "in_with_hierarchy", SupervisorsService.getSelectedSupervisors().toString() ) );
                    }
                    break;
                case 'agreements':
                    if(masterFilters.from && masterFilters.to) {
                        data = data.concat( MasterFilters.addDateFilter("event.startedAt", masterFilters.from, masterFilters.to) );
                    }
                    if(masterFilters.selectedEmployees.length  && !(SupervisorsService.getSelectedSupervisors().length && masterFilters.selectedEmployees[0] == 'all')) {
                        var selectedEmployees = masterFilters.selectedEmployees[0] === "all" ? "" : masterFilters.selectedEmployees.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "event.employee.id", "in", selectedEmployees ) );
                    }
                    if((SupervisorsService.getSelectedSupervisors().length && !masterFilters.selectedEmployees.length) || (SupervisorsService.getSelectedSupervisors().length && masterFilters.selectedEmployees[0] == 'all')) {
                        data = data.concat( MasterFilters.addMasterFilter( "event.employee.id", "in_with_hierarchy", SupervisorsService.getSelectedSupervisors().toString() ) );
                    }
                    if(masterFilters.selectedCustomers.length) {
                        var selectedCustomers = masterFilters.selectedCustomers[0] === "all" ? "" : masterFilters.selectedCustomers.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "event.eventCustomerSector.customerSector.customer.id", "in", selectedCustomers ) );
                    }
                    if(masterFilters.selectedProducts) {
                        var selectedProducts = masterFilters.selectedProducts[0] === "all" ? "" : masterFilters.selectedProducts.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "product.id", "in", selectedProducts ) );
                    }
                    if(masterFilters.selectedAgreementValuations) {
                        var selectedAgreementValuations = masterFilters.selectedAgreementValuations[0] === "all" ? "" : masterFilters.selectedAgreementValuations.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "agreementValuation.id", "in", selectedAgreementValuations ) );
                    }
                    break;
                case 'employeeVisits':
                    if(masterFilters.from && masterFilters.to) {
                        data = data.concat( MasterFilters.addDateFilter("startedAt", masterFilters.from, masterFilters.to) );
                    }
                    if(masterFilters.selectedEmployees.length) {
                        var selectedEmployees = masterFilters.selectedEmployees[0] === "all" ? "" : masterFilters.selectedEmployees.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "employee.id", "in", selectedEmployees ) );
                    }
                    break;
                case 'customerProducts':
                    if(masterFilters.from && masterFilters.to) {
                        data = data.concat( MasterFilters.addDateFilter("event.startedAt", masterFilters.from, masterFilters.to) );
                    }
                    if(masterFilters.selectedEmployees.length) {
                        var selectedEmployees = masterFilters.selectedEmployees[0] === "all" ? "" : masterFilters.selectedEmployees.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "event.employee.id", "in", selectedEmployees ) );
                    }
                    if(masterFilters.selectedProducts) {
                        var selectedProducts = masterFilters.selectedProducts[0] === "all" ? "" : masterFilters.selectedProducts.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "product.id", "in", selectedProducts ) );
                    }
                    if(masterFilters.selectedCustomers.length) {
                        var selectedCustomers = masterFilters.selectedCustomers[0] === "all" ? "" : masterFilters.selectedCustomers.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "event.eventCustomerSector.customerSector.customer.id", "in", selectedCustomers ) );
                    }
                    break;
                case 'purchasesReport':
                    if(masterFilters.from && masterFilters.to) {
                        data = data.concat( MasterFilters.addDateOnlyFilter("issueDate", masterFilters.from, masterFilters.to) );
                    }
                    if(masterFilters.selectedEmployees.length) {
                        var selectedEmployees = masterFilters.selectedEmployees[0] === "all" ? "" : masterFilters.selectedEmployees.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "employee.id", "in", selectedEmployees ) );
                    }
                    if(masterFilters.selectedPharmacies.length) {
                        var selectedPharmacies = masterFilters.selectedPharmacies[0] === "all" ? "" : masterFilters.selectedPharmacies.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "sector.id", "in", selectedPharmacies ) );
                    }
                    break;
                case 'hospitalizations':
                    if(masterFilters.from && masterFilters.to) {
                        data = data.concat( MasterFilters.addDateOnlyFilter("dateIssue", masterFilters.from, masterFilters.to) );
                    }
                    break;
                case 'leadManagement':
                    if(masterFilters.from && masterFilters.to) {
                        data = data.concat( MasterFilters.addDateFilter("dateTime", masterFilters.from, masterFilters.to) );
                    }
                    // Remove bookedAppointment filter ECRM-337
                    /*if(masterFilters.from1) {
                        data = data.concat( MasterFilters.addMasterFilter( "appointmentDate", "from", masterFilters.from1 ) );
                    }
                    if(masterFilters.to1) {
                        data = data.concat( MasterFilters.addMasterFilter( "appointmentDate", "to", masterFilters.to1 ) );
                    }*/
                    if (masterFilters.selectedCallTypes && masterFilters.selectedCallTypes.length > 0) {
                        var selectedCallTypes = masterFilters.selectedCallTypes[0] === "all" ? "" :  masterFilters.selectedCallTypes[0] === "none" ? masterFilters.selectedCallTypes[0] : masterFilters.selectedCallTypes.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "callType.id", "in", selectedCallTypes ) );
                    }

                    data = data.concat( MasterFilters.addMasterFilter( "hospitalizations", "isnull", masterFilters.hospitalizations ) );
                    //data = data.concat( MasterFilters.addMasterFilter( "bookedAppointment", "eqbool", masterFilters.bookedAppointment ) );
                    break;
                case "followUp":
                    if(masterFilters.from && masterFilters.to) {
                        data = data.concat( MasterFilters.addDateFilter("leadCall.dateTime", masterFilters.from, masterFilters.to) );
                    }
                    // Remove bookedAppointment filter ECRM-337
                    /*if(masterFilters.from1) {
                        data = data.concat( MasterFilters.addMasterFilter( "leadCall.appointmentDate", "from", masterFilters.from1 ) );
                    }
                    if(masterFilters.to1) {
                        data = data.concat( MasterFilters.addMasterFilter( "leadCall.appointmentDate", "to", masterFilters.to1 ) );
                    }*/

                    data = data.concat( MasterFilters.addMasterFilter( "leadCall.hospitalizations", "isnull", masterFilters.hospitalizations ) );
                    //data = data.concat( MasterFilters.addMasterFilter( "leadCall.bookedAppointment", "eqbool", masterFilters.bookedAppointment ) );
                    break;
                case "leadHospitalizations":
                    if(masterFilters.from && masterFilters.to) {
                        data = data.concat( MasterFilters.addDateFilter("leadHospitalization.leadCalls.dateTime", masterFilters.from, masterFilters.to) );
                    }
                    // Remove bookedAppointment filter ECRM-337
                    /*if(masterFilters.from1) {
                        data = data.concat( MasterFilters.addMasterFilter( "leadHospitalization.leadCalls.appointmentDate", "from", masterFilters.from1 ) );
                    }
                    if(masterFilters.to1) {
                        data = data.concat( MasterFilters.addMasterFilter( "leadHospitalization.leadCalls.appointmentDate", "to", masterFilters.to1 ) );
                    }*/

                    data = data.concat( MasterFilters.addMasterFilter( "leadHospitalization.leadCalls.hospitalizations", "isnull", masterFilters.hospitalizations ) );
                    //data = data.concat( MasterFilters.addMasterFilter( "leadHospitalization.leadCalls.bookedAppointment", "eqbool", masterFilters.bookedAppointment ) );
                    break;
                case "patchReports":
                    if(masterFilters.from && masterFilters.to) {
                        data = data.concat( MasterFilters.addDateOnlyFilter("patchReport.issueDate", masterFilters.from, masterFilters.to) );
                    }
                    break;
                case 'schedules':
                    if(masterFilters.from && masterFilters.to) {
                        data = data.concat( MasterFilters.addDateFilter("eventSchedule.startedAt", masterFilters.from, masterFilters.to) );
                    }
                    break;
                case 'medReport':
                    data = data.concat( MasterFilters.addMasterFilter( "isActive", "eqbool", true ) );

                    data = data.concat(
                        [
                            {
                                "field": "isActive", "operator": "eqbool",
                                "value": masterFilters.isActive
                            }
                        ]
                    );

                    if(masterFilters.selectedMonths) {
                        data = data.concat(
                            [
                                {
                                    "field": "fromDate", "operator": "inMonths",
                                    "value": masterFilters.selectedMonths.toString()
                                },
                                {
                                    "field": "toDate", "operator": "inMonths",
                                    "value": masterFilters.selectedMonths.toString()
                                }
                            ]
                        );
                    }

                    if(masterFilters.selectedYears) {
                        data = data.concat(
                            [
                                {
                                    "field": "fromDate", "operator": "inYears",
                                    "value": masterFilters.selectedYears.toString()
                                },
                                {
                                    "field": "toDate", "operator": "inYears",
                                    "value": masterFilters.selectedYears.toString()
                                }
                            ]
                        );
                    }

                    if (masterFilters.selectedEmployees) {
                        if(masterFilters.selectedEmployees.length) {
                            var selectedEmployees = masterFilters.selectedEmployees[0] === "all" ? "" : masterFilters.selectedEmployees.toString();
                            data = data.concat( MasterFilters.addMasterFilter( "employee.id", "in", selectedEmployees ) );
                        }
                    }
                    if (masterFilters.selectedCustomers) {
                        if (masterFilters.selectedCustomers.length) {
                            var selectedCustomers = masterFilters.selectedCustomers[0] === "all" ? "" : masterFilters.selectedCustomers.toString();
                            data = data.concat(MasterFilters.addMasterFilter("sector.id", "in", selectedCustomers));
                        }
                    }
                    if (masterFilters.selectedMedicines) {
                        if (masterFilters.selectedMedicines.length) {
                            data = data.concat(MasterFilters.addMasterFilter("medReportLines.medicine.id", "in", masterFilters.selectedMedicines.toString()));
                        }
                    }
                    break;
                case 'medReportSumsPerCustomerAndMonth':
                    data = data.concat( MasterFilters.addMasterFilter( "isActive", "eqbool", true ) );

                    data = data.concat(
                        [
                            {
                                "field": "isActive", "operator": "eqbool",
                                "value": masterFilters.isActive
                            }
                        ]
                    );

                    if(masterFilters.selectedMonths) {
                        data = data.concat(
                            [
                                {
                                    "field": "fromDate", "operator": "inMonths",
                                    "value": masterFilters.selectedMonths.toString()
                                },
                                {
                                    "field": "toDate", "operator": "inMonths",
                                    "value": masterFilters.selectedMonths.toString()
                                }
                            ]
                        );
                    }

                    if(masterFilters.selectedYear) {
                        data = data.concat(
                            [
                                {
                                    "field": "fromDate", "operator": "inYears",
                                    "value": masterFilters.selectedYear.toString()
                                },
                                {
                                    "field": "toDate", "operator": "inYears",
                                    "value": masterFilters.selectedYear.toString()
                                }
                            ]
                        );
                    }

                    if(masterFilters.selectedEmployees.length) {
                        var selectedEmployees = masterFilters.selectedEmployees[0] === "all" ? "" : masterFilters.selectedEmployees.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "employee.id", "in", selectedEmployees ) );
                    }

                    if (masterFilters.selectedMedicines.length) {
                        data = data.concat(MasterFilters.addMasterFilter("medReportLines.medicine.id", "in", masterFilters.selectedMedicines.toString()));
                    }
                    break;
                case 'medReportSumsPerMonth':
                    data = data.concat( MasterFilters.addMasterFilter( "isActive", "eqbool", true ) );

                    data = data.concat(
                        [
                            {
                                "field": "isActive", "operator": "eqbool",
                                "value": masterFilters.isActive
                            }
                        ]
                    );

                    if(masterFilters.selectedMonths) {
                        data = data.concat(
                            [
                                {
                                    "field": "fromDate", "operator": "inMonths",
                                    "value": masterFilters.selectedMonths.toString()
                                },
                                {
                                    "field": "toDate", "operator": "inMonths",
                                    "value": masterFilters.selectedMonths.toString()
                                }
                            ]
                        );
                    }

                    if(masterFilters.selectedYear) {
                        data = data.concat(
                            [
                                {
                                    "field": "fromDate", "operator": "inYears",
                                    "value": masterFilters.selectedYear.toString()
                                },
                                {
                                    "field": "toDate", "operator": "inYears",
                                    "value": masterFilters.selectedYear.toString()
                                }
                            ]
                        );
                    }

                    if(masterFilters.selectedEmployees.length) {
                        var selectedEmployees = masterFilters.selectedEmployees[0] === "all" ? "" : masterFilters.selectedEmployees.toString();
                        data = data.concat( MasterFilters.addMasterFilter( "employee.id", "in", selectedEmployees ) );
                    }

                    if (masterFilters.selectedMedicines.length) {
                        data = data.concat(MasterFilters.addMasterFilter("medReportLines.medicine.id", "in", masterFilters.selectedMedicines.toString()));
                    }
                    break;
            }
            return data
        }
    }
})();
