(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PurchaseDetailController', PurchaseDetailController);

    PurchaseDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Purchase', 'Employee', 'PurchaseStatus', 'Sector', 'Customer', 'PaymentMethod', 'TaxStatus', 'PurchaseNumberingRow', 'TradePolicy'];

    function PurchaseDetailController($scope, $rootScope, $stateParams, entity, Purchase, Employee, PurchaseStatus, Sector, Customer, PaymentMethod, TaxStatus, PurchaseNumberingRow, TradePolicy) {
        var vm = this;
        vm.purchase = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:purchaseUpdate', function(event, result) {
            vm.purchase = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
