(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProductPriceDetailController', ProductPriceDetailController);

    ProductPriceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ProductPrice', 'Product'];

    function ProductPriceDetailController($scope, $rootScope, $stateParams, entity, ProductPrice, Product) {
        var vm = this;
        vm.productPrice = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:productPriceUpdate', function(event, result) {
            vm.productPrice = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
