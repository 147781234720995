/**
 * Created by Maria on 11/5/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminAgreementsController', AdminAgreementsController);

    AdminAgreementsController.$inject = ['$scope', '$state', '$filter', 'ParseLinks', 'AlertService', 'pagingParams', 'KendoFilter','$http', '$rootScope', '$location', 'CustomUtils'];

    function AdminAgreementsController ($scope, $state, $filter, ParseLinks, AlertService, pagingParams, KendoFilter, $http, $rootScope, $location, CustomUtils) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(67);
        vm.pageHeading = 'Αξιολογηση';

        function onChange(arg) {
            // Get selected cell
            var selected = this.select();
            // Get the row that the cell belongs to.
            var row = this.select().closest("tr");
            // Get cell index (column number)
            var idx = selected.index();
            // Get column name from Grid column definition
            var col = this.options.columns[idx-1].field;

            if (col === 'event.eventCustomerSector.customerSector.customer.firstName' || col === 'event.eventCustomerSector.customerSector.customer.lastName') {
                var customerId = this.dataItem(row).event.eventCustomerSector.customerSector.customer.id;
                $rootScope.$apply(function () {
                    $rootScope.selectedUser = {};
                    $rootScope.selectedUser.id = customerId;
                    $state.go('customerInfo', {id: customerId, stateFrom: 'adminAgreements'});
                });
            } else if (col === 'event.employee.account.lastName') {
                var userId = this.dataItem(row).event.employee.id;
                $rootScope.$apply(function () {
                    $rootScope.selectedUser = {};
                    $rootScope.selectedUser.id = userId;
                    // $location.path('/employees/search').search({id: userId});
                    $state.go('userInfo', {id: userId, stateFrom: 'adminAgreements'});
                });
            } else {
                //this.clearSelection(); //his throws error
                this.saveChanges();
            }
        }

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        $http.get('/api/me/agreements',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (data, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        } else {
                            if (data.filter != undefined) {
                                angular.forEach(data.filter.filters, function (item) {
                                    if (item.field === "event.startedAt") {
                                        item.value = kendo.toString(kendo.parseDate(item.value), 'yyyy-MM-dd');
                                    }
                                });
                            }
                            return data;
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        if(data.content != undefined) {
                            data.content.forEach(function(item){

                                if (item.event === null) {
                                    item.event = {
                                        startedAt: '',
                                        employee: {
                                            account: {
                                                lastName: ""
                                            }
                                        },
                                        eventCustomerSector: {
                                            customerSector: {
                                                customer: {
                                                    last_name: "",
                                                    first_name: ""
                                                }
                                            }
                                        }
                                    };
                                } else {
                                    if (item.event.eventCustomerSector === null) {
                                        item.event.eventCustomerSector = {
                                            customerSector: {
                                                customer: {
                                                    last_name: "",
                                                    first_name: ""
                                                }
                                            }
                                        };
                                    } else {
                                        if (item.event.eventCustomerSector.customerSector === null) {
                                            item.event.eventCustomerSector.customerSector = {
                                                customer: {
                                                    last_name: "",
                                                    first_name: ""
                                                }
                                            };
                                        } else {
                                            if (item.event.eventCustomerSector.customerSector.customer === null) {
                                                item.event.eventCustomerSector.customerSector.customer = {
                                                    last_name: "",
                                                    first_name: ""
                                                };
                                            }
                                        }
                                    }
                                }
                            });
                        }

                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: {editable: false, nullable: false},
                            'event.startedAt': {type: 'date'}
                        }
                    }
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                pageSize: 100,
                group: [{field: "event.startedAt", dir: "desc"}]
            }),
            groupable: false,
            pageable: {
                messages: {
                    display: "{0} - {1} από {2} Αξιολόγηση", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                    empty: "Δεν βρέθηκε Αξιολόγηση",
                    page: "Σελίδα",
                    allPages: "Όλα",
                    of: "από {0}", // {0} is total amount of pages.
                    itemsPerPage: "εγγραφές ανά σελίδα",
                    first: "Πήγαινε στη πρώτη σελίδα",
                    previous: "Πήγαινε στη τελευταία σελίδα",
                    next: "Πήγαινε στην επόμενη σελίδα",
                    last: "Πήγαινε στη τελευταία σελίδα",
                    refresh: "Ανανέωση"
                }
            },
            noRecords: {
                template: "Δεν βρέθηκε Αξιολόγηση"
            },
            resizable: true,
            filterable: {
                mode: "row"
            },
            sortable: true,
            columns: [
                {
                    field: "event.startedAt", hidden: true,
                    groupHeaderTemplate: "Ημερομηνία Επίσκεψης: #= value ? kendo.toString(kendo.parseDate(value), 'dddd, dd/MM/yyyy') : ''#"
                },
                {
                    field: "event.startedAt",
                    title: "Ημερομηνία Επίσκεψης",
                    template: "#= event.startedAt ? kendo.toString(kendo.parseDate(event.startedAt), 'dddd, dd/MM/yyyy HH:mm') : ''#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "product.name", title: "Προϊόν",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "description",
                    title: "Περιγραφή Αξιολόγησης",
                    template: "#= description == null ? ' ' : description #",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "promisedQuantity", title: "Προβλεπόμενη Συχνότητα",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 160
                },
                {
                    field: "priority",
                    title: "Σειρά προτεραιότητας",
                    template: "#=priority != null ? priority + 1 : ''#",
                    filterable: {
                        cell: {
                            operator: "eq",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 140
                },
                //{field: "agreedPrice", title: "Αξία που Συμφωνήθηκε",
                //    filterable: {
                //        cell: {
                //            operator: "startswith",
                //            showOperators: false
                //        }
                //    },
                //    format: "{0:c}"
                //},
                {
                    field: "agreementValuation.name", title: "Αξιολόγηση", attributes: {
                        class: "#=agreementValuation.id == '1' ? 'red' : agreementValuation.id == '2' ? 'orange' : agreementValuation.id == '3' ? 'purple' : agreementValuation.id == '4' ? 'green' : 'black' #"
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "event.eventCustomerSector.customerSector.customer.firstName", title: "Όνομα Επαγγελματία Υγείας", attributes: {
                        class: "#= 'link-cell link-cell-color' #"
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "event.eventCustomerSector.customerSector.customer.lastName", title: "Επώνυμο Επαγγελματία Υγείας", attributes: {
                        class: "#= 'link-cell link-cell-color' #"
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "event.employee.account.lastName", title: "Όνομα Ιατρικού Επισκέπτη", attributes: {
                        class: "#= 'link-cell link-cell-color' #"
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                }
            ],
            editable: "inline",
            selectable: "cell",
            height: vm.contentHeight,
            change: onChange
        };
    }
})();
