/**
 * Created by Kristy on 7/5/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProspectivePatientsController', ProspectivePatientsController);

    ProspectivePatientsController.$inject = ['$http', '$state', '$filter','KendoFilter', 'KendoGrid', 'ProspectivePatient', 'CalculateAge', 'KendoService', '$timeout', 'CustomUtils'];

    function ProspectivePatientsController ($http, $state, $filter, KendoFilter, KendoGrid, ProspectivePatient, CalculateAge, KendoService, $timeout, CustomUtils) {
        var vm = this;
        vm.editMode = false;
        vm.pageHeading = "Πιθανοι Ασθενεις";
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(66);
        var comboBoxes = ["#masterFilter", "#prefectures", "#cities"];
        var grid = {};
        var dataSource = {};

        vm.birthYearOptions = {
            start: "decade",
            depth: "decade",
            format: "yyyy"
        };

        var promise = $http.get('/api/employees/me');
        promise.then(function(response) {
            vm.geodata = response.data.geodata;
            if(!vm.geodata) vm.initializeMasterData();
            else vm.initializeGeodata();
        });

        vm.refreshCombobox = function(filter, combobox) {
            combobox.filter(filter);
            combobox.read();
        };

        vm.initializeMasterData = function() {
            //Prefectures Combobox
            dataSource.prefecturesDataSource = KendoService.initDefaultDataSource('/api/masterdata/prefectures');
            vm.prefecturesComboBoxOptions = KendoService.initDefaultOptions(dataSource.prefecturesDataSource, "startswith", "name", "id", false);
            vm.prefecturesComboBoxOptions.change = function() {
                var filter = {};
                if(this.value() && !isNaN(this.value())) {
                    filter = {
                        "field": "prefecture.id",
                        "operator": "eq",
                        "value": this.value()
                    };
                }
                vm.refreshCombobox(filter, vm.citiesComboBoxOptions.dataSource);
            };

            //Cities Combobox
            dataSource.citiesDataSource = KendoService.initDataSource('/api/masterdata/cities', 1, 50);
            vm.citiesComboBoxOptions = KendoService.initDefaultOptions(dataSource.citiesDataSource, "startswith", "name", "id", false);
            vm.citiesComboBoxOptions.template = "<table>${name} &nbsp;<span class='hint'>(${prefecture.name})</span></table>";
            vm.citiesComboBoxOptions.change = function() {
                var filter = {};
                if(this.dataItem(this.select())) {
                    filter = {
                        "field"   : "id",
                        "operator": "eq",
                        "value"   : this.dataItem(this.select()).prefecture.id
                    };
                    vm.refreshCombobox(filter, vm.prefecturesComboBoxOptions.dataSource);
                } else {
                    vm.refreshCombobox(filter, vm.prefecturesComboBoxOptions.dataSource);
                }
            };
        };

        vm.initializeGeodata = function() {
            dataSource.geodataDataSource = new kendo.data.DataSource({
                serverFiltering: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) o.data.filter = { filters: [] };

                        o.data.page = 1;
                        o.data.pageSize = 100;
                        $http.get('/api/masterdata/geodata',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response.content);
                            })
                            .error(function (response) {});
                    }
                },
                schema: {
                    parse: function(data) {
                        angular.forEach(data, function(item) {
                            item.masterFilter = "";
                            if(item.street !==null) {
                                item.masterFilter =  item.street + ", ";
                            }
                            item.masterFilter = item.masterFilter + item.city + ", " + item.postalCode + ", " + item.prefecture;
                        });
                        return data;
                    }
                }
            });

            vm.masterFilterComboBoxOptions = KendoService.initDefaultOptions(dataSource.geodataDataSource, "search", "masterFilter", "id");
            vm.masterFilterComboBoxOptions.headerTemplate = "<div class='dropdown-header k-widget k-header'>" +
                "<span>Διεύθυνση</span>"+
                "<span>Πόλη</span>"+
                "<span>Τ.Κ.</span>"+
                "<span>Νομός</span></div>";
            vm.masterFilterComboBoxOptions.template = "#= street != null ? street + ', ' + city + ', ' + postalCode + ', ' + prefecture : city + ', ' + postalCode + ', ' + prefecture #";
            vm.masterFilterComboBoxOptions.placeholder =   "Αναζητήστε Διεύθυνση, Πόλη, Τ.Κ., Νομό";
            vm.masterFilterComboBoxOptions.change = function(){
                //initialize inputs (autocomplete)
                if(this.dataItem(this.select())) {
                    var self = this;
                    //using $timeout to trigger the change
                    $timeout(function(){
                        vm.selectedItem.prefectureName = self.dataItem(self.select()).prefecture;
                        vm.selectedItem.cityName = self.dataItem(self.select()).city;
                        vm.selectedItem.address = self.dataItem(self.select()).street;
                        vm.selectedItem.postalCode = self.dataItem(self.select()).postalCode;
                    },0);
                }
            };
        };

        vm.customEdit = function(e) {
            angular.element("#prospectivePatientsGrid").data("kendoGrid")
                .editRow($(e.currentTarget).closest("tr"));
        };

        vm.customAdd = function() {
            angular.element("#prospectivePatientsGrid").data("kendoGrid")
                .addRow();
        };

        grid.datasource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) { o.data.filter = { filters: [] }; }

                    angular.forEach( o.data.filter.filters, function(item) {
                        if ( item.value instanceof Date) {
                            item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                        }
                    });

                    $http.get('/api/prospective-patients/search',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        });
                },
                create: function (o){
                    var data = o.data.models[0];
                    /*if (data.age) {
                        data.birthYear = CalculateAge.getBirthYearFromAge(data.age).getFullYear();
                    } else {
                        data.birthYear = "";
                    }*/

                    if( !data.city.id ) data.city = null;
                    if( !data.prefecture.id ) data.prefecture = null;
                    if( !data.attendant.id ) data.attendant = null;
                    if( !data.profession.id ) data.profession = null;
                    ProspectivePatient.update({}, data,
                        function(result){
                            $state.go('prospectivePatientInfo', {id: result.id});
                            KendoGrid.refresh(vm.prospectivePatientsGridOptions)
                    });
                },
                update: function (o){
                    var data = o.data.models[0];
                    /*if (data.age) {
                        data.birthYear = CalculateAge.getBirthYearFromAge(data.age).getFullYear();
                    } else {
                        data.birthYear = "";
                    }*/

                    if( !data.city.id ) data.city = null;
                    if( !data.prefecture.id ) data.prefecture = null;
                    if( !data.attendant.id ) data.attendant = null;
                    if( !data.profession.id ) data.profession = null;
                    ProspectivePatient.update({}, data,
                        function(){ KendoGrid.refresh(vm.prospectivePatientsGridOptions) });
                }
            },
            schema : {
                data: function (data) {
                    if(data.content !== undefined) {
                        data.content.forEach(function(item){
                            if (item.attendant) {
                                if (item.attendant.lastName && item.attendant.firstName) {
                                    item.attendant.fullName = item.attendant.lastName + " " + item.attendant.firstName;
                                } else if (!item.attendant.firstName) {
                                    item.attendant.fullName = item.attendant.lastName;
                                } else if (!item.attendant.lastName) {
                                    item.attendant.fullName = item.attendant.firstName;
                                }
                            }
                            if (item.attendant === null) item.attendant = {};
                            if (item.profession === null) item.profession = {};

                            /*if (item.birthYear) {
                                var date = new Date();
                                date.setFullYear(item.birthYear);
                                item.birthYear = date;
                                item.age = CalculateAge.getAgeFromBirthYear(item.birthYear);
                            }*/
                        });
                    }
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        lastName: { validation: { required: { message: "Το επώνυμο του Ασθενή είναι υποχρεωτικό" } } },
                        firstName: { validation: { required: { message: "Το όνομα του Ασθενή είναι υποχρεωτικό" } } },
                        age: { type: "number" },
                        email: { type: "email" },
                        birthYear: { type: "number", defaultValue: "", validation: { max: 4 } },
                        attendant: { defaultValue: {} },
                        profession: { defaultValue: {} },
                        isWrongPhone: { defaultValue: false },
                        isWrongContact: { defaultValue: false },
                        amka: { type: "text" }
                    }
                }
            },
            pageSize: 100,
            serverPaging: true,
            serverSorting: true,
            scrollable:false,
            serverFiltering :true
        });

        grid.columns = vm.gridColumns = [
            {
                template: "<md-button ng-click='vm.customEdit($event)' class='md-raised md-mini' style='min-width: 50px;margin: 0;' aria-label='add'>" +
                    " <i class='dark-grey material-icons fixed-icons-2'>edit</i></md-button>",
                title: "&nbsp;",
                width: 80,
                selectable: false
            },
            {
                field: "lastName",
                title: "Επώνυμο Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "firstName",
                title: "Όνομα Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "phone",
                title: "Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "email",
                title: "Email",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "mobile",
                title: "Κινητό",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "birthYear",
                title: "Έτος Γέννησης",
                filterable: {
                    cell: {
                        operator: "startswithnum",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "amka",
                title: "ΑΜΚΑ",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "prefectureName",
                title: "Νομός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "cityName",
                title: "Πόλη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "postalCode",
                title: "Τ.Κ.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "address",
                title: "Διεύθυνση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "addressNum",
                title: "Αριθμός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                template: '<md-checkbox class="checkbox-toolbar green inline"  aria-label="selectable" ' +
                'ng-model="dataItem.isWrongPhone" ' +
                'ng-disabled="true"></md-checkbox>',
                field: "isWrongPhone", title: "Λάθος Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "eqbool",
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "Ναι", value: "true" },
                                        { text: "Όχι", value: "false" }]
                                }),
                                valuePrimitive: true

                            })
                        },
                        showOperators: false
                    }
                }
            },
            {
                template: '<md-checkbox class="checkbox-toolbar green inline"  aria-label="selectable" ' +
                'ng-model="dataItem.isWrongContact" ' +
                'ng-disabled="true"></md-checkbox>',
                field: "isWrongContact", title: "Λάθος Στοιχεία Επικοινωνίας",
                filterable: {
                    cell: {
                        operator: "eqbool",
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "Ναι", value: "true" },
                                        { text: "Όχι", value: "false" }]
                                }),
                                valuePrimitive: true

                            })
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "attendant.fullName", title: "Συνοδός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                sortable: false
            },
            {
                field: "profession.name", title: "Επάγγελμα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];

        vm.prospectivePatientsGridOptions = {
            dataSource: grid.datasource,
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: true,
            columns:  grid.columns,
            editable: {
                mode: "popup",
                template: kendo.template(angular.element("#popup-prospective-patient").html())
            },
            edit: function(e) {
                vm.editMode = true;
                KendoGrid.defaultEditPopup(this._editContainer);
                e.model.dirty = true;
                vm.selectedItem = e.model;
                KendoGrid.defaultCustomEditPopup(this._editContainer,1000,990);

                //Attendants Combobox
                dataSource.attendant = new kendo.data.DataSource({
                    serverFiltering: true,
                    serverSorting: true,
                    transport: {
                        read: function (o) {
                            var url = '/api/attendants/search';
                            if (o.data.filter === undefined) o.data.filter = { filters: [] };

                            o.data.page = 1;
                            o.data.pageSize = 1;
                            $http.get(url, {params: o.data})
                                .success(function (response) {
                                    o.success(response.content);
                                })
                                .error(function (response) {});
                        }
                    },
                    schema: {
                        parse: function (data) {
                            var attendant = $filter('filter')(data, {id: vm.selectedItem.attendant.id}, true);
                            if (!attendant.length) {
                                data.push(vm.selectedItem.attendant);
                            }
                            data.forEach(function (item) {
                                item.fullName = item.lastName + " " + item.firstName
                            });
                            return data;
                        }
                    }
                });
                vm.attendantOptions = KendoService.initDefaultOptions(dataSource.attendant, "startswith", "fullName", "id", false);
                vm.attendantOptions.placeholder = "Επιλογή συνοδού";

                //Professions Combobox
                dataSource.professions = KendoService.initDefaultDataSource('/api/professions/search');
                vm.professionOptions = KendoService.initDefaultOptions(dataSource.professions, "startswith", "name", "id", false);

                //refresh kendoComboBox
                if(e.model.isNew()) {
                    angular.forEach(comboBoxes, function (item) {
                        var comboBox = angular.element(item).data("kendoComboBox");
                        if (comboBox) {
                            vm.refreshCombobox({}, comboBox.dataSource);
                        }
                    });
                }

                if(!e.model.isNew()) {
                    if(e.model.prefecture && !vm.geodata){
                        var filter = {
                            "field": "prefecture.id",
                            "operator": "eq",
                            "value": e.model.prefecture.id
                        };

                        vm.refreshCombobox(filter, vm.citiesComboBoxOptions.dataSource);
                    }

                    if(e.model.city && !vm.geodata){
                        var filterCity = {
                            "field"   : "id",
                            "operator": "eq",
                            "value"   : e.model.city.prefecture.id
                        };

                        vm.refreshCombobox(filterCity, vm.prefecturesComboBoxOptions.dataSource);
                    }
                }

                vm.initPrefecturesComboBox = true;
                vm.initCitiesComboBox = true;

                //if geodata init city and prefecture
                if(!e.model.city) e.model.city = {};
                if(!e.model.prefecture) e.model.prefecture = {};

            },
            toolbar: [
                /*{ template: "<md-button class='md-raised md-mini md-primary' aria-label='add' ng-click='vm.customAdd()'>" +
                    "Προσθήκη Νέας Εγγραφής</md-button>" },*/
                { template: "<div class='right'><label>Σύνολο: {{vm.prospectivePatientsGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }
            ],
            cancel: function(){
                vm.editMode = false;
                vm.canceled = true;
                KendoGrid.refresh(vm.prospectivePatientsGridOptions)
            },
            save: function(e){
                vm.editMode = false;
            }
        };

        vm.onSelection = function(data) {
            if (!vm.editMode && !vm.canceled) $state.go('prospectivePatientInfo', {id: data.id});
            if ( vm.canceled ) { vm.canceled = !vm.canceled };
        };
    }
})();
