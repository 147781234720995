/**
 * Created by Kristy on 24/5/2017.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminEmployeeViewEmployeeEventsController', AdminEmployeeViewEmployeeEventsController);

    AdminEmployeeViewEmployeeEventsController.$inject = ['$scope', '$http', '$filter', 'EmployeeViewService', 'KendoFilter', 'CustomUtils', 'customersView'];

    function AdminEmployeeViewEmployeeEventsController ($scope, $http, $filter, EmployeeViewService, KendoFilter, CustomUtils, customersView) {
        var vm = this;
        vm.customerView = customersView.data.content[0].value;
        vm.selectedUser = EmployeeViewService.getSelectedUser();
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(55);
        vm.myTarget = true;

        vm.clearDatePickers = function () {
            angular.element('#masterFiltersEvents').find('#from').data('kendoDatePicker').value("");
            angular.element('#masterFiltersEvents').find('#to').data('kendoDatePicker').value("");
        };

        vm.masterFiltersOnClick = function (e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id == "today") {

                vm.clearDatePickers();

                vm.from = $filter('date')(date, 'yyyy-MM-dd');
                vm.to = $filter('date')(date, 'yyyy-MM-dd');

            }
            else if (e.id == "week") {

                vm.clearDatePickers();

                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6

                vm.from = $filter('date')(firstWeekDay, 'yyyy-MM-dd');
                vm.to = $filter('date')(lastWeekDay, 'yyyy-MM-dd');

            }
            else if (e.id == "month") {

                vm.clearDatePickers();

                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                vm.from = $filter('date')(firstMonthDay, 'yyyy-MM-dd');
                vm.to = $filter('date')(lastMonthDay, 'yyyy-MM-dd');
            }

            if ( e.target != undefined) {
                angular.element("#eventsGrid").data("kendoGrid").dataSource.read();
            } else {
                angular.element("#eventsGrid").data("kendoGrid").dataSource.read();
            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ], attributes: { class: "buttonGroup" }
                },
                { type: "separator" },
                { template: "<label>Από: </label>" },
                {
                    template: "<input kendo-date-picker id='from' options='vm.fromOptions' class='from'/>",
                    overflow: "never"
                },
                { template: "<label>Εώς: </label>" },
                {
                    template: "<input kendo-date-picker id='to' options='vm.toOptions' class='to'/>",
                    overflow: "never"
                },
                { type: "separator" },
                {
                    template: "<label class='left-space'>My Target: </label><div class='inline'>" +
                    "<md-radio-group class='left-space' layout='row' ng-change='vm.eventsGridOptions.dataSource.page(1).read()' ng-model='vm.myTarget'>" +
                    "<md-radio-button ng-value=true class='md-primary'> Ναι </md-radio-button>" +
                    "<md-radio-button ng-value=false class='md-primary'> Όχι </md-radio-button>" +
                    "<md-radio-button ng-value=null class='md-primary'> Όλα </md-radio-button></md-radio-group></div>",
                    overflow: "never", attributes: {class: "left-space"}
                },
            ]
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('.buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                vm.from = $filter('date')(value, 'yyyy-MM-dd');
                vm.masterFiltersOnClick(e);
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('.buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                vm.to = $filter('date')(value, 'yyyy-MM-dd');
                vm.masterFiltersOnClick(e);
            }
        };

        $scope.googleMapsRedirect = function(dataItem) {
            if (dataItem.latitude != 0 && dataItem.longitude != 0) {
                window.open(
                    'http://maps.google.com/maps?q=' + dataItem.latitude + ',' + dataItem.longitude,
                    '_blank' //This is what makes it open in a new window.
                );
            }
        };

        $scope.imageChoice = function(dataItem) {
            if(dataItem.latitude == 0 && dataItem.latitude == 0){
                return $scope.image = "content/images/error_icon.png";
            }
            else{
                return $scope.image = "content/images/google_map.png";
            }
        };

        vm.eventsGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "employee.id", "operator": "eq",  "value": vm.selectedUser.id}
                            ]
                        );

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        //master filters functionality
                        if(vm.from && vm.to) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "startedAt", "operator": "from",  "value": $filter('date')(vm.from, 'yyyy-MM-ddT00:00:00.000') + 'Z' },
                                    { "field": "startedAt", "operator": "to",  "value": $filter('date')(vm.to, 'yyyy-MM-ddT23:59:59.000') + 'Z' }
                                ]
                            );
                        }

                        // MyTarget
                        if (vm.myTarget !== null) {
                            o.data.filter.filters.push({
                                field: "eventCustomerSector.customerSector.employeeCustomerSectors.myTarget",
                                operator: "eqbool",
                                "value": vm.myTarget // vm.myTarget === null ? 'all' : vm.myTarget
                            });
                        }

                        $http.get('/api/me/events',
                            {params: o.data})
                            .success(function (response) {
                                console.log(response);
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (data, operation) {
                        return data;
                    }
                },
                schema: {
                    data: function (data) {
                        if(data !== undefined) {
                            data.content.forEach(function(item){

                                if (item.eventStatus === null) {
                                    item.eventStatus = {};
                                    item.eventStatus.name = "";
                                }

                                if (item.eventCustomerSector === null) {
                                    item.eventCustomerSector = {
                                        customerSector: {
                                            customer: {
                                                last_name: "",
                                                first_name: "",
                                                customerCategory: {
                                                    name: ""
                                                },
                                                customerSubCategory: {
                                                    name: ""
                                                }
                                            },
                                            sector: {
                                                address: "",
                                                city: {
                                                    name: ""
                                                }
                                            }
                                        },
                                        agreementValuation: {
                                            name: ""
                                        }
                                    };
                                } else {
                                    if (item.eventCustomerSector.agreementValuation === null) {
                                        item.eventCustomerSector.agreementValuation = {
                                            name: ""
                                        };
                                    }
                                    if (item.eventCustomerSector.customerSector === null) {
                                        item.eventCustomerSector.customerSector = {
                                            customer: {
                                                last_name: "",
                                                first_name: "",
                                                customerCategory: {
                                                    name: ""
                                                },
                                                customerSubCategory: {
                                                    name: ""
                                                }
                                            },
                                            sector: {
                                                address: "",
                                                city: {
                                                    name: ""
                                                }
                                            }
                                        };
                                    }else {
                                        if (item.eventCustomerSector.customerSector.customer === null) {
                                            item.eventCustomerSector.customerSector.customer = {
                                                last_name: "",
                                                first_name: "",
                                                customerCategory: {
                                                    name: ""
                                                },
                                                customerSubCategory: {
                                                    name: ""
                                                }
                                            };
                                        }else {
                                            if (item.eventCustomerSector.customerSector.customer.customerCategory === null) {
                                                item.eventCustomerSector.customerSector.customer.customerCategory = {
                                                    name: ""
                                                }
                                            }

                                            if (item.eventCustomerSector.customerSector.customer.customerSubCategory === null) {
                                                item.eventCustomerSector.customerSector.customer.customerSubCategory = {
                                                    name: ""
                                                }
                                            }
                                        }

                                        if (item.eventCustomerSector.customerSector.sector === null) {
                                            item.eventCustomerSector.customerSector.sector = {
                                                address: "",
                                                    city: {
                                                    name: ""
                                                }
                                            }
                                        }
                                    }
                                }

                            });
                        }
                        return data.content;
                    },
                    total : function(data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            name: { validation: { required: true , validation: { required: true, min: 3}} },
                            startedAt: { type: 'date'}
                        }
                    }
                },
                serverSorting: true,
                serverFiltering: true,
                serverPaging: true,
                pageSize: 50,
            }),
            height: vm.contentHeight,
            pageable: {
                messages: {
                    display: "{0} - {1} από {2} Επισκέψεις", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                    empty: "Δεν βρέθηκαν Επισκέψεις",
                    page: "Σελίδα",
                    allPages: "Όλα",
                    of: "από {0}", // {0} is total amount of pages.
                    itemsPerPage: "εγγραφές ανά σελίδα",
                    first: "Πήγαινε στη πρώτη σελίδα",
                    previous: "Πήγαινε στη τελευταία σελίδα",
                    next: "Πήγαινε στην επόμενη σελίδα",
                    last: "Πήγαινε στη τελευταία σελίδα",
                    refresh: "Ανανέωση"
                }
            },
            sortable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            columns: [
                {
                    title: "Επαγγελματίας Υγείας",
                    columns: [
                        {field: "eventCustomerSector.customerSector.customer.lastName", title: "Επώνυμο Επαγγελματία Υγείας",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.customerSector.customer.firstName", title: "Όνομα Επαγγελματία Υγείας",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.customerSector.customer.customerCategory.name", title: "Κατηγορία",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            },
                            hidden: vm.customerView !== '1'
                        },
                        {field: "eventCustomerSector.customerSector.customer.customerSubCategory.name", title: "Υποκατηγορία",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            },
                            hidden: vm.customerView !== '1'
                        }
                    ]
                },
                {field: "startedAt", title: "Ημερομηνία Επίσκεψης", template: "#= kendo.toString(kendo.parseDate(startedAt), 'dddd, dd/MM/yyyy HH:mm')#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    }},
                {field: "eventStatus.name", title: "Κατάσταση Επίσκεψης",attributes: {
                    class: "#=eventStatus.id == '1' ? 'red' : 'green' #"
                },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "eventCustomerSector.agreementValuation.name", title: "Αξιολόγηση", template: "#= (eventCustomerSector.agreementValuation == null || eventCustomerSector.agreementValuation.name == null ) ? ' ' : eventCustomerSector.agreementValuation.name #",
                    attributes: {   class: "#=eventCustomerSector.agreementValuation.id == '1' ? 'red' : eventCustomerSector.agreementValuation.id == '2' ? 'orange' : eventCustomerSector.agreementValuation.id == '3' ? 'purple' : eventCustomerSector.agreementValuation.id == '4' ? 'green' : 'black' #"
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {
                    command:[
                        {name:"map", template: "<input type='image' ng-src={{imageChoice(dataItem.eventCustomerSector)}} ng-click='googleMapsRedirect(dataItem.eventCustomerSector)' style='width: 30px;height: 30px;'/>"} ],
                    attributes: { class: "#= 'link-cell-color' #" },
                    title: "Τοποθεσία",
                    width:120
                },
                {
                    title: "Μονάδα Υγείας",
                    columns: [
                        {field: "eventCustomerSector.sector.sectorType.name", title: "Τύπος",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            },
                            hidden: vm.customerView === '1' || vm.customerView === '6'
                        },
                        {field: "eventCustomerSector.sector.name", title: "Όνομα",
                            filterable: {
                                cell: {
                                    operator: "like",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.sector.address", title: "Διεύθυνση",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.sector.cityName", title: "Πόλη",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.sector.prefectureName", title: "Νομός",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            },
                            hidden: vm.customerView !== '1'
                        },
                        {field: "eventCustomerSector.sector.osfeBrick", title: "Brick ΟΣΦΕ",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            },
                            hidden: vm.customerView !== '1'
                        }
                    ]
                },
                {
                    field: "eventCustomerSector.agreedPercent",
                    title: "Ποσοστό Συχνότητας",
                    template: "#= (eventCustomerSector.agreedPercent == null) ? ' ' : kendo.format('{0:p0}', eventCustomerSector.agreedPercent / 100)#",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    hidden: vm.customerView === '1' || vm.customerView === '6'
                 },
                {field: "description", title: "Σημειώσεις",
                    filterable: {
                        cell: {
                            operator: "like",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    hidden: vm.customerView === '1' || vm.customerView === '6'}
            ],
            noRecords: {
                template: "Δεν βρέθηκαν Επισκέψεις"
            }
        };
    }
})();
