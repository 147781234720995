/**
 * Created by Teo on 28/08/2018.
 * A custom service using $resource just
 * like jHipster's default entity services,
 * but for '/search' API-endpoint
 * Use it like this:
   HospitalizationService.search({
        sort: {
            "field": "sortOrder",
            "dir": "asc"
        },
        page: 1,
        pageSize: 200,
        filter:{
            filters:[
                {
                    field: "leadCall.id",
                    operator: "eq",
                    value: 45
                }
            ]
        }}).$promise.then(function(result){...});
 */

(function() {
    'use strict';
    angular
        .module('sigmasfApp')
        .factory('HospitalizationService', HospitalizationService);

    HospitalizationService.$inject = ['$resource'];

    function HospitalizationService ($resource) {
        var resourceUrl =  '/api/hospitalizations/search/';

        return $resource(resourceUrl, {}, {
            'search': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data.content;
                }
            }
        });
    }
})();
