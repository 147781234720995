/**
 * Created by gmogas on 18/1/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('purchaseItems', {
                parent: 'purchaseView',
                url: '/purchaseItems',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR', 'ROLE_PHARM_VISITOR', 'ROLE_ACCOUNTING_DEP', 'ROLE_STOREHOUSE', 'ROLE_PHARM_SUPERVISOR']
                },
                templateUrl: 'app/displays/purchases/items/purchaseItems.html',
                controller: 'PurchaseItemsController',
                controllerAs: 'vm',
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    entity: ['$stateParams', 'Purchase', function($stateParams, Purchase) {
                        return Purchase.get({id : $stateParams.id});
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('purchase');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
