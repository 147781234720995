/**
 * Created by Teo on 02/05/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadCallsController', LeadCallsController);

    LeadCallsController.$inject = ['$http', '$state', '$filter','KendoFilter', 'KendoToolbar', 'KendoGrid', 'CustomUtils'];

    function LeadCallsController ($http, $state, $filter, KendoFilter, KendoToolbar, KendoGrid, CustomUtils) {
        var vm = this;
        vm.pageHeading = "Κλησεις";
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(62);
        var grid = {};

        grid.datasource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }
                    if (vm.from && vm.to) {
                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                {
                                    "field": "dateTime", "operator": "from",
                                    "value": $filter('date')(vm.from, 'yyyy-MM-ddT00:00:00.000') + 'Z'
                                },
                                {
                                    "field": "dateTime", "operator": "to",
                                    "value": $filter('date')(vm.to, 'yyyy-MM-ddT23:59:59.000') + 'Z'
                                }
                            ]
                        );
                    }
                    if (o.data.sort === undefined || !o.data.sort.length) {
                        o.data.sort = { "field": "dateTime",  "dir": "desc" };
                    }
                    angular.forEach( o.data.filter.filters, function(item) {
                        switch (item.field){
                            case "dateTime":
                                if (item.value instanceof Date) {
                                    item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                                }
                                break;
                            case "nextFollowUpDate":
                                if (item.value instanceof Date) {
                                    item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                                }
                                break;
                            case "leadHospitalizations.possibleDischargeDate":
                                if (item.value instanceof Date) {
                                    item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                                }
                                break;
                            default:
                                break;
                        }
                    });

                    //console.log(o);
                    $http.get('/api/lead-calls/search',
                        {params: o.data})
                        .success(function (response) {
                            //console.log(response);
                            o.success(response);
                        });
                }
            },
            schema : {
                data: function (data) {
                    if(data.content !== undefined) {
                        data.content.forEach(function(item){
                            if (!item.callType) item.callType = {};
                            if (!item.prospectivePatient) item.prospectivePatient = {};
                            if (item.prospectivePatient && !item.prospectivePatient.attendant) item.prospectivePatient.attendant = {};
                            if (!item.oldPatient) item.oldPatient = {};
                            if (!item.oldPatientDoctor) item.oldPatientDoctor = {};
                            if (!item.leadOffers) item.leadOffers = {};
                            if (!item.leadOffers.offerStatus) item.leadOffers.offerStatus = {};
                            if (!item.leadHospitalizations) item.leadHospitalizations = {};
                            if (!item.leadHospitalizations.hospitalizationType) item.leadHospitalizations.hospitalizationType = {};
                        });
                    }
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        dateTime: { type: "date" },
                        nextFollowUpDate: { type: "date"},
                        'leadHospitalizations.possibleDischargeDate': {type: 'date'}
                    }
                }
            },
            pageSize: 100,
            serverPaging: true,
            serverSorting: true,
            scrollable:false,
            serverFiltering :true
        });
        grid.columns = vm.gridColumns = [
            {
                template: "<md-button class='md-raised md-mini' style='min-width: 50px;margin: 0;' aria-label='add' ui-sref='leadCallView({id:dataItem.id})'>" + // ui-sref='leadCallEdit({id:dataItem.id})'
                " <i class='dark-grey material-icons fixed-icons-2'>edit</i></md-button>",
                title: "&nbsp;",
                width: 80
            },
            {
                field: "dateTime",
                title: "Ημερομηνία",
                template: "#= dateTime ? kendo.toString(kendo.parseDate(dateTime), 'dddd, dd/MM/yyyy, HH:mm') : ''#",
                filterable: {
                    cell: {
                        operator: "eqdate",
                        showOperators: false
                    }
                }
            },
            {
                field: "callType.name",
                title: "Τύπος Κλήσης",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "leadOffers.offerStatus.name",
                title: "Προσφορά",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "prospectivePatient.firstName",
                title: "Όνομα Πιθανού Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "prospectivePatient.lastName",
                title: "Επώνυμο Πιθανού Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "prospectivePatient.phone",
                title: "Τηλέφωνο Πιθανού Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "prospectivePatient.age",
                title: "Ηλικία",
                filterable: {
                    cell: {
                        operator: "startswithnum",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "leadHospitalizations.hospitalizationType.name",
                title: "Ασφάλιση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            /*{
                field: "prospectivePatient.attendant.firstName",
                title: "Όνομα Συνοδού",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },*/
            {
                field: "prospectivePatient.attendant.lastName",
                title: "Επώνυμο Συνοδού",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "prospectivePatient.attendant.phone",
                title: "Τηλέφωνο Συνοδού",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "leadHospitalizations.possibleDischargeDate",
                title: "Πιθανή Ημερ/νια Εξιτηρίου",
                template: "#= leadHospitalizations.possibleDischargeDate ? kendo.toString(kendo.parseDate(leadHospitalizations.possibleDischargeDate), 'dddd, dd/MM/yyyy') : ''#",
                filterable: {
                    cell: {
                        operator: "eqdateonly",
                        showOperators: false
                    }
                }
            },
            {
                field: "nextFollowUpDate",
                title: "FollowUp - Ημερ/νία",
                template: "#= nextFollowUpDate ? kendo.toString(kendo.parseDate(nextFollowUpDate), 'dddd, dd/MM/yyyy') : ''#",
                filterable: {
                    cell: {
                        operator: "eqdateonly",
                        showOperators: false
                    }
                }
            },
            {
                field: "createdBy",
                title: "Δημιουργία Από",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "lastModifiedBy",
                title: "Τελευτ. Επεξεργ. Από",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];

        vm.leadCallsGridOptions = {
            dataSource: grid.datasource,
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: true,
            columns:  grid.columns,
            editable: false,
            toolbar: [
                /*{ template: "<md-button class='md-raised md-mini md-primary' aria-label='add' ng-click='vm.addNew()'>" +
                    "Προσθήκη Νέας Εγγραφής</md-button>" },*/
                { template: "<div class='right'><label>Σύνολο: {{vm.leadCallsGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }
            ]
        };

        vm.masterFiltersOnClick = function(e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id === "today") {
                KendoToolbar.clearDatePickers();
                vm.from = date;
                vm.to = date;
            }
            else if (e.id === "week") {
                KendoToolbar.clearDatePickers();
                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6
                vm.from = firstWeekDay;
                vm.to = lastWeekDay;
            }
            else if (e.id === "month") {
                KendoToolbar.clearDatePickers();
                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                vm.from = firstMonthDay;
                vm.to = lastMonthDay;
            }
            else if (e.id === "year") {
                KendoToolbar.clearDatePickers();
                vm.from = new Date(date.getFullYear(), 0, 1);
                vm.to = new Date();
            }
            KendoToolbar.setFrom(vm.from);
            KendoToolbar.setTo(vm.to);
            KendoGrid.refresh(vm.leadCallsGridOptions);
        };

        vm.toolbarOptions = {
            items: KendoToolbar.getOptions(["buttonGroup", "year", "from", "to"], vm)
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.from = value;
                } else {
                    vm.from = null;
                }

                vm.masterFiltersOnClick(e);
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.to = value;
                } else {
                    vm.to = null;
                }

                vm.masterFiltersOnClick(e);
            }
        };

        vm.addNew = function() {
            $state.go('leadCallView', {id: null});
        };

        vm.onFilterChange = function () {
            angular.element("#leadCallsGrid").data("kendoGrid").dataSource.read();
        };

        vm.edit = function(dataItem) {
            $state.go('leadCallView', {id: dataItem.id});
            //vm.leadCallWindow.title("Επεξεργασία Κλήσης");
            //vm.leadCallWindow.center().open();
        };
    }
})();
