(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('FirstNameDeleteController',FirstNameDeleteController);

    FirstNameDeleteController.$inject = ['$uibModalInstance', 'entity', 'FirstName'];

    function FirstNameDeleteController($uibModalInstance, entity, FirstName) {
        var vm = this;
        vm.firstName = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            FirstName.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
