(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('EventCategoryTypeDetailController', EventCategoryTypeDetailController);

    EventCategoryTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'EventCategoryType', 'EventCategory', 'EventType'];

    function EventCategoryTypeDetailController($scope, $rootScope, $stateParams, entity, EventCategoryType, EventCategory, EventType) {
        var vm = this;
        vm.eventCategoryType = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:eventCategoryTypeUpdate', function(event, result) {
            vm.eventCategoryType = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
