(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ChangesDetailController', ChangesDetailController);

    ChangesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Changes', 'ChangeField', 'ChangeTable', 'Customer', 'Sector', 'CustomerSector', 'WorkingDay', 'Employee'];

    function ChangesDetailController($scope, $rootScope, $stateParams, entity, Changes, ChangeField, ChangeTable, Customer, Sector, CustomerSector, WorkingDay, Employee) {
        var vm = this;
        vm.changes = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:changesUpdate', function(event, result) {
            vm.changes = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
