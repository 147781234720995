/**
 * Created by Kristy on 1/6/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('recDoctorInfo', {
                parent: 'recDoctorView',
                url: '',
                data: {
                    authorities: ['ROLE_REC_ADMINISTRATOR', 'ROLE_REC_HEALTH_VISITOR', 'ROLE_REC_MANAGEMENT'],
                    pageTitle: 'sigmaCrmApp.recDoctor.home.title'
                },
                templateUrl: 'app/displays/recDoctors/info/info.html',
                controller: 'RecDoctorViewInfoController',
                controllerAs: 'vm',
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
