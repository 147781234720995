(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CompanyTypeDeleteController',CompanyTypeDeleteController);

    CompanyTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'CompanyType'];

    function CompanyTypeDeleteController($uibModalInstance, entity, CompanyType) {
        var vm = this;
        vm.companyType = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            CompanyType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
