/**
 * Created by Kristy on 15/6/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminCustomerSectorsAllController', AdminCustomerSectorsAllController);

    AdminCustomerSectorsAllController.$inject = ['$scope', '$filter', '$timeout','$http', 'favouriteFilters', 'employee', 'customersView', 'SupervisorsService', 'MasterFiltersService', 'KendoFilter', 'FavouriteFiltersService', 'MasterToKendoFiltersService', 'KendoService'];

    function AdminCustomerSectorsAllController ($scope, $filter, $timeout, $http, favouriteFilters, employee, customersView, SupervisorsService, MasterFiltersService, KendoFilter, FavouriteFiltersService, MasterToKendoFiltersService, KendoService) {
        var vm = this;
        vm.favouriteFilters = favouriteFilters.data.content;
        vm.employee = employee;
        SupervisorsService.setSelectedSupervisors([]);
        vm.masterFilters = { selectedSupervisors: SupervisorsService.getSelectedSupervisors(), selectedCustomerSectors: [], selectedCities: [],
            selectedPrefectures: [], selectedSectorTypes: [], myTarget: true, selectedSoftwares: [], isActive: true, lastVisitDate: { operator: 'eqdate' } };
        vm.pageHeading = 'Ευρετήριο Μονάδων Υγείας Επαγγελματία Υγείας';
        vm.customersURL = '/api/customer-sectors/all';
        vm.isEdited = vm.isRenameMode = false;
        vm.selectedFilter = {};
        vm.disabled = true;
        vm.mainGrid = {};
        vm.masterOptions = [];
        vm.filter = {};
        vm.view = 'customerSectors';
        vm.customerView = customersView.data.content[0].value;

        var setView = function() {
            var filterable = vm.masterFilters.selectedCustomerSectors.length || SupervisorsService.getSelectedSupervisors().length ? {
                mode: "row"
            } : false;

            vm.disabled = !vm.masterFilters.selectedCustomerSectors.length && !SupervisorsService.getSelectedSupervisors().length;
            vm.mainGrid.setOptions({
                sortable: vm.masterFilters.selectedCustomerSectors.length || SupervisorsService.getSelectedSupervisors().length,
                groupable: vm.masterFilters.selectedCustomerSectors.length || SupervisorsService.getSelectedSupervisors().length,
                filterable: filterable
            });
        };

        $scope.$watch("vm.masterFilters.selectedSupervisors",function(value){
            SupervisorsService.setSelectedSupervisors(value);
            setView();
            vm.employeesMulti.enable(!vm.masterFilters.selectedSupervisors.length);
        });
        $scope.$watch("vm.masterFilters.selectedCustomerSectors", function () {
            setView();
            vm.supervisorsMulti.enable(!vm.masterFilters.selectedCustomerSectors.length);
        });

        vm.masterFiltersEdited = function(){
            var gridOptions = FavouriteFiltersService.getKendoOptions(vm.mainGrid);
            $timeout(function(){
                vm.isEdited = !!(vm.selectedFilter.kendoOptions && vm.masterOptions.length &&
                    (!angular.equals(vm.masterOptions, MasterToKendoFiltersService.getMasterFilters([], vm.masterFilters, vm.view)) || !angular.equals(JSON.parse(vm.selectedFilter.kendoOptions), gridOptions)) );
            });
        };
        $scope.$watch("vm.masterFilters", vm.masterFiltersEdited, true);

        vm.collapseAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-collapse").trigger("click"); };
        vm.expandAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-expand").trigger("click"); };

        vm.supervisorsMultiOptions = MasterFiltersService.getSupervisorsComboBoxOptions();
        vm.supervisorsMultiOptions.change = function () {
            vm.employeesMulti.value('');
            vm.employeesMultiOptions.dataSource.filter({});
            vm.selectedCustomerSectors = [];
            vm.employeesMulti.dataSource.read();
            $timeout(function (){ vm.disabled = !vm.masterFilters.selectedCustomerSectors.length && !SupervisorsService.getSelectedSupervisors().length; });
        };
        vm.employeesMultiOptions = MasterFiltersService.getEmployeesComboBoxOptions(["all", "none", "me"]);
        vm.employeesMultiOptions.dataSource.filter({});

        vm.citiesMultiDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function (o) {
                    if (o.data.filter == undefined) o.data.filter = {filters: []};

                    o.data.page = 1;
                    o.data.pageSize = 100;
                    $http.get('/api/masterdata/cities', {params: o.data})
                        .success(function (response) {
                            response.content.unshift({id:"none", name:"(Κενό)", prefecture: {}}, {id:"all", name:"Επιλογή όλων", prefecture: {}});
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            }
        });
        vm.citiesMultiOptions = {
            dataSource: vm.citiesMultiDataSource,
            filter: "startswith",
            valuePrimitive: false,
            dataTextField: "name",
            dataValueField: "id",
            template: "<table>${name} &nbsp;" +
            "<span ng-if=\"this.dataItem.id != 'all' && this.dataItem.id != 'none'\" class='hint'>(${prefecture.name})</span></table>",
            select: function(e) {
                var dataItem = this.dataSource.view()[e.item.index()];//
                var values = this.value();
                var self = this;

                MasterFiltersService.selectAllNone(self, dataItem, values, ["all", "none"]);
            }
        };

        vm.prefecturesMultiDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function (o) {
                    if (o.data.filter == undefined) o.data.filter = {filters: []};

                    o.data.page = 1;
                    o.data.pageSize = 100;
                    $http.get('/api/masterdata/prefectures', {params: o.data})
                        .success(function (response) {
                            response.content.unshift({id:"none", name:"(Κενό)"}, {id:"all", name:"Επιλογή όλων"});
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            }
        });
        vm.prefecturesMultiOptions = {
            dataSource: vm.prefecturesMultiDataSource,
            filter: "startswith",
            valuePrimitive: false,
            dataTextField: "name",
            dataValueField: "id",
            select: function(e) {
                var dataItem = this.dataSource.view()[e.item.index()];
                var values = this.value();
                var self = this;

                MasterFiltersService.selectAllNone(self, dataItem, values, ["all", "none"]);
            }
        };

        vm.sectorTypesMultiDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function (o) {
                    if (o.data.filter == undefined) o.data.filter = {filters: []};

                    o.data.page = 1;
                    o.data.pageSize = 100;
                    $http.get('/api/masterdata/sector-types', {params: o.data})
                        .success(function (response) {
                            response.content.unshift({id:"none", name:"(Κενό)"}, {id:"all", name:"Επιλογή όλων"});
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            }
        });
        vm.sectorTypesMultiOptions = {
            dataSource: vm.sectorTypesMultiDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id",
            select: function(e) {
                var dataItem = this.dataSource.view()[e.item.index()];
                var values = this.value();
                var self = this;

                MasterFiltersService.selectAllNone(self, dataItem, values, ["all", "none"]);
            }
        };

        var softwareDataSource = [{text: 'Επιλογή όλων', value: 'all'}, {text: 'Όχι', value: 'none'},
            {text: $filter('translate')('global.field.software.medexpress'), value: 'medexpress'},
            {text: $filter('translate')('global.field.software.medmobile'), value: 'medmobile'},
            {text: $filter('translate')('global.field.software.other'), value: 'other'}];
        vm.softwareOptions = {
            dataSource: softwareDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "text",
            dataValueField: "value",
            placeholder: "Επιλογή λογισμικού",
            select: function (e) {
                var dataItem = this.dataSource.view()[e.item.index()];
                var values = this.value();

                if (dataItem.value === "all" || dataItem.value === "none") {
                    this.value("");
                } else if (values.indexOf("all") !== -1) {
                    values = angular.element.grep(values, function(value) {
                        return value !== "all";
                    });
                    this.value(values);
                } else if (values.indexOf("none") !== -1) {
                    values = angular.element.grep(values, function(value) {
                        return value !== "none";
                    });
                    this.value(values);
                } else if (values.indexOf("other") !== -1) {
                    values = angular.element.grep(values, function(value) {
                        return value !== "other";
                    });
                    this.value(values);
                }
            }
        };

        vm.openMenu = function($mdOpenMenu, ev) { $mdOpenMenu(ev); };

        vm.clearDatePickers = function () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        };

        vm.masterFiltersOnClick = function (e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id == "today") {

                vm.clearDatePickers();
                $timeout(function() {
                    vm.masterFilters.from = date;
                    vm.masterFilters.to = date;
                });

            }
            else if (e.id == "week") {

                vm.clearDatePickers();

                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6

                $timeout(function() {
                    vm.masterFilters.from = firstWeekDay;
                    vm.masterFilters.to = lastWeekDay;
                });
            }
            else if (e.id == "month") {

                vm.clearDatePickers();

                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                $timeout(function() {
                    vm.masterFilters.from = firstMonthDay;
                    vm.masterFilters.to = lastMonthDay;
                });
            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup", id:"buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ], overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Από: </label><input class='inline' kendo-date-picker id='from' options='vm.fromOptions' k-ng-model='vm.masterFilters.from'/>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Εώς: </label><input class='inline' kendo-date-picker id='to' options='vm.toOptions' k-ng-model='vm.masterFilters.to'/>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Προιστάμενος: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.supervisorsMulti' multiple='multiple' " +
                    "k-options='vm.supervisorsMultiOptions' k-ng-model='vm.masterFilters.selectedSupervisors'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Ιατρικός Επισκέπτης: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.employeesMulti' multiple='multiple' " +
                    "k-options='vm.employeesMultiOptions' k-ng-model='vm.masterFilters.selectedCustomerSectors'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Πόλη: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select multiple='multiple' " +
                    "k-options='vm.citiesMultiOptions' k-ng-model='vm.masterFilters.selectedCities'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Νομός: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select multiple='multiple' " +
                    "k-options='vm.prefecturesMultiOptions' k-ng-model='vm.masterFilters.selectedPrefectures'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Τύπος: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select multiple='multiple' " +
                    "k-options='vm.sectorTypesMultiOptions' k-ng-model='vm.masterFilters.selectedSectorTypes'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>My Target: </label><div class='inline'><md-radio-group layout='row' ng-model='vm.masterFilters.myTarget' ng-disabled='vm.disabled'>" +
                    "<md-radio-button ng-value=true class='md-primary'> Ναι </md-radio-button>" +
                    "<md-radio-button ng-value=false class='md-primary'> Όχι </md-radio-button>" +
                    "<md-radio-button ng-value='' class='md-primary'> Όλα </md-radio-button></md-radio-group></div>",
                    overflow: "never", attributes: {class: "left left-space"}
                },
                {
                    template: "<label>Ενεργός: </label><md-checkbox aria-label='isActive' ng-model='vm.masterFilters.isActive' ng-change='vm.onFilterChange()' class='checkbox-toolbar green inline'></md-checkbox>",
                    overflow: "never", attributes: {class: "left left-space"}
                },
                {
                    template: "<div ng-if='vm.customerView === \"3\"'><label>Λογισμικό: </label><select kendo-multi-select multiple='multiple' id='multiSelect' name='multiSelect' class='inline' k-ng-model='vm.masterFilters.selectedSoftwares' " +
                    "                            k-options='vm.softwareOptions' class='col-fix'></select></div>",
                    overflow: "never", attributes: {class: "left left-space"}
                },
                {
                    template: "<div ng-if='vm.customerView === \"3\"'><label>Ημέρες Τελ. Επίσκεψης: </label>" +
                    "<input id='lastVisitDate' style='width: 5em;' kendo-numeric-text-box min='0' k-ng-model='vm.masterFilters.lastVisitDate.value' k-format='\"n0\"'/>" +
                    "<md-menu class='master-filter-btn'> <md-button aria-label='Open filter operators menu' class='md-icon-button' ng-click='vm.openMenu($mdOpenMenu, $event)'> " +
                    "<md-icon class='filter-list-icon'></md-icon> </md-button> " +
                    "<md-menu-content width='4'> " +
                    "<md-menu-item> <md-button ng-click='vm.masterFilters.lastVisitDate.operator = \"eqdate\" '> Ίσο</md-button></md-menu-item> " +
                    "<md-menu-item> <md-button ng-click='vm.masterFilters.lastVisitDate.operator = \"gte\" '> Μικρότερο ή Ίσο</md-button></md-menu-item> " +
                    "<md-menu-item> <md-button ng-click='vm.masterFilters.lastVisitDate.operator = \"lte\" '> Μεγαλύτερο ή Ίσο</md-button></md-menu-item> " +
                    "</md-menu-content> </md-menu></div>",
                    overflow: "never", attributes: {class: "left left-space"}
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary' aria-label='search' ng-click='vm.mainGridOptions.dataSource.read()' ng-disabled='vm.disabled'>" +
                    "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>", attributes: { class: "right" },
                    overflow: "never"
                }
            ]
        };

        vm.fromOptions = {
            change: function() {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.masterFilters.from = value;
                } else {
                    vm.masterFilters.from = null;
                }
            }
        };

        vm.toOptions = {
            change: function() {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.masterFilters.to = value;
                } else {
                    vm.masterFilters.to = null;
                }
            }
        };

        var filterTemplate = function (args) {
            KendoFilter.getTemplate(args);
        };

        var dayTimeFilterTemplate = function (args) {
            KendoFilter.getDayTimeTemplate(args);
        };

        var booleanFilterTemplate = function (args) {
            KendoFilter.getBooleanTemplate(args);
        };

        vm.mainGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function (o) {
                        if (o.data.filter == undefined) o.data.filter = {filters: []};
                        o.data.filter.filters = MasterToKendoFiltersService.getMasterFilters(o.data.filter.filters, vm.masterFilters, vm.view);

                        var foundCitiesByField = $filter('filter')(o.data.filter.filters, {field: "sector.cityName"}, true)[0];
                        if (vm.masterFilters.selectedCities.length) {
                            if (foundCitiesByField.value !== "" && foundCitiesByField.value !== "none") {
                                var cityArray = [];

                                angular.forEach(vm.masterFilters.selectedCities, function (city) {
                                    cityArray.push(city.name);
                                });
                                foundCitiesByField.value = cityArray.toString();
                            }
                        }

                        var foundPrefecturesByField = $filter('filter')(o.data.filter.filters, {field: "sector.prefectureName"}, true)[0];
                        if (vm.masterFilters.selectedPrefectures.length) {
                            if (foundPrefecturesByField.value !== "" && foundPrefecturesByField.value !== "none") {

                                var prefectureArray = [];
                                angular.forEach(vm.masterFilters.selectedPrefectures, function (prefecture) {
                                    prefectureArray.push(prefecture.name);
                                });
                                foundPrefecturesByField.value = prefectureArray.toString();
                            }
                        }

                        if(vm.masterFilters.selectedSoftwares.length) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "customer.software", "operator":  vm.masterFilters.selectedSoftwares.indexOf('none') > -1 ? "isnull" : "in",
                                        "value": vm.masterFilters.selectedSoftwares.indexOf('all') > -1 ? "medexpress,medmobile,other" :
                                            vm.masterFilters.selectedSoftwares.indexOf('none') > -1 ? null :
                                                vm.masterFilters.selectedSoftwares.toString() }
                                ]
                            );
                        }

                        if(vm.masterFilters.lastVisitDate.value) {
                            var dt = new Date();
                            dt.setDate( dt.getDate() - vm.masterFilters.lastVisitDate.value );

                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "customer.lastVisitDate", "operator":  vm.masterFilters.lastVisitDate.operator ? vm.masterFilters.lastVisitDate.operator : 'eqdate',
                                        "value": dt }
                                ]
                            );
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "customer.isActive",
                                    "operator":  "eqbool",
                                    "value": vm.masterFilters.isActive }
                            ]
                        );

                        o.data.filter.filters.push({
                            field: "employeeCustomerSectors.active",
                            operator: "eqbool",
                            value: true
                        });

                        angular.forEach(o.data.filter.filters, function (item) {
                            if ( item.field === 'lastVisitDate' ) {
                                var dt = new Date();
                                dt.setDate( dt.getDate() - item.value );
                                item.value = dt;
                            }
                        });

                        if (vm.customerView === '3') {
                            // Cross, fetch only not expired customerSectors
                            o.data.filter.filters.push({
                                field: "endedAt",
                                operator: "null_or_greater",
                                value: $filter('date')(Date.now(), 'yyyy-MM-dd')
                            });
                        }

                        if(vm.customerView === '3' || vm.customerView === '1' || vm.customerView === '5' || vm.customerView === '6') {
                            vm.customersURL = '/api/customer-sectors/employees/all';
                        }
                        $http.get(vm.customersURL, {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    }
                },
                schema: {
                    data: function (data) {
                        data.forEach(function (item) {
                            if (item.customer === null) item.customer = {};
                            if (item.customer !== null) {
                                if (item.customer.customerCategory === null) item.customer.customerCategory = {};
                                if (item.customer.customerSubCategory === null) item.customer.customerSubCategory = {};
                                if (item.customer.doctorSpecialty === null) item.customer.doctorSpecialty = {}
                            }
                            if (item.sector === null) item.sector = {};
                            if (item.sector !== null) {
                                if (item.sector.city === null) item.sector.city = {};
                                if (item.sector.prefecture === null) item.sector.prefecture = {};
                                if (item.sector.sectorType === null) item.sector.sectorType = {};
                            }
                            item.employeesString = "";
                            if (item.employees) {
                                item.employees.forEach(function (employeeName) {
                                    item.employeesString += "[" + employeeName + "] ";
                                });
                            }
                        });
                        return data;
                    },
                    parse: function (data) {
                        var dt = new Date();
                        dt.setHours(23, 59, 59);
                        data.forEach(function (item) {
                            if (item.customer) {
                                item.customer.lastVisitDate =
                                    item.customer.lastVisitDate ? Math.floor(Math.abs(new Date(item.customer.lastVisitDate).getTime() - dt.getTime()) / (1000 * 60 * 60 * 24)) : '';
                            }
                        });
                        return data;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: {editable: false, nullable: false},
                            isActive: {
                                type: "boolean",
                                defaultValue: true,
                                parse: function (value) {
                                    return !!(value == "1" || value == "true" || value == true);

                                }
                            },
                            'customer.lastVisitDate': { type: 'number', editable: false}
                        }
                    }
                },
                aggregate: [{field: "customer.lastName", aggregate: "count"},
                    {field: "customer.firstName", aggregate: "count"},
                    {field: "customer.doctorSpecialty.name", aggregate: "count"},
                    {field: "customer.customerCategory.name", aggregate: "count"},
                    {field: "customer.customerSubCategory.name", aggregate: "count"},
                    {field: "customer.dayTime", aggregate: "count"},
                    {field: "customer.title", aggregate: "count"},
                    {field: "sector.name", aggregate: "count"},
                    {field: "sector.sectorType.name", aggregate: "count"},
                    {field: "sector.address", aggregate: "count"},
                    {field: "sector.osfeBrick", aggregate: "count"},
                    {field: "sector.prefectureName", aggregate: "count"},
                    {field: "sector.cityName", aggregate: "count"},
                    {field: "main", aggregate: "count"}]
            }),
            sortable: false,
            groupable: false,
            filterable: false,
            resizable: true,
            columns: [
                {
                    title: "Στοιχεία Επαγγελματία Υγείας",
                    columns: [
                        {
                            field: "customer.lastName",
                            title: "Επώνυμο",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            groupHeaderTemplate: "#= value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "customer.firstName",
                            title: "Όνομα",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            groupHeaderTemplate: "#= (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "customer.doctorSpecialty.name",
                            title: "Ειδικοτητα 1η",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "customer.customerCategory.name",
                            title: "Κατηγορία",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            groupHeaderTemplate: "#=  typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "customer.customerSubCategory.name",
                            title: "Υποκατηγορία",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            groupHeaderTemplate: "#=  typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "customer.dayTime",
                            title: "Ωράριο",
                            aggregates: ["count"],
                            template: "#= (customer.dayTime == 'day') ? 'ΠΡΩΙΝΌ' : (customer.dayTime == 'night') ? 'ΑΠΟΓΕΥΜΑΤΙΝΌ' : '' #",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    showOperators: false,
                                    template: dayTimeFilterTemplate
                                }
                            },
                            groupHeaderTemplate: "#= typeof value !== 'undefined' ? ((value == 'day') ? 'ΠΡΩΙΝΟ' : (value == 'night') ? 'ΑΠΟΓΕΥΜΑΤΙΝΟ' : '') : '' #(Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            hidden: vm.customerView === '1' || vm.customerView === '6',
                            field: "customer.title",
                            title: "Τίτλος",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            groupHeaderTemplate: "#=typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            hidden: vm.customerView !== '3',
                            field: "customer.software",
                            title: "Λογισμικό",
                            template: function (dataItem) {
                                return dataItem.customer.software ? $filter('translate')('global.field.software.' + dataItem.customer.software ) : 'Όχι';
                            },
                            filterable: false
                        },
                        {
                            hidden: vm.customerView !== '3',
                            field: "customer.lastVisitDate", title: "Ημέρες Τελ. Επίσκεψης",
                            filterable: {
                                operators: {
                                    number: {
                                        eq: 'Ίσο',
                                        gte: 'Μεγαλύτερο ή Ίσο',
                                        lte: 'Μικρότερο ή Ίσο'
                                    }
                                },
                                cell: {
                                    template: function (args) {
                                        args.element.kendoNumericTextBox({
                                            format: "n0",
                                            decimals: 0,
                                            min: 0
                                        });

                                    }
                                }
                            },
                            width: 150
                        }
                    ]
                },
                {
                    title: "Στοιχεία Μονάδας Υγείας",
                    columns: [
                        {
                            field: "sector.name",
                            title: "Όνομα",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "contains",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            groupHeaderTemplate: "#= value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "sector.sectorType.name",
                            title: "Τύπος",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            groupHeaderTemplate: "#= (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "sector.address",
                            title: "Διεύθυνση",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            groupHeaderTemplate: "#= (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "sector.addressNum",
                            title: "Αριθμός",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            groupHeaderTemplate: "#=  (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "sector.osfeBrick",
                            title: "Brick ΟΣΦΕ",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            groupHeaderTemplate: "#=  (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "sector.prefectureName",
                            title: "Νομός",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            groupHeaderTemplate: "#= (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            field: "sector.cityName",
                            title: "Πόλη",
                            aggregates: ["count"],
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: filterTemplate,
                                    showOperators: false
                                }
                            },
                            groupHeaderTemplate: "#= (value == null) ? '' : value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        },
                        {
                            hidden: vm.customerView === '1' || vm.customerView === '6',
                            field: "main",
                            title: "Κύρια Μ.Υ.",
                            aggregates: ["count"],
                            template: "#= (main == true) ? 'Ναι' : 'Όχι' #",
                            filterable: {
                                cell: {
                                    operator: "eq",
                                    showOperators: false,
                                    template: booleanFilterTemplate
                                }
                            },
                            groupHeaderTemplate: "#= (value == true) ? 'Κύρια Μ.Υ.: Ναι' : (value == false) ? 'Κύρια Μ.Υ.: Όχι' : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                        }
                    ]
                },
                {
                    hidden: vm.customerView !== '3' && vm.customerView !== '1' && vm.customerView !== '5',
                    field: "employeesString", title: "Ιατρικοι Επισκεπτες",
                    filterable: false,
                    sortable: false
                }

            ],
            toolbar: [
                {template: "<md-button class='md-raised md-mini md-primary' aria-label='collapseAll' ng-click='vm.collapseAll()'>Collapse All</md-button>"},
                {template: "<md-button class='md-raised md-mini md-primary' aria-label='expandAll' ng-click='vm.expandAll()'>Expand All</md-button>"},
                {
                    name: "excel",
                    template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                    "<span class='k-icon k-i-excel'></span>Export</md-button></div>"
                }
            ],
            excel: {
                allPages: true,
                fileName: "Επαγγελματίες ΥγείαςΜονάδες.xlsx"
            },
            dataBound: function () { vm.masterFiltersEdited(); }
        };

        $timeout(function () {
            MasterFiltersService.clearFilterEvent()
        });
    }
})();
