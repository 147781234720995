(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('OtherEventDetailController', OtherEventDetailController);

    OtherEventDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'OtherEvent', 'EventCategoryType', 'Employee'];

    function OtherEventDetailController($scope, $rootScope, $stateParams, entity, OtherEvent, EventCategoryType, Employee) {
        var vm = this;
        vm.otherEvent = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:otherEventUpdate', function(event, result) {
            vm.otherEvent = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
