/**
 * Created by Kristy on 1/6/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('recDoctors', {
                parent: 'app',
                url: '/recDoctors',
                data: {
                    authorities: ['ROLE_REC_ADMINISTRATOR', 'ROLE_REC_HEALTH_VISITOR', 'ROLE_REC_MANAGEMENT'],
                    pageTitle: 'sigmaCrmApp.recDoctor.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/displays/recDoctors/recDoctors.html',
                        controller: 'RecDoctorsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

    .state('recDoctorView', {
            abstract: true,
            parent: 'app',
            url: '/recDoctors/search?id:doctorId',
            data: {
                authorities: ['ROLE_REC_ADMINISTRATOR', 'ROLE_REC_HEALTH_VISITOR', 'ROLE_REC_MANAGEMENT'],
                pageTitle: 'sigmaCrmApp.recDoctor.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/displays/recDoctors/recDoctorView.html',
                    controller: 'RecDoctorViewController',
                    controllerAs: 'vm'
                }
            },
            params: {
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                    return {
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
    }

})();
