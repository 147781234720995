(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SectorTypeDetailController', SectorTypeDetailController);

    SectorTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'SectorType'];

    function SectorTypeDetailController($scope, $rootScope, $stateParams, entity, SectorType) {
        var vm = this;
        vm.sectorType = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:sectorTypeUpdate', function(event, result) {
            vm.sectorType = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
