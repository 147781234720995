/**
 * Created by Kristy on 7/5/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MDRoomCostsController', MDRoomCostsController);

    MDRoomCostsController.$inject = ['$scope', 'KendoGrid', '$http', '$filter', 'KendoFilter', 'KendoService', 'RoomCost'];

    function MDRoomCostsController ($scope, KendoGrid, $http, $filter, KendoFilter, KendoService, RoomCost) {
        var vm = this;
        vm.pageHeading = $filter('translate')('masterData.roomCosts.title');
        var dataSource = {};
        var onSaveSuccess = function () {
            KendoGrid.refresh(vm.mainGridOptions);
        };

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) o.data.filter = { filters: [] };

                        $http.get('/api/room-costs/search',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    },
                    create: function(o) {
                        var data = o.data.models[0];
                        RoomCost.update(data, onSaveSuccess);
                    },
                    update: function(o) {
                        var data = o.data.models[0];
                        RoomCost.update(data, onSaveSuccess);
                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            cost: { type: "number" },
                            costType: { defaultValue: null },
                            roomType: { defaultValue: null }
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            toolbar: [{name :"create", text: "Προσθήκη Νέας εγγραφής"},
                { template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }],
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "cost", title: "Κόστος",
                    filterable: {
                        cell: {
                            operator: "eq",
                            template: function (args) {
                                args.element.kendoNumericTextBox({
                                    format: "{0:n0}"
                                });
                            },
                            showOperators: false
                        }
                    },
                    attributes:{style:"text-align:right;"},
                    template: "#= (cost == null) ? '' : kendo.format('{0:n0}', cost)#"
                },
                {
                    field: "costType", title: "Τύπος Κόστους",
                    template: "#= costType ? costType.name : '' #",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    editor: function(container) {
                        //costType combobox edit configuration
                        dataSource.costType = KendoService.initDataSource('/api/cost-types/search', 1, 50);
                        vm.costTypeOptions = KendoService.initDefaultOptions(dataSource.costType, "startswith", "name", "id", false);

                        angular.element('<select id="costType" data-bind="value:costType" ' +
                            'kendo-combo-box k-options="vm.costTypeOptions"></select>')
                            .appendTo(container)
                    }
                },
                {
                    field: "roomType", title: "Τύπος Δωματίου",
                    template: "#= roomType ? roomType.name : '' #",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    editor: function(container) {
                        //roomType combobox edit configuration
                        dataSource.roomType = KendoService.initDataSource('/api/room-types/search', 1, 50);
                        vm.roomTypeOptions = KendoService.initDefaultOptions(dataSource.roomType, "startswith", "name", "id", false);

                        angular.element('<select id="roomType" data-bind="value:roomType" ' +
                            'kendo-combo-box k-options="vm.roomTypeOptions"></select>')
                            .appendTo(container)
                    }
                },
                {
                    command:[
                        {name:"edit", text: "Επεξεργασία"}
                    ],
                    text: "Επεξεργασία",
                    title: "&nbsp;"
                }
            ],
            edit: function() { KendoGrid.defaultEditPopup(this._editContainer); },
            editable: "popup"
        };

    }
})();
