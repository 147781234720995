/**
 * Created by Kristy on 24/5/2017.
 */
(function () {
    'use strict';

    angular
        .module('sigmasfApp')
        .service('EmployeeViewService', EmployeeViewService);

    EmployeeViewService.$inject = ['$filter'];
    function EmployeeViewService ($filter) {
        var selectedUser = {};

        return {
            getSelectedUser: function () {
                return selectedUser;
            },
            setSelectedUser: function (value) {
                selectedUser = value;
            }
        };

    }
})();
