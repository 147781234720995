/**
 * Created by Kristy on 2/8/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SectorCustomersController', SectorCustomersController);

    SectorCustomersController.$inject = ['$http', 'entity', '$stateParams','$filter', 'KendoGrid', 'KendoFilter', 'MasterFiltersService', 'CustomUtils', '$mdToast'];

    function SectorCustomersController ($http, entity, $stateParams, $filter, KendoGrid, KendoFilter, MasterFiltersService, CustomUtils, $mdToast) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(58);
        vm.sector = entity;
        vm.customers = vm.sector.customers;

        vm.selectedSectorId = $stateParams.id;
        vm.selectedAssignees = vm.selectedEmployees =[];
        vm.massSelect = true;
        vm.startedAt = kendo.toString(new Date(), "d");

        vm.dataSource = {};
        vm.dataSource.grid = {};
        vm.dataSource.grid.customerSector = new kendo.data.DataSource({
            autoBind: false,
            transport: {
                read: function (o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = {filters: []};
                    }

                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            {"field": "sector.id", "operator": "eq", "value": vm.selectedSectorId}
                        ]
                    );

                    $http.get('/api/me/customer-sectors',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {
                            o.error(response);
                            $mdToast.show(
                                $mdToast.simple()
                                    .textContent('Κάποιο σφάλμα προέκυψε')
                                    .position("bottom right")
                                    .hideDelay(3000));
                        });
                },
                create: function (o) {
                    angular.forEach(vm.selectedAssignees, function (item) {
                        if (!item.customerCategory.name) item.customerCategory = null;
                        if (!item.customerSubCategory.name) item.customerSubCategory = null;
                        if (!item.customerTitle.name) item.customerTitle = null;
                        if (!item.doctorSpecialty.name) item.doctorSpecialty = null;
                        if (!item.doctorSpecificSpecialty.name) item.doctorSpecificSpecialty = null;
                    });

                    $http.post(' api/customers/sector/'+ vm.selectedSectorId, vm.selectedAssignees)
                        .success(function () {
                            KendoGrid.refresh(vm.gridOptions.customerSector);
                        })
                        .error(function (response) {});
                }
            },
            batch: true,
            schema: {
                data: function (data) {
                    if(data.content !== undefined) {
                        data.content.forEach(function(item){
                            if (item.customer){
                                if(item.customer.doctorSpecialty === null || item.customer.doctorSpecialty === undefined){
                                    item.customer.doctorSpecialty = { name: "" };
                                }

                                if(item.customer.customerCategory === null || item.customer.customerCategory === undefined){
                                    item.customer.customerCategory = { name: "" };
                                }

                                if(item.customer.dayTime !== null && item.customer.dayTime !== undefined){
                                    if(item.customer.dayTime === "day") {
                                        item.customer.dayTime = "ΠΡΩΙΝΌ";
                                    }else if(item.customer.dayTime === "night"){
                                        item.customer.dayTime = "ΑΠΟΓΕΥΜΑΤΙΝΌ";
                                    }
                                }else {
                                    item.customer.dayTime = ""
                                }

                                if(item.customer.customerSubCategory !== null && item.customer.customerSubCategory !== undefined){
                                    if(item.customer.customerSubCategory === "one") {
                                        item.customer.customerSubCategory = '1';
                                    }else if(item.customer.customerSubCategory === "two"){
                                        item.customer.customerSubCategory = '2';
                                    }
                                }else {
                                    item.customer.customerSubCategory = {}
                                }
                            }else {
                                item.customer = {
                                    firstName: "",
                                    lastName: "",
                                    cellPhone: "",
                                    phone: "",
                                    email: "",
                                    doctorSpecialty: { name: "" },
                                    customerCategory: { name: "" },
                                    customerSubCategory: {},
                                    dayTime: {},
                                    title: "",
                                    isActive: null,
                                    saw: null
                                };
                            }
                        });
                    }
                    return data.content;

                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        id: { editable: false, nullable: true},
                        customer: { defaultValue: {
                            doctorSpecialty: { defaultValue: {id: null, name : null} },
                            customerCategory: { defaultValue: {id: null, name : null} },
                                customerSubCategory: { defaultValue: {id: null, name : null}} }
                        },
                        'customer.isActive':{
                            type: "boolean",
                            defaultValue: true,
                            parse: function(value) {
                                if (value == "1" || value == "true" || value == true) {
                                    return true;
                                }
                                return false;
                            }
                        },
                        'customer.saw':{
                            type: "boolean",
                            defaultValue: true,
                            parse: function(value) {
                                return !!(value == "1" || value == "true" || value == true);

                            }
                        },
                        'customer.dayTime':{defaultValue: null}
                    }
                }
            },
            serverFiltering: true,
            serverSorting: true,
            serverPaging: true,
            pageSize: 20,
            scrollable:false
        });

        vm.dataSource.grid.suggestedCustomers = new kendo.data.DataSource({
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    //master filters functionality
                    if(vm.selectedEmployees.length) {
                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "customerSectors.employeeCustomerSectors.employee.id", "operator": "in",
                                    "value": vm.selectedEmployees.toString() }
                            ]
                        );
                    }

                    $http.get('/api/customers/sectors/' + vm.selectedSectorId + '/suggest',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response);
                        })
                        .error(function (response) {
                            o.error(response);
                            console.log(response);
                        });
                }
            },
            batch: true,
            schema: {
                data: function (data) {
                    if(data.content != undefined) {
                        data.content.forEach(function(item){

                            if(item.doctorSpecialty === null){
                                item.doctorSpecialty = {};
                                item.doctorSpecialty.name = "";
                            }

                            if(item.doctorSpecificSpecialty === null){
                                item.doctorSpecificSpecialty = {};
                                item.doctorSpecificSpecialty.name = "";
                            }

                            if(item.customerCategory === null){
                                item.customerCategory = {};
                                item.customerCategory.name = "";
                            }

                            if(item.customerSubCategory === null){
                                item.customerSubCategory = {};
                                item.customerSubCategory.name = "";
                            }

                            if(item.customerTitle === null){
                                item.customerTitle = {};
                                item.customerTitle.name = "";
                            }

                        });
                    }
                    return data.content;

                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        id: { editable: false, nullable: true},
                        doctorSpecialty:{defaultValue: {id: null, name : null}},
                        customerCategory:{defaultValue: { id : null, name : null}},
                        customerSubCategory:{defaultValue: { id : null, name : null}}
                    }
                }
            },
            pageSize: 100,
            serverPaging: true,
            serverSorting: true,
            scrollable:false,
            serverFiltering :true
        });

        // MultiSelect Options
        vm.employeesMultiOptions = MasterFiltersService.getEmployeesComboBoxOptions(["none", "me"]); //"all",
        vm.employeesMultiOptions.change = function (e) {
            KendoGrid.refresh(vm.gridOptions.suggestedCustomers);
        };

        // Grid Columns
        vm.gridColumns = {};
        vm.gridColumns.customerSector = [
            {
                field: "customer.lastName",
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width:140
            },
            {
                field: "customer.firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.doctorSpecialty.name",
                title: "Ειδικοτητα 1η",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.customerCategory.name",
                title: "Κατηγορία",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.customerSubCategory.name",
                title: "Υποκατηγορία",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false,
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "1", value: "1" },
                                        { text: "2", value: "2" }]
                                }),
                                valuePrimitive: true

                            })
                        }
                    }
                }
            },
            {
                field: "customer.cellPhone",
                title: "Κινητό",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.phone",
                title: "Σταθερό Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.email",
                title: "Email",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.dayTime",
                title: "Ωράριο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false,
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "ΠΡΩΙΝΌ", value: "ΠΡΩΙΝΌ" },
                                        { text: "ΑΠΟΓΕΥΜΑΤΙΝΌ", value: "ΑΠΟΓΕΥΜΑΤΙΝΌ" }]
                                }),
                                valuePrimitive: true

                            })
                        }
                    }
                }
            },
            {
                field: "customer.title",
                title: "Τίτλος",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.isActive",
                title: "Ενεργός",
                filterable: {
                    cell: {
                        operator: "eqbool",
                        showOperators: false,
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "Ναι", value: "true" },
                                        { text: "Όχι", value: "false" }],
                                    filtering : function(e){
                                        console.log(e)
                                    }
                                }),
                                valuePrimitive: true

                            })
                        }
                    }
                },
                template: "#= (customer.isActive == '1') ? 'Ναι' : 'Όχι' #"
            },
            {
                field: "customer.saw",
                title: "MyTarget",
                filterable: {
                    cell: {
                        operator: "eqbool",
                        showOperators: false,
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "Ναι", value: "true" },
                                        { text: "Όχι", value: "false" }]
                                }),
                                valuePrimitive: true

                            })
                        }
                    }
                },
                template: "#= (customer.saw == '1') ? 'Ναι' : 'Όχι' #"
            }
        ];
        vm.gridColumns.suggestedCustomers = [
            {
                field: "lastName",
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {
                field: "firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {
                field: "doctorSpecialty.name",
                title: "Ειδικοτητα 1η",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {
                field: "doctorSpecificSpecialty.name",
                title: "Ειδικοτητα 2η",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {
                field: "customerCategory.name",
                title: "Κατηγορία",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 94
            },
            {
                field: "customerSubCategory.name",
                title: "Υποκατηγορία",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 115
            }
        ];

        // Grid Options
        vm.gridOptions = {};
        vm.gridOptions.customerSector = {
            dataSource: vm.dataSource.grid.customerSector,
            toolbar: [{ template: "<a class='k-button k-button-icontext k-grid-add'><span class='k-icon k-add'></span>Προσθήκη Επαγγελματιών Υγείας</a>" },
                { template: "<div class='right'><label>Σύνολο: {{vm.dataSource.grid.customerSector.total()}}</label></div>",
                    overflow: "never" }],
            sortable: true,
            pageable: true,
            selectable: true,
            scrolable: true,
            filterable: {
                mode: "row"
            },
            columns: vm.gridColumns.customerSector,
            edit: function(e) {
                vm.initPopupGrid = true;
                vm.selectedAssignees = [];
                vm.selectedEmployees = [];
                //reload popup grid
                KendoGrid.refresh(vm.gridOptions.suggestedCustomers);
                KendoGrid.defaultCustomEditPopup(e.container, 1300, 1290);
            },
            editable: {
                mode: "popup",
                template: kendo.template(angular.element("#popup_editor").html())
            },
            cancel: function() {
                KendoGrid.refresh(vm.gridOptions.customerSector);
                delete vm.initPopupGrid;
            },
            save: function() {
                delete vm.initPopupGrid;
            },
            noRecords: {
                template: "Δεν βρέθηκαν εγγραφές"
            }
        };
        vm.gridOptions.suggestedCustomers = {
            dataSource: vm.dataSource.grid.suggestedCustomers,
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: true,
            columns: vm.gridColumns.suggestedCustomers,
            selectedItems: function(dataItems) {
                vm.selectedAssignees = dataItems;
            },
            dataBound: function (e) {
                angular.element("#popupGridcheckbox").prop('checked', false);
                //toggle class on click
                angular.element(".checkbox").bind("change", function (e) {
                    angular.element(e.target).closest("tr").toggleClass("k-state-selected");
                });

                var view = this.dataSource.data();
                var me = this;

                var selectedAssigneesViewCounter = 0;
                angular.forEach( view, function(item) {
                    var foundItemById = $filter('filter')(vm.selectedAssignees, {id: item.id}, true)[0];
                    if(vm.selectedAssignees.indexOf(foundItemById) !== -1){
                        me.tbody.find("tr[data-uid='" + item.uid + "']")
                            .addClass("k-state-selected");
                        item.selected = true;
                        selectedAssigneesViewCounter++
                    }
                });
                if(selectedAssigneesViewCounter == view.length) {
                    angular.element("#popupGridcheckbox").prop('checked', true);
                }
            },
            noRecords: {
                template: "Δεν βρέθηκαν εγγραφές"
            }
        };
    }
})();
