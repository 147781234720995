/**
 * Created by Kristy on 8/7/2016.
 */
(function () {
    'use strict';

    angular
        .module('sigmasfApp')
        .service('MassSchedules', MassSchedules);

    MassSchedules.$inject = ['$filter'];
    function MassSchedules ($filter) {

        this.createSchedule = function (customerSectors, shiftType, data, item, index, restartIndex) {
            var startDate;
            var finishedDate;

            //use new empty object for override issues
            var schedule = {};
            angular.copy(data, schedule);

            switch (shiftType) {
                case 'day':
                    if(schedule.firstShiftFrom && schedule.firstShiftTo) {
                        startDate = schedule.start.setTime(schedule.firstShiftFrom.getTime() + schedule.durationTime*60*1000*index);
                        finishedDate = new Date(startDate).getTime() + schedule.durationTime*60*1000;
                        if (finishedDate > schedule.start.setTime(schedule.firstShiftTo.getTime())) {
                            return 'validationError';
                        }
                    }
                    break;
                case 'night':
                    if(schedule.secondShiftFrom && schedule.secondShiftTo) {
                        startDate = schedule.start.setTime(schedule.secondShiftFrom.getTime() + schedule.durationTime*60*1000*index);
                        finishedDate = new Date(startDate).getTime()+ schedule.durationTime*60*1000;
                        if (finishedDate > schedule.start.setTime(schedule.secondShiftTo.getTime())) {
                            return 'validationError';
                        }
                    }
                    break;
                case 'dayNight':
                    if(schedule.firstShiftFrom && schedule.firstShiftTo && schedule.secondShiftFrom && schedule.secondShiftTo) {
                        startDate = schedule.start.setTime(schedule.firstShiftFrom.getTime() + schedule.durationTime*60*1000*index);
                        finishedDate = new Date(startDate).getTime()+ schedule.durationTime*60*1000;
                        if (finishedDate > schedule.start.setTime(schedule.firstShiftTo.getTime())) {
                            schedule.secondShift = true;
                            startDate = schedule.start.setTime(schedule.secondShiftFrom.getTime() + schedule.durationTime*60*1000*restartIndex);
                            finishedDate = new Date(startDate).getTime()+ schedule.durationTime*60*1000;
                            if (finishedDate > schedule.start.setTime(schedule.secondShiftTo.getTime())) {
                                return 'validationError';
                            }
                        }
                    }
                    break;
            }

            schedule.eventSchedule.startedAt = new Date(startDate);
            schedule.eventSchedule.finishedAt = new Date(finishedDate);

            if (!item.eventCustomerSector) {
                schedule.eventCustomerSector.customerSector.id = item.id;
                angular.extend(schedule.eventCustomerSector.customerSector, {
                    customer: item.customer,
                    sector: item.sector
                });
            } else {
                angular.extend(schedule.eventCustomerSector, item.eventCustomerSector);
            }

            schedule.id = null;
            schedule.eventCategoryType = {
                id: 1
            };

            return schedule;
        };

    }
})();
