/**
 * Created by Kristy on 24/5/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('employeeSectors', {
                parent: 'adminEmployeeView',
                url: '/sectors',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR', 'ROLE_ACCOUNTING_DEP', 'ROLE_REC_ADMINISTRATOR', 'ROLE_PHARM_SUPERVISOR'],
                    pageTitle: 'sigmaCrmApp.customer.home.title'
                },
                templateUrl: 'app/displays/adminEmployees/sectors/sectors.html',
                controller: 'AdminEmployeeViewSectorsController',
                controllerAs: 'vm',
                params: {
                    search: null
                },
                resolve: {
                    customersView: ['ApplicationParameters', function (ApplicationParameters) {
                        return ApplicationParameters.customersView();
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
