(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SectorDialogController', SectorDialogController);

    SectorDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Sector', 'SectorType', 'DoctorSpecialty', 'CustomerSector', 'Country', 'Region', 'Prefecture', 'City', 'CompanyType', 'TaxOffice', 'TaxStatus', 'ClinicName', 'SectorCategory'];

    function SectorDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Sector, SectorType, DoctorSpecialty, CustomerSector, Country, Region, Prefecture, City, CompanyType, TaxOffice, TaxStatus, ClinicName, SectorCategory) {
        var vm = this;
        vm.sector = entity;
        vm.sectortypes = SectorType.query();
        vm.doctorspecialties = DoctorSpecialty.query();
        vm.sectors = Sector.query();
        vm.customersectors = CustomerSector.query();
        vm.countries = Country.query();
        vm.regions = Region.query();
        vm.prefectures = Prefecture.query();
        vm.cities = City.query();
        vm.companytypes = CompanyType.query();
        vm.taxoffices = TaxOffice.query();
        vm.taxstatuses = TaxStatus.query();
        vm.clinicnames = ClinicName.query();
        vm.sectorcategories = SectorCategory.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:sectorUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.sector.id !== null) {
                Sector.update(vm.sector, onSaveSuccess, onSaveError);
            } else {
                Sector.save(vm.sector, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
