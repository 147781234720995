/**
 * Created by Kristy on 3/7/2017.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('RecDoctorViewHospitalizationController', RecDoctorViewHospitalizationController);

    RecDoctorViewHospitalizationController.$inject = [ '$stateParams', '$http', '$filter', 'KendoFilter' ];

    function RecDoctorViewHospitalizationController ( $stateParams, $http, $filter, KendoFilter ) {
        var vm = this;
        var grid = {};
        var recDoctorId = $stateParams.id;

        grid.datasource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }

                    angular.forEach( o.data.filter.filters, function(item) {
                        if ( item.value instanceof Date) {
                            item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                        }
                    });

                    o.data.filter.filters =
                        o.data.filter.filters.concat([ { "field": "customer.id", "operator": "eq", "value": recDoctorId } ]);

                    $http.get('/api/hospitalizations/search',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        });
                }
            },
            schema : {
                data: function (data) {
                    if(data.content !== undefined) {
                        data.content.forEach(function(item){
                            if (item.sector === null) item.sector = {};
                            if (item.sector.sectorType === null) item.sector.sectorType = {};
                            if (item.clinicName === null) item.clinicName = {};
                            if (item.roomType === null) item.roomType = {};
                            if (item.finalRoomType === null) item.finalRoomType = {};
                            if (item.hospitalizationType === null) item.hospitalizationType = {};
                            if (item.customerTitle === null) item.customerTitle = {};
                            if (item.patient === null) item.patient = {};
                            if (item.customer === null) item.customer = {};
                        });
                    }
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        dateIssue: { type: "date" }
                    }
                }
            },
            pageSize: 100,
            serverPaging: true,
            serverSorting: true,
            scrollable:false,
            serverFiltering :true
        });
        grid.columns = vm.gridColumns = [
            {
                field: "customer.lastName",
                title: "Επώνυμο Επαγγελματία Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.firstName",
                title: "Όνομα Επαγγελματία Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "sector.name",
                title: "Όνομα Μονάδας Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "sector.sectorType.name",
                title: "Τύπος Μονάδας Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customerTitle.name",
                title: "Τύπος Επαγγελματία Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "clinicName.name",
                title: "Κλινική",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "roomType.name",
                title: "Επιθυμητός Τύπος Δωματίου",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "finalRoomType.name",
                title: "Τελική Τοποθέτηση",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "hospitalizationType.name",
                title: "Παρατηρήσεις",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "notes",
                title: "Σχόλια",
                editor: function(container) {
                    angular.element('<textarea rows="3" cols="150" class = "k-textbox custom-popup-textarea pull-right" data-bind="value:notes"></textarea>')
                        .appendTo(container);
                },
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "dateIssue", title: "Ημερομηνία Εισαγωγής", template: "#= dateIssue ? kendo.toString(kendo.parseDate(dateIssue), 'dddd, dd/MM/yyyy') : ''#",
                filterable: {
                    cell: {
                        operator: "eqdateonly",
                        showOperators: false
                    }
                }
            }
        ];

        vm.hospitalizationsGridOptions = {
            dataSource: grid.datasource,
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: true,
            columns:  grid.columns,
            editable: false,
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.hospitalizationsGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }]
        };
    }

})();
