/**
 * Created by Kristy on 2/6/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PatientsController', PatientsController);

    PatientsController.$inject = ['$http', '$state', '$timeout', 'Patient', 'KendoGrid','KendoFilter', 'CalculateAge', 'CustomUtils'];

    function PatientsController ($http, $state, $timeout, Patient, KendoGrid, KendoFilter, CalculateAge, CustomUtils) {
        var vm = this;
        vm.pageHeading = "Ασθενεις";
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(65);

        var grid = {};
        grid.datasource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }

                    $http.get('/api/patients/search',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        });
                },
                create: function (o){
                    var data = o.data.models[0];
                    data.yearOfBirth = kendo.toString( data.yearOfBirth, 'yyyy');
                    Patient.update({}, data, function(){ KendoGrid.refresh(vm.patientsGridOptions) });
                },
                update: function (o){
                    var data = o.data.models[0];
                    data.yearOfBirth = kendo.toString( data.yearOfBirth, 'yyyy' );
                    Patient.update({}, data, function(){ KendoGrid.refresh(vm.patientsGridOptions) });
                }
            },
            schema : {
                data: function (data) {
                    if(data.content !== undefined) {
                        angular.forEach( data.content, function (item) {
                            if (item.yearOfBirth) {
                                var date = new Date();
                                date.setFullYear(item.yearOfBirth);
                                item.yearOfBirth = date;
                                item.age = CalculateAge.getAgeFromBirthYear(item.yearOfBirth);
                            }
                        })
                    }
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        lastName: { validation: { required: { message: "Το επώνυμο του Ασθενή είναι υποχρεωτικό" } } },
                        firstName: { validation: { required: { message: "Το όνομα του Ασθενή είναι υποχρεωτικό" } } },
                        age: { type: "number" }
                    }
                }
            },
            pageSize: 100,
            serverPaging: true,
            serverSorting: true,
            scrollable:false,
            serverFiltering :true
        });

        grid.columns = vm.gridColumns = [
            {
                command: [
                    {name: "edit", text: ""}
                ],
                title: "&nbsp;",
                width: 80
            },
            {
                field: "lastName",
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "amka",
                title: "Α.Μ.Κ.Α",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "phone",
                title: "Τηλ. Επικοινωνίας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "email",
                title: "Email Επικοινωνίας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "yearOfBirth", title: "Έτος Γέννησης",
                format: "{0:yyyy}",
                editor: function(container, options) {
                    angular.element('<input id="yearOfBirth"  data-bind="value:yearOfBirth" class = "custom-popup-datepicker pull-right" title="yearOfBirth"/>')
                        .appendTo(container)
                        .kendoDatePicker({
                            start: "decade",
                            depth: "decade",
                            format: "yyyy",
                            change: function() {
                                options.model.set("age", this.value() ? CalculateAge.getAgeFromBirthYear(this.value()) : null);
                            }
                        });
                },
                filterable: {
                    cell: {
                        operator: "startswithnum",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "age", title: "Ηλικία",
                editor: function(container, options) {
                    angular.element('<input id="age" min="0"  data-bind="value:age" class = "custom-popup-numeric pull-right">')
                        .appendTo(container)
                        .kendoNumericTextBox({
                            change: function() {
                                options.model.set("yearOfBirth", CalculateAge.getBirthYearFromAge(this.value()));
                            },
                            spin: function() {
                                options.model.set("yearOfBirth", CalculateAge.getBirthYearFromAge(this.value()));
                            },
                            format: "n0"
                        });
                },
                filterable: false,
                sortable: false
            },
            {
                field: "notes",
                title: "Σχόλια",
                editor: function(container) {
                    angular.element('<textarea rows="3" cols="150" class = "k-textbox custom-popup-textarea pull-right" data-bind="value:notes"></textarea>')
                        .appendTo(container);
                },
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];

        vm.patientsGridOptions = {
            dataSource: grid.datasource,
            sortable: true,
            pageable: {
                messages: {
                    display: "{0} - {1} από {2} Ασθενείς", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                    empty: "Δεν βρέθηκαν Ασθενείς",
                    page: "Σελίδα",
                    allPages: "Όλα",
                    of: "από {0}", // {0} is total amount of pages.
                    itemsPerPage: "εγγραφές ανά σελίδα",
                    first: "Πήγαινε στη πρώτη σελίδα",
                    previous: "Πήγαινε στη τελευταία σελίδα",
                    next: "Πήγαινε στην επόμενη σελίδα",
                    last: "Πήγαινε στη τελευταία σελίδα",
                    refresh: "Ανανέωση"
                }
            },
            noRecords: {
                template: "Δεν βρέθηκαν Ασθενείς"
            },
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: true,
            columns:  grid.columns,
            editable: {
                mode: "popup"
            },
            height: vm.contentHeight,
            edit: function() { KendoGrid.defaultEditPopup(this._editContainer); }
        };

        vm.onSelection = function(data) {
            $state.go('patientInfo', {id: data.id});
        };

        vm.onAddPatientClick = function () {
            const grid = $("#patientsGrid").data("kendoGrid");
            grid.addRow();
        };
    }
})();
