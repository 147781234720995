(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MedReportLineDeleteController',MedReportLineDeleteController);

    MedReportLineDeleteController.$inject = ['$uibModalInstance', 'entity', 'MedReportLine'];

    function MedReportLineDeleteController($uibModalInstance, entity, MedReportLine) {
        var vm = this;
        vm.medReportLine = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            MedReportLine.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
