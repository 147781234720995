/**
 * Created by Kristy on 4/10/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminCustomersPendingHistoryPatchReportsController', AdminCustomersPendingHistoryPatchReportsController);

    AdminCustomersPendingHistoryPatchReportsController.$inject = ['$http','$state', '$q', '$stateParams','$filter', '$timeout', 'PatchItemMasterFilter', 'DetailGridsService', 'KendoFilter'];

    function AdminCustomersPendingHistoryPatchReportsController ($http, $state, $q, $stateParams, $filter, $timeout, PatchItemMasterFilter, DetailGridsService, KendoFilter) {
        var vm = this;
        vm.selectedUserId = $stateParams.id;
        vm.detailGridsService = DetailGridsService;
        vm.openPhotoWindow = function (e) {
            e.preventDefault();
            // e.target is the DOM element representing the button
            var tr = $(e.target).closest("tr"); // get the current table row (tr)
            // get the data bound to the current table row
            vm.photo = this.dataItem(tr);

            vm.photoWindow.title(vm.photo.patchPhotoName);
            vm.photoWindow.center().open();
            vm.canceler = $q.defer();
            $http.get('api/patch-photos/' + vm.photo.patchPhotoName, {timeout: vm.canceler.promise})
                .success(function (response,status,headers) {
                    vm.patchPhoto = response;
                })
                .error(function (response) {
                    console.log(response);
                });
        };

        vm.closePhotoWindow = function () {
            vm.canceler.resolve();
            delete vm.patchPhoto;
        };

        vm.savePhoto = function () {
            var dataURI = "data:image/png;base64," + vm.patchPhoto;
            kendo.saveAs({
                dataURI: dataURI,
                fileName: vm.photo.patchPhotoName
            });
        };

        vm.pendingHistoryGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "patchStatus", "operator": "eq",
                                    "value": 'executed' },
                                { "field": "customerSector.customer.id", "operator": "eq",
                                    "value": vm.selectedUserId }
                            ]
                        );

                        if(PatchItemMasterFilter.getSelectedPatchItems().length) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "patchReportItems.patchItem.id", "operator": "in",
                                        "value": PatchItemMasterFilter.getSelectedPatchItems().toString() }
                                ]
                            );
                        }

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                            }
                        });

                        $http.get('/api/me/patch-reports',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema: {
                    data: function (data) {
                        if(data.content != undefined) {
                            data.content.forEach(function(item){
                                if (item.materialCategory === null)
                                    item.materialCategory = {};
                            });
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            issueDate: { type: 'date'}
                        }
                    }
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                pageSize: 50
            }),
            sortable: true,
            pageable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {field: "issueDate", title: "Ημερ/νια Γνωμάτευσης",
                    template: "#= issueDate ? kendo.toString(kendo.parseDate(issueDate), 'dddd, dd/MM/yyyy') : ''#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    }
                },
                {field: "customerSector.customer.lastName", title: "Επώνυμο Επαγγελματία Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "customerSector.customer.firstName", title: "Όνομα Επαγγελματία Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "customerSector.sector.name", title: "Όνομα Μονάδας Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "customerSector.sector.address", title: "Διεύθυνση",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "customerSector.sector.cityName", title: "Πόλη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "patchCustomer.lastName", title: "Επώνυμο Ασθενή",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "patchCustomer.firstName", title: "Όνομα Ασθενή",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "patchCustomer.amka", title: "ΑΜΚΑ Ασθενή",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "barcode", title: "Barcode",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "materialCategory.name", title: "Κατηγορία Υλικού",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "employee.account.firstName", title: "Όνομα Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "submitPoint", title: "Σημείο Υποβολής",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                }
            ],
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.pendingHistoryGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }]
        };

        vm.patchReportItemsGridOptions = function (dataItem) {
            if (dataItem != undefined) {
                return {
                    dataSource: {
                        batch: true,
                        transport: {
                            read: function(o) {
                                var url = '/api/me/patch-report-items';
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }
                                o.data.filter.filters.push({ "field": "patchReport.id", "operator": "eq", "value": dataItem.id});
                                $http.get(url, {params: o.data })
                                    .success(function (response,status,headers) {
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        },
                        schema: {
                            data: function (data) {
                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: {editable: false, nullable: true},
                                    monthlyQuantity: {type: 'number'}
                                }
                            }
                        },
                        serverPaging: true,
                        pageSize: 15
                    },
                    pageable: true,
                    columns: [
                        {field: "patchItem.name", title: "Περιγραφή Υλικού"},
                        {field: "monthlyQuantity", title: "Μηνιαία Ποσότητα σε τεμάχια"},
                        {field: "icd10.code", title: "Κωδικός Διάγνωσης"},
                        {field: "icd10.description", title: "Περιγραφή Διάγνωσης"}
                    ]
                };
            }
        };

        vm.patchReportPhotosGridOptions = function (dataItem) {
            if (dataItem != undefined) {
                return {
                    dataSource: {
                        batch: true,
                        transport: {
                            read: function(o) {
                                var url = '/api/me/patch-report-photos';
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }
                                o.data.filter.filters.push({ "field": "patchReport.id", "operator": "eq", "value": dataItem.id});
                                $http.get(url, {params: o.data })
                                    .success(function (response,status,headers) {
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        },
                        schema: {
                            data: function (data) {
                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: {editable: false, nullable: true},
                                    monthlyQuantity: {type: 'number'}
                                }
                            }
                        },
                        serverPaging: true,
                        pageSize: 15
                    },
                    pageable: true,
                    columns: [
                        {field: "patchPhotoName", title: "Όνομα αρχείου"},
                        {field: "patchPhotoType", title: "Τύπος φωτογραφίας"},
                        {
                            command:[
                                { name:"show", text: "Προβολή", click: vm.openPhotoWindow }
                            ],
                            title: "&nbsp",
                            width:90
                        }
                    ]
                };
            }
        };
    }
})();
