(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CustomerSectorDialogController', CustomerSectorDialogController);

    CustomerSectorDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CustomerSector', 'Customer', 'Sector', 'WorkingDay', 'SectorPosition', 'EventCustomerSector', 'EmployeeCustomerSector'];

    function CustomerSectorDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CustomerSector, Customer, Sector, WorkingDay, SectorPosition, EventCustomerSector, EmployeeCustomerSector) {
        var vm = this;

        vm.customerSector = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.customers = Customer.query();
        vm.sectors = Sector.query();
        vm.workingdays = WorkingDay.query();
        vm.sectorpositions = SectorPosition.query();
        vm.eventcustomersectors = EventCustomerSector.query();
        vm.employeecustomersectors = EmployeeCustomerSector.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.customerSector.id !== null) {
                CustomerSector.update(vm.customerSector, onSaveSuccess, onSaveError);
            } else {
                CustomerSector.save(vm.customerSector, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('sigmasfApp:customerSectorUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startedAt = false;
        vm.datePickerOpenStatus.endedAt = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
