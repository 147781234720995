/**
 * Created by Kristy on 24/5/2017.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminEmployeeViewEmployeeAgreementsController', AdminEmployeeViewEmployeeAgreementsController);

    AdminEmployeeViewEmployeeAgreementsController.$inject = ['$scope', '$http', '$filter', 'EmployeeViewService', 'KendoFilter', 'CustomUtils'];

    function AdminEmployeeViewEmployeeAgreementsController ($scope, $http, $filter, EmployeeViewService, KendoFilter, CustomUtils) {
        var vm = this;
        vm.selectedUser = EmployeeViewService.getSelectedUser();
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(55);

        vm.clearDatePickers = function () {
            angular.element('#masterFiltersAgreements').find('#from').data('kendoDatePicker').value("");
            angular.element('#masterFiltersAgreements').find('#to').data('kendoDatePicker').value("");
        };

        vm.masterFiltersOnClick = function (e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            if (e.id == "today") {

                vm.clearDatePickers();

                vm.from = $filter('date')(date, 'yyyy-MM-dd');
                vm.to = $filter('date')(date, 'yyyy-MM-dd');

            }
            else if (e.id == "week") {

                vm.clearDatePickers();

                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6

                vm.from = $filter('date')(firstWeekDay, 'yyyy-MM-dd');
                vm.to = $filter('date')(lastWeekDay, 'yyyy-MM-dd');

            }
            else if (e.id == "month") {

                vm.clearDatePickers();

                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                vm.from = $filter('date')(firstMonthDay, 'yyyy-MM-dd');
                vm.to = $filter('date')(lastMonthDay, 'yyyy-MM-dd');
            }

            if ( e.target != undefined) {
                angular.element("#agreementsGrid").data("kendoGrid").dataSource.read();
            } else {
                angular.element("#agreementsGrid").data("kendoGrid").dataSource.read();
            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ], attributes: { class: "buttonGroup" }
                },
                { type: "separator" },
                { type: "separator" },
                { template: "<label>Από: </label>" },
                {
                    template: "<input kendo-date-picker id='from' options='vm.fromOptions' class='from'/>",
                    overflow: "never"
                },
                { template: "<label>Εώς: </label>" },
                {
                    template: "<input kendo-date-picker id='to' options='vm.toOptions' class='to'/>",
                    overflow: "never"
                }
            ]
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('.buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                vm.from = $filter('date')(value, 'yyyy-MM-dd');
                vm.masterFiltersOnClick(e);
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('.buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                vm.to = $filter('date')(value, 'yyyy-MM-dd');
                vm.masterFiltersOnClick(e);
            }
        };

        vm.agreementsGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "event.employee.id", "operator": "eq",  "value": vm.selectedUser.id}
                            ]
                        );

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        //master filters functionality
                        if(vm.from && vm.to) {
                            o.data.filter.filters = o.data.filter.filters.concat(
                                [
                                    { "field": "event.startedAt", "operator": "from",  "value": $filter('date')(vm.from, 'yyyy-MM-ddT00:00:00.000') + 'Z' },
                                    { "field": "event.startedAt", "operator": "to",  "value": $filter('date')(vm.to, 'yyyy-MM-ddT23:59:59.000') + 'Z' }
                                ]
                            );
                        }

                        $http.get('/api/me/agreements',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (data, operation) {
                        return data;
                    }
                },
                serverSorting: true,
                serverFiltering: true,
                serverPaging: true,
                pageSize: 50,
                group: {field:  'event_date'},
                schema: {
                    data: function (data) {
                        if(data != undefined) {
                            data.content.forEach(function(item){

                                if (item.event === null) {
                                    item.event = {
                                        startedAt: '',
                                        eventCustomerSector: {
                                            customerSector: {
                                                customer: {
                                                    last_name: "",
                                                    first_name: ""
                                                }
                                            }
                                        }
                                    };
                                } else {
                                    if (item.event.eventCustomerSector === null) {
                                        item.event.eventCustomerSector = {
                                            customerSector: {
                                                customer: {
                                                    last_name: "",
                                                    first_name: ""
                                                }
                                            }
                                        };
                                    } else {
                                        if (item.event.eventCustomerSector.customerSector === null) {
                                            item.event.eventCustomerSector.customerSector = {
                                                customer: {
                                                    last_name: "",
                                                    first_name: ""
                                                }
                                            };
                                        }
                                    }
                                }

                                if (item.product === null) {
                                    item.product = { name: ''};
                                }

                                if (item.agreementValuation === null) {
                                    item.agreementValuation = { name: ''};
                                }

                                if (item.eventPlace === null) {
                                    item.eventPlace = {};
                                    item.eventPlace.name = "";
                                }
                            });
                        }
                        return data.content;
                    },
                    parse: function(data) {
                        angular.forEach(data.content, function(item) {
                            if (item.event != undefined) {
                                item.event_date = kendo.toString(kendo.parseDate(item.event.startedAt), 'dd/MM/yyyy');
                            }

                        });
                        return data;
                    },
                    total : function(data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: false },
                            promisedQuantity: { type:'number' },
                            priority: { type:'number' },
                            //agreed_price: { type:'number' },
                            event_date: { type: 'date'},
                            'event.startedAt': { type: 'date' }
                        }
                    }
                }
            }),
            height: vm.contentHeight,
            pageable: true,
            sortable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            columns: [
                {field: "event_date", hidden: true,
                    groupHeaderTemplate: "Ημερομηνία Επίσκεψης: #= kendo.toString(kendo.parseDate(value), 'dddd, dd/MM/yyyy')#"
                },
                {field: "event.startedAt", title: "Ημερομηνία Επίσκεψης", template: "#= kendo.toString(kendo.parseDate(event.startedAt), 'dddd, dd/MM/yyyy HH:mm')#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    }},
                {field: "product.name", title: "Προϊόν",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "promisedQuantity", title: "Προβλεπόμενη Συχνότητα",
                    filterable: {
                        cell: {
                            operator: "eq",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "priority", title: "Σειρά προτεραιότητας",
                    filterable: {
                        cell: {
                            operator: "eq",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "agreementValuation.name", title: "Αξιολόγηση",attributes: {
                    class: "#=agreementValuation.id == '1' ? 'red' : agreementValuation.id == '2' ? 'orange' : agreementValuation.id == '3' ? 'purple' : agreementValuation.id == '4' ? 'green' : 'black' #"
                },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "event.eventCustomerSector.customerSector.customer.firstName", title: "Όνομα Επαγγελματία Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }},
                {field: "event.eventCustomerSector.customerSector.customer.lastName", title: "Επώνυμο Επαγγελματία Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }}
            ],
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.agreementsGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }]
        };
    }

})();
