/**
 * Created by Kristy on 3/11/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('mdDialogCtrl', mdDialogCtrl);

    mdDialogCtrl.$inject = ['confirmSaveCustomer', 'existedCustomers', 'closeDialog', '$http'];

    function mdDialogCtrl (confirmSaveCustomer, existedCustomers, closeDialog, $http) {
        var vm = this;
        vm.clicked = [];
        vm.existedCustomers = existedCustomers;
        vm.closeDialog = closeDialog;
        vm.confirmSave = confirmSaveCustomer;
        vm.getSectors =  function(idx, item) {
            if (!item.sectors) {
                $http.get('/api/customers/' + item.id + '/me/customer-sectors')
                    .success(function (response) {
                        vm.existedCustomers[idx].sectors = response;
                    });
            }
            vm.clicked[idx] = !vm.clicked[idx];
        }
    }
})();
