/**
 * Created by Kristy Kavvada on 07/2/2020.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminCustomerViewMedReportsWithLinesController', AdminCustomerViewMedReportsWithLinesController);

    AdminCustomerViewMedReportsWithLinesController.$inject = ['$http','$state', '$stateParams', '$filter', 'Principal', 'KendoFilter', 'KendoChangeEvent', 'medReportMedicine', 'MedReportService'];

    function AdminCustomerViewMedReportsWithLinesController ($http, $state, $stateParams, $filter, Principal, KendoFilter, KendoChangeEvent, medReportMedicine, MedReportService) {
        var vm = this;
        vm.medReportMedicine = medReportMedicine.data.content[0];
        vm.selectedUserId = $stateParams.id;
        vm.windowOptions = {
            appendTo: '#custom-med-report-window'
        };
        vm.medReportService = MedReportService;
        vm.medReportsGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "isActive", "operator": "eqbool", "value": true }
                            ]
                        );

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "status", "operator": "eq", "value": "approved" }
                            ]
                        );

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "customer.id", "operator": "eq",  "value": vm.selectedUserId}
                            ]
                        );

                        $http.get('/api/me/med-reports',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (data) {
                        return data;
                    }
                },
                schema: {
                    data: function (data) {
                        if(data != undefined) {
                            data.content.forEach(function(item){
                            });
                        }
                        return data.content;
                    },
                    total : function(data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            fromDate: { type: 'date'},
                            toDate: { type: 'date'},
                            checkDigit1: { type: 'number'},
                            checkDigit2: { type: 'number'}
                        }
                    }
                },
                serverSorting: true,
                serverFiltering: true,
                serverPaging: true,
                pageSize: 50
            }),
            pageable: true,
            sortable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: "cell",
            columns: [
                {command:[{
                    name:"customEdit",
                    template: "<a role='button' class='k-button smaller-k-edit-btn' ui-sref='medReportPopup' ng-click='vm.openWindow($event, dataItem)'>" +
                        "<span class='k-icon k-i-edit'></span></a>", text: ""
                    }],
                    attributes: {
                        "class": "smaller-k-edit-btn"
                    }, title: "&nbsp;", width:57
                },
                {
                    field: "fromDate",
                    title: "Μήνας",
                    template: "#= fromDate == null ? '' : kendo.toString(kendo.parseDate(fromDate), 'MMMM')#",
                    filterable: false
                },
                {
                    field: "toDate",
                    title: "Έτος",
                    template: "#= toDate == null ? '' : kendo.toString(kendo.parseDate(toDate), 'yyyy')#",
                    filterable: false
                },
                {
                    field: "checkDigit1", title: "Check Digit",
                    filterable: false,
                    width: 140
                },
                {
                    field: "checkDigit2", title: "Check Digit 2",
                    filterable: false,
                    width: 140
                },
                {
                    field: "code", title: "Κωδικός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",
                    attributes: {
                        class: "link-cell link-cell-color"
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }, width: 200
                },
                {field: "employee.account.firstName", title: "Όνομα Ιατρικού Επισκέπτη",
                    attributes: {
                        class: "link-cell link-cell-color"
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }, width: 200
                }
            ],
            toolbar: [{ name: "New Med Report",
                text: "New Med Report", template: "<input type='button' class='k-button' value = 'Προσθήκη Report' ui-sref='medReportPopup' ng-click='vm.openWindow($event)' />" },
                { template: "<div class='right'><label>Σύνολο: {{vm.medReportsGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }],
            editable: {
                mode: "popup"
            },
            change: function () { KendoChangeEvent.onChange(this, [5, 6], "employee"); }
        };

        vm.medReportLinesGridOptions = function (dataItem) {
            if (dataItem != undefined) {
                return {
                    dataSource: {
                        batch: true,
                        transport: {
                            read: function(o) {
                                vm.showProgress = true;
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.filter.filters.push({field: "medReport.id", operator: "eq", value: dataItem.id});
                                $http.get('/api/med-report-lines/search',
                                    {params: o.data})
                                    .success(function (response) {
                                        o.success(response);
                                        vm.showProgress = false;
                                    })
                                    .error(function (response) {
                                        vm.showProgress = false;
                                    });
                            }
                        },
                        schema: {
                            data: function (data) {
                                if(data != undefined) {
                                    data.content.forEach(function(item){
                                    });
                                }
                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: { editable: false, nullable: true },
                                    sum: { type: "number" },
                                    marketSharePercentage: { type: "number" },
                                    numberOfChanges: { type: "number" }
                                }
                            }
                        },
                        serverFiltering: true,
                        serverSorting: true,
                        serverPaging: true,
                        pageSize: 15
                    },
                    pageable: true,
                    sortable: true,
                    filterable: {
                        mode: "row"
                    },
                    columns: [
                        {field: "medicine.name", title: "Όνομα Σκευάσματος",
                            hidden: vm.medReportMedicine.value !== 'name',
                            filterable: {
                                cell: {
                                    operator: "like",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "medicine.pairCode", title: "Σκεύασμα",
                            hidden: vm.medReportMedicine.value !== 'pairCode',
                            filterable: {
                                cell: {
                                    operator: "like",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "medicine.barcode", title: "Barcode Σκευάσματος",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {
                            field: "sum", title: "Σύνολο",
                            filterable: {
                                operators: {
                                    number: {
                                        eq: 'Ίσο',
                                        gte: 'Μεγαλύτερο ή Ίσο',
                                        lte: 'Μικρότερο ή Ίσο'
                                    }
                                },
                                cell: {
                                    template: function (args) {
                                        args.element.kendoNumericTextBox({
                                            format: "n0",
                                            decimals: 0,
                                            min: 0
                                        });

                                    }
                                }
                            }
                        }
                    ],
                    editable: false
                };
            }
        };

        vm.openWindow = function(e, dataItem){
            console.log(dataItem);
            MedReportService.setMedReport(dataItem);
            var wdw = angular.element("#popup-new-medReport").data("kendoWindow");
            wdw.center().open();
        }
    }

})();
