/**
 * Created by Teo on Ιούλ, 2018
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('CustomUtils', CustomUtils);

    CustomUtils.$inject = [];

    function CustomUtils () {

        return {
            getHeightPixelsFromPercentage: heightPixelsFromPercentage,
            getWidthPixelsFromPercentage: widthPixelsFromPercentage,
            getRandomColor: getRandomColor,
            getYears: getYears,
            getMonths: getMonths
        };

        /**
         * Get height pixels from given percentage.
         * This helps to give direct height on a div
         * and be responsive in all screens.
         * @param percentage How much percentage of screen
         *        height you want to cover
         * @returns {number} Result in pixels
         */
        function heightPixelsFromPercentage(percentage){
            var screenHeight = window.screen.height;
            return (screenHeight * percentage) / 100;
        }

        /**
         * Get width pixels from given percentage.
         * This helps to give direct width on a div
         * and be responsive in all screens.
         * @param percentage How much percentage of screen
         *        width you want to cover
         * @returns {number} Result in pixels
         */
        function widthPixelsFromPercentage(percentage){
            var screenWidth = window.screen.width;
            return (screenWidth * percentage) / 100;
        }

        /**
         * Get a random generated color in hex
         * @returns {string} Color in #hex format
         */
        function getRandomColor(){
            return '#' + (function co(lor) {
                return (lor +=
                    [0,1,2,3,4,5,6,7,8,9,'a','b','c','d','e','f'][Math.floor(Math.random()*16)])
                && (lor.length === 6) ?  lor : co(lor);
            })('')
        }

        /**
         * Get an array with years.
         * @param startYear The year to start from.
         * @param reversed Boolean indicating if results should be sorted in reverse.
         * @returns {any} An array with years.
         */
        function getYears(startYear, reversed){
            var currentYear = new Date().getFullYear(), years = [];
            startYear = startYear || 2015;

            while ( startYear <= currentYear ) {
                years.push({value: startYear++});
            }

            return reversed === true ? years.reverse() : years;
        }

        /**
         * Get an array with months. Each object has this struct:
         * {
         *  id: 1,
         *  name: 'Ιανουάριος'
         * }
         * @param defaultItem Boolean indicating if array has a default 1st item
         * @returns {Array} An array with months.
         */
        function getMonths(defaultItem) {
            var months = [];
            moment.locale('el');
            if (defaultItem === true){
                months.push({
                    id: 0,
                    value: "Επιλογή Μήνα"
                });
            }
            angular.forEach(moment.months(), function (value, key) {
                months.push({
                    id: key + 1,
                    value: value
                });
            });
            return months;
        }
    }
})();
