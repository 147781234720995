/**
 * Created by Teo on 10/09/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadCallsAggregatedPerDiseaseController', LeadCallsAggregatedPerDiseaseController);

    LeadCallsAggregatedPerDiseaseController.$inject = ['$http', 'CustomUtils', 'Alerts'];

    function LeadCallsAggregatedPerDiseaseController ($http, CustomUtils, Alerts) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(47);
        vm.pageHeading = "Ενημερωσεις ανα Παθηση";
        vm.data1 = []; vm.data2 = []; vm.data3 = [];
        var colors1 = [];
        var dataSource = {};
        var allMonths = CustomUtils.getMonths(false);
        vm.sumLabel = ""; vm.sum1Label = "";
        vm.selectedLabelMonths = []; // Holds month labels for chips

        dataSource.yearsData = CustomUtils.getYears(2017, true);
        vm.year = dataSource.yearsData[0];
        vm.yearsOptions = {
            dataSource: {
                data: dataSource.yearsData
            },
            dataTextField: "value",
            dataValueField: "value",
            change: function() {
                $('#chart1').data('kendoChart').dataSource.read();
                monthsIndexToLabels();
            }
        };

        vm.selectedMonths = [];
        vm.monthsOptions = {
            dataSource: {
                data: allMonths
            },
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "value",
            dataValueField: "id",
            placeholder: "Όλος ο χρόνος",
            change: function (e) {
                $('#chart1').data('kendoChart').dataSource.read();
                monthsIndexToLabels();
            }
        };

        $(document).ready(createCharts);
        $(document).bind("kendo:skinChange", createCharts);
        monthsIndexToLabels();

        vm.exportPDF = function () {
            console.log("export");
            kendo.drawing.drawDOM($("#barChartsContainer"), {
                paperSize: "auto",//"A3",
                landscape: true
                // margin: { left: "1cm", top: "1cm", right: "1cm", bottom: "1cm" },
                //template: $("#page-template").html()
            })
                .then(function (group) {
                    return kendo.drawing.exportPDF(group);
                })
                .done(function (data) {
                    kendo.saveAs({
                        dataURI: data,
                        fileName: "Συγκεντρωτικά Lead Management ανά Πάθηση_" + vm.year.value + ".pdf"
                    });
                });
        };

        function createCharts() {
            $("#chart1").kendoChart({
                title: {
                    position: "top",
                    text: "Ενημερώσεις ανά Πάθηση"
                },
                dataSource: {
                    transport: {
                        read: function (o) {
                            vm.sum1Label = "";
                            $http.get("/api/lead-calls/analytics/5/?year=" + vm.year.value + "&months=" + vm.selectedMonths)
                                .success(function (response) {
                                    var count = 0;
                                    if (response && response.length > 0){
                                        for (var i=0; i < response.length; i++){
                                            response[i].columnName = response[i].columnName + " - " + (response[i].percentage).toFixed(2) + "%";
                                            colors1.push(CustomUtils.getRandomColor());
                                            count += response[i].value;
                                        }

                                        count > 0 ? vm.sum1Label = "Σύνολο: " + count : vm.sum1Label = "";
                                        o.success(response);
                                    }else {
                                        // bind empty dummy array for updating categoryAxis
                                        vm.sum1Label = "";
                                        o.success([{
                                            columnName: "",
                                            percentage: 0,
                                            value: 0
                                        }]);
                                    }
                                })
                                .error(function (error) {
                                    console.log(response);
                                    vm.sum1Label = "";
                                    Alerts.showAlert("Sigma SalesForce", "Λυπούμαστε. Κάποιο σφάλμα προσέκυψε!", "ΟΚ");
                                });
                        }
                    }
                },
                seriesColors: colors1,
                legend: {
                    visible: false
                },
                valueAxis: {
                    max: 600,
                    line: {
                        visible: false
                    },
                    minorGridLines: {
                        visible: true
                    },
                    labels: {
                        rotation: "auto"
                    },
                    axisCrossingValue: 0
                },
                categoryAxis: {
                    majorGridLines: {
                        visible: true
                    }
                },
                seriesDefaults: {
                    type: "bar",
                    labels: {
                        visible: true,
                        background: "transparent",
                        template: "#= value ? value : ''#"
                    }
                },
                tooltip: {
                    visible: true,
                    format: "{0}"
                },
                series: [{
                    field: "value",
                    categoryField: "columnName"
                }]
            });
        }

        function monthsIndexToLabels() {
            if (vm.selectedMonths.length === 0){
                vm.selectedLabelMonths = ["Όλος ο χρόνος"];
                vm.selectedLabelMonths.push("#" + vm.year.value)
            }else {
                vm.selectedLabelMonths = ["#" + vm.year.value];
                angular.forEach(vm.selectedMonths, function (value, key) {
                    vm.selectedLabelMonths.push(allMonths[value - 1].value);
                });
            }
        }
    }
})();
