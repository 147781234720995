(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('DiscountReasonDetailController', DiscountReasonDetailController);

    DiscountReasonDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'DiscountReason'];

    function DiscountReasonDetailController($scope, $rootScope, $stateParams, entity, DiscountReason) {
        var vm = this;
        vm.discountReason = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:discountReasonUpdate', function(event, result) {
            vm.discountReason = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
