/**
 * Created by Kristy on 7/6/2017.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PatientViewInfoController', PatientViewInfoController);

    PatientViewInfoController.$inject = [ '$stateParams', '$timeout', 'Patient', 'NotificationOptions', 'KendoNotifications', 'CalculateAge' ];

    function PatientViewInfoController ( $stateParams, $timeout, Patient, NotificationOptions, KendoNotifications, CalculateAge ) {
        var vm = this;
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");
        var date = new Date();

        function calculateYearOfBirth() {
            var self = this;
            $timeout(function (){
                vm.patient.yearOfBirth = CalculateAge.getBirthYearFromAge(self.value());
            });
        }

        function calculateAge() {
            var self = this;
            $timeout(function (){
                vm.patient.age = CalculateAge.getAgeFromBirthYear(self.value());
            });
        }

        vm.ageOptions = {
            change: calculateYearOfBirth,
            spin: calculateYearOfBirth
        };

        Patient.get({id : $stateParams.id})
            .$promise.then(function(data) {
                vm.patient = data;
                date.setFullYear(vm.patient.yearOfBirth);
                vm.patient.yearOfBirth = date;
                vm.patient.age = CalculateAge.getAgeFromBirthYear(vm.patient.yearOfBirth);
            });

        vm.yearOfBirthOptions = {
            start: "decade",
            depth: "decade",
            format: "yyyy",
            change: calculateAge
        };

        vm.update = function() {
            if (vm.validator.validate()) {
                vm.patient.yearOfBirth = kendo.toString(vm.patient.yearOfBirth, 'yyyy');
                Patient.update({}, vm.patient,
                    function () {
                        KendoNotifications.onSaveSuccess(notification)
                    },
                    function () {
                        KendoNotifications.onSaveError(notification)
                    })
            } else {
                KendoNotifications.onSaveError(notification);
            }
        };
    }

})();
