(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProspectivePatientDialogController', ProspectivePatientDialogController);

    ProspectivePatientDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProspectivePatient', 'Attendant', 'Profession', 'NativeLanguage', 'Country', 'Region', 'Prefecture', 'City', 'Patient', 'CountryPhoneCode'];

    function ProspectivePatientDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ProspectivePatient, Attendant, Profession, NativeLanguage, Country, Region, Prefecture, City, Patient, CountryPhoneCode) {
        var vm = this;
        vm.prospectivePatient = entity;
        vm.attendants = Attendant.query();
        vm.professions = Profession.query();
        vm.nativelanguages = NativeLanguage.query();
        vm.countries = Country.query();
        vm.regions = Region.query();
        vm.prefectures = Prefecture.query();
        vm.cities = City.query();
        vm.patients = Patient.query();
        vm.countryphonecodes = CountryPhoneCode.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:prospectivePatientUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.prospectivePatient.id !== null) {
                ProspectivePatient.update(vm.prospectivePatient, onSaveSuccess, onSaveError);
            } else {
                ProspectivePatient.save(vm.prospectivePatient, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
