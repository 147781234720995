/**
 * Created by Kristy on 3/10/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('PatchItemMasterFilter', PatchItemMasterFilter);

    PatchItemMasterFilter.$inject = ['$http', '$timeout'];

    function PatchItemMasterFilter($http, $timeout) {
        var selectedPatchItems = [];
        var patchItemsMultiOptions = {
            dataSource: new kendo.data.DataSource({
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20,
                transport: {
                    read: function (o) {
                        var url = '/api/masterdata/patch-items';
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }
                        $http.get(url,
                            {params: o.data})
                            .success(function (response) {
                                o.success(response.content);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                }
            }),
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id",
            placeholder: "Επιλογή επιθεμάτων",
            change: function (e) {
                setSelectedPatchItems(this.value());
                angular.element("#patchReportGrid2").data("kendoGrid").dataSource.read();
            }
        };

        return {
            getSelectedPatchItems: getSelectedPatchItems,
            setSelectedPatchItems: setSelectedPatchItems,
            getPatchItemsMultiOptions: function () {
                return patchItemsMultiOptions
            }
        };

        function getSelectedPatchItems() {
            return selectedPatchItems
        }

        function setSelectedPatchItems(value) {
            selectedPatchItems = value;
        }
    }
})();
