/**
 * Created by Kristy Kavvada on 30/11/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('BeautyCenterViewEmployeesController', BeautyCenterViewEmployeesController);

    BeautyCenterViewEmployeesController.$inject = ['$http', '$stateParams', 'EmployeeCustomerSector'];

    function BeautyCenterViewEmployeesController ($http, $stateParams, EmployeeCustomerSector) {
        var vm = this;
        var grid = angular.element('#employeesGrid');
        var modal = angular.element('#popup-employee-customer-sector');
        var multiSelect = angular.element('#employees');

        vm.selectedSectorId = $stateParams.id;
        vm.selectedEmployeeCustomerSector = {};
        vm.selectedEmployeeCustomerSectors = [];
        vm.currentEmployeeCustomerSectors = [];
        vm.pageHeading = 'Ιατρικοί Επισκέπτες που επισκέπτονται το κέντρο αισθητικής';

        var urls = {};
        urls.sectorEmployees = '/api/sectors/'+vm.selectedSectorId+'/employees';

        // DataSources
        vm.dataSource = {};
        vm.dataSource.grid = {};
        vm.dataSource.grid.employee = new kendo.data.DataSource({
            autoBind: false,
            transport: {
                read: function (o) {
                    $http.get(urls.sectorEmployees,{})
                        .success(function (response) {
                            vm.currentEmployeeCustomerSectors = response;
                            if( multiSelect.data('kendoMultiSelect')) multiSelect.data('kendoMultiSelect').dataSource.read();
                            o.success(response);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            },
            batch: true,
            pageSize: 100,
            scrollable:false
        });

        vm.modalWindowOptions = {
            title: "Επιλέξτε ιατρικό επισκέπτη/τες",
            visible: false,
            modal: true,
            resizable: true,
            width: 800,
            height: 300
        };

        // Grid Columns
        vm.gridColumns = {};
        vm.gridColumns.employee = [
            {
                field: "employee.account.lastName",
                title: "Επώνυμο"
            },
            {
                field: "employee.account.firstName",
                title: "Όνομα"
            },
            {
                field: "employee.account.login",
                title: "Χρήστης"
            },
            {
                field: "employee.phone",
                title: "Τηλέφωνο"
            },
            {
                command:
                {
                    name: "delete",
                    text: "ΔΙΑΓΡΑΦΗ",
                    click: deleteButtonPush,
                    className: "k-i-cancel"
                },
                title: "&nbsp;"
            }
        ];

        vm.options = {};
        vm.options.employees = {
            dataSource: new kendo.data.DataSource({
                serverFiltering: true,
                transport: {
                    read: function (o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = {filters: []};
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "account.authorities.name", "operator": "in",
                                    "value": "ROLE_BC_VISITOR", "ignoreCase": true}
                            ]
                        );

                        o.data.page = 1;
                        o.data.pageSize = 10000;
                        $http.get('/api/me/employees',
                            {params: o.data})
                            .success(function (response, status, headers) {
                                o.success(response.content);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema: {
                    parse: function(data) {
                        angular.forEach(data, function(item) {
                            //define object for data binding
                            if(item.account != null) {
                                item.active = true;
                                item.employee = {
                                    account : {
                                        fullName: item.account.lastName + " " + item.account.firstName
                                    },
                                    id: item.id
                                }
                            }
                        });

                        var filteredData = [];

                        data.forEach(function (item) {
                            var found = false;
                            vm.currentEmployeeCustomerSectors.forEach(function (ecs) {
                                if (ecs.employee.id == item.id) {
                                    found = true;
                                }
                            })
                            if (!found) {
                                filteredData.push(item);
                            }
                        });

                         return filteredData;
                    }
                }
            }),
            valuePrimitive: false,
            dataTextField: "employee.account.fullName",
            dataValueField: "employee.id"
        };


        vm.gridOptions = {};
        vm.gridOptions.employee = {
            dataSource: vm.dataSource.grid.employee,
            resizable: true,
            columns: vm.gridColumns.employee,
            toolbar: [
                {
                    name: "New Employee Customer Sector",
                    text: "New Employee Customer Sector",
                    template: "<input type='button' class='k-button' value = 'Ανάθεση σε ιατρικό επισκέπτη/τες' ng-click='vm.openModalWindow()' />"
                }
            ]
        };

        vm.openModalWindow = function() {
            modal.data("kendoWindow").center().open();
        };

        vm.closeModalWindow = function() {
            modal.data("kendoWindow").close();
        };

        function deleteButtonPush(e) {
            e.preventDefault();
            vm.selectedEmployeeCustomerSector = this.dataItem($(e.currentTarget).closest("tr"));
            vm.selectedEmployeeCustomerSector.active = false;
            EmployeeCustomerSector.update(vm.selectedEmployeeCustomerSector, onDeleteSaveSuccess, onDeleteSaveError);
        }

        vm.add = function () {
            var postData = [];
            vm.selectedEmployeeCustomerSectors.forEach(function (item) {
                var newPost = {};
                newPost.active = true;
                newPost.sector = {};
                newPost.sector.id = vm.selectedSectorId;
                newPost.employee = {};
                newPost.employee.id = item.id;
                postData.push(newPost)
            });

            $http.post('api/employee-customer-sectors/mass', postData, {}).then(onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.selectedEmployeeCustomerSectors = [];
            grid.data('kendoGrid').dataSource.read();
            vm.closeModalWindow();
        }

        function onSaveError() {
            console.error("Error on saving vm.selectedEmployeeCustomerSectors");
        }

        function onDeleteSaveSuccess(result) {
            grid.data('kendoGrid').dataSource.read();
        }

        function onDeleteSaveError() {
            console.error("Error on saving vm.selectedEmployeeCustomerSector");
        }
    }

})();
