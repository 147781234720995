(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SectorClinicNameDetailController', SectorClinicNameDetailController);

    SectorClinicNameDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'SectorClinicName', 'Sector', 'ClinicName'];

    function SectorClinicNameDetailController($scope, $rootScope, $stateParams, entity, SectorClinicName, Sector, ClinicName) {
        var vm = this;
        vm.sectorClinicName = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:sectorClinicNameUpdate', function(event, result) {
            vm.sectorClinicName = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
