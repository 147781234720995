/**
 * Created by Kristy on 30/9/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminPatchReportsController', AdminPatchReportsController);

    AdminPatchReportsController.$inject = ['$http', '$state', 'ApplicationParameters', 'PatchItemMasterFilter'];

    function AdminPatchReportsController ($http, $state, ApplicationParameters, PatchItemMasterFilter) {
        var vm = this;
        vm.selectedPatchItems = PatchItemMasterFilter.getSelectedPatchItems();
        ApplicationParameters.hasModule('Patches', true);

        vm.patchItemsMultiOptions = PatchItemMasterFilter.getPatchItemsMultiOptions();

        vm.toolbarOptions = {
            items: [
                { template: "<label>Επίθεμα: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.patchItemsMulti' multiple='multiple' " +
                "k-options='vm.patchItemsMultiOptions' k-ng-model='vm.selectedPatchItems'></select>", overflow: "never" }
            ]
        };
    }
})();
