/**
 * Created by Teo on 24/07/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadManagementAggregatedController', LeadManagementAggregatedController);

    LeadManagementAggregatedController.$inject = ['$state'];

    function LeadManagementAggregatedController ($state) {
        var vm = this;
        vm.$state = $state;
        vm.pageHeading = "Συνολα Lead Management";
    }
})();
