(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('HospitalizationTypeDeleteController',HospitalizationTypeDeleteController);

    HospitalizationTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'HospitalizationType'];

    function HospitalizationTypeDeleteController($uibModalInstance, entity, HospitalizationType) {
        var vm = this;
        vm.hospitalizationType = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            HospitalizationType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
