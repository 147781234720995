(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CustomerCategoryDetailController', CustomerCategoryDetailController);

    CustomerCategoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'CustomerCategory'];

    function CustomerCategoryDetailController($scope, $rootScope, $stateParams, entity, CustomerCategory) {
        var vm = this;
        vm.customerCategory = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:customerCategoryUpdate', function(event, result) {
            vm.customerCategory = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
