(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CollaborationDeleteController',CollaborationDeleteController);

    CollaborationDeleteController.$inject = ['$uibModalInstance', 'entity', 'Collaboration'];

    function CollaborationDeleteController($uibModalInstance, entity, Collaboration) {
        var vm = this;
        vm.collaboration = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Collaboration.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
