/**
 * Created by Kristy on 4/10/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('ApplicationParameters', ApplicationParameters);

    ApplicationParameters.$inject = ['$http', '$state'];

    function ApplicationParameters ($http, $state) {
        var url = "/api/masterdata/application-parameters";

        return {
            hasModule: hasModule,
            customersView: customersView,
            eventCycle: eventCycle,
            getParameter: getParameter
        };

        function hasModule(module, access) {
            if (access) {
                $http.get(url,
                    {
                        params: {
                            page: 1,
                            pageSize: 1,
                            filter: {filters: [{field: "name", operator: "eq", value: module}]}
                        }
                    })
                    .success(function (response, status, headers) {
                        if (response.content[0].value != 1) {
                                $state.go('accessdenied');
                        }
                    })
                    .error(function (response) {
                        console.log(response);
                    });
            } else {
                return $http.get(url,
                    {
                        params: {
                            page: 1,
                            pageSize: 1,
                            filter: {filters: [{field: "name", operator: "eq", value: module}]}
                        }
                    })
            }
        }

        function getParameter(parameterName) {
            return $http.get(url,
                {
                    params: {
                        page: 1,
                        pageSize: 1,
                        filter: {filters: [{field: "name", operator: "eq", value: parameterName}]}
                    }
                });
        }

        function customersView() {
            return $http.get(url,
                {
                    params: {
                        page: 1,
                        pageSize: 1,
                        filter: {filters: [{field: "name", operator: "eq", value: 'Customers_View'}]}
                    }
                });
        }

        function eventCycle() {
            return $http.get(url,
                {
                    params: {
                        page: 1,
                        pageSize: 1,
                        filter: {filters: [{field: "name", operator: "eq", value: 'Event_Cycle'}]}
                    }
                });
        }
    }

})();
