(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminPatchDoctorsController', AdminPatchDoctorsController);

    AdminPatchDoctorsController.$inject = ['$http','$state','$filter', 'Principal', 'ApplicationParameters', 'KendoFilter', 'KendoGrid'];

    function AdminPatchDoctorsController ($http, $state, $filter, Principal, ApplicationParameters, KendoFilter, KendoGrid) {
        var vm = this;

        ApplicationParameters.hasModule('Patches', true);

        vm.pageHeading = 'Συστησαντες Επαγγελματίες Υγείας';
        //
        //Data for DropDown
        var cityDataSource = new kendo.data.DataSource({
            transport: {
                read: function(o) {
                    var url = '/api/v1/cities/all';
                    $http.get(url)
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {
                            console.log(response);
                        });


                }
            }
        });
        //DropDown Options
        vm.cityDropDownOptions = {
            dataSource: cityDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };

        //Data for DropDown
        vm.specialtyDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get('/api/masterdata/doctor-specialties',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        //Data for DropDown
        vm.categoryDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get('/api/masterdata/customer-categories',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        //Data for DropDown
        vm.subCategoryDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get('/api/masterdata/customer-sub-categories',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        var sectorDataSource = new kendo.data.DataSource({
            transport: {
                read: function(o) {
                    var url = '/api/sectors';
                    $http.get(url)
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {
                            console.log(response);
                        });


                }
            }
        });
        //DropDown Options
        vm.sectorDropDownOptions = {
            dataSource: sectorDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };

        var assignedUserDataSource = new kendo.data.DataSource({
            transport: {
                read: function(o) {
                    var url = '/api/employees';
                    $http.get(url)
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {
                            console.log(response);
                        });

                }
            }
        });
        //DropDown Options
        vm.assignedUserDropDownOptions = {
            dataSource: assignedUserDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };

        vm.dayTimeDataSource = new kendo.data.DataSource({
            data: [
                { value: 'day', text: "ΠΡΩΙΝΌ"},
                { value: 'night',  text: "ΑΠΟΓΕΥΜΑΤΙΝΌ"}
            ]
        });

        vm.titleDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get('/api/masterdata/customer-titles',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        vm.customerUrl = "/api/customers";

        vm.gridColumns = [
            {
                field: "lastName",
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {
                field: "firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {
                field: "doctorSpecialty.name",
                title: "Ειδικοτητα 1η",
                editor: function(container, options) {
                    angular.element('<select id="doctor-specialty" data-bind="value:doctorSpecialty.id"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: vm.specialtyDataSource,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {
                field: "doctorSpecificSpecialty.name",
                title: "Ειδικοτητα 2η",
                editor: function(container, options) {
                    angular.element('<select id="doctor-specific-specialty" data-bind="value:doctorSpecificSpecialty.id"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: vm.specialtyDataSource,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 145
            },
            {
                field: "customerCategory.name",
                title: "Κατηγορία",
                editor: function(container, options) {
                    angular.element('<select id="customer-category" data-bind="value:customerCategory.id"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: vm.categoryDataSource,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 94
            },
            {
                field: "customerSubCategory.name",
                title: "Υποκατηγορία",
                editor: function(container, options) {
                    angular.element('<select id="customer-subcategory" data-bind="value:customerSubCategory.id"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: vm.subCategoryDataSource,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 115
            },
            //{
            //    field: "user.lastName",
            //    title: "Πόρος",
            //    filterable: {
            //        cell: {
            //            operator: "startswith",
            //            showOperators: false
            //        }
            //    }
            //},
            //{
            //    field: "user.user_detail.district.name",
            //    title: "Περιοχή",
            //    aggregates: ["count"],
            //    filterable: {
            //        cell: {
            //            operator: "startswith",
            //            showOperators: false
            //        }
            //    }
            //},
            //{
            //    field: "sector.name",
            //    title: "Τύπος Μονάδας Υγείας",
            //    filterable: {
            //        cell: {
            //            operator: "startswith",
            //            showOperators: false
            //        }
            //    }
            //},
            {
                field: "cellPhone",
                title: "Κινητό Τηλ",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 90
            },
            {
                field: "phone",
                title: "Σταθερό Τηλ",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 90
            },
            {
                field: "email",
                title: "Email",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 151
            },
            {
                field: "dayTime",
                title: "Ωράριο",
                template: "#= (dayTime == 'day') ? 'ΠΡΩΙΝΌ' : (dayTime == 'night') ? 'ΑΠΟΓΕΥΜΑΤΙΝΌ' : '' #",
                editor: function(container, options) {
                    angular.element('<select id="day-time" data-bind="value:dayTime"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: vm.dayTimeDataSource,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "text",
                            dataValueField: "value"
                        });
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false,
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ value: "day", text: "ΠΡΩΙΝΌ" },
                                        { value: "night", text: "ΑΠΟΓΕΥΜΑΤΙΝΌ" }]
                                }),
                                valuePrimitive: true

                            })
                        }
                    }
                },
                width: 120
            },
            //{
            //    field: "city.county.name",
            //    title: "Νομός",
            //    filterable: {
            //        cell: {
            //            operator: "startswith",
            //            showOperators: false
            //        }
            //    }
            //},
            //{
            //    field: "city.name",
            //    title: "Πόλη",
            //    filterable: {
            //        cell: {
            //            operator: "startswith",
            //            showOperators: false
            //        }
            //    }
            //},
            {
                field: "customerTitle.name",
                title: "Τίτλος",
                editor: function(container, options) {
                    angular.element('<select id="customer-title" data-bind="value:customerTitle.id"></select>')
                        .appendTo(container)
                        .kendoComboBox({
                            dataSource: vm.titleDataSource,
                            filter: "startswith",
                            valuePrimitive: true,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                },
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                width: 120
            },
            {
                field: "isActive",
                title: "Ενεργός",
                filterable: {
                    cell: {
                        operator: "eqbool",
                        showOperators: false,
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "Ναι", value: "true" },
                                        { text: "Όχι", value: "false" }],
                                    filtering : function(e){
                                        console.log(e)
                                    }
                                }),
                                valuePrimitive: true

                            })
                        }
                    }
                },
                width: 75,
                template: "#= (isActive == '1') ? 'Ναι' : 'Όχι' #"
            },
            {
                field: "saw",
                title: "MyTarget",
                filterable: {
                    cell: {
                        operator: "eqbool",
                        showOperators: false,
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind:false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{ text: "Ναι", value: "true" },
                                        { text: "Όχι", value: "false" }]
                                }),
                                valuePrimitive: true

                            })
                        }
                    }
                },
                width: 80,
                template: "#= (saw == '1') ? 'Ναι' : 'Όχι' #"
            },
            {field: "createdDate", title: "Ημερομηνία Εισαγωγής", template: "#= kendo.toString(kendo.parseDate(createdDate), 'dddd, dd/MM/yyyy HH:mm')#",
                filterable: {
                    cell: {
                        operator: "eqdate",
                        showOperators: false
                    }
                },
                width: 140
            },
            {
                hidden: true,
                field: "customerTypes",
                title: "Τύποι Επαγγελματία Υγείας",
                filterable: false,
                editor: function (container, options) {
                    angular.element('<select id="customerTypes" multiple="multiple" data-bind="value: customerTypes"/>')
                        .appendTo(container)
                        .kendoMultiSelect({
                            dataSource: new kendo.data.DataSource({
                                data: [
                                    {id:2, name:"ΕΠΑΓΓΕΛΜΑΤΙΕΣ ΥΓΕΙΑΣ ΓΙΑ ΕΠΙΘΕΜΑΤΑ"}
                                ]
                            }),
                            valuePrimitive: false,
                            dataTextField: "name",
                            dataValueField: "id"
                        });
                },
                width: 120
            }
        ];

        vm.gridToolbar = [{ template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label></div>",
            overflow: "never" }];

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        $http.get('/api/patches/customers',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    create: function (o){

                        var data = o.data.models[0];

                        if(!data.customerCategory.id)
                            data.customerCategory = null;

                        if(!data.customerSubCategory.id)
                            data.customerSubCategory = null;

                        if(!data.customerTitle.id)
                            data.customerTitle = null;

                        if(!data.doctorSpecialty.id)
                            data.doctorSpecialty = null;

                        if(!data.doctorSpecificSpecialty.id)
                            data.doctorSpecificSpecialty = null;

                        //type for patches customers
                        //data.customerTypes = [{ id: 2 }];

                        $http.post(vm.customerUrl, data)
                            .success(function (response) {
                                var newCustomerId = response.id;
                                $state.go('customerInfo', {id: newCustomerId});
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    update: function (o){

                        var data = o.data.models[0];

                        if(!data.customerCategory.id)
                            data.customerCategory = null;

                        if(!data.customerSubCategory.id)
                            data.customerSubCategory = null;

                        if(!data.customerTitle.id)
                            data.customerTitle = null;

                        if(!data.doctorSpecialty.id)
                            data.doctorSpecialty = null;

                        if(!data.doctorSpecificSpecialty.id)
                            data.doctorSpecificSpecialty = null;

                        $http.put(vm.customerUrl, data)
                            .success(function (response) {
                                angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                batch: true,
                schema: {
                    data: function (data) {
                        if(data.content != undefined) {
                            data.content.forEach(function(item){

                                if(item.doctorSpecialty === null){
                                    item.doctorSpecialty = {};
                                    item.doctorSpecialty.name = "";
                                }

                                if(item.doctorSpecificSpecialty === null){
                                    item.doctorSpecificSpecialty = {};
                                    item.doctorSpecificSpecialty.name = "";
                                }

                                if(item.customerCategory === null){
                                    item.customerCategory = {};
                                    item.customerCategory.name = "";
                                }

                                if(item.customerSubCategory === null){
                                    item.customerSubCategory = {};
                                    item.customerSubCategory.name = "";
                                }

                                if(item.customerTitle === null){
                                    item.customerTitle = {};
                                    item.customerTitle.name = "";
                                }

                            });
                        }
                        return data.content;

                    },
                    total: function (data) {
                      return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true},
                            isActive:{
                                type: "boolean",
                                defaultValue: true,
                                parse: function(value) {
                                    return !!(value == "1" || value == "true" || value == true);

                                }
                            },
                            saw:{
                                type: "boolean",
                                defaultValue: true,
                                parse: function(value) {
                                    return !!(value == "1" || value == "true" || value == true);

                                }
                            },
                            doctorSpecialty:{defaultValue: {id: null, name : null}},
                            doctorSpecificSpecialty:{defaultValue: {id: null, name : null}},
                            customerCategory:{defaultValue: { id : null, name : null}},
                            customerTitle:{defaultValue: { id : null, name : null}},
                            customerSubCategory:{defaultValue: { id : null, name : null}},
                            dayTime:{defaultValue: null},
                            createdDate: { type: 'date', editable: false},
                            customerTypes: {defaultValue: [{ id : 2, name : "ΕΠΑΓΓΕΛΜΑΤΙΕΣ ΥΓΕΙΑΣ ΓΙΑ ΕΠΙΘΕΜΑΤΑ"}]}
                        }
                    }
                },
                pageSize: 100,
                serverPaging: true,
                serverSorting: true,
                scrollable:false,
                serverFiltering :true
            }),
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: true,
            columns: vm.gridColumns,
            save: function (e) {
                var customersGrid = angular.element("#mainGrid").data("kendoGrid");
                customersGrid.refresh();
            },
            toolbar: vm.gridToolbar
        };

        Principal.hasAuthority('ROLE_HEALTH_VISITOR').then( function (result) {
            if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR'])) {

                var grid = angular.element("#mainGrid").data("kendoGrid");
                vm.gridColumns.unshift({
                    command: [
                        {name: "edit", text: ""}
                    ],
                    title: "&nbsp;",
                    width: 80
                });

                vm.gridToolbar.unshift({name: "create", text: "Προσθήκη Νέου Επαγγελματία Υγείας"});

                grid.setOptions({
                    columns: vm.gridColumns,
                    editable: {
                        mode: "popup"
                    },
                    edit: function(e) {
                        //$scope.countyName = e.model.city.county.name;
                        KendoGrid.defaultEditPopup(this._editContainer);

                        //fix css
                        angular.element( "input[name='isActive']" ).parent().addClass("k-checkbox-custom");
                        angular.element( "input[name='saw']" ).parent().addClass("k-checkbox-custom");
                        angular.element( "div[data-container-for='customerTypes']" ).addClass("k-multi-custom");
                        angular.element( "label[for='createdDate']" ).parent().next().addClass("k-text-custom");
                    },
                    toolbar: vm.gridToolbar
                });
            }
        });

        vm.onSelection = function(data) {
            var selectedId = data.id;
            $state.go('customerInfo', {id: selectedId, stateFrom: 'adminPatchDoctors'});
        };

    }
})();
