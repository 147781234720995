(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MDPatchItemsController', MDPatchItemsController);

    MDPatchItemsController.$inject = ['$scope', '$state', '$http', '$filter', 'KendoFilter'];

    function MDPatchItemsController ($scope, $state, $http, $filter, KendoFilter) {
        var vm = this;

        vm.pageHeading = $filter('translate')('masterData.patchItems.title');

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {

                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/masterdata/patch-items',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: {editable: false, nullable: true}
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }],
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "name", title: "Όνομα",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "legacyCode", title: "legacyCode",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                }
            ],
            editable: false
        };

    }
})();
