(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadOfferRoomTypeDetailController', LeadOfferRoomTypeDetailController);

    LeadOfferRoomTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'LeadOfferRoomType', 'LeadOffer', 'RoomType'];

    function LeadOfferRoomTypeDetailController($scope, $rootScope, $stateParams, entity, LeadOfferRoomType, LeadOffer, RoomType) {
        var vm = this;
        vm.leadOfferRoomType = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:leadOfferRoomTypeUpdate', function(event, result) {
            vm.leadOfferRoomType = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
