(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AddDiseaseDetailController', AddDiseaseDetailController);

    AddDiseaseDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'AddDisease'];

    function AddDiseaseDetailController($scope, $rootScope, $stateParams, entity, AddDisease) {
        var vm = this;
        vm.addDisease = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:addDiseaseUpdate', function(event, result) {
            vm.addDisease = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
