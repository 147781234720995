/**
 * Created by Maria on 26/5/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SectorsController', SectorsController);

    SectorsController.$inject = ['$http', '$state', '$timeout', 'KendoGrid', 'KendoService', 'KendoFilter', 'CustomUtils'];

    function SectorsController ($http, $state, $timeout, KendoGrid, KendoService, KendoFilter, CustomUtils) {
        var vm = this;
        vm.pageHeading = 'Μοναδες Υγειας';
        vm.selectedEmployees = [];
        var comboBoxes = ["#masterFilter","#prefectures", "#cities", "#parentSector"];
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(65);

        vm.onAddSectorClick = function () {
            const grid = $("#mainGrid").data("kendoGrid");
            grid.addRow();
        };

        vm.employeesMultiDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: function (o) {
                    var url = '/api/me/employees';
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }
                    if (o.data.sort == undefined || !o.data.sort.length) {
                        o.data.sort = { "field": "account.lastName",  "dir": "asc" };
                    }

                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            { "field": "account.activated", "operator": "eqbool",
                                "value": true }
                        ]
                    );

                    o.data.page = 1;
                    o.data.pageSize = 20;
                    $http.get(url,
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            },
            schema: {
                parse: function (data) {
                    data.forEach(function (item) {
                        item.employee = {
                            account: {
                                fullName: item.account.lastName + " " + item.account.firstName
                            }
                        };
                    });
                    return data;
                }
            }
        });

        vm.employeesMultiOptions = KendoService.initDefaultOptions( vm.employeesMultiDataSource, "startswith", "employee.account.fullName", "id");
        vm.employeesMultiOptions.placeholder = "Επιλογή ιατρικού επισκέπτη";
        vm.employeesMultiOptions.change = function (e) {
            angular.element("#mainGrid").data("kendoGrid").dataSource.read();
        };

        vm.toolbarOptions = {
            items: [
                {
                    template: "<label>Ιατρικός Επισκέπτης: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.employeesMulti' multiple='multiple' " +
                    "k-options='vm.employeesMultiOptions' k-ng-model='vm.selectedEmployees'></select>",
                    overflow: "never", attributes: { class: "left" }
                }
            ]
        };

        vm.dataSource = {};
        vm.dataSource.doctorSpecialty = KendoService.initDefaultDataSource('/api/masterdata/doctor-specialties');
        vm.dataSource.sectorTypes =  new kendo.data.DataSource({
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }
                    o.data.filter.filters.push({ "field": "sectorTypeCategory", "operator": "startswith", "value": 'hcu'});

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get( '/api/masterdata/sector-types',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            }
        });

        //DropDown Options
        vm.sectorTypeComboBoxOptions = {
            dataSource: vm.dataSource.sectorTypes,
            filterable: true,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id",
            suggest: true,
            change: function() {
                var obj = this.dataItem();
                //$timeout to trigger the change
                $timeout(function(){
                    vm.isPrivate = obj.isPrivate;
                    if(vm.isPrivate) {
                        vm.specialtyComboBox.value('');
                        vm.sectorsComboBox.text('');
                        vm.sectorsComboBox.value('');
                    }
                });
            }
        };

        vm.specialtyComboBoxOptions = {
            dataSource: vm.dataSource.doctorSpecialty,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };

        vm.sectorsDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }
                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            //isPrivate eqbool false
                            { "field": "iseditable", "operator": "eqbool",
                                "value": false }
                        ]
                    );

                    if(vm.sectorId) {
                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "id", "operator": "noteq",
                                    "value": vm.sectorId }
                            ]
                        )
                    }

                    o.data.page = 1;
                    o.data.pageSize = 20;
                    $http.get('/api/me/sectors',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            }
        });

        vm.sectorsAllComboBoxOptions = {
            dataSource: vm.sectorsDataSource,
            filter: "like",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };

        vm.refreshCombobox = function(filter, combobox) {
            combobox.filter(filter);
            combobox.read();
        };

        $http.get('/api/employees/me')
            .success(function (response) {
                vm.geodata = response.geodata;

                if(!vm.geodata) {
                    var prefecturesDataSource = new kendo.data.DataSource({
                        serverFiltering: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.page = 1;
                                o.data.pageSize = 100000;
                                $http.get('/api/masterdata/prefectures',
                                    {params: o.data})
                                    .success(function (response) {
                                        o.success(response.content);
                                    })
                                    .error(function (response) {});
                            }
                        }
                    });

                    //DropDown Options
                    vm.prefecturesComboBoxOptions = {
                        dataSource: prefecturesDataSource,
                        filter: "startswith",
                        valuePrimitive: false,
                        dataTextField: "name",
                        dataValueField: "id",
                        change: function(){
                            var filter = {};
                            var parentFilter = {};
                            if(this.value() && !isNaN(this.value())) {
                                filter = {
                                    "field": "prefecture.id",
                                    "operator": "eq",
                                    "value": this.value()
                                };
                                parentFilter = {
                                    "field": "prefectureName",
                                    "operator": "eq",
                                    "value": this.text()
                                };
                            }
                            vm.refreshCombobox(filter, vm.citiesComboBoxOptions.dataSource);
                            vm.refreshCombobox(parentFilter, vm.sectorsAllComboBoxOptions.dataSource);
                        }
                    };

                    var citiesDataSource = new kendo.data.DataSource({
                        serverFiltering: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.page = 1;
                                o.data.pageSize = 50;
                                $http.get('/api/masterdata/cities',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response.content);
                                    })
                                    .error(function (response) {});
                            }
                        }
                    });

                    //DropDown Options
                    vm.citiesComboBoxOptions = {
                        dataSource: citiesDataSource,
                        filter: "startswith",
                        valuePrimitive: false,
                        dataTextField: "name",
                        dataValueField: "id",
                        template: "<table>${name} &nbsp;" +
                        "<span class='hint'>(${prefecture.name})</span></table>",
                        change: function(e){
                            var filter = {};
                            if(this.dataItem(this.select())) {
                                filter = {
                                    "field"   : "id",
                                    "operator": "eq",
                                    "value"   : this.dataItem(this.select()).prefecture.id
                                };
                            }
                            vm.refreshCombobox(filter, vm.prefecturesComboBoxOptions.dataSource);

                        }
                    };
                }
                else {
                    var geodataDataSource = new kendo.data.DataSource({
                        serverFiltering: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.page = 1;
                                o.data.pageSize = 100;
                                $http.get('/api/masterdata/geodata',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response.content);
                                    })
                                    .error(function (response) {});
                            }
                        },
                        schema: {
                            parse: function(data) {
                                angular.forEach(data, function(item) {
                                    item.masterFilter = "";
                                    if(item.street !==null) {
                                        item.masterFilter =  item.street + ", ";
                                    }
                                    item.masterFilter = item.masterFilter + item.city + ", " + item.postalCode + ", " + item.prefecture;
                                });
                                return data;
                            }
                        }
                    });

                    vm.masterFilterComboBoxOptions = {
                        dataSource: geodataDataSource,
                        filter: "search",
                        dataTextField: "masterFilter",
                        dataValueField: "id",
                        headerTemplate:"<div class='dropdown-header k-widget k-header'>" +
                        "<span>Διεύθυνση</span>"+
                        "<span>Πόλη</span>"+
                        "<span>Τ.Κ.</span>"+
                        "<span>Νομός</span></div>",
                        template:
                            "#= street != null ? street + ', ' + city + ', ' + postalCode + ', ' + prefecture : city + ', ' + postalCode + ', ' + prefecture #",
                        placeholder: "Αναζητήστε Διεύθυνση, Πόλη, Τ.Κ., Νομό",
                        change: function(){
                            //initialize inputs (autocomplete)
                            if(this.dataItem(this.select())) {
                                var self = this;
                                //using $timeout to trigger the change
                                $timeout(function(){
                                    vm.saveNewSector.prefectureName = self.dataItem(self.select()).prefecture;
                                    vm.saveNewSector.cityName = self.dataItem(self.select()).city;
                                    vm.saveNewSector.address = self.dataItem(self.select()).street;
                                    vm.saveNewSector.postalCode = self.dataItem(self.select()).postalCode;
                                    vm.suggestBricks(vm.saveNewSector.postalCode);

                                    var parentFilter = {};
                                    if (vm.saveNewSector.prefectureName) {
                                        parentFilter = {
                                            "field": "prefectureName",
                                            "operator": "eq",
                                            "value": vm.saveNewSector.prefectureName
                                        };
                                    }
                                    vm.refreshCombobox(parentFilter, vm.sectorsAllComboBoxOptions.dataSource);
                                },0);
                            }
                        }
                    };
                }

                vm.mainGridOptions = {
                    dataSource: new kendo.data.DataSource({
                        batch: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                if(vm.selectedEmployees.length) {
                                    o.data.filter.filters = o.data.filter.filters.concat(
                                        [
                                            { "field": "customerSectors.employeeCustomerSectors.employee.id", "operator": "inAll",
                                                "value": vm.selectedEmployees.toString() }
                                        ]
                                    );
                                }

                                if(o.data.filter != undefined && o.data.filter.filters != undefined){
                                    angular.forEach(o.data.filter.filters, function(item) {
                                        if (item.field === "sectorParent.name"){
                                            item.field = "parent.name"
                                        }
                                    });
                                }

                                if(o.data.sort != undefined){
                                    angular.forEach(o.data.sort, function(item) {
                                        if (item.field === "sectorParent.name"){
                                            item.field = "parent.name"
                                        }
                                    });
                                }

                                $http.get('/api/me/sectors',
                                    {params: o.data})
                                    .success(function (response) {
                                        o.success(response);
                                    })
                                    .error(function (response) {});
                            },
                            create: function(o) {
                                vm.newSector = o.data.models[0];

                                if(!vm.newSector.city != null && (vm.newSector.city.id === null || isNaN(vm.newSector.city.id))){
                                    vm.newSector.city = null;
                                }

                                if(vm.newSector.prefecture != null && (vm.newSector.prefecture.id === null || isNaN(vm.newSector.prefecture.id))){
                                    vm.newSector.prefecture = null;
                                }

                                if(vm.newSector.sectorType != null && vm.newSector.sectorType.id === null){
                                    vm.newSector.sectorType = null;
                                }

                                if(vm.newSector.doctorSpecialty != null){
                                    if(vm.newSector.doctorSpecialty.id === null || vm.newSector.sectorType.isPrivate) {
                                        vm.newSector.doctorSpecialty = null;
                                    }
                                }

                                if(vm.newSector.parent != null){
                                    if(vm.newSector.parent.id === null || vm.newSector.sectorType.isPrivate) {
                                        vm.newSector.parent = null;
                                    }
                                }

                                angular.extend(vm.newSector, vm.saveNewSector);
                                delete vm.newSector.id;

                                vm.newSector.brick = vm.brick;
                                vm.newSector.osfeBrick = vm.osfeBrick;

                                $http.post('api/sectors',vm.newSector)
                                    .success(function (response) {
                                        o.success(response);
                                        angular.element("#mainGrid").data("kendoGrid").dataSource.read();
                                    })
                                    .error(function (response) {});
                            },
                            update: function(o) {

                                vm.updateSector = o.data.models[0];
                                angular.extend(vm.saveNewSector, vm.updateSector);

                                if(vm.updateSector.city != null) {
                                    if((vm.updateSector.city.id === null
                                        || vm.updateSector.city.id === undefined || isNaN(vm.updateSector.city.id)) || vm.geodata){
                                        vm.updateSector.city = null;
                                    }
                                }

                                if(vm.updateSector.prefecture != null) {
                                    if((vm.updateSector.prefecture.id === null
                                        || vm.updateSector.prefecture.id === undefined || isNaN(vm.updateSector.prefecture.id)) || vm.geodata){
                                        vm.updateSector.prefecture = null;
                                    }
                                }

                                if(vm.updateSector.sectorType != null) {
                                    if(vm.updateSector.sectorType.id === null || vm.updateSector.sectorType.id === undefined){
                                        vm.updateSector.sectorType = null;
                                    }
                                }

                                if(vm.updateSector.doctorSpecialty != null){
                                    if(vm.updateSector.doctorSpecialty.id === null || vm.updateSector.doctorSpecialty.id === undefined || vm.updateSector.sectorType.isPrivate) {
                                        vm.updateSector.doctorSpecialty = null;
                                    }
                                }

                                if(vm.updateSector.parent != null){
                                    if(vm.updateSector.parent.id === null || vm.updateSector.parent.id === undefined || vm.updateSector.sectorType.isPrivate) {
                                        vm.updateSector.parent = null;
                                    }
                                }

                                vm.updateSector.brick = vm.brick;
                                vm.updateSector.osfeBrick = vm.osfeBrick;

                                $http.put('api/sectors',vm.updateSector)
                                    .success(function (response) {
                                        vm.mainGridOptions.dataSource.read();
                                    })
                                    .error(function (response) {});
                            }
                        },
                        schema: {
                            data: function (data) {
                                if(data.content != undefined) {
                                    data.content.forEach(function(item){
                                        if(item.name === null){
                                            item.name = "";
                                        }

                                        if(item.sectorType === null){
                                            item.sectorType = {};
                                            item.sectorType.name = "";
                                        }

                                        if(item.doctorSpecialty === null){
                                            item.doctorSpecialty = {};
                                            item.doctorSpecialty.name = "";
                                        }

                                        if(item.parent === null){
                                            item.parent = {};
                                            item.parent.name = "";
                                        }

                                    });
                                }

                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: { editable: false, nullable: false },
                                    sectorType : {defaultValue : { id : null, name : null}},
                                    prefecture: { defaultValue: { id : null, name : null} },
                                    city : {defaultValue : {id : null, name : null}},
                                    doctorSpecialty : {defaultValue : { id : null, name : null}},
                                    sectorParent: { from: "parent", defaultValue : { id : null, name : null} }
                                }
                            }
                        },
                        serverPaging: true,
                        serverFiltering: true,
                        serverSorting: true,
                        pageSize: 100
                    }),
                    sortable: true,
                    pageable: {
                        messages: {
                            display: "{0} - {1} από {2} Μοναδες Υγειας", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                            empty: "Δεν βρέθηκαν Μοναδες Υγειας",
                            page: "Σελίδα",
                            allPages: "Όλα",
                            of: "από {0}", // {0} is total amount of pages.
                            itemsPerPage: "εγγραφές ανά σελίδα",
                            first: "Πήγαινε στη πρώτη σελίδα",
                            previous: "Πήγαινε στη τελευταία σελίδα",
                            next: "Πήγαινε στην επόμενη σελίδα",
                            last: "Πήγαινε στη τελευταία σελίδα",
                            refresh: "Ανανέωση"
                        }
                    },
                    noRecords: {
                        template: "Δεν βρέθηκαν Μοναδες Υγειας"
                    },
                    selectable: true,
                    resizable: true,
                    filterable: {
                        mode: "row"
                    },
                    columns: [
                        {field: "name", title: "Όνομα Μονάδας Υγείας",
                            filterable: {
                                cell: {
                                    operator: "like",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "sectorType.name", title: "Τύπος Μονάδας Υγείας",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "doctorSpecialty.name", title: "Ειδικότητες στη ΜΣ",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "address", title: "Διεύθυνση",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "addressNum", title: "Αριθμός",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "postalCode", title: "Τ.Κ.",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "cityName", title: "Πόλη",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "prefectureName", title: "Νομός",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "brick", title: "Brick",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "osfeBrick", title: "Brick ΟΣΦΕ",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "phone", title: "Τηλέφωνο",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "sectorParent.name", title: "Ανήκει στη ΜΣ",
                            filterable: {
                                cell: {
                                    field : "parent.name",
                                    operator: "like",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {command:[{name:"edit", text: "Επεξεργασία"}], title: "&nbsp;", width:140}
                    ],
                    editable: {
                        mode: "popup",
                        template: kendo.template(angular.element("#popup_editor").html())
                    },
                    height: vm.contentHeight,
                    edit: function(e) {
                        e.model.dirty = true;
                        vm.selectedSector = e.model;
                        vm.sectorId = null;
                        if (vm.selectedSector.sectorType) {
                            vm.isPrivate = vm.selectedSector.sectorType.isPrivate;
                        }
                        vm.saveNewSector = {};
                        if(e.model.id) {
                            vm.saveNewSector = vm.selectedSector;
                        }
                        if (e.model.sectorParent) vm.sectorParent = e.model.sectorParent;
                        KendoGrid.defaultCustomEditPopup(this._editContainer,1000,990);
                        // angular.element("div.k-edit-form-container").width(1000);

                        //refresh kendoComboBox
                        if(e.model.isNew()) {
                            angular.forEach(comboBoxes, function (item) {
                                var comboBox = angular.element(item).data("kendoComboBox");
                                if (comboBox) {
                                    vm.refreshCombobox({}, comboBox.dataSource);
                                }
                            });
                        }

                        if(!e.model.isNew()) {
                            vm.sectorId = e.model.id;

                            if(e.model.prefecture && !vm.geodata){
                                var filter = {
                                    "field": "prefecture.id",
                                    "operator": "eq",
                                    "value": e.model.prefecture.id
                                };

                                vm.refreshCombobox(filter, vm.citiesComboBoxOptions.dataSource);
                            }

                            if(e.model.city && !vm.geodata){
                                var filterCity = {
                                    "field"   : "id",
                                    "operator": "eq",
                                    "value"   : e.model.city.prefecture.id
                                };

                                vm.refreshCombobox(filterCity, vm.prefecturesComboBoxOptions.dataSource);
                            }

                            if(e.model.prefectureName){
                                var filterParent = {
                                    "field": "prefectureName",
                                    "operator": "eq",
                                    "value": e.model.prefectureName
                                };

                                vm.refreshCombobox(filterParent, vm.sectorsAllComboBoxOptions.dataSource);
                            }

                            vm.brick = e.model.brick;
                            vm.osfeBrick = e.model.osfeBrick;
                        }

                        vm.initPrefecturesComboBox = true;
                        vm.initCitiesComboBox = true;

                        //if geodata init city and prefecture
                        if(!e.model.city) e.model.city = {};
                        if(!e.model.prefecture) e.model.prefecture = {};

                        var wnd = angular.element(e.container).data("kendoWindow");
                        wnd.center();
                    },
                    save: function() {
                        delete vm.initPrefecturesComboBox;
                        delete vm.initCitiesComboBox;
                    },
                    cancel: function() {
                        delete vm.initPrefecturesComboBox;
                        delete vm.initCitiesComboBox;
                    }
                };
            })
            .error(function (response) {});

        vm.onSelection = function(data) {
            var sectorId = data.id;
            $state.go('sectorInfo', {id: sectorId});
        };

        vm.suggestBricks = function($value) {
            var data = {};
            data.filter ={
                filters: [
                    {
                        "field": "postalCode",
                        "operator": "eq",
                        "value": $value,
                        "ignoreCase": true
                    }
                ]};

            data.page = 1;
            data.pageSize = 1;


            $http.get('/api/masterdata/bricks',
                {params: data})
                .success(function (response) {
                    if (response.content != null
                        && response.content instanceof Array
                        && response.content.length > 0) {
                        vm.brick = response.content[0].territoryNo;
                    }
                })
                .error(function (response) {});


            $http.get('/api/masterdata/brick-osfes',
                {params: data})
                .success(function (response) {
                    if (response.content != null
                        && response.content instanceof Array
                        && response.content.length > 0) {
                        vm.osfeBrick = response.content[0].territoryName;
                    }
                })
                .error(function (response) {});
        };
    }
})();
