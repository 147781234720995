(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('doctorSpecialties', {
            parent: 'masterData',
            url: '/doctorSpecialties',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_ACCOUNTING_DEP', 'ROLE_REC_ADMINISTRATOR']
            },
            views: {
                'content@': {
                    templateUrl: 'app/masterData/doctorSpecialties/doctorSpecialties.html',
                    controller: 'MDDoctorSpecialtiesController',
                    controllerAs: 'vm'
                }
            },
            params: {
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', function ($stateParams) {
                    return {
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('masterData');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
    }

})();
