(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PatchReportDetailController', PatchReportDetailController);

    PatchReportDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PatchReport', 'PatchCustomer', 'Employee', 'PatchReportMaterialCategory', 'CustomerSector'];

    function PatchReportDetailController($scope, $rootScope, $stateParams, entity, PatchReport, PatchCustomer, Employee, PatchReportMaterialCategory, CustomerSector) {
        var vm = this;
        vm.patchReport = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:patchReportUpdate', function(event, result) {
            vm.patchReport = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
