(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MedicineDetailController', MedicineDetailController);

    MedicineDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Medicine'];

    function MedicineDetailController($scope, $rootScope, $stateParams, entity, Medicine) {
        var vm = this;
        vm.medicine = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:medicineUpdate', function(event, result) {
            vm.medicine = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
