(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadCallDialogController', LeadCallDialogController);

    LeadCallDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'LeadCall', 'CallType', 'ProspectivePatient', 'Patient', 'LeadHospitalization', 'Customer'];

    function LeadCallDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, LeadCall, CallType, ProspectivePatient, Patient, LeadHospitalization, Customer) {
        var vm = this;
        vm.leadCall = entity;
        vm.calltypes = CallType.query();
        vm.prospectivepatients = ProspectivePatient.query();
        vm.patients = Patient.query();
        vm.leadhospitalizations = LeadHospitalization.query();
        vm.customers = Customer.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:leadCallUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.leadCall.id !== null) {
                LeadCall.update(vm.leadCall, onSaveSuccess, onSaveError);
            } else {
                LeadCall.save(vm.leadCall, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateTime = false;
        vm.datePickerOpenStatus.appointmentDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
