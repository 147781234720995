(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProspectivePatientDeleteController',ProspectivePatientDeleteController);

    ProspectivePatientDeleteController.$inject = ['$uibModalInstance', 'entity', 'ProspectivePatient'];

    function ProspectivePatientDeleteController($uibModalInstance, entity, ProspectivePatient) {
        var vm = this;
        vm.prospectivePatient = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            ProspectivePatient.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
