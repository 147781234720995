/**
 * Created by Kristy on 29/11/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('HasAuthorizationByUser', HasAuthorizationByUser);

    HasAuthorizationByUser.$inject = ['$http', '$state'];

    function HasAuthorizationByUser ($http, $state) {
        var url = "/api/employees/me";

        return {
                hasAuthority: hasAuthority
            };

        function hasAuthority() {
            $http.get( url )
                .success(function ( response ) {
                    var unauthorizedUsers = [ 'kksilouri', 'mathanasiou' ];
                    if ( unauthorizedUsers.indexOf(response.account.login) !== -1 ) {
                        $state.go('accessdenied');
                    }
                })
                .error(function (response) {
                    console.log(response);
                });
        }
    }

})();
