/**
 * Created by Maria on 16/5/2016.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminCustomerViewController', AdminCustomerViewController);

    AdminCustomerViewController.$inject = ['DataUtils', '$http','$state', '$stateParams', '$filter', 'Principal', '$scope', 'NotificationOptions', 'ApplicationParameters', 'healthVisitorEditSector', 'CustomDataUtils'];

    function AdminCustomerViewController (DataUtils, $http, $state, $stateParams, $filter, Principal, $scope, NotificationOptions, ApplicationParameters, healthVisitorEditSector, CustomDataUtils) {
        var vm = this;
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");
        if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR'])) {
            vm.canEdit = true;
        }else {
            vm.readOnly = true;
        }

        vm.showCustomerSectorsTab = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR']) ||
            (CustomDataUtils.isNotEmpty(healthVisitorEditSector.data.content) && healthVisitorEditSector.data.content[0].value === '1');

        vm.pageHeading = 'Επαγγελματίας Υγείας';
        vm.customer = {};
        vm.profilePicture = "customer_icon.png";
        vm.selectedUserId = $stateParams.id;
        vm.stateFrom = $stateParams.stateFrom;
        vm.$state = $state;
        vm.canPatchReport = false;
        vm.hasMedReport = false;
        ApplicationParameters.hasModule('Med_Report', false).then(function(response) {
            vm.hasMedReport  = response.data.content[0].value === "1";
        });

        $http.get('api/customers/' + vm.selectedUserId)
            .success(function (response) {
                vm.customer = response;
                var foundItemById = $filter('filter')(vm.customer.customerTypes, {id: 2}, true)[0];
                vm.canPatchReport = vm.customer.customerTypes.indexOf(foundItemById) !== -1;
            })
            .error(function (response) {
                console.log(response);
            });

        kendo.Backbone = kendo.Backbone || {};

        kendo.Backbone.DataSource = kendo.data.DataSource.extend({
            init: function(options) {
                if (options.transport && options.transport.read) {
                    options.transport.read.type = 'GET';
                    options.transport.read.dataType = 'json';
                    options.transport.read.contentType = 'application/json;charset=UTF-8';
                    options.transport.read.parameterMap = function (data, operation) {
                        return JSON.stringify(data);
                    }
                }
                kendo.data.DataSource.fn.init.call(this, options);
            }
        });

        vm.setProfilePhoto = function ($file, customer) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        customer.profilePhoto = base64Data;
                        customer.profilePhotoContentType = $file.type;
                    });

                    $http.put('api/customers', customer)
                        .success(function (response) {
                            notification.show({
                                message: "Η ενημέρωση της φωτογραφίας ήταν επιτυχής!"
                            }, "success");
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                });
            }
        };

        vm.goBack = function () {
            $state.go(vm.stateFrom !== null && vm.stateFrom !== undefined ? vm.stateFrom : 'adminCustomers');
        };
    }
})();
