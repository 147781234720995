/**
 * Created by Kristy on 12/9/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .directive('favouriteFiltersWindow', favouriteFiltersWindow);

    function favouriteFiltersWindow () {

        return {
            restrict: 'A',
            template: "<div kendo-window=\"vm.window\" k-width=\"600\" k-height=\"180\" k-visible=\"false\" k-modal=\"true\" k-resizable=\"false\" k-draggable=\"false\"\n" +
            "     k-on-close=\"vm.filter = {};vm.isRenameMode = false\" k-title=\"'Αποθήκευση Φίλτρου'\">\n" +
            "    <div id=\"addView\" style=\"margin-top: 5%;\">\n" +
            "        <div class=\"k-edit-label\" style=\"margin-left: 12%;margin-right: 2%;\">\n" +
            "            <label>* Όνομα Φίλτρου </label>\n" +
            "        </div>\n" +
            "        <input id=\"filterName\" name=\"filterName\" ng-model=\"vm.filter.name\" class=\"k-textbox info pull-left\"/>\n" +
            "    </div>\n" +
            "    <div>\n" +
            "        <div class=\"buttons-wrap pull-right\" style=\"clear: both;margin-top: 30px;\">\n" +
            "            <md-button class=\"blue-color-md-button md-raised md-mini md-primary md-primary-light\" aria-label=\"add\" ng-click=\"vm.saveFilter(vm.isRenameMode ? vm.filter : {name: vm.filter.name})\">\n" +
            "                <i class=\"material-icons fixed-icons fixed-icons-2\">save</i>Αποθήκευση</md-button>\n" +
            "            <md-button class=\"md-raised md-mini\" aria-label=\"cancel\" ng-disabled=\"vm.isSaving\" ng-click=\"vm.window.close()\">\n" +
            "                <i class=\"material-icons fixed-icons fixed-icons-2\">clear</i>Ακύρωση</md-button>\n" +
            "        </div>\n" +
            "    </div>\n" +
            "</div>"
        };
    }
})();
