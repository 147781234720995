(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SectorTypeDialogController', SectorTypeDialogController);

    SectorTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'SectorType'];

    function SectorTypeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, SectorType) {
        var vm = this;
        vm.sectorType = entity;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:sectorTypeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.sectorType.id !== null) {
                SectorType.update(vm.sectorType, onSaveSuccess, onSaveError);
            } else {
                SectorType.save(vm.sectorType, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
