/**
 * Created by Kristy on 25/2/2020.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MedReportApprovalsController', MedReportApprovalsController);

    MedReportApprovalsController.$inject = ['$http','$state', '$stateParams','$filter', '$timeout', 'ApplicationParameters', 'HasAuthorizationByUser'];

    function MedReportApprovalsController ($http, $state, $stateParams, $filter, $timeout, ApplicationParameters, HasAuthorizationByUser) {
        var vm = this;
        ApplicationParameters.hasModule('Med_Report', true);
        vm.$state = $state;
    }
})();
