(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CompetitorDetailController', CompetitorDetailController);

    CompetitorDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Competitor'];

    function CompetitorDetailController($scope, $rootScope, $stateParams, entity, Competitor) {
        var vm = this;
        vm.competitor = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:competitorUpdate', function(event, result) {
            vm.competitor = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
