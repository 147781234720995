/**
 * Created by Teo on 26/07/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadHospitalizationDiseasesAllController', LeadHospitalizationDiseasesAllController);

    LeadHospitalizationDiseasesAllController.$inject = ['$scope', '$filter','$http', '$timeout', 'KendoFilter', 'favouriteFilters','KendoToolbar', 'FavouriteFiltersService',
        'KendoGrid', 'MasterToKendoFiltersService', 'employee', 'CustomUtils'];

    function LeadHospitalizationDiseasesAllController ($scope, $filter, $http, $timeout, KendoFilter, favouriteFilters, KendoToolbar, FavouriteFiltersService, KendoGrid,
                                          MasterToKendoFiltersService, employee, CustomUtils) {
        var vm = this;
        vm.pageHeading = "Αναφορές Νοσηλειών";
        vm.contentWidth = CustomUtils.getWidthPixelsFromPercentage(84);
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(51);
        vm.url = '/api/lead-calls/all';
        vm.favouriteFilters = favouriteFilters.data.content;
        vm.employee = employee;
        vm.masterFilters = { hospitalizations: true/*, bookedAppointment: false */};

        vm.disabled = true;
        vm.isEdited = vm.isRenameMode = false;
        vm.selectedFilter = {};
        vm.mainGrid = {};
        vm.masterOptions = [];
        vm.filter = {};
        vm.view = 'leadHospitalizations';

        var setView = function() {
            var filterable = vm.masterFilters.from && vm.masterFilters.to ? {
                mode: "row"
            } : false;

            var sortable = vm.masterFilters.from && vm.masterFilters.to ? {
                mode: "multiple",
                allowUnsort: true,
                showIndexes: true
            } : false;

            vm.disabled = !vm.masterFilters.from || !vm.masterFilters.to;
            vm.mainGrid.setOptions({
                sortable: sortable,
                groupable: vm.masterFilters.from && vm.masterFilters.to,
                filterable: filterable
            });
        };
        $scope.$watchGroup(["vm.masterFilters.from", "vm.masterFilters.to"], function () { setView(); });

        vm.masterFiltersEdited = function(){
            var gridOptions = FavouriteFiltersService.getKendoOptions(vm.mainGrid);
            $timeout(function(){
                vm.isEdited = !!(vm.selectedFilter.kendoOptions && vm.masterOptions.length &&
                    (!angular.equals(vm.masterOptions, MasterToKendoFiltersService.getMasterFilters([], vm.masterFilters, vm.view)) || !angular.equals(JSON.parse(vm.selectedFilter.kendoOptions), gridOptions)) );
            });
        };
        $scope.$watch("vm.masterFilters", vm.masterFiltersEdited, true);

        vm.collapseAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-collapse").trigger("click"); };
        vm.expandAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-expand").trigger("click"); };

        //Date Filter
        vm.masterFiltersOnClick = function(e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');
            if (e.id === "today") {
                KendoToolbar.clearDatePickers();
                $timeout(function () {
                    vm.masterFilters.from = date;
                    vm.masterFilters.to = date;
                });
            }
            else if (e.id === "week") {
                KendoToolbar.clearDatePickers();
                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6
                $timeout(function () {
                    vm.masterFilters.from = firstWeekDay;
                    vm.masterFilters.to = lastWeekDay;
                });
            }
            else if (e.id === "month") {
                KendoToolbar.clearDatePickers();
                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                $timeout(function () {
                    vm.masterFilters.from = firstMonthDay;
                    vm.masterFilters.to = lastMonthDay;
                });
            }
            else if (e.id === "year") {
                KendoToolbar.clearDatePickers();
                $timeout(function () {
                    vm.masterFilters.from = new Date(date.getFullYear(), 0, 1);
                    vm.masterFilters.to = new Date();
                });
            }
        };

        var booleanFilterTemplate = function (args) {
            KendoFilter.getBooleanTemplate(args);
        };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup", id: "buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" },
                        { text: "Έτος", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "year" }
                    ], overflow: "never", attributes: {class: "left"}
                },
                {type: "separator", overflow: "never", attributes: {class: "left"}},
                {
                    template: "<label>Από: </label><input class='inline' kendo-date-picker id='from' onkeydown='return false' options='vm.fromOptions' k-ng-model='vm.masterFilters.from'/>",
                    overflow: "never",
                    attributes: {class: "left"}
                },
                {
                    template: "<label>Εώς: </label><input class='inline' kendo-date-picker id='to' onkeydown='return false' options='vm.toOptions' k-ng-model='vm.masterFilters.to'/>",
                    overflow: "never",
                    attributes: {class: "left"}
                },
                {type: "separator", overflow: "never", attributes: {class: "left"}},
                {
                    template: "<label>Έγινε Εισαγωγή: </label><div class='inline'><md-radio-group layout='row' ng-model='vm.masterFilters.hospitalizations'>" +
                    "<md-radio-button ng-value=false class='md-primary'> Ναι </md-radio-button>" +
                    "<md-radio-button ng-value=true class='md-primary'> Όχι </md-radio-button>" +
                    "<md-radio-button ng-value='' class='md-primary'> Όλα </md-radio-button></md-radio-group></div>",
                    overflow: "never", attributes: {class: "left"}
                },
                // Remove bookedAppointment filter ECRM-337
                /*{type: "separator", overflow: "never", attributes: {class: "left"}},
                {
                    template: "<label>Δια Ζώσης: </label><div class='inline'><md-radio-group layout='row' ng-model='vm.masterFilters.bookedAppointment' ng-change='vm.onBookedAppointmentChange();'>" +
                    "<md-radio-button ng-value=true class='md-primary'> Ναι </md-radio-button>" +
                    "<md-radio-button ng-value=false class='md-primary'> Όχι </md-radio-button>" +
                    "<md-radio-button ng-value='' class='md-primary'> Όλα </md-radio-button></md-radio-group></div>",
                    overflow: "never", attributes: {class: "left"}
                },
                {type: "separator", overflow: "never", attributes: {class: "left"}},
                {
                    template: "<label>Ημ/νία Ραντεβού:</label><label>Από: </label><input class='inline' kendo-date-picker id='from1' options='vm.fromOptions1' k-ng-model='vm.masterFilters.from1' ng-disabled='!vm.masterFilters.bookedAppointment'/>" +
                                                             "<label>Εώς: </label><input class='inline' kendo-date-picker id='to1' options='vm.toOptions1' k-ng-model='vm.masterFilters.to1' ng-disabled='!vm.masterFilters.bookedAppointment'/>",
                    overflow: "never",
                    attributes: {class: "left"}
                },*/
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary md-primary-light' aria-label='search' ng-click='vm.mainGridOptions.dataSource.read()' ng-disabled='vm.disabled'>" +
                    "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>",
                    overflow: "never",
                    attributes: {class: "right"}
                }
            ]
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.masterFilters.from = value;
                } else {
                    vm.masterFilters.from = null;
                }
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.masterFilters.to = value;
                } else {
                    vm.masterFilters.to = null;
                }
            }
        };

        vm.fromOptions1 = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.masterFilters.from1 = value;
                } else {
                    vm.masterFilters.from1 = null;
                }
            }
        };

        vm.toOptions1 = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');
                var value = this.value();
                if (value) {
                    vm.masterFilters.to1 = value;
                } else {
                    vm.masterFilters.to1 = null;
                }
            }
        };

        // Not used
        vm.onBookedAppointmentChange = function () {
            if (vm.masterFilters.bookedAppointment === true){
                // do nothing
            }else {
                vm.masterFilters.from1 = "";
                vm.masterFilters.to1 = "";
            }
        };

        var filterTemplate = function (args) {
            KendoFilter.getTemplate(args);
        };

        var grid = {};
        grid.columns = [
            {
               title: "Στοιχεία Νοσηλείας",
               columns: [
                   {
                       field: "possibleDischargeDate",
                       title: "Πιθανή Ημερ/νια Εξιτηρίου",
                       width: "110px",
                       template: "#= possibleDischargeDate ? kendo.toString(kendo.parseDate(possibleDischargeDate), 'dddd, dd/MM/yyyy') : ''#",
                       filterable: {
                           cell: {
                               operator: "eq",
                               showOperators: false
                           }
                       },
                       aggregates: ["count"],
                       groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                   },
                   {
                       field: "leadHospitalization.surgeryType",
                       title: "Είδος Επέμβασης",
                       width: "90px",
                       filterable: {
                           cell: {
                               operator: "startswith",
                               template: function (args) {
                                   KendoFilter.getTemplate(args);
                               },
                               showOperators: false
                           }
                       },
                       aggregates: ["count"],
                       groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                   },
                   {
                       field: "leadHospitalization.addDiseaseOther",
                       title: "Άλλο Νόσημα",
                       width: "90px",
                       filterable: {
                           cell: {
                               operator: "startswith",
                               template: function (args) {
                                   KendoFilter.getTemplate(args);
                               },
                               showOperators: false
                           }
                       },
                       aggregates: ["count"],
                       groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                   },
                   {
                       field: "leadHospitalization.sector.name",
                       title: "Νοσοκοκείο",
                       width: "90px",
                       filterable: {
                           cell: {
                               operator: "startswith",
                               template: function (args) {
                                   KendoFilter.getTemplate(args);
                               },
                               showOperators: false
                           }
                       },
                       aggregates: ["count"],
                       groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                   },
                   {
                       field: "leadHospitalization.competitor.name",
                       title: "Άλλο Κ.Α.",
                       width: "90px",
                       filterable: {
                           cell: {
                               operator: "startswith",
                               template: function (args) {
                                   KendoFilter.getTemplate(args);
                               },
                               showOperators: false
                           }
                       },
                       aggregates: ["count"],
                       groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                   },
                   {
                       field: "leadHospitalization.hospitalizationType.name",
                       title: "Ασφάλιση",
                       width: "90px",
                       filterable: {
                           cell: {
                               operator: "startswith",
                               template: function (args) {
                                   KendoFilter.getTemplate(args);
                               },
                               showOperators: false
                           }
                       },
                       aggregates: ["count"],
                       groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                   },
                   {
                       field: "leadHospitalization.clinicName.name",
                       title: "Κλινική",
                       width: "90px",
                       filterable: {
                           cell: {
                               operator: "startswith",
                               template: function (args) {
                                   KendoFilter.getTemplate(args);
                               },
                               showOperators: false
                           }
                       },
                       aggregates: ["count"],
                       groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                   }
               ]
            },
            {
                title: "Στοιχεία Πιθανού Ασθενή",
                columns: [
                    {
                        field: "leadHospitalization.leadCalls.prospectivePatient.firstName",
                        title: "Όνομα Πιθανού Ασθενή",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        },
                        aggregates: ["count"],
                        groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                    },
                    {
                        field: "leadHospitalization.leadCalls.prospectivePatient.lastName",
                        title: "Επώνυμο Πιθανού Ασθενή",
                        filterable: {
                            cell: {
                                operator: "startswith",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        },
                        aggregates: ["count"],
                        groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                    },
                    {
                        field: "leadHospitalization.leadCalls.prospectivePatient.age",
                        title: "Ηλικία",
                        width: "110px",
                        filterable: {
                            cell: {
                                operator: "eq",
                                template: function (args) {
                                    KendoFilter.getTemplate(args);
                                },
                                showOperators: false
                            }
                        },
                        aggregates: ["count"],
                        groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                    }
                ]
            },
            {
                field: "disease.name",
                title: "Ασθένια",
                width: "90px",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                aggregates: ["count"],
                groupHeaderTemplate: "#= typeof value !== 'undefined' ? value : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            },
            {
                field: "isClosedHospitalization",
                title: "Είδος Νοσηλείας",
                template: "#= (isClosedHospitalization == 'true') ? 'Ανοιχτή' : 'Κλειστή' #",
                width: "110px",
                filterable: {
                    cell: {
                        operator: "eq",
                        showOperators: false,
                        template: function (args) {
                            args.element.kendoDropDownList({
                                autoBind: false,
                                dataTextField: "text",
                                dataValueField: "value",
                                dataSource: new kendo.data.DataSource({
                                    data: [{text: "Ανοιχτή", value: "true"},
                                        {text: "Κλειστή", value: "false"}]
                                }),
                                valuePrimitive: true

                            })
                        }
                    }
                },
                aggregates: ["count"],
                groupHeaderTemplate: "#= (value == 'true') ? 'Είδος Νοσηλείας: Ανοιχτή' : (value == 'false') ? 'Είδος Νοσηλείας: Κλειστή' : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            },
            {
                field: "hadSurgery",
                title: "Χειρουργείο",
                template: "#= (hadSurgery == 'true') ? 'Ναι' : 'Όχι' #",
                width: "110px",
                filterable: {
                    cell: {
                        operator: "eq",
                        showOperators: false,
                        template: booleanFilterTemplate
                    }
                },
                aggregates: ["count"],
                groupHeaderTemplate: "#= (value == 'true') ? 'Χειρουργείο: Ναι' : (value == 'false') ? 'Χειρουργείο: Όχι' : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            },
            {
                field: "isFollowingHospitalization",
                title: "Συνέχεια Νοσ.",
                template: "#= (isFollowingHospitalization == 'true') ? 'Ναι' : 'Όχι' #",
                width: "110px",
                filterable: {
                    cell: {
                        operator: "eq",
                        showOperators: false,
                        template: booleanFilterTemplate
                    }
                },
                aggregates: ["count"],
                groupHeaderTemplate: "#= (value == 'true') ? 'Συνέχεια Νοσηλείας: Ναι' : (value == 'false') ? 'Συνέχεια Νοσηλείας: Όχι' : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
            }

        ];
        grid.dataSource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function (o) {
                    if (o.data.filter === undefined) { o.data.filter = { filters: [] }; }
                    o.data.filter.filters = MasterToKendoFiltersService.getMasterFilters(o.data.filter.filters, vm.masterFilters, vm.view);

                    /*angular.forEach( o.data.filter.filters, function(item) {
                        switch (item.field){
                            case "dateTime":
                                if (item.value instanceof Date) {
                                    item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                                }
                                break;
                            case "nextFollowUpDate":
                                if (item.value instanceof Date) {
                                    item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                                }
                                break;
                            case "leadHospitalizations.possibleDischargeDate":
                                if (item.value instanceof Date) {
                                    item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                                }
                                break;
                            default:
                                break;
                        }
                        // if ( item.value instanceof Date) {
                        //     item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                        // }
                    });*/

                    $http.get('/api/lead-hospitalization-diseases/all', {params: o.data})
                        .success(function (response) {
                            console.log(response);
                            o.success(response);
                        })
                        .error(function (response) {});
                }
            },
            schema: {
                data: function (data) {
                    if(data !== null && data !== undefined) {
                        data.forEach(function(item){
                            if (!item.leadHospitalization){
                                item.leadHospitalization = {
                                    sector: {},
                                    competitor: {},
                                    hospitalizationType: {},
                                    clinicName: {},
                                    leadCalls: {
                                        prospectivePatient: {},
                                        leadOffers: {},
                                        leadHospitalizations: {},
                                        callType: {}
                                    }
                                };
                            }else {
                                if (!item.leadHospitalization.sector) item.leadHospitalization.sector = {};
                                if (!item.leadHospitalization.competitor) item.leadHospitalization.competitor = {};
                                if (!item.leadHospitalization.hospitalizationType) item.leadHospitalization.hospitalizationType = {};
                                if (!item.leadHospitalization.clinicName) item.leadHospitalization.clinicName = {};
                                if (!item.leadHospitalization.leadCalls){
                                    item.leadHospitalization.leadCalls = {
                                        prospectivePatient: {},
                                        leadOffers: {},
                                        leadHospitalizations: {},
                                        callType: {}
                                    }
                                }else {
                                    if (!item.leadHospitalization.leadCalls.prospectivePatient) item.leadHospitalization.leadCalls.prospectivePatient = {};
                                    if (!item.leadHospitalization.leadCalls.leadOffers) item.leadHospitalization.leadCalls.leadOffers = {};
                                    if (!item.leadHospitalization.leadCalls.leadHospitalizations) item.leadHospitalization.leadCalls.leadHospitalizations = {};
                                    if (!item.leadHospitalization.leadCalls.callType) item.leadHospitalization.leadCalls.callType = {};
                                }
                            }
                        });
                    }
                    return data;
                },
                parse: function (data) {
                    angular.forEach(data, function (item) {
                        item.possibleDischargeDate = kendo.toString(kendo.parseDate(item.leadHospitalization.possibleDischargeDate), 'dd/MM/yyyy');
                        item.diagnosisDate = kendo.toString(kendo.parseDate(item.leadHospitalization.diagnosisDate), 'dd/MM/yyyy');
                        item.isClosedHospitalization = item.leadHospitalization.isClosedHospitalization === true ? "true" : "false";
                        item.hadSurgery = item.leadHospitalization.hadSurgery === true ? "true" : "false";
                        item.isFollowingHospitalization = item.leadHospitalization.isFollowingHospitalization === true ? "true" : "false";
                    });
                    return data;
                },
                model: {
                    id: "id",
                    fields: {
                        id: {editable: false, nullable: false},
                        diagnosisDate: { type: "date" },
                        possibleDischargeDate: { type: "date" },
                        "leadHospitalization.leadCalls.prospectivePatient.age": { type: "number" },
                    }
                }
            }
        });
        vm.mainGridOptions = {
            autoBind: false,
            dataSource: grid.dataSource,
            sortable: false,
            groupable: false,
            resizable: true,
            filterable: false,
            columns: grid.columns,
            toolbar: [
                { template: "<md-button class='md-raised md-mini md-primary' aria-label='collapseAll' ng-click='vm.collapseAll()'>Collapse All</md-button>" },
                { template: "<md-button class='md-raised md-mini md-primary' aria-label='expandAll' ng-click='vm.expandAll()'>Expand All</md-button>" },
                { name: "excel", template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                    "<span class='k-icon k-i-excel'></span>Export</md-button></div>" }
            ],
            excel: {
                allPages: true,
                fileName: "Αναφορές Κλήσεων.xlsx"
            },
            dataBound: function () { vm.masterFiltersEdited(); }
        };
    }
})();
