/**
 * Created by Kristy Kavvada on 27/2/2020.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('MedReportService', MedReportService);

    MedReportService.$inject = ['$rootScope', '$http'];

    function MedReportService($rootScope, $http) {

        return {
            getEmployeesDatasource: getEmployeesDatasource,
            setMedReport: setMedReport
        };

        function getEmployeesDatasource() {
            return new kendo.data.DataSource({
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: function (o) {
                        if (o.data.filter == undefined) o.data.filter = { filters: [] };
                        if (o.data.sort == undefined || !o.data.sort.length) o.data.sort = { "field": "account.lastName",  "dir": "asc" };
                        o.data.page = 1;
                        o.data.pageSize = 20;

                        $http.get('/api/me/employees',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response.content);
                            })
                            .error(function () {});
                    }
                },
                schema: {
                    parse: function (data) {
                        data.forEach(function (item) {
                            item.fullName = item.account.lastName + " " + item.account.firstName;
                        });
                        return data;
                    }
                }
            });
        }

        function setMedReport(value) {
            $rootScope.$broadcast('medReport:updated',value);
        }
    }
})();
