/**
 * Created by Kristy on 7/9/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .directive('applicationParameters', applicationParameters);

    applicationParameters.$inject = ['$http', 'Principal'];

    function applicationParameters($http, Principal) {

        return {
            restrict: 'A',
            link: linkFunc
        };

        function linkFunc(scope, element, attrs) {
            var authorities = attrs.hasAnyAuthority.replace(/\s+/g, '').split(',');
            var url = "/api/masterdata/application-parameters";

            var setVisible = function () {
                    element.removeClass('hidden');
                },
                setHidden = function () {
                    element.addClass('hidden');
                },
                defineVisibility = function (reset) {
                    var result;
                    if (reset) {
                        setVisible();
                    }

                    result = Principal.hasAnyAuthority(authorities);
                    if (result && reset) {
                        setVisible();
                    } else {
                        setHidden();
                    }
                };

                scope.$watch(function() {
                    return Principal.isAuthenticated();
                }, function() {
                    $http.get(url,
                        {params:{
                            page: 1,
                            pageSize: 1,
                            filter : { filters: [{field: "name", operator: "eq",  value: attrs.applicationParameters}] }}})
                        .success(function (response,status,headers) {
                            if (response.content[0].value == 1) {
                                defineVisibility(true);

                                /**
                                 *Pharmacies Tab View
                                 *Visible only when both "Visit_Pharmacies" & "Purchase_Pharmacies" parameters are true
                                 *Hidden if "Visit_Pharmacies" is set to false
                                 */

                                if ( attrs.applicationParameters == "Visit_Pharmacies" && attrs.extraApplicationParameters) {
                                    $http.get(url,
                                        {params:{
                                            page: 1,
                                            pageSize: 1,
                                            filter : { filters: [{field: "name", operator: "eq",  value: attrs.extraApplicationParameters}] }}})
                                        .success(function (response,status,headers) {
                                            var value = response.content[0].value == 1;
                                            defineVisibility(value);
                                        })
                                        .error(function (response) {
                                            console.log(response);
                                        });
                                }
                            } else {
                                defineVisibility(false);
                            }

                            var unauthorizedUsers = [ 'kksilouri', 'mathanasiou' ];
                            if (Principal.identity().$$state.value) {
                                if ( unauthorizedUsers.indexOf(Principal.identity().$$state.value.login) !== -1 && attrs.applicationParameters ==  "Approvals" ) {
                                    setHidden();
                                }
                            }

                        })
                        .error(function (response) {
                            console.log(response);
                        });

                });

        }
    }
})();
