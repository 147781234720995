(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProductCategoryProductDetailController', ProductCategoryProductDetailController);

    ProductCategoryProductDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ProductCategoryProduct', 'Product', 'ProductCategory'];

    function ProductCategoryProductDetailController($scope, $rootScope, $stateParams, entity, ProductCategoryProduct, Product, ProductCategory) {
        var vm = this;
        vm.productCategoryProduct = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:productCategoryProductUpdate', function(event, result) {
            vm.productCategoryProduct = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
