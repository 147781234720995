(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CallTypeDetailController', CallTypeDetailController);

    CallTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'CallType'];

    function CallTypeDetailController($scope, $rootScope, $stateParams, entity, CallType) {
        var vm = this;
        vm.callType = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:callTypeUpdate', function(event, result) {
            vm.callType = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
