(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SectorPositionDeleteController',SectorPositionDeleteController);

    SectorPositionDeleteController.$inject = ['$uibModalInstance', 'entity', 'SectorPosition'];

    function SectorPositionDeleteController($uibModalInstance, entity, SectorPosition) {
        var vm = this;
        vm.sectorPosition = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            SectorPosition.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
