/**
 * Created by Maria on 11/5/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminEventsController', AdminEventsController);

    AdminEventsController.$inject = ['$scope', '$state','$http', '$rootScope', '$location', '$filter', 'KendoFilter', 'CustomUtils', 'customersView'];

    function AdminEventsController ($scope, $state, $http, $rootScope, $location, $filter, KendoFilter, CustomUtils, customersView) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(67);
        vm.pageHeading = 'Επισκεψεις';
        vm.eventTarget = 'all';
        vm.customerView = customersView.data.content[0].value;

        vm.toolTipOptions = {
            filter: "td span",
            position: "top",
            content: function(e){
                var element = e.target[0];
                e.sender.popup.element.css("visibility", "hidden");

                if (element.offsetWidth < element.scrollWidth) {

                    e.sender.popup.element.css("visibility", "visible");

                    return element.innerText;
                }

                return "";
            }
        };

        function onChange(arg) {
            // Get selected cell
            var selected = this.select();
            // Get the row that the cell belongs to.
            var row = this.select().closest("tr");
            var cellIndex = selected[0].cellIndex;

            if((cellIndex === 1 || cellIndex === 2) &&
                this.dataItem(row).eventCustomerSector.sector.sectorType.sectorTypeCategory === 'hcu') {
                var customerId = this.dataItem(row).eventCustomerSector.customerSector.customer.id;
                $rootScope.$apply(function() {
                    $rootScope.selectedUser = {};
                    $rootScope.selectedUser.id = customerId;
                    $state.go('customerInfo', {id: customerId, stateFrom: 'adminEvents'});
                });
            } else if(cellIndex === 13 || cellIndex === 14) {
                var userId = this.dataItem(row).employee.id;
                $rootScope.$apply(function() {
                    $rootScope.selectedUser = {};
                    $rootScope.selectedUser.id = userId;
                    // $location.path('/employees/search').search({id:userId});
                    $state.go('userInfo', {id: userId, stateFrom: 'adminEvents'});
                });
            } else {
                //this.clearSelection(row); //this throws error
                this.saveChanges();
            }
        }

        $scope.googleMapsRedirect = function(dataItem) {
            if (dataItem.latitude != 0 && dataItem.longitude != 0) {
                window.open(
                    'http://maps.google.com/maps?q=' + dataItem.latitude + ',' + dataItem.longitude,
                    '_blank' //This is what makes it open in a new window.
                );
            }
        };

        $scope.imageChoice = function(dataItem) {

            if(dataItem.latitude == 0 && dataItem.latitude == 0){
                return $scope.image = "content/images/error_icon.png";
            }
            else{
                return $scope.image = "content/images/google_map.png";
            }
        };

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = { filters: [] };
                        }

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        if (vm.eventTarget === 'customers'){
                            o.data.filter.filters.push({
                                field: "eventCustomerSector.customerSector",
                                operator: "isnotnull",
                                value: "null"
                            });
                            o.data.filter.filters.push({
                                field: "eventCustomerSector.sector",
                                operator: "isnotnull",
                                value: "null"
                            });
                        }

                        if (vm.eventTarget === 'sectors') {
                            o.data.filter.filters.push({
                                field: "eventCustomerSector.customerSector",
                                operator: "isnull",
                                value: "null"
                            });
                            o.data.filter.filters.push({
                                field: "eventCustomerSector.sector",
                                operator: "isnotnull",
                                value: "null"
                            });
                        }

                        $http.get('/api/me/events',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (options, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        } else {
                            if (options.data.filter != undefined) {
                                angular.forEach(options.data.filter.filters, function (item) {
                                    if (item.field === "startedAt") {
                                        item.value = kendo.toString(kendo.parseDate(item.value), 'yyyy-MM-dd');
                                    }
                                });
                            }
                            return data;
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        if(data.content != undefined) {
                            data.content.forEach(function(item){

                                if (item.eventCustomerSector == null) {
                                    item.eventCustomerSector = {
                                        customerSector: {
                                            customer: {
                                                last_name: "",
                                                first_name: "",
                                                doctorSpecialty: {}
                                            },
                                            sector: {
                                                address: "",
                                                city: {
                                                    name: ""
                                                }
                                            }
                                        },
                                        sector: {
                                            address: "",
                                            city: {
                                                name: ""
                                            }
                                        },
                                        agreementValuation: {
                                            name: ""
                                        },
                                        communicationType: {
                                            name: ""
                                        }
                                    };
                                } else {
                                    if (item.eventCustomerSector.customerSector == null) {
                                        item.eventCustomerSector.customerSector = {
                                            customer: {
                                                last_name: "",
                                                first_name: "",
                                                doctorSpecialty: {}
                                            },
                                            sector: {
                                                address: "",
                                                city: {
                                                    name: ""
                                                }
                                            }
                                        };
                                    } else {
                                        if (item.eventCustomerSector.customerSector.customer == null) {
                                            item.eventCustomerSector.customerSector.customer = {doctorSpecialty: {}}
                                        } else {
                                            if (item.eventCustomerSector.customerSector.customer.doctorSpecialty == null)
                                                item.eventCustomerSector.customerSector.customer.doctorSpecialty = {}
                                        }
                                    }

                                    if (item.eventCustomerSector.agreementValuation === null) {
                                        item.eventCustomerSector.agreementValuation = {};
                                        item.eventCustomerSector.agreementValuation.name = "";
                                    }

                                    if (item.eventCustomerSector.communicationType === null) {
                                        item.eventCustomerSector.communicationType = {};
                                        item.eventCustomerSector.communicationType.name = "";
                                    }
                                }

                                if (item.eventCustomerSector.sector == null) {
                                    item.eventCustomerSector.sector = {
                                        sectorType: {}
                                    };
                                }

                                if (item.eventCustomerSector.sector.sectorType == null) {
                                    item.eventCustomerSector.sector.sectorType = {
                                        name: ""
                                    };
                                }

                                if (item.eventStatus === null) {
                                    item.eventStatus = {};
                                    item.eventStatus.name = "";
                                }

                                if (item.eventPlace === null) {
                                    item.eventPlace = {};
                                    item.eventPlace.name = "";
                                }
                                if (item.employee === null) {
                                    item.employee = { account: { lastName: ""}};
                                }else {
                                    if (item.employee.account === null) {
                                        item.employee.account = { lastName: "" };
                                    }
                                }

                                if(item.eventCategoryType === null){
                                    item.eventCategoryType = {};
                                    if(item.eventCategory === null){
                                        item.eventCategory = {};
                                        item.eventCategory.name = "";
                                    }
                                }else{
                                    if(item.eventCategory === null){
                                        item.eventCategory = {};
                                        item.eventCategory.name = "";
                                    }
                                }

                            });
                        }

                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            startedAt: { type: 'date'}
                        }
                    }
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                pageSize: 100
            }),
            sortable: true,
            pageable: {
                messages: {
                    display: "{0} - {1} από {2} Επισκέψεις", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                    empty: "Δεν βρέθηκαν Επισκέψεις",
                    page: "Σελίδα",
                    allPages: "Όλα",
                    of: "από {0}", // {0} is total amount of pages.
                    itemsPerPage: "εγγραφές ανά σελίδα",
                    first: "Πήγαινε στη πρώτη σελίδα",
                    previous: "Πήγαινε στη τελευταία σελίδα",
                    next: "Πήγαινε στην επόμενη σελίδα",
                    last: "Πήγαινε στη τελευταία σελίδα",
                    refresh: "Ανανέωση"
                }
            },
            noRecords: {
                template: "Δεν βρέθηκαν Επισκέψεις"
            },
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    title: "Επαγγελματίας Υγείας",
                    columns: [
                        {field: "eventCustomerSector.customerSector.customer.lastName", title: "Επώνυμο Επαγγελματία Υγείας",attributes: {
                            class: "#= eventCustomerSector.sector.sectorType.sectorTypeCategory == 'hcu' ? 'link-cell link-cell-color' : ''#"
                        },
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.customerSector.customer.firstName", title: "Όνομα Επαγγελματία Υγείας",attributes: {
                            class: "#= eventCustomerSector.sector.sectorType.sectorTypeCategory == 'hcu' ? 'link-cell link-cell-color' : ''#"
                        },
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {
                            field: "eventCustomerSector.customerSector.customer.doctorSpecialty.name",
                            title: "Ειδικοτητα 1η",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            },
                            width: 155
                        }
                    ]
                },
                {field: "startedAt", title: "Ημερομηνία Επίσκεψης", template: "#= startedAt ? kendo.toString(kendo.parseDate(startedAt), 'dddd, dd/MM/yyyy HH:mm') : ''#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    }
                },
                {field: "eventStatus.name", title: "Κατάσταση Επίσκεψης",attributes: {
                    class: "#=eventStatus.id == '1' ? 'red' : 'green' #"
                },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                // {field: "eventCategoryType.eventCategory.name", title: "Κατηγορία Επίσκεψης",
                //     filterable: {
                //         cell: {
                //             operator: "startswith",
                //             template: function (args) {
                //                 KendoFilter.getTemplate(args);
                //             },
                //             showOperators: false
                //         }
                //     }
                // },
                {field: "eventCustomerSector.communicationType.name", title: "Τύπος Επικοινωνίας",
                    hidden: vm.customerView !== '3',
                    template: "#= (eventCustomerSector.communicationType == null || eventCustomerSector.communicationType.name == null) ? ' ' " +
                        ": eventCustomerSector.communicationType.name #",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 100
                },
                {field: "eventCustomerSector.agreementValuation.name", title: "Αξιολόγηση", template: "#= (eventCustomerSector.agreementValuation == null || eventCustomerSector.agreementValuation.name == null) ? ' ' : eventCustomerSector.agreementValuation.name #",attributes: {
                    class: "#=eventCustomerSector.agreementValuation.id == '1' ? 'red' : eventCustomerSector.agreementValuation.id == '2' ? 'orange' : eventCustomerSector.agreementValuation.id == '3' ? 'purple' : eventCustomerSector.agreementValuation.id == '4' ? 'green' : 'black' #"
                },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 100
                },
                {
                    command:[
                        {name:"map", template: "<input type='image' ng-src={{imageChoice(dataItem.eventCustomerSector)}} ng-click='googleMapsRedirect(dataItem.eventCustomerSector)' style='width: 30px;height: 30px;'/>"}
                    ],
                    attributes: { class: "#= 'link-cell-color' #" },
                    title: "Τοποθεσία",
                    width:90
                },
                {
                    title: "Μονάδα Υγείας",
                    columns: [
                        {field: "eventCustomerSector.sector.sectorType.name", title: "Τύπος",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.sector.name",
                            template: "<span>#: eventCustomerSector.sector.name # </span>", title: "Όνομα",
                            filterable: {
                                cell: {
                                    operator: "like",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.sector.address", title: "Διεύθυνση",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "eventCustomerSector.sector.cityName", title: "Πόλη",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            },
                            width:120
                        },
                        {field: "eventCustomerSector.sector.prefectureName", title: "Νομός",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            },
                            width:120
                        }
                    ]
                },
                //{field: "customer.address", title: "Διεύθυνση",
                //    filterable: {
                //        cell: {
                //            operator: "startswith",
                //            showOperators: false
                //        }
                //    }
                //},
                //{field: "customer.city.name", title: "Πόλη",
                //    filterable: {
                //        cell: {
                //            operator: "startswith",
                //            showOperators: false
                //        }
                //    }
                //},
                //{field: "customer.city.county.name", title: "Νομός",
                //    filterable: {
                //        cell: {
                //            operator: "startswith",
                //            showOperators: false
                //        }
                //    }
                //},
                // {field: "eventCustomerSector.agreedPercent", title: "Ποσοστό Συχνότητας", template: "#= (eventCustomerSector.agreedPercent == null) ? ' ' : kendo.format('{0:p0}', eventCustomerSector.agreedPercent / 100)#",
                //     filterable: {
                //         cell: {
                //             operator: "startswith",
                //             template: function (args) {
                //                 KendoFilter.getTemplate(args);
                //             },
                //             showOperators: false
                //         }
                //     }
                // },
                {
                    title: "Ιατρικός Επισκέπτης",
                    columns: [
                        {field: "employee.account.firstName", title: "Όνομα Ιατρικού Επισκέπτη",attributes: {
                            class: "#= 'link-cell link-cell-color' #"
                        },
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {field: "employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",attributes: {
                            class: "#= 'link-cell link-cell-color' #"
                        },
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        }
                    ]
                },
                // {field: "description", title: "Σημειώσεις Ραντεβού",
                //     filterable: {
                //         cell: {
                //             operator: "like",
                //             template: function (args) {
                //                 KendoFilter.getTemplate(args);
                //             },
                //             showOperators: false
                //         }
                //     }
                // }
            ],
            editable: "inline",
            height: vm.contentHeight,
            selectable: "cell",
            change: onChange
        };

        vm.detailedGridOptions = function (dataItem) {
            if (dataItem != undefined) {
                return {
                    dataSource: {
                        batch: true,
                        transport: {
                            read: function(o) {
                                var url = '/api/me/agreements';
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }
                                o.data.filter.filters.push({ "field": "event.id", "operator": "eq", "value": dataItem.id});
                                $http.get(url, {params: o.data })
                                    .success(function (response,status,headers) {
                                        vm.total = headers()['x-total-count'];
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        },
                        schema: {
                            data: function (data) {
                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: {editable: false, nullable: true},
                                    name: {validation: {required: true, validation: {required: true, min: 3}}}
                                }
                            }
                        },
                        serverPaging: true,
                        pageSize: 15
                    },
                    pageable: true,
                    columns: [
                        {field: "product.name", title: "Προϊόν"},
                        {field: "description", title: "Περιγραφή Αξιολόγησης", template: "#= description == null ? ' ' : description #"},
                        {field: "promisedQuantity", title: "Προβλεπόμενη Συχνότητα"},
                        {field: "priority", title: "Σειρά προτεραιότητας"}
                    ]
                };
            }
        };

        vm.onEventTargetChange = function () {
            vm.mainGridOptions.dataSource.read();
        };
    }
})();
