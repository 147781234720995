/**
 * Created by Kristy on 10/3/2017.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MDTradePoliciesViewRulesController', MDTradePoliciesViewRulesController);

    MDTradePoliciesViewRulesController.$inject = ['$http','$state', '$stateParams', '$filter', 'KendoFilter', 'KendoGrid'];

    function MDTradePoliciesViewRulesController ($http, $state, $stateParams, $filter, KendoFilter, KendoGrid) {
        var vm = this;

        vm.selectedTradePolicyId = $stateParams.id;
        vm.ruleTypePurchasePrice = $filter('translate')('masterData.tradePolicyRules.purchasePrice');
        vm.ruleTypeQuantityPerRow = $filter('translate')('masterData.tradePolicyRules.quantityPerRow');
        vm.ruleTypeDataSource = new kendo.data.DataSource({
            data: [{ value: "purchasePrice", text: vm.ruleTypePurchasePrice}, { value: "quantityPerRow", text: vm.ruleTypeQuantityPerRow}]
        });
        vm.effectTypeDataSource = new kendo.data.DataSource({
            data: [{ value: "discount",
                    text: $filter('translate')('masterData.tradePolicyRuleEffects.tradePolicyRuleEffectTypeEnum.discount')},
                { value: "present",
                    text: $filter('translate')('masterData.tradePolicyRuleEffects.tradePolicyRuleEffectTypeEnum.present')},
                { value: "discountPresent",
                    text: $filter('translate')('masterData.tradePolicyRuleEffects.tradePolicyRuleEffectTypeEnum.discountPresent')}]
        });
        vm.effectTypeDataSourceForQuantityPerRow = new kendo.data.DataSource({
            data: [
                { value: "present", text: $filter('translate')('masterData.tradePolicyRuleEffects.tradePolicyRuleEffectTypeEnum.present')},
                { value: "discountInRow", text: $filter('translate')('masterData.tradePolicyRuleEffects.tradePolicyRuleEffectTypeEnum.discountInRow')}
            ]
        });
        vm.severityDataSource = new kendo.data.DataSource({
            data: [{ value: "recommend",
                text: $filter('translate')('masterData.tradePolicyRuleEffects.tradePolicyRuleEffectSeverityEnum.recommend')},
                { value: "strict",
                    text: $filter('translate')('masterData.tradePolicyRuleEffects.tradePolicyRuleEffectSeverityEnum.strict')},
                { value: "limit",
                    text: $filter('translate')('masterData.tradePolicyRuleEffects.tradePolicyRuleEffectSeverityEnum.limit')}]
        });
        vm.presentListDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 50;
                    $http.get('/api/present-lists/search',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });
        vm.productDataSource = new kendo.data.DataSource({
            serverFiltering: false,
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get('/api/masterdata/products',
                        {params: o.data})
                        .success(function (response,status,headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        function ruleTypeTemplate(container, options) {
            if (container.ruleType == "purchasePrice") {
                return vm.ruleTypePurchasePrice;
            }else if (container.ruleType == "quantityPerRow") {
                return vm.ruleTypeQuantityPerRow;
            }
            return "";
        }

        function effectTypeTemplate(container, options) {
            switch (container.effectType) {
                case "discount":
                    return $filter('translate')('masterData.tradePolicyRuleEffects.tradePolicyRuleEffectTypeEnum.discount');
                    break;
                case "present":
                    return $filter('translate')('masterData.tradePolicyRuleEffects.tradePolicyRuleEffectTypeEnum.present');
                    break;
                case "discountPresent":
                    return $filter('translate')('masterData.tradePolicyRuleEffects.tradePolicyRuleEffectTypeEnum.discountPresent');
                    break;
                case "discountInRow":
                    return $filter('translate')('masterData.tradePolicyRuleEffects.tradePolicyRuleEffectTypeEnum.discountInRow');
                    break;
                default:
                    return "";
                    break;
            }
        }

        function severityTemplate(container, options) {
            switch (container.severity) {
                case "recommend":
                    return $filter('translate')('masterData.tradePolicyRuleEffects.tradePolicyRuleEffectSeverityEnum.recommend');
                    break;
                case "strict":
                    return $filter('translate')('masterData.tradePolicyRuleEffects.tradePolicyRuleEffectSeverityEnum.strict');
                    break;
                case "limit":
                    return $filter('translate')('masterData.tradePolicyRuleEffects.tradePolicyRuleEffectSeverityEnum.limit');
                    break;
                default:
                    return "";
                    break
            }
        }

        vm.tradePolicyRulesGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [{ "field": "tradePolicy.id", "operator": "eq",
                                "value":  vm.selectedTradePolicyId }]
                        );

                        $http.get('/api/trade-policy-rules/search',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    create: function(o) {
                        var data = o.data.models[0];
                        data.tradePolicy = { id: vm.selectedTradePolicyId };
                        $http.post('/api/trade-policy-rules', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#tradePolicyRulesGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    update: function(o) {
                        var data = o.data.models[0];
                        $http.put('/api/trade-policy-rules', data)
                            .success(function (response) {
                                o.success(response);
                                angular.element("#tradePolicyRulesGrid").data("kendoGrid").dataSource.read();
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            ruleType: { validation: { required: true , validation: { required: true, min: 3}}},
                            fromValue: { type: 'number'},
                            toValue: { type: 'number' }
                        }
                    }
                },
                serverFiltering: true,
                serverSorting: true,
                serverPaging: true,
                pageSize: 20
            }),
            toolbar: [{name :"create", text: "Προσθήκη Νέας εγγραφής"},
                { template: "<div class='right'><label>Σύνολο: {{vm.tradePolicyRulesGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }],
            sortable: true,
            pageable: true,
            selectable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "ruleType", title: "Τύπος Κανόνα",
                    template: ruleTypeTemplate,
                    editor: function(container, options) {
                        angular.element('<select id="day-time" data-bind="value:ruleType"></select>')
                            .appendTo(container)
                            .kendoComboBox({
                                dataSource: vm.ruleTypeDataSource,
                                filter: "eq",
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value"
                            });
                    },
                    filterable: false
                },
                {
                    field: "fromValue", title: "Από (Αξία ή Ποσότητα)",
                    filterable: {
                        cell: {
                            operator: "eq",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "toValue", title: "Εώς (Αξία ή Ποσότητα)",
                    filterable: {
                        cell: {
                            operator: "eq",
                            showOperators: false
                        }
                    }
                },
                {
                    command:[
                        {name:"edit", text: "Επεξεργασία"}
                    ],
                    text: "Επεξεργασία",
                    title: "&nbsp;"
                }
            ],
            edit: function() { KendoGrid.defaultEditPopup(this._editContainer); },
            editable: "popup"
        };

        vm.detailedGridOptions = function (dataItem) {
            console.log(dataItem);
            if (dataItem !== undefined && dataItem.ruleType !== undefined) {
                switch (dataItem.ruleType){
                    case "purchasePrice":
                        return {
                            dataSource: {
                                batch: true,
                                transport: {
                                    read: function(o) {
                                        if (o.data.filter == undefined) {
                                            o.data.filter = { filters: [] };
                                        }
                                        o.data.filter.filters.push({field: "tradePolicyRule.id", operator: "eq", value: dataItem.id});
                                        $http.get('/api/trade-policy-rule-effects/search',
                                            {params: o.data})
                                            .success(function (response,status,headers) {
                                                o.success(response);
                                            })
                                            .error(function (response) {
                                                console.log(response);
                                            });
                                    },
                                    create: function(o) {
                                        var data = o.data.models[0];
                                        data.tradePolicyRule = {id : dataItem.id};

                                        if (!data.presentList.id) data.presentList = null;
                                        $http.post('/api/trade-policy-rule-effects', data)
                                            .success(function (response) {
                                                o.success(response);
                                                angular.element("#detailGrid").data("kendoGrid").dataSource.read();
                                            })
                                            .error(function (response) {
                                                console.log(response);
                                            });
                                    },
                                    update: function(o) {
                                        var data = o.data.models[0];

                                        if (!data.presentList.id) data.presentList = null;
                                        $http.put('/api/trade-policy-rule-effects', data)
                                            .success(function (response) {
                                                o.success(response);
                                                angular.element("#detailGrid").data("kendoGrid").dataSource.read();
                                            })
                                            .error(function (response) {
                                                console.log(response);
                                            });
                                    }
                                },
                                schema: {
                                    data: function (data) {
                                        if (data.content) {
                                            angular.forEach(data.content, function(item) {
                                                if (!item.presentList) item.presentList =  {};
                                            });
                                        }
                                        return data.content;
                                    },
                                    total: function (data) {
                                        return data.totalEntries;
                                    },
                                    model: {
                                        id: "id",
                                        fields: {
                                            id: { editable: false, nullable: true },
                                            effectType: { validation: { required: true , validation: { required: true, min: 3}} },
                                            discountPercent: { editable: true, type: "number", nullable: true },
                                            presentList: { defaultValue: {} }
                                        }
                                    }
                                },
                                serverFiltering: true,
                                serverSorting: true,
                                serverPaging: true,
                                pageSize: 15
                            },
                            toolbar: [{name :"create", text: "Προσθήκη Νέας εγγραφής"}],
                            pageable: true,
                            sortable: true,
                            filterable: {
                                mode: "row"
                            },
                            columns: [
                                {
                                    field: "effectType", title: "Επίδραση Εμπορικής Πολιτικής",
                                    template: effectTypeTemplate,
                                    editor: function(container, options) {
                                        angular.element('<select id="effectType" data-bind="value:effectType"></select>')
                                            .appendTo(container)
                                            .kendoComboBox({
                                                dataSource: vm.effectTypeDataSource,
                                                filter: "eq",
                                                valuePrimitive: true,
                                                dataTextField: "text",
                                                dataValueField: "value",
                                                change: function () {
                                                    var value = this.value();
                                                    if (value) {
                                                        var discountPercent = angular.element("#discountPercent").data("kendoNumericTextBox");
                                                        var presentList = angular.element("#presentList").data("kendoComboBox");
                                                        var isPresent = value == "present";
                                                        var isDiscount = value == "discount";

                                                        discountPercent.enable(!isPresent);
                                                        if (isPresent) {
                                                            discountPercent.value("");
                                                            discountPercent.trigger("change");
                                                        }

                                                        presentList.enable(!isDiscount);
                                                        if (isDiscount) {
                                                            presentList.value("");
                                                            presentList.trigger("change");
                                                        }
                                                    }
                                                }
                                            });
                                    },
                                    filterable: false
                                },
                                {
                                    field: "severity", title: "Τρόπος Επίδρασης",
                                    template: severityTemplate,
                                    editor: function(container, options) {
                                        angular.element('<select id="day-time" data-bind="value:severity"></select>')
                                            .appendTo(container)
                                            .kendoComboBox({
                                                dataSource: vm.severityDataSource,
                                                filter: "eq",
                                                valuePrimitive: true,
                                                dataTextField: "text",
                                                dataValueField: "value"
                                            });
                                    },
                                    filterable: false
                                    // filterable: {
                                    //     cell: {
                                    //         operator: "startswith",
                                    //         showOperators: false,
                                    //         template: function (args) {
                                    //             args.element.kendoDropDownList({
                                    //                 autoBind:false,
                                    //                 dataTextField: "text",
                                    //                 dataValueField: "value",
                                    //                 dataSource: severityDataSource,
                                    //                 valuePrimitive: true
                                    //
                                    //             })
                                    //         }
                                    //     }
                                    // }
                                },
                                {
                                    field: "discountPercent", title: "Έκπτωση Παραστατικού",
                                    template: "#= (discountPercent == null) ? '' : kendo.format('{0:p0}', discountPercent / 100)#",
                                    editor: function(container,options){
                                        angular.element("<input id='discountPercent' data-bind='value: discountPercent'>")
                                            .appendTo(container)
                                            .kendoNumericTextBox({ format:"{0:#\\%}", min: 0 });
                                    },
                                    filterable: {
                                        cell: {
                                            operator: "eq",
                                            template: function (args) {
                                                args.element.kendoNumericTextBox({
                                                    format: "{0:#\\%}"
                                                });
                                            },
                                            showOperators: false
                                        }
                                    }
                                },
                                {
                                    field: "presentList.code", title: "Λιστα Δώρων",
                                    editor: function(container, options) {
                                        angular.element('<select id="presentList" data-bind="value:presentList.id"></select>')
                                            .appendTo(container)
                                            .kendoComboBox({
                                                dataSource: vm.presentListDataSource,
                                                filter: "eq",
                                                valuePrimitive: true,
                                                dataTextField: "code",
                                                dataValueField: "id"
                                            });
                                    },
                                    filterable: {
                                        cell: {
                                            operator: "startswith",
                                            template: function (args) {
                                                KendoFilter.getTemplate(args);
                                            },
                                            showOperators: false
                                        }
                                    }
                                },
                                {
                                    command:[ {name:"edit", text: "Επεξεργασία"}],
                                    title: "&nbsp;"
                                }
                            ],
                            editable: "popup",
                            edit: function(e) {
                                if (e.model.effectType == "present") {
                                    var discountPercent = angular.element("#discountPercent").data("kendoNumericTextBox");
                                    discountPercent.enable(false);
                                }

                                if (e.model.effectType == "discount") {
                                    var presentList = angular.element("#presentList").data("kendoComboBox");
                                    presentList.enable(false);
                                }

                                KendoGrid.defaultEditPopup(this._editContainer);
                            }
                        };
                        break;
                    case "quantityPerRow":
                        return {
                            dataSource: {
                                batch: true,
                                transport: {
                                    read: function(o) {
                                        if (o.data.filter == undefined) {
                                            o.data.filter = { filters: [] };
                                        }
                                        o.data.filter.filters.push({field: "tradePolicyRule.id", operator: "eq", value: dataItem.id});
                                        $http.get('/api/trade-policy-rule-effects/search',
                                            {params: o.data})
                                            .success(function (response,status,headers) {
                                                console.log(response);
                                                o.success(response);
                                            })
                                            .error(function (response) {
                                                console.log(response);
                                            });
                                    },
                                    create: function(o) {
                                        var data = o.data.models[0];
                                        data.tradePolicyRule = {id : dataItem.id};

                                        if (!data.presentList.id) data.presentList = null;
                                        $http.post('/api/trade-policy-rule-effects', data)
                                            .success(function (response) {
                                                o.success(response);
                                                angular.element("#detailGrid").data("kendoGrid").dataSource.read();
                                            })
                                            .error(function (response) {
                                                console.log(response);
                                            });
                                    },
                                    update: function(o) {
                                        var data = o.data.models[0];

                                        if (!data.presentList.id) data.presentList = null;
                                        $http.put('/api/trade-policy-rule-effects', data)
                                            .success(function (response) {
                                                o.success(response);
                                                angular.element("#detailGrid").data("kendoGrid").dataSource.read();
                                            })
                                            .error(function (response) {
                                                console.log(response);
                                            });
                                    }
                                },
                                schema: {
                                    data: function (data) {
                                        if (data.content) {
                                            angular.forEach(data.content, function(item) {
                                                if (!item.presentList) item.presentList =  {};
                                                if (!item.product) item.product = {};
                                            });
                                        }
                                        return data.content;
                                    },
                                    total: function (data) {
                                        return data.totalEntries;
                                    },
                                    model: {
                                        id: "id",
                                        fields: {
                                            id: { editable: false, nullable: true },
                                            effectType: { validation: { required: true , validation: { required: true, min: 3}} },
                                            discountPercent: { editable: true, type: "number", nullable: true },
                                            presentList: { defaultValue: {} },
                                            product: { defaultValue: {} }
                                        }
                                    }
                                },
                                serverFiltering: true,
                                serverSorting: true,
                                serverPaging: true,
                                pageSize: 15
                            },
                            toolbar: [{name :"create", text: "Προσθήκη Νέας εγγραφής"}],
                            pageable: true,
                            sortable: true,
                            filterable: {
                                mode: "row"
                            },
                            columns: [
                                {
                                    field: "effectType", title: "Επίδραση Εμπορικής Πολιτικής",
                                    template: effectTypeTemplate,
                                    editor: function(container, options) {
                                        angular.element('<select id="effectType" data-bind="value:effectType"></select>')
                                            .appendTo(container)
                                            .kendoComboBox({
                                                dataSource: vm.effectTypeDataSourceForQuantityPerRow,
                                                filter: "eq",
                                                valuePrimitive: true,
                                                dataTextField: "text",
                                                dataValueField: "value",
                                                change: function () {
                                                    var value = this.value();
                                                    console.log(value);
                                                    if (value) {
                                                        var discountPercent = angular.element("#discountPercent").data("kendoNumericTextBox");
                                                        var presentList = angular.element("#presentList").data("kendoComboBox");
                                                        var isPresent = value == "present";
                                                        var isDiscount = value == "discountInRow";

                                                        discountPercent.enable(!isPresent);
                                                        if (isPresent) {
                                                            discountPercent.value("");
                                                            discountPercent.trigger("change");
                                                        }

                                                        presentList.enable(!isDiscount);
                                                        if (isDiscount) {
                                                            presentList.value("");
                                                            presentList.trigger("change");
                                                        }
                                                    }
                                                }
                                            });
                                    },
                                    filterable: false
                                },
                                {
                                    field: "severity", title: "Τρόπος Επίδρασης",
                                    template: severityTemplate,
                                    editor: function(container, options) {
                                        angular.element('<select id="day-time" data-bind="value:severity"></select>')
                                            .appendTo(container)
                                            .kendoComboBox({
                                                dataSource: vm.severityDataSource,
                                                filter: "eq",
                                                valuePrimitive: true,
                                                dataTextField: "text",
                                                dataValueField: "value"
                                            });
                                    },
                                    filterable: false
                                    // filterable: {
                                    //     cell: {
                                    //         operator: "startswith",
                                    //         showOperators: false,
                                    //         template: function (args) {
                                    //             args.element.kendoDropDownList({
                                    //                 autoBind:false,
                                    //                 dataTextField: "text",
                                    //                 dataValueField: "value",
                                    //                 dataSource: severityDataSource,
                                    //                 valuePrimitive: true
                                    //
                                    //             })
                                    //         }
                                    //     }
                                    // }
                                },
                                {
                                    field: "discountPercent", title: "Έκπτωση Παραστατικού",
                                    template: "#= (discountPercent == null) ? '' : kendo.format('{0:p0}', discountPercent / 100)#",
                                    editor: function(container,options){
                                        angular.element("<input id='discountPercent' data-bind='value: discountPercent'>")
                                            .appendTo(container)
                                            .kendoNumericTextBox({ format:"{0:#\\%}", min: 0 });
                                    },
                                    filterable: {
                                        cell: {
                                            operator: "eq",
                                            template: function (args) {
                                                args.element.kendoNumericTextBox({
                                                    format: "{0:#\\%}"
                                                });
                                            },
                                            showOperators: false
                                        }
                                    }
                                },
                                {
                                    field: "presentList.code", title: "Λιστα Δώρων",
                                    editor: function(container, options) {
                                        angular.element('<select id="presentList" data-bind="value:presentList.id"></select>')
                                            .appendTo(container)
                                            .kendoComboBox({
                                                dataSource: vm.presentListDataSource,
                                                filter: "eq",
                                                valuePrimitive: true,
                                                dataTextField: "code",
                                                dataValueField: "id"
                                            });
                                    },
                                    filterable: {
                                        cell: {
                                            operator: "startswith",
                                            template: function (args) {
                                                KendoFilter.getTemplate(args);
                                            },
                                            showOperators: false
                                        }
                                    }
                                },
                                {
                                    field: "product.name", title: "Για το προιόν",
                                    editor: function(container, options) {
                                        angular.element('<select id="product" data-bind="value:product.id"></select>')
                                            .appendTo(container)
                                            .kendoComboBox({
                                                dataSource: vm.productDataSource,
                                                filter: "contains",
                                                valuePrimitive: true,
                                                dataTextField: "name",
                                                dataValueField: "id"
                                            });
                                    },
                                    filterable: {
                                        cell: {
                                            operator: "startswith",
                                            template: function (args) {
                                                KendoFilter.getTemplate(args);
                                            },
                                            showOperators: false
                                        }
                                    }
                                },
                                {
                                    command:[ {name:"edit", text: "Επεξεργασία"}],
                                    title: "&nbsp;"
                                }
                            ],
                            editable: "popup",
                            edit: function(e) {
                                if (e.model.effectType == "present") {
                                    var discountPercent = angular.element("#discountPercent").data("kendoNumericTextBox");
                                    discountPercent.enable(false);
                                }

                                if (e.model.effectType == "discount") {
                                    var presentList = angular.element("#presentList").data("kendoComboBox");
                                    presentList.enable(false);
                                }

                                KendoGrid.defaultEditPopup(this._editContainer);
                            }
                        };
                        break;
                }
            }
        };
    }

})();
