(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('TempCustomerDetailController', TempCustomerDetailController);

    TempCustomerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TempCustomer', 'DoctorSpecialty', 'CustomerCategory', 'CustomerSector', 'CustomerTitle', 'CustomerSubCategory', 'Customer', 'TempCustomerSector', 'Employee', 'CustomerType', 'Collaboration'];

    function TempCustomerDetailController($scope, $rootScope, $stateParams, entity, TempCustomer, DoctorSpecialty, CustomerCategory, CustomerSector, CustomerTitle, CustomerSubCategory, Customer, TempCustomerSector, Employee, CustomerType, Collaboration) {
        var vm = this;
        vm.tempCustomer = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:tempCustomerUpdate', function(event, result) {
            vm.tempCustomer = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
