/**
 * Created by Teo on 18/01/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('CustomDateUtils', CustomDateUtils);

    CustomDateUtils.$inject = [];

    function CustomDateUtils () {

        var service = {
            toServerDateFormat : toServerDateFormat,
            fromServerDateFormat : fromServerDateFormat,
            getDiffInMinutes : getDiffInMinutes,
            getDateTime : getDateTime,
            numberToMonth : numberToMonth
        };

        return service;

        /**
         * Convert a date with hours and minutes in
         * server's ZonedDateTime format.
         * @param date Should be in 'YYYY-MM-DD' format
         * @param hours Given hours, number
         * @param minutes Given minutes, number
         */
        function toServerDateFormat(date, hours, minutes){
            var mDate;

            if (hours < 10){
                hours = "0" + hours;
            }

            if (minutes < 10){
                minutes = "0" + minutes;
            }

            mDate = date + "T" + hours + ":" + minutes + ":00.000Z";
            return mDate;
        }

        /**
         * Convert server ZonedDateTime date object to Date and Time
         * @param serverDate A Date object in "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'" format
         */
        function fromServerDateFormat(serverDate) {
            serverDate.setHours(serverDate.getHours() - 2);
            return serverDate;
        }

        /**
         * Get a difference between 2 Dates in minutes.
         * @param date1 Should be a Date object
         * @param date2 Should be a Date object
         */
        function getDiffInMinutes(date1, date2){
            var result;
            var bDate, sDate;

            if (date1 > date2){
                bDate = date1;
                sDate = date2;
            }else if (date1 < date2){
                bDate = date2;
                sDate = date1;
            }else {
                return 0;
            }

            var diff = bDate.getTime() - sDate.getTime();
            result = Math.round(diff / 60000);

            return result;
        }

        /**
         * Merge date and time in a Date object. This is useful
         * when you have 1 control for date and 1 control for time
         * and API wants a ZonedDateTime object.
         * @param date The actual date. Should be Date object
         * @param time The actual time. Should be Date object
         * @returns {Date} Date with proper time in a Date object
         */
        function getDateTime(date, time) {
            var dateTime = new Date(date);
            dateTime.setHours(time.getHours());
            dateTime.setMinutes(time.getMinutes());
            dateTime.setSeconds(time.getSeconds());
            dateTime.setMilliseconds(time.getMilliseconds());
            return dateTime;
        }

        function numberToMonth(number) {
            switch (number){
                case 0:
                    return "Ιανουαρίος";
                case 1:
                    return "Φεβρουάριος";
                case 2:
                    return "Μάρτιος";
                case 3:
                    return "Απρίλιος";
                case 4:
                    return "Μάιος";
                case 5:
                    return "Ιούνιος";
                case 6:
                    return "Ιούλιος";
                case 7:
                    return "Αύγουστος";
                case 8:
                    return "Σεπτέμβριος";
                case 9:
                    return "Οκτώβριος";
                case 10:
                    return "Νοέμβριος";
                case 11:
                    return "Δεκέμβριος";
                default:
                    return "";
            }
        }
    }

})();
