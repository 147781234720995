/**
 * Created by gmogas on 31/3/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProductLotMassController', ProductLotMassController);

    ProductLotMassController.$inject = ['$scope', '$timeout', '$http', '$filter', 'ApplicationParameters', 'KendoNotifications'];

    function ProductLotMassController ($scope, $timeout, $http, $filter, ApplicationParameters, KendoNotifications) {
        var vm = this;
        var ALLOWED_EXTENSIONS = [".xlsx"];
        ApplicationParameters.hasModule('Purchase_Pharmacies', true);
        //material design to kendo upload button
        $timeout(function () {
            var uploadButton = $(".form-group .k-upload-button");
            uploadButton.removeClass("k-button");
            uploadButton.addClass("md-raised md-primary md-button");
            $(".form-group .k-widget.k-upload").addClass("upload-btn-background");
            uploadButton.prepend($('<md-icon ng-bind="&quot;file_upload&quot;" class="ng-binding material-icons">file_upload</md-icon>'));

        },100);

        vm.onSelect = function(e) {
            var extension = e.files[0].extension.toLowerCase();
            if (ALLOWED_EXTENSIONS.indexOf(extension) == -1) {
                alert("Please, select a supported file format");
                e.preventDefault();
            }
            // Load the converted document into the spreadsheet
            var spreadsheet = $("#spreadsheet").data("kendoSpreadsheet");
            spreadsheet.fromFile(e.files[0].rawFile);
        };

        vm.save = function () {
            var spreadsheet = $("#spreadsheet").data("kendoSpreadsheet");
            var spreadsheetData = spreadsheet.toJSON();
            var data = [];
            // extract data from json
            spreadsheetData.sheets[0].rows.forEach(function (row) {
                // prevent getting the header row
                if (row.index != 0) {
                    var productLot = {};
                    // starting to extract the objects
                    row.cells.forEach(function (cell) {
                        switch(cell.index) {
                            case 0:
                                productLot.barcode = cell.value;
                                break;
                            case 2:
                                productLot.referenceNumber = cell.value;
                                break;
                            case 3:
                                productLot.expirationMonth = cell.value;
                                break;
                            case 5:
                                productLot.fromIdNumber = cell.value;
                                break;
                            case 6:
                                productLot.toIdNumber = cell.value;
                                break;
                            case 7:
                                var rd = new Date('1900-01-01');
                                rd.setDate(rd.getDate() + cell.value -2);
                                productLot.registrationDate = rd.getDate() + '/' + (rd.getMonth() + 1) + '/' + rd.getFullYear();
                                break;
                            default:
                                break;
                        }
                    });
                    data.push(productLot);
                }
            });

            var req = {
                method: 'POST',
                url: 'api/product-lots/import',
                data: data
            };

            $http(req).then(function(){
                $scope.notification.show("Το ανέβασμα του αρχείου ολοκληρώθηκε με επιτυχία", "success");
            }, function(response){
                var centered = $("#centeredNotification").kendoNotification({
                    stacking: "down",
                    autoHideAfter: 0,
                    show: KendoNotifications.onShow,
                    button: true,
                    hideOnClick: false,
                    width: 500,
                    height: 250
                }).data("kendoNotification");

                centered.show(' ' + $filter('translate')('masterData.messages.error.'
                        + response.data.params[0] + ' ') + ': '  + response.data.params[1], "error"); //.split(" ")[0]
            });
        };

    }
})();
