/**
 * Created by Kristy on 31/8/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PendingChangesController', PendingChangesController);

    PendingChangesController.$inject = ['$http', '$timeout', '$state', '$stateParams','$filter', 'KendoNotifications', 'KendoGrid', 'Approvals', 'KendoFilter'];

    function PendingChangesController ($http, $timeout, $state, $stateParams, $filter, KendoNotifications, KendoGrid, Approvals, KendoFilter) {
        var vm = this;
        vm.statusChanged = '';
        vm.saw = true;
        vm.approvals = Approvals;
        Approvals.setSelectedList([]);
        vm.massSelect = true;
        vm.kendoGrid = KendoGrid;
        vm.sectorType = null;

        var notification = angular.element("#notification").kendoNotification({
            autoHideAfter: 7000,
            allowHideAfter: 1000,
            button: true,
            hideOnClick: false
        }).data("kendoNotification");

        vm.changeStatus = function(e) {
            e.preventDefault();
            // e.target is the DOM element representing the button
            var tr = $(e.target).closest("tr"); // get the current table row (tr)
            // get the data bound to the current table row
            var change = this.dataItem(tr);

            $http.put('api/changes/' + e.data.commandName + '-one', change)
                .success(function () {
                    switch (e.data.commandName) {
                        case "approve":
                            notification.success("Η αλλαγή εγκρίθηκε με επιτυχία!");
                            break;
                        case "reject":
                            notification.success("Η αλλαγή απορριφθηκε με επιτυχία!");
                            break
                    }
                    vm.statusChanged = 'statusChanged';
                    vm.pendingGridOptions.dataSource.read();
                })
                .error(function (response) {
                    console.log(response);
                    notification.error("Η ενημέρωση των στοιχείων απέτυχε! " + $filter('translate')('global.messages.error.' + response.params[0]));
                });

            //clear selected list
            vm.statusChanged = '';
            Approvals.setSelectedList([]);
        };

        vm.approveRejectSelected = function(action) {
            var selectedList = Approvals.getSelectedList();
            $http.put('api/changes/' + action, selectedList)
                .success(function (response) {
                    var msg = "";
                    if (response.length === selectedList.length) {
                        msg = "Όλες οι Αλλαγές ";
                        if (action === 'approve') {
                            msg += "εγκρίθηκαν με επιτυχία!";
                        }else {
                            msg += "απορρίφθηκαν με επιτυχία!";
                        }
                        notification.success(msg);
                    } else if (response.length === 0) {
                        msg = "Καμία Αλλαγή δεν ";
                        if (action === 'approve') {
                            msg += "Εγκρίθηκε. ";
                        }else {
                            msg += "Απορρίφθηκε. ";
                        }
                        msg += "Πατήστε Έγκριση/Απόρριψη μεμονωμένα σε κάθε Αλλαγή για περισσότερες λεπτομέρειες";
                        notification.error(msg);
                    } else {
                        if (action === 'approve') {
                            msg = "Εγκρίθηκαν ";
                        }else {
                            msg = "Απορρίφθηκαν ";
                        }
                        msg += response.length + " από " + selectedList.length +
                            " Αλλαγές. Πατήστε Έγκριση/Απόρριψη μεμονωμένα σε κάθε Αλλαγή για περισσότερες λεπτομέρειες";
                        notification.warning(msg);
                    }

                    vm.statusChanged = 'statusChanged';
                    vm.pendingGridOptions.dataSource.read();
                })
                .error(function (response) {
                    console.log(response);
                    notification.error("Η ενημέρωση των στοιχείων απέτυχε! " + $filter('translate')('global.messages.error.' + response.params[0]));
                    vm.pendingGridOptions.dataSource.read();
                });

            //clear selected list
            vm.statusChanged = '';
            Approvals.setSelectedList([]);
            vm.previewWindow.close();
        };

        function showOldValue(container) {
            return Approvals.calculateValue(container, container.oldValue);
        }

        function showNewValue(container) {
            return Approvals.calculateValue(container, container.value);
        }

        vm.openPreviewWindow = function (status) {
            if(Approvals.getSelectedList().length) {
                vm.initPopupGrid = true;
                vm.status = status;
                Approvals.openWindow(vm.previewWindow, status);
            }
        };

        vm.closePreviewWindow = function() {
            Approvals.closeWindow(vm.pendingGridOptions, vm.previewWindow);
            delete vm.initPopupGrid;
        };

        vm.pendingPreviewGridOptions = {
            dataSource: new kendo.data.DataSource({
                data: Approvals.getSelectedList(),
                schema: {
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            createdDate: {type: 'date'}
                        }
                    }
                },
                pageSize: 20
            }),
            pageable: true,
            columns: [
                {field: "createdDate", title: "Ημερομηνία", template: "#= createdDate ? kendo.toString(kendo.parseDate(createdDate), 'dddd, dd/MM/yyyy HH:mm') : ''#",
                    filterable: false,
                    width:200
                },
                {
                    title: "Αλλαγή",
                    template:"<span class='label label-info'>#: changeTable.nameGr#</span>", width:100
                },
                {field: "customer.lastName", title: "Επώνυμο Επαγγελματία Υγείας",
                    filterable: false,
                    width:160
                },
                {field: "customer.firstName", title: "Όνομα Επαγγελματία Υγείας",
                    filterable: false,
                    width:160
                },
                {field: "employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",
                    filterable: false,
                    width:160
                },
                {field: "employee.account.firstName", title: "Όνομα Ιατρικού Επισκέπτη",
                    filterable: false,
                    width:160
                },
                {field: "changeField.nameGr", title: "Πεδίο",
                    filterable: false,
                    width:140
                },
                {field: "oldValue", title: "Από",
                    template: showOldValue,
                    filterable: false,
                    width:160
                },
                {field: "value", title: "Σε",
                    template: showNewValue,
                    filterable: false,
                    width:160
                },
                { "template": "<md-button class='md-icon-button' aria-label='Remove' ng-click='vm.approvals.removeItem(vm.pendingPreviewGridOptions.dataSource.data(), #=id#)'>" +
                "<md-icon class='remove'></md-icon></md-button>", width: 90 }
            ]
        };

        vm.pendingGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "status", "operator": "eq",  "value": "pending"}
                            ]
                        );

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "sectorType", "operator": "sectortype",  "value": vm.sectorType}
                            ]
                        );

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        $http.get('api/me/changes',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (options, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            createdDate: {type: 'date'}
                        }
                    }
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                pageSize: 50
            }),
            sortable: true,
            pageable: true,
            selectable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    command:[
                        { name:"approve", text: "Έγκριση", click: vm.changeStatus }
                    ],
                    attributes: { class: "#= 'green-color' #" },
                    title: "&nbsp",
                    width:90
                },
                {
                    command:[
                        { name:"reject", text: "Απόρριψη", click: vm.changeStatus }
                    ],
                    attributes: { class: "#= 'red-color' #" },
                    title: "&nbsp",
                    width:100
                },
                {field: "createdDate", title: "Ημερομηνία", template: "#= createdDate ? kendo.toString(kendo.parseDate(createdDate), 'dddd, dd/MM/yyyy HH:mm') : ''#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    },
                    width:200
                },
                {
                    title: "Αλλαγή",
                    template:"<span class='label label-info'>#: changeTable.nameGr#</span>", width:100
                },
                {field: "customer.lastName", title: "Επώνυμο Επαγγελματία Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {field: "customer.firstName", title: "Όνομα Επαγγελματία Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {field: "employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {field: "employee.account.firstName", title: "Όνομα Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {field: "changeField.nameGr", title: "Πεδίο",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:140
                },
                {field: "oldValue", title: "Από",
                    template: showOldValue,
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {field: "value", title: "Σε",
                    template: showNewValue,
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                }
            ],
            selectedItems: function(dataItems) {
                Approvals.setSelectedList(dataItems);
            },
            dataBound: function (e) {
                angular.element("#pendingGridcheckbox").prop('checked', false);
                //toggle class on click
                angular.element(".checkbox").bind("change", function (e) {
                    angular.element(e.target).closest("tr").toggleClass("k-state-selected");
                });

                var view = this.dataSource.data();
                var me = this;

                if (Approvals.getSelectedList().length) {
                    var selectedListViewCounter = 0;
                    angular.forEach( view, function(item) {
                        var foundItemById = $filter('filter')(Approvals.getSelectedList(), {id: item.id}, true)[0];
                        if(Approvals.getSelectedList().indexOf(foundItemById) !== -1){
                            me.tbody.find("tr[data-uid='" + item.uid + "']")
                                .addClass("k-state-selected");
                            item.selected = true;
                            selectedListViewCounter++
                        }
                    });
                    if(selectedListViewCounter == view.length) {
                        angular.element("#pendingGridcheckbox").prop('checked', true);
                    }
                }
            },
            toolbar: [
                {template: "<md-button class='green-color-md-button md-raised md-mini md-primary' aria-label='approveSelected' ng-click='vm.openPreviewWindow(\"approve\")'>" +
                "Μαζική Έγκριση επιλεγμένων</md-button>"},
                {template: "<md-button class='red-color-md-button md-raised md-mini md-primary' aria-label='rejectSelected' ng-click='vm.openPreviewWindow(\"reject\")'>" +
                "Μαζική Απόρριψη επιλεγμένων</md-button>"},
                { template: "<div class='right'><label>Σύνολο: {{vm.pendingGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }
            ]
        };

        vm.sectorTypeOptions = {
            dataSource: [
                { text: 'Φαρμακοποιοί', value: "pharmacy" },
                { text: 'Επαγγελματίες Υγείας', value: "hcu" },
                { text: 'Υπεύθυνοι Κέντρου', value: "beautyCenter" }
            ],
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "text",
            dataValueField: "value",
            change: function () {
                var self = this;
                $timeout(function() {
                    vm.sectorType = self.value();
                    vm.pendingGridOptions.dataSource.read();
                });
            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    template: "<label>Τύπος Επαγγελματία Υγείας: </label><select id='sectorType' name='sectorType' class='inline' kendo-combo-box " +
                    "k-options='vm.sectorTypeOptions'></select>",
                    overflow: "never", attributes: { class: "left" }
                }
            ]
        };
    }
})();
