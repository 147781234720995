(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CollaborationDetailController', CollaborationDetailController);

    CollaborationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Collaboration'];

    function CollaborationDetailController($scope, $rootScope, $stateParams, entity, Collaboration) {
        var vm = this;
        vm.collaboration = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:collaborationUpdate', function(event, result) {
            vm.collaboration = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
