/**
 * Created by Kristy Kavvada on 11/2/2020.
 */


(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MedReportWithLinesController', MedReportWithLinesController);

    MedReportWithLinesController.$inject = ['$scope', '$filter', '$timeout','$http', 'employee', 'favouriteFilters', 'FavouriteFiltersService', 'MasterFiltersService', 'KendoFilter', 'MasterToKendoFiltersService', 'CustomUtils', 'Principal', 'ApplicationParameters', 'KendoService'];

    function MedReportWithLinesController ($scope, $filter, $timeout, $http, employee, favouriteFilters, FavouriteFiltersService, MasterFiltersService, KendoFilter, MasterToKendoFiltersService, CustomUtils, Principal, ApplicationParameters, KendoService) {
        var vm = this;
        vm.url = '/api/me/med-reports';
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(57);
        vm.favouriteFilters = favouriteFilters.data.content;
        vm.employee = employee;
        vm.masterFilters = { selectedEmployees: [], selectedCustomers: [], selectedMonths: [], selectedYears: [], selectedMedicines: [], isActive: true };
        vm.pageHeading = 'Συνολα & Συγκριτικα';
        vm.disabled = true;
        vm.isEdited = false;
        vm.selectedFilter = {};
        vm.mainGrid = {};
        vm.masterOptions = [];
        vm.filter = {};
        vm.showProgress = false;
        vm.principal = Principal;
        var months = CustomUtils.getMonths(false);
        var years = CustomUtils.getYears(2017, true);
        var exportFlag = false;
        var detailExportPromises = [];
        vm.view = 'medReport';

        vm.hasMedReport = false;
        ApplicationParameters.hasModule('Med_Report', false).then(function (response) {
            vm.hasMedReport = response.data.content[0].value === "1";
        });
        ApplicationParameters.hasModule('Med_Report', true);

        var setView = function() {
            var filterable = vm.masterFilters.selectedMonths.length && vm.masterFilters.selectedYears.length ? {
                mode: "row"
            } : false;

            var sortable = vm.masterFilters.selectedMonths.length && vm.masterFilters.selectedYears.length ? {
                mode: "multiple",
                allowUnsort: true,
                showIndexes: true
            } : false;

            vm.disabled = !vm.masterFilters.selectedMonths.length || !vm.masterFilters.selectedYears.length;
            vm.mainGrid.setOptions({
                sortable: sortable,
                groupable: vm.masterFilters.selectedMonths.length && vm.masterFilters.selectedYears.length,
                filterable: filterable
            });
        };

        $scope.$watchGroup(["vm.masterFilters.selectedMonths", "vm.masterFilters.selectedYears"], function () { setView(); });
        vm.masterFiltersEdited = function(){
            var gridOptions = FavouriteFiltersService.getKendoOptions(vm.mainGrid);
            $timeout(function(){
                vm.isEdited = !!(vm.selectedFilter.kendoOptions && vm.masterOptions.length &&
                    (!angular.equals(vm.masterOptions, MasterToKendoFiltersService.getMasterFilters([], vm.masterFilters, vm.view)) || !angular.equals(JSON.parse(vm.selectedFilter.kendoOptions), gridOptions)) );
            });
        };
        $scope.$watch("vm.masterFilters", vm.masterFiltersEdited, true);

        vm.collapseAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-collapse").trigger("click"); };
        vm.expandAll = function () { angular.element("#mainGrid").find(".k-icon.k-i-expand").trigger("click"); };

        vm.employeesMultiOptions = MasterFiltersService.getEmployeesComboBoxOptions(["all", "me"]);
        vm.employeesMultiOptions.dataSource.filter({});

        vm.customersMultiOptions = MasterFiltersService.getCustomersComboBoxOptions(["all"]);
        vm.customersMultiOptions.dataSource.filter({});

        vm.medicinesDataSource = KendoService.initDataSource('/api/medicines/search', 1, 100);
        vm.medicinesMultiOptions = KendoService.initDefaultOptions(vm.medicinesDataSource, "startswith", "name", "id");
        vm.medicinesMultiOptions.placeholder = "Επιλογή Φαρμάκου";

        vm.monthsMultiOptions = KendoService.initDefaultOptions(months, "startswith", "value", "id");
        vm.monthsMultiOptions.placeholder = "Επιλογή Μήνα";
        vm.yearsMultiOptions = KendoService.initDefaultOptions(years, "startswith", "value", "value");
        vm.yearsMultiOptions.placeholder = "Επιλογή Έτους";
        vm.masterFilters.selectedYears.push(years[0].value); //2020
        vm.toolbarOptions = {
            items: [
                {
                    template: "<label>Μήνες: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select multiple='multiple' " +
                        "k-options='vm.monthsMultiOptions' k-ng-model='vm.masterFilters.selectedMonths'></select>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    template: "<label>Έτη: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select multiple='multiple' " +
                        "k-options='vm.yearsMultiOptions' k-ng-model='vm.masterFilters.selectedYears'></select>",
                    overflow: "never", attributes: {class: "left"}
                },
                { type: "separator", overflow: "never", attributes: { class: "left" } },
                {
                    template: "<label>Ιατρικός Επισκέπτης: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.employeesMulti' multiple='multiple' " +
                        "k-options='vm.employeesMultiOptions' k-ng-model='vm.masterFilters.selectedEmployees'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Επαγγελματίας Υγείας: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.customersMulti' multiple='multiple' " +
                        "k-options='vm.customersMultiOptions' k-ng-model='vm.masterFilters.selectedCustomers'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Φάρμακο: </label><select id='multiSelect' name='multiSelect' class='inline' kendo-multi-select='vm.medicineMulti' multiple='multiple' " +
                        "k-options='vm.medicinesMultiOptions' k-ng-model='vm.masterFilters.selectedMedicines'></select>",
                    overflow: "never", attributes: { class: "left" }
                },
                {
                    template: "<label>Ενεργό: </label><md-checkbox aria-label='isActive' ng-model='vm.masterFilters.isActive' class='checkbox-toolbar green inline'></md-checkbox>",
                    overflow: "never", attributes: {class: "left"}
                },
                {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary' aria-label='search' ng-click='vm.mainGridOptions.dataSource.read();' ng-disabled='vm.disabled'>" +
                        "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>", overflow: "never", attributes: { class: "right" }
                }
            ]
        };

        vm.mainGridOptions = {
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function (o) {
                        if (o.data.filter === undefined) o.data.filter = { filters: [] };
                        o.data.filter.filters = MasterToKendoFiltersService.getMasterFilters(o.data.filter.filters, vm.masterFilters, vm.view);
                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                            }
                        });

                        $http.get(vm.url, {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {});
                    }
                },
                schema: {
                    data: function (data) {
                        if(data.content) {
                            angular.forEach(data.content, function (item) {
                                if (!item.customer) item.customer = {lastName: ""};
                            });
                        }
                        return data.content;
                    },
                    parse: function(data) {
                        if(data.content) {
                            angular.forEach(data.content, function (item) {
                                if (item.fromDate) item.month = kendo.toString(kendo.parseDate(item.fromDate), 'MMMM');
                                if (item.fromDate) item.year = kendo.toString(kendo.parseDate(item.fromDate), 'yyyy');
                            });
                        }
                        return data;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: {editable: false, nullable: true},
                            fromDate: {type: 'date'},
                            toDate: {type: 'date'},
                            checkDigit1: {type: 'number'},
                            checkDigit2: {type: 'number'}
                        }
                    }
                },
                serverSorting: true,
                serverFiltering: true,
                serverPaging: true,
                pageSize: 50
            }),
            height: CustomUtils.getHeightPixelsFromPercentage(55),
            sortable: true,
            groupable: false,
            pageable: {
                messages: {
                    display: "{0} - {1} από {2} Αναφορές", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                    empty: "Δεν βρέθηκαν αναφορές",
                    page: "Σελίδα",
                    allPages: "Όλα",
                    of: "από {0}", // {0} is total amount of pages.
                    itemsPerPage: "εγγραφές ανά σελίδα",
                    first: "Πήγαινε στη πρώτη σελίδα",
                    previous: "Πήγαινε στη τελευταία σελίδα",
                    next: "Πήγαινε στην επόμενη σελίδα",
                    last: "Πήγαινε στη τελευταία σελίδα",
                    refresh: "Ανανέωση"
                }
            },
            filterable: false,
            resizable: true,
            columns: [
                {
                    field: "month",
                    title: "Μήνας",
                    filterable: false,
                    sortable: false
                },
                {
                    field: "year",
                    title: "Έτος",
                    filterable: false,
                    sortable: false
                },
                {
                    field: "checkDigit1", title: "Check Digit",
                    filterable: false,
                    width: 140
                },
                {
                    field: "checkDigit2", title: "Check Digit 2",
                    filterable: false,
                    width: 140
                },
                {
                    field: "code", title: "Κωδικός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "customer.lastName", title: "Επώνυμο Επαγγελματία Υγείας",
                    attributes: {
                        class: "link-cell link-cell-color"
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }, width: 200
                },
                {
                    field: "customer.firstName", title: "Όνομα Επαγγελματία Υγείας",
                    attributes: {
                        class: "link-cell link-cell-color"
                    },
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }, width: 200
                }
            ],
            toolbar: [
                { template: "<md-button class='md-raised md-mini md-primary' aria-label='collapseAll' ng-click='vm.collapseAll()'>Σύμπτυξη όλων</md-button>" },
                { template: "<md-button class='md-raised md-mini md-primary' aria-label='expandAll' ng-click='vm.expandAll()'>Επέκταση Όλων</md-button>" },
                { name: "excel", template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                        "<span class='k-icon k-i-excel'></span>Export</md-button></div>" }
            ],
            noRecords: {
                template: "Δεν βρέθηκαν εγγραφές"
            },
            excel: {
                allPages: true,
                fileName: "MedReports.xlsx"
            },
            dataBound: function () { vm.masterFiltersEdited(); },
            excelExport: function (e) {
                if (!exportFlag){
                    e.preventDefault();
                    exportFlag = true;
                    var workbook = e.workbook;

                    detailExportPromises = [];

                    var masterData = e.data;

                    for (var rowIndex = 0; rowIndex < masterData.length; rowIndex++) {
                        vm.exportChildData(masterData[rowIndex], rowIndex, e.sender._data[rowIndex]);
                    }

                    $.when.apply(null, detailExportPromises)
                        .then(function() {
                            // get the export results
                            var detailExports = $.makeArray(arguments);

                            // sort by masterRowIndex
                            detailExports.sort(function(a, b) {
                                return a.masterRowIndex - b.masterRowIndex;
                            });

                            // add an empty column
                            workbook.sheets[0].columns.unshift({
                                width: 30
                            });

                            // prepend an empty cell to each row
                            for (var i = 0; i < workbook.sheets[0].rows.length; i++) {
                                workbook.sheets[0].rows[i].cells.unshift({});
                            }
                            var totalDataLength = 0;
                            // merge the detail export sheet rows with the master sheet rows
                            for (var i = 0; i < detailExports.length; i++) {
                                var findByType = detailExports[i-1] ? $filter('filter')(detailExports[i-1].sheet.rows, {type: "data"}, true) : [];
                                totalDataLength += findByType.length;
                                var masterRowIndex;
                                if (e.sender.dataSource.group().length) {
                                    masterRowIndex = detailExports[i].masterRowIndex + 1
                                        + e.sender.dataSource.group().length + (i * 2) + (i * e.sender.dataSource.group().length)
                                        + totalDataLength;
                                } else {
                                    masterRowIndex = detailExports[i].masterRowIndex + 1 + (i * 2) + totalDataLength;
                                }

                                var sheet = detailExports[i].sheet;

                                // prepend an empty cell to each row
                                for (var ci = 0; ci < sheet.rows.length; ci++) {
                                    if (sheet.rows[ci].cells[0].value) {
                                        sheet.rows[ci].cells.unshift({});
                                    }
                                }

                                // insert the detail sheet rows after the master row
                                [].splice.apply(workbook.sheets[0].rows, [masterRowIndex + 1, 0].concat(sheet.rows));
                            }

                            // save the workbook
                            kendo.saveAs({
                                dataURI: new kendo.ooxml.Workbook(workbook).toDataURL(),
                                fileName: "Αναφορά Med.xlsx"
                            });
                            exportFlag = false;
                        });
                }else {
                    exportFlag = false;
                    vm.showProgress = false;
                }
            }
        };

        vm.medReportLinesGridOptions = function (dataItem) {
            if (dataItem !== undefined) {
                return {
                    dataSource: {
                        batch: true,
                        transport: {
                            read: function(o) {
                                vm.showProgress = true;
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.filter.filters.push({field: "medReport.id", operator: "eq", value: dataItem.id});
                                $http.get('/api/med-report-lines/search',
                                    {params: o.data})
                                    .success(function (response) {
                                        o.success(response);
                                        vm.showProgress = false;
                                    })
                                    .error(function (response) {
                                        vm.showProgress = false;
                                    });
                            }
                        },
                        schema: {
                            data: function (data) {
                                if(data != undefined) {
                                    data.content.forEach(function(item){
                                    });
                                }
                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: { editable: false, nullable: true },
                                    sum: { type: "number" },
                                    marketSharePercentage: { type: "number" },
                                    numberOfChanges: { type: "number" }
                                }
                            }
                        },
                        aggregate: [{field: "sum", aggregate: "sum"}],
                        serverFiltering: true,
                        serverSorting: true,
                        serverPaging: true,
                        pageSize: 15
                    },
                    pageable: {
                        messages: {
                            display: "{0} - {1} από {2} Αναφορές", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                            empty: "Δεν βρέθηκαν αναφορές",
                            page: "Σελίδα",
                            allPages: "Όλα",
                            of: "από {0}", // {0} is total amount of pages.
                            itemsPerPage: "εγγραφές ανά σελίδα",
                            first: "Πήγαινε στη πρώτη σελίδα",
                            previous: "Πήγαινε στη τελευταία σελίδα",
                            next: "Πήγαινε στην επόμενη σελίδα",
                            last: "Πήγαινε στη τελευταία σελίδα",
                            refresh: "Ανανέωση"
                        }
                    },
                    sortable: true,
                    filterable: false,
                    columns: [
                        {
                            field: "medicine.barcode", title: "Barcode",
                            filterable: {
                                cell: {
                                    operator: "startswith",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            },
                            footerTemplate: "Σύνολο:"
                        },
                        {
                            field: "medicine.name", title: "Φάρμακο",
                            filterable: {
                                cell: {
                                    operator: "like",
                                    template: function (args) {
                                        KendoFilter.getTemplate(args);
                                    },
                                    showOperators: false
                                }
                            }
                        },
                        {
                            field: "sum", title: "Σύνολο",
                            filterable: {
                                operators: {
                                    number: {
                                        eq: 'Ίσο',
                                        gte: 'Μεγαλύτερο ή Ίσο',
                                        lte: 'Μικρότερο ή Ίσο'
                                    }
                                },
                                cell: {
                                    template: function (args) {
                                        args.element.kendoNumericTextBox({
                                            format: "n0",
                                            decimals: 0,
                                            min: 0
                                        });

                                    }
                                }
                            },
                            aggregates: ["sum"],
                            footerTemplate: "#=sum ? kendo.format('{0:n0}', sum) : ''#"
                        },
                        // {
                        //     field: "marketSharePercentage",
                        //     title: "Μερίδιο Αγοράς (%)",
                        //     attributes:{style:"text-align:right;"},
                        //     template: "#= (marketSharePercentage == null) ? '' : kendo.format('{0:p0}', marketSharePercentage / 100)#",
                        //     filterable: {
                        //         cell: {
                        //             operator: "eq",
                        //             template: function (args) {
                        //                 args.element.kendoNumericTextBox({
                        //                     format: "{0:n0}"
                        //                 });
                        //             },
                        //             showOperators: false
                        //         }
                        //     }
                        // },
                        // {
                        //     field: "numberOfChanges", title: "Αριθμός Αλλαγών",
                        //     filterable: {
                        //         operators: {
                        //             number: {
                        //                 eq: 'Ίσο',
                        //                 gte: 'Μεγαλύτερο ή Ίσο',
                        //                 lte: 'Μικρότερο ή Ίσο'
                        //             }
                        //         },
                        //         cell: {
                        //             template: function (args) {
                        //                 args.element.kendoNumericTextBox({
                        //                     format: "n0",
                        //                     decimals: 0,
                        //                     min: 0
                        //                 });
                        //
                        //             }
                        //         }
                        //     }
                        // }
                    ],
                    editable: false
                };
            }
        };

        vm.exportChildData = function (dataItem, rowIndex, masterItem) {
            var deferred = $.Deferred();

            detailExportPromises.push(deferred);

            var exporter = new kendo.ExcelExporter({
                columns: vm.medReportLinesGridOptions(masterItem).columns,
                dataSource: vm.medReportLinesGridOptions(masterItem).dataSource
            });

            exporter.workbook().then(function(book, data) {
                deferred.resolve({
                    masterRowIndex: rowIndex,
                    sheet: book.sheets[0]
                });
            });
        };
    }

})();
