(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('CustomDataUtils', CustomDataUtils);

    CustomDataUtils.$inject = ['$window'];

    function CustomDataUtils ($window) {

        var service = {
            openWindow: openWindow,
            openDocument: openDocument,
            base64toBlob: base64toBlob,
            toDataURL: toDataURL,
            Base64EncodeUrl: Base64EncodeUrl,
            Base64DecodeUrl: Base64DecodeUrl,
            arrayRemove: arrayRemove,
            getValueFromHeaderLabel: getValueFromHeaderLabel,
            removeTotalsFromHeaderLabel: removeTotalsFromHeaderLabel,
            getGroupColumn: getGroupColumn,
            convertToNumberFormat: convertToNumberFormat,
            isNotNil: isNotNil,
            isNotEmpty: isNotEmpty
        };

        return service;

        function openWindow (type, data) {
            $window.open('data:' + type + ';base64,' + data);
        }

        function openDocument (action, title, type, base64data) {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = base64toBlob(base64data, type);

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.setAttribute("type", "hidden");
            link.href = data;

            switch (action) {
                case 'preview':
                    link.target = "title";
                    break;
                case 'download':
                    link.download = title + type.replace(new RegExp("^[^/].*/"), ".");
                    break;
            }

            document.body.appendChild(link);
            link.click();
            link.remove();
            setTimeout(function(){
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
                    , 100});
        }

        function base64toBlob(base64data, contentType, sliceSize) {
            contentType = contentType || '';
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(base64data);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, {type: contentType});
            return blob;
        }

        // Converts image to base64 url string (pdfKit need it this way)
        function toDataURL(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function() {
                var reader = new FileReader();
                reader.onloadend = function() {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }

        /**
         * use this to make a Base64 encoded string URL friendly,
         * i.e. '+' and '/' are replaced with '-' and '_' also any trailing '='
         * characters are removed
         *
         * @param {String} str the encoded string
         * @returns {String} the URL friendly encoded String
         */
        function Base64EncodeUrl(str){
            return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
        }

        /**
         * Use this to recreate a Base64 encoded string that was made URL friendly
         * using Base64EncodeurlFriendly.
         * '-' and '_' are replaced with '+' and '/' and also it is padded with '+'
         *
         * @param {String} str the encoded string
         * @returns {String} the URL friendly encoded String
         */
        function Base64DecodeUrl(str){
            str = (str + '===').slice(0, str.length + (str.length % 4));
            return str.replace(/-/g, '+').replace(/_/g, '/');
        }

        /**
         * Filters an array and returns an array with elements that not matching the value.
         * @param arr The array to be filtered.
         * @param value The value that elements should not match
         */
        function arrayRemove(arr, value){
            return arr.filter(function (ele) {
                return ele.type !== value;
            });
        }

        /**
         * Gets the value from an excel header label.
         * Format of the header label should be like this 'Blah blah (Σύνολο: 117)'
         * It extracts 117 value from the header label
         * @param headerLabel The label of excel header
         */
        function getValueFromHeaderLabel(headerLabel) {
            return headerLabel.match(/[0-9]*(\.?[0-9]*)*,?[0-9]*[0-9]/g); // get all numbers from string
        }

        /**
         * Gets the value name of the grouped column.
         * Format of the header label should be like this 'Blah blah (Blah blah)'
         * @param value The label of excel header
         */
        function getGroupColumn(value) {
            return value.match(/(.*)\(/).pop();
        }

        /**
         * Removes the totals from an excel header label
         * Format of the header label should be like this 'Blah blah (Σύνολο: 117)'
         * It extracts Blah blah from the header label
         * @param headerLabel
         * @returns {string}
         */
        function removeTotalsFromHeaderLabel(headerLabel) {
            var striped = headerLabel.match(/^(.*)\(/).pop(); // start of string till first '('
            return striped.substring(0, striped.length -1); // remove last char '('
        }

        /**
         * Converts the value given to float format
         * It extracts the float number
         * @param value
         * @returns {number}
         */
        function convertToNumberFormat(value) {
            return parseFloat(value.replace(/\./g, '').replace(/,/g, '.').replace(/ €/g, ''));
        }

        function isNotNil(value) {
            return !(value === null || value === undefined);
        }

        function isNotEmpty(arrayValue) {
            return isNotNil(arrayValue) && arrayValue.length > 0;
        }
    }
})();
