(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('FollowupCallDialogController', FollowupCallDialogController);

    FollowupCallDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'FollowupCall', 'FollowupType', 'LeadCall', 'RejectionReason', 'FollowupStatus', 'Competitor'];

    function FollowupCallDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, FollowupCall, FollowupType, LeadCall, RejectionReason, FollowupStatus, Competitor) {
        var vm = this;
        vm.followupCall = entity;
        vm.followuptypes = FollowupType.query();
        vm.leadcalls = LeadCall.query();
        vm.rejectionreasons = RejectionReason.query();
        vm.followupstatuses = FollowupStatus.query();
        vm.competitors = Competitor.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:followupCallUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.followupCall.id !== null) {
                FollowupCall.update(vm.followupCall, onSaveSuccess, onSaveError);
            } else {
                FollowupCall.save(vm.followupCall, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.date = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
