(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PrefectureDetailController', PrefectureDetailController);

    PrefectureDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Prefecture', 'Region'];

    function PrefectureDetailController($scope, $rootScope, $stateParams, entity, Prefecture, Region) {
        var vm = this;
        vm.prefecture = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:prefectureUpdate', function(event, result) {
            vm.prefecture = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
