(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PresentListDetailController', PresentListDetailController);

    PresentListDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PresentList'];

    function PresentListDetailController($scope, $rootScope, $stateParams, entity, PresentList) {
        var vm = this;
        vm.presentList = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:presentListUpdate', function(event, result) {
            vm.presentList = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
