/**
 * Created by Kristy on 30/8/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .directive('notificationsTemplate', notificationsTemplate);

    function notificationsTemplate () {

        return {
            restrict: 'A',
            template: "<script id=\"errorTemplate\" type=\"text/x-kendo-template\">\n" +
            "    <div class=\"wrong-pass\">\n" +
            "        <i class=\"material-icons fixed-icons\">assignment_late</i>\n" +
            "        <h6>#= message #</h6>\n" +
            "    </div>\n" +
            "</script>\n" +
            "\n" +
            "<script id=\"successTemplate\" type=\"text/x-kendo-template\">\n" +
            "    <div class=\"upload-success\">\n" +
            "        <i class=\"material-icons fixed-icons\">assignment_turned_in</i>\n" +
            "        <h6>#= message #</h6>\n" +
            "    </div>\n" +
            "</script>\n" +
            "<span id=\"notification\" style=\"display:none;\"></span>"
        };
    }
})();
