/**
 * Created by Kristy on 4/7/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('KendoToolbar', KendoToolbar);

    KendoToolbar.$inject = ['$filter'];

    function KendoToolbar ($filter) {

        return {
            getOptions: getOptions,
            clearDatePickers: clearDatePickers,
            setFrom: setFrom,
            setTo: setTo
        };

        function getOptions (args, vm) {
            var items = [];
            var label = {};

            if (args.indexOf("buttonGroup") > -1) {
                var buttonGroup = {
                    type: "buttonGroup", id:"buttonGroup",
                    buttons: [
                        { text: $filter('translate')('global.toolbar.today'), toggle: vm.masterFiltersOnClick, togglable: true,
                            group: "text-align", icon: "refresh", id: "today" },
                        { text: $filter('translate')('global.toolbar.week'), toggle: vm.masterFiltersOnClick, togglable: true,
                            group: "text-align", icon: "refresh", id: "week" },
                        { text: $filter('translate')('global.toolbar.month'), toggle: vm.masterFiltersOnClick, togglable: true,
                            group: "text-align", icon: "refresh", id: "month" }
                    ]
                };

                if (args.indexOf("year") > -1) {
                    buttonGroup.buttons.push({ text: $filter('translate')('global.toolbar.year'), toggle: vm.masterFiltersOnClick, togglable: true,
                        group: "text-align", icon: "refresh", id: "year" });
                }

                items.push(buttonGroup);
                items.push({ type: "separator" });
            }
            if (args.indexOf("from") > -1) {
                label = { template: "<label translate='global.toolbar.from'>From: </label>" };
                var from = {
                    template: "<input kendo-date-picker id='from' options='vm.fromOptions'/>",
                    overflow: "never"
                };
                items.push(label);
                items.push(from);
            }
            if (args.indexOf("to") > -1) {
                label = { template: "<label translate='global.toolbar.to'>To: </label>" };
                var to = {
                    template: "<input kendo-date-picker id='to' options='vm.toOptions'/>",
                    overflow: "never"
                };
                items.push(label);
                items.push(to);
            }
            if (args.indexOf("masterFilterFrom") > -1) {
                label = { template: "<label translate='global.toolbar.from'>From: </label>" };
                var from = {
                    template: "<input kendo-date-picker id='from' options='vm.fromOptions' k-ng-model='vm.masterFilters.from'/>",
                    overflow: "never"
                };
                items.push(label);
                items.push(from);
            }
            if (args.indexOf("masterFilterTo") > -1) {
                label = { template: "<label translate='global.toolbar.to'>To: </label>" };
                var to = {
                    template: "<input kendo-date-picker id='to' options='vm.toOptions' k-ng-model='vm.masterFilters.to'/>",
                    overflow: "never"
                };
                items.push(label);
                items.push(to);
            }
            if (args.indexOf("search") > -1) {
                var search = {
                    template: "<md-button class='next-button-toolbar md-raised md-mini md-primary' aria-label='search' ng-click='vm.mainGridOptions.dataSource.read()' ng-disabled='vm.disabled'>" +
                    "<md-icon class='search-refresh'></md-icon>Αναζήτηση</md-button>", attributes: { class: "right" },
                    overflow: "never"
                };
                items.push(search);
            }
            if (args.indexOf("myTarget") > -1) {
                var myTarget = {
                    template: "<label>My Target: </label><div class='inline'><md-radio-group layout='row' ng-change='vm.mainGridOptions.dataSource.read()' ng-model='vm.masterFilters.myTarget'>" +
                    "<md-radio-button ng-value=true class='md-primary'> Ναι </md-radio-button>" +
                    "<md-radio-button ng-value=false class='md-primary'> Όχι </md-radio-button>" +
                    "<md-radio-button ng-value='' class='md-primary'> Όλα </md-radio-button></md-radio-group></div>",
                    overflow: "never", attributes: {class: "left-space"}
                };
                items.push(myTarget);
            }

            return items;
        }

        function clearDatePickers () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        }

        function setFrom(date){
            $("#from").data("kendoDatePicker").value(date);
        }

        function setTo(date){
            $("#to").data("kendoDatePicker").value(date);
        }
    }

})();
