(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SectorCategoryDetailController', SectorCategoryDetailController);

    SectorCategoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'SectorCategory'];

    function SectorCategoryDetailController($scope, $rootScope, $stateParams, entity, SectorCategory) {
        var vm = this;
        vm.sectorCategory = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:sectorCategoryUpdate', function(event, result) {
            vm.sectorCategory = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
