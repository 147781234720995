/**
 * Created by Kristy on 3/10/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('DetailGridsService', DetailGridsService);

    DetailGridsService.$inject = ['$http', '$timeout'];

    function DetailGridsService($http, $timeout) {

        return {
            getPatchReportItemsGridOptions: function (dataItem) {
                if (dataItem != undefined) {
                    return {
                        dataSource: {
                            batch: true,
                            transport: {
                                read: function(o) {
                                    var url = '/api/me/patch-report-items';
                                    if (o.data.filter == undefined) {
                                        o.data.filter = { filters: [] };
                                    }
                                    o.data.filter.filters.push({ "field": "patchReport.id", "operator": "eq", "value": dataItem.id});
                                    $http.get(url, {params: o.data })
                                        .success(function (response,status,headers) {
                                            o.success(response);
                                        })
                                        .error(function (response) {
                                            console.log(response);
                                        });
                                }
                            },
                            schema: {
                                data: function (data) {
                                    if(data.content != undefined) {
                                        data.content.forEach(function(item){
                                            if (item.icd10 === null) item.icd10 = {};
                                        });
                                    }
                                    return data.content;
                                },
                                total: function (data) {
                                    return data.totalEntries;
                                },
                                model: {
                                    id: "id",
                                    fields: {
                                        id: {editable: false, nullable: true},
                                        monthlyQuantity: {type: 'number'}
                                    }
                                }
                            },
                            serverPaging: true,
                            pageSize: 15
                        },
                        pageable: true,
                        columns: [
                            {field: "patchItem.name", title: "Περιγραφή Υλικού",
                                filterable: {
                                    cell: {
                                        operator: "startswith",
                                        showOperators: false
                                    }
                                }
                            },
                            {field: "monthlyQuantity", title: "Μηνιαία Ποσότητα σε τεμάχια",
                                filterable: {
                                    cell: {
                                        operator: "eq",
                                        showOperators: false
                                    }
                                }
                            },
                            {field: "icd10.code", title: "Κωδικός Διάγνωσης",
                                filterable: {
                                    cell: {
                                        operator: "startswith",
                                        showOperators: false
                                    }
                                }
                            },
                            {field: "icd10.description", title: "Περιγραφή Διάγνωσης",
                                filterable: {
                                    cell: {
                                        operator: "startswith",
                                        showOperators: false
                                    }
                                }
                            },
                            {field: "comment", title: "Σχόλια",
                                filterable: {
                                    cell: {
                                        operator: "startswith",
                                        showOperators: false
                                    }
                                }
                            }
                        ]
                    };
                }
            },
            getPatchReportPhotosGridOptions:  function (dataItem, gridColumns) {
                if (dataItem != undefined) {
                    return {
                        dataSource: {
                            batch: true,
                            transport: {
                                read: function(o) {
                                    var url = '/api/me/patch-report-photos';
                                    if (o.data.filter == undefined) {
                                        o.data.filter = { filters: [] };
                                    }
                                    o.data.filter.filters.push({ "field": "patchReport.id", "operator": "eq", "value": dataItem.id});
                                    $http.get(url, {params: o.data })
                                        .success(function (response,status,headers) {
                                            o.success(response);
                                        })
                                        .error(function (response) {
                                            console.log(response);
                                        });
                                }
                            },
                            schema: {
                                data: function (data) {
                                    return data.content;
                                },
                                total: function (data) {
                                    return data.totalEntries;
                                },
                                model: {
                                    id: "id",
                                    fields: {
                                        id: {editable: false, nullable: true},
                                        monthlyQuantity: {type: 'number'}
                                    }
                                }
                            },
                            serverPaging: true,
                            pageSize: 15
                        },
                        pageable: true,
                        columns: gridColumns
                    };
                }
            }
        };
    }
})();
