(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminPharmaciesController', AdminPharmaciesController);

    AdminPharmaciesController.$inject = ['$timeout', '$http','$state','$filter', 'Principal', 'KendoService', 'KendoGrid','KendoFilter', 'CustomUtils'];

    function AdminPharmaciesController ($timeout, $http, $state, $filter, Principal, KendoService, KendoGrid, KendoFilter, CustomUtils) {
        var vm = this;
        var pharmaciesGrid = angular.element('#pharmaciesGrid');
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(60);
        vm.principal = Principal;
        vm.showProgress = false;

        vm.canCreateSector = Principal.hasAnyAuthority(['ROLE_SYSTEM', 'ROLE_ADMIN', 'ROLE_ACCOUNTING_DEP', 'ROLE_PHARM_SUPERVISOR']);
        vm.showFilters = false;
        vm.showFilters = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT','ROLE_SUPERVISOR', 'ROLE_PHARM_SUPERVISOR']);
        vm.gridColumns = [];
        if (Principal.hasAnyAuthority(['ROLE_SYSTEM', 'ROLE_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SUPERVISOR', 'ROLE_ACCOUNTING_DEP', 'ROLE_PHARM_SUPERVISOR'])){
            // GridColumns with Edit
            vm.gridColumns = [
                {
                    command: [
                        {name: "edit", text: ""}
                    ],
                    title: "&nbsp;",
                    width: 80
                },
                {
                    field: "code",
                    title: "Κωδικός Ε.Ε.",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "name",
                    title: "Επωνυμία",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "vat",
                    title: "Α.Φ.Μ.",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "companyType.name",
                    title: "Εταιρική Μορφή",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "address",
                    title: "Διεύθυνση",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "addressNum",
                    title: "Αριθμός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "cityName",
                    title: "Πόλη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "prefectureName",
                    title: "Νομός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "postalCode", title: "Τ.Κ.",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "brick",
                    title: "Brick",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "osfeBrick",
                    title: "Brick ΟΣΦΕ",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "phone",
                    title: "Τηλέφωνο",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "createdDate",
                    title: "Ημερομηνία εισαγωγής",
                    template: "#= kendo.toString(kendo.parseDate(createdDate), 'dddd, dd/MM/yyyy HH:mm')#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "creditControlCheck", title: "Πιστωτικός Έλεγχος",
                    template: "#= creditControlCheck === true ? 'Ναι' : 'Όχι' #",
                    filterable: {
                        cell: {
                            operator: "eqbool",
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Ναι", value: "true" },
                                            { text: "Όχι", value: "false" }]
                                    }),
                                    valuePrimitive: true
                                })
                            },
                            showOperators: false
                        }
                    }}
            ];
        }else {
            // GridColumns without Edit
            vm.gridColumns = [
                {
                    field: "code",
                    title: "Κωδικός Ε.Ε.",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "name",
                    title: "Επωνυμία",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "vat",
                    title: "Α.Φ.Μ.",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "companyType.name",
                    title: "Εταιρική Μορφή",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "address",
                    title: "Διεύθυνση",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "addressNum",
                    title: "Αριθμός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "cityName",
                    title: "Πόλη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "prefectureName",
                    title: "Νομός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "postalCode", title: "Τ.Κ.",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "brick",
                    title: "Brick",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "osfeBrick",
                    title: "Brick ΟΣΦΕ",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "phone",
                    title: "Τηλέφωνο",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "createdDate",
                    title: "Ημερομηνία εισαγωγής",
                    template: "#= kendo.toString(kendo.parseDate(createdDate), 'dddd, dd/MM/yyyy HH:mm')#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "creditControlCheck", title: "Πιστωτικός Έλεγχος",
                    template: "#= creditControlCheck === true ? 'Ναι' : 'Όχι' #",
                    filterable: {
                        cell: {
                            operator: "eqbool",
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Ναι", value: "true" },
                                            { text: "Όχι", value: "false" }]
                                    }),
                                    valuePrimitive: true
                                })
                            },
                            showOperators: false
                        }
                    }}
            ];
        }

        vm.selectedEmployees = vm.selectedSupervisors = [];
        vm.sector= {};
        var comboBoxes = ["#masterFilter","#prefectures", "#cities"];

        vm.isSaving = false;

        vm.pageHeading = 'Φαρμακεια';

        vm.dataSource = {};
        vm.dataSource.companyTypes = KendoService.initDefaultDataSource('/api/masterdata/company-types');
        vm.dataSource.taxOffices = KendoService.initDefaultDataSource('/api/masterdata/tax-offices');
        vm.dataSource.taxStatuses = KendoService.initDefaultDataSource('/api/tax-statuses/search');

        vm.companyTypesComboBoxOptions = {
            dataSource: vm.dataSource.companyTypes,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };

        vm.taxOfficesComboBoxOptions = {
            dataSource: vm.dataSource.taxOffices,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };

        vm.taxStatusesComboBoxOptions = {
            dataSource: vm.dataSource.taxStatuses,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };

        vm.refreshCombobox = function(filter, combobox) {
            combobox.filter(filter);
            combobox.read();
        };

        vm.onExcelExportClick = function () {
            const grid = $("#pharmaciesGrid").data("kendoGrid");
            grid.saveAsExcel();
            vm.showProgress = true;
        };

        vm.onAddPharmacyClick = function () {
            const grid = $("#pharmaciesGrid").data("kendoGrid");
            grid.addRow();
        };

        $http.get('/api/employees/me')
            .success(function (response,status,headers) {
                vm.geodata = response.geodata;

                if(!vm.geodata) {
                    var prefecturesDataSource = new kendo.data.DataSource({
                        serverFiltering: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.page = 1;
                                o.data.pageSize = 100000;
                                $http.get('/api/masterdata/prefectures',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response.content);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        }
                    });

                    //DropDown Options
                    vm.prefecturesComboBoxOptions = {
                        dataSource: prefecturesDataSource,
                        filter: "startswith",
                        valuePrimitive: false,
                        dataTextField: "name",
                        dataValueField: "id",
                        change: function(e){
                            var filter = {};
                            if(this.value() && !isNaN(this.value())) {
                                filter = {
                                    "field": "prefecture.id",
                                    "operator": "eq",
                                    "value": this.value()
                                };
                            }
                            vm.refreshCombobox(filter, vm.citiesComboBoxOptions.dataSource);
                        }
                    };

                    var citiesDataSource = new kendo.data.DataSource({
                        serverFiltering: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.page = 1;
                                o.data.pageSize = 50;
                                $http.get('/api/masterdata/cities',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response.content);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        }
                    });

                    //DropDown Options
                    vm.citiesComboBoxOptions = {
                        dataSource: citiesDataSource,
                        filter: "startswith",
                        valuePrimitive: false,
                        dataTextField: "name",
                        dataValueField: "id",
                        template: "<table>${name} &nbsp;" +
                        "<span class='hint'>(${prefecture.name})</span></table>",
                        change: function(e){
                            var filter = {};
                            if(this.dataItem(this.select())) {
                                filter = {
                                    "field"   : "id",
                                    "operator": "eq",
                                    "value"   : this.dataItem(this.select()).prefecture.id
                                };
                            }
                            vm.refreshCombobox(filter, vm.prefecturesComboBoxOptions.dataSource);

                        }
                    };
                }
                else {
                    var geodataDataSource = new kendo.data.DataSource({
                        serverFiltering: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.page = 1;
                                o.data.pageSize = 100;
                                $http.get('/api/masterdata/geodata',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response.content);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            }
                        },
                        schema: {
                            parse: function(data) {
                                angular.forEach(data, function(item) {
                                    item.masterFilter = "";
                                    if(item.street !==null) {
                                        item.masterFilter =  item.street + ", ";
                                    }
                                    item.masterFilter = item.masterFilter + item.city + ", " + item.postalCode + ", " + item.prefecture;
                                });
                                return data;
                            }
                        }
                    });

                    vm.masterFilterComboBoxOptions = {
                        dataSource: geodataDataSource,
                        filter: "search",
                        dataTextField: "masterFilter",
                        dataValueField: "id",
                        headerTemplate:"<div class='dropdown-header k-widget k-header'>" +
                        "<span>Διεύθυνση</span>"+
                        "<span>Πόλη</span>"+
                        "<span>Τ.Κ.</span>"+
                        "<span>Νομός</span></div>",
                        template:
                            "#= street != null ? street + ', ' + city + ', ' + postalCode + ', ' + prefecture : city + ', ' + postalCode + ', ' + prefecture #",
                        placeholder: "Αναζητήστε Διεύθυνση, Πόλη, Τ.Κ., Νομό",
                        change: function(e){
                            //initialize inputs (autocomplete)
                            if(this.dataItem(this.select())) {
                                var self = this;
                                //using $timeout to trigger the change
                                $timeout(function(){
                                    vm.sector.prefectureName = self.dataItem(self.select()).prefecture;
                                    vm.sector.cityName = self.dataItem(self.select()).city;
                                    vm.sector.address = self.dataItem(self.select()).street;
                                    vm.sector.postalCode = self.dataItem(self.select()).postalCode;
                                    vm.suggestBricks(vm.sector.postalCode);
                                },0);
                            }
                        }
                    };
                }

                vm.pharmaciesGridOptions = {
                    dataSource: new kendo.data.DataSource({
                        batch: true,
                        transport: {
                            read: function(o) {
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                //master filters functionality
                                if(vm.from && vm.to) {
                                    o.data.filter.filters = o.data.filter.filters.concat(
                                        [
                                            { "field": "createdDate", "operator": "from",
                                                "value": $filter('date')(vm.from, 'yyyy-MM-ddT00:00:00.000') + 'Z' },
                                            { "field": "createdDate", "operator": "to",
                                                "value": $filter('date')(vm.to, 'yyyy-MM-ddT23:59:59.000') + 'Z' }
                                        ]
                                    );
                                }

                                if (vm.selectedEmployees.length > 0) {
                                    o.data.selectedEmployeeIds = vm.selectedEmployees.toString();
                                }

                                if(vm.selectedSupervisors.length && !vm.selectedEmployees.length) {
                                    o.data.selectedSupervisorIds = vm.selectedSupervisors.toString();
                                }

                                angular.forEach( o.data.filter.filters, function(item) {
                                    if ( item.value instanceof Date) {
                                        item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                                    }
                                });

                                $http.get('/api/me/pharmacies',
                                    {params: o.data})
                                    .success(function (response,status,headers) {
                                        o.success(response);
                                    })
                                    .error(function (response) {
                                        console.log(response);
                                    });
                            },
                            create: function (o){
                                var data = o.data.models[0];
                                var params = {
                                    filter: { filters: [{ "field": "sectorTypeCategory", "operator": "startswith",  "value": "pharmacy"}] },
                                    page: 1,
                                    pageSize: 1
                                };

                                $http.get('/api/masterdata/sector-types', {params: params})
                                    .success(function (response) {
                                        data.sectorType = response.content[0];

                                        if(!data.companyType.id)
                                            data.companyType = null;
                                        if(!data.taxStatus.id)
                                            data.taxStatus = null;
                                        if(!data.taxOffice.id)
                                            data.taxOffice = null;
                                        if(!data.city.id)
                                            data.city = null;
                                        if(!data.prefecture.id)
                                            data.prefecture = null;

                                        if(vm.sector.city != null && (vm.sector.city.id === null || isNaN(vm.sector.city.id))){
                                            vm.sector.city = null;
                                        }

                                        if(vm.sector.prefecture != null && (vm.sector.prefecture.id === null || isNaN(vm.sector.prefecture.id))){
                                            vm.sector.prefecture = null;
                                        }

                                        angular.extend(data, vm.sector);

                                        $http.post('/api/sectors', data)
                                            .success(function (response) {
                                                pharmaciesGrid.data("kendoGrid").dataSource.read();
                                                vm.onSelection(response);
                                            })
                                            .error(function (response) {
                                                console.log(response);
                                            });
                                    })
                                    .error(function (response) {});
                            },
                            update: function (o){

                                var data = o.data.models[0];
                                var params = {
                                    filter: { filters: [{ "field": "sectorTypeCategory", "operator": "startswith",  "value": "pharmacy"}] },
                                    page: 1,
                                    pageSize: 1
                                };

                                $http.get('/api/masterdata/sector-types', {params: params})
                                    .success(function (response) {
                                        data.sectorType = response.content[0];

                                        if(!data.companyType.id)
                                            data.companyType = vm.sector.companyType = null;
                                        if(!data.taxStatus.id)
                                            data.taxStatus = vm.sector.taxStatus = null;
                                        if(!data.taxOffice.id)
                                            data.taxOffice = vm.sector.taxOffice = null;

                                        if(vm.sector.city != null) {
                                            if((vm.sector.city.id === null
                                                    || vm.sector.city.id === undefined || isNaN(vm.sector.city.id)) || vm.geodata){
                                                vm.sector.city = null;
                                            }
                                        }

                                        if(vm.sector.prefecture != null) {
                                            if((vm.sector.prefecture.id === null
                                                    || vm.sector.prefecture.id === undefined || isNaN(vm.sector.prefecture.id)) || vm.geodata){
                                                vm.sector.prefecture = null;
                                            }
                                        }

                                        angular.extend(data, vm.sector);

                                        $http.put('/api/sectors', data)
                                            .success(function (response) {
                                                pharmaciesGrid.data("kendoGrid").dataSource.read();
                                            })
                                            .error(function (response) {
                                                console.log(response);
                                            });
                                    })
                                    .error(function (response) {});
                            },
                            parameterMap: function (options, operation) {
                                if (operation !== 'read') {
                                    return JSON.stringify(options.data.models);
                                } else {
                                    return data;
                                }
                            }
                        },
                        schema : {
                            data: function (data) {
                                angular.forEach( data.content, function (item) {
                                    if ( item.companyType == null ) {
                                        item.companyType = {};
                                    }
                                    if ( item.taxStatus == null ) {
                                        item.taxStatus = {};
                                    }
                                    if ( item.taxOffice == null ) {
                                        item.taxOffice = {};
                                    }
                                    if (item.creditControlCheck === null || item.creditControlCheck === undefined) {
                                        item.creditControlCheck = false;
                                    }
                                });
                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: "id",
                                    name : { editable: false, validation: {required: { message: "Απαραίτητο Πεδίο" } } },
                                    vat : { editable: false, validation: {required: { message: "Απαραίτητο Πεδίο" } } },
                                    address : { editable: false, validation: {required: { message: "Απαραίτητο Πεδίο" } } },
                                    addressNum : { editable: false, validation: {required: { message: "Απαραίτητο Πεδίο" } } },
                                    cityName : { editable: false, validation: {required: { message: "Απαραίτητο Πεδίο" } } },
                                    prefectureName : { editable: false, validation: {required: { message: "Απαραίτητο Πεδίο" } } },
                                    brick : { editable: false },
                                    osfeBrick : { editable: false },
                                    phone : { editable: false, validation: {required: { message: "Απαραίτητο Πεδίο" } } },
                                    createdDate: { type: 'date', editable: false},
                                    companyType: { defaultValue: { id: null, name: null }},
                                    taxStatus: { defaultValue: { id: null, name: null }},
                                    taxOffice: { defaultValue: { id: null, name: null }},
                                    creditControlCheck: { type: "boolean" }
                                }
                            }
                        },
                        pageSize: 100,
                        serverPaging: true,
                        serverSorting: true,
                        scrollable:false,
                        serverFiltering :true
                    }),
                    dataBound: function(e) {
                        if (!vm.canCreateSector) {
                            var grid = pharmaciesGrid.data("kendoGrid");
                            var gridData = grid.dataSource.view();
                            if(gridData.length) {
                                var currentUid = gridData[0].uid;
                                var currentRow = grid.table.find("tr[data-uid='" + currentUid + "']");
                                var editButton = $(currentRow).find(".k-grid-edit");
                                editButton.hide();
                            }
                        }
                    },
                    noRecords: {
                        template: "Δεν βρέθηκαν Φαρμακεία"
                    },
                    sortable: true,
                    pageable: {
                        messages: {
                            display: "{0} - {1} από {2} Φαρμακεία", // {0} is the index of the first record on the page, {1} - the index of the last record on the page, {2} is the total amount of records.
                            empty: "Δεν βρέθηκαν Φαρμακεία",
                            page: "Σελίδα",
                            allPages: "Όλα",
                            of: "από {0}", // {0} is total amount of pages.
                            itemsPerPage: "εγγραφές ανά σελίδα",
                            first: "Πήγαινε στη πρώτη σελίδα",
                            previous: "Πήγαινε στη τελευταία σελίδα",
                            next: "Πήγαινε στην επόμενη σελίδα",
                            last: "Πήγαινε στη τελευταία σελίδα",
                            refresh: "Ανανέωση"
                        }
                    },
                    filterable: {
                        mode: "row"
                    },
                    resizable: true,
                    selectable: true,
                    columns:  vm.gridColumns,
                    height: vm.contentHeight,
                    editable: {
                        mode: "popup",
                        template: kendo.template($("#popup_editor_pharmacies").html())
                    },
                    edit: function(e) {
                        e.model.dirty = true;
                        vm.selectedSector = e.model;
                        if(e.model.id) {
                            vm.sector = vm.selectedSector;
                        } else {
                            vm.sector= {};
                        }

                        //refresh kendoComboBox
                        if(e.model.isNew()) {
                            angular.forEach(comboBoxes, function (item) {
                                var comboBox = angular.element(item).data("kendoComboBox");
                                if (comboBox) {
                                    vm.refreshCombobox({}, comboBox.dataSource);
                                }
                            });
                        }

                        if(!e.model.isNew()) {
                            if(e.model.prefecture && !vm.geodata){
                                var filter = {
                                    "field": "prefecture.id",
                                    "operator": "eq",
                                    "value": e.model.prefecture.id
                                };

                                vm.refreshCombobox(filter, vm.citiesComboBoxOptions.dataSource);
                            }

                            if(e.model.city && !vm.geodata){
                                var filterCity = {
                                    "field"   : "id",
                                    "operator": "eq",
                                    "value"   : e.model.city.prefecture.id
                                };

                                vm.refreshCombobox(filterCity, vm.prefecturesComboBoxOptions.dataSource);
                            }
                        }

                        //if geodata init city and prefecture
                        if(!e.model.city) e.model.city = {};
                        if(!e.model.prefecture) e.model.prefecture = {};


                        KendoGrid.defaultCustomEditPopup(this._editContainer, 1000, 990);

                        // angular.element("div.k-edit-form-container").width(1000);
                        var wnd = angular.element(e.container).data("kendoWindow");
                        wnd.center();
                    },
                    cancel: function() {
                        pharmaciesGrid.data("kendoGrid").dataSource.read();
                    },
                    excel: {
                        allPages: true,
                        fileName: "Φαρμακεία.xlsx"
                    },
                    excelExport: function (e) {
                        const sheet = e.workbook.sheets[0];
                        var columnIdx = null;

                        for (var i=0; i<e.sender.columns.length; i++) {
                            if (e.sender.columns[i].field === "creditControlCheck") {
                                columnIdx = i;
                                break;
                            }
                        }

                        if (columnIdx != null) {
                            var template = kendo.template(this.columns[columnIdx].template);
                            for (var j = 1; j < sheet.rows.length; j++) {
                                var row = sheet.rows[j];

                                var dataItem = {
                                    creditControlCheck: row.cells[columnIdx - 1].value
                                };

                                row.cells[columnIdx - 1].value = template(dataItem);
                            }
                        }

                        vm.showProgress = false;
                    }
                };

            })
            .error(function (response) {
                console.log(response);
            });

        vm.clearDatePickers = function () {
            angular.element("#from").data('kendoDatePicker').value("");
            angular.element("#to").data('kendoDatePicker').value("");
        };

        vm.masterFiltersOnClick = function (e) {
            var date = new Date();
            angular.element(e.target).addClass('k-state-active');

            console.log(e);

            if (e.id == "today") {

                vm.clearDatePickers();

                vm.from = date;
                vm.to = date;

            }
            else if (e.id == "week") {

                vm.clearDatePickers();

                var first = date.getDate() - date.getDay() + 1;
                var firstWeekDay = new Date(new Date().setDate(first)); // First day is the day of the month - the day of the week + 1
                var lastWeekDay = new Date(new Date().setDate(first + 6)); // last day is the first day + 6

                vm.from = firstWeekDay;
                vm.to = lastWeekDay;

            }
            else if (e.id == "month") {

                vm.clearDatePickers();

                var firstMonthDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastMonthDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                vm.from = firstMonthDay;
                vm.to = lastMonthDay;
            }

            pharmaciesGrid.data("kendoGrid").dataSource.read();
        };

        vm.employeesMultiDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: function (o) {
                    var url = '/api/me/employees';

                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }
                    if (o.data.sort == undefined || !o.data.sort.length) {
                        o.data.sort = { "field": "account.lastName",  "dir": "asc" };
                    }
                    if(vm.selectedSupervisors.length) {
                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "supervisor.id", "operator": "in",
                                    "value": vm.selectedSupervisors.toString() }
                            ]
                        );
                    }

                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            { "field": "account.activated", "operator": "eqbool",
                                "value": true }
                        ]
                    );

                    o.data.page = 1;
                    o.data.pageSize = 20;
                    $http.get(url,
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            },
            schema: {
                parse: function (data) {
                    data.forEach(function (item) {
                        item.employee = {
                            account: {
                                fullName: item.account.lastName + " " + item.account.firstName
                            }
                        };
                    });
                    return data;
                }
            }
        });

        vm.employeesMultiOptions = {
            dataSource: vm.employeesMultiDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "employee.account.fullName",
            dataValueField: "id",
            placeholder: "Επιλογή Ιατρικού Επισκέπτη",
            change: function (e) {
                pharmaciesGrid.data("kendoGrid").dataSource.read();
            }
        };

        vm.supervisorsMultiDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: function (o) {
                    var url = '/api/me/employees';
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }
                    if (o.data.sort == undefined || !o.data.sort.length) {
                        o.data.sort = { "field": "account.lastName",  "dir": "asc" };
                    }
                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            { "field": "account.authorities", "operator": "eq",
                                "value": "ROLE_SUPERVISOR" },
                            { "field": "account.activated", "operator": "eqbool",
                                "value": true }

                        ]
                    );
                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            { "field": "account.authorities", "operator": "eq",
                                "value": "ROLE_PHARM_SUPERVISOR" }
                        ]
                    );
                    o.data.page = 1;
                    o.data.pageSize = 20;
                    $http.get(url,
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            },
            schema: {
                parse: function (data) {
                    data.forEach(function (item) {
                        item.employee = {
                            account: {
                                fullName: item.account.lastName + " " + item.account.firstName
                            }
                        };
                    });
                    return data;
                }
            }
        });

        vm.supervisorsMultiOptions = {
            dataSource: vm.supervisorsMultiDataSource,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "employee.account.fullName",
            dataValueField: "id",
            placeholder: "Επιλογή προισταμένου",
            change: function (e) {
                vm.employeesMulti.value('');
                vm.selectedEmployees = [];
                vm.employeesMulti.dataSource.read();
                pharmaciesGrid.data("kendoGrid").dataSource.read();
            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    type: "buttonGroup", id:"buttonGroup",
                    buttons: [
                        { text: "Σήμερα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "today" },
                        { text: "Εβδομάδα", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "week" },
                        { text: "Μήνας", toggle: vm.masterFiltersOnClick, togglable: true, group: "text-align", icon: "refresh", id: "month" }
                    ]
                },
                { type: "separator" },
                { type: "separator" },
                { template: "<label>Από: </label>" },
                {
                    template: "<input kendo-date-picker id='from' options='vm.fromOptions'/>",
                    overflow: "never"
                },
                { template: "<label>Εώς: </label>" },
                {
                    template: "<input kendo-date-picker id='to' options='vm.toOptions'/>",
                    overflow: "never"
                },
                { type: "separator" },
                { type: "separator" },
                {
                    template: "<label ng-if='vm.showFilters'>Ιατρικός Επισκέπτης: </label>"
                },
                {
                    template: "<input ng-if='vm.showFilters' id='multiSelect' style='width: 200px;' class='inline' multiple='multiple' k-options='vm.employeesMultiOptions' k-ng-model='vm.selectedEmployees' kendo-multi-select='vm.employeesMulti'/>",
                    overflow: "never"
                },
                {
                    template: "<label ng-if='vm.showFilters'>Προιστάμενος: </label>"
                },
                {
                    template: "<input ng-if='vm.showFilters' id='multiSelect' style='width: 200px;' name='multiSelect' class='inline' kendo-multi-select multiple='multiple' " +
                    "k-options='vm.supervisorsMultiOptions' k-ng-model='vm.selectedSupervisors'></input>",
                    overflow: "never"
                }
            ]
        };

        vm.fromOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.from = value;
                }

                vm.masterFiltersOnClick(e);
            }
        };

        vm.toOptions = {
            change: function(e) {
                angular.element('#buttonGroup').find('.k-state-active').removeClass('k-state-active');

                var value = this.value();
                if (value) {
                    vm.to = value;
                }

                vm.masterFiltersOnClick(e);
            }
        };

        vm.onSelection = function(data) {
            var sectorId = data.id;
            $state.go('pharmacyInfo', {id: sectorId});
        };

        vm.suggestBricks = function($value) {
            var data = {};
            data.filter ={
                filters: [
                    {
                        "field": "postalCode",
                        "operator": "eq",
                        "value": $value,
                        "ignoreCase": true
                    }
                ]};

            data.page = 1;
            data.pageSize = 1;


            $http.get('/api/masterdata/bricks',
                {params: data})
                .success(function (response) {
                    if (response.content != null
                        && response.content instanceof Array
                        && response.content.length > 0) {
                        vm.sector.brick = response.content[0].code;
                    }
                })
                .error(function (response) {
                    console.log(response);
                });


            $http.get('/api/masterdata/brick-osfes',
                {params: data})
                .success(function (response) {
                    if (response.content != null
                        && response.content instanceof Array
                        && response.content.length > 0) {
                        vm.sector.osfeBrick = response.content[0].code;
                    }
                })
                .error(function (response) {
                    console.log(response);
                });
        };
    }
})();
