(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('EventStatusSearch', EventStatusSearch);

    EventStatusSearch.$inject = ['$resource'];

    function EventStatusSearch($resource) {
        var resourceUrl =  'api/_search/event-statuses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
