/**
 * Created by Teo on 04/09/2019.
 */

(function() {
    'use strict';
    var isDlgOpen;

    angular
        .module('sigmasfApp')
        .controller('EventCycleMdDialogCtrl', EventCycleMdDialogCtrl);

    EventCycleMdDialogCtrl.$inject = ['$scope', '$mdDialog', '$http'];

    function EventCycleMdDialogCtrl ($scope, $mdDialog, $http) {
        $scope.eventCycle = null;
        $scope.message = "";

        $http.get('/api/masterdata/application-parameters',
            {params:{
                    page: 1,
                    pageSize: 1,
                    filter : { filters: [{field: "name", operator: "eq",  value: "Event_Cycle"}] }}})
            .success(function (response) {
                if (response.content.length > 0) {
                    if (response.content[0].value !== null && response.content[0].value !== undefined) {
                        response.content[0].value = Number(response.content[0].value);
                    }else {
                        response.content[0].value = 0;
                    }

                    $scope.eventCycle = response.content[0];
                }
            })
            .error(function (error) {
                $scope.message = error;
            });


        $scope.cancelDialog = function () {
            if (isDlgOpen) return;

            isDlgOpen = false;
            $mdDialog.cancel();
        };

        $scope.update = function () {
            $scope.message = "";
            if ($scope.eventCycle.value == null || $scope.eventCycle.value === undefined) {
                $scope.message = "Η τιμή δεν μπορεί να είναι κενή";
                return;
            }

            if ($scope.eventCycle.value < 0) {
                $scope.message = "Η τιμή δεν μπορεί να είναι μικρότερη του μηδενός";
                return;
            }

            if ($scope.eventCycle.id == null || $scope.eventCycle.id === undefined) {
                $scope.message = "Κάποιο σφάλμα προέκυψε. Η παράμετρος δεν έχει id. Επικοινωνήστε με τον διαχειριστή του συστήματος";
                return;
            }

            $http.put('/api/application-parameters', $scope.eventCycle).success(function () {
                isDlgOpen = false;
                $mdDialog.hide();
            }).error(function () {
                $scope.message = "Κάποιο σφάλμα προέκυψε";
            });
        };
    }
})();
