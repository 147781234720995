(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ApplicationParameterDeleteController',ApplicationParameterDeleteController);

    ApplicationParameterDeleteController.$inject = ['$uibModalInstance', 'entity', 'ApplicationParameter'];

    function ApplicationParameterDeleteController($uibModalInstance, entity, ApplicationParameter) {
        var vm = this;
        vm.applicationParameter = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            ApplicationParameter.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
