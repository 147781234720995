(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CallTypeDialogController', CallTypeDialogController);

    CallTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CallType'];

    function CallTypeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CallType) {
        var vm = this;
        vm.callType = entity;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:callTypeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.callType.id !== null) {
                CallType.update(vm.callType, onSaveSuccess, onSaveError);
            } else {
                CallType.save(vm.callType, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
