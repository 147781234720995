/**
 * Created by Maria on 24/5/2016.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('adminEmployeesAllController', adminEmployeesAllController);

    adminEmployeesAllController.$inject = ['$http', 'KendoFilter'];

    function adminEmployeesAllController ($http, KendoFilter) {
        var vm = this;

        vm.pageHeading = 'Ευρετηριο Ιατρικων Επισκεπτων';
        vm.employeeUrl = '/api/employees/all';
        vm.selectedProductCategories = [];

        vm.collapseAll = function() {
            angular.element("#mainGrid").find(".k-icon.k-i-collapse").trigger("click");
        };

        vm.expandAll = function() {
            angular.element("#mainGrid").find(".k-icon.k-i-expand").trigger("click");
        };

        function productCategoriesDisplay(data, container) {
            if(data.productCategories) {
                return data.productCategories.map(function(elem) {
                    return elem.name
                }).join(', ');
            }
            return "";
        }

        vm.mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                serverFiltering: true,
                transport: {
                    read: function (o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        if(vm.selectedProductCategories.length) {
                            o.data.filter.filters.push(
                                { "field": "productCategories.id", "operator": "in",
                                    "value": vm.selectedProductCategories.toString() }
                            );
                        }
                        $http.get(vm.employeeUrl, { params: o.data })
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (data, operation) {
                        return data;
                    }
                },
                schema: {
                    data: function (data) {
                        data.forEach(function (item) {
                            //if(!item.productCategories.length) { item.productCategories = []; item.productCategories[0] = {name: ""} }
                        });
                        return data;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: {editable: false, nullable: false}
                        }
                    }
                },
                filter: {field: "account.activated", operator: "eqbool", value: true},
                aggregate: [{field: "active", aggregate: "count"}]
            }),
            sortable: true,
            groupable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    field: "account.login",
                    title: "Όνομα σύνδεσης",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "account.firstName",
                    title: "Όνομα",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    groupHeaderTemplate: "#= value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                },
                {
                    field: "account.lastName",
                    title: "Επώνυμο",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    groupHeaderTemplate: "#= value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                },
                {
                    field: "phone",
                    title: "Τηλέφωνο",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "district",
                    title: "Περιοχή",
                    template: "#= (district == 'north') ? 'ΒΟΡΕΙΑ ΕΛΛΑΔΑ' : (district == 'south') ? 'ΝΟΤΙΑ ΕΛΛΑΔΑ' : (district == 'westAndPeloponnese') ? 'ΔΥΤ. ΕΛΛΑΔΟΣ & ΠΕΛΟΠΟΝΝΗΣΟΥ' : '' #",
                    aggregates: ["count"],
                    filterable: {
                        cell: {
                            operator: "startswith",
                            showOperators: false,
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Βόρεια Ελλάδα", value: "north" },
                                            { text: "Νότια Ελλάδα", value: "south" },
                                            { text: "Δυτ. Ελλάδος & Πελοποννήσου", value: "westAndPeloponnese" }]
                                    }),
                                    valuePrimitive: true

                                })
                            }
                        }
                    },
                    groupHeaderTemplate: "#=  (value == 'north') ? 'Βόρεια Ελλάδα' : (value == 'south') ? 'Νότια Ελλάδα'  : (value == 'westAndPeloponnese') ? 'Δυτ. Ελλάδος & Πελοποννήσου' : '' # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                },
                {
                    field: "account.email",
                    title: "Email",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {
                    field: "account.activated",
                    title: "Ενεργός",
                    template: "#= (account.activated == '1') ? 'Ναι' : 'Όχι' #",
                    filterable: {
                        cell: {
                            operator: "eqbool",
                            showOperators: false,
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Ναι", value: "true" },
                                            { text: "Όχι", value: "false" }]
                                    }),
                                    valuePrimitive: true

                                })
                            }
                        }
                    },
                    groupHeaderTemplate: "#= (value == true) ? 'Ενεργός: Ναι' : (value == false) ? 'Ενεργός: Όχι' : ''#"
                },
                {
                    field: "mobileVersion",
                    title: "Mobile Έκδοση",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    groupHeaderTemplate: "#= value # (Σύνολο: #=kendo.format('{0:n0}', count)#)"
                },
                {
                    field: "productCategories.id",
                    title: "Κατηγορίες Προϊόντων",
                    filterable: {
                        cell: {
                            operator: "in",
                            showOperators: false,
                            template: function (args) {
                                args.element.removeAttr('data-bind').kendoMultiSelect({
                                    dataSource: new kendo.data.DataSource({
                                        serverFiltering: true,
                                        transport: {
                                            read: function (o) {
                                                if (o.data.filter == undefined) {
                                                    o.data.filter = {filters: []};
                                                }
                                                o.data.page = 1;
                                                o.data.pageSize = 10000;
                                                $http.get('/api/masterdata/product-categories',
                                                    {params: o.data})
                                                    .success(function (response, status, headers) {
                                                        response.content.unshift({id:"none", name :"(Κενό)"});
                                                        o.success(response.content);
                                                    })
                                                    .error(function (response) {
                                                        console.log(response);
                                                    });
                                            }
                                        }
                                    }),
                                    valuePrimitive: true,
                                    dataTextField: "name",
                                    dataValueField: "id",
                                    change: function () {
                                        vm.selectedProductCategories = this.value();
                                        vm.mainGridOptions.dataSource.read();
                                    },
                                    select: function (e) {
                                        var dataItem = this.dataSource.view()[e.item.index()];
                                        var values = this.value();

                                        if (dataItem.id === "none") {
                                            this.value("");
                                        } else if (values.indexOf("none") !== -1) {
                                            values = angular.element.grep(values, function (value) {
                                                return value !== "none";
                                            });
                                            this.value(values);
                                        }
                                    }
                                })
                            }
                        }
                    },
                    groupable: false,
                    template: productCategoriesDisplay,
                    editor: function (container, options) {
                        angular.element('<select id="productCategories" multiple="multiple" data-bind="value: productCategories"/>')
                            .appendTo(container)
                            .kendoMultiSelect({
                                dataSource: new kendo.data.DataSource({
                                    serverFiltering: true,
                                    transport: {
                                        read: function (o) {
                                            if (o.data.filter == undefined) {
                                                o.data.filter = {filters: []};
                                            }

                                            o.data.page = 1;
                                            o.data.pageSize = 10000;
                                            $http.get('/api/masterdata/product-categories',
                                                {params: o.data})
                                                .success(function (response, status, headers) {
                                                    o.success(response.content);
                                                })
                                                .error(function (response) {
                                                    console.log(response);
                                                });
                                        }
                                    }
                                }),
                                valuePrimitive: false,
                                dataTextField: "name",
                                dataValueField: "id"
                            });
                    }
                    //groupHeaderTemplate: "#= !value ? '(Κενό)' : 'Με τιμή'#"
                }
            ],
            toolbar: [
                { template: "<md-button class='md-raised md-mini md-primary' aria-label='collapseAll' ng-click='vm.collapseAll()'>Collapse All</md-button>" },
                { template: "<md-button class='md-raised md-mini md-primary' aria-label='expandAll' ng-click='vm.expandAll()'>Expand All</md-button>" },
                { name: "excel", template: "<div class='right'><label>Σύνολο: {{vm.mainGridOptions.dataSource.total()}}</label><md-button class='md-raised md-mini md-primary k-button-icontext k-grid-excel' aria-label='excel'>" +
                "<span class='k-icon k-i-excel'></span>Export</md-button></div>" }
            ],
            excel: {
                allPages: true,
                fileName: "Ιατρικοί Επισκέπτες.xlsx"
            }
        }
    }
})();
