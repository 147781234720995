/**
 * Created by Teo on 17/04/2019.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('purchasesAggregatedPerSector', {
                parent: 'purchasesAggregated',
                url: '/perSector',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SUPERVISOR', 'ROLE_ACCOUNTING_DEP'],
                    pageTitle: 'Purchases Aggregated Per Sector'
                },
                templateUrl: 'app/displays/adminIndex/purchasesAggregated/perSector/purchasesAggregatedPerSector.html',
                controller: 'PurchasesAggregatedPerSectorController',
                controllerAs: 'vm',
                params: {
                    search: null
                },
                resolve: {
                    pharmacyVisitors: ['$http','$stateParams', function ($http) {
                        return $http.get('/api/employees/by-authority/?authority=ROLE_PHARM_VISITOR&excludeSystem=true&activated=true').then(function (response) {
                            if (response && response.data){
                                angular.forEach(response.data, function (item) {
                                    item.fullName = item.account ? item.account.lastName + " " + item.account.firstName : ""
                                })
                            }
                            return response.data});
                    }],
                    accountingDeps: ['$http','$stateParams', function ($http) {
                        return $http.get('/api/employees/by-authority/?authority=ROLE_ACCOUNTING_DEP&excludeSystem=true&activated=true').then(function (response) {
                            if (response && response.data){
                                angular.forEach(response.data, function (item) {
                                    item.fullName = item.account ? item.account.lastName + " " + item.account.firstName : ""
                                })
                            }
                            return response.data});
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
