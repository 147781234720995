(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('TempSectorDialogController', TempSectorDialogController);

    TempSectorDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'TempSector', 'SectorType', 'DoctorSpecialty', 'Sector', 'CustomerSector', 'Country', 'Region', 'Prefecture', 'City', 'CompanyType', 'TaxOffice', 'TaxStatus', 'SectorCategory'];

    function TempSectorDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, TempSector, SectorType, DoctorSpecialty, Sector, CustomerSector, Country, Region, Prefecture, City, CompanyType, TaxOffice, TaxStatus, SectorCategory) {
        var vm = this;
        vm.tempSector = entity;
        vm.sectortypes = SectorType.query();
        vm.doctorspecialties = DoctorSpecialty.query();
        vm.sectors = Sector.query();
        vm.customersectors = CustomerSector.query();
        vm.countries = Country.query();
        vm.regions = Region.query();
        vm.prefectures = Prefecture.query();
        vm.cities = City.query();
        vm.approvedsectors = Sector.query({filter: 'tempsector-is-null'});
        $q.all([vm.tempSector.$promise, vm.approvedsectors.$promise]).then(function() {
            if (!vm.tempSector.approvedSector || !vm.tempSector.approvedSector.id) {
                return $q.reject();
            }
            return Sector.get({id : vm.tempSector.approvedSector.id}).$promise;
        }).then(function(approvedSector) {
            vm.approvedsectors.push(approvedSector);
        });
        vm.companytypes = CompanyType.query();
        vm.taxoffices = TaxOffice.query();
        vm.taxstatuses = TaxStatus.query();
        vm.sectorcategories = SectorCategory.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:tempSectorUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.tempSector.id !== null) {
                TempSector.update(vm.tempSector, onSaveSuccess, onSaveError);
            } else {
                TempSector.save(vm.tempSector, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.issuedDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
