(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AnalyticsController', AnalyticsController);

    AnalyticsController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$http'];

    function AnalyticsController ($scope, Principal, LoginService, $state, $http) {
        var vm = this;

        $http.get('/api/employees/me')
            .success(function (response,status,headers) {
                vm.user = response;
                Principal.identity().then(function(account) {
                    var filter = {};
                    if ( account.authorities.indexOf('ROLE_SUPERVISOR') > -1)
                    {
                        switch(vm.user.account.login)
                        {
                            case 'antoniou':
                            case 'vsarakinis':
                                filter = {field: "name", operator: "eq",  value: "Bi_Url_" + vm.user.district};
                                break;
                            case 'vstefanou':
                            case 'kksilouri':
                            case 'mathanasiou':
                                filter = {field: "name", operator: "eq",  value: "Bi_Url_" + vm.user.account.login};
                                break;
                            default:
                                filter = {field: "name", operator: "eq",  value: "Bi_Url"};
                                break;
                        }
                    }
                    else
                    {
                        filter = {field: "name", operator: "eq",  value: "Bi_Url"};
                    }

                    $http.get('/api/masterdata/application-parameters',
                        {params:{
                            page: "1",
                            pageSize: "1",
                            filter : { filters: [filter] }}})
                        .success(function (response,status,headers) {
                            var iframe = document.getElementById('iFrameEmbedReport');
                            iframe.src = response.content[0].value;
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                });
            })
            .error(function (response) {
                console.log(response);
            });

        vm.printIframe = function (iFrameContent) {
            var frame = angular.element(iFrameContent);

            if (!frame) {
                console.log('No iFrame found');
                return;
            }
            window.print();
        };
    }
})();
