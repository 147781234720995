(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('CustomerTitleDetailController', CustomerTitleDetailController);

    CustomerTitleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'CustomerTitle'];

    function CustomerTitleDetailController($scope, $rootScope, $stateParams, entity, CustomerTitle) {
        var vm = this;
        vm.customerTitle = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:customerTitleUpdate', function(event, result) {
            vm.customerTitle = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
