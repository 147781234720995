/**
 * Created by Kristy on 27/7/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('EmployeesAssignationController', EmployeesAssignationController);

    EmployeesAssignationController.$inject = ['$http', '$filter', '$stateParams', 'Sector', 'EmployeesService', 'KendoNotifications', 'KendoValidator', 'NotificationOptions', 'KendoGrid', 'CustomerSectorsService', 'CustomerSector'];

    function EmployeesAssignationController ($http, $filter, $stateParams, Sector, EmployeesService, KendoNotifications, KendoValidator, NotificationOptions, KendoGrid, CustomerSectorsService, CustomerSector) {
        var vm = this;
        vm.massSelect = true;
        vm.employeesService = EmployeesService;
        vm.hasAssignations = vm.employeesService.getHasAssignations();
        vm.selectedEmployees = [];
        vm.myTarget = true;
        vm.selectedUserId = $stateParams.id;
        vm.customerSectorsService = CustomerSectorsService;
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");
        var validator = angular.element("#form").kendoValidator(KendoValidator.getCustomValidatorOptions).data("kendoValidator");
        vm.sector = vm.customerSectorsService.getSector();

        //POPUP grid
        var grid = {};
        grid.dataSource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }

                    $http.get('/api/me/employees',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {});
                },
                parameterMap: function (data, operation) {
                    if (operation !== 'read') {
                        return JSON.stringify(data.models);
                    } else {
                        return data;
                    }
                }
            },
            schema: {
                data: function (data) {
                    if(data != undefined) {
                        data.content.forEach(function(item){

                            if (!item.account) {
                                item.account = {
                                    firstName: "",
                                    lastName: "",
                                    login: ""
                                };
                            }

                            if (!item.supervisor) {
                                item.supervisor = {
                                    account: {fullName: ""}
                                }
                            } else {
                                if (item.supervisor.account.lastName && item.supervisor.account.firstName) {
                                    item.supervisor.account.fullName =
                                        item.supervisor.account.lastName + " " + item.supervisor.account.firstName;
                                } else if (!item.supervisor.account.firstName) {
                                    item.supervisor.account.fullName = item.supervisor.account.lastName;
                                } else if (!item.supervisor.account.lastName) {
                                    item.supervisor.account.fullName = item.supervisor.account.firstName;
                                }
                            }
                        });
                    }
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        id: { editable: false, nullable: false },
                        district : {defaultValue: {}},
                        password: { type: 'password', editable: true, nullable: false, defaultValue: null },
                        supervisor: { defaultValue: { account: { defaultValue: {}} }},
                        account: { defaultValue: {} },
                        mobileVersion: {editable:false}
                    }
                }
            },
            serverFiltering: true,
            serverSorting: true,
            serverPaging: true,
            pageSize: 20
        });
        vm.employeeAllGridOptions = vm.employeesService.getEmployeesOptions(grid.dataSource);
        angular.extend(vm.employeeAllGridOptions, {
            selectedItems: function(dataItems) {
                vm.selectedEmployees = dataItems;
            },
            dataBound: function (e) {
                angular.element("#employeeAllGridcheckbox").prop('checked', false);
                //toggle class on click
                angular.element(".checkbox").bind("change", function (e) {
                    angular.element(e.target).closest("tr").toggleClass("k-state-selected");
                });

                var view = this.dataSource.data();
                var me = this;

                if (vm.selectedEmployees) {
                    var selectedEmployeesViewCounter = 0;
                    angular.forEach( view, function(item) {
                        var foundItemById = $filter('filter')(vm.selectedEmployees, {id: item.id}, true)[0];
                        if(vm.selectedEmployees.indexOf(foundItemById) !== -1){
                            me.tbody.find("tr[data-uid='" + item.uid + "']")
                                .addClass("k-state-selected");
                            item.selected = true;
                            selectedEmployeesViewCounter++
                        }
                    });
                    if(selectedEmployeesViewCounter == view.length) {
                        angular.element("#employeeAllGridcheckbox").prop('checked', true);
                    }
                }
            }
        });
        KendoGrid.refresh(vm.employeeAllGridOptions)

        vm.updateSector = function(){
            if (!vm.customerSectorsService.getSector().id) {
                if (validator.validate()) {
                    Sector.update({}, vm.customerSectorsService.getSector(),
                        function (response) {
                            KendoNotifications.onSave(notification, "Η Μονάδα Υγείας προστέθηκε με επιτυχία", "success");
                            vm.savedSector = true;
                            vm.customerSectorsService.setIsSectorSaved(true);
                            vm.customerSectorsService.setSector(response);
                            vm.saveCustomerSectors();
                        },
                        function () {
                            KendoNotifications.onSave(notification, "Η προσθήκη της Μονάδα Υγείας απέτυχε", "error");
                        });
                } else {
                    KendoNotifications.onSave(notification, "Η προσθήκη της Μονάδα Υγείας απέτυχε", "error");
                }
            } else {
                vm.saveCustomerSectors();
            }
        };

        vm.saveCustomerSectors = function() {
            var wdw = angular.element("#popup-new-customSector").data("kendoWindow");
            vm.choices = [];
            angular.forEach( vm.selectedEmployees, function(item) {
                var modifiedItem = {
                    active: true,
                    myTarget: vm.myTarget,
                    employee: { id: item.id },
                    id: null
                };
                vm.choices.push(modifiedItem)
            });
            var modifiedCustomerSector = {
                id: null,
                employeeCustomerSectors: vm.choices,
                customer: { id: vm.selectedUserId },
                startedAt: new Date(),
                sector: vm.customerSectorsService.getSector()
            };

            vm.newCustomerSector = vm.customerSectorsService.getCustomerSector();
            angular.extend(vm.newCustomerSector, modifiedCustomerSector);

            if(!vm.newCustomerSector.sectorPosition.id) vm.newCustomerSector.sectorPosition = null;

            CustomerSector.update({}, vm.newCustomerSector,
                function () {
                    KendoNotifications.onSave(notification, "Η ανάθεση Μονάδα Υγείας πραγματοποιήθηκε με επιτυχία!", "success");
                    angular.element("#sectorsGrid").data("kendoGrid").dataSource.read();
                    delete vm.newSectorObj;
                    wdw.close();
                },
                function () {
                    KendoNotifications.onSave(notification, "Η ανάθεση Μονάδα Υγείας απέτυχε", "error");
                });

            //clear dataItems selected
            vm.statusChanged = "saved";
        };
    }
})();
