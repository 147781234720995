/**
 * Created by Teo on 01/06/2018.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProspectivePatientViewLeadCallsController', ProspectivePatientViewLeadCallsController);

    ProspectivePatientViewLeadCallsController.$inject = [ '$stateParams', '$http', '$state', '$filter', 'KendoFilter', 'LeadCall', 'callTypes', 'CustomUtils'];

    function ProspectivePatientViewLeadCallsController ( $stateParams, $http, $state, $filter, KendoFilter, LeadCall, callTypes, CustomUtils) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(58);
        var grid = {};
        var prospectivePatientId = $stateParams.id;
        vm.selectedLeadCallId = null;
        vm.leadCall = {};
        vm.callTypes = callTypes;
        vm.showProgress = false;

        grid.datasource = new kendo.data.DataSource({
            batch: true,
            transport: {
                read: function(o) {
                    if (o.data.filter === undefined) {
                        o.data.filter = { filters: [] };
                    }
                    if(vm.from && vm.to) {
                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "dateTime", "operator": "fromdate",
                                    "value": $filter('date')(vm.from, 'yyyy-MM-dd')},
                                { "field": "dateTime", "operator": "todate",
                                    "value": $filter('date')(vm.to, 'yyyy-MM-dd')}
                            ]
                        );
                    }
                    if (o.data.sort === undefined || !o.data.sort.length) {
                        o.data.sort = { "field": "dateTime",  "dir": "desc" };
                    }
                    angular.forEach( o.data.filter.filters, function(item) {
                        switch (item.field){
                            case "dateTime":
                                if (item.value instanceof Date) {
                                    item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                                }
                                break;
                            case "nextFollowUpDate":
                                if (item.value instanceof Date) {
                                    item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                                }
                                break;
                            default:
                                break;
                        }
                    });

                    o.data.filter.filters =
                        o.data.filter.filters.concat([ { "field": "prospectivePatient.id", "operator": "eq", "value": prospectivePatientId } ]);
                    $http.get('/api/lead-calls/search',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response);
                        });
                }
            },
            schema : {
                data: function (data) {
                    if(data.content !== undefined) {
                        data.content.forEach(function(item){
                            if (!item.callType) item.callType = {};
                            if (!item.prospectivePatient){
                                console.log(item);
                                item.prospectivePatient = {};
                            }
                            if (item.prospectivePatient && !item.prospectivePatient.attendant){
                                item.prospectivePatient.attendant = {}
                            }
                            if (!item.oldPatient) item.oldPatient = {};
                            if (!item.oldPatientDoctor) item.oldPatientDoctor = {};

                        });
                    }
                    return data.content;
                },
                total: function (data) {
                    return data.totalEntries;
                },
                model: {
                    id: "id",
                    fields: {
                        dateTime: { type: "date" },
                        nextFollowUpDate: { type: "date"}
                    }
                }
            },
            pageSize: 100,
            serverPaging: true,
            serverSorting: true,
            scrollable:true,
            serverFiltering :true
        });
        grid.columns = vm.gridColumns = [
            {
                field: "dateTime", title: "Ημερομηνία", template: "#= dateTime ? kendo.toString(kendo.parseDate(dateTime), 'dddd, dd/MM/yyyy, HH:mm') : ''#",
                filterable: {
                    cell: {
                        operator: "eqdate",
                        showOperators: false
                    }
                }
            }
        ];

        vm.leadCallsGridOptions = {
            dataSource: grid.datasource,
            sortable: true,
            pageable: true,
            filterable: {
                mode: "row"
            },
            resizable: true,
            selectable: true,
            columns:  grid.columns,
            editable: false/*,
            dataBound: resizeGrid,
            toolbar: [{ template: "<div class='right'><label>Σύνολο: {{vm.hospitalizationsGridOptions.dataSource.total()}}</label></div>",
                overflow: "never" }]*/
        };

        vm.onSelection = function(data) {
            vm.showProgress = true;
            vm.selectedLeadCallId = data.id;
            LeadCall.get({id : vm.selectedLeadCallId}).$promise.then(function (result) {
                vm.showProgress = false;
                if (result){
                    //console.log(result);
                    vm.leadCall = result;
                    // Quick workaround for setup callType radio button
                    vm.leadCall.callType = vm.callTypes.find(function (callType) {
                        return vm.leadCall.callType.id === callType.id;
                    });
                    vm.startDate = vm.startTime = vm.leadCall.dateTime;
                    vm.setData(["prospectivePatient", "attendant", "oldPatient", "oldPatientDoctor"]);
                }else {
                    vm.leadCall = {};
                }
            });
        };

        vm.setData = function (model) {
            if( vm.leadCall.prospectivePatient && model.indexOf("prospectivePatient") !== -1 ) {
                vm.leadCall.prospectivePatient.fullName = vm.leadCall.prospectivePatient.lastName + " " + vm.leadCall.prospectivePatient.firstName;
            }else {
                vm.leadCall.prospectivePatient = {};
            }
            if( vm.leadCall.prospectivePatient && vm.leadCall.prospectivePatient.attendant && model.indexOf("attendant") !== -1 ) {
                vm.leadCall.prospectivePatient.attendant.fullName = vm.leadCall.prospectivePatient.attendant.lastName + " " + vm.leadCall.prospectivePatient.attendant.firstName;
            }else {
                vm.leadCall.prospectivePatient = {};
                vm.leadCall.prospectivePatient.attendant = {};
            }
            if( vm.leadCall.oldPatient && model.indexOf("oldPatient") !== -1 ) {
                vm.leadCall.oldPatient.fullName = vm.leadCall.oldPatient.lastName + " " + vm.leadCall.oldPatient.firstName;
            }else {
                vm.leadCall.oldPatient = {};
            }
            if( vm.leadCall.oldPatientDoctor && model.indexOf("oldPatientDoctor") !== -1 ) {
                vm.leadCall.oldPatientDoctor.fullName = vm.leadCall.oldPatientDoctor.lastName + " " + vm.leadCall.oldPatientDoctor.firstName;
            }else {
                vm.leadCall.oldPatientDoctor = {};
            }
        };

        vm.goTo = function (where, id) {
            switch (where){
                case "prospectivePatient":
                    $state.go('prospectivePatientInfo', {id: id});
                    break;
                case "attendant":
                    // View does not exist
                    //$state.go('attendantView', {id: id});
                    console.log("View does not exist! Coming soon!");
                    break;
                case "oldPatient":
                    $state.go('patientInfo', {id: id});
                    break;
                case "oldPatientDoctor":
                    $state.go('customerInfo', {id: id});
                    break;
                case "leadCall":
                    $state.go('leadCallView', {id: id});
                    break;
            }
        }

        /*resizeGrid();
        $(window).resize(function(){
            resizeGrid();
        });*/
        /*function resizeGrid() {
            console.log("resizeGrid");
            var gridElement = $("#patientLeadCallsGrid"),
                dataArea = gridElement.find(".k-grid-content"),
                gridHeight = gridElement.innerHeight(),
                otherElements = gridElement.children().not(".k-grid-content"),
                otherElementsHeight = 0;
            otherElements.each(function(){
                otherElementsHeight += $(this).outerHeight();
            });
            dataArea.height(gridHeight - otherElementsHeight);
        }*/
    }
})();
