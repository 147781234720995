(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('OtherEventDeleteController',OtherEventDeleteController);

    OtherEventDeleteController.$inject = ['$uibModalInstance', 'entity', 'OtherEvent'];

    function OtherEventDeleteController($uibModalInstance, entity, OtherEvent) {
        var vm = this;
        vm.otherEvent = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            OtherEvent.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
