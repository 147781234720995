/**
 * Created by gmogas on 11/11/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AdminPharmacyViewCustomersController', AdminPharmacyViewCustomersController);

    AdminPharmacyViewCustomersController.$inject = ['$http', '$stateParams', 'Principal', 'Customer', 'CustomerSector'];

    function AdminPharmacyViewCustomersController ($http, $stateParams, Principal, Customer, CustomerSector) {
        var vm = this;

        var modal = angular.element('#popup-customer');
        var grid = angular.element('#customerSectorGrid');
        var multiSelect = angular.element('#customerType');

        vm.selectedSectorId = $stateParams.id;
        vm.selectedCustomerTypes = [];
        vm.pageHeading = 'Επαφές Φαρμακείου';
        vm.editableItem = {};
        vm.isSaving = false;

        var urls = {};
        urls.sectorCustomerSectors = '/api/sectors/'+vm.selectedSectorId+'/customer-sectors';

        vm.modalWindowOptions = {
            title: "Νέα επαφή",
            visible: false,
            modal: true,
            resizable: true,
            width: 800,
            height: 700
        };

        // DataSources
        vm.dataSource = {};
        vm.dataSource.grid = {};
        vm.dataSource.grid.customerSector = new kendo.data.DataSource({
            autoBind: false,
            transport: {
                read: function (o) {
                    $http.get(urls.sectorCustomerSectors,{})
                        .success(function (response) {
                            o.success(response);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            },
            schema: {
                data: function (data) {
                    if(data != undefined) {
                        data.forEach(function(item) {
                            item.types = "";
                            if (item.customer != null && item.customer.customerTypes != null) {
                                item.customer.customerTypes.forEach(function (type) {
                                    if (item.types === "") {
                                        item.types = type.name;
                                    } else {
                                        item.types = item.types + ", " + type.name;
                                    }
                                })
                            }
                        });
                    }
                    return data;
                },
                model: {
                    id: "id",
                    fields: {
                        id: { editable: false, nullable: true },
                        startedAt: { type: 'date'},
                        endedAt: { type: 'date', defaultValue : null}
                    }
                }
            },
            batch: true,
            pageSize: 100,
            scrollable:false
        });

        // Grid Columns
        vm.gridColumns = {};
        vm.gridColumns.customerSector = [
            {
                field: "types",
                title: "Θέση",
                filterable: false
            },
            {
                field: "customer.lastName",
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.amka",
                title: "Α.Μ.Κ.Α.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.cellPhone",
                title: "Κινητό",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.phone",
                title: "Σταθερό Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.email",
                title: "Email",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            },
            {
                field: "startedAt",
                template: "#= startedAt ? kendo.toString(kendo.parseDate(startedAt), 'dddd, dd/MM/yyyy') : ''#",
                title: "Από",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            },
            {
                field: "endedAt",
                template: "#= endedAt ? kendo.toString(kendo.parseDate(endedAt), 'dddd, dd/MM/yyyy') : ''#",
                title: "Έως",
                filterable: {
                    cell: {
                        operator: "startswith",
                        showOperators: false
                    }
                }
            }
        ];

        vm.canEditCustomerSector = false;
        if (Principal.hasAnyAuthority(['ROLE_SYSTEM', 'ROLE_ADMIN', 'ROLE_ACCOUNTING_DEP', 'ROLE_MANAGEMENT', 'ROLE_PHARM_SUPERVISOR'])) {
            vm.canEditCustomerSector = true;
            vm.gridColumns.customerSector.unshift({
                command:
                {
                    name: "edit",
                    text: "",
                    click: editButtonPush
                },
                title: "&nbsp;",
                width: 80
            });
        }

        vm.options = {};
        vm.options.customerTypes = {
            dataTextField: "name",
            dataValueField: "id",
            valuePrimitive: true,
            autoBind: true,
            dataSource: {
                data : [
                    {id : 3, name : "ΦΑΡΜΑΚΟΠΟΙΟΣ"},
                    {id : 4, name : "ΥΠΕΥΘΥΝΟΣ ΦΑΡΜΑΚΕΙΟΥ"},
                    {id : 5, name : "ΒΟΗΘΟΣ ΦΑΡΜΑΚΕΙΟΥ"}
                ]
            }
        };

        vm.gridOptions = {};
        vm.gridOptions.customerSector = {
            dataSource: vm.dataSource.grid.customerSector,
            resizable: true,
            selectable: true,
            columns: vm.gridColumns.customerSector,
            toolbar: [
                {
                    name: "New Customer Sector",
                    text: "New Customer Sector",
                    template: "<input type='button' class='k-button' value = 'Προσθήκη νέας επαφής' ng-show='vm.canEditCustomerSector' ng-click='vm.openModalWindow()' />"
                }
            ]
        };

        vm.closeModalWindow = function() {
            vm.editableItem = {};
            modal.data("kendoWindow").close();
        };

        vm.openModalWindow = function() {
            vm.editableItem = {};
            vm.editableItem.customer = {};
            vm.editableItem.customer.customerTypes = [{id : 3, name : "ΦΑΡΜΑΚΟΠΟΙΟΣ"}];
            vm.editableItem.sector = {};
            vm.editableItem.sector.id = vm.selectedSectorId;
            vm.editableItem.startedAt = getTodayDate();
            vm.showModalWindow();
        };

        function editButtonPush(e) {
            e.preventDefault();
            vm.editableItem = this.dataItem($(e.currentTarget).closest("tr"));
            vm.showModalWindow();
        }

        vm.showModalWindow = function() {
            vm.selectedCustomerTypes = [];
            vm.editableItem.customer.customerTypes.forEach(function (item) {
                vm.selectedCustomerTypes.push(item.id);
            });
            multiSelect.data('kendoMultiSelect').value(vm.selectedCustomerTypes);
            modal.data("kendoWindow").center().open();
        };

        vm.save = function () {
            vm.isSaving = true;

            vm.editableItem.customer.customerTypes = [];
            vm.selectedCustomerTypes.forEach(function (item) {
                vm.editableItem.customer.customerTypes.push({ id: item});
            });

            if (vm.editableItem.customer.id != null) {
                Customer.update(vm.editableItem.customer, onCustomerSaveSuccess, onCustomerSaveError);
            } else {
                Customer.save(vm.editableItem.customer, onCustomerSaveSuccess, onCustomerSaveError);
            }
        };

        var onCustomerSaveSuccess = function(result) {
            vm.editableItem.customer = result;

            vm.editableItem.startedAt = kendo.toString(kendo.parseDate(vm.editableItem.startedAt), 'yyyy-MM-dd');
            vm.editableItem.endedAt = kendo.toString(kendo.parseDate(vm.editableItem.endedAt), 'yyyy-MM-dd');

            console.log(vm.editableItem);
            if (vm.editableItem.id != null) {
                CustomerSector.update(vm.editableItem, onCustomerSectorSaveSuccess, onCustomerSectorSaveError);
            } else {
                CustomerSector.save(vm.editableItem, onCustomerSectorSaveSuccess, onCustomerSectorSaveError);
            }
        };

        var onCustomerSaveError = function() {
            vm.isSaving = false;
            console.log("errorrr on saving the customer");
        };

        var onCustomerSectorSaveSuccess = function(result) {
            grid.data('kendoGrid').dataSource.read();
            vm.isSaving = false;
            vm.closeModalWindow();
        };

        var onCustomerSectorSaveError = function() {
            vm.isSaving = false;
            console.log("errorrr on saving the customer sector");
        };

        function getTodayDate() {
            var today = new Date();

            var returnDate;

            if (today.getDate() < 10) {
                returnDate = today.getFullYear()
                    + '-'
                    + (today.getMonth() + 1)
                    + '-0'
                    + today.getDate();
            } else {
                returnDate = today.getFullYear()
                    + '-'
                    + (today.getMonth() + 1)
                    + '-'
                    + today.getDate();
            }

            return returnDate;
        }
    }
})();
