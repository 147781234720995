/**
 * Created by Kristy on 9/6/2017.
 */
(function () {
    'use strict';

    angular
        .module('sigmasfApp')
        .service('HospitalizationGrids', HospitalizationGrids);

    HospitalizationGrids.$inject = ['$http', 'KendoFilter'];
    function HospitalizationGrids ($http, KendoFilter) {
        var popupGrid = {};

        //Grid's Columns initialization
        var customerColumns = [
            {
                field: "customer.lastName",
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "customer.doctorSpecialty.name",
                title: "Ειδικότητα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "sector.name",
                title: "Όνομα Μονάδας Υγείας",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];
        var patientColumns = [
            {
                field: "lastName",
                title: "Επώνυμο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "firstName",
                title: "Όνομα",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "amka",
                title: "Α.Μ.Κ.Α",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "phone",
                title: "Τηλ. Επικοινωνίας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "email",
                title: "Email Επικοινωνίας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "yearOfBirth", title: "Έτος Γέννηση",
                format: "{0:yyyy}",
                filterable: {
                    cell: {
                        operator: "startswithnum",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "notes",
                title: "Σχόλια",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];
        var sectorColumns = [
            {field: "name", title: "Όνομα Μονάδας Υγείας",
                filterable: {
                    cell: {
                        operator: "like",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "sectorType.name", title: "Τύπος Μονάδας Υγείας",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "address", title: "Διεύθυνση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "postalCode", title: "Τ.Κ.",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {field: "cityName", title: "Πόλη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];
        var prospectivePatientColumns = [
            {
                field: "lastName",
                title: "Επώνυμο Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "firstName",
                title: "Όνομα Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "phone",
                title: "Τηλέφωνο",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "email",
                title: "Email",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "birthYear",
                title: "Έτος Γέννησης",
                filterable: {
                    cell: {
                        operator: "startswithnum",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "cityName",
                title: "Πόλη",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "address",
                title: "Διεύθυνση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "attendant.fullName", title: "Συνοδός",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                },
                sortable: false
            }
        ];
        var leadCallColumns = [
            {
                field: "dateTime",
                title: "Ημερομηνία Κλήσης",
                template: "#= dateTime ? kendo.toString(kendo.parseDate(dateTime), 'dddd, dd/MM/yyyy, HH:mm') : ''#",
                filterable: {
                    cell: {
                        operator: "eqdate",
                        showOperators: false
                    }
                }
            },
            {
                field: "leadOffers.offerStatus.name",
                title: "Προσφορά",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "prospectivePatient.firstName",
                title: "Όνομα Πιθανού Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "prospectivePatient.lastName",
                title: "Επώνυμο Πιθανού Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "prospectivePatient.phone",
                title: "Τηλέφωνο Πιθανού Ασθενή",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "leadHospitalizations.hospitalizationType.name",
                title: "Ασφάλιση",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "prospectivePatient.attendant.firstName",
                title: "Όνομα Συνοδού",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "prospectivePatient.attendant.lastName",
                title: "Επώνυμο Συνοδού",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            },
            {
                field: "prospectivePatient.attendant.phone",
                title: "Τηλέφωνο Συνοδού",
                filterable: {
                    cell: {
                        operator: "startswith",
                        template: function (args) {
                            KendoFilter.getTemplate(args);
                        },
                        showOperators: false
                    }
                }
            }
        ];

        //Grid's Datasource initialization
        var getCustomerDatasource = function () {
            return new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = { filters: [] };
                        }
                        // get only active customer-sectors
                        o.data.active = true;

                        $http.get('/api/me/customer-sectors',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            });
                    }
                },
                schema : {
                    data: function (data) {
                        if(data.content !== undefined) {
                            angular.forEach( data.content, function( item ) {
                                if( !item.customer.doctorSpecialty ) item.customer.doctorSpecialty = {};
                                if( !item.sector ) item.sector = {};
                            })
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    }
                },
                pageSize: 100,
                serverPaging: true,
                serverSorting: true,
                scrollable:false,
                serverFiltering :true
            });
        };
        var getPatientDatasource = function () {
            return new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/patients/search',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            });
                    }
                },
                schema : {
                    data: function (data) {
                        if(data.content !== undefined) {
                            angular.forEach( data.content, function (item) {
                                if (item.yearOfBirth) {
                                    var date = new Date();
                                    date.setFullYear(item.yearOfBirth);
                                    item.yearOfBirth = date;
                                }
                            })
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    }
                },
                pageSize: 100,
                serverPaging: true,
                serverSorting: true,
                scrollable:false,
                serverFiltering :true
            });
        };
        var getSectorDatasource = function () {
            return new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = { filters: [] };
                        }

                        $http.get('/api/me/sectors',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    }
                },
                schema: {
                    data: function (data) {
                        if(data.content !== undefined) {
                            data.content.forEach(function(item){
                                if(item.name === null) item.name = "";
                                if(item.sectorType === null) item.sectorType = {};
                            });
                        }

                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    }
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                pageSize: 100
            })
        };
        var getProspectivePatientDatasource = function () {
            return new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) { o.data.filter = { filters: [] }; }

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                            }
                        });

                        $http.get('/api/prospective-patients/search',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            });
                    },
                    create: function (o){
                        var data = o.data.models[0];
                        /*if (data.age) {
                            data.birthYear = CalculateAge.getBirthYearFromAge(data.age).getFullYear();
                        } else {
                            data.birthYear = "";
                        }*/

                        if( !data.city.id ) data.city = null;
                        if( !data.prefecture.id ) data.prefecture = null;
                        if( !data.attendant.id ) data.attendant = null;
                        ProspectivePatient.update({}, data,
                            function(){ KendoGrid.refresh(vm.prospectivePatientsGridOptions) });
                    },
                    update: function (o){
                        var data = o.data.models[0];
                        /*if (data.age) {
                            data.birthYear = CalculateAge.getBirthYearFromAge(data.age).getFullYear();
                        } else {
                            data.birthYear = "";
                        }*/

                        if( !data.city.id ) data.city = null;
                        if( !data.prefecture.id ) data.prefecture = null;
                        if( !data.attendant.id ) data.attendant = null;
                        ProspectivePatient.update({}, data,
                            function(){ KendoGrid.refresh(vm.prospectivePatientsGridOptions) });
                    }
                },
                schema : {
                    data: function (data) {
                        if(data.content !== undefined) {
                            data.content.forEach(function(item){
                                if (item.attendant) {
                                    if (item.attendant.lastName && item.attendant.firstName) {
                                        item.attendant.fullName = item.attendant.lastName + " " + item.attendant.firstName;
                                    } else if (!item.attendant.firstName) {
                                        item.attendant.fullName = item.attendant.lastName;
                                    } else if (!item.attendant.lastName) {
                                        item.attendant.fullName = item.attendant.firstName;
                                    }
                                }
                                if (item.attendant === null) item.attendant = {};
                                if (item.profession === null) item.profession = {};

                                /*if (item.birthYear) {
                                    var date = new Date();
                                    date.setFullYear(item.birthYear);
                                    item.birthYear = date;
                                    item.age = CalculateAge.getAgeFromBirthYear(item.birthYear);
                                }*/
                            });
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            lastName: { validation: { required: { message: "Το επώνυμο του Ασθενή είναι υποχρεωτικό" } } },
                            firstName: { validation: { required: { message: "Το όνομα του Ασθενή είναι υποχρεωτικό" } } },
                            age: { type: "number" },
                            email: { type: "email" },
                            birthYear: { type: "number", validation: { max: 4 } },
                            attendant: { defaultValue: {} },
                            profession: { defaultValue: {} }
                        }
                    }
                },
                pageSize: 100,
                serverPaging: true,
                serverSorting: true,
                scrollable:false,
                serverFiltering :true
            });
        };
        var getLeadCallDatasource = function () {
            return new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter === undefined) {
                            o.data.filter = { filters: [] };
                        }
                        if (o.data.sort === undefined || !o.data.sort.length) {
                            o.data.sort = { "field": "dateTime",  "dir": "desc" };
                        }
                        angular.forEach( o.data.filter.filters, function(item) {
                            switch (item.field){
                                case "dateTime":
                                    if (item.value instanceof Date) {
                                        item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                                    }
                                    break;
                                case "nextFollowUpDate":
                                    if (item.value instanceof Date) {
                                        item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                                    }
                                    break;
                                case "leadHospitalizations.possibleDischargeDate":
                                    if (item.value instanceof Date) {
                                        item.value = $filter('date')(item.value, 'yyyy-MM-dd');
                                    }
                                    break;
                                default:
                                    break;
                            }
                        });

                        // filter only LeadCalls that hasn't turned into Hospitalization
                        o.data.filter.filters.push(
                            { "field": "hospitalizations",
                                "operator": "isnull",
                                "value": true }
                        );
                        $http.get('/api/lead-calls/search',
                            {params: o.data})
                            .success(function (response) {
                                //console.log(response);
                                o.success(response);
                            });
                    }
                },
                schema : {
                    data: function (data) {
                        if(data.content !== undefined) {
                            data.content.forEach(function(item){
                                if (!item.callType) item.callType = {};
                                if (!item.prospectivePatient) item.prospectivePatient = {};
                                if (item.prospectivePatient && !item.prospectivePatient.attendant) item.prospectivePatient.attendant = {};
                                if (!item.oldPatient) item.oldPatient = {};
                                if (!item.oldPatientDoctor) item.oldPatientDoctor = {};
                                if (!item.leadOffers) item.leadOffers = {};
                                if (!item.leadOffers.offerStatus) item.leadOffers.offerStatus = {};
                                if (!item.leadHospitalizations) item.leadHospitalizations = {};
                                if (!item.leadHospitalizations.hospitalizationType) item.leadHospitalizations.hospitalizationType = {};
                            });
                        }
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            dateTime: { type: "date" },
                            nextFollowUpDate: { type: "date"},
                            'leadHospitalizations.possibleDischargeDate': {type: 'date'}
                        }
                    }
                },
                pageSize: 100,
                serverPaging: true,
                serverSorting: true,
                scrollable:false,
                serverFiltering :true
            });
        };

        return {
            getGridOptions: getGridOptions,
            getGridTitle: getGridTitle
        };

        function getGridOptions( grid ) {
            switch (grid) {
                case 'patient':
                    popupGrid.columns = patientColumns;
                    popupGrid.datasource = getPatientDatasource();
                    break;
                case 'customer':
                    popupGrid.columns = customerColumns;
                    popupGrid.datasource = getCustomerDatasource();
                    break;
                case 'sector':
                    popupGrid.columns = sectorColumns;
                    popupGrid.datasource = getSectorDatasource();
                    break;
                case 'prospectivePatient':
                    popupGrid.columns = prospectivePatientColumns;
                    popupGrid.datasource = getProspectivePatientDatasource();
                    break;
                case 'leadCall':
                    popupGrid.columns = leadCallColumns;
                    popupGrid.datasource = getLeadCallDatasource();
                    break;
                default:
            }

            return {
                dataSource: popupGrid.datasource,
                sortable: true,
                pageable: true,
                filterable: {
                    mode: "row"
                },
                resizable: true,
                selectable: true,
                columns:  popupGrid.columns,
                editable: false,
                grid: grid
            };
        }

        function getGridTitle( grid ){
            switch (grid){
                case 'patient':
                    return "Επιλέξτε Ασθενή";
                case 'customer':
                    return "Επιλέξτε Επαγγελματία Υγείας";
                case 'sector':
                    return "Επιλέξτε Νοσοκομείο";
                case 'prospectivePatient':
                    return "Επιλέξτε Ασθενή από LeadManagement";
                case 'leadCall':
                    return "Επιλέξτε Κλήση από LeadManagement";
                default:
                    return "Επιλέξτε";
            }
        }
    }
})();
