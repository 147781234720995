/**
 * Created by Kristy on 2/8/2017.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('SectorInfoController', SectorInfoController);

    SectorInfoController.$inject = ['$http', '$state', '$timeout', '$stateParams', 'KendoService', 'NotificationOptions', 'CustomUtils', 'Principal', 'CustomDataUtils'];

    function SectorInfoController ($http, $state, $timeout, $stateParams, KendoService, NotificationOptions, CustomUtils, Principal, CustomDataUtils) {
        var vm = this;
        vm.contentHeight = CustomUtils.getHeightPixelsFromPercentage(50);
        vm.$state = $state;
        vm.pageHeading = 'Μονάδα Υγείας';
        vm.selectedSectorId = $stateParams.id;
        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");

        vm.editSectorType = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGEMENT', 'ROLE_SUPERVISOR', 'ROLE_ACCOUNTING_DEP', 'ROLE_REC_ADMINISTRATOR', 'ROLE_REC_FRONT_DESK']);

        // DataSources
        vm.dataSource = {};
        vm.dataSource.sectorTypes =  new kendo.data.DataSource({
            transport: {
                read: function(o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }
                    o.data.filter.filters.push({ "field": "sectorTypeCategory", "operator": "startswith", "value": 'hcu'});

                    o.data.page = 1;
                    o.data.pageSize = 100000;
                    $http.get( '/api/masterdata/sector-types',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            }
        });
        vm.dataSource.companyTypes = KendoService.initDefaultDataSource('/api/masterdata/company-types');
        vm.dataSource.taxOffices = KendoService.initDefaultDataSource('/api/masterdata/tax-offices');
        vm.dataSource.doctorSpecialty = KendoService.initDefaultDataSource('/api/masterdata/doctor-specialties');
        vm.dataSource.sectors = new kendo.data.DataSource({
            serverFiltering: true,
            transport: {
                read: function (o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = {filters: []};
                    }
                    o.data.filter.filters = o.data.filter.filters.concat(
                        [
                            //isPrivate eqbool false
                            {
                                "field": "iseditable",
                                "operator": "eqbool",
                                "value": false }
                        ]
                    );

                    o.data.page = 1;
                    o.data.pageSize = 20;
                    $http.get('/api/me/sectors',
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });
        vm.dataSource.workingHours = new kendo.data.DataSource({
            data: [
                { value: 'morning', text: 'Πρωί'},
                { value: 'afternoon', text: 'Απόγευμα'},
                { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα'}
            ]
        });

        // Drop Down Options
        vm.dropDownOptions = {};
        vm.dropDownOptions.companyType = {
            dataSource: vm.dataSource.companyTypes,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };
        vm.dropDownOptions.taxOffice = {
            dataSource: vm.dataSource.taxOffices,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };
        vm.dropDownOptions.sector = {
            dataSource: vm.dataSource.sectors,
            filter: "like",
            valuePrimitive: false,
            dataTextField: "name",
            dataValueField: "name"
        };
        vm.dropDownOptions.doctorSpecialty = {
            dataSource: vm.dataSource.doctorSpecialty,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "name",
            dataValueField: "id"
        };
        vm.dropDownOptions.workingHours = {
            dataSource: vm.dataSource.workingHours,
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "text",
            dataValueField: "value"
        };

        vm.dropDownOptions.sectorType = KendoService.initDefaultOptions(vm.dataSource.sectorTypes, "startswith", "name", "id");
        vm.dropDownOptions.sectorType.change = function() {
            var obj = this.dataItem();

            //$timeout to trigger the change
            $timeout(function(){
                vm.isPrivate = obj.isPrivate;
                if(vm.isPrivate) {
                    vm.sector.doctorSpecialty = null;
                    vm.specialtyComboBox.value('');
                    vm.sector.parent = null;
                    vm.sectorsComboBox.text('');
                    vm.sectorsComboBox.value('');
                }
            });
        };

        $http.get('api/sectors/' + vm.selectedSectorId)
            .success(function (response) {
                vm.sector = response;
                if (vm.sector.sectorType) {
                    vm.isPrivate = vm.sector.sectorType.isPrivate;
                }

                $http.get('/api/employees/me')
                    .success(function (response) {
                        vm.geodata = response.geodata;
                        if(!vm.geodata) {
                            var prefecturesDataSource = new kendo.data.DataSource({
                                serverFiltering: true,
                                transport: {
                                    read: function(o) {
                                        if (o.data.filter == undefined) {
                                            o.data.filter = { filters: [] };
                                        }

                                        o.data.page = 1;
                                        o.data.pageSize = 100000;
                                        $http.get('/api/masterdata/prefectures',
                                            {params: o.data})
                                            .success(function (response,status,headers) {
                                                o.success(response.content);
                                            })
                                            .error(function (response) {
                                                console.log(response);
                                            });
                                    }
                                }
                            });

                            //DropDown Options
                            vm.prefecturesComboBoxOptions = {
                                autoBind: false,
                                dataSource: prefecturesDataSource,
                                text: vm.sector.prefectureName,
                                filter: "startswith",
                                valuePrimitive: false,
                                dataTextField: "name",
                                dataValueField: "id",
                                change: function(e){
                                    var filter = {};
                                    if(this.value() && !isNaN(this.value())) {
                                        filter = {
                                            "field": "prefecture.id",
                                            "operator": "eq",
                                            "value": this.value()
                                        };
                                    }
                                    vm.refreshCombobox(filter, vm.citiesComboBoxOptions.dataSource);
                                }
                            };

                            var citiesDataSource = new kendo.data.DataSource({
                                serverFiltering: true,
                                transport: {
                                    read: function(o) {
                                        if (o.data.filter == undefined) {
                                            o.data.filter = { filters: [] };
                                        }

                                        o.data.page = 1;
                                        o.data.pageSize = 50;
                                        $http.get('/api/masterdata/cities',
                                            {params: o.data})
                                            .success(function (response,status,headers) {
                                                o.success(response.content);
                                            })
                                            .error(function (response) {
                                                console.log(response);
                                            });
                                    }
                                }
                            });

                            //DropDown Options
                            vm.citiesComboBoxOptions = {
                                autoBind: false,
                                dataSource: citiesDataSource,
                                text: vm.sector.cityName,
                                filter: "startswith",
                                valuePrimitive: false,
                                dataTextField: "name",
                                dataValueField: "id",
                                template: "<table>${name} &nbsp;" +
                                "<span class='hint'>(${prefecture.name})</span></table>",
                                change: function(e){
                                    var filter = {};
                                    if(this.dataItem(this.select())) {
                                        filter = {
                                            "field"   : "id",
                                            "operator": "eq",
                                            "value"   : this.dataItem(this.select()).prefecture.id
                                        };
                                    }
                                    vm.refreshCombobox(filter, vm.prefecturesComboBoxOptions.dataSource);
                                }
                            };

                            if(vm.sector.prefecture) {
                                var filter = {
                                    "field": "prefecture.id",
                                    "operator": "eq",
                                    "value": vm.sector.prefecture.id
                                };
                                vm.refreshCombobox(filter, vm.citiesComboBoxOptions.dataSource);
                            }

                        }
                        else {
                            var geodataDataSource = new kendo.data.DataSource({
                                serverFiltering: true,
                                transport: {
                                    read: function(o) {
                                        if (o.data.filter == undefined) {
                                            o.data.filter = { filters: [] };
                                        }

                                        o.data.page = 1;
                                        o.data.pageSize = 100;
                                        $http.get('/api/masterdata/geodata',
                                            {params: o.data})
                                            .success(function (response,status,headers) {
                                                o.success(response.content);
                                            })
                                            .error(function (response) {
                                                console.log(response);
                                            });
                                    }
                                },
                                schema: {
                                    parse: function(data) {
                                        angular.forEach(data, function(item) {
                                            item.masterFilter = "";
                                            if(item.street !==null) {
                                                item.masterFilter =  item.street + ", ";
                                            }
                                            item.masterFilter = item.masterFilter + item.city + ", " + item.postalCode + ", " + item.prefecture;
                                        });
                                        return data;
                                    }
                                }
                            });

                            vm.masterFilterComboBoxOptions = {
                                dataSource: geodataDataSource,
                                filter: "search",
                                dataTextField: "masterFilter",
                                dataValueField: "id",
                                headerTemplate:"<div class='dropdown-header k-widget k-header'>" +
                                "<span>Διεύθυνση</span>"+
                                "<span>Πόλη</span>"+
                                "<span>Τ.Κ.</span>"+
                                "<span>Νομός</span></div>",
                                template:
                                    "#= street != null ? street + ', ' + city + ', ' + postalCode + ', ' + prefecture : city + ', ' + postalCode + ', ' + prefecture #",
                                placeholder: "Αναζητήστε Διεύθυνση, Πόλη, Τ.Κ., Νομό",
                                change: function(e){
                                    //initialize inputs (autocomplete)
                                    if(this.dataItem(this.select())) {
                                        var self = this;
                                        //using $timeout to trigger the change
                                        $timeout(function(){
                                            vm.sector.prefectureName = self.dataItem(self.select()).prefecture;
                                            vm.sector.cityName = self.dataItem(self.select()).city;
                                            vm.sector.address = self.dataItem(self.select()).street;
                                            vm.sector.postalCode = self.dataItem(self.select()).postalCode;
                                            vm.suggestBricks(vm.sector.postalCode);
                                        },0);
                                    }
                                }
                            };
                        }
                    })
                    .error(function (response) {});
            })
            .error(function (response) {});

        vm.update = function() {

            if(vm.geodata) {
                delete vm.sector.city;
                delete vm.sector.prefecture;
            }

            if (vm.sector.creditControlCheck == true) {
                vm.sector.creditControlCheck = 1;
            } else {
                vm.sector.creditControlCheck = 0;
            }

            if(vm.sector.sectorType !== null){
                if(vm.sector.sectorType.id === null) {
                    vm.sector.sectorType = null;
                }
            }

            vm.sector.parent = (vm.sector.parent && vm.sector.parent.id > 0) ? vm.sector.parent : null;
            //Todo Add Validation
           // if (validator.validate()) {
            $http.put('api/sectors', vm.sector)
                .success(function (response) {
                    // For binding a city that does not exist in initial dataSource of cityComboBox
                    if (!vm.geodata && CustomDataUtils.isNotNil(response.city) && CustomDataUtils.isNotNil(vm.citiesComboBoxOptions) &&
                        CustomDataUtils.isNotNil(vm.citiesComboBoxOptions.dataSource)) {
                        const dataArray = vm.citiesComboBoxOptions.dataSource.data();
                        if (CustomDataUtils.isNotEmpty(dataArray) && dataArray.filter(function (e) { return e.id === response.city.id }).length < 1) {
                            // push sector's city in city dataSource
                            vm.citiesComboBoxOptions.dataSource.pushUpdate(response.city);
                        }
                    }

                    vm.sector = response;

                    notification.show({
                        message: "Η ενημέρωση των στοιχείων ήταν επιτυχής!"
                    }, "success");
                })
                .error(function (response) {
                    notification.show({
                        message: "Η ενημέρωση των στοιχείων απέτυχε!"
                    }, "error");
                    console.log(response);
                });
           // }else{
           //    angular.element("#errorNotification").kendoNotification().data("kendoNotification").show("Η ενημέρωση των στοιχείων απέτυχε!", "error");
           // }

        };

        vm.refreshCombobox = function(filter, combobox) {
            combobox.filter(filter);
            combobox.read();
        };

        vm.suggestBricks = function($value) {
            var data = {};
            data.filter ={
                filters: [
                    {
                        "field": "postalCode",
                        "operator": "eq",
                        "value": $value,
                        "ignoreCase": true
                    }
                ]};

            data.page = 1;
            data.pageSize = 1;


            $http.get('/api/masterdata/bricks',
                {params: data})
                .success(function (response) {
                    if (response.content != null
                        && response.content instanceof Array
                        && response.content.length > 0) {
                        vm.sector.brick = response.content[0].territoryNo;
                    }
                })
                .error(function (response) {
                    console.log(response);
                });


            $http.get('/api/masterdata/brick-osfes',
                {params: data})
                .success(function (response) {
                    if (response.content != null
                        && response.content instanceof Array
                        && response.content.length > 0) {
                        vm.sector.osfeBrick = response.content[0].territoryName;
                    }
                })
                .error(function (response) {
                    console.log(response);
                });
        };
    }
})();
