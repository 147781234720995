(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('OtherEventDialogController', OtherEventDialogController);

    OtherEventDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'OtherEvent', 'EventCategoryType', 'Employee'];

    function OtherEventDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, OtherEvent, EventCategoryType, Employee) {
        var vm = this;
        vm.otherEvent = entity;
        vm.eventcategorytypes = EventCategoryType.query();
        vm.employees = Employee.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:otherEventUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.otherEvent.id !== null) {
                OtherEvent.update(vm.otherEvent, onSaveSuccess, onSaveError);
            } else {
                OtherEvent.save(vm.otherEvent, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateFrom = false;
        vm.datePickerOpenStatus.dateTo = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
