/**
 * Created by Kristy on 25/2/2020.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MedReportApprovalsPendingController', MedReportApprovalsPendingController);

    MedReportApprovalsPendingController.$inject = ['$http','$state', '$stateParams','$filter', '$timeout', 'KendoGrid', 'Approvals', 'KendoFilter', 'CustomUtils', 'KendoService', 'MedReport'];

    function MedReportApprovalsPendingController ($http, $state, $stateParams, $filter, $timeout, KendoGrid, Approvals, KendoFilter, CustomUtils, KendoService, MedReport) {
        var vm = this;
        vm.statusChanged = '';
        vm.saw = true;
        vm.approvals = Approvals;
        Approvals.setSelectedList([]);
        vm.massSelect = true;
        vm.kendoGrid = KendoGrid;
        vm.gridId = '#approvalsPendingGrid';

        var employeesDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: function (o) {
                    var url = '/api/me/employees';

                    if (o.data.filter == undefined) {
                        o.data.filter = { filters: [] };
                    }
                    if (o.data.sort == undefined || !o.data.sort.length) {
                        o.data.sort = { "field": "account.lastName",  "dir": "asc" };
                    }
                    o.data.page = 1;
                    o.data.pageSize = 20;
                    $http.get(url,
                        {params: o.data})
                        .success(function (response) {
                            o.success(response.content);
                        })
                        .error(function (response) {});
                }
            },
            schema: {
                parse: function (data) {
                    data.forEach(function (item) {
                        item.fullName = item.account.lastName + " " + item.account.firstName;
                    });
                    return data;
                }
            }
        });
        vm.employeesComboBoxOptions = KendoService.initDefaultOptions(employeesDataSource, "startswith", "fullName", "id", false);

        vm.changeStatus = function(e) {
            e.preventDefault();
            // e.target is the DOM element representing the button
            var tr = $(e.target).closest("tr"); // get the current table row (tr)
            // get the data bound to the current table row
            var data = this.dataItem(tr);
            var change = [data];

            $http.put('api/med-reports/'+e.data.commandName, change)
                .success(function (response,status,headers) {
                    switch (e.data.commandName) {
                        case "approve":
                            notification.show({
                                message: "Η εγγραφή εγκρίθηκε με επιτυχία!"
                            }, "success");
                            break;
                    }
                    vm.statusChanged = 'statusChanged';
                    vm.pendingGridOptions.dataSource.read();
                })
                .error(function (response) {
                    notification.show({
                        message: "Η ενημέρωση των στοιχείων απέτυχε!"
                    }, "error");
                });

            //clear selected list
            vm.statusChanged = '';
            Approvals.setSelectedList([]);
        };

        vm.approveSelected = function(status) {
            $http.put('api/med-reports/'+status, Approvals.getSelectedList())
                .success(function (response,status) {
                    switch (status) {
                        case "approve":
                            notification.show({
                                message: "Οι αλλαγές εγκρίθηκαν με επιτυχία!"
                            }, "success");
                            break;
                    }
                    vm.statusChanged = 'statusChanged';
                    vm.pendingGridOptions.dataSource.read();
                })
                .error(function (response) {
                    notification.show({
                        message: "Η ενημέρωση των στοιχείων απέτυχε!"
                    }, "error");
                    vm.pendingGridOptions.dataSource.read();
                });

            //clear selected list
            vm.statusChanged = '';
            Approvals.setSelectedList([]);
            vm.previewWindow.close();
        };

        vm.openPreviewWindow = function (status) {
            if(Approvals.getSelectedList().length) {
                vm.initPopupGrid = true;
                vm.status = status;
                Approvals.openWindow(vm.previewWindow, status);
            }
        };

        vm.closePreviewWindow = function() {
            Approvals.closeWindow(vm.pendingGridOptions, vm.previewWindow);
            delete vm.initPopupGrid;
        };

        vm.pendingPreviewGridOptions = {
            dataSource: new kendo.data.DataSource({
                data: Approvals.getSelectedList(),
                schema: {
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            fromDate: {type: 'date'}
                        }
                    }
                },
                pageSize: 20
            }),
            pageable: true,
            columns: [
                {
                    field: "fromDate",
                    title: "Μήνας",
                    template: "#= fromDate == null ? '' : kendo.toString(kendo.parseDate(fromDate), 'MMMM')#", width: 200,
                    filterable: false
                },
                {
                    field: "toDate",
                    title: "Έτος",
                    template: "#= toDate == null ? '' : kendo.toString(kendo.parseDate(toDate), 'yyyy')#",
                    filterable: false, width: 180
                },
                {
                    field: "checkDigit1", title: "Check Digit",
                    filterable: false, width: 180
                },
                {
                    field: "checkDigit2", title: "Check Digit 2",
                    filterable: false, width: 180
                },
                {
                    field: "code", title: "Κωδικός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }, width: 250
                },
                {field: "employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }, width: 180
                },
                {field: "employee.account.firstName", title: "Όνομα Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }, width: 180
                },
                { "template": '<md-button class="md-icon-button" aria-label="Remove" ng-click="vm.approvals.removeItem(vm.pendingPreviewGridOptions.dataSource.data(), #=id#, vm.gridId)">' +
                "<md-icon class='remove'></md-icon></md-button>", width: 90 }
            ]
        }

        vm.pendingGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "isActive", "operator": "eqbool", "value": true }
                            ]
                        );

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "status", "operator": "eq",  "value": "pending"}
                            ]
                        );

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        $http.get('api/me/med-reports',
                            {params: o.data})
                            .success(function (response) {
                                o.success(response);
                            })
                            .error(function () {});
                    },
                    update: function(o) {
                        vm.saveMedReport = o.data.models[0];
                        MedReport.update({}, vm.saveMedReport,
                            function () {
                                vm.pendingGridOptions.dataSource.read();
                            });
                    },
                    parameterMap: function (options, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            fromDate: {  editable: false,type: 'date'},
                            toDate: {  editable: false,type: 'date'},
                            code: {  editable: false, type: 'string'},
                            checkDigit1: {  editable: false,type: 'number'},
                            checkDigit2: {  editable: false,type: 'number'},
                            'employee.account.lastName': { editable: true }
                        }
                    }
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                pageSize: 50
            }),
            height: CustomUtils.getHeightPixelsFromPercentage(60),
            sortable: true,
            pageable: true,
            resizable: true,
            editable: "inline",
            selectable: "row",
            navigatable: true,
            filterable: false,
            columns: [
                {
                    command:[
                        { name:"approve", text: "Έγκριση", click: vm.changeStatus }
                    ],
                    attributes: { class: "#= 'green-color' #" },
                    title: "&nbsp",
                    width:85
                },
                {
                    field: "fromDate",
                    title: "Μήνας",
                    template: "#= fromDate == null ? '' : kendo.toString(kendo.parseDate(fromDate), 'MMMM')#", width: 250
                },
                {
                    field: "toDate",
                    title: "Έτος",
                    template: "#= toDate == null ? '' : kendo.toString(kendo.parseDate(toDate), 'yyyy')#", width: 230
                },
                {
                    field: "checkDigit1", title: "Check Digit", width: 230
                },
                {
                    field: "checkDigit2", title: "Check Digit 2", width: 230
                },
                {
                    field: "code", title: "Κωδικός", width: 300
                },
                {field: "employee.account.lastName", title: "Ιατρικός Επισκέπτης", attributes: {
                        "name": "employee"
                    },
                    template: "#= employee.account.lastName#" + ' ' + "#= employee.account.firstName #",
                    editor: function(container) {
                        angular.element('<select id="employee-account" data-bind="value:employee.id"></select>')
                            .appendTo(container)
                            .kendoComboBox(vm.employeesComboBoxOptions);
                    }
                }
            ],
            selectedItems: function(dataItems) {
                Approvals.setSelectedList(dataItems);
            },
            edit: function(e) {
                e.model.dirty = true;
            },
            dataBound: function (e) {
                angular.element("#pendingGridcheckbox").prop('checked', false);
                //toggle class on click
                angular.element(".checkbox").bind("change", function (e) {
                    angular.element(e.target).closest("tr").toggleClass("k-state-selected");
                });

                var view = this.dataSource.data();
                var me = this;

                if (Approvals.getSelectedList().length) {
                    var selectedListViewCounter = 0;
                    angular.forEach( view, function(item) {
                        var foundItemById = $filter('filter')(Approvals.getSelectedList(), {id: item.id}, true)[0];
                        if(Approvals.getSelectedList().indexOf(foundItemById) !== -1){
                            me.tbody.find("tr[data-uid='" + item.uid + "']")
                                .addClass("k-state-selected");
                            item.selected = true;
                            selectedListViewCounter++
                        }
                    });
                    if(selectedListViewCounter == view.length) {
                        angular.element("#pendingGridcheckbox").prop('checked', true);
                    }
                }

                angular.element("#approvalsPendingGrid tbody tr [name='employee']").on("click", function(e) {
                    if (!angular.element(this).hasClass('k-grid-edit-row')) {
                        angular.element("#approvalsPendingGrid").data("kendoGrid").editRow(angular.element(this));
                    }
                });
            },
            toolbar: [
                {template: "<md-button class='green-color-md-button md-raised md-mini md-primary' aria-label='approveSelected' ng-click='vm.openPreviewWindow(\"approve\")'>" +
                "Μαζική Έγκριση επιλεγμένων</md-button>"},
                { template: "<div class='right'><label>Σύνολο: {{vm.pendingGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }
            ]
        };

        vm.medReportLinesGridOptions = function (dataItem) {
            if (dataItem != undefined) {
                return {
                    dataSource: {
                        batch: true,
                        transport: {
                            read: function(o) {
                                vm.showProgress = true;
                                if (o.data.filter == undefined) {
                                    o.data.filter = { filters: [] };
                                }

                                o.data.filter.filters.push({field: "medReport.id", operator: "eq", value: dataItem.id});
                                $http.get('/api/med-report-lines/search',
                                    {params: o.data})
                                    .success(function (response) {
                                        o.success(response);
                                    })
                                    .error(function () {
                                    });
                            }
                        },
                        schema: {
                            data: function (data) {
                                return data.content;
                            },
                            total: function (data) {
                                return data.totalEntries;
                            },
                            model: {
                                id: "id",
                                fields: {
                                    id: { editable: false, nullable: true },
                                    sum: { type: "number" },
                                    aQuantity: { type: 'number' },
                                    bQuantity: { type: 'number' }
                                }
                            }
                        },
                        serverFiltering: true,
                        serverSorting: true,
                        serverPaging: true,
                        pageSize: 15
                    },
                    pageable: true,
                    sortable: true,
                    filterable: {
                        mode: "row"
                    },
                    columns: [
                        {field: "medicine.name", title: "Όνομα Σκευάσματος",
                            filterable: false
                        },
                        {field: "medicine.barcode", title: "Barcode Σκευάσματος",
                            filterable: false
                        },
                        {
                            field: "aQuantity",
                            title: "Ποσότητα Α",
                            filterable: false
                        },
                        {
                            field: "bQuantity",
                            title: "Ποσότητα Β",
                            filterable: false
                        },
                        {
                            field: "sum", title: "Σύνολο",
                            filterable: false
                        }
                    ],
                    editable: false
                };
            }
        };
    }
})();
