(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('EventCategoryDetailController', EventCategoryDetailController);

    EventCategoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'EventCategory'];

    function EventCategoryDetailController($scope, $rootScope, $stateParams, entity, EventCategory) {
        var vm = this;
        vm.eventCategory = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:eventCategoryUpdate', function(event, result) {
            vm.eventCategory = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
