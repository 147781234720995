/**
 * Created by Kristy on 25/8/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PendingController', PendingController);

    PendingController.$inject = ['$http','$state', '$stateParams','$filter', '$timeout', 'NotificationOptions', 'KendoGrid', 'Approvals', 'KendoFilter'];

    function PendingController ($http, $state, $stateParams, $filter, $timeout, NotificationOptions, KendoGrid, Approvals, KendoFilter) {
        var vm = this;
        vm.statusChanged = '';
        vm.saw = true;
        vm.approvals = Approvals;
        Approvals.setSelectedList([]);
        vm.massSelect = true;
        vm.sectorType = null;
        vm.kendoGrid = KendoGrid;

        vm.customerTypesDataSource = new kendo.data.DataSource({
            transport: {
                read: function (o) {
                    if (o.data.filter == undefined) {
                        o.data.filter = {filters: []};
                    }

                    o.data.page = 1;
                    o.data.pageSize = 10;
                    $http.get('api/masterdata/customer-types',
                        {params: o.data})
                        .success(function (response, status, headers) {
                            o.success(response.content);
                        })
                        .error(function (response) {
                            console.log(response);
                        });
                }
            }
        });

        vm.customerTypesMultiOptions = {
            dataSource: vm.customerTypesDataSource,
            filter: "startswith",
            valuePrimitive: false,
            dataTextField: "name",
            dataValueField: "id"
        };

        var notification = angular.element("#notification").kendoNotification(NotificationOptions).data("kendoNotification");
        vm.changeStatus = function(e) {
            e.preventDefault();
            // e.target is the DOM element representing the button
            var tr = $(e.target).closest("tr"); // get the current table row (tr)
            // get the data bound to the current table row
            var data = this.dataItem(tr);
            var change = [data];

            $http.put('api/temp-customers/'+e.data.commandName, change)
                .success(function (response,status,headers) {
                    switch (e.data.commandName) {
                        case "approve":
                            notification.show({
                                message: "Η εγγραφή εγκρίθηκε με επιτυχία!"
                            }, "success");
                            break;
                        case "reject":
                            notification.show({
                                message: "Η εγγραφή απορριφθηκε με επιτυχία!"
                            }, "success");
                            break
                    }
                    vm.statusChanged = 'statusChanged';
                    vm.pendingGridOptions.dataSource.read();
                })
                .error(function (response) {
                    notification.show({
                        message: "Η ενημέρωση των στοιχείων απέτυχε!"
                    }, "error");
                    console.log(response);
                });

            //clear selected list
            vm.statusChanged = '';
            Approvals.setSelectedList([]);
        };

        vm.approveRejectSelected = function(status) {
            $http.put('api/temp-customers/'+status, Approvals.getSelectedList())
                .success(function (response,status,headers) {
                    switch (status) {
                        case "approve":
                            notification.show({
                                message: "Οι αλλαγές εγκρίθηκαν με επιτυχία!"
                            }, "success");
                            break;
                        case "reject":
                            notification.show({
                                message: "Οι αλλαγές απορρίφθηκαν με επιτυχία!"
                            }, "success");
                            break
                    }
                    vm.statusChanged = 'statusChanged';
                    vm.pendingGridOptions.dataSource.read();
                })
                .error(function (response) {
                    notification.show({
                        message: "Η ενημέρωση των στοιχείων απέτυχε!"
                    }, "error");
                    vm.pendingGridOptions.dataSource.read();
                    console.log(response);
                });

            //clear selected list
            vm.statusChanged = '';
            Approvals.setSelectedList([]);
            vm.previewWindow.close();
        };

        vm.openPreviewWindow = function (status) {
            if(Approvals.getSelectedList().length) {
                vm.initPopupGrid = true;
                vm.status = status;
                Approvals.openWindow(vm.previewWindow, status);
            }
        };

        vm.closePreviewWindow = function() {
            Approvals.closeWindow(vm.pendingGridOptions, vm.previewWindow);
            delete vm.initPopupGrid;
        };

        vm.pendingPreviewGridOptions = {
            dataSource: new kendo.data.DataSource({
                data: Approvals.getSelectedList(),
                schema: {
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            createdDate: {type: 'date'}
                        }
                    }
                },
                pageSize: 20
            }),
            pageable: true,
            columns: [
                {
                    field: "createdDate",
                    title: "Ημερομηνία",
                    template: "#= createdDate ? kendo.toString(kendo.parseDate(createdDate), 'dddd, dd/MM/yyyy HH:mm') : ''#",
                    filterable: false
                },
                {
                    title: "Νέα",
                    template: kendo.template(angular.element("#labelsTemplate").html()), width:50
                },
                {field: "lastName", title: "Επώνυμο Επαγγελματία Υγείας",
                    filterable: false,
                    width:160
                },
                {field: "firstName", title: "Όνομα Επαγγελματία Υγείας",
                    filterable: false,
                    width:160
                },
                {field: "employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",
                    filterable: false,
                    width:160
                },
                {field: "employee.account.firstName", title: "Όνομα Ιατρικού Επισκέπτη",
                    filterable: false,
                    width:160
                },
                {
                    title: "Συνδέσεις",
                    template:"<span class='label label-warning'>#: tempCustomerSectors.length#</span>", width:80
                },
                { "template": "<md-button class='md-icon-button' aria-label='Remove' ng-click='vm.approvals.removeItem(vm.pendingPreviewGridOptions.dataSource.data(), #=id#)'>" +
                "<md-icon class='remove'></md-icon></md-button>", width: 90 }
            ]
        };

        vm.pendingGridOptions = {
            dataSource: new kendo.data.DataSource({
                batch: true,
                transport: {
                    read: function(o) {
                        if (o.data.filter == undefined) {
                            o.data.filter = { filters: [] };
                        }

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "status", "operator": "eq",  "value": "pending"}
                            ]
                        );

                        o.data.filter.filters = o.data.filter.filters.concat(
                            [
                                { "field": "sectorType", "operator": "sectortype",  "value": vm.sectorType}
                            ]
                        );

                        angular.forEach( o.data.filter.filters, function(item) {
                            if ( item.value instanceof Date) {
                                item.value = $filter('date')(item.value, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
                            }
                        });

                        $http.get('api/me/temp-customers',
                            {params: o.data})
                            .success(function (response,status,headers) {
                                o.success(response);
                            })
                            .error(function (response) {
                                console.log(response);
                            });
                    },
                    parameterMap: function (options, operation) {
                        if (operation !== 'read') {
                            return JSON.stringify(data.models);
                        }
                    }
                },
                schema: {
                    data: function (data) {
                        if(data.content != undefined) {
                            data.content.forEach(function(item){
                                item.newSector = false;
                                item.selectedIndex = 0;

                                if (item.doctorSpecialty === null) {
                                    item.doctorSpecialty = { id: null, name: "" };
                                }
                                if (item.doctorSpecificSpecialty === null) {
                                    item.doctorSpecificSpecialty = { id: null, name: "" };
                                }
                                if (item.customerCategory === null) {
                                    item.customerCategory = { id: null, name: "" };
                                }
                                if (item.customerSubCategory === null) {
                                    item.customerSubCategory = { id: null, name: "" };
                                }
                                if (item.customerTitle === null) {
                                    item.customerTitle = { id: null, name: "" };
                                }

                                if(item.tempCustomerSectors === null) {
                                    item.tempCustomerSectors = [];
                                }
                                angular.forEach(item.tempCustomerSectors, function (tempCustomerSector){
                                    if(tempCustomerSector.sector) {
                                        tempCustomerSector.displaySector = tempCustomerSector.sector;
                                    } else {
                                        item.newSector = true;
                                        tempCustomerSector.newSector = true;
                                        tempCustomerSector.displaySector = tempCustomerSector.tempSector;
                                    }

                                    if(tempCustomerSector.tempSector === null) {
                                        tempCustomerSector.tempSector = { sectorType: {} };
                                    } else if (tempCustomerSector.tempSector.sectorType === null){
                                        tempCustomerSector.tempSector.sectorType= {};
                                    }

                                    if(tempCustomerSector.sector === null) {
                                        tempCustomerSector.sector = { sectorType: {} };
                                    } else if (tempCustomerSector.sector.sectorType === null){
                                        tempCustomerSector.sector.sectorType= {};
                                    }
                                });
                            });
                        }

                        return data.content;
                    },
                    total: function (data) {
                        return data.totalEntries;
                    },
                    model: {
                        id: "id",
                        fields: {
                            id: { editable: false, nullable: true },
                            createdDate: {type: 'date'}
                        }
                    }
                },
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                pageSize: 50
            }),
            sortable: true,
            pageable: true,
            selectable: true,
            resizable: true,
            filterable: {
                mode: "row"
            },
            columns: [
                {
                    command:[
                        { name:"approve", text: "Έγκριση", click: vm.changeStatus }
                    ],
                    attributes: { class: "#= 'green-color' #" },
                    title: "&nbsp",
                    width:85
                },
                {
                    command:[
                        { name:"reject", text: "Απόρριψη", click: vm.changeStatus }
                    ],
                    attributes: { class: "#= 'red-color' #" },
                    title: "&nbsp",
                    width:90
                },
                {field: "createdDate", title: "Ημερομηνία", template: "#= createdDate ? kendo.toString(kendo.parseDate(createdDate), 'dddd, dd/MM/yyyy HH:mm') : ''#",
                    filterable: {
                        cell: {
                            operator: "eqdate",
                            showOperators: false
                        }
                    },
                    width:200
                },
                {
                    title: "Νέα",
                    template: kendo.template(angular.element("#labelsTemplate").html()), width:60
                },
                {field: "lastName", title: "Επώνυμο Επαγγελματία Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {field: "firstName", title: "Όνομα Επαγγελματία Υγείας",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {field: "employee.account.lastName", title: "Επώνυμο Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {field: "employee.account.firstName", title: "Όνομα Ιατρικού Επισκέπτη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width:160
                },
                {
                    title: "Συνδέσεις",
                    template:"<span class='label label-warning'>#: tempCustomerSectors.length#</span>", width:80
                }
            ],
            selectedItems: function(dataItems) {
                Approvals.setSelectedList(dataItems);
            },
            dataBound: function (e) {
                angular.element("#pendingGridcheckbox").prop('checked', false);
                //toggle class on click
                angular.element(".checkbox").bind("change", function (e) {
                    angular.element(e.target).closest("tr").toggleClass("k-state-selected");
                });

                var view = this.dataSource.data();
                var me = this;

                if (Approvals.getSelectedList().length) {
                    var selectedListViewCounter = 0;
                    angular.forEach( view, function(item) {
                        var foundItemById = $filter('filter')(Approvals.getSelectedList(), {id: item.id}, true)[0];
                        if(Approvals.getSelectedList().indexOf(foundItemById) !== -1){
                            me.tbody.find("tr[data-uid='" + item.uid + "']")
                                .addClass("k-state-selected");
                            item.selected = true;
                            selectedListViewCounter++
                        }
                    });
                    if(selectedListViewCounter == view.length) {
                        angular.element("#pendingGridcheckbox").prop('checked', true);
                    }
                }
            },
            toolbar: [
                {template: "<md-button class='green-color-md-button md-raised md-mini md-primary' aria-label='approveSelected' ng-click='vm.openPreviewWindow(\"approve\")'>" +
                "Μαζική Έγκριση επιλεγμένων</md-button>"},
                {template: "<md-button class='red-color-md-button md-raised md-mini md-primary' aria-label='rejectSelected' ng-click='vm.openPreviewWindow(\"reject\")'>" +
                "Μαζική Απόρριψη επιλεγμένων</md-button>"},
                { template: "<div class='right'><label>Σύνολο: {{vm.pendingGridOptions.dataSource.total()}}</label></div>",
                    overflow: "never" }
            ]
        };

        vm.sectorTypeOptions = {
            dataSource: [
                { text: 'Φαρμακοποιοί', value: "pharmacy" },
                { text: 'Επαγγελματίες Υγείας', value: "hcu" },
                { text: 'Υπεύθυνοι Κέντρου', value: "beautyCenter" }
            ],
            filter: "startswith",
            valuePrimitive: true,
            dataTextField: "text",
            dataValueField: "value",
            change: function () {
                var self = this;
                $timeout(function() {
                    vm.sectorType = self.value();
                    vm.pendingGridOptions.dataSource.read();
                });

            }
        };

        vm.toolbarOptions = {
            items: [
                {
                    template: "<label>Τύπος Επαγγελματία Υγείας: </label><select id='sectorType' name='sectorType' class='inline' kendo-combo-box " +
                    "k-options='vm.sectorTypeOptions'></select>",
                    overflow: "never", attributes: { class: "left" }
                }
            ]
        };
    }
})();
