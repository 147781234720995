(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProductPriceDeleteController',ProductPriceDeleteController);

    ProductPriceDeleteController.$inject = ['$uibModalInstance', 'entity', 'ProductPrice'];

    function ProductPriceDeleteController($uibModalInstance, entity, ProductPrice) {
        var vm = this;
        vm.productPrice = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            ProductPrice.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
