/**
 * Created by Kristy on 22/12/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('KendoFilter', KendoFilter);

    KendoFilter.$inject = ['$filter'];

    const excludedKeys = ["Shift", "CapsLock", "Control", "Alt", "Meta", "F1", "F2",
        "F3", "F4", "F5", "F5", "F6", "F7", "F8", "F9", "F10", "F11", "F12", "Escape", "ContextMenu",
        "ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown", "NumLock", "Home", "Insert", "PageUp",
        "PageDown", "End", "ScrollLock", "Pause", "Enter"];

    function KendoFilter ($filter) {

        return {
            getTemplate : getTemplate,
            getDayTimeTemplate : getDayTimeTemplate,
            getBooleanTemplate : getBooleanTemplate,
            getEnumerationTemplate : getEnumerationTemplate,
            getEmployeeCustomerSectorsTemplate: getEmployeeCustomerSectorsTemplate
        };

        function getTemplate (args) {
            args.element.css("width", "90%").addClass("k-textbox").keydown(function(e){
                if (excludedKeys.indexOf(e.key) === -1) {
                    setTimeout(function(){
                        $(e.target).trigger("change");
                    });
                }
            });
        }

        function getDayTimeTemplate (args) {
            args.element.kendoDropDownList({
                autoBind: false,
                dataTextField: "text",
                dataValueField: "value",
                dataSource: new kendo.data.DataSource({
                    data: [{text: "ΠΡΩΙΝΌ", value: "day"},
                        {text: "ΑΠΟΓΕΥΜΑΤΙΝΌ", value: "night"}]
                }),
                valuePrimitive: true

            })
        }

        function getEnumerationTemplate (args, field, values) {
            var data = [];
            angular.forEach(values, function (value) {
                data.push({text: value !== 'none' ? $filter('translate')('global.field.' + field + '.' + value) : 'Όχι', value: value});
            });
            args.element.kendoDropDownList({
                autoBind: false,
                dataTextField: "text",
                dataValueField: "value",
                dataSource: new kendo.data.DataSource({
                    data: data
                }),
                valuePrimitive: true

            })
        }

        function getBooleanTemplate (args) {
            args.element.kendoDropDownList({
                autoBind: false,
                dataTextField: "text",
                dataValueField: "value",
                dataSource: new kendo.data.DataSource({
                    data: [{text: "Ναι", value: "true"},
                        {text: "Όχι", value: "false"}]
                }),
                valuePrimitive: true

            })
        }

        function getEmployeeCustomerSectorsTemplate(dataItem) {
            var template = "";

            if (dataItem.employeeCustomerSectors !== null &&
                dataItem.employeeCustomerSectors !== undefined &&
                dataItem.employeeCustomerSectors.length > 0) {
                dataItem.employeeCustomerSectors.forEach(function (employeeCustomerSector) {
                    if (employeeCustomerSector.employee !== null &&
                        employeeCustomerSector.employee !== undefined &&
                        employeeCustomerSector.employee.account !== null &&
                        employeeCustomerSector.employee.account !== undefined) {
                        if (employeeCustomerSector.myTarget === true) {
                            template += "[" + "<strong>" + kendo.htmlEncode(employeeCustomerSector.employee.account.lastName) + " " + kendo.htmlEncode(employeeCustomerSector.employee.account.firstName) + "</strong>" + "]" + "<br/>";
                        }else {
                            template += "[" + kendo.htmlEncode(employeeCustomerSector.employee.account.lastName) + " " + kendo.htmlEncode(employeeCustomerSector.employee.account.firstName) + "]" + "<br/>";
                        }
                    }
                });
            }

            return template;
        }
    }

})();
