(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('RoomTypeDeleteController',RoomTypeDeleteController);

    RoomTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'RoomType'];

    function RoomTypeDeleteController($uibModalInstance, entity, RoomType) {
        var vm = this;
        vm.roomType = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            RoomType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
