(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PatchItemDeleteController',PatchItemDeleteController);

    PatchItemDeleteController.$inject = ['$uibModalInstance', 'entity', 'PatchItem'];

    function PatchItemDeleteController($uibModalInstance, entity, PatchItem) {
        var vm = this;
        vm.patchItem = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            PatchItem.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
