(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadHospitalizationDialogController', LeadHospitalizationDialogController);

    LeadHospitalizationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'LeadHospitalization', 'Sector', 'Competitor', 'HospitalizationType', 'ClinicName', 'AddDisease', 'AddCondition', 'CommunicationStatus'];

    function LeadHospitalizationDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, LeadHospitalization, Sector, Competitor, HospitalizationType, ClinicName, AddDisease, AddCondition, CommunicationStatus) {
        var vm = this;
        vm.leadHospitalization = entity;
        vm.sectors = Sector.query();
        vm.competitors = Competitor.query();
        vm.hospitalizationtypes = HospitalizationType.query();
        vm.clinicnames = ClinicName.query();
        vm.adddiseases = AddDisease.query();
        vm.addconditions = AddCondition.query();
        vm.communicationstatuses = CommunicationStatus.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:leadHospitalizationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.leadHospitalization.id !== null) {
                LeadHospitalization.update(vm.leadHospitalization, onSaveSuccess, onSaveError);
            } else {
                LeadHospitalization.save(vm.leadHospitalization, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.diagnosisDate = false;
        vm.datePickerOpenStatus.possibleDischargeDate = false;
        vm.datePickerOpenStatus.hospitalizationDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
