/**
 * Created by Kristy on 25/7/2016.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('KendoGrid', KendoGrid);

    KendoGrid.$inject = ['$filter'];

    function KendoGrid ($filter) {

        return {
            refresh : refresh,
            refreshList : refreshList,
            refreshWithFilter : refreshWithFilter,
            defaultEditInline: defaultEditInline,
            defaultEditPopup: defaultEditPopup,
            defaultCustomEditPopup: defaultCustomEditPopup,
            initSelected : initSelected
        };

        function refresh (grid) {
            if (grid.dataSource.page() > 1){
                grid.dataSource.page(1);
                grid.dataSource.filter({});
            }
            grid.dataSource.read();
        }

        function refreshList (grid, newData) {
            grid.dataSource.data(newData);
        }

        function refreshWithFilter (grid, filter) {
            grid.dataSource.page(1);
            grid.dataSource.filter(filter);
            grid.dataSource.read();
        }

        function defaultEditInline (container) {
            var updateBtnTxt = $filter('translate')('global.form.updateBtnTxt'),
                cancelBtnTxt = $filter('translate')('global.form.cancelBtnTxt');

            //sets the text of the "Update" button
            angular.element(container).find("a.k-grid-update").text(updateBtnTxt);
            //sets the text of the "Cancel" button
            angular.element(container).find("a.k-grid-cancel").text(cancelBtnTxt);
        }

        function defaultEditPopup (container) {
            var updateBtnTxt = $filter('translate')('global.form.updateBtnTxt'),
                cancelBtnTxt = $filter('translate')('global.form.cancelBtnTxt');

            //sets the text of the "Update" button
            angular.element(container).find("a.k-grid-update").text(updateBtnTxt);
            //sets the text of the "Cancel" button
            angular.element(container).find("a.k-grid-cancel").text(cancelBtnTxt);

            angular.element(container).kendoWindow("title",$filter('translate')('entity.action.edit'));
        }

        function defaultCustomEditPopup (container, width, formWidth) {
            defaultEditPopup(container);

            //for large edit window
            angular.element(container).width(width).data("kendoWindow").center();
            angular.element(".k-edit-form-container").width(formWidth);
            angular.element(".k-edit-form-container").css({"margin-left": "10px"});
        }

        function initSelected (gridId, self, array) {
            angular.element("#"+gridId+"checkbox").prop('checked', false);
            //toggle class on click
            angular.element(".checkbox").bind("change", function (e) {
                angular.element(e.target).closest("tr").toggleClass("k-state-selected");
            });

            var view = self.dataSource.data();

            if (array.length) {
                var counter = 0;
                angular.forEach( view, function(item) {
                    var foundItemById = $filter('filter')(array, {id: item.id}, true)[0];
                    if(array.indexOf(foundItemById) !== -1){
                        self.tbody.find("tr[data-uid='" + item.uid + "']")
                            .addClass("k-state-selected");
                        item.selected = true;
                        counter++
                    }
                });
                if(counter == view.length) {
                    angular.element("#"+gridId+"checkbox").prop('checked', true);
                }
            }
        }
    }

})();
