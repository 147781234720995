(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('MedReportLineDetailController', MedReportLineDetailController);

    MedReportLineDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'MedReportLine', 'MedReport', 'Medicine'];

    function MedReportLineDetailController($scope, $rootScope, $stateParams, entity, MedReportLine, MedReport, Medicine) {
        var vm = this;
        vm.medReportLine = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:medReportLineUpdate', function(event, result) {
            vm.medReportLine = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
