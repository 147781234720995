/**
 * Created by Kristy Kavvada on 13/2/2020.
 */
(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('medReportSumsPerCustomerAndMonth', {
                parent: 'medReport',
                url: '/per-customer',
                data: {
                    authorities: ['ROLE_CHECKER'],
                    pageTitle: 'sigmaCrmApp.employee.home.title'
                },
                templateUrl: 'app/displays/adminIndex/medReport/medReportSumsPerCustomerAndMonth/medReportSumsPerCustomerAndMonth.html',
                controller: 'MedReportSumsCustomerAndMonthController',
                controllerAs: 'vm',
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    employee: ['$http', function($http) {
                        return $http.get('/api/employees/me')
                    }],
                    favouriteFilters: ['$http', function($http) {
                        return $http.get('/api/favourite-filters/search', {params: { filter: { filters: [{
                                        "field": "viewName", "operator": "eq",
                                        "value": "medReportSumsPerCustomerAndMonth"
                                    }] } }} );
                    }]
                }
            })
    }

})();
