(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('EventTypeDeleteController',EventTypeDeleteController);

    EventTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'EventType'];

    function EventTypeDeleteController($uibModalInstance, entity, EventType) {
        var vm = this;
        vm.eventType = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            EventType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
