(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('AnalyticsNewController', AnalyticsNewController);

    AnalyticsNewController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$http'];

    function AnalyticsNewController ($scope, Principal, LoginService, $state, $http) {


        var vm = this;

        $http.get('/api/employees/me')
            .success(function (response,status,headers) {
                vm.user = response;
                Principal.identity().then(function (account) {
                    if (vm.user.account.login == 'gstefanidis') {
                        var iframe = document.getElementById('iFrameEmbedReport');
                        iframe.src = "https://app.powerbi.com/view?r=eyJrIjoiN2QwZTUxMjMtM2Q4Mi00MThjLWIyODQtMzc5Njk4ZmYyODNmIiwidCI6IjZlZDUwOWI4LTViMzAtNDFmYS1hNzc4LWZjNDAwZTRkZDhmYiIsImMiOjh9";
                        console.log("new");
                    }
                })
            })
            .error(function (response) {
                console.log(response);
            });

        vm.printIframe = function (iFrameContent) {
            var frame = angular.element(iFrameContent);

            if (!frame) {
                console.log('No iFrame found');
                return;
            }
            window.print();
        };
    }
})();
