(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('TransportationDetailController', TransportationDetailController);

    TransportationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Transportation'];

    function TransportationDetailController($scope, $rootScope, $stateParams, entity, Transportation) {
        var vm = this;
        vm.transportation = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:transportationUpdate', function(event, result) {
            vm.transportation = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
