(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('ProductCategoryProductController', ProductCategoryProductController);

    ProductCategoryProductController.$inject = ['$scope', '$state', 'ProductCategoryProduct'];

    function ProductCategoryProductController ($scope, $state, ProductCategoryProduct) {
        var vm = this;
        vm.productCategoryProducts = [];
        vm.loadAll = function() {
            ProductCategoryProduct.query(function(result) {
                vm.productCategoryProducts = result;
            });
        };

        vm.loadAll();
        
    }
})();
