(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('LeadOfferRoomTypeDialogController', LeadOfferRoomTypeDialogController);

    LeadOfferRoomTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'LeadOfferRoomType', 'LeadOffer', 'RoomType'];

    function LeadOfferRoomTypeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, LeadOfferRoomType, LeadOffer, RoomType) {
        var vm = this;
        vm.leadOfferRoomType = entity;
        vm.leadoffers = LeadOffer.query();
        vm.roomtypes = RoomType.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('sigmasfApp:leadOfferRoomTypeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.leadOfferRoomType.id !== null) {
                LeadOfferRoomType.update(vm.leadOfferRoomType, onSaveSuccess, onSaveError);
            } else {
                LeadOfferRoomType.save(vm.leadOfferRoomType, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
