(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .controller('PatchReportItemDetailController', PatchReportItemDetailController);

    PatchReportItemDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'PatchReportItem', 'PatchReport', 'PatchItem', 'Icd10'];

    function PatchReportItemDetailController($scope, $rootScope, $stateParams, entity, PatchReportItem, PatchReport, PatchItem, Icd10) {
        var vm = this;
        vm.patchReportItem = entity;
        
        var unsubscribe = $rootScope.$on('sigmasfApp:patchReportItemUpdate', function(event, result) {
            vm.patchReportItem = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
