/**
 * Created by Kristy on 28/7/2017.
 */

(function() {
    'use strict';

    angular
        .module('sigmasfApp')
        .factory('CustomerSectorsService', CustomerSectorsService);

    CustomerSectorsService.$inject = ['KendoFilter'];

    function CustomerSectorsService(KendoFilter) {
        var savedSector = false;
        var sector = {};
        var customerSector = {};
        var citiesComboboxOptions = {};
        var prefecturesComboboxOptions = {};

        return {
            getSectorsGridColumns: getSectorsGridColumns,
            getSectorsOptions: getSectorsOptions,

            getTimeEditor: getTimeEditor,
            getDateEditor: getDateEditor,
            setIsSectorSaved: setIsSectorSaved,
            getIsSectorSaved: getIsSectorSaved,
            getSector: getSector,
            setSector: setSector,
            getCustomerSector: getCustomerSector,
            setCustomerSector: setCustomerSector,
            getCitiesComboboxOptions: getCitiesComboboxOptions,
            setCitiesComboboxOptions: setCitiesComboboxOptions,
            getPrefecturesComboboxOptions: getPrefecturesComboboxOptions,
            setPrefecturesComboboxOptions: setPrefecturesComboboxOptions
        };

        function getSectorsGridColumns(combobox) {
            return [
                {command:[{name:"edit", text: ""}], attributes: {
                        "class": "smaller-k-edit-btn"
                    }, title: "&nbsp;", width:57},
                {
                    field: "sector.name", title: "Μονάδα Υγείας",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 155
                },
                {
                    field: "sector.sectorType.name",
                    title: "Τύπος",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }, width: 110
                },
                {field: "sectorParent.name", title: "Ανήκει στη Μ.Υ.",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 145
                },
                {field: "sector.address", title: "Διεύθυνση",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 100
                },
                {field: "sector.addressNum", title: "Αριθμός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 40
                },
                {field: "sector.postalCode", title: "Τ.Κ.",
                    filterable: {
                        cell: {
                            operator: "contains",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 45
                },
                {field: "sector.cityName", title: "Πόλη",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 120
                },
                {field: "sector.prefectureName", title: "Νομός",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 120
                },
                {field: "sector.brick", title: "Brick", hidden: true,
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "sector.osfeBrick", title: "Brick ΟΣΦΕ",
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    width: 100
                },
                {field: "monday", title: "Δευτέρα",
                    filterable: false,
                    sortable: false,
                    width: 35,
                    editor: function(container, options) {
                        if (options.model.monday === 'morning') {
                            options.model.monday = { value: 'morning', text: 'Πρωί' };
                        } else if (options.model.monday === 'afternoon'){
                            options.model.monday = { value: 'afternoon', text: 'Απόγευμα' };
                        } else if (options.model.monday === 'morningAfternoon'){
                            options.model.monday = { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα' }
                        }

                        angular.element('<input data-bind="value: monday.value" k-ng-model="monday"/>')
                            .appendTo(container)
                            .kendoComboBox({
                                dataSource: new kendo.data.DataSource({
                                    data: [
                                        { value: 'morning', text: 'Πρωί'},
                                        { value: 'afternoon', text: 'Απόγευμα'},
                                        { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα'}
                                    ]
                                }),
                                filter: "startswith",
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value"
                            });
                    },
                    template: "#= (monday == 'morning') ? 'Π' : (monday == 'afternoon') ? 'Α' : (monday == 'morningAfternoon') ? 'Π/Α' : '' #"
                },
                {field: "tuesday", title: "Τρίτη",
                    filterable: false,
                    sortable: false,
                    width: 35,
                    editor: function(container, options) {
                        if (options.model.tuesday === 'morning') {
                            options.model.tuesday = { value: 'morning', text: 'Πρωί' };
                        } else if (options.model.tuesday === 'afternoon'){
                            options.model.tuesday = { value: 'afternoon', text: 'Απόγευμα' };
                        } else if (options.model.tuesday === 'morningAfternoon'){
                            options.model.tuesday = { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα' }
                        }

                        angular.element('<input data-bind="value: tuesday.value" k-ng-model="tuesday"/>')
                            .appendTo(container)
                            .kendoComboBox({
                                dataSource: new kendo.data.DataSource({
                                    data: [
                                        { value: 'morning', text: 'Πρωί'},
                                        { value: 'afternoon', text: 'Απόγευμα'},
                                        { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα'}
                                    ]
                                }),
                                filter: "startswith",
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value"
                            });
                    },
                    template: "#= (tuesday == 'morning') ? 'Π' : (tuesday == 'afternoon') ? 'Α' : (tuesday == 'morningAfternoon') ? 'Π/Α' : '' #"
                },
                {field: "wednesday", title: "Τετάρτη",
                    filterable: false,
                    sortable: false,
                    width: 35,
                    editor: function(container, options) {
                        if (options.model.wednesday === 'morning') {
                            options.model.wednesday = { value: 'morning', text: 'Πρωί' };
                        } else if (options.model.wednesday === 'afternoon'){
                            options.model.wednesday = { value: 'afternoon', text: 'Απόγευμα' };
                        } else if (options.model.wednesday === 'morningAfternoon'){
                            options.model.wednesday = { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα' }
                        }

                        angular.element('<input data-bind="value: wednesday.value" k-ng-model="wednesday"/>')
                            .appendTo(container)
                            .kendoComboBox({
                                dataSource: new kendo.data.DataSource({
                                    data: [
                                        { value: 'morning', text: 'Πρωί'},
                                        { value: 'afternoon', text: 'Απόγευμα'},
                                        { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα'}
                                    ]
                                }),
                                filter: "startswith",
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value"
                            });
                    },
                    template: "#= (wednesday == 'morning') ? 'Π' : (wednesday == 'afternoon') ? 'Α' : (wednesday == 'morningAfternoon') ? 'Π/Α' : '' #"
                },
                {field: "thursday", title: "Πέμπτη",
                    filterable: false,
                    sortable: false,
                    width: 35,
                    editor: function(container, options) {
                        if (options.model.thursday === 'morning') {
                            options.model.thursday = { value: 'morning', text: 'Πρωί' };
                        } else if (options.model.thursday === 'afternoon'){
                            options.model.thursday = { value: 'afternoon', text: 'Απόγευμα' };
                        } else if (options.model.thursday === 'morningAfternoon'){
                            options.model.thursday = { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα' }
                        }

                        angular.element('<input data-bind="value: thursday.value" k-ng-model="thursday"/>')
                            .appendTo(container)
                            .kendoComboBox({
                                dataSource: new kendo.data.DataSource({
                                    data: [
                                        { value: 'morning', text: 'Πρωί'},
                                        { value: 'afternoon', text: 'Απόγευμα'},
                                        { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα'}
                                    ]
                                }),
                                filter: "startswith",
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value"
                            });
                    },
                    template: "#= (thursday == 'morning') ? 'Π' : (thursday == 'afternoon') ? 'Α' : (thursday == 'morningAfternoon') ? 'Π/Α' : '' #"
                },
                {field: "friday", title: "Παρασκευή",
                    filterable: false,
                    sortable: false,
                    width: 35,
                    editor: function(container, options) {
                        if (options.model.friday === 'morning') {
                            options.model.friday = { value: 'morning', text: 'Πρωί' };
                        } else if (options.model.friday === 'afternoon'){
                            options.model.friday = { value: 'afternoon', text: 'Απόγευμα' };
                        } else if (options.model.friday === 'morningAfternoon'){
                            options.model.friday = { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα' }
                        }

                        angular.element('<input data-bind="value: friday.value" k-ng-model="friday"/>')
                            .appendTo(container)
                            .kendoComboBox({
                                dataSource: new kendo.data.DataSource({
                                    data: [
                                        { value: 'morning', text: 'Πρωί'},
                                        { value: 'afternoon', text: 'Απόγευμα'},
                                        { value: 'morningAfternoon', text: 'Πρωί/Απόγευμα'}
                                    ]
                                }),
                                filter: "startswith",
                                valuePrimitive: true,
                                dataTextField: "text",
                                dataValueField: "value"
                            });
                    },
                    template: "#= (friday == 'morning') ? 'Π' : (friday == 'afternoon') ? 'Α' : (friday == 'morningAfternoon') ? 'Π/Α' : '' #"
                },
                {template: '<md-checkbox class="checkbox-toolbar customers green inline"  aria-label="main" ng-model="dataItem.main" ng-disabled="true"></md-checkbox>',
                    field: "main", title: "Βασική Μονάδα",
                    // editor: function(container){
                    //     angular.element('<md-checkbox ng-model="dataItem.main"  aria-label="main" ' +
                    //         'class="checkbox-toolbar green inline"></md-checkbox>')
                    //         .appendTo(container);
                    // },
                    filterable: {
                        cell: {
                            operator: "eq",
                            template: function (args) {
                                args.element.kendoDropDownList({
                                    autoBind:false,
                                    dataTextField: "text",
                                    dataValueField: "value",
                                    dataSource: new kendo.data.DataSource({
                                        data: [{ text: "Ναι", value: "true" },
                                            { text: "Όχι", value: "false" }]
                                    }),
                                    valuePrimitive: true

                                })
                            },
                            showOperators: false
                        }
                    }, width: 70
                },
                {field: "sectorPosition.name", title: "Νοσοκομειακή Θέση", hidden: true,
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    },
                    editor: function(container) {
                        angular.element('<select id="sector-position" data-bind="value:sectorPosition.id"></select>')
                            .appendTo(container)
                            .kendoComboBox(combobox);
                    }
                },
                {
                    field: "phone", title: "Τηλέφωνο", hidden: true,
                    filterable: {
                        cell: {
                            operator: "startswith",
                            template: function (args) {
                                KendoFilter.getTemplate(args);
                            },
                            showOperators: false
                        }
                    }
                },
                {field: "startedAt", title: "Ημερομηνία Έναρξης", hidden: true, template: "#= kendo.toString(kendo.parseDate(startedAt), 'dddd, dd/MM/yyyy')#",
                    filterable: {
                        cell: {
                            operator: "eq",
                            showOperators: false
                        }
                    }
                },
                {field: "endedAt", title: "Ημερομηνία Λήξης", hidden: true, template: "#= (endedAt == null) ? '' : kendo.toString(kendo.parseDate(endedAt), 'dddd, dd/MM/yyyy')#",
                    filterable: {
                        cell: {
                            operator: "eq",
                            showOperators: false
                        }
                    }
                }
            ];
        }

        function getSectorsOptions(datasource, columns) {
            return {
                dataSource: datasource,
                sortable: true,
                filterable: {
                    mode: "row"
                },
                resizable: true,
                columns: columns,
                toolbar: [{ name: "New Customer Sector",
                    text: "New Customer Sector", template: "<input type='button' class='k-button' value = 'Προσθήκη Μονάδας Υγείας' ui-sref='customerSectorAssignation' ng-click='vm.openWindow()' />" },
                    { template: "<div class='right'><label>Σύνολο: {{vm.sectorsGridOptions.dataSource.total()}}</label></div>",
                        overflow: "never" }],
                editable: {
                    mode: "popup"
                }
            };
        }

        function getTimeEditor(container, options) {
            return angular.element('<input data-text-field="' + options.field + '" data-value-field="' + options.field + '" data-bind="value:' + options.field + '" data-format="' + options.format + '"/>')
                .appendTo(container)
                .kendoTimePicker({});
        }

        function getDateEditor(container, options) {
            return angular.element('<input data-bind="value:' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    dataSource: [
                        {id:1, name:'Δευτέρα'},
                        {id:2, name:'Τρίτη'},
                        {id:3, name:'Τετάρτη'},
                        {id:4, name:'Πέμπτη'},
                        {id:5, name:'Παρασκευή'}
                    ],
                    valuePrimitive: true,
                    dataTextField: "name",
                    dataValueField: "id"

                });
        }

        function setIsSectorSaved(value) {
            savedSector = value;
        }

        function getIsSectorSaved() {
            return savedSector;
        }

        function getSector() {
            return sector;
        }

        function setSector(value) {
            sector = value;
        }

        function getCustomerSector() {
            return customerSector;
        }

        function setCustomerSector(value) {
            customerSector = value;
        }

        function getCitiesComboboxOptions() {
            return citiesComboboxOptions;
        }

        function setCitiesComboboxOptions(value) {
            citiesComboboxOptions = value;
        }

        function getPrefecturesComboboxOptions() {
            return prefecturesComboboxOptions;
        }

        function setPrefecturesComboboxOptions(value) {
            prefecturesComboboxOptions = value;
        }
    }
})();
